import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationMapService {
  zoom: number = 8;

  constructor() { }
}
