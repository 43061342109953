import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {preference} from "../../../common-classes/app-objects.model";
import {TimeService} from "../../time/time.service";
import {BusinessService} from "../../../business.service";
import * as FileSaver from "file-saver";
import {TagsService} from "../../../tags.service";
import {Appointment} from '../../../../models/Appointment.model';
import {SchedulerPreferenceService} from '../../../scheduler-preference.service';

@Component({
  selector: 'add-to-calendar',
  templateUrl: './add-to-calendar.component.html',
  styleUrls: ['./add-to-calendar.component.css']
})
export class AddToCalendarComponent implements OnInit {
  appointments: Appointment[] = [];
  preference: preference = this.schedulerPreferenceService.schedulerPreference;

  constructor(private bottomSheetRef: MatBottomSheetRef<AddToCalendarComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public passedData: {appointments: Appointment[]},
              private timeService: TimeService, private businessService: BusinessService, private tagsService: TagsService, private schedulerPreferenceService: SchedulerPreferenceService) { }

  getISODateFormat(date: Date){
    let year = this.timeService.getPipeYearFromDate(date);
    let month = this.timeService.getPipeMonthFromDate(date);
    let day = this.timeService.getPipeDayFromDate(date);
    let hour = this.timeService.getPipeHourFromDate(date)
    let minutes = this.timeService.getPipeMinutesFromDate(date);
    let milliseconds = "00"
    let dateToReturn = year + "" + month + "" + day + "T" + hour + "" + minutes + "" + milliseconds + "Z";
    return dateToReturn;
  }

  getICSFile(appointment: Appointment){
      this.businessService.getApptICS(appointment.hashId)
          .subscribe(response => {
            FileSaver.saveAs(response, appointment.hashId);
          })
  }

  selectCalendarType(calendarType: string){
    for(let i = 0, x = this.appointments.length; i < x; i++) {
      let url: string = "";
      let eventTitle = this.tagsService.convertApptPropertiesToTags('addToCalendarEventTitle', this.preference, this.appointments[i]);
      eventTitle = encodeURIComponent(eventTitle);
      let startDate = this.getISODateFormat(this.appointments[i].clientStartDateTimeUTC as Date);
      let endDate = this.getISODateFormat(this.appointments[i].clientEndDateTimeUTC);
      if((this.appointments[i].address === undefined || this.appointments[i].address === null || this.appointments[i].address.trim()==="") &&
          this.appointments[i].location.description !== undefined && this.appointments[i].location.description !== null && this.appointments[i].location.description.trim() !== "")
        this.appointments[i].address = this.appointments[i].location.description;
      let locationText = this.tagsService.convertApptPropertiesToTags('addToCalendarEventLocation', this.preference, this.appointments[i]);
      locationText = encodeURIComponent(locationText);
      let detailsText = this.tagsService.convertApptPropertiesToTags('addToCalendarEventDescription', this.preference, this.appointments[i]);
      detailsText = encodeURIComponent(detailsText);
      switch (calendarType) {
        case 'google':
          let dates = startDate + "/" + endDate;
          url = "http://www.google.com/calendar/render?action=TEMPLATE&text=" + eventTitle + "&dates=" + dates + "&location=" + locationText + "&details=" + detailsText;
          break;
        case 'ical':
        case 'ics':
          url = null;
          this.getICSFile(this.appointments[i]);
          break;
        case 'outlook':
          url = "https://outlook.live.com/owa/?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=" + startDate + "&enddt=" + endDate + "&subject=" + eventTitle + "&location=" + locationText + "&description=" + detailsText;
          break;
        case 'yahoo':
          url = "https://calendar.yahoo.com/?v=60&view=d&type=20&title=" + eventTitle + "&st=" + startDate + "&et=" + endDate + "&in_loc=" + locationText + "&desc=" + detailsText;
      }
      if(url !== null)
        window.open(url, "_blank")
      this.businessService.recordAuditTrailNavigation('Add to Calendar (' + calendarType + ')').subscribe(() => {});
      this.bottomSheetRef.dismiss();
    }
  }

  ngOnInit() {
    this.appointments = this.passedData.appointments;
  }

}
