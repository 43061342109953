import { Component, OnInit } from '@angular/core';
import {
  Client,
  ClientAccount,
  miniWebsite,
  preference,
  schedulerLink
} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {ClientAccountService} from "../../client-account/client-account.service";
import {WaitlistService} from "../waitlist.service";
import {ActivatedRoute} from "@angular/router";
import {TagsService} from "../../tags.service";
import {WaitList} from '../../../models/WaitList.model';
import {Appointment} from '../../../models/Appointment.model';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';

@Component({
  selector: 'app-remove-waitlist',
  templateUrl: './remove-waitlist.component.html'
})
export class RemoveWaitlistComponent implements OnInit {
  preference: preference = this.schedulerPreferenceService.schedulerPreference;
  clientAccount: ClientAccount = this.clientAccountService.loggedInClient;
  selectedClient: Client;
  waitlistHash: string;
  waitlist: WaitList;
  waitlistRemoveLoaded: boolean = false;
  waitlistRemoveLoading: boolean = true;
  waitlistRemoving: boolean = false;
  waitlistRemoved: boolean = false;
  errorLoading: boolean = false;
  errorRemoving: boolean = false;
  waitlistRemoveTitle: string;
  waitlistRemoveText: string;

  constructor(private waitlistService: WaitlistService,
              private businessService: BusinessService,
              private route: ActivatedRoute,
              private clientAccountService: ClientAccountService,
              private tagsService: TagsService,
              private schedulerPreferenceService: SchedulerPreferenceService) {
  }

  removeSuccess(){
    this.waitlistRemoving = false;
    this.waitlistRemoved = true;
    this.waitlistRemoveTitle = this.preference.labelMap.waitlistRemoveSuccessTitle;
    this.waitlistRemoveText =  this.tagsService.convertWaitListPropertiesToTags('waitlistRemoveSuccessText', this.preference, [this.waitlist], this.waitlist);
  }

  removeError(){
    this.waitlistRemoving = false;
    this.errorRemoving = true;
  }

  removeFromWaitlist(){
    this.waitlistRemoving = true;
    this.waitlistService.removeFromWaitlist(this.waitlist.waitListId)
        .subscribe((waitlist: WaitList) => {
          this.waitlist = waitlist;
          this.removeSuccess();
        }, error => {
          this.removeError();
        })
  }

  getWaitlist(){
    this.route.params.subscribe(params => {
      this.waitlistHash = params["waitlistHash"];
    })
    this.waitlistService.getWaitlist(this.waitlistHash)
        .subscribe((waitlist: WaitList) => {
          this.waitlist = waitlist;
          if(this.waitlist.status !== 'REMOVED'){
            this.waitlistRemoveTitle = this.preference.labelMap.waitlistRemoveTitle;
            this.waitlistRemoveText =  this.tagsService.convertWaitListPropertiesToTags('waitlistRemoveText', this.preference, [this.waitlist], this.waitlist);
          } else {
            this.removeSuccess();
          }
          this.waitlistRemoveLoading = false;
          this.waitlistRemoveLoaded = true;
          this.errorLoading = false;
        }, error => {
          this.waitlistRemoveLoading = false;
          this.waitlistRemoveLoaded = false;
          this.errorLoading = true;
        })
  }

  ngOnInit() {
    this.selectedClient = this.clientAccountService.setSelectedClientOnComponent(this.preference);
    this.getWaitlist();
  }

}
