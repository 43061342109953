import {Injectable} from "@angular/core";
import {preference} from "../../common-classes/app-objects.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ResponsiveService} from "../../responsive.service";
import * as _ from "lodash";
import {FormFunctionsService} from "../../form-functions.service";
import {CustomField, DepFieldDefnIdObj} from '../../../models/CustomField.model';

@Injectable()
export class ClientInfoService {
    constructor(private responsiveService: ResponsiveService, private formFunctionsService: FormFunctionsService){}
    standardEmailValidationRegex = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,8}$";

    createSendTextMessageReminderCustomField(preference: preference, depFieldDefnId: number, waitListOnly: boolean){
        let depSetValue = false;
        if(preference.booleanMap.defaultSMSRemindValue){
            depSetValue = true;
        }

        let sendTextMessageReminderCustomField: CustomField = {
            'active': true,
            'allowClientEdit': true,
            'businessId': null,
            'code': 'sendTextReminder',
            'createdDate': null,
            'createdUser': null,
            'dataType': 'CHKBOX',
            'defaultValue': null,
            'depAction': "ENABLE",
            'depAndOr': null,
            'depCondition': null,
            'depError': null,
            'depFieldDefnId': depFieldDefnId,
            'depFieldDefnIds': null,
            'depFieldDefnIdObjs': null,
            'depSetValue': depSetValue,
            'depType': "HAS_VALUE",
            'depValue': null,
            'enable': true,
            'otherFieldDepOnThisField': false,
            'extendedValue': null,
            'fieldValues': null,
            'dropdownValues': null,
            'filterByService': null,
            'hint': waitListOnly ? preference.labelMap.smsHintWaitList : preference.labelMap.smsHint,
            'inputType': null,
            'label': waitListOnly ? preference.labelMap.infoPnlSendTextCBLabelWaitList : preference.labelMap.infoPnlSendTextCBLabel,
            'labelTag': null,
            'mask': null,
            'mode': 'APPT_TXT_REMINDER',
            'modifiedDate': null,
            'modifiedUser': null,
            'oldFieldIds': null,
            'required': false,
            'schedulerPreferenceFieldDefnId': -1,
            'serviceIds': null,
            'sortOrder': null,
            'tagName': 'CUSTOM_FIELD_SENDTEXTMSGREMINDER',
            'userDefinedField': false,
            'validationError': null,
            'validationRegex': null,
            'value': null,
            'booleanValue': null,
            'valueTag': '%CUSTOM_FIELD_SENDTEXTMSGREMINDER%',
            'visible': true,
            'visibleOnScheduler': true,
            'showOnScheduler': true,
            'requiredJSON': null,
            'requiredObj': null,
            'requiredObjInvalid': false
        };
        return sendTextMessageReminderCustomField;
    }

    createSendTextMessageReminderHrsCustomField(preference: preference, depFieldDefnId: number){
        let fieldValues = preference.labelMap.infoPnlSendTextD1Hr + "\n" + preference.labelMap.infoPnlSendTextD2Hr + "\n" + preference.labelMap.infoPnlSendTextD4Hr + "\n" + preference.labelMap.infoPnlSendTextD12Hr + "\n" + preference.labelMap.infoPnlSendTextD24Hr;
        if (preference.clientReminderSMSHoursList)  // TT-6496 2/5/2021
            if (preference.clientReminderSMSHoursList.length === 0) // no options to select, return null
                return null;
            else {
                fieldValues = '';
                for (let i = 0, x = preference.clientReminderSMSHoursList.length; i < x; i++) {
                    switch (preference.clientReminderSMSHoursList[i]) {
                        case 1:
                            fieldValues = fieldValues + preference.labelMap.infoPnlSendTextD1Hr;
                            break;
                        case 2:
                            fieldValues = fieldValues + preference.labelMap.infoPnlSendTextD2Hr;
                            break;
                        case 4:
                            fieldValues = fieldValues + preference.labelMap.infoPnlSendTextD4Hr;
                            break;
                        case 12:
                            fieldValues = fieldValues + preference.labelMap.infoPnlSendTextD12Hr;
                            break;
                        case 24:
                            fieldValues = fieldValues + preference.labelMap.infoPnlSendTextD24Hr;
                            break;
                    }
                    if (i !== preference.clientReminderSMSHoursList.length - 1) // is not the last value in the list
                        fieldValues = fieldValues + "\n";
                }
            }
        let sendTextMessageReminderCustomField: CustomField = {
            'active': true,
            'allowClientEdit': true,
            'businessId': null,
            'code': 'sendTextReminderHrs',
            'createdDate': null,
            'createdUser': null,
            'dataType': 'LIST',
            'defaultValue': preference.labelMap.infoPnlSendTextD1Hr,
            'depAction': 'VISIBLE',
            'depAndOr': null,
            'depCondition': 'EQ',
            'depError': null,
            'depFieldDefnId': depFieldDefnId,
            'depFieldDefnIds': null,
            'depFieldDefnIdObjs': null,
            'depSetValue': preference.labelMap.infoPnlSendTextD1Hr,
            'depType': 'EXACT',
            'depValue': 'true',
            'enable': null,
            'otherFieldDepOnThisField': false,
            'extendedValue': null,
            'fieldValues': fieldValues,
            'dropdownValues': null,
            'filterByService': null,
            'hint': null,
            'inputType': null,
            'label': preference.labelMap.infoPnlSendTextHrsCBLabel,
            'labelTag': null,
            'mask': null,
            'mode': 'APPT_TXT_REMINDER',
            'modifiedDate': null,
            'modifiedUser': null,
            'oldFieldIds': null,
            'required': false,
            'schedulerPreferenceFieldDefnId': -2,
            'serviceIds': null,
            'sortOrder': null,
            'tagName': 'CUSTOM_FIELD_SENDTEXTMSGREMINDERHRS',
            'userDefinedField': false,
            'validationError': null,
            'validationRegex': null,
            'value': null,
            'booleanValue': null,
            'valueTag': '%CUSTOM_FIELD_SENDTEXTMSGREMINDERHRS%',
            'visible': true,
            'visibleOnScheduler': true,
            'showOnScheduler': true,
            'requiredJSON': null,
            'requiredObj': null,
            'requiredObjInvalid': false
        };
        return sendTextMessageReminderCustomField;
    }

    createAdditionalGuestsFieldValues(maxAdditionalGuests: number){
        let fieldValues = '';
        for(let i = 0; i <= maxAdditionalGuests; i++){
            if(i < maxAdditionalGuests){
                fieldValues = fieldValues + i + '\n'
            } else {
                fieldValues = fieldValues + i;
            }
        }
        return fieldValues;
    }

    createAdditionalGuestsDropdown(preference: preference){
        let fieldValues = this.createAdditionalGuestsFieldValues(preference.maxAdditionalGuests);
        let additionalGuestsCustomField: CustomField = {
            'active': true,
            'allowClientEdit': true,
            'businessId': null,
            'code': 'additionalGuests',
            'createdDate': null,
            'createdUser': null,
            'dataType': 'LIST',
            'defaultValue': '0',
            'depAction': null,
            'depAndOr': null,
            'depCondition': null,
            'depError': null,
            'depFieldDefnId': null,
            'depFieldDefnIds': null,
            'depFieldDefnIdObjs': null,
            'depSetValue': null,
            'depType': null,
            'depValue': null,
            'enable': null,
            'otherFieldDepOnThisField': false,
            'extendedValue': null,
            'fieldValues': fieldValues,
            'dropdownValues': null,
            'filterByService': null,
            'hint': null,
            'inputType': null,
            'label': preference.labelMap.additionalGuestText,
            'labelTag': null,
            'mask': null,
            'mode': 'APPT_ADDITIONAL_GUESTS',
            'modifiedDate': null,
            'modifiedUser': null,
            'oldFieldIds': null,
            'required': false,
            'schedulerPreferenceFieldDefnId': -3,
            'serviceIds': null,
            'sortOrder': null,
            'tagName': 'CUSTOM_FIELD_ADDITIONAL_GUESTS',
            'userDefinedField': false,
            'validationError': null,
            'validationRegex': null,
            'value': null,
            'booleanValue': null,
            'valueTag': '%CUSTOM_FIELD_ADDITIONAL_GUESTS%',
            'visible': true,
            'visibleOnScheduler': true,
            'showOnScheduler': true,
            'requiredJSON': null,
            'requiredObj': null,
            'requiredObjInvalid': false
        };
        return additionalGuestsCustomField;
    }

    createPasswordField(preference: preference){
        let passwordField: CustomField = {
            'active': true,
            'allowClientEdit': true,
            'businessId': preference.businessId,
            'code': 'password',
            'createdDate': null,
            'createdUser': null,
            'dataType': 'TXT',
            'defaultValue': null,
            'depAction': null,
            'depAndOr': null,
            'depCondition': null,
            'depError': null,
            'depFieldDefnId': null,
            'depFieldDefnIds': null,
            'depFieldDefnIdObjs': null,
            'depSetValue': null,
            'depType': null,
            'depValue': null,
            'enable': null,
            'otherFieldDepOnThisField': null,
            'extendedValue': null,
            'fieldValues': null,
            'dropdownValues': null,
            'filterByService': null,
            'hint': null,
            'inputType': 'password',
            'label': preference.labelMap.passwordFieldLabel,
            'labelTag': null,
            'mask': null,
            'mode': null,
            'modifiedDate': null,
            'modifiedUser': null,
            'oldFieldIds': null,
            'required': true,
            'schedulerPreferenceFieldDefnId': -4,
            'serviceIds': null,
            'sortOrder': null,
            'tagName': null,
            'userDefinedField': null,
            'validationError': null,
            'validationRegex': null,
            'value': null,
            'booleanValue': null,
            'valueTag': null,
            'visible': true,
            'visibleOnScheduler': true,
            'showOnScheduler': true,
            'requiredJSON': null,
            'requiredObj': null,
            'requiredObjInvalid': false
        }
        return passwordField;
    }

    isClientInfoFormValid(clientInfoForm: FormGroup, fields: CustomField[]): boolean { // LHB 1/15/2021 TT-7294
        let validForm = clientInfoForm.valid;
        if (validForm)
            return validForm;
        else {
            let invalidFieldCount = 0;
            for(let prop in clientInfoForm.controls){
                if(clientInfoForm.controls[prop].invalid) {
                    for(let i = 0, x = fields.length; i < x; i++){
                        if(prop === fields[i].schedulerPreferenceFieldDefnId.toString() && fields[i].showOnScheduler)
                            invalidFieldCount++;
                    }
                }
            }
            if (invalidFieldCount === 0)
                validForm = true;
            return validForm;
        }
    }

    reasonIdCheckForDependencyFieldsFlag(fieldWithCondition: CustomField, reasonId: number): boolean {
        if(reasonId === undefined || reasonId === null ||
            (reasonId !== undefined && (fieldWithCondition.serviceIds === null || fieldWithCondition.serviceIds.length === 0) ||
                (fieldWithCondition.serviceIds !== null && fieldWithCondition.serviceIds.length > 0 && fieldWithCondition.serviceIds.indexOf(reasonId) !== -1)) ||
            (reasonId === -1 && fieldWithCondition.value !== null &&fieldWithCondition.serviceIds !== null && fieldWithCondition.serviceIds.length > 0)) { // LHB 09/14/2020 TT-6998
            return true;
        } else
            return false;
    }

    setClientInfoFormValue(clientInfoForm: FormGroup, fieldWithCondition: CustomField, schedulerPreferenceFieldDefnList: CustomField[], reasonId: number){
        if(clientInfoForm !== undefined) {
            let fieldName = '' + fieldWithCondition.schedulerPreferenceFieldDefnId + '';
            if(!this.reasonIdCheckForDependencyFieldsFlag(fieldWithCondition, reasonId))
                fieldWithCondition.showOnScheduler = false;
            if (fieldWithCondition.showOnScheduler) {
                if (clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId] === undefined) {
                    let validatorsArray = this.createValidatorsArray(fieldWithCondition);
                    clientInfoForm.addControl(fieldName, new FormControl({
                        value: fieldWithCondition.depSetValue,
                        disabled: !fieldWithCondition.enable
                    }, validatorsArray));
                } else if(((clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId].enabled && fieldWithCondition.depAction === 'ENABLE') ||
                    (clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId].disabled && fieldWithCondition.depAction === 'DISABLE')) &&
                    (clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId].value === undefined || clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId].value === null)) {
                    clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId].patchValue(fieldWithCondition.depSetValue);
                }  else if(clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId].value !== fieldWithCondition.value) {
                    clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId].patchValue(fieldWithCondition.value);
                }
                if(fieldWithCondition.enable){
                    clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId].enable();
                } else {
                    clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId].disable();
                }
                this.checkDependencyFields(fieldWithCondition, schedulerPreferenceFieldDefnList, clientInfoForm, reasonId);
            } else if(!fieldWithCondition.showOnScheduler && clientInfoForm.controls[fieldWithCondition.schedulerPreferenceFieldDefnId] !== undefined){
                clientInfoForm.removeControl(fieldName);
                this.checkDependencyFields(fieldWithCondition, schedulerPreferenceFieldDefnList, clientInfoForm, reasonId);
            }

        }
    };

    dateDependentFieldEqualitySetting(fieldWithCondition: CustomField, clientInfoForm: FormGroup, showOrEnable: boolean, valueToPatch: string | boolean){
        switch(fieldWithCondition.depAction){
            case 'VISIBLE':
                fieldWithCondition.showOnScheduler = showOrEnable;
                break;
            case 'HIDDEN':
                fieldWithCondition.showOnScheduler = showOrEnable;
                break;
            case 'ENABLE':
                fieldWithCondition.enable = showOrEnable;
                break;
            case 'DISABLE':
                fieldWithCondition.enable = showOrEnable;
                break;
        }
        if (valueToPatch !== null)
            fieldWithCondition.value = String(valueToPatch);
        return fieldWithCondition;
    }

    setUpDependencyFields(fieldWithCondition: CustomField, fieldDependedOn: CustomField, valueProperty: string, depValueArray: string[], clientInfoForm: FormGroup, schedulerPreferenceFieldDefnList: CustomField[], reasonId: number){
        // if (fieldDependedOn.schedulerPreferenceFieldDefnId === 23257 || fieldDependedOn.schedulerPreferenceFieldDefnId === 21342) {
        //     console.log('fieldWithCondition: ');
        //     console.log(fieldWithCondition)
        //     console.log('fieldDependedOn: ');
        //     console.log(fieldDependedOn)
        //     console.log('clientInfoForm: ');
        //     console.log(clientInfoForm)
        //     console.log('reasonId: ');
        //     console.log(reasonId)
        //     console.log('depValueArray: ');
        //     console.log(depValueArray)
        // }
        fieldDependedOn.otherFieldDepOnThisField = true;
        let showOrEnable = true;
        let propToToggle = 'showOnScheduler';
        if(fieldWithCondition.depAction === 'VISIBLE' || fieldWithCondition.depAction === 'ENABLE'){
            showOrEnable = true;
        } else if(fieldWithCondition.depAction === 'HIDDEN' || fieldWithCondition.depAction === 'DISABLE'){
            showOrEnable = false;
        }
        if(fieldWithCondition.depAction === 'ENABLE' || fieldWithCondition.depAction === 'DISABLE'){
            propToToggle = 'enable';
        }

        //Condition to Check:
        //1: if the field is not 'NOR' condition (meaning it is an any (OR) or all (AND)) then need to check that value in depValueArray is the value that the field that depends on it requires
        //2: if the field is a 'NOR' condition, then need to check that none of the values in the depValueArray match the value that the field that is depended on is set to
        let depValueConditionToCheck = false;
        if (fieldWithCondition.depAndOr === 'NOR' && depValueArray.indexOf(fieldDependedOn[valueProperty]) === -1 && fieldDependedOn[valueProperty] !== null)
            depValueConditionToCheck = true;
        else if (fieldWithCondition.depAndOr === 'OR' && depValueArray.indexOf(fieldDependedOn[valueProperty]) !== -1)
            depValueConditionToCheck = true;
        else if (fieldWithCondition.depAndOr === 'AND' && fieldWithCondition.depFieldDefnIds.length === 1 && depValueArray.indexOf(fieldDependedOn[valueProperty]) !== -1)
            depValueConditionToCheck = true;
        else if (fieldWithCondition.depAndOr === 'AND' && fieldWithCondition.depFieldDefnIds.length > 1) { // TT-7315 LHB 1/22/2021
            // find other fields in list that field with condition depends on and see what their values are:
            let validValuesCounter = 0;
            for (let i = 0, x = fieldWithCondition.depFieldDefnIdObjs.length; i < x; i++)
                for (let j = 0, y = schedulerPreferenceFieldDefnList.length; j < y; j++)
                    if (fieldWithCondition.depFieldDefnIdObjs[i].schedulerPreferenceFieldDefnId === schedulerPreferenceFieldDefnList[j].schedulerPreferenceFieldDefnId &&
                        fieldWithCondition.depFieldDefnIdObjs[i].depValueArray.indexOf(schedulerPreferenceFieldDefnList[j].value) !== -1)
                        validValuesCounter++
            if (validValuesCounter === fieldWithCondition.depFieldDefnIds.length)
                depValueConditionToCheck = true;
        } else if (!fieldWithCondition.depAndOr && depValueArray.indexOf(fieldDependedOn[valueProperty]) !== -1)
            depValueConditionToCheck = true;
        // if((depValueArray.indexOf(fieldDependedOn[valueProperty]) !== -1 && fieldWithCondition.depAndOr !== 'NOR') ||
        //     (depValueArray.indexOf(fieldDependedOn[valueProperty]) === -1 && fieldWithCondition.depAndOr === 'NOR' && fieldDependedOn[valueProperty] !== null)) {
        //     depValueConditionToCheck = true;
        // }
        switch(fieldDependedOn.dataType){
            case 'DATE':
                let currentDateObj = new Date();
                let currentYear = currentDateObj.getFullYear();
                let currentMonth = currentDateObj.getMonth();
                let currentDate = currentDateObj.getDate();
                let dateTimeMsToCompareWith = new Date(currentYear, currentMonth, currentDate, 0, 0, 0, 0).getTime();
                let dependedOnFieldNumberValue = Number(fieldDependedOn.value);
                switch (fieldWithCondition.depType){
                    case 'AGE_FROM_TODAY':
                        //no need to change dateTimeMsToCompareWith because already properly set to the day they are booking
                        break;
                    case 'AGE_FROM_APPT_DATE':
                        //TO DO ONCE AVAILABILITY AND DATE SELECTION ARE IN PLACE: COME BACK AND CALCULATE DATETIMEMSTOCOMPAREWITH FOR THE APPOINTMENT DATE
                        break;
                }
                let msDifference = dateTimeMsToCompareWith - dependedOnFieldNumberValue;
                let secsDifference = msDifference / 1000;
                let minutesDifference = secsDifference / 60;
                let hoursDifference = minutesDifference / 60;
                let daysDifference = hoursDifference / 24;
                let depValueDays = Number(depValueArray[0]);
                //FIRST NEED TO CHECK IF VALUE OF DATE FIELD WOULD MAKE IT SO THAT THIS FIELD SHOULD HAVE WHATEVER ACTION TAKEN ON IT
                if(dependedOnFieldNumberValue === 0) {//MEANS NO DATE HAS BEEN FILLED IN YET
                    fieldWithCondition = this.dateDependentFieldEqualitySetting(fieldWithCondition, clientInfoForm, !showOrEnable, fieldWithCondition.defaultValue);
                } else if(daysDifference > depValueDays){
                    //days between selected on DATE field minus either current day or date of appointment is GREATER THAN number of days set in depValue
                    switch(fieldWithCondition.depCondition){
                        case 'GT':
                        case 'GE':
                            //SINCE CHECK IS FOR INPUT VALUE TO BE GREATER THAN OR EQUAL TO DEP DAYS VALUE, AND IN THIS CASE THE INPUT VALUE *IS* GREATER THAN DEP DAYS VALUE, THEN WHATEVER ACTION IS DEFINED *SHOULD* BE TAKE AND VALUE SHOULD GET SET
                            fieldWithCondition = this.dateDependentFieldEqualitySetting(fieldWithCondition, clientInfoForm, showOrEnable, fieldWithCondition.depSetValue);
                            break;
                        case 'EQ':
                        case 'LE':
                        case 'LT':
                            //SINCE CHECK IS FOR INPUT VALUE TO BE LESS THAN OR EQUAL TO DEP DAYS VALUE, AND IN THIS CASE THE INPUT VALUE IS GREATER THAN DEP DAYS VALUE, THEN WHATEVER ACTION IS DEFINED SHOULD *NOT* BE TAKE AND DEP VALUE SHOULD GET REMOVED
                            fieldWithCondition = this.dateDependentFieldEqualitySetting(fieldWithCondition, clientInfoForm, !showOrEnable, fieldWithCondition.defaultValue);
                            break;
                    }
                } else if(daysDifference === depValueDays){
                    //days between selected on DATE field minus either current day or date of appointment is EQUAL TO the number of days set in depValue
                    switch(fieldWithCondition.depCondition){
                        case 'GT':
                        case 'LT':
                            //SINCE CHECK IS FOR INPUT VALUE TO BE GREATER THAN OR LESS THAN DEP DAYS VALUE, AND IN THIS CASE THE INPUT VALUE IS EQUAL TO DEP DAYS VALUE, THEN WHATEVER ACTION IS DEFINED SHOULD *NOT* BE TAKE AND VALUE SHOULD GET REMOVED
                            fieldWithCondition = this.dateDependentFieldEqualitySetting(fieldWithCondition, clientInfoForm, !showOrEnable, fieldWithCondition.defaultValue);
                            break;
                        case 'GE':
                        case 'EQ':
                        case 'LE':
                            //SINCE CHECK IS FOR INPUT VALUE TO BE GREATER/LESS THAN OR EQUAL TO DEP DAYS VALUE, AND IN THIS CASE THE INPUT VALUE *IS* EQUAL TO DEP DAYS VALUE, THEN WHATEVER ACTION IS DEFINED *SHOULD* BE TAKE AND VALUE SHOULD GET SET
                            fieldWithCondition = this.dateDependentFieldEqualitySetting(fieldWithCondition, clientInfoForm, showOrEnable, fieldWithCondition.depSetValue);
                            break;
                    }
                } else if(daysDifference < depValueDays){
                    //days between selected on DATE field minus either current day or date of appointment is LESS THAN number of days set in depValue
                    switch(fieldWithCondition.depCondition){
                        case 'GT':
                        case 'GE':
                        case 'EQ':
                            //SINCE CHECK IS FOR INPUT VALUE TO BE GREATER THAN OR EQUAL TO DEP DAYS VALUE, AND IN THIS CASE THE INPUT VALUE IS LESS THAN DEP DAYS VALUE, THEN WHATEVER ACTION IS DEFINED SHOULD *NOT* BE TAKE AND VALUE SHOULD GET REMOVED
                            fieldWithCondition = this.dateDependentFieldEqualitySetting(fieldWithCondition, clientInfoForm, !showOrEnable, fieldWithCondition.defaultValue);
                            break;
                        case 'LE':
                        case 'LT':
                            //SINCE CHECK IS FOR INPUT VALUE TO BE LESS THAN OR EQUAL TO DEP DAYS VALUE, AND IN THIS CASE THE INPUT VALUE *IS* LESS THAN DEP DAYS VALUE, THEN WHATEVER ACTION IS DEFINED *SHOULD* BE TAKE AND VALUE SHOULD GET SET
                            fieldWithCondition = this.dateDependentFieldEqualitySetting(fieldWithCondition, clientInfoForm, showOrEnable, fieldWithCondition.depSetValue);
                            break;
                    }
                }
                break;
            case 'LIST':
                if(depValueConditionToCheck){
                    fieldWithCondition[propToToggle] = showOrEnable;
                    fieldWithCondition.value = fieldWithCondition.depSetValue;
                } else {
                    fieldWithCondition[propToToggle] = !showOrEnable;
                }
                break;
            case 'TXT':
                if (fieldWithCondition.depType === 'HAS_VALUE' && fieldDependedOn.value !== null && fieldDependedOn.value.trim() !== "") {
                    fieldWithCondition[propToToggle] = showOrEnable;
                    if(fieldWithCondition.value === null) {
                        fieldWithCondition.value = fieldWithCondition.depSetValue;
                    }
                } else {
                    fieldWithCondition[propToToggle] = !showOrEnable;
                    fieldWithCondition.value = fieldWithCondition.defaultValue;
                }
                break;
            case 'CHKBOX':
                if (fieldWithCondition.depType === 'EXACT' && fieldDependedOn.value !== null && fieldDependedOn.value.toString() === fieldWithCondition.depValue) {
                    fieldWithCondition[propToToggle] = showOrEnable;
                    fieldWithCondition.value = fieldWithCondition.depSetValue;
                } else {
                    fieldWithCondition[propToToggle] = !showOrEnable;
                }
                break;
        }

        this.setClientInfoFormValue(clientInfoForm, fieldWithCondition, schedulerPreferenceFieldDefnList, reasonId);
    }

    checkDependencyFields(field: CustomField, schedulerPreferenceFieldDefnList: CustomField[], clientInfoForm: FormGroup, reasonId: number){
        if(field.otherFieldDepOnThisField){
            for (let i = 0, x = schedulerPreferenceFieldDefnList.length; i < x; i++) {
                if(schedulerPreferenceFieldDefnList[i].depFieldDefnIdObjs && schedulerPreferenceFieldDefnList[i].depFieldDefnIdObjs.length !== 0){
                    for(let j = 0, y = schedulerPreferenceFieldDefnList[i].depFieldDefnIdObjs.length; j < y; j++){
                        if(schedulerPreferenceFieldDefnList[i].depFieldDefnIdObjs[j].schedulerPreferenceFieldDefnId === field.schedulerPreferenceFieldDefnId){
                            this.setUpDependencyFields(schedulerPreferenceFieldDefnList[i], field, 'value', schedulerPreferenceFieldDefnList[i].depFieldDefnIdObjs[j].depValueArray, clientInfoForm, schedulerPreferenceFieldDefnList, reasonId);
                        }
                    }
                }
            }
        }
    }

    isValidRegex(validationRegex): boolean{
        let isValid = true;
        try {
            new RegExp(validationRegex);
        } catch(e) {
            isValid = false;
        }
        return isValid;
    }

    isValidJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    getField(customFields: CustomField[], code: string): CustomField {
        let field = null;
        for (let i = 0, x = customFields.length; i < x; i++)
            if (customFields[i].code === code)
                field = customFields[i];
        return field;

    }

    checkContainsConfirmEmail(customFields: CustomField[]): boolean {
        let containsConfirmEmail = false;
        for (let i = 0, x = customFields.length; i < x; i++)
            if (customFields[i].showOnScheduler && customFields[i].code === 'confirmEmailAddress')
                containsConfirmEmail = true;
        return containsConfirmEmail;
    }

    matchingEmails = function(emailFieldId: string, confirmEmailFieldId: string) {
        return (group: FormGroup): {[key: string]: any} => {
            let emailField = group.controls[emailFieldId];
            let confirmEmailField = group.controls[confirmEmailFieldId];

            if (emailField.value !== confirmEmailField.value) {
                return {
                    confirmEmailMismatch: true
                };
            }
        }
    }

    printPreferenceId(schedulerPreferenceFieldDefnList: CustomField[], id: number) {
        for (let i = 0, x = schedulerPreferenceFieldDefnList.length; i < x; i++)
            if (schedulerPreferenceFieldDefnList[i].schedulerPreferenceFieldDefnId === id) {
                console.log('schedulerPreferenceFieldDefnList ' + id + JSON.stringify(schedulerPreferenceFieldDefnList[i]));
            }

    }

    createValidatorsArray(field: CustomField){
        let validatorsArray = [];
        if(field.required){
            validatorsArray.push(Validators.required);
        }
        if(field.inputType === 'email'){
            let emailRegexExpression = "[^ @]*@[^ @]*";
            validatorsArray.push(Validators.pattern(emailRegexExpression));
        }

        if(field.inputType === 'url'){
            let urlRegexExpression = '^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$';
            validatorsArray.push(Validators.pattern(urlRegexExpression));
        }
        if(field.validationRegex !== null && field.dataType !== "FILEUPLOAD"){
            if(this.isValidRegex(field.validationRegex)) {
                validatorsArray.push(Validators.pattern(field.validationRegex));
            }
        }
        return validatorsArray;
    }

    initialConfigurationOfSchedulerFields(originalFieldDefnList: CustomField[], preference?: preference, fieldsFromFirstBooking?: CustomField[], waitListOnly?: boolean){
        let fieldDefnList: CustomField[] = _.cloneDeep(originalFieldDefnList);
        if(fieldsFromFirstBooking){ // Fix for TT-6691
            for(let i = 0, x = fieldsFromFirstBooking.length; i < x; i++){
                if(fieldsFromFirstBooking[i].mode === 'CLIENT' && fieldsFromFirstBooking[i].value !== null){
                    for(let j = 0, y = fieldDefnList.length; j < y; j++){
                        if(fieldsFromFirstBooking[i].schedulerPreferenceFieldDefnId === fieldDefnList[j].schedulerPreferenceFieldDefnId){
                            fieldDefnList[j].value = fieldsFromFirstBooking[i].value;
                        }
                    }
                }
            }
        }
        if(preference !== undefined && preference.maxAdditionalGuests > 0){
            let additionalGuestCustomField: CustomField = this.createAdditionalGuestsDropdown(preference);
            fieldDefnList.push(additionalGuestCustomField);
        }
        //first remove any of the fields that are not visible at all:
        for(let i = 0; i < fieldDefnList.length; i++){
            if(fieldDefnList[i].visible === false){
                fieldDefnList.splice(i,1);
                i--;
            }
        }
        for(let i = 0; i < fieldDefnList.length; i++){//INITIAL CUSTOM FIELD SET UP
            if(preference !== undefined && preference.askClientSMSRemind && fieldDefnList[i].code === 'cellPhone' && fieldDefnList[i].visibleOnScheduler){
                let sendTextMessageReminderCustomField: CustomField = this.createSendTextMessageReminderCustomField(preference, fieldDefnList[i].schedulerPreferenceFieldDefnId, waitListOnly);
                fieldDefnList.splice(i + 1, 0, sendTextMessageReminderCustomField);
            }
            if(preference !== undefined && fieldDefnList[i].code === 'sendTextReminder' && !waitListOnly){
                //ADD SEND TEXT MESSAGE HOURS DROPDOWN TO FORM OBJECT
                let sendTextMessageReminderHrsCustomField: CustomField = this.createSendTextMessageReminderHrsCustomField(preference, fieldDefnList[i].schedulerPreferenceFieldDefnId);
                if (sendTextMessageReminderHrsCustomField)
                    fieldDefnList.splice(i + 1, 0, sendTextMessageReminderHrsCustomField);
            }
            if(fieldDefnList[i].visible && fieldDefnList[i].visibleOnScheduler){
                fieldDefnList[i].showOnScheduler = true;
                fieldDefnList[i].enable = true;
            }
            if(fieldDefnList[i].defaultValue && !fieldDefnList[i].value)
                fieldDefnList[i].value = fieldDefnList[i].defaultValue;
            switch(fieldDefnList[i].dataType){
                case 'TXT':
                    switch(fieldDefnList[i].code){
                        case 'cellPhone':
                        case 'homePhone':
                        case 'businessPhone':
                            fieldDefnList[i].inputType = 'phone';
                            break;
                        case 'emailAddress':
                            fieldDefnList[i].inputType = 'email';
                            break;
                        case 'password':
                            fieldDefnList[i].inputType = 'password';
                            break;
                        default:
                            fieldDefnList[i].inputType = 'text';
                    }
                    break;
                case 'LIST':
                    if(fieldDefnList[i].fieldValues !== null){
                        fieldDefnList[i].dropdownValues = fieldDefnList[i].fieldValues.split(/\n/);
                    }
                    break;
                case 'LIST_CHKBOX':
                    if(fieldDefnList[i].fieldValues !== null){
                        let dropdownValues = fieldDefnList[i].fieldValues.split(/\n/);
                        fieldDefnList[i].dropdownValues = [];
                        for(let a = 0, x = dropdownValues.length; a < x; a++){
                            if (dropdownValues[a])
                                dropdownValues[a] = dropdownValues[a].trim();
                            let chkBoxObject = {label: dropdownValues[a], value: false};
                            fieldDefnList[i].dropdownValues.push(chkBoxObject);
                        }
                    }
                    if(fieldDefnList[i].value !== null){//Prechecking the check boxes for the values already selected
                        let checkedValuesArray = this.formFunctionsService.getCheckedValues(fieldDefnList[i].value);
                        if(fieldDefnList[i].dropdownValues !== undefined){
                            for(let a = 0, x =  fieldDefnList[i].dropdownValues.length; a < x; a++){
                                //GIVE EACH CHECKBOX VALUE A NAME CONVENTION FOR MODEL STORAGE
                                if(checkedValuesArray.indexOf(fieldDefnList[i].dropdownValues[a].label) !== -1){
                                    fieldDefnList[i].dropdownValues[a].value = true;
                                }
                            }
                        }
                    }
                    break;
                case 'URL':
                    fieldDefnList[i].inputType = 'url';
                    break;
                case 'CHKBOX':
                    if(fieldDefnList[i].value !== null && typeof fieldDefnList[i].value === 'string'){
                        let fieldValue = fieldDefnList[i].value.toLowerCase();
                        if(fieldValue === 'false' || fieldValue === 'no'){
                            // @ts-ignore
                            fieldDefnList[i].value = false;
                        } else {
                            // @ts-ignore
                            fieldDefnList[i].value = true;
                        }
                    }
                    break;
                case 'FILEUPLOAD':
                    fieldDefnList[i].inputType = 'file';
                    break;
                case 'DATE':
                    if(fieldDefnList[i].requiredJSON !== null && this.isValidJson(fieldDefnList[i].requiredJSON)){
                        fieldDefnList[i].requiredObj = JSON.parse(fieldDefnList[i].requiredJSON);
                    }
                    break;
            }
            if(!fieldDefnList[i].depFieldDefnIdObjs)
                fieldDefnList[i].depFieldDefnIdObjs = [];
            if(!fieldDefnList[i].depFieldDefnIds)
                fieldDefnList[i].depFieldDefnIds = [];
            if(fieldDefnList[i].depType === 'JSON' && fieldDefnList[i].depCondition !== null){
                let depConditionJson = JSON.parse(fieldDefnList[i].depCondition);
                if(depConditionJson.AND !== undefined){
                    fieldDefnList[i].depAndOr = 'AND';
                } else if(depConditionJson.NOR !== undefined){
                    fieldDefnList[i].depAndOr = 'NOR';
                } else {
                    fieldDefnList[i].depAndOr = 'OR';
                }
                let conditionsFieldDepOnArray = depConditionJson[fieldDefnList[i].depAndOr];
                for(let j = 0, y = conditionsFieldDepOnArray.length; j < y; j++){
                    for(let prop in conditionsFieldDepOnArray[j]){
                        let defIdNumber = Number(prop);
                        if(fieldDefnList[i].depFieldDefnIds && fieldDefnList[i].depFieldDefnIds.indexOf(defIdNumber)===-1){
                            fieldDefnList[i].depFieldDefnIds.push(defIdNumber);
                            let depFieldDefnIdObj: DepFieldDefnIdObj = {schedulerPreferenceFieldDefnId: defIdNumber, depValueArray: [conditionsFieldDepOnArray[j][prop]]};
                            fieldDefnList[i].depFieldDefnIdObjs.push(depFieldDefnIdObj);
                        } else if (fieldDefnList[i].depFieldDefnIdObjs) {
                            for(let k = 0, z = fieldDefnList[i].depFieldDefnIdObjs.length; k < z; k++){
                                if(fieldDefnList[i].depFieldDefnIdObjs[k].schedulerPreferenceFieldDefnId === defIdNumber){
                                    fieldDefnList[i].depFieldDefnIdObjs[k].depValueArray.push(conditionsFieldDepOnArray[j][prop])
                                }
                            }
                        }


                    }
                }
            } else if(fieldDefnList[i].depFieldDefnId !== null){
                let depFieldDefnIdObj: DepFieldDefnIdObj = {schedulerPreferenceFieldDefnId: fieldDefnList[i].depFieldDefnId, depValueArray: [fieldDefnList[i].depValue]};
                fieldDefnList[i].depFieldDefnIdObjs.push(depFieldDefnIdObj);
            }
            if(fieldDefnList[i].depFieldDefnIdObjs.length !== 0){
                for(let k = 0, z = fieldDefnList[i].depFieldDefnIdObjs.length; k < z; k++) {
                    for (let j = 0, y = fieldDefnList.length; j < y; j++) {
                        if (fieldDefnList[j].schedulerPreferenceFieldDefnId === fieldDefnList[i].depFieldDefnIdObjs[k].schedulerPreferenceFieldDefnId) {
                            this.setUpDependencyFields(fieldDefnList[i], fieldDefnList[j], 'defaultValue', fieldDefnList[i].depFieldDefnIdObjs[k].depValueArray, undefined, fieldDefnList, null);
                        }
                    }
                }

            }
        }
        if(preference && preference.askClientSMSRemind && !this.responsiveService.onMobileViewPort){//NEED TO REVERSE ORDER OF THE sendTextReminder AND sendTextReminderHrs FIELDS SO FLOAT IS PROPER ONLY ON LARGE SCREEN SIZES
            let positionOfSentTextReminder = null, positionOfSendTextReminderHrs = null;
            for(let i = 0, x = fieldDefnList.length; i < x; i++){
                if(fieldDefnList[i].code === 'sendTextReminder'){
                    positionOfSentTextReminder = i;
                } else if(fieldDefnList[i].code === 'sendTextReminderHrs'){
                    positionOfSendTextReminderHrs = i;
                }
            }
            let temp = fieldDefnList[positionOfSentTextReminder];
            if (positionOfSendTextReminderHrs) {
                fieldDefnList[positionOfSentTextReminder] = fieldDefnList[positionOfSendTextReminderHrs];
                fieldDefnList[positionOfSendTextReminderHrs] = temp;
            } else {
                fieldDefnList[positionOfSentTextReminder] = temp;
            }
        }
        return fieldDefnList;
    }



}
