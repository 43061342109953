import {Component, OnDestroy, OnInit} from '@angular/core';
import {DisclaimerFormService} from "./disclaimer-form.service";
import {ActivatedRoute} from "@angular/router";
import {DisclaimerForm, DisclaimerFormData} from "./disclaimer-form.model";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-disclaimer-form',
  templateUrl: './disclaimer-form.component.html'
})
export class DisclaimerFormComponent implements OnInit {

  constructor(private disclaimerFormService: DisclaimerFormService, private route: ActivatedRoute) { }

  ngOnInit() {

  }


}
