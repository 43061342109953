import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {BusinessService} from "../business.service";
import {
    CSFilterParameterBean,
    loginRegisterPopUpObject,
    miniWebsite,
    movePanels,
    MultipleAppts,
    preference,
    schedulerLink
} from "../common-classes/app-objects.model";
import {MatDialog} from "@angular/material";
import {AuthService} from "../auth/auth.service";
import {ClientAccountService} from "../client-account/client-account.service";
import {ErrorModalComponent} from "../modals/error-modal/error-modal.component";
import {Subscription} from "rxjs";
import {ResponsiveService} from "../responsive.service";
import {ClientInfoService} from "./client-info/client-info.service";
import {SchedulerService} from './scheduler.service';
import {WaitList} from '../../models/WaitList.model';
import {Appointment} from '../../models/Appointment.model';
import {SessionService} from '../session.service';
import {SchedulerLinkService} from '../scheduler-link.service';
import {SchedulerPreferenceService} from '../scheduler-preference.service';
import {MiniWebsiteService} from '../mini-website.service';
import {schedulerPanel, SchedulerPanelValues} from '../../models/SchedulerPanel.model';
import {TimeService} from './time/time.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html'
})
export class SchedulerComponent implements OnInit, OnDestroy, AfterViewChecked {
    isAuthenticated: boolean;
    authenticationSubscription: Subscription;
    loginClosedSubscription: Subscription;
    registerClosedSubscription: Subscription;
    isEmbedded: boolean;
    errorMessage: string;
    dataLoaded = false;
    errorLoading = false;
    schedulerLink: schedulerLink;
    preference: preference;
    miniWebsite: miniWebsite;
    panels: schedulerPanel[] = [];
    inputValues: any = [];
    panelsOrdered: boolean = false;
    clickedFromMyAccount: boolean = false;
    directionComingFrom: string = 'next';
    appt: Appointment;
    multipleAppts: MultipleAppts;
    waitLists: WaitList[];
    currentPanel: schedulerPanel;
    welcomePanel: schedulerPanel;
    locationSuperGroupPanel: schedulerPanel;
    locationGroupPanel: schedulerPanel;
    locationPanel: schedulerPanel;
    staffPanel: schedulerPanel;
    reasonSuperGroupPanel: schedulerPanel;
    reasonGroupPanel: schedulerPanel;
    reasonPanel: schedulerPanel;
    timePanel: schedulerPanel;
    clientInfoPanel: schedulerPanel;
    finalConfirmationPanel: schedulerPanel;
    allPanels: any = this.schedulerService.getAllPanels();
    CSFilterObj: CSFilterParameterBean;
    panelValues = SchedulerPanelValues;
    noAppointmentsAvailable: boolean = false;
    isSandboxAccount: boolean = false;
    showAppointmentIntentPanel: boolean = false;
    appointmentIntentPanel: schedulerPanel;
    constructor(private businessService: BusinessService, private _formBuilder: FormBuilder, private dialog: MatDialog, private responsiveService: ResponsiveService,
                private sessionService: SessionService, private schedulerLinkService: SchedulerLinkService, private schedulerPreferenceService: SchedulerPreferenceService,
                private miniWebsiteService: MiniWebsiteService, private timeService: TimeService,
                private authService: AuthService, private clientAccountService: ClientAccountService, private clientInfoService: ClientInfoService, private schedulerService: SchedulerService, private router: Router) { }

    ngAfterViewChecked(): void {
        this.responsiveService.setHeightForEmbedScheduler();
    }



    resetClientInfoForm(reasonId?: number, reasonType?: string){
        for(let k = 0, z = this.panels.length; k < z; k++){
            if(this.panels[k].panel === SchedulerPanelValues.clientInfo){
                // this.clientInfoService.printPreferenceId(this.preference.schedulerPreferenceFieldDefnList, 27523);
                this.panels[k].formGroup = this.schedulerService.getPanelForm(SchedulerPanelValues.clientInfo, this.preference.schedulerPreferenceFieldDefnList, reasonId, reasonType)
                // this.clientInfoService.printPreferenceId(this.preference.schedulerPreferenceFieldDefnList, 27523);
            }
        }
    }

    clearTimePanelForm() {
        this.timePanel.formGroup = new FormGroup({//Clear selected times from previous booking
            'selectedDateTime': this.schedulerService.getTimeSlotFormGroup()});
    }

    movePanels(moveData: movePanels){
        if(moveData.noAppointmentAvail)
            this.noAppointmentsAvailable = moveData.noAppointmentAvail;
        else
            this.noAppointmentsAvailable = false;
        this.directionComingFrom = moveData.direction;
        for(let i = 0, x = this.panels.length; i < x; i++){
            if(this.panels[i].panel === moveData.panel.panel){
                if(moveData.direction === 'skip' && moveData.panel.panel === SchedulerPanelValues.appointmentIntent){
                    this.currentPanel = this.panels[i + 1];
                }
                else if(moveData.direction === 'next' &&
                    ((moveData.panel.panel !== SchedulerPanelValues.clientInfo && moveData.panel.formGroup.valid) ||
                        (moveData.panel.panel === SchedulerPanelValues.appointmentIntent) ||
                        (moveData.panel.panel === SchedulerPanelValues.clientInfo && this.clientInfoService.isClientInfoFormValid(moveData.panel.formGroup, this.preference.schedulerPreferenceFieldDefnList)))){
                    if(this.panels[i].hasSelectedValues)
                        this.inputValues = this.schedulerService.arrangeInputValues(this.inputValues, moveData.panel.formGroup.value, i, this.panels, this.CSFilterObj);
                    if(moveData.panel.panel === SchedulerPanelValues.reasons){//Fix for TT-6153 -- after select service, update form on clientInfo panel based on selection
                        for(let j = 0, y = this.inputValues.length; j < y; j++){
                            if(this.inputValues[j].panel === SchedulerPanelValues.reasons){
                               this.resetClientInfoForm(this.inputValues[j].values[0].value.reasonId, this.inputValues[j].values[0].value.reasonType);
                            }
                        }

                    }
                    this.appt = moveData.appt;
                    this.multipleAppts = moveData.multipleAppts;
                    this.waitLists = moveData.waitLists;

                    if(i === x - 1){//THEY ARE ON THE LAST PANEL SO HAVE CLICKED THE BOOK ANOTHER APPT BTN
                        this.clearTimePanelForm();
                        this.preference.schedulerPreferenceFieldDefnList = this.clientInfoService.initialConfigurationOfSchedulerFields(this.preference.schedulerPreferenceFieldDefnListOriginal, this.preference, this.preference.schedulerPreferenceFieldDefnList);
                        this.resetClientInfoForm();
                        this.CSFilterObj = this.schedulerService.newCSFilterObj();
                        let panelIndexToAdvanceTo = 1;
                        if(this.preference.onlyAllowBookingByInvitation ||
                            (this.schedulerLink.skipWelcome && this.preference.maxActiveApptClient === -1) || (!this.schedulerLink.skipWelcome && this.preference.maxActiveApptClient !== -1)){
                            panelIndexToAdvanceTo = 0;
                        } else if(this.schedulerLink.skipWelcome && this.preference.maxActiveApptClient !== -1){
                            panelIndexToAdvanceTo = -1;
                        }
                        if(this.clientAccountService.loggedInClient && this.preference.maxActiveApptClient !== -1){
                            this.getActiveApptCountForClient(panelIndexToAdvanceTo);
                        } else {
                            panelIndexToAdvanceTo = 0; // LHB 09/24/2021 TT-8182
                            this.currentPanel = this.panels[panelIndexToAdvanceTo];
                            this.inputValues = [];//TT-6010
                        }
                    } else if(moveData.panel.panel === SchedulerPanelValues.clientInfo){//NEXT PANEL IS FINAL CONFIRMATION SO NEED TO BE SURE TO SHOW THE RIGHT ONE; BY DEFAULT IT IS THE FINALCONFIRMATION PANEL FROM ALLPANELS ARRAY
                        this.currentPanel = this.panels[i + 1];
                    } else if(moveData.panel.panel === this.panels[0].panel || //They are on the first page of the scheduling flow; typically it is panel='welcome', but could have skipped welcome so need to check if it is the first panel in the list of all panels
                        (this.clickedFromMyAccount && moveData.panel.panel === this.panels[1].panel)){ // LHB 2/19/2021 TT-7417 they are on the first page of the scheduling flow after having clicked the book appt btn from the My Account view
                        this.clickedFromMyAccount = false;
                        if((this.preference.loginReq === 1 || this.preference.loginReq === 2 || this.preference.booleanMap.appointmentIntentRequired) && !this.isAuthenticated && !this.clientAccountService.loggedInClient) {//SCHEDULER REQUIRES LOGIN BEFORE MOVING ON SO CHECK AND IF NOT LOGGED IN SHOW LOGIN POP UP)
                            let loginTriggeredData: loginRegisterPopUpObject = new loginRegisterPopUpObject();
                            loginTriggeredData.moveData = moveData;
                            loginTriggeredData.index = i;
                            loginTriggeredData.loadMyAccount = false;
                            loginTriggeredData.calledFrom = 'SCHEDULER';
                            loginTriggeredData.callSubscriptionNext = true;
                            this.authService.loginCalled.next(loginTriggeredData);
                        } else if(this.clientAccountService.loggedInClient && this.preference.maxActiveApptClient !== -1){
                            this.getActiveApptCountForClient(i);
                        } else {
                            this.currentPanel = this.panels[i + 1];
                        }
                    } else {
                        if(this.panels[i + 1].panel === SchedulerPanelValues.appointmentIntent) {
                            if (this.schedulerLink.booleanMap.appointmentIntentRequired
                                || this.CSFilterObj.reasonRequiresIntent) {
                                    this.currentPanel = this.panels[i + 1];
                            } else {
                                this.currentPanel = this.panels[i + 2];
                            }
                        } else {
                            this.currentPanel = this.panels[i + 1];
                        }
                    }
                } else if(moveData.direction === 'back' && moveData.panel.panelOrder > 0){
                    if(this.panels[i - 1].panel === SchedulerPanelValues.appointmentIntent) {
                        if (this.schedulerLink.booleanMap.appointmentIntentRequired
                            || this.CSFilterObj.reasonRequiresIntent) {
                            this.currentPanel = this.panels[i - 1];
                        } else {
                            this.currentPanel = this.panels[i - 2];
                        }
                    } else {
                        this.currentPanel = this.panels[i - 1];
                    }
                }
                if (this.currentPanel.recordNavigatePanel)
                    this.businessService.recordAuditTrailNavigation(this.currentPanel.recordNavigatePanel).subscribe(() => {});

                if(!this.isEmbedded) {
                    //scroll to top of container
                    let el = document.getElementById('schedulerContainerRow');
                    el.scrollIntoView({behavior: 'smooth'});
                }
            }
        }
    }

    getActiveApptCountForClient(currentPanelIndex: number) {
        this.businessService.getActiveApptCount(this.clientAccountService.loggedInClient.businessId, this.clientAccountService.loggedInClient.clientList)
            .subscribe(clientApptCountMap => {
                for(let i = 0, x = this.clientAccountService.loggedInClient.clientList.length; i < x; i++){
                    for(let j = 0, y = clientApptCountMap.length; j < y; j++){
                        for(let prop in clientApptCountMap[j]){
                            if(Number(prop) === this.clientAccountService.loggedInClient.clientList[i].clientId){
                                this.clientAccountService.loggedInClient.clientList[i].activeApptCount = clientApptCountMap[j][prop];
                            }
                        }
                    }
                }
                let allowedToBook = false;
                for(let i = 0, x = this.clientAccountService.loggedInClient.clientList.length; i < x; i++){
                    if(this.clientAccountService.loggedInClient.clientList[i].activeApptCount < this.preference.maxActiveApptClient){
                        allowedToBook = true;
                    }
                }
                if(allowedToBook){
                    this.currentPanel = this.panels[currentPanelIndex + 1];
                } else {
                    this.currentPanel = this.panels[currentPanelIndex];
                    const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(), data: {preference: this.preference, errorType: 'maxApptsReached'}})
                }
                this.dataLoaded = true;
            }, error => {
                // console.log(error);
            })
    }

    orderPanels(appMode: number, skipWelcome: boolean, includeReasonGroups: boolean, includeReasonSuperGroups: boolean){
        let panelsToShow: string[] = this.schedulerService.getPanelOrder(appMode, includeReasonGroups, includeReasonSuperGroups);
        this.panels = this.schedulerService.configurePanels(skipWelcome, panelsToShow, this.showAppointmentIntentPanel);
        for(let i = 0, x = this.panels.length; i < x; i++){
            switch(this.panels[i].panel){
                case SchedulerPanelValues.welcome:
                    this.welcomePanel = this.panels[i];
                    break;
                case SchedulerPanelValues.locationSuperGroups:
                    this.locationSuperGroupPanel = this.panels[i];
                    break;
                case SchedulerPanelValues.locationGroups:
                    this.locationGroupPanel = this.panels[i];
                    break;
                case SchedulerPanelValues.locations:
                    this.locationPanel = this.panels[i];
                    break;
                case SchedulerPanelValues.staff:
                    this.staffPanel = this.panels[i];
                    break;
                case SchedulerPanelValues.reasonSuperGroups:
                    this.reasonSuperGroupPanel = this.panels[i];
                    break;
                case SchedulerPanelValues.reasonGroups:
                    this.reasonGroupPanel = this.panels[i];
                    break;
                case SchedulerPanelValues.reasons:
                    this.reasonPanel = this.panels[i];
                    break;
                case SchedulerPanelValues.appointmentIntent:
                    this.appointmentIntentPanel = this.panels[i];
                    break;
                case SchedulerPanelValues.time:
                    this.timePanel = this.panels[i];
                    break;
                case SchedulerPanelValues.clientInfo:
                    this.clientInfoPanel = this.panels[i];
                    break;
                case SchedulerPanelValues.finalConfirmation:
                    this.finalConfirmationPanel = this.panels[i];
                    break;

            }
        }
        let initialIndex = 0;
        // LHB 07/17/2020 TT-6840 -- if click book appointment from top nav or from button on client profile then load first page in scheduling flow
        if(!skipWelcome && history.state && this.preference.maxActiveApptClient === -1 &&
            ((history.state.navigationLink && history.state.navigationLink) ||
            (history.state.onProfileHome && history.state.onProfileHome))) {
            if(this.preference.maxActiveApptClient !== -1){
                this.getActiveApptCountForClient(initialIndex);
            } else {
                initialIndex = 1;
                this.currentPanel = this.panels[initialIndex];
                this.dataLoaded = true;
            }
        } else {
            this.currentPanel = this.panels[initialIndex];
            this.dataLoaded = true;
        }
    }

    loginRegisterClosed(moveData: movePanels, currentPanelIndex: number) {
        if(this.isAuthenticated && moveData !== undefined && currentPanelIndex !==  undefined){
            if(this.preference.maxActiveApptClient === -1){
                this.movePanels(moveData);
            } else {
                this.getActiveApptCountForClient(currentPanelIndex);
            }
        }
    }

    ngOnInit() {
        if(window.location.href.indexOf('businessWeb/web/manage') !== -1) {
            let apptHash = sessionStorage.getItem('apptHash');
            if (apptHash !== undefined && apptHash !== null && apptHash !== '') {
                this.router.navigate(['appts/' + apptHash]);
            }
        }
      this.isEmbedded = this.responsiveService.isEmbedded;
      this.isSandboxAccount = this.businessService.isSandboxAccount;
      if(this.businessService.business){
          if(this.businessService.business.status !== 'ACTIVE'){
              this.dataLoaded = false;
              this.errorLoading = true;
              // this.miniWebsite = this.businessService.createErrorMiniWebsite('inactive');
              this.panels.push(this.allPanels.welcomePanel);
              this.currentPanel = this.panels[0];
          } else {
              // this.dataLoaded = true;
              this.errorLoading = false;
              this.schedulerLink = this.schedulerLinkService.schedulerLink;
              this.preference = this.schedulerPreferenceService.schedulerPreference;
              this.miniWebsite = this.miniWebsiteService.miniWebsite;
              this.CSFilterObj = this.schedulerService.newCSFilterObj();

              if(this.preference.onlyAllowBookingByInvitation && !this.sessionService.sessionObject.parmInvitationUUId)
                  this.preference.skipWelcome = false;

              // determine whether to show appointment intent panel
              this.showAppointmentIntentPanel = this.businessService.appointmentIntentAllowed || false;

              if(this.showAppointmentIntentPanel && history.state.apptintentid){
                  sessionStorage.setItem("appointmentIntentId", history.state.apptintentid);
              }
              this.orderPanels(this.preference.appMode, this.preference.skipWelcome, this.preference.showServiceGroup, this.preference.showServiceSuperGroup);
          }
      } else if(!this.businessService.business){
          this.dataLoaded = false;
          this.errorLoading = true;
      } else {
          this.businessService.landingPageObjectLoaded.subscribe(didLoad => {
              this.dataLoaded = true;
              this.errorLoading = false;
              this.schedulerLink = this.schedulerLinkService.schedulerLink;
              this.preference = this.schedulerPreferenceService.schedulerPreference;
              this.miniWebsite = this.miniWebsiteService.miniWebsite;
          })
      }
      this.isAuthenticated = this.authService.isAuth();
      this.authenticationSubscription = this.authService.authChange.subscribe(isAuth => {
          this.isAuthenticated = this.authService.isAuth();
          if(isAuth === false){
              this.preference.schedulerPreferenceFieldDefnList = this.clientInfoService.initialConfigurationOfSchedulerFields(this.preference.schedulerPreferenceFieldDefnListOriginal, this.preference);
              if(this.preference.loginReq === 2 || this.preference.loginReq === 1)
                  this.currentPanel = this.panels[0];
          }
      });
      this.loginClosedSubscription = this.authService.loginClosed.subscribe((loginTriggeredData: loginRegisterPopUpObject) => {
          this.loginRegisterClosed(loginTriggeredData.moveData, loginTriggeredData.index);
      });
      this.registerClosedSubscription = this.authService.registerClosed.subscribe((loginTriggeredData: loginRegisterPopUpObject) => {
          this.loginRegisterClosed(loginTriggeredData.moveData, loginTriggeredData.index);
      })

    }

    ngOnDestroy(): void {
        if (this.authenticationSubscription)
            this.authenticationSubscription.unsubscribe();
        if (this.loginClosedSubscription)
            this.loginClosedSubscription.unsubscribe();
        if (this.registerClosedSubscription)
            this.registerClosedSubscription.unsubscribe();
    }

}
