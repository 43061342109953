import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {BusinessService} from "../../business.service";
import {loginRegisterPopUpObject, miniWebsite, preference} from "../../common-classes/app-objects.model";
import {LoginComponent} from "../../auth/login/login.component";
import {RegisterComponent} from "../../auth/register/register.component";
import {MatDialog} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ResponsiveService} from "../../responsive.service";
import {StoreService} from "../../store/store.service";
import {MiniWebsiteDisplayConfig} from "../../common-classes/default-styles.model";
import {ResetPasswordComponent, ResetPasswordModalData} from "../../auth/reset-password/reset-password.component";

@Component({
  selector: 'login-register-btns',
  templateUrl: './login-register-btns.component.html',
  styles: ['#cartBtn {line-height: 33px}']
})
export class LoginRegisterBtnsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() preference: preference;
  @Input() miniWebsite: miniWebsite;
  @Input() parentComponent: string;
  displayConfig: MiniWebsiteDisplayConfig;
  authSubscription: Subscription;
  loginCalledSubscription: Subscription;
  registerCalledSubscription: Subscription;
  addConnectedClientSubscription: Subscription;
  logoutCalledSubscription: Subscription;
  cartSubscription: Subscription;
  paramsSubscription: Subscription;
  isAuthenticated: boolean;
  showLoginRegisterBtns: boolean = false;
  showLoginBtn: boolean;
  showRegisterBtn: boolean;
  cartQuantity: number = 0;

  constructor(private businessService: BusinessService, private authService: AuthService, private dialog: MatDialog, private cdRef: ChangeDetectorRef,
              private router: Router, private route: ActivatedRoute, private responsiveService: ResponsiveService, private storeService: StoreService) { }

  ngAfterViewInit(): void {
    if(this.miniWebsite.displayConfig.store) {
        this.storeService.loadCart(this.miniWebsite.businessId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.preference !== undefined && changes.preference.currentValue !== undefined && changes.preference.previousValue === undefined){
      //if(this.parentComponent === 'app-header' || (this.parentComponent !== 'app-header' && this.responsiveService.isEmbedded)){
      let showBtns = this.responsiveService.showLoginRegisterBtnLogic(this.preference);
      this.showLoginBtn = showBtns.showLoginBtn && this.businessService.business.acceptOnlineAppointments;
      this.showRegisterBtn = showBtns.showRegisterBtn && this.businessService.business.acceptOnlineAppointments;
      if(this.showLoginBtn || this.showRegisterBtn){
        this.showLoginRegisterBtns = true;
        this.isAuthenticated = this.authService.isAuth();
      }
      //}
    }

  }

  ngOnInit() {
    this.paramsSubscription = this.route.queryParams.subscribe((params: Params) => {
      let register: string = params["register"];
      if(register !== undefined && register !== null && register.toLowerCase() === 'true'){
        this.register(false);
      }
    })
    this.displayConfig = this.miniWebsite.displayConfig;
    this.authSubscription = this.authService.authChange.subscribe(authStatus => {
      this.isAuthenticated = authStatus;
      if(!this.isAuthenticated && this.router.url.indexOf('my-account')!==-1){
        this.router.navigate(['scheduler']);
      }
    });
    this.loginCalledSubscription = this.authService.loginCalled.subscribe((loginTriggeredData: loginRegisterPopUpObject) => {
      this.login(loginTriggeredData.email, loginTriggeredData.description, loginTriggeredData);
    })
    this.registerCalledSubscription = this.authService.registerCalled.subscribe(resetPwd => {
      this.register(resetPwd);
    })
    this.addConnectedClientSubscription = this.authService.addConnectedClientCalled.subscribe((loginTriggeredData: loginRegisterPopUpObject) => {
      this.register(false, undefined, undefined, loginTriggeredData);
    })
    this.logoutCalledSubscription = this.authService.logoutCalled.subscribe(resetPwd => {
      this.logout();
    })
    this.cartSubscription = this.storeService.cartChange.subscribe((cartChange: any) => {
      this.cartQuantity = this.storeService.updateCart();
      this.cdRef.detectChanges();
    });

  }


  showCart(){
    this.router.navigate(['cart']);
  }

  login(email?: string, description?: string, loginTriggeredData?: loginRegisterPopUpObject){
    this.businessService.checkToggleSidenav();
    let loadMyAccount = true;
    if(loginTriggeredData && loginTriggeredData.loadMyAccount !== undefined)
      loadMyAccount = loginTriggeredData.loadMyAccount;
    const dialogRef = this.dialog.open(LoginComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(), width: this.responsiveService.getMaxWidthForModals(),
      data: {preference: this.preference, email: email, description: description, loadMyAccount: loadMyAccount}});
    dialogRef.afterClosed().subscribe((loginModalObj: any) => {
      if(loginModalObj!== undefined) {
        this.isAuthenticated = loginModalObj.isAuthenticated;
        if (loginModalObj.resetPwd || loginModalObj.needAccount) {
          this.register(loginModalObj.resetPwd, loginModalObj.email, loginModalObj.needAccount, loginTriggeredData, loginModalObj.accountLocked);
        } else if (loginModalObj.forcePasswordReset) {
          this.forcePasswordReset(loginModalObj.email);
        } else if(loginTriggeredData !== undefined && loginTriggeredData.callSubscriptionNext){
          this.authService.loginClosed.next(loginTriggeredData);
        }
      }
    });
  }
  register(resetPwd: boolean, email?: string, needAccount?: boolean, loginTriggeredData?: loginRegisterPopUpObject, accountLocked?: boolean){
    this.businessService.checkToggleSidenav();
    const regDialogRef = this.dialog.open(RegisterComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(), width: this.responsiveService.getMaxWidthForModals(),
      data: {preference: this.preference, resetPwd: resetPwd, email: email, needAccount: needAccount, loginRegisterPopUpObject: loginTriggeredData, accountLocked: accountLocked}});
    regDialogRef.afterClosed().subscribe((registerModalObj: any) => {
      if(registerModalObj !== undefined) {
        this.isAuthenticated = registerModalObj.isAuthenticated;
        if (registerModalObj.openLogin) {
          this.login(registerModalObj.email, registerModalObj.descriptionToPassToLogin);
        } else if(registerModalObj.isConnectClient){
          this.authService.connectedClientClosed.next(registerModalObj.newClient);
        } else if(loginTriggeredData !== undefined && loginTriggeredData.moveData !== undefined){//was called from scheduler movePanels method
          this.authService.registerClosed.next(loginTriggeredData);
        }
      }
    });
  }

  forcePasswordReset(email: string) {
    const dataToPass: ResetPasswordModalData = new ResetPasswordModalData();
    dataToPass.skipPasswordRequestCheck = true;
    dataToPass.email = email;
    dataToPass.errorMsg = 'Your password must be reset. New passwords must be at least 8 characters with a number, special, upper, and lower case letter.';
    const regDialogRef = this.dialog.open(ResetPasswordComponent, {
      maxWidth: this.responsiveService.getMaxWidthForModals(),
      width: this.responsiveService.getMaxWidthForModals(),
      data: dataToPass
    });
    regDialogRef.afterClosed().subscribe((registerModalObj: any) => {
      this.login(registerModalObj.email, 'Password successfully reset');
    });

  }
  logout(){
    this.businessService.checkToggleSidenav();
    this.authService.clientLogout()
      .subscribe(
          (response: string) => {
            this.authService.updateToken(response);
            this.authService.logoutSuccessfully();
          },
          error => {
            // console.log(error);
          }
      );
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    this.loginCalledSubscription.unsubscribe();
    this.registerCalledSubscription.unsubscribe();
    this.addConnectedClientSubscription.unsubscribe();
    this.logoutCalledSubscription.unsubscribe();
    this.cartSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
    // this.innerWidthSubscription.unsubscribe();
  }

}
