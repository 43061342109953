import {Component, EventEmitter, Input, Output, OnInit, AfterViewChecked} from '@angular/core';
import {
  currency,
  preference
} from "../../../common-classes/app-objects.model";
import {ResponsiveService} from "../../../responsive.service";
import {MatOptionSelectionChange} from "@angular/material/core";
import {Reason, ReasonDuration, RepeatingApptOption} from '../../../../models/Reason.model';

@Component({
  selector: 'reason-chip-list',
  templateUrl: './reason-chip-list.component.html',
  styleUrls: ['./reason-chip-list.component.css']
})
export class ReasonChipListComponent implements OnInit, AfterViewChecked {
  @Input() preference: preference;
  @Input() reason: Reason;
  @Input() currency: currency;
  @Input() pullRight: boolean;
  @Output() selectObject = new EventEmitter<{event: MatOptionSelectionChange, reason: Reason}>();

  constructor(public responsiveService: ResponsiveService) { }

  ngOnInit() {
  }

  ngAfterViewChecked(): void {
    this.responsiveService.checkMatOptionWidth(".durationDropdown","#schedulerContainerRow");
  }

  setReasonDuration(event: MatOptionSelectionChange, duration: ReasonDuration, reason: Reason){
        reason.selectedDuration = duration;
        this.selectObject.emit({event: event, reason: reason});
  }
  setRepeatingApptOption(event: MatOptionSelectionChange, duration: RepeatingApptOption, reason: Reason){
    reason.selectedRepeatingApptOption = duration;
    this.selectObject.emit({event: event, reason: reason});
  }

}
