import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationMapComponent } from './location-map.component';
import {AgmCoreModule} from '@agm/core';
import {PipesModule} from '../../../../pipes/pipes.module';
import {UIElementsModule} from '../../../ui-elements/ui-elements.module';
import {MaterialModule} from '../../../material.module';



@NgModule({
  declarations: [LocationMapComponent],
  imports: [
    CommonModule,
    AgmCoreModule,
    PipesModule,
      UIElementsModule,
      MaterialModule
  ],
  exports: [LocationMapComponent]
})
export class LocationMapModule { }
