export class AvailabilityFilterData {
    address: string = null;
    businessTimeZone: string;
    clientId: number = null;
    clientTimeZone: string;
    duration: number = null;
    locationGroupIdList: number[] = null;
    locationIdList: number[] = null;
    locationSuperGroupIdList: number[] = null;
    reasonGroupIdList: number[] = null;
    reasonIdList: number[] = null;
    reasonSuperGroupIdList: number[] = null;
    resourceId: number = null;
    showClientTimezone: boolean = null;
    staffIdList: number[] = null;
    waitListMode: boolean = null;
}
