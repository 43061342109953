import {NgModule} from "@angular/core";
import {ViewAppointmentsListComponent} from "./view-appointments-list.component";
import {AppointmentsTableComponent} from "./appointments-table/appointments-table.component";
import {RepeatingAppointmentsTableComponent} from "./repeating-appointments-table/repeating-appointments-table.component";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "../../../material.module";
import {PipesModule} from "../../../../pipes/pipes.module";
import {UIElementsModule} from "../../../ui-elements/ui-elements.module";
import {ClientAccountHeaderModule} from "../../client-account-header/client-account-header.module";
import { WaitlistRegistrationsTableComponent } from './waitlist-registrations-table/waitlist-registrations-table.component';

@NgModule({
    declarations: [
        ViewAppointmentsListComponent,
        AppointmentsTableComponent,
        RepeatingAppointmentsTableComponent,
        WaitlistRegistrationsTableComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        PipesModule,
        UIElementsModule,
        ClientAccountHeaderModule
    ],
    exports: [
        ViewAppointmentsListComponent,
        AppointmentsTableComponent,
        RepeatingAppointmentsTableComponent
    ]
})
export class ViewAppointmentsListModule{}