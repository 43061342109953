import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailabilityCalendarComponent } from './availability-calendar/availability-calendar.component';
import {MaterialModule} from '../material.module';
import {FormsModule} from '@angular/forms';
import {UIElementsModule} from '../ui-elements/ui-elements.module';
import { TimeslotMultiSelectComponent } from './timeslot-multi-select/timeslot-multi-select.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import { BookingObjectSelectorComponent } from './booking-object-selector/booking-object-selector.component';



@NgModule({
  declarations: [AvailabilityCalendarComponent, TimeslotMultiSelectComponent, TimePickerComponent, BookingObjectSelectorComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        UIElementsModule,
        TimepickerModule.forRoot()

    ],
  exports: [AvailabilityCalendarComponent, TimeslotMultiSelectComponent, TimePickerComponent, BookingObjectSelectorComponent]
})
export class BookingComponentsModule { }
