import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MainObjectTypes} from '../../../models/MainObjectTypes.model';
import {MonthAvailability, SingleDateAvailability} from '../../scheduler/time/time-objects.model';
import {CSFilterParameterBean, MonthDataObj, MonthDay, preference} from '../../common-classes/app-objects.model';
import {WaitList} from '../../../models/WaitList.model';
import {Subscription} from 'rxjs/index';
import {TimeService} from '../../scheduler/time/time.service';
import {FormatFunctionsService} from '../../format-functions.service';
import {JoinWaitListService} from '../../waitlist/join-wait-list/join-wait-list.service';
import {AvailabilityService} from '../availability.service';
import {AvailabilityFilterData} from '../../../models/AvailabilityFilterData.model';
import {Appointment} from '../../../models/Appointment.model';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';

@Component({
  selector: 'app-availability-calendar',
  templateUrl: './availability-calendar.component.html',
  styleUrls: ['./availability-calendar.component.css']
})
export class AvailabilityCalendarComponent implements OnInit, OnDestroy {
  calendarVisible: boolean = false;
  dateForDisplay: string | Date;
  selectedDate: SingleDateAvailability;
  originalDate: string | number | Date;
  @Input() objectType: MainObjectTypes.APPT | MainObjectTypes.WAIT_LIST;
  @Input() editing: boolean = false;
  @Input() CSFilterObj: CSFilterParameterBean;
  @Input() timezone: string;
  @Output() onSetDay = new EventEmitter<Appointment | WaitList>();
  mainObjectTypes =  MainObjectTypes;
  monthAvailability: MonthAvailability;
  monthAvailabilityLoaded: boolean = false;
  monthHasAvailability: boolean = false;
  currentDate: Date = new Date();
  activeYear: number = this.currentDate.getFullYear();
  activeMonth: number = this.currentDate.getMonth() + 1;
  activeDay: number = this.currentDate.getDate();
  monthDays: MonthDay[] = [];
  monthsData: MonthDataObj[] = this.timeService.getMonthsData(this.activeYear);
  weekdays = this.timeService.getWeekdayData();
  currentMonthData: MonthDataObj = this.monthsData[this.activeMonth - 1];
  calendarMonthTitle: string;
  dateChangedSubscription: Subscription;
  loading: boolean = true;
  waitlistMode: boolean = false;
  preference: preference;
  allowGetNextMonth: boolean = false;
  allowGetPrevMonth: boolean = false;
  constructor(private timeService: TimeService, private formatFunctionsService: FormatFunctionsService, private joinWaitListService: JoinWaitListService,
              private availabilityService: AvailabilityService, private schedulerPreferenceService: SchedulerPreferenceService) {
    this.preference = schedulerPreferenceService.schedulerPreference;
  }

  checkAllowClickNonAvailableDay(day: MonthDay) {
    const daySelected: SingleDateAvailability = new SingleDateAvailability(day.dayNumInMonth, this.monthAvailability.month - 1, this.monthAvailability.year);
    if (this.objectType === this.mainObjectTypes.WAIT_LIST && this.preference.booleanMap && this.preference.booleanMap.allowWaitListRegistrationOutsideOfAvailability) {
      if (daySelected.date >= this.availabilityService.minBookingDate && daySelected.date <= this.availabilityService.maxBookingDate)
        return true;
      else
        return false;
    } else
      return false;
  }

  setCalendarMonthTitle() {
    this.calendarMonthTitle = this.timeService.getMonthAbbrWithDatePipe(this.currentMonthData.startDateOfMonth) + ' ' + this.timeService.getPipeYearFromDate(this.currentMonthData.startDateOfMonth);
  }

  setDay(day: MonthDay, event: MouseEvent) {
    if (day.available || this.checkAllowClickNonAvailableDay(day)) {
      event.preventDefault();
      this.hideCalendar();
      this.activeDay = day.dayNumInMonth;
      const utcDate = new Date(Date.UTC(this.monthAvailability.year,this.monthAvailability.month - 1,  day.dayNumInMonth));
      const daySelected: SingleDateAvailability = new SingleDateAvailability(day.dayNumInMonth, this.monthAvailability.month - 1, this.monthAvailability.year, utcDate);
      let selectedDayChanged = false;
      if (this.selectedDate === undefined ||
          this.selectedDate.day !== daySelected.day ||
          this.selectedDate.month !== daySelected.month ||
          this.selectedDate.year !== daySelected.year) {
        this.selectedDate = daySelected;
        selectedDayChanged = true;
      }
      if (selectedDayChanged) {
        for (let i = 0, x = this.monthAvailability.openDays.length; i < x; i++)
          if (this.monthAvailability.openDays[i].day === day.dayNumInMonth &&
              (this.selectedDate === undefined ||
                  this.selectedDate.day !== this.monthAvailability.openDays[i].day ||
                  this.selectedDate.month !== this.monthAvailability.openDays[i].month ||
                  this.selectedDate.year !== this.monthAvailability.openDays[i].year)) {
            this.selectedDate = this.monthAvailability.openDays[i];
          }
        switch (this.objectType) {
          case MainObjectTypes.APPT:
            // this.apptDetailService.convertSelectedDateToApptStartDate(this.selectedDate);
            // this.availabilityService.currentEventDateChanged.next();
            // this.onSetDay.emit(this.apptDetailService.currentEvent);
            break;
          case MainObjectTypes.WAIT_LIST:
            this.joinWaitListService.currentEvent.startDate = this.selectedDate.date;
            this.availabilityService.currentEventDateChanged.next();
            this.onSetDay.emit(this.joinWaitListService.currentEvent);
            break;

        }
      }
    }

  }

  nextMonth(currentMonthData: MonthDataObj, event: MouseEvent) {
    event.preventDefault();
    this.loading = true;
    const nextMonthData = this.timeService.moveToNextMonth(currentMonthData, this.activeYear, this.monthsData, this.activeMonth);
    this.activeYear = nextMonthData.activeYear;
    this.activeMonth = nextMonthData.activeMonth;
    this.monthsData = nextMonthData.monthsData;
    this.currentMonthData = this.monthsData[this.activeMonth - 1];
    this.getCalendarDates(this.currentMonthData.currentYear, this.currentMonthData.monthNumberFull);
    this.setCalendarMonthTitle();
  }

  previousMonth(currentMonthData: MonthDataObj, event: MouseEvent) {
    event.preventDefault();
    this.loading = true;
    if (currentMonthData.monthNumberIndex === 0) {
      this.activeYear = this.activeYear - 1;
      this.monthsData =  this.timeService.getMonthsData(this.activeYear);
      this.activeMonth = 12;
    } else
      this.activeMonth = this.activeMonth - 1;
    this.currentMonthData = this.monthsData[this.activeMonth - 1];
    this.getCalendarDates(this.currentMonthData.currentYear, this.currentMonthData.monthNumberFull);
    this.setCalendarMonthTitle();
  }

  setSelectedMonthOnCalendar() {
    if (this.monthAvailability.month !== this.activeMonth || this.monthAvailability.month !== this.currentMonthData.monthNumberFull) {
      this.activeMonth = this.monthAvailability.month;
      if (this.activeYear !== this.monthAvailability.year) {
        this.activeYear = this.monthAvailability.year;
        this.monthsData = this.timeService.getMonthsData(this.activeYear);
      }
      this.currentMonthData = this.monthsData[this.activeMonth - 1];
    }
    this.monthDays = this.timeService.getDaysOfMonth(this.activeYear, this.activeMonth, this.monthAvailability);
    this.setCalendarMonthTitle();
  }

  setSelectedDate(selectedAvailableDay: SingleDateAvailability) {
    this.selectedDate = selectedAvailableDay;
    this.setSelectedMonthOnCalendar();
  }

  organizeMonthDates() {
    this.monthAvailabilityLoaded = true;
    if (this.monthAvailability.openDays !== undefined && this.monthAvailability.openDays !== null && this.monthAvailability.openDays.length !== 0) {
      this.monthHasAvailability = true;
      let dateFound = false;
      const selectedDay: number = Number(this.availabilityService.getEventDay());
      for (let i = 0, x = this.monthAvailability.openDays.length; i < x; i++)
        if (this.monthAvailability.openDays[i].day === selectedDay) {
          this.setSelectedDate(this.monthAvailability.openDays[i]);
          dateFound = true;
        }
      if (!dateFound)
        this.setSelectedMonthOnCalendar();
    } else { // No open days in selected month
      this.monthHasAvailability = false;
      this.setSelectedMonthOnCalendar();
    }
  }

  getCalendarDates(year: string | number, month: string | number) {
    this.loading = true;
    this.availabilityService.getAvailableMonthDates(year, month).subscribe((monthAvailability: MonthAvailability) => {
      this.monthAvailability = monthAvailability;
      this.loading = false;
      this.allowGetNextMonth = this.availabilityService.checkIfNextMonthAllowed(this.activeYear, this.activeMonth, this.currentMonthData);
      this.allowGetPrevMonth = this.availabilityService.checkIfPrevMonthAllowed(this.activeYear, this.activeMonth, this.currentMonthData);
      this.organizeMonthDates();
    });

  }

  dateChanged(selectedDate: string | Date) {
    // console.log(selectedDate);
  }

  navigateAvailableDates(event: KeyboardEvent, currentDate: string) {
    // console.log(event);
    // console.log(currentDate);
  }

  showCalendar() {
    this.calendarVisible = true;
    const availabilityFilterData: AvailabilityFilterData = this.availabilityService.getAvailabilityFilterDataForAppt();
    let availabilityFilterDataChanged = false;
    if (!this.availabilityService.availabilityFilterData)
      availabilityFilterDataChanged = true;
    else
      for (const prop in availabilityFilterData)
        if (Array.isArray(availabilityFilterData[prop]) && !this.formatFunctionsService.arraysEqual(availabilityFilterData[prop], this.availabilityService.availabilityFilterData[prop]))
          availabilityFilterDataChanged = true;
        else if (!Array.isArray(availabilityFilterData[prop]) && availabilityFilterData[prop] !== this.availabilityService.availabilityFilterData[prop])
          availabilityFilterDataChanged = true;
    if (availabilityFilterDataChanged)
      this.getCalendarDates(this.availabilityService.getEventYear(), this.availabilityService.getEventMonth());
  }

  @HostListener('window:click', ['$event'])
  keyEvent(event: any) {
    const clickPath = event.composedPath();
    let hideCalendar = true;
    clickPath.forEach((eventTarget, index) => {
      const htmlElement = eventTarget as HTMLElement;
      if (htmlElement.id === 'availabilityCalendar')
        hideCalendar = false;
    });
    if (hideCalendar)
      this.hideCalendar();
  }


  hideCalendar() {
    setTimeout(() => {
      if (!this.loading)
        this.calendarVisible = false;
    }, 250);
  }

  setDateDisplay(dateToShow: string | number | Date) {
    if(dateToShow instanceof Date){
      this.dateForDisplay = this.timeService.getShortDateFromDateNoConversion(dateToShow);
      this.activeDay = Number(dateToShow.getDate());
    }else{
      this.dateForDisplay = this.timeService.getShortDateFromDate(dateToShow);
      this.activeDay = Number(this.timeService.getPipeDayFromDate(this.originalDate));
    }
    this.originalDate = dateToShow;
  }

  ngOnInit(): void {
    this.setDateDisplay(this.availabilityService.currentEvent.startDate);
    this.dateChangedSubscription = this.availabilityService.currentEventDateChanged.subscribe(() => {
      this.setDateDisplay(this.availabilityService.currentEvent.startDate);
    });
  }

  ngOnDestroy(): void {
    this.dateChangedSubscription.unsubscribe();
    this.availabilityService.availabilityFilterData = null;
  }

}
