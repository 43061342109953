import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Invoice, preference} from '../../../../common-classes/app-objects.model';
import {Router} from '@angular/router';
import {TagsService} from '../../../../tags.service';
import {SchedulerPreferenceService} from '../../../../scheduler-preference.service';

@Component({
  selector: 'app-prompt-package-pay',
  templateUrl: './prompt-package-pay.component.html',
  styles: []
})
export class PromptPackagePayComponent implements OnInit {
  invoice: Invoice;
  preference: preference;
  showPackageOptionCounter: number
  constructor(@Inject(MAT_DIALOG_DATA) private passedData: {invoice: Invoice, showPackageOptionCounter: number},
              private schedulerPreferenceService: SchedulerPreferenceService,
              private router: Router, public dialogRef: MatDialogRef<PromptPackagePayComponent>, private tagsService: TagsService) {
    this.preference = schedulerPreferenceService.schedulerPreference;

  }



  selectPayWithPackage(paymentMethod: string){
    this.dialogRef.close(paymentMethod);
  }

  ngOnInit() {
    this.invoice = this.passedData.invoice;
    this.showPackageOptionCounter = this.passedData.showPackageOptionCounter;
  }

}
