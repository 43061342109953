import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    ClientAccount, CSFilterParameterBean, Invoice, movePanels, MultipleAppts, PaymentMap, preference
} from "../../common-classes/app-objects.model";
import {TagsService} from "../../tags.service";
import {TimeService} from "../time/time.service";
import {ClientAccountService} from "../../client-account/client-account.service";
import {BusinessService} from "../../business.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {ResponsiveService} from "../../responsive.service";
import * as $ from 'jquery';
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {AddToCalendarComponent} from "../common-components/add-to-calendar/add-to-calendar.component";
import {WaitList} from '../../../models/WaitList.model';
import {Appointment} from '../../../models/Appointment.model';
import {SessionService} from '../../session.service';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {schedulerPanel} from '../../../models/SchedulerPanel.model';

@Component({
  selector: 'confirmation-panel',
  templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @Output() onMovePanels = new EventEmitter<movePanels>();
    @Input() preference: preference;
    @Input() panel: schedulerPanel;
    @Input() CSFilterObj: CSFilterParameterBean;
    @Input() appt: Appointment;
    @Input() multipleAppts: MultipleAppts;
    @Input() waitLists: WaitList[];
    @Input() directionComingFrom: string;
    paymentMap: PaymentMap;
    bookingObject: any;
    showBackButton: boolean = false;
    showBookAnotherButton: boolean = true;
    paymentButtonTag = '%PAYMENT_BUTTON%';
    confirmationText: string;
    confirmationAfterPaymentButton: string;
    paymentButtonLabel: string;
    panelTitleProperty: string;
    connectingWithPaymentPortal: boolean = false;
    enteringPayment: boolean = false;
    redirectUrl: SafeUrl = null;
    redirectUrlLoaded: boolean = false;
    showPaymentButton: boolean;
    paymentRequired: boolean;
    authorizeRequired: boolean;
    appointments: Appointment[] = [];
    dblBookedAppointments: Appointment[] = [];
    showAddToCalendarBtn: boolean = true;
    showPrintButton: boolean = false;
    bookAnotherProp: string = 'labelBookAnother';

    constructor(private tagsService: TagsService, private timeService: TimeService, private responsiveService: ResponsiveService,
                private sanitizer: DomSanitizer, private router: Router, private route: ActivatedRoute,
                private clientAccountService: ClientAccountService, private businessService: BusinessService, private addToCalendarSheet: MatBottomSheet,
                private sessionService: SessionService, private schedulerPreferenceService: SchedulerPreferenceService) { }

    movePanels(direction: string, continueShoppingUrl: string){
        if(continueShoppingUrl !== null && continueShoppingUrl.trim() !== ''){
            window.location.href = continueShoppingUrl;
        } else {
            this.onMovePanels.emit({direction: direction, panel: this.panel})
        }
    }
    addToCalendar(){
        let apptsToPass = [this.bookingObject];
        if(this.appointments !== undefined && this.appointments !== null && this.appointments.length > 0){
            apptsToPass = this.appointments;
        }
        this.addToCalendarSheet.open(AddToCalendarComponent, {data: {appointments: apptsToPass}});
    }

    sanitizeRedirectUrl(url: string){
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    payInvoice(){
        this.connectingWithPaymentPortal = true;
        if(this.clientAccountService.loggedInClient === undefined) { // LHB 05/26/2020 -- added if check around setting of client account to fix TT-6619
            let clientAccount: ClientAccount = {
                emailAddress: this.bookingObject.client.emailAddress,
                password: null,
                active: true,
                businessId: this.bookingObject.businessId,
                clientAccountId: null,
                clientList: [this.bookingObject.client]
            }
            this.clientAccountService.setLoggedInClient(clientAccount);
        }
        this.businessService.getInvoiceById(this.bookingObject.invoiceId)
            .subscribe((invoice: Invoice) => {
                const navigationExtras: NavigationExtras = {
                    relativeTo: this.route.root,
                    queryParams: {
                        paying: this.preference.booleanMap.autoProgressToPaymentPortal
                    },
                    state: {
                        invoice: invoice,
                        apptHash: this.bookingObject.hashId || null,
                        paymentRequired: this.paymentRequired,
                        authorizeRequired: this.authorizeRequired,
                    },
                    queryParamsHandling: 'merge',
                };
                this.router.navigate(['invoices/' + invoice.invoiceHash], navigationExtras)
            })
    }
    // _clickHandler = this.payInvoice.bind(this);
    ngAfterViewChecked(): void {
        this.responsiveService.setHeightForEmbedScheduler();
    }

    //Call to test API to test cancellation due to non payment after waitlist accept
    // cancelNoPay(){
    //     this.businessService.cancelApptNoPay(this.bookingObject).subscribe(response => {
    //         console.log(response);
    //     })
    // }

    ngAfterViewInit() {
        if(this.showPaymentButton || this.authorizeRequired){
            let paymentBtnAreaParentTag = $("span#paymentBtnArea").parent().get(0).tagName;
            let paymentBtnAreaParentStyle = $("span#paymentBtnArea").parent().get(0).style;
            let wrapperHtml = "<" + paymentBtnAreaParentTag + " style='" + paymentBtnAreaParentStyle.cssText +"'></" + paymentBtnAreaParentTag + ">";
            $("#paymentBtnWrapper").wrap(wrapperHtml);
        }
    }

    checkBookAnotherRedirect(){
        if(this.showBookAnotherButton && this.preference.continueShoppingUrl !== undefined && this.preference.continueShoppingUrl !== null && this.preference.continueShoppingUrl.length > 10){
            let redirectSubstring = this.preference.continueShoppingUrl.substring(0,9);
            if(redirectSubstring === 'redirect:'){
                let continueShoppingUrl = this.preference.continueShoppingUrl.substring(9);
                this.movePanels('next', continueShoppingUrl)
            }
        }
    }

    configureWaitListTextDisplay(waitLists: WaitList[], confirmationText: string){
        //waitlistSuccessText
        if(!this.panelTitleProperty){//panelTitleProperty has not been defined by appt object already
            this.panelTitleProperty = 'thanksWaitListTitle';
        }

        this.confirmationText = confirmationText + this.tagsService.convertWaitListPropertiesToTags('waitListSuccessText', this.preference, waitLists, waitLists[0]);
        this.checkBookAnotherRedirect()
    }

    organizeTextAroundPaymentButton(){

        if(!this.authorizeRequired && (this.appt && this.appt.reason && !this.appt.reason.requirePayment && this.appt.reason.requirePreAuthPayment))
            this.showPaymentButton = false;
        else
            this.showPaymentButton = true;
        this.paymentButtonLabel = this.authorizeRequired ? this.preference.labelMap.authorizeCCBtnLabel :this.preference.labelMap.payNowButtonLabel
        //Add all text before payment button to temp variable
        let textBeforePaymentButton = '';
        let indexOfEndOfPaymentBtnTag = this.confirmationText.indexOf(this.paymentButtonTag) + this.paymentButtonTag.length;
        textBeforePaymentButton = this.confirmationText.substring(0, indexOfEndOfPaymentBtnTag);
        this.confirmationAfterPaymentButton = this.confirmationText.substring(indexOfEndOfPaymentBtnTag);
        this.confirmationText = textBeforePaymentButton;
        this.confirmationText = this.confirmationText.replace(new RegExp('%PAYMENT_BUTTON%', 'g'),'<span id="paymentBtnArea"></span>');
    }

    isStaffConfirmationRequired(bookingObject: any){
        return (this.businessService.requireStaffConfirmation && bookingObject.reason.requireStaffConfirmation === null) || bookingObject.reason.requireStaffConfirmation;
    }

    configureConfirmationMessage(bookingObject: any, multipleAppts: MultipleAppts, msgProp: string, authReq: boolean,
                                 paymentReq: boolean, showBookAnother: boolean, titleProp?: string){
        if(titleProp === undefined)
            titleProp = msgProp + 'Title';
        this.panelTitleProperty = titleProp;
        if(multipleAppts !== undefined)
            this.confirmationText = this.tagsService.convertRepeatingApptPropertiesToTags(msgProp + 'Repeating', this.preference, multipleAppts.recurringAppointment, this.appointments);
        else
            this.confirmationText = this.tagsService.convertApptPropertiesToTags(msgProp, this.preference, bookingObject);
        this.authorizeRequired = authReq;
        this.paymentRequired = paymentReq;
        if(showBookAnother !== undefined)
            this.showBookAnotherButton = showBookAnother;
    }

    configureConfirmationTextDisplay(bookingObject: any, multipleAppts: MultipleAppts){
        //First check if account accepts payment upon booking and whether or not the appointment has an invoice and price set to it
        //Did have the check to show require confirmation message first, but moved that to second per TT-6279
        if(this.schedulerPreferenceService.schedulerPreference.acceptPaymentOnBooking &&
            bookingObject.invoiceId !== null &&
            bookingObject.price !== 0){//If the business accepts payments upon booking, the invoice id is filled in, and the price is not equal to zero (i.e. a coupon hasn't been applied to totally discount price) then show one of the payment confirmation messages
            if(bookingObject.reason.requirePreAuthPayment || (bookingObject.reason.requirePreAuthPayment === null && this.businessService.requirePreAuthPayment)){
                //Show the thanksPaymentTitleAuthOnly and thanksInfoPaymentAuthOnly messages
                this.configureConfirmationMessage(bookingObject, multipleAppts, 'thanksInfoPaymentAuthOnly', true, false, false, 'thanksPaymentTitleAuthOnly');
            } else if(bookingObject.reason.requirePayment || (bookingObject.reason.requirePayment === null && this.businessService.requirePayment)){
                if(this.isStaffConfirmationRequired(bookingObject))
                    this.configureConfirmationMessage(bookingObject, multipleAppts, 'thanksInfoPaymentRequiredStaffConfirmationRequired', false, true, false);
                else //Show the thanksPaymentTitleRequired and thanksInfoPaymentRequired messages
                    this.configureConfirmationMessage(bookingObject, multipleAppts, 'thanksInfoPaymentRequired', false, true, false, 'thanksPaymentTitleRequired');
            } else if(this.isStaffConfirmationRequired(bookingObject)) {
                //Show the thanksInfoPaymentStaffConfirmationRequiredTitle and thanksInfoPaymentStaffConfirmationRequired messages
                this.configureConfirmationMessage(bookingObject, multipleAppts, 'thanksInfoPaymentStaffConfirmationRequired', false, false, this.showBookAnotherButton);
            } else {
                //Show the thanksPaymentTitle and thanksInfoPayment messages
                this.configureConfirmationMessage(bookingObject, multipleAppts, 'thanksInfoPayment', false, false, this.showBookAnotherButton, 'thanksPaymentTitle');
            }
            if(this.confirmationText.indexOf(this.paymentButtonTag) !== -1){
                this.organizeTextAroundPaymentButton();
            }
        } else if(this.isStaffConfirmationRequired(bookingObject)){//DOES REQUIRE STAFF CONFIRMATION
            this.configureConfirmationMessage(bookingObject, multipleAppts, 'thanksInfoConfirm', false, false, this.showBookAnotherButton, this.panel.panelTitleProperty);
        } else if((!this.businessService.requireStaffConfirmation && bookingObject.reason.requireStaffConfirmation === null) || bookingObject.reason.requireStaffConfirmation===false) {//DOES NOT REQUIRE STAFF CONFIRMATION
            this.configureConfirmationMessage(bookingObject, multipleAppts, 'thanksInfo', false, false, this.showBookAnotherButton, this.panel.panelTitleProperty);
        }
        if(multipleAppts !== undefined && this.dblBookedAppointments.length > 0){
            this.confirmationText = this.confirmationText + this.tagsService.convertRepeatingApptPropertiesToTags('thanksInfoRepeatingApptsNotSaved', this.preference, multipleAppts.recurringAppointment, this.dblBookedAppointments);
        }
        if(bookingObject.client !== undefined && bookingObject.client !== null && bookingObject.client.formList !== undefined && bookingObject.client.formList !== null && bookingObject.client.formList.length > 0){
            let formsListText = this.tagsService.convertClientFormsList(this.preference.labelMap.disclaimerFormConfirmationMessage, bookingObject.client, this.preference);
            this.confirmationText = this.confirmationText + formsListText;
        }
        if(this.waitLists !== undefined) {
            this.configureWaitListTextDisplay(this.waitLists, this.confirmationText);
        } else {
            this.checkBookAnotherRedirect()
        }

    }

    ngOnInit() {
        if (this.directionComingFrom === 'joinWaitList')
            this.bookAnotherProp = 'registerAnotherWaitList'
        if(this.clientAccountService.loggedInClient !== undefined && this.clientAccountService.loggedInClient !== null){
            for(let i = 0, x = this.clientAccountService.loggedInClient.clientList.length; i < x; i++){
                if(this.appt !== undefined && this.appt !== null && this.clientAccountService.loggedInClient.clientList[i].clientId === this.appt.client.clientId){
                    this.clientAccountService.loggedInClient.clientList[i] = this.appt.client;
                    this.clientAccountService.selectedClient = this.clientAccountService.loggedInClient.clientList[i];
                }
                if(this.multipleAppts !== undefined && this.multipleAppts !== null && this.clientAccountService.loggedInClient.clientList[i].clientId === this.multipleAppts.recurringAppointment.client.clientId){
                    this.clientAccountService.loggedInClient.clientList[i] = this.multipleAppts.recurringAppointment.client;
                    this.clientAccountService.selectedClient = this.clientAccountService.loggedInClient.clientList[i];
                }
            }
        }
        if(this.preference.onlyAllowBookingByInvitation)
            this.sessionService.sessionObject.invitationCompleted = true;
        if(this.preference.showBookAnother === false)
            this.showBookAnotherButton = false;
        if(this.preference.allowPrintConfirmation)
            this.showPrintButton = true;

        this.showAddToCalendarBtn = this.preference.showAddToCalendar;
        if(this.multipleAppts){
            this.bookingObject = this.multipleAppts.recurringAppointment;

            for(let i = 0, x = this.multipleAppts.appointments.length; i < x; i++){
                if(this.multipleAppts.appointments[i].thankYouString === this.businessService.doubleBookedTimeErrorMsg){
                    this.dblBookedAppointments.push(this.multipleAppts.appointments[i]);
                } else {
                    this.appointments.push(this.multipleAppts.appointments[i])
                }
            }
            this.configureConfirmationTextDisplay(this.multipleAppts.recurringAppointment, this.multipleAppts);
        } else if(this.appt){
            this.bookingObject = this.appt;
            this.configureConfirmationTextDisplay(this.appt, undefined);
        } else if(this.waitLists) {
            this.bookingObject = this.waitLists;
            this.showAddToCalendarBtn = false;
            this.configureWaitListTextDisplay(this.waitLists, '');
        }
    }
}
