import {HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Subscription} from "rxjs";
import {OnDestroy, OnInit} from "@angular/core";
import {AuthService} from "./auth.service";

export class AuthInterceptorService implements HttpInterceptor {

    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler){
        if(req.url.indexOf('fileUploadS3.do')!==-1){
            let headers = new HttpHeaders(
                {'Authorization': 'Bearer ' + this.authService.token,
                    'Accept-Language': sessionStorage.getItem('locale')
                });

            const modifiedRequest = req.clone({headers: headers});
            return next.handle(modifiedRequest);
        } else {
            let headers = new HttpHeaders(
                {'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.authService.token,
                    'Accept-Language': sessionStorage.getItem('locale'),
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
                });

            const modifiedRequest = req.clone({headers: headers});
            return next.handle(modifiedRequest);
        }
    }
}
