import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    Client,
    ClientAccount,
    preference
} from "../common-classes/app-objects.model";
import {FormGroup} from "@angular/forms";
import {ClientInfoService} from "../scheduler/client-info/client-info.service";
import {ClientAccountService} from "../client-account/client-account.service";
import {MatOptionSelectionChange} from "@angular/material";
import {BusinessService} from "../business.service";
import * as _ from "lodash";
import {ResponsiveService} from "../responsive.service";
import {TagsService} from "../tags.service";
import {FormFunctionsService} from "../form-functions.service";
import {TimeService} from "../scheduler/time/time.service";
import {Reason} from '../../models/Reason.model';
import {CustomField} from '../../models/CustomField.model';
import {SchedulerPreferenceService} from '../scheduler-preference.service';
import * as _moment from 'moment';
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {DateAdapter} from "@angular/material/core";
import {registerLocaleData} from "@angular/common";
const moment = _moment;
@Component({
  selector: 'app-scheduler-pref-field-form',
  templateUrl: './scheduler-pref-field-form.component.html',
  styleUrls: ['../form-field-styles.component.css']
})
export class SchedulerPrefFieldFormComponent implements OnInit, OnChanges {
    @Input() fieldDefnList: CustomField[];
    @Input() clientInfoForm: FormGroup;
    @Input() preference: preference;
    @Input() selectedReason: Reason;
    @Input() editing: boolean;
    @Input() selectClientOnForm: boolean;
    @Input() disableEmailAddress: boolean;
    @Input() clientSaving: boolean;
    @Input() addConnectedClient: boolean = false;
    @Input() earliestApptDate: Date; // LHB 08/08/2020 TT-4897 -- need to pass appt date to form to add conditions check
    @Output() onFormLoaded = new EventEmitter<boolean>();
    @Output() onFileUploading = new EventEmitter<boolean>();
    formLoaded: boolean = false;
    clientAccount: ClientAccount;
    selectedClient: Client;



    constructor(private clientInfoService: ClientInfoService, private clientAccountService: ClientAccountService, private responsiveService: ResponsiveService, private timeService: TimeService,
                private cdRef: ChangeDetectorRef, private businessService: BusinessService, private tagsService: TagsService, private formFunctionsService: FormFunctionsService,
                private schedulerPreferenceService: SchedulerPreferenceService, private dateAdapter: DateAdapter<MomentDateAdapter>) { }


    applyNumericMask = function(newFieldValue: string, mask: string){
        if(mask !== null && newFieldValue !== null) {
            let fieldValueMinusMaskDigits = '';
            for(let i = 0, x = mask.length; i < x; i++) {
                if (i < newFieldValue.length && mask[i] !== newFieldValue[i]) {
                    fieldValueMinusMaskDigits = fieldValueMinusMaskDigits + newFieldValue[i];
                }
            }
            newFieldValue = fieldValueMinusMaskDigits;
            newFieldValue = newFieldValue.replace(/\D/g,''); // since right now only allow for digit masking, replace all non digits in field
            for(let i = 0, x = mask.length; i < x; i++){
                if(mask[i] !== '#'){
                    if (i <= newFieldValue.length)
                        newFieldValue = newFieldValue.slice(0, i) + mask[i] + newFieldValue.slice(i);

                }
            }
            return newFieldValue;
        } else {
            return null;

        }
    };

    emailAddressChecks(field: CustomField){
        if(field.code === 'emailAddress'){
            if (this.disableEmailAddress)
                this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].disable();
            for (let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++) // LHB 10/08/2020 TT-7064
                if(this.preference.schedulerPreferenceFieldDefnList[i].code === 'confirmEmailAddress' && this.preference.schedulerPreferenceFieldDefnList[i].value !== null)
                    this.checkConfirmEmailMatchesEmail(field);
        }
    }

    setMultiCheckboxValue(field: CustomField, dropdownValue: any, event: any){
        field.value = this.formFunctionsService.setMultiCheckboxValue(field.value, dropdownValue.label, event);
        this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].setValue(field.value);
    }

    checkRequiredCheckbox(field: CustomField){ // LHB 08/08/2020 TT-6858
        if(field.required && field.dataType === 'CHKBOX') {
            for (let prop in this.clientInfoForm.controls) {
                if (prop === field.schedulerPreferenceFieldDefnId.toString()) {
                    if (field.value) {
                        this.clientInfoForm.controls[prop].setErrors(null);
                        field.requiredObjInvalid = false;
                    } else {
                        this.clientInfoForm.controls[prop].setErrors({'invalid': true});
                        field.requiredObjInvalid = true;
                    }
                }
            }
        }
    }

    checkConfirmEmailMatchesEmail(field: CustomField){
        if(this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId])
            for (let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++)
                if (this.preference.schedulerPreferenceFieldDefnList[i].code === 'emailAddress') {
                    const emailAddressValue = this.clientInfoForm.controls[this.preference.schedulerPreferenceFieldDefnList[i].schedulerPreferenceFieldDefnId].value;
                    const confirmEmailAddressValue = this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].value;
                    if (emailAddressValue && confirmEmailAddressValue) {
                        if( emailAddressValue.toUpperCase() !== confirmEmailAddressValue.toUpperCase()) {
                            this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].setErrors({confirmEmailMismatch: true});
                            field.confirmEmailMismatch = true;
                        } else {
                            this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].setErrors({confirmEmailMismatch: null});
                            this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].updateValueAndValidity();
                            field.confirmEmailMismatch = false;
                        }
                    } else {
                        this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].setErrors({confirmEmailMismatch: null});
                        this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].updateValueAndValidity();
                        field.confirmEmailMismatch = false;
                    }
                }
    }

    checkSaveRequiredObjsOnField(fieldInForm: boolean, field: CustomField){
        if(fieldInForm && field.dataType === 'DATE' && field.requiredObj){
            let todayMoment = this.timeService.getMomentFromDate(new Date());
            let apptDateMoment = this.timeService.getMomentFromDate(this.earliestApptDate);
            let selectedDateMoment = this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].value;
            let fieldValuePassesDepChecks: boolean;
            for(let i = 0, x = field.requiredObj.depChecks.length; i < x; i++){
                if(fieldValuePassesDepChecks !== undefined && !fieldValuePassesDepChecks && field.requiredObj.depAndOr === 'AND')
                    continue;
                if(fieldValuePassesDepChecks !== undefined && fieldValuePassesDepChecks && field.requiredObj.depAndOr === 'OR')
                    continue;
                if(fieldValuePassesDepChecks !== undefined && fieldValuePassesDepChecks && field.requiredObj.depAndOr === 'NOR')
                    continue;
                let daysDiff = 0;
                if(field.requiredObj.depChecks[i].depType === 'AGE_FROM_TODAY')
                    daysDiff = this.timeService.getMomentDaysDiff(selectedDateMoment, todayMoment);
                else
                    daysDiff = this.timeService.getMomentDaysDiff(selectedDateMoment, apptDateMoment);
                switch(field.requiredObj.depChecks[i].depCondition){
                    case 'LT':
                        fieldValuePassesDepChecks = daysDiff < (field.requiredObj.depChecks[i].depValue * -1);
                        break;
                    case 'LE':
                        fieldValuePassesDepChecks = daysDiff <= (field.requiredObj.depChecks[i].depValue * -1);
                        break;
                    case 'EQ':
                        fieldValuePassesDepChecks = daysDiff === field.requiredObj.depChecks[i].depValue;
                        break;
                    case 'GE':
                        fieldValuePassesDepChecks = daysDiff >= field.requiredObj.depChecks[i].depValue;
                        break;
                    case 'GT':
                        fieldValuePassesDepChecks = daysDiff > field.requiredObj.depChecks[i].depValue;
                        break;
                }
            }
            if(fieldValuePassesDepChecks && field.requiredObj.depAndOr === 'NOR')
                fieldValuePassesDepChecks = false;
            if(fieldInForm && !fieldValuePassesDepChecks) {
                this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].setErrors({'requiredCheckInvalid': true});
                field.requiredObjInvalid = true;
            } else if(fieldInForm && !this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].hasError('pattern')) {
                this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].setErrors(null);
                field.requiredObjInvalid = false;
            }
        }
    }

    checkSaveRequiredObjs() {
        for (let i = 0, x = this.fieldDefnList.length; i < x; i++) {
            let fieldInForm = false; // LHB 1/6/2021 TT-7264 using boolean to control what actions to take in method so that fields that don't show on scheduler can still be set up as dependencies
            if (this.clientInfoForm.controls[this.fieldDefnList[i].schedulerPreferenceFieldDefnId])
                fieldInForm = true;
            if (fieldInForm && this.clientInfoForm.controls[this.fieldDefnList[i].schedulerPreferenceFieldDefnId].value && this.fieldDefnList[i].dataType === 'DATE' && this.fieldDefnList[i].requiredObj)
                this.checkSaveRequiredObjsOnField(fieldInForm, this.fieldDefnList[i])
        }
    }

    checkRegexError(field: CustomField) {
        let fieldInForm = false; // LHB 1/6/2021 TT-7264 using boolean to control what actions to take in method so that fields that don't show on scheduler can still be set up as dependencies
        if (this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId])
            fieldInForm = true;
        if (fieldInForm && this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].invalid && this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].hasError('pattern'))
            field.regexInvalid = true;
        else
            field.regexInvalid = false;
    }

    checkDependencyFields(field: CustomField, inputEvent?: any) {
        if(this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId]) {
            let input = this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].value
            if (input && input instanceof String) {
                const match = input.match(/[\u3131-\uD79D]/g);
                if (match !== null) {
                    return;
                }
            }
        }

        let fieldInForm = false; // LHB 1/6/2021 TT-7264 using boolean to control what actions to take in method so that fields that don't show on scheduler can still be set up as dependencies
        if (this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId])
            fieldInForm = true;
        if (fieldInForm && (!inputEvent || (inputEvent.code !== 'Backspace' && inputEvent.code !== 'Delete'))) {
            let maskedValue: string = this.applyNumericMask(this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].value, field.mask);
            if (maskedValue) //if it returns null, there's no reason to use a setter here
                this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].setValue(maskedValue);
        }

        if (fieldInForm)
            field.value = this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].value;
        let reasonId = null;
        if(this.selectedReason !== undefined)
            reasonId = this.selectedReason.reasonId;
        this.clientInfoService.checkDependencyFields(field, this.preference.schedulerPreferenceFieldDefnList, this.clientInfoForm, reasonId);
        this.emailAddressChecks(field);
        this.checkRegexError(field);
        if (field.code === 'confirmEmailAddress')
            this.checkConfirmEmailMatchesEmail(field);
        this.checkSaveRequiredObjs();
        this.checkRequiredCheckbox(field);
        if (this.clientInfoService.checkContainsConfirmEmail(this.preference.schedulerPreferenceFieldDefnList)) // LHB 3/12/2021 TT-7528
            this.checkConfirmEmailMatchesEmail(this.clientInfoService.getField(this.preference.schedulerPreferenceFieldDefnList, 'confirmEmailAddress'));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if((changes.clientSaving !== undefined && changes.clientSaving.previousValue === true && changes.clientSaving.currentValue === false && changes.clientSaving.firstChange === false) ||
            (changes.editing !== undefined && changes.editing.previousValue === true && changes.editing.currentValue === false && changes.editing.firstChange === false)){
            this.cdRef.detectChanges();
        }

    }

    organizeFieldsWithSelectedClient(){
        //Go through all the fields on the form that are client fields and fill with data from selectedClient
        for(let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++){
            for (let j = 0, y = this.selectedClient.fields.length; j < y; j++) {
                if (this.selectedClient.fields[j].schedulerPreferenceFieldDefnId === this.preference.schedulerPreferenceFieldDefnList[i].schedulerPreferenceFieldDefnId) {
                    this.selectedClient.fields[j].label = this.preference.schedulerPreferenceFieldDefnList[i].label; // LHB 06/30/2021 TT-7935 for applying translations to field labels on client profile view
                    this.selectedClient.fields[j].hint = this.preference.schedulerPreferenceFieldDefnList[i].hint; // LHB 07/23/2021 TT-8033 for applying translations to field hints on client profile view
                    if (this.selectedClient.fields[j].value !== null && this.selectedClient.fields[j].value !== "") {
                        if (this.preference.schedulerPreferenceFieldDefnList[i].showOnScheduler) // LHB 1/6/2021 TT-7264 separated this out so could still loop through checking dependency fields
                            for (let prop in this.clientInfoForm.controls) {
                                if (this.selectedClient.fields[j].schedulerPreferenceFieldDefnId === Number(prop) && !this.addConnectedClient && this.clientInfoForm.controls[prop] !== undefined) {
                                    this.clientInfoForm.controls[prop].setValue(this.selectedClient.fields[j].value);
                                }
                            }
                        this.preference.schedulerPreferenceFieldDefnList[i].value = this.selectedClient.fields[j].value;
                        this.checkDependencyFields(this.preference.schedulerPreferenceFieldDefnList[i], undefined)
                        if (this.preference.schedulerPreferenceFieldDefnList[i].code !== 'cellPhone') {
                            this.preference.schedulerPreferenceFieldDefnList[i].showOnScheduler = false;
                        }
                        this.preference.schedulerPreferenceFieldDefnList[i].updatedBasedOnLogin = true;
                    }
                }
            }
        }
        this.formLoaded = true;
        this.cdRef.detectChanges();
        this.onFormLoaded.emit(this.formLoaded);
    }

    fileUploadChange(fileInputEvent: any, field: CustomField){
        field.fileUploading = true;
        let proceedWithUpload = true;
        if(field.validationRegex !== null){
            let isValid = true;
            try {
                new RegExp(field.validationRegex);
            } catch(e) {
                isValid = false;
            }
            if(isValid){
                let regexTest = new RegExp(field.validationRegex);
                proceedWithUpload = regexTest.test(fileInputEvent.target.files[0].name);
            }
        }
        if(proceedWithUpload){
            let copiedFileName = _.cloneDeep(fileInputEvent.target.files[0].name);
            this.onFileUploading.emit(true);
            this.businessService.uploadFile(fileInputEvent.target.files[0], field.businessId)
                .subscribe(file => {
                    field.fileUploading = false;
                    field.fileUploadError = false;
                    field.value = file;
                    this.onFileUploading.emit(false);
                    this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].setValue(field.value);
                    field.fileForDisplay = copiedFileName;
                    field.fileUploadSuccess = true;
                    setTimeout(() => {
                        field.fileUploadSuccess = false;
                    }, 3000)
                }, (error)=> {
                    this.onFileUploading.emit(false);
                    field.fileUploading = false;
                    field.fileUploadError = true;
                    field.fileUploadErrorMsg = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.fileUploadErrorMsg, this.tagsService.preferenceTagsArray);
                    this.removeFile(field);
                })
        } else {
            field.fileUploading = false;
            field.fileUploadError = true;
            field.fileUploadErrorMsg = field.validationError;
        }
    }
    chooseFilesForUpload(field: CustomField){
        let htmlId = 'field' + field.schedulerPreferenceFieldDefnId;
        document.getElementById(htmlId).click();
    }

    removeFile(field: CustomField){
        field.value = null;
        this.clientInfoForm.controls[field.schedulerPreferenceFieldDefnId].setValue(field.value);
        field.fileForDisplay = null;
    }

    selectClient(event: MatOptionSelectionChange, client: Client){
        if(client.clientId !== this.selectedClient.clientId && event.isUserInput){
            this.selectedClient = client;
            this.clientAccountService.selectedClient = this.selectedClient;
            //this.configureFieldsForDisplay();
            for(let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++){
                if(this.preference.schedulerPreferenceFieldDefnList[i].updatedBasedOnLogin) {
                    this.preference.schedulerPreferenceFieldDefnList[i].value = null;
                    this.preference.schedulerPreferenceFieldDefnList[i].showOnScheduler = true;
                    this.preference.schedulerPreferenceFieldDefnList[i].updatedBasedOnLogin = false;
                    for(let prop in this.clientInfoForm.controls){
                        if(this.preference.schedulerPreferenceFieldDefnList[i].schedulerPreferenceFieldDefnId === Number(prop)){
                            this.clientInfoForm.controls[prop].setValue(this.preference.schedulerPreferenceFieldDefnList[i].value);
                        }
                    }
                }
            }
            this.organizeFieldsWithSelectedClient()
        }
    }

    ngOnInit() {
        registerLocaleData(this.timeService.locale);
        this.dateAdapter.setLocale(this.timeService.locale);
        moment.locale(this.timeService.locale);
        if(this.clientAccountService.loggedInClient){
            this.clientAccount = this.clientAccountService.loggedInClient;
            if(this.clientAccountService.selectedClient){
                this.selectedClient = this.clientAccountService.selectedClient;
            } else {
                this.selectedClient = this.clientAccount.clientList[0];
                this.clientAccountService.setSelectedClient(this.selectedClient, this.preference.schedulerPreferenceFieldDefnList);
            }
            this.organizeFieldsWithSelectedClient()
        } else if(this.disableEmailAddress) {//likely on the register screen and no client is selected
            for(let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++){
                if(this.preference.schedulerPreferenceFieldDefnList[i].code === 'emailAddress'){
                    this.emailAddressChecks(this.preference.schedulerPreferenceFieldDefnList[i]);
                }
            }
            this.formLoaded = true;
            this.onFormLoaded.emit(this.formLoaded);
        } else {
            this.formLoaded = true;
            this.onFormLoaded.emit(this.formLoaded);
        }
    }

}
