import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    AfterViewChecked,
    ChangeDetectorRef,
    OnDestroy
} from '@angular/core';
import {Client, ClientAccount, loginRegisterPopUpObject, preference} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {MatOptionSelectionChange} from "@angular/material";
import {ResponsiveService} from "../../responsive.service";
import {AuthService} from "../../auth/auth.service";
import {ClientAccountService} from "../client-account.service";
import {Subscription} from "rxjs";
import {FormFunctionsService} from "../../form-functions.service";

@Component({
  selector: 'client-account-header',
  templateUrl: './client-account-header.component.html',
    styleUrls: ['./client-account-header.component.css']
})
export class ClientAccountHeaderComponent implements OnInit, AfterViewChecked, OnDestroy {
    @Input() headerId: string;
    @Input() titleId: string;
    @Input() viewingClientsLabel: string;
    @Input() title: string;
    @Input() preference: preference;
    @Input() clientAccount: ClientAccount;
    @Input() selectedClient: Client;
    @Input() showBackButton: boolean;
    @Input() showClientSelector: boolean;
    @Input() showPrintButton: boolean = false;
    @Input() printId: string;
    @Input() hideConnectButton: boolean;
    @Output() onSelectClient = new EventEmitter<{event: MatOptionSelectionChange, client: Client}>();
    @Output() onGoBack = new EventEmitter();
    connectedClientClosedSubscription: Subscription;
    showConnectClientButton: boolean = false;
    constructor(private businessService: BusinessService, private cdRef: ChangeDetectorRef, private clientAccountService: ClientAccountService,
                private responsiveService: ResponsiveService, private authService: AuthService, private formFunctionsService: FormFunctionsService) { }

    checkShowConnectClientBtn(){
        let showLoginBtns = this.responsiveService.showLoginRegisterBtnLogic(this.preference);
        if(this.authService.isAuth() && this.preference.allowAddConnectedClient && this.clientAccountService.loggedInClient !== undefined && this.clientAccountService.loggedInClient !== null &&
            this.clientAccountService.loggedInClient.clientList !== undefined && this.clientAccountService.loggedInClient.clientList !== null &&
            this.clientAccountService.loggedInClient.clientList.length === 1 && !this.hideConnectButton)
            this.showConnectClientButton = showLoginBtns.showRegisterBtn;
        else
            this.showConnectClientButton = false;
    }

    ngOnInit() {
        this.connectedClientClosedSubscription = this.authService.connectedClientClosed.subscribe((client: Client) => {
            let event: MatOptionSelectionChange = new MatOptionSelectionChange(null, true);
            this.onSelectClient.emit({event: event, client: client});
            this.checkShowConnectClientBtn();
        });
        this.clientAccountService.addConnectClientToClientList(this.preference);
        this.checkShowConnectClientBtn();
    }
    ngAfterViewChecked(){
        if(this.responsiveService.innerWidth > this.responsiveService.xsScreenBreakPoint || !this.showClientSelector){//TT-6092
            let showLoginRegisterBtns = this.responsiveService.showLoginRegisterBtnLogic(this.preference);
            this.responsiveService.adjustMatCardTitleBarHeight(this.headerId, this.preference, showLoginRegisterBtns)
        }

        this.cdRef.detectChanges();

    }
    selectClient(event: MatOptionSelectionChange, client: Client){
        if(event.isUserInput && client.fullName === this.preference.labelMap.clientProfileAddNewClientLabel)
            this.addNewClient()
        else
            this.onSelectClient.emit({event: event, client: client});
    }


    goBack(){
        this.onGoBack.emit();
    }
    addNewClient(){
        let loginTriggeredData: loginRegisterPopUpObject = new loginRegisterPopUpObject();
        loginTriggeredData.calledFrom = loginTriggeredData.connectClientCallFrom;
        this.authService.addConnectedClientCalled.next(loginTriggeredData);
    }

    ngOnDestroy(): void {
        this.connectedClientClosedSubscription.unsubscribe();
    }

    printPage() {
        if(this.printId !== undefined){
            this.formFunctionsService.printElement(document.getElementById(this.printId))
            window.print();
        }
    }

}
