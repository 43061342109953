import {NgModule} from "@angular/core";
import {AlertDirective} from "./alert.directive";
import {LinkDirective} from "./link.directive";
import {CommonModule} from "@angular/common";
import {TextFormattingDirective} from "./text-formatting.directive";
import {ImageSizerDirective} from "./image-sizer.directive";
import {ChipDirective} from "./chip.directive";
import {ButtonStylingDirective} from "./button-styling.directive";
import {BackgroundStylingDirective} from "./background-styling.directive";
import {WrapperClassesDirective} from "./wrapper-classes.directive";
import { MultiCheckboxFormFieldComponent } from './multi-checkbox-form-field/multi-checkbox-form-field.component';
import { FieldLabelComponent } from './field-label/field-label.component';
import { ButtonSaveComponent } from './button-save/button-save.component';

@NgModule({
    declarations: [
        AlertDirective,
        LinkDirective,
        TextFormattingDirective,
        ImageSizerDirective,
        ChipDirective,
        ButtonStylingDirective,
        BackgroundStylingDirective,
        WrapperClassesDirective,
        MultiCheckboxFormFieldComponent,
        FieldLabelComponent,
        ButtonSaveComponent
    ],
    imports: [CommonModule],
    exports: [
        AlertDirective,
        LinkDirective,
        TextFormattingDirective,
        ImageSizerDirective,
        ChipDirective,
        ButtonStylingDirective,
        BackgroundStylingDirective,
        WrapperClassesDirective,
        MultiCheckboxFormFieldComponent,
        FieldLabelComponent,
        ButtonSaveComponent
    ]
})

export class UIElementsModule {

}
