import {AfterViewChecked, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {preference} from "../../../common-classes/app-objects.model";
import {BusinessService} from "../../../business.service";
import {ResponsiveService} from "../../../responsive.service";
import {Location} from '../../../../models/Location.model';

@Component({
  selector: 'app-variable-address',
  templateUrl: './variable-address.component.html'
})
export class VariableAddressComponent implements OnInit, AfterViewChecked {
  selectedLocation: Location;
  preference: preference;
  address: String;
  validatingAddress: boolean = false;
  errorValidatingAddress: boolean = false;
  matchingAddresses: String[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) private passedData: any, public dialogRef: MatDialogRef<VariableAddressComponent>, private businessService: BusinessService,
              private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.selectedLocation = this.passedData.selectedLocation;
    this.preference = this.passedData.preference;
    if(this.passedData.address){
      this.address = this.passedData.address;
      this.validateAddress(this.address.toString());
    }
  }

  ngAfterViewChecked(): void {
    this.responsiveService.configureValidateAddressBtnForMobile();
  }

  confirmAddress(address: string){
    this.selectedLocation.variableAddress = address;
    this.dialogRef.close(this.selectedLocation);
  }

  validateAddress(address: string){
    this.validatingAddress = true;
    this.businessService.validateVariableLocationAddress(address)
        .subscribe((addresses: string[]) =>{
            this.validatingAddress = false;
            this.matchingAddresses = addresses;
        }, (error)=> {
          this.validatingAddress = false;
          this.errorValidatingAddress = true;
        });
  }
  checkEnter(event: KeyboardEvent, address: string){
    if(event.key === 'Enter'){
      this.validateAddress(address);
    }
  }


}
