import {Group, GroupTypes} from './Group.model';
import {Reason} from './Reason.model';
import {Location} from './Location.model';
import {Staff} from './Staff.model';

export enum BookingSelectionObjectTypes {
    REASON = 'REASON',
    LOCATION = 'LOCATION',
    STAFF = 'STAFF',
    GROUP = 'GROUP'
}
export class BookingSelectionOption {
    label: string;
    objectType: BookingSelectionObjectTypes;
    options: Location[] | Staff[] | Reason[] | Group[];
    selectedOption: Location | Staff | Reason | Group;
    groupType: GroupTypes;
}
