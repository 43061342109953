export class Location {
    timezone: string;
    locationId: number;
    locationName: string;
    displayName: string;
    description: string;
    logoUrl: string;
    locationType: string;
    selected?: boolean;
    allowTZChange?: number;
    latitude?: number;
    longitude?: number;
    locationPnlMapInfoWindowContent?: string;
    variableAddress?: string;
    wasUndefined?: boolean;
    virtualRoomUrl: string;

    constructor() {
    }
}

