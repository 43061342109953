import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TitleBarComponent} from './title-bar.component';
import {MaterialModule} from '../../../material.module';
import {UIElementsModule} from '../../../ui-elements/ui-elements.module';



@NgModule({
  declarations: [TitleBarComponent],
  imports: [
    CommonModule,
      MaterialModule,
      UIElementsModule
  ],
  exports: [TitleBarComponent]
})
export class TitleBarModule { }
