import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client, currency, Invoice, preference} from "../../../../common-classes/app-objects.model";
import {BusinessService} from "../../../../business.service";
import {ClientAccountService} from "../../../client-account.service";
import {TagsService} from "../../../../tags.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ResponsiveService} from "../../../../responsive.service";
import {PaymentIntentResponse} from '../../../../../models/Stripe/PaymentIntent.model';
import {SetupIntentResponse} from '../../../../../models/Stripe/SetupIntent.model';
import {StripeService} from '../stripe/stripe.service';
import {formatCurrency} from "@angular/common";

declare var Stripe;



@Component({
  selector: 'lumapay-payment-form',
  templateUrl: './lumapay.component.html',
  styleUrls: ['./lumapay.component.css']
})
export class LumapayComponent implements OnInit, AfterViewChecked {
  @Output() onGoBack = new EventEmitter<Invoice>();
  @Input() preference: preference;
  @Input() invoice: Invoice;
  @Input() client: Client;
  @Input() currency: currency;
  @Input() path: string;
  stripeJs: string = 'https://js.stripe.com/v3/';
  // stripeJs: string = 'https://checkout.stripe.com/checkout.js';
  publishableKey: string;
  accountId: string;
  paymentFormLoaded: boolean = false;
  paymentFormHasErrors: boolean = false;
  paying: boolean = false;
  @Input() submitBtnLabel: string;
  @Input() submittingBtnLabel: string;
  stripe; // : stripe.Stripe;
  card;
  cardErrors;
  form: any;

  loading = false;
  confirmation;
  saveClientPayment: boolean = false;
  tipAmount = 0;
  selectedTipRate: string = '0';
  otherTipAmount: string;

  constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private tagsService: TagsService,
              private route: ActivatedRoute, private router: Router, private responsiveService: ResponsiveService, private stripeService: StripeService) {}

  setSaveClientPayment(){
    if(this.saveClientPayment)
      (<HTMLInputElement>document.getElementById('saveClientPayment')).value = "true"; //casting so .value will work
    else
      (<HTMLInputElement>document.getElementById('saveClientPayment')).value = "false"; //casting so .value will work
  }

  setTips(event){
    let tempInvoice: Invoice = new Invoice(this.invoice.businessId, this.invoice.client);
    if(event.value !== 'Custom'){
     tempInvoice.paymentAmount = this.invoice.paymentAmount + ((event.value /100) * this.invoice.paymentAmount);
      this.updateLabels(tempInvoice);
    }else{
      this.otherTipAmount = '0';
      tempInvoice.paymentAmount = this.invoice.paymentAmount;
      this.updateLabels(tempInvoice);
    }
  }

  setCustomTip(event){
    let tempInvoice: Invoice = new Invoice(this.invoice.businessId, this.invoice.client);
    console.log(event);
    tempInvoice.paymentAmount = this.invoice.paymentAmount + (+event.value);
    this.updateLabels(tempInvoice);

  }

  updateLabels(tempInv){
      let chargeLabel = 'invoiceCreditCardChargeLabel';
      let chargingLabel = 'invoiceCreditCardChargingLabel';
      if (this.invoice.preAuthPayment && !this.invoice.hasBeenPreAuthed) {
        chargeLabel = 'invoiceCreditCardAuthorizeLabel';
        chargingLabel = 'invoiceCreditCardAuthorizingLabel';
      }
      this.submitBtnLabel = this.tagsService.assignObjectToTags(tempInv, this.preference.labelMap[chargeLabel], this.tagsService.invoiceFieldTags, this.currency);
      this.submittingBtnLabel = this.tagsService.assignObjectToTags(tempInv, this.preference.labelMap[chargingLabel], this.tagsService.invoiceFieldTags, this.currency);
  }

  async handleForm(e) {
    e.preventDefault();

    const { source, error } = await this.stripe.createSource(this.card);

    if (error) {
      // Inform the customer that there was an error.
      const cardErrors = error.message;
    } else {
      // Send the token to your server.
      this.loading = true;
      // const user = await this.auth.getUser();
      // const fun = this.functions.httpsCallable('stripeCreateCharge');
      // this.confirmation = await fun({ source: source.id, uid: user.uid, amount: this.amount }).toPromise();
      this.loading = false;

    }
  }

  submitStripeForm(){
    this.form.onsubmit(undefined);
  }

  getUrlWithoutParams(){
    let newUrl = window.location.href.substring(0, window.location.href.lastIndexOf('?'));

    return newUrl;
  }

  goBackToInvoice(data: any){
    sessionStorage.setItem('updatedInvoice', data);
    let newUrl = this.getUrlWithoutParams();
    window.location.href = newUrl + '?paying=false';
  }


  createLumapyAddCardForm(counter: number) {
    if (typeof Stripe !== "undefined" && counter < 20) {

      let newUrl = window.location.href.substring(0, window.location.href.lastIndexOf('?'));
      // create the set up intent prior to collecting details
      let url = sessionStorage.getItem('csAPIBase') + 'cs/client/' + this.client.clientId + '/setUpIntent';
      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }
      })
          .catch(err => {
            (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
            let errorElement = document.getElementById('card-errors');
            let jsonErr = JSON.parse(err);
            if (jsonErr && jsonErr.message)
              errorElement.textContent = jsonErr.message;
            else
              errorElement.textContent = err;
            window.location.href = newUrl + '?paying=error';
          })
          .then((response: Response) => {
            if (!response.ok) {
              return response.text().then(errorInfo => Promise.reject(errorInfo));
            }
            return response.text();
          })
          .then(data => {

            let setupIntentData: SetupIntentResponse = JSON.parse(data);
            let stripe = Stripe(this.publishableKey, {stripeAccount: this.accountId});


            const options = {
              clientSecret: setupIntentData.secret
            };
            const elements = stripe.elements(options);

            // Create an instance of the card Element.
            let card = elements.create('payment');

            // Add an instance of the card Element into the `card-element` <div>.
            card.mount('#payment-element');
            this.form = document.getElementById('stripeCreditCardForm');
            (<HTMLInputElement>document.getElementById('card-errors')).hidden = true;
            this.form.addEventListener('submit', async (event) => {
              event.preventDefault();
              (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = true;
              const {error: submitError} = await stripe.confirmSetup({
                elements, confirmParams: {
                  return_url: window.location.href
                }
                , redirect: "if_required"
              });
              if (submitError) {
                let errorElement = document.getElementById('card-errors');
                errorElement.textContent = submitError.message;
                (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = false;
                return;
              }
              window.location.href = window.location.href + '?cardAdded=true';
            });
            this.paymentFormLoaded = true;
          });

    }else {
      counter++;
      setTimeout(() => this.createLumapyAddCardForm(counter), 100)
    }
  }

  createStripePaymentForm(counter: number){
    if(typeof Stripe !== "undefined" && counter < 20){
      let path = sessionStorage.getItem("paymentFormPath");
      //LOAD STRIPE PAYMENT FORM
      let stripe = Stripe(this.publishableKey, {stripeAccount: this.accountId});

      let paymentAmountOpt = this.invoice.paymentAmount;
        if (paymentAmountOpt === undefined || paymentAmountOpt === null) {
          paymentAmountOpt = this.invoice.balanceAmount;
        }
        let captureMethod = 'automatic';
        if (this.invoice.preAuthPayment && !this.invoice.hasBeenPreAuthed)
          captureMethod = 'manual';

        const options = {
          mode: 'payment',
          amount: (paymentAmountOpt * 100),
          currency: this.businessService.currency.currencyCode.toLowerCase(),
          capture_method: captureMethod,
        };
        options.amount = Math.round(options.amount);
        const elements = stripe.elements(options);




      // Create an instance of the card Element.
      let card = elements.create('payment');

      // Add an instance of the card Element into the `card-element` <div>.
      card.mount('#payment-element');
      this.form = document.getElementById('stripeCreditCardForm');
      (<HTMLInputElement>document.getElementById('card-errors')).hidden = true;
      this.form.addEventListener('submit', async (event) => {
        event.preventDefault();

        if(this.selectedTipRate !== 'Other' && this.selectedTipRate !== '0'){
          var r : number =  +this.selectedTipRate;
         this.tipAmount = ((r /100) * this.invoice.paymentAmount);
        }else if( this.selectedTipRate === 'Other'){
          // set tipAmount from other textfield
        //  var tempTip = this.otherTipAmount.match('/[+-]?\d+(\.\d+)?/g').join('');
          this.tipAmount = +this.otherTipAmount;
        }

        (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = true;
          let newUrl = window.location.href.substring(0, window.location.href.lastIndexOf('?'));
          const {error : submitError} = await elements.submit();
          if(submitError){
            let errorElement = document.getElementById('card-errors');
            errorElement.textContent = submitError.message;
            (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = false;
            return;
          }
        let url;
        let invoiceHash;
        let authOnly;
        let balanceAmount;
        let paymentAmount;
        let saveClientPayment;
        let tempTipRate;
        let tempTipAmount;

        tempTipAmount = this.tipAmount;
        tempTipRate = this.selectedTipRate;

        if(tempTipRate !== 'Other' && tempTipRate !== 'No Tip')
          tempTipRate += '%';

        invoiceHash = (<HTMLInputElement>document.getElementById('invoiceHash')).value;
        authOnly = (<HTMLInputElement>document.getElementById('authOnly')).value;
        balanceAmount = (<HTMLInputElement>document.getElementById('balanceAmount')).value;
        paymentAmount = (<HTMLInputElement>document.getElementById('paymentAmount')).value;
        saveClientPayment = (<HTMLInputElement>document.getElementById('saveClientPayment')).value;
        if (paymentAmount === undefined || paymentAmount === null) {
          paymentAmount = balanceAmount;
        }

        if(this.tipAmount > 0){
          var temp: number = +paymentAmount;
          temp += this.tipAmount;
          paymentAmount = temp;
        }
        //get the username from incoming params in case this was done in back office
        const queryString = window.location.href;
        const urlParams = new URLSearchParams(queryString);
        console.log(urlParams.get('username'));
        let username = urlParams.get('username');

        url = sessionStorage.getItem('csAPIBase') + 'cs/stripe/paymentIntent/' + invoiceHash + '?amt=' + paymentAmount + '&saveClientPayment=' + saveClientPayment + '&authOnly=' + authOnly + '&username=' + username;

        fetch(url, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
          }
        })
            .catch(err => {
              (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
              let errorElement = document.getElementById('card-errors');
              let jsonErr = JSON.parse(err);
              if(jsonErr && jsonErr.message)
                errorElement.textContent = jsonErr.message;
              else
                errorElement.textContent = err;
              window.location.href = newUrl + '?paying=error';
            })
            .then((response: Response) => {
              if (!response.ok) {
                return response.text().then(errorInfo => Promise.reject(errorInfo));
              }
              return response.text();
            })
            .then(data => {
              if(path === 'invoice') {
                let paymentIntentData: PaymentIntentResponse = JSON.parse(data);
                let clientSecret = paymentIntentData.secret;
                if(this.saveClientPayment) {
                  console.log('Updating elements for off session');
                  elements.update({setupFutureUsage: 'off_session'});
                }else{
                  console.log('Updating elements for on session');
                  elements.update({setupFutureUsage: 'on_session'});
                }
                if(this.tipAmount > 0){
                  paymentAmountOpt += this.tipAmount;
                  elements.update({amount: (paymentAmountOpt * 100)});
                }
                stripe.confirmPayment({elements, clientSecret, confirmParams: {
                  return_url: window.location.href
                  }
                  ,redirect: "if_required"
                    }
                    ,{}).then(function(result) {
                    console.log('Went here with result: ' + JSON.stringify(result));
                  if (result.error) {
                    // Inform the customer that there was an error.
                    (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
                    let errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    window.location.href = newUrl + '?paying=error';
                    (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = false;
                  } else if (result.paymentIntent) {
                    url = sessionStorage.getItem('csAPIBase') + 'cs/stripe/chargePaymentIntent/' + invoiceHash + '?paymentIntentId=' + result.paymentIntent.id + '&authOnly=' + authOnly;
                    if(tempTipRate !== 'No Tip' && tempTipAmount >= 0){
                      url += '&tipAmount=' + tempTipAmount + "&tipRate=" + tempTipRate;
                      url = encodeURI(url);
                    }
                    fetch(url, {
                      method: 'POST',
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                      }
                    })
                        .catch(err => {
                          (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
                          let errorElement = document.getElementById('card-errors');
                          let jsonErr = JSON.parse(err);
                          if(jsonErr && jsonErr.message)
                            errorElement.textContent = jsonErr.message;
                          else
                            errorElement.textContent = err;

                          window.location.href = newUrl + '?paying=error';
                        })
                        .then((response: Response) => {
                          if (!response.ok) {
                            return response.text().then(errorInfo => Promise.reject(errorInfo));
                          }
                          return response.text();
                        })
                        .then(data => {
                          let updatedInvoice = JSON.parse(data);
                          if(updatedInvoice.paymentConfirmUrl !== null && updatedInvoice.paymentConfirmUrl.trim() !== "" && updatedInvoice.subStatus !== 'Payment Declined')
                            window.location.href = updatedInvoice.paymentConfirmUrl;
                          else {
                            sessionStorage.setItem('updatedInvoice', data);
                            window.location.href = newUrl + '?paying=false';
                          }
                        })
                  }

                })
              }
            })
            .catch(err => {
              (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
              let errorElement = document.getElementById('card-errors');
              let jsonErr = JSON.parse(err);
              if(jsonErr && jsonErr.message)
                errorElement.textContent = jsonErr.message;
              else
                errorElement.textContent = err;
              window.location.href = newUrl + '?paying=error';
            });


      });
      this.paymentFormLoaded = true;

      if(path === 'invoice') {
        (<HTMLInputElement>document.getElementById('invoiceHash')).value = this.invoice.invoiceHash; //casting so .value will work
        (<HTMLInputElement>document.getElementById('balanceAmount')).value = String(this.invoice.balanceAmount); //casting so .value will work
        (<HTMLInputElement>document.getElementById('paymentAmount')).value = String(this.invoice.paymentAmount); //casting so .value will work
        (<HTMLInputElement>document.getElementById('saveClientPayment')).value = "false"; //casting so .value will work
        let authOnly = this.invoice.preAuthPayment && !this.invoice.hasBeenPreAuthed;
        (<HTMLInputElement>document.getElementById('authOnly')).value = String(authOnly); //casting so .value will work
      } else if(path === 'addCard'){
        (<HTMLInputElement>document.getElementById('clientId')).value = String(this.client.clientId); //casting so .value will work
      }
    } else {
      counter++;
      setTimeout(() => this.createStripePaymentForm(counter), 100)
    }
  }

  addStripeJSFile(){
    this.stripeService.addStripeJSFile();
    if(this.path === 'addCard')
      this.createLumapyAddCardForm(0);
    else
      this.createStripePaymentForm(0)
  }

  ngAfterViewChecked(){
    this.responsiveService.setHeightForEmbedAccount();
  }

  ngOnInit() {
    sessionStorage.setItem("paymentFormPath", this.path);
    let businessId: number;
    this.selectedTipRate = '0';
    if(this.path === 'invoice'){
      businessId = this.invoice.businessId;
    } else if(this.path === 'addCard'){
      this.submitBtnLabel = this.preference.labelMap.clientPaymentsAddCardBtn;
      this.submittingBtnLabel = this.preference.labelMap.clientPaymentsAddingCardBtn;
      businessId = this.client.businessId;
    }
    if (this.invoice && this.invoice.invoiceId) {
      this.businessService.getStripeConfigByInvoice(businessId, this.invoice.invoiceId).subscribe((stripeConfig: any) => {
        this.publishableKey = stripeConfig.stripePublishableKey;
        this.accountId = stripeConfig.stripeAccountId;
        this.addStripeJSFile();
      });
    } else {
      this.businessService.getStripeConfigDefault(businessId).subscribe((stripeConfig: any) => {
        this.publishableKey = stripeConfig.stripePublishableKey;
        this.accountId = stripeConfig.stripeAccountId;
        this.addStripeJSFile();
      });
    }


    sessionStorage.setItem('submittingBtnLabel', this.submittingBtnLabel);
    this.route.queryParams
        .subscribe(params => {
          console.log('Params ' + JSON.stringify(params));
          let paying = params.paying;
          let cardAdded = params.cardAdded;
          if(paying === "false"){
            let updatedInvoice = sessionStorage.getItem('updatedInvoice');
            sessionStorage.removeItem('updatedInvoice');
            this.goBack(JSON.parse(updatedInvoice));
          } else if(paying === 'error'){
            (<HTMLInputElement>document.getElementById('stripe-creditcard')).innerHTML = this.submitBtnLabel;
            (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = false;
            this.submittingBtnLabel = this.tagsService.assignObjectToTags(this.invoice, this.preference.labelMap.invoiceCreditCardChargingLabel, this.tagsService.invoiceFieldTags, this.currency);
            sessionStorage.setItem('invoiceCreditCardChargingLabel', this.submittingBtnLabel);
          }
        })
  }


  goBack(invoice){
    this.onGoBack.emit(invoice);
  }
}

