import {PipeTransform, Pipe} from "@angular/core";
import {CurrencyPipe} from "@angular/common";
import {currency} from "../app/common-classes/app-objects.model";

@Pipe({ name: 'localeCurrency'})

export class LocaleCurrency implements PipeTransform{
    constructor(private currencyPipe: CurrencyPipe) {}
    transform(value: any, currency: currency) {
        return this.currencyPipe.transform(value,currency.currencyCode,"symbol","1.2-2", sessionStorage.getItem('locale'))
    }
}