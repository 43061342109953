export enum MainObjectTypes {
    APPT = 'APPOINTMENT',
    AUDIT_TRAIL = 'AUDIT_TRAIL',
    CLASS = 'CLASS',
    CLIENT = 'CLIENT',
    COUPON = 'COUPON',
    COURSE_SET = 'COURSE_SET',
    COURSE_SET_SESSION = 'COURSE_SET_SESSION',
    COURSE_SET_REGISTRATION = 'COURSE_SET_REGISTRATION',
    DISCLAIMER_FORM = 'DISCLAIMER_FORM',
    DISCLAIMER_FORM_DATA = 'DISCLAIMER_FORM_DATA',
    EMAIL = 'EMAIL',
    EMAIL_CATEGORY = 'EMAIL_CATEGORY',
    EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
    GIFT_CARD = 'GIFT_CARD',
    INVOICE = 'INVOICE',
    INVOICE_ITEM = 'INVOICE_ITEM',
    PACKAGE_SOLD = 'PACKAGE_SOLD',
    PAYMENT = 'PAYMENT',
    PRODUCT = 'PRODUCT',
    REASON = 'REASON',
    REPEATING_APPOINTMENT = 'REPEATING_APPOINTMENT',
    REPEATING_CLASS_SCHEDULE = 'REPEATING_CLASS_SCHEDULE',
    STAFF = 'STAFF',
    TIME_OFF = 'TIME_OFF',
    WAIT_LIST = 'WAIT_LIST',
    WAIT_LIST_HOLD = 'WAIT_LIST_HOLD',
    WAIT_LIST_INVITE = 'WAIT_LIST_INVITE',
    VACATION = 'VACATION'
}
