export class CSSProperties {
    backgroundColor: string;
    borderColor: string;
    color: string;
    justifyContent: string;
    alignment: string;
    float: string;
    position: string;
    top: string;
    fontSize: string;
    borderBottomColor: string;
    borderBottomWidth: string;
    backgroundImage: string;
    stroke: string;
    maxWidth: string;

    constructor(backgroundColor?: string,
                color?: string,
                borderColor?: string,
                justifyContent?: string,
                alignment?: string,
                float?: string,
                position?: string,
                top?: string,
                fontSize?: string,
                borderBottomColor?: string,
                borderBottomWidth?: string,
                backgroundImage?: string,
                stroke?: string,
                maxWidth?: string) {
        this.backgroundColor = backgroundColor;
        this.borderColor = borderColor;
        this.color = color;
        this.justifyContent = justifyContent;
        this.alignment = alignment;
        this.float = float;
        this.position = position;
        this.top = top;
        this.fontSize = fontSize;
        this.borderBottomColor = borderBottomColor;
        this.borderBottomWidth = borderBottomWidth;
        this.backgroundImage = backgroundImage;
        this.stroke = stroke;
        this.maxWidth = maxWidth;
    }

    setStyles(properties){
        this.backgroundColor = properties['backgroundColor'];
        this.borderColor = properties['borderColor'];
        this.color = properties['color'];
        this.justifyContent = properties['justifyContent'];
        this.alignment = properties['alignment'];
        this.float = properties['float'];
        this.position = properties['position'];
        this.top = properties['top'];
        this.fontSize = properties['fontSize'];
        this.borderBottomColor = properties['borderBottomColor'];
        this.borderBottomWidth = properties['borderBottomWidth'];
        this.backgroundImage = properties['backgroundImage'];
        this.stroke = properties['stroke'];
        this.maxWidth = properties['maxWidth'];
    }

    getStylesForStylesheet() {
        let returnObject = {};
        if(this.backgroundColor !== undefined)
            returnObject['background-color'] = this.backgroundColor;
        if(this.color !== undefined)
            returnObject['color'] = this.color;
        if(this.borderColor !== undefined)
            returnObject['border-color'] = this.borderColor;
        if(this.justifyContent !== undefined)
            returnObject['justify-content'] = this.justifyContent;
        if(this.alignment !== undefined)
            returnObject['alignment'] = this.alignment;
        if(this.float !== undefined)
            returnObject['float'] = this.float;
        if(this.position !== undefined)
            returnObject['position'] = this.position;
        if(this.top !== undefined)
            returnObject['top'] = this.top;
        if(this.fontSize !== undefined)
            returnObject['font-size'] = this.fontSize;
        if(this.borderBottomColor !== undefined)
            returnObject['border-bottom-color'] = this.borderBottomColor;
        if(this.borderBottomWidth !== undefined)
            returnObject['border-bottom-width'] = this.borderBottomWidth;
        if(this.backgroundImage !== undefined)
            returnObject['background-image'] = this.backgroundImage;
        if(this.stroke !== undefined)
            returnObject['stroke'] = this.stroke;
        if(this.maxWidth !== undefined)
            returnObject['max-width'] = this.maxWidth;
        return returnObject;
    }
}

//Used to set default values that styleMap should inherit if no values have been saved by business
export class DefaultStyleMap {
    packages: {} = {columns: 3};
}

export class DisplayConfigObj {
    enable: boolean = false;
    children: string[] = [];
}

//Used to hide/show elements on screen
export class MiniWebsiteDisplayConfig {
    store: DisplayConfigObj = {enable: false, children: ['packages']};
    packages: DisplayConfigObj = new DisplayConfigObj();
    waitList: DisplayConfigObj = {enable: false, children: []};
}


export class BaseAlerts {
    infoAlert: CSSProperties = new CSSProperties('#cce5ff','#004085','#b8daff')
    successAlert: CSSProperties = new CSSProperties('#d4edda','#155724','#c3e6cb')
    errorAlert: CSSProperties = new CSSProperties('#f8d7da','#721c24','#f5c6cb')
}

export class BaseLinks {
    linkBaseColor: string = '#1C827C';
}

export class BaseSpinner {
    spinner: CSSProperties = new CSSProperties(undefined, '#1c827c');
}

export class SpinnerElement {
    spinner = {
        savedSelectorValue: 'loadingSpinner',
        strokeSelector: 'mat-progress-spinner.loadingSpinner circle'
    }
}

export class BaseButtons {
    basicBtn: CSSProperties = new CSSProperties(null,'#000000');
    primaryBtn: CSSProperties = new CSSProperties(null,'#0071EB');
    accentBtn: CSSProperties = new CSSProperties(null,'#1C827C');
    // successBtn: CSSProperties = new CSSProperties(null,'#28a745');
    successBtn: CSSProperties = new CSSProperties(null,'#218739');
    errorBtn: CSSProperties = new CSSProperties(null,'#dc3545');
}

export class BaseChips {
    basicChip: CSSProperties = new CSSProperties('#E0e0e0','#000000');
    primaryChip: CSSProperties = new CSSProperties('#0071EB','#ffffff');
    successChip: CSSProperties = new CSSProperties('#28a745','#ffffff');
    warningChip: CSSProperties = new CSSProperties('#ffc107','#212529');
    errorChip: CSSProperties = new CSSProperties('#dc3545','#ffffff');
}

export class BaseFormElements {
    formFieldGeneral: CSSProperties = new CSSProperties(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, '14px');
    formFieldResting: CSSProperties = new CSSProperties(undefined, '#0000008a');
    formFieldFocused: CSSProperties = new CSSProperties(undefined, '#45b6af');
    formFieldInvalid: CSSProperties = new CSSProperties(undefined, '#dc3545');
    formFieldPrint: CSSProperties = new CSSProperties(undefined, '#000000');
    radioDial: CSSProperties = new CSSProperties(undefined, '#45b6af');
    checkBox: CSSProperties = new CSSProperties(undefined, '#45b6af');
}
export class FormElements {
    formFieldGeneral = {
        savedSelectorValue: 'mat-form-field.mat-form-field',
        textSelector: 'mat-form-field.mat-form-field, ' +
            'div.checkbox-list.form-field mat-label, ' +
            'div.radio-list.form-field mat-label, ' +
            'div.file-upload.form-field > mat-label, ' +
            'div.signature-field.form-field > mat-label, ' +
            'div.fieldsInline div.inlineChkBox, ' +
            'div.fieldsInline div.inlineSelect div.mat-select-value span, ' +
            '.mat-list.form-field > mat-list-item.mat-list-item, ' +
            '.mat-checkbox.mat-checkbox-tt, ' +
            'label.customFieldLabel, ' +
            'div.formDescriptionField'
    }
    formFieldResting = {
        savedSelectorValue:'.mat-form-field',
        colorSelector: '.mat-form-field .mat-form-field-label, ' +
        '.mat-form-field .mat-form-field-label > span, ' +
        '.mat-form-field .mat-form-field-required-marker, ' +
        'div.checkbox-list.form-field mat-label, ' +
        'div.radio-list.form-field mat-label, ' +
        'div.file-upload.form-field > mat-label, ' +
        'div.signature-field.form-field > mat-label, ' +
        'mat-hint.mat-hint, div.fieldsInline div.inlineChkBox, ' +
        'div.fieldsInline div.inlineSelect div.mat-select-value span, ' +
        '.mat-list.form-field > mat-list-item.mat-list-item, ' +
        '.mat-select-arrow',
        borderSelector: '.mat-stroked-button:not([disabled])',
        backgroundSelector: '.mat-form-field .mat-form-field-underline',
    };
    formFieldFocused = {
        savedSelectorValue:'.mat-form-field.mat-focused',
        colorSelector: '.mat-form-field.mat-focused .mat-form-field-label, ' +
        '.mat-form-field.mat-focused .mat-form-field-label > span, ' +
        '.mat-form-field.mat-focused .mat-form-field-required-marker',
        backgroundSelector: '.mat-form-field.mat-focused .mat-form-field-ripple',
    };
    formFieldInvalid = {
        savedSelectorValue:'.mat-form-field-invalid',
        colorSelector: '.mat-form-field.mat-form-field-invalid .mat-form-field-label, ' +
        '.mat-form-field.mat-form-field-invalid .mat-form-field-label > span, ' +
        '.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker',
        backgroundSelector: '.mat-form-field.mat-form-field-invalid .mat-form-field-ripple',
    };
    formFieldDisabled = {
        savedSelectorValue:'.mat-form-field-disabled',
        colorSelector: '.mat-form-field.mat-form-field-disabled .mat-form-field-label, ' +
        '.mat-form-field.mat-form-field-disabled .mat-form-field-label > span, ' +
        'div.checkbox-list.form-field.mat-form-field-disabled mat-label, ' +
        'div.radio-list.form-field.mat-form-field-disabled mat-label, ' +
        'div.file-upload.form-field.mat-form-field-disabled > mat-label, ' +
        'div.signature-field.form-field.mat-form-field-disabled > mat-label, ' +
        '.mat-form-field-disabled .mat-form-field-required-marker' +
        '.formDisabled .mat-input-element:disabled,' +
        '.formDisabled div.checkbox-list div.printCheckboxes, ' +
        '.formDisabled label.customFieldLabel, ' +
        '.formDisabled div.formDescriptionField',
        dottedUnderlineSelector: '.mat-form-field.mat-form-field-disabled .mat-form-field-underline',
    };
    formFieldPrint = {
        savedSelectorValue:'.mat-form-field-print',
        colorSelector: '#printSection .mat-form-field.mat-form-field-disabled .mat-form-field-label, ' +
        '#printSection .mat-form-field.mat-form-field-disabled .mat-form-field-label > span, ' +
        '#printSection div.checkbox-list.form-field.mat-form-field-disabled mat-label, ' +
        '#printSection div.checkbox-list.form-field.mat-form-field-disabled .mat-checkbox-label, ' +
        '#printSection div.radio-list.form-field.mat-form-field-disabled mat-label, ' +
        '#printSection div.file-upload.form-field.mat-form-field-disabled > mat-label, ' +
        '#printSection div.signature-field.form-field.mat-form-field-disabled > mat-label, ' +
        '#printSection .mat-form-field-disabled .mat-form-field-required-marker,' +
        '#printSection .mat-input-element:disabled,' +
        '#printSection div.checkbox-list div.printCheckboxes, ' +
        '#printSection label.customFieldLabel, ' +
        '#printSection div.formDescriptionField',
        dottedUnderlineSelector: '#printSection .mat-form-field.mat-form-field-disabled .mat-form-field-underline',
    };
    radioDial = {
        savedSelectorValue:'.mat-radio-button-tt',
        borderSelector: '.mat-radio-button.mat-radio-button-tt.mat-radio-checked .mat-radio-outer-circle,' +
        '.mat-radio-outer-circle',
        backgroundSelector: '.mat-radio-button.mat-radio-button-tt .mat-radio-inner-circle,' +
            '.mat-radio-button.mat-radio-button-tt .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-radio-button-tt.mat-radio-checked .mat-radio-persistent-ripple',
    };
    checkbox = {
        savedSelectorValue:'.mat-checkbox-tt',
        borderSelector: '.mat-checkbox.mat-checkbox-tt.mat-checkbox-checked,' +
        '.mat-checkbox-frame',
        backgroundSelector: '.mat-checkbox.mat-checkbox-tt .mat-checkbox-ripple .mat-ripple-element:not(.mat-checkbox-disabled),' +
            '.mat-checkbox.mat-checkbox-tt.mat-checkbox-checked .mat-checkbox-background',
    };
}

export class MatListElements {
    listItemInactive = {
        savedSelectorValue:'.mat-list-item',
        colorSelector:'mat-list-item.mat-list-item div.mat-list-text',
        backgroundSelector: 'mat-list-item.mat-list-item'
    }
    listItemActive = {
        savedSelectorValue:'mat-list > .mat-list-item.selected,mat-list > .mat-list-item:hover',
        colorSelector:'mat-list-item.mat-list-item.selected div.mat-list-text,mat-list-item.mat-list-item:hover div.mat-list-text',
        backgroundSelector: 'mat-list-item.mat-list-item.selected, mat-list-item.mat-list-item:hover'
    }
}

export class CardElements {
    headerBorderSelector = ''
}

export class NewToOldStylesMap {
    map = [{newProp:'body',oldProps:[{oldPropName: 'websiteBackgroundColor', newPropProperty: 'backgroundColor'},
                    {oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'h1',oldProps:[{oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'h2',oldProps:[{oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'h3',oldProps:[{oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'h4',oldProps:[{oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'h5',oldProps:[{oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'h6',oldProps:[{oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'label',oldProps:[{oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'span',oldProps:[{oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'p',oldProps:[{oldPropName: 'baseTextColor', newPropProperty: 'color'}]},
            {newProp:'businessInfo',oldProps: [{oldPropName: 'businessInfoColor', newPropProperty: 'backgroundColor'}]},
            {newProp:'policy',oldProps: [{oldPropName: 'policyBackgroundColor', newPropProperty: 'backgroundColor'}]},
            {newProp:'footer',oldProps:[{oldPropName: 'footerBackgroundColor', newPropProperty: 'backgroundColor'},
                    {oldPropName: 'footerTextColor', newPropProperty: 'color'}]},
            {newProp:'pageHeader',oldProps: [{oldPropName: 'navigationBackgroundColor', newPropProperty: 'backgroundColor'}]},
            {newProp:'schedulerHeaderText',oldProps:[{oldPropName: 'headerColor', newPropProperty: 'color'}]},
            {newProp: 'businessLogo', oldProps: [{oldPropName: 'logoAlign', newPropProperty: 'alignment'}]},
            {newProp: 'primaryBtn', oldProps: [{oldPropName: 'primaryButtonBaseColor', newPropProperty: 'color'}]},
            {newProp: 'basicBtn', oldProps: [{oldPropName: 'basicButtonBaseColor', newPropProperty: 'color'}]},
            {newProp: 'successBtn', oldProps: [{oldPropName: 'successButtonBaseColor', newPropProperty: 'color'}]},
            {newProp: 'errorBtn', oldProps: [{oldPropName: 'errorButtonBaseColor', newPropProperty: 'color'}]},
            {newProp: 'accentBtn', oldProps: [{oldPropName: 'accentButtonBaseColor', newPropProperty: 'color'}]},
            {newProp: 'darkAccentBtn', oldProps: [{oldPropName: 'darkAccentBtnBaseColor', newPropProperty: 'color'}]},
            {newProp: 'loginBtn', oldProps: [{oldPropName: 'loginBtnBaseColor', newPropProperty: 'color'}]},
            {newProp: 'registerBtn', oldProps: [{oldPropName: 'registerBtnBaseColor', newPropProperty: 'color'}]},
            {newProp: 'logoutBtn', oldProps: [{oldPropName: 'logoutBtnBaseColor', newPropProperty: 'color'}]},
            {newProp: 'backBtn', oldProps: [{oldPropName: 'backBtnBaseColor', newPropProperty: 'color'}]},
            {newProp: 'nextBtn', oldProps: [{oldPropName: 'backBtnBaseColor', newPropProperty: 'color'}]},
            {newProp: 'successBtn', oldProps: [{oldPropName: 'successButtonBaseColor', newPropProperty: 'color'}]},
            {newProp: 'footerTimeTapLink', oldProps: [{oldPropName: 'footerLinkColor', newPropProperty: 'color'}]},
            {newProp: 'basicChip', oldProps: [{oldPropName: 'defaultChipBGColor', newPropProperty: 'backgroundColor'},
                    {oldPropName: 'defaultChipFontColor', newPropProperty: 'color'}]},
            {newProp: 'selectedDayOnCalendar', oldProps: [{oldPropName: 'darkAccentBtnBaseColor', newPropProperty: 'backgroundColor'},
                    {oldPropName: 'linkWithBGBaseColor', newPropProperty: 'color'}]},
            {newProp: 'dayHasAvailability', oldProps: [{oldPropName: 'accentButtonBaseColor', newPropProperty: 'backgroundColor'},
                    {oldPropName: 'linkWithBGBaseColor', newPropProperty: 'color'}]},
            {newProp: '.mat-checkbox-tt', oldProps: [{oldPropName: 'accentTextColor', newPropProperty: 'color'}]},
            {newProp: '.mat-radio-button-tt', oldProps: [{oldPropName: 'accentTextColor', newPropProperty: 'color'}]},
            {newProp: '.mat-form-field', oldProps: [{oldPropName: 'accentTextColor', newPropProperty: 'color'}]},
            {newProp: 'a', oldProps: [{oldPropName: 'linkBaseColor', newPropProperty: 'color'}]}
        ]
}


