import {SingleTimeSlot} from '../app/scheduler/time/time-objects.model';

export class DateAvailability {
    constructor(
        public clustered: boolean,
        public date: string,
        public timeSlots: SingleTimeSlot[]
    ) {
    }
}
