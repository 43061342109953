import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
// parent level locales (without country)
import localeArabic from '@angular/common/locales/ar';
import localeBulgarian from '@angular/common/locales/bg';
import localeCatalan from '@angular/common/locales/ca';
import localeDanish from '@angular/common/locales/da';
import localeGerman from '@angular/common/locales/de';
import localeGreek from '@angular/common/locales/el';
import localeSpanish from '@angular/common/locales/es';
import localeFinnish from '@angular/common/locales/fi';
import localeFilipino from '@angular/common/locales/fil';
import localeFrench from '@angular/common/locales/fr';
import localeHebrew from '@angular/common/locales/he';
import localeHindi from '@angular/common/locales/hi';
import localeIcelandic from '@angular/common/locales/is';
import localeIndonesian from '@angular/common/locales/id';
import localeItalian from '@angular/common/locales/it';
import localeJapanese from '@angular/common/locales/ja';
import localeKorean from '@angular/common/locales/ko';
import localeDutch from '@angular/common/locales/nl';
import localeNorwegianBokmal from '@angular/common/locales/nb';
import localeNorwegianNynorsk from '@angular/common/locales/nn';
import localePortuguese from '@angular/common/locales/pt';
import localeRussian from '@angular/common/locales/ru';
import localeSwedish from '@angular/common/locales/sv';
import localeTurkish from '@angular/common/locales/tr';
import localeVietnamese from '@angular/common/locales/vi';
import localeChinese from '@angular/common/locales/zh';
// English locales
import localeEnglishGB from '@angular/common/locales/en-GB';
import localeEnglisAU from '@angular/common/locales/en-AU';
// Spanish locales
import localeSpanishArgentina from '@angular/common/locales/es-AR';
import localeSpanishBolivia from '@angular/common/locales/es-BO';
import localeSpanishChile from '@angular/common/locales/es-CL';
import localeSpanishColombia from '@angular/common/locales/es-CO';
import localeSpanishCostaRica from '@angular/common/locales/es-CR';
import localeSpanishCuba from '@angular/common/locales/es-CU';
import localeSpanishDR from '@angular/common/locales/es-DO';
import localeSpanishEcuador from '@angular/common/locales/es-EC';
import localSpanishElSalvador from '@angular/common/locales/es-SV';
import localeSpanishGuatemala from '@angular/common/locales/es-GT';
import localeSpanishHonduras from '@angular/common/locales/es-HN';
import localeSpanishMexico from '@angular/common/locales/es-MX';
import localeSpanishNicaragua from '@angular/common/locales/es-NI';
import localeSpanishPanama from '@angular/common/locales/es-PA';
import localeSpanishParaguay from '@angular/common/locales/es-PY';
import localeSpanishPeru from '@angular/common/locales/es-PE';
import localeSpanishPuertoRico from '@angular/common/locales/es-PR';
import * as moment from "moment/moment";

@Injectable({ providedIn: 'root' })
export class LocaleService {

    private _locale: string;

    constructor() {
        let storedLocale = 'en-US';
        try {
            storedLocale = sessionStorage.getItem('locale');
        } catch (e) {

        }
        moment.locale(storedLocale);
    }

    set locale(value: string) {
        this._locale = value;
    }
    get locale(): string {
        return this._locale || 'en-US';
    }



    registerCulture(culture: string) {
        if (!culture) {
            return;
        }
        this.locale = culture;
        let fullLocaleFound = this.setLocaleWithLanguageAndCountry(culture);

        if(!fullLocaleFound) {
            this.setLocaleWithLanguageOnly(culture);
        }
    }

    setLocaleWithLanguageAndCountry(culture: string): boolean{
        switch (culture) {
            case 'en-GB':
                registerLocaleData(localeEnglishGB, culture);
                return true;
                break;
            case 'en-AU':
                registerLocaleData(localeEnglishGB, culture);
                return true;
                break;
            case 'es-AR':
                registerLocaleData(localeSpanishArgentina, culture);
                return true;
                break;
            case 'es-BO':
                registerLocaleData(localeSpanishBolivia, culture);
                return true;
                break;
            case 'es-CL':
                registerLocaleData(localeSpanishChile, culture);
                return true;
                break;
            case 'es-CO':
                registerLocaleData(localeSpanishColombia, culture);
                return true;
                break;
            case 'es-CR':
                registerLocaleData(localeSpanishCostaRica, culture);
                return true;
                break;
            case 'es-CU':
                registerLocaleData(localeSpanishCuba, culture);
                return true;
                break;
            case 'es-DO':
                registerLocaleData(localeSpanishDR, culture);
                return true;
                break;
            case 'es-EC':
                registerLocaleData(localeSpanishEcuador, culture);
                return true;
                break;
            case 'es-SV':
                registerLocaleData(localSpanishElSalvador, culture);
                return true;
                break;
            case 'es-GT':
                registerLocaleData(localeSpanishGuatemala, culture);
                return true;
                break;
            case 'es-HN':
                registerLocaleData(localeSpanishHonduras, culture);
                return true;
                break;
            case 'es-MX':
                registerLocaleData(localeSpanishMexico, culture);
                return true;
                break;
            case 'es-NI':
                registerLocaleData(localeSpanishNicaragua, culture);
                return true;
                break;
            case 'es-PA':
                registerLocaleData(localeSpanishPanama, culture);
                return true;
                break;
            case 'es-PY':
                registerLocaleData(localeSpanishParaguay, culture);
                return true;
                break;
            case 'es-PE':
                registerLocaleData(localeSpanishPeru, culture);
                return true;
                break;
            case 'es-PR':
                registerLocaleData(localeSpanishPuertoRico, culture);
                return true;
                break;
            case 'en-NZ':
                registerLocaleData(localeEnglishGB, culture);
                return true;
                break;
            default:
                if(culture !== 'en-US'){
                    registerLocaleData(localeEnglishGB, culture);
                }
                return true;
        }
        return false;
    }

    setLocaleWithLanguageOnly(culture: string){
        let languageSubstring = culture;
        if (culture.indexOf("-") !== -1) {
            let indexOfHyphen = culture.indexOf("-");
            languageSubstring = culture.substring(0, indexOfHyphen);
        }

        // Register locale data since only the en-US locale data comes with Angular
        switch (languageSubstring) {
            case 'ar':
                registerLocaleData(localeArabic, culture);
                break;
            case 'bg':
                registerLocaleData(localeBulgarian, culture);
                break;
            case 'ca':
                registerLocaleData(localeCatalan, culture);
                break;
            case 'da':
                registerLocaleData(localeDanish, culture);
                break;
            case 'de':
                registerLocaleData(localeGerman, culture);
                break;
            case 'el':
                registerLocaleData(localeGreek, culture);
                break;
            case 'es':
                registerLocaleData(localeSpanish, culture);
                break;
            case 'fi':
                registerLocaleData(localeFinnish, culture);
                break;
            case 'fil':
                registerLocaleData(localeFilipino, culture);
                break;
            case 'fr':
                registerLocaleData(localeFrench, culture);
                break;
            case 'he':
                registerLocaleData(localeHebrew, culture);
                break;
            case 'hi':
                registerLocaleData(localeHindi, culture);
                break;
            case 'id':
                registerLocaleData(localeIndonesian, culture);
                break;
            case 'is':
                registerLocaleData(localeIcelandic, culture);
                break;
            case 'it':
                registerLocaleData(localeItalian, culture);
                break;
            case 'ja':
                registerLocaleData(localeJapanese, culture);
                break;
            case 'ko':
                registerLocaleData(localeKorean, culture);
                break;
            case 'no':
            case 'nb':
                registerLocaleData(localeNorwegianBokmal, culture);
                break;
            case 'nn':
                registerLocaleData(localeNorwegianNynorsk, culture);
                break;
            case 'nl':
                registerLocaleData(localeDutch, culture);
                break;
            case 'pt':
                registerLocaleData(localePortuguese, culture);
                break;
            case 'ru':
                registerLocaleData(localeRussian, culture);
                break;
            case 'sv':
                registerLocaleData(localeSwedish, culture);
                break;
            case 'tr':
                registerLocaleData(localeTurkish, culture);
                break;
            case 'vi':
                registerLocaleData(localeVietnamese, culture);
                break;
            case 'zh':
                registerLocaleData(localeChinese, culture);
                break;
        }
    }
}
