import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    CSFilterParameterBean, movePanels, preference
} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {ResponsiveService} from "../../responsive.service";
import {SelectSchedulingObject} from "../common-components/scheduler-list-items/scheduler-list-items.component";
import {Staff} from '../../../models/Staff.model';
import {schedulerPanel} from '../../../models/SchedulerPanel.model';
import {SchedulerService} from '../scheduler.service';
@Component({
  selector: 'staff-panel',
  templateUrl: './staff.component.html'
})
export class StaffComponent implements OnInit {
    @Output() onMovePanels = new EventEmitter<movePanels>();
    @Input() preference: preference;
    @Input() panel: schedulerPanel;
    @Input() CSFilterObj: CSFilterParameterBean;
    @Input() directionComingFrom: string;
    staffs: Staff[] = [];
    showBackButton: boolean = true;
    noPreferenceStaff: Staff;
    staffLoaded: boolean = false;
    errorLoading: boolean = false;
    searchingStaff: boolean = false;
    staffPnlNoStaffFilterSearchMsg: string = '';
    totalStaffLength: number;
    constructor(private businessService: BusinessService, private responsiveService: ResponsiveService, private cdRef: ChangeDetectorRef, private schedulerService: SchedulerService) { }

    getStaff(CSFilterObj: CSFilterParameterBean, filterSearch: boolean){
        this.searchingStaff = filterSearch;
        this.businessService.getStaff(CSFilterObj)
            .subscribe((staffs: Staff[]) => {
                this.staffs = staffs;
                if(this.preference.staffNoPreference === 0 && this.staffs.length > 1){
                    this.noPreferenceStaff = new Staff(null, this.preference.labelMap.labelNoPreference);
                    this.noPreferenceStaff.imageUrl = null;
                    this.noPreferenceStaff.staffProfile = null;

                    this.staffs.unshift(this.noPreferenceStaff);
                }
                if(this.staffs.length === 1 && this.directionComingFrom === 'next'){
                    // this.panel.formGroup.patchValue({
                    //     staff: this.staffs[0]
                    // });
                    if(this.CSFilterObj.staffIdList === null) {
                        this.selectObject(this.staffs[0], this.preference.autoAdvanceWhenSingle)
                    }
                }
                if(this.staffs.length === 0 && CSFilterObj.filterStaff !== undefined && CSFilterObj.filterStaff !== null && CSFilterObj.filterStaff !== ''){
                    this.staffPnlNoStaffFilterSearchMsg = this.preference.labelMap.staffPnlNoStaffFilterSearchMsg.replace(new RegExp('%FILTER_SEARCH%', 'g'), CSFilterObj.filterStaff);
                }
                if(!this.searchingStaff){
                    this.totalStaffLength = staffs.length;
                }
                if(this.panel.formGroup.value.staff !== null){
                    let objFound = false;
                    for(let i = 0, x = this.staffs.length; i < x; i++){
                        if(this.staffs[i].professionalId === this.panel.formGroup.value.staff.professionalId){
                            this.staffs[i].selected = true;
                            objFound = true;
                        }
                    }
                    if(!objFound){
                        this.panel.formGroup = this.schedulerService.getPanelForm(this.panel.panel, this.preference.schedulerPreferenceFieldDefnList);
                    }
                }
                this.staffLoaded = true;
                this.searchingStaff = false;
                this.cdRef.detectChanges();
            }, (error)=> {
                this.staffLoaded = false;
                this.errorLoading = true;
                this.searchingStaff = false;
            })
    }

    ngOnInit() {
      this.responsiveService.setHeightForEmbedScheduler();
      this.getStaff(this.CSFilterObj, false)
    }
    movePanels(direction: string){
        if (direction === 'back')
            this.CSFilterObj.filterStaff = null;
        this.onMovePanels.emit({direction: direction, panel: this.panel})
    }

    selectSchedulingObject(schedulingObject: SelectSchedulingObject){
        this.selectObject(schedulingObject.itemSelected, schedulingObject.moveForward, schedulingObject.changeEvent);
    }

    selectObject(staff: Staff, moveForward: boolean, event?: MouseEvent){
        if(!this.businessService.checkInnerHTMLForHrefClick(event)) {
            staff.selected = true;
            this.panel.formGroup.patchValue({staff: staff});
            if (moveForward) {
                this.movePanels('next');
            }
        }
    }

}
