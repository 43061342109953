import {AfterViewChecked, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource} from "@angular/material";
import {ClientAccountService} from "../../client-account.service";
import {TagsService} from "../../../tags.service";
import {
    Client, ClientAccount, miniWebsite, preference, RecurringAppointment,
    schedulerLink
} from "../../../common-classes/app-objects.model";
import {DatePipe, TitleCasePipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import * as momentTimezone from "moment-timezone/moment-timezone";
import {BusinessService} from "../../../business.service";
import {TimeService} from "../../../scheduler/time/time.service";
import * as moment from "moment";
import {ResponsiveService} from "../../../responsive.service";
import {MomentTimezoneService} from "../../../scheduler/time/moment-timezones.service";
import {TableColumnDataFilterFormat, TableColumnDataModel} from "../../../common-classes/TableColumnData.model";
import {DataTablesService} from "../../../data-tables.service";
import {WaitList} from '../../../../models/WaitList.model';
import {Appointment} from '../../../../models/Appointment.model';
import {SchedulerPreferenceService} from '../../../scheduler-preference.service';

@Component({
    selector: 'app-view-appointments-list',
    templateUrl: './view-appointments-list.component.html',
})
export class ViewAppointmentsListComponent implements OnInit, AfterViewChecked {
    pageLoaded = false;
    dataLoaded = false;
    errorLoading = false;
    loadingData = false;
    preference: preference;
    clientAccount: ClientAccount;
    selectedClient: Client;
    titleBarProp: string;
    noApptsFoundProp: string;
    selectedListView: string;
    appts: Appointment[];
    repeatingAppts: RecurringAppointment[];
    waitlists: WaitList[];
    allApptStatuses = [
        {'status': 'OPEN', 'selected': false, 'buttonLabel': 'upcomingApptBtnLabel', 'titleBarProp': 'openApptTitle', 'noApptsFoundProp': 'noOpenApptsMsg'},
        {'status': 'COMPLETED', 'selected': false, 'buttonLabel': 'completedApptBtnLabel', 'titleBarProp': 'completedApptTitle', 'noApptsFoundProp': 'noCompletedApptsMsg'},
        {'status': 'CANCELLED', 'selected': false, 'buttonLabel': 'cancelledApptBtnLabel', 'titleBarProp': 'cancelledApptTitle', 'noApptsFoundProp': 'noCancelledApptsMsg'},
        {'status': 'NO_SHOW', 'selected': false, 'buttonLabel': 'noshowApptBtnLabel', 'titleBarProp': 'noshowApptTitle', 'noApptsFoundProp': 'noNoShowApptsMsg'},
        {'status': 'REPEATING', 'selected': false, 'buttonLabel': 'repeatingApptBtnLabel', 'titleBarProp': 'repeatingApptTitle', 'noApptsFoundProp': 'noRepeatingApptsMsg'},
        {'status': 'WAITLIST', 'selected': false, 'buttonLabel': 'waitlistRegistrationBtnLabel', 'titleBarProp': 'waitlistRegistrationTitle', 'noApptsFoundProp': 'noWaitlistRegistrationsMsg'}
    ];
    apptStatuses = [];

    constructor(private businessService: BusinessService,
                private clientAccountService: ClientAccountService,
                private route: ActivatedRoute,
                private timeService: TimeService,
                private tagsService: TagsService,
                private router: Router,
                private titleCasePipe: TitleCasePipe,
                private datePipe: DatePipe,
                private dataTablesService: DataTablesService,
                private responsiveService: ResponsiveService,
                private schedulerPreferenceService: SchedulerPreferenceService) {
        this.preference = schedulerPreferenceService.schedulerPreference;
        moment.locale(sessionStorage.getItem('locale'));
    }
    goBack(){
        this.router.navigate(['/my-account'], {relativeTo: this.route.root});
    }

    selectClient(changeEvent: any){
        let client = changeEvent.client;
        let event = changeEvent.event;
        if(client.clientId !== this.selectedClient.clientId && event.isUserInput){
            this.selectedClient = client;
            this.clientAccountService.selectedClient = this.selectedClient;
            this.getApptsByStatus();
        }
    }

    getApptsByStatus(){
        this.loadingData = true;
        for(let i = 0, x = this.apptStatuses.length; i < x; i++){
            if(this.apptStatuses[i].selected){
                this.titleBarProp = this.apptStatuses[i].titleBarProp;
                this.noApptsFoundProp = this.apptStatuses[i].noApptsFoundProp;
                this.pageLoaded = true;
                switch(this.apptStatuses[i].status){
                    case 'REPEATING':
                        this.selectedListView = 'REPEATING';
                        this.clientAccountService.getRepeatingAppts(this.selectedClient.clientId)
                            .subscribe((repeatingAppts: RecurringAppointment[]) => {
                                this.repeatingAppts = repeatingAppts;
                                this.dataLoaded = true;
                                this.loadingData = false;
                                this.errorLoading = false;
                            }, error => {
                                this.dataLoaded = false;
                                this.loadingData = false;
                                this.errorLoading = true;
                            });
                        break;
                    case 'WAITLIST':
                        this.selectedListView = 'WAITLIST';
                        // get waitlist registrations getWaitlistRegistrations
                        this.clientAccountService.getWaitlistRegistrations(this.selectedClient.clientId)
                            .subscribe((waitlistRegistrations: WaitList[]) => {
                                // console.log(waitlistRegistrations);
                                this.waitlists = waitlistRegistrations;
                                this.dataLoaded = true;
                                this.loadingData = false;
                                this.errorLoading = false;
                            }, error => {
                                this.dataLoaded = false;
                                this.loadingData = false;
                                this.errorLoading = true;
                            });
                        break;
                    default:
                        this.selectedListView = 'APPOINTMENTS';
                        this.clientAccountService.getApptsByStatus(this.selectedClient.clientId, this.apptStatuses[i].status)
                            .subscribe((appts: Appointment[]) => {
                                this.appts = appts;
                                this.dataLoaded = true;
                                this.loadingData = false;
                                this.errorLoading = false;
                            }, error => {
                                this.dataLoaded = false;
                                this.loadingData = false;
                                this.errorLoading = true;
                            })
                }

            }
        }
    };

    selectStatus = function(status: any){
        for(let i = 0, x = this.apptStatuses.length; i < x; i++){
            if(this.apptStatuses[i].status === status.status){
                this.apptStatuses[i].selected = true;
            } else {
                this.apptStatuses[i].selected = false;
            }
        }
        this.getApptsByStatus();
        this.fixRowHeaders();
    };

    fixRowHeaders() {
        let rowHeaders: HTMLCollectionOf<HTMLElement>;
        rowHeaders = document.getElementsByClassName('rowHeader') as HTMLCollectionOf<HTMLElement>;
        Array.from(rowHeaders).forEach(header => {
            header.removeAttribute('role');
            header.setAttribute('role','rowheader');
        });
    }

    ngAfterViewChecked(){
        this.responsiveService.setHeightForEmbedAccount();
        this.fixRowHeaders();
    }

    ngOnInit() {
        if(this.businessService.business){
            if(this.businessService.business.status !== 'ACTIVE'){
                this.dataLoaded = false;
                this.errorLoading = true;
            } else {
                this.clientAccount = this.clientAccountService.loggedInClient;
                if(this.clientAccountService.selectedClient !== undefined && this.clientAccountService.selectedClient !== null){
                    for(let i = 0, x = this.clientAccount.clientList.length; i < x; i++){
                        if(this.clientAccount.clientList[i].clientId === this.clientAccountService.selectedClient.clientId){
                            this.selectedClient = this.clientAccount.clientList[i];
                        }
                    }
                } else {
                    this.selectedClient = this.clientAccountService.selectClientBasedOnLoggedInEmail();
                    this.clientAccountService.setSelectedClient(this.selectedClient, this.preference.schedulerPreferenceFieldDefnList);
                }
                if(this.selectedClient === undefined && this.clientAccountService.loggedInClient !== undefined){
                    this.selectedClient = this.clientAccountService.loggedInClient.clientList[0];
                }
                if(this.selectedClient.timeZoneCode === null){
                    this.selectedClient.timeZoneCode = {'timeZoneCode': sessionStorage.getItem("timezone")}
                }
            }
            // LHB 09/03/2020 TT-6646
            if(this.preference.appointmentListStatuses === undefined || this.preference.appointmentListStatuses === null || this.preference.appointmentListStatuses.length === 0)
                this.preference.appointmentListStatuses = ['OPEN','COMPLETED','CANCELLED','NO_SHOW'];
            for(let i = 0, x = this.preference.appointmentListStatuses.length; i < x; i++){
                for(let j = 0, y = this.allApptStatuses.length; j < y; j++){
                    if(this.preference.appointmentListStatuses[i] === this.allApptStatuses[j].status)
                        this.apptStatuses.push(this.allApptStatuses[j]);
                }
            }
            let statusPassedThroughHistoryState = false;
            if(history.state !== undefined && history.state !== null && history.state.listStatus !== undefined && history.state.listStatus !== null){
                let statusToFind = history.state.listStatus;
                for(let i = 0, x = this.allApptStatuses.length; i < x; i++){
                    if(this.allApptStatuses[i].status === statusToFind){
                        this.allApptStatuses[i].selected = true;
                        statusPassedThroughHistoryState = true;
                    }
                }
            }
            if(!statusPassedThroughHistoryState)
                this.allApptStatuses[0].selected = true;
            this.getApptsByStatus();
        } else {
            this.dataLoaded = false;
            this.errorLoading = true;
        }
    }

}


