export class ScreeningQuestion {
    constructor(
        public screeningQuestionId: number,
        public businessId: number,
        public name: string,
        public question: string,
        public failButton: string,
        public failResponse: string,
        public passButton: string
    ) {
    }
}
