import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ResponsiveService} from '../../responsive.service';

@Component({
  selector: 'app-field-label',
  template: `
    <label class="col-form-label"
           [ngClass]="{'text-right': labelAlign==='right', 'text-left': labelAlign==='left', 'text-center': labelAlign==='center', 'hasError': hasError,
                'pr-0': removePaddingRight, 'pl-0': removePaddingLeft, 'italicText': italics}">
      <a *ngIf="externalLink" href="{{externalLink}}" target="_blank"><i class="fas fa-external-link-alt"></i> </a><span
        *ngIf="required" class="text-danger">* </span> {{label | titlecase}}:
    </label>
    <div [ngClass]="{'text-right': labelAlign==='right', 'text-left': labelAlign==='left', 'text-center': labelAlign==='center', 'pr-0': removePaddingRight, 'pl-0': removePaddingLeft}" *ngIf="showHelpLink">
      <a (click)="helpLinkClicked.emit()" class="ttLink">{{helpLinkText}}</a>
    </div>
  `,
  styles: ['.hasError {color: #f64e60;} .col-form-label {z-index: 0; width: 100%}']
})
export class FieldLabelComponent implements OnInit {
  @Input() labelAlign: string = 'right';
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() italics: boolean = false;
  @Input() removePaddingRight: boolean = this.responsiveService.onMobileViewPort;
  @Input() removePaddingLeft: boolean = this.responsiveService.onMobileViewPort;
  @Input() externalLink: string;
  @Input() hasError: boolean;
  @Input() showHelpLink: boolean;
  @Input() helpLinkText: string;
  @Output() helpLinkClicked = new EventEmitter();
  constructor(private responsiveService: ResponsiveService) { }

  ngOnInit() {
  }

}
