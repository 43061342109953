import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ClientAccountHeaderComponent} from "./client-account-header.component";
import {MaterialModule} from "../../material.module";
import {UIElementsModule} from "../../ui-elements/ui-elements.module";

@NgModule({
    declarations: [
        ClientAccountHeaderComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        UIElementsModule
    ],
    exports: [
        ClientAccountHeaderComponent
    ]
})
export class ClientAccountHeaderModule{}