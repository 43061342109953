import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FocusMonitor} from '@angular/cdk/a11y';
import {ScreeningQuestion} from '../../../models/ScreeningQuestion.model';

@Component({
  selector: 'app-screening-question',
  templateUrl: './screening-question.component.html'
})
export class ScreeningQuestionComponent implements OnInit, AfterViewInit {
    screeningQuestion: ScreeningQuestion;
    screeningQuestionStatus: string = 'indeterminate';
    constructor(@Inject(MAT_DIALOG_DATA) private passedData: any, public dialogRef: MatDialogRef<ScreeningQuestionComponent>, private _focusMonitor: FocusMonitor) { }

    ngAfterViewInit(): void {
        this._focusMonitor.stopMonitoring(document.getElementById('screeningQuestionFailBtn'));
    }

    ngOnInit() {
        this.screeningQuestion = this.passedData.screeningQuestion;
    }

    answerScreeningQuestion(screeningQuestionStatus: string){
        this.screeningQuestionStatus = screeningQuestionStatus;
        if(this.screeningQuestionStatus === 'pass'){
            this.dialogRef.close(this.screeningQuestionStatus);
        }
    }

}
