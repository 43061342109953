import { Component, OnInit } from '@angular/core';
import {StoreService} from "../store.service";
import {currency, preference} from "../../common-classes/app-objects.model";
import {Package} from "./packages.model";
import {StyleSheetService} from "../../styleSheetService";
import {BusinessService} from "../../business.service";
import {ResponsiveService} from "../../responsive.service";
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {Location} from '../../../models/Location.model';

@Component({
  selector: 'packages-list',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {
  packages: Package[];
  preference: preference;
  currency: currency;
  styleMap: any;
  packagesLoading: boolean = true;
  errorLoading: boolean = false;
  packagesLoaded: boolean = false;
  constructor(private storeService: StoreService, private styleSheetService: StyleSheetService,
              private schedulerPreferenceService: SchedulerPreferenceService,
              private businessService: BusinessService, private responsiveService: ResponsiveService) { }

  getPackages(){
    this.packagesLoading = true;
    this.storeService.getPackages()
        .subscribe((packages: Package[]) => {
          this.packages = packages;
          // for(let i = 0, x = this.packages.length; i < x; i++){
          //
          // }
          this.packagesLoading = false;
          this.packagesLoaded = true;
          this.errorLoading = false;
        }, (error)=> {
          this.packagesLoading = false;
          this.packagesLoaded = false;
          this.errorLoading = true;
        })
  }

  ngOnInit() {
      this.styleMap = this.styleSheetService.mwStyleMap;
      this.preference = this.schedulerPreferenceService.schedulerPreference;
      this.currency = this.businessService.currency;
      // console.log("this.styleMap " )
      // console.log(this.styleMap);
      this.getPackages();
  }

}
