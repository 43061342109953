import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, NgForm} from "@angular/forms";
import {Client, ClientAccount, loginRegisterPopUpObject, preference} from "../../common-classes/app-objects.model";
import {TimeZoneCode} from "../../scheduler/time/time-objects.model";
import {AuthService} from "../auth.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import * as _ from "lodash";
import {BusinessService} from "../../business.service";
import {ClientInfoService} from "../../scheduler/client-info/client-info.service";
import {TagsService} from "../../tags.service";
import {ClientAccountService} from "../../client-account/client-account.service";
import {GoogleAnalyticsService} from "../../google-analytics.service";
import {TitleCasePipe} from "@angular/common";
import {ConfigListService} from '../../config-list.service';
import {SchedulerPanelValues} from '../../../models/SchedulerPanel.model';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {TimezoneService} from '../../scheduler/common-components/timezone/timezone.service';
import {ErrorModalComponent} from '../../modals/error-modal/error-modal.component';
import {ResponsiveService} from '../../responsive.service';
import * as _moment from 'moment';
const moment = _moment;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
    styleUrls: ['../../form-field-styles.component.css']
})
export class RegisterComponent implements OnInit {
  preference: preference;
  clientExists: boolean = false;
  clientNeedsToRegister: boolean = false;
  resetPwd: boolean = false;
  disableEmailAddress: boolean = true;
  connectClient: boolean = false;
  loginRegisterObj: loginRegisterPopUpObject = new loginRegisterPopUpObject();
  title: string = '';
  description: string = '';
  buttonLabel: string = '';
  checkingEmail: boolean = false;
  checkingEmailFailed: boolean = false;
  registeringClient: boolean = false;
  registrationFailed: boolean = false;
  timezonesFailed: boolean = false;
  loginFailed: boolean = false;
  requiredFieldsEmpty: boolean = false;
  requiredFieldsEmptyMsg: string = '';
  clientNotFoundNoNewRegistrations: boolean = false;
  email: string;
  newClient: Client;
  registrationForm: FormGroup;
  error: string = '';
  passwordInstructions: string = 'Passwords must be at least 8 characters with a number, special character, upper case, and lower case letter';
  registerModalObj: any = {isAuthenticated: false, openLogin: false, descriptionToPassToLogin: null, email: '', isConnectClient: this.connectClient, newClient: null}
  constructor(public authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private passedData: any, private tagsService: TagsService,
              public dialogRef: MatDialogRef<RegisterComponent>, private clientAccountService: ClientAccountService, private titleCasePipe: TitleCasePipe, private schedulerService: SchedulerService,
              private businessService: BusinessService, private clientInfoService: ClientInfoService, private googleAnalyticsService: GoogleAnalyticsService, private configListService: ConfigListService,
              private timezoneService: TimezoneService, private responsiveService: ResponsiveService, private dialog: MatDialog) { }

  ngOnInit() {
    this.preference = this.passedData.preference;
    this.resetPwd = this.passedData.resetPwd;
    if (this.passedData.accountLocked) {
        this.registrationFailed = true;
        this.error = 'Your account has been locked. Please reset your password.';
    }
    if(this.passedData.email !== undefined){
        this.email = this.passedData.email;
    }
    if(this.passedData.loginRegisterPopUpObject !== undefined &&
        this.passedData.loginRegisterPopUpObject.calledFrom !== undefined &&
        this.passedData.loginRegisterPopUpObject.calledFrom === this.loginRegisterObj.connectClientCallFrom){
        this.connectClient = true;
    }
    if(this.resetPwd){
        this.title = this.preference.labelMap.resetPwdTitle;
        this.description = this.preference.labelMap.resetPwdDescription;
        this.buttonLabel = this.preference.labelMap.resetPwdButtonLabel;
    } else if(this.connectClient){
        this.title = this.preference.labelMap.registerConnectClientTitle;
        this.disableEmailAddress = false;
        this.createNewClient(undefined, this.loginRegisterObj.connectClientCallFrom);
    } else {
        this.title = this.preference.labelMap.registerTitle;
        this.description = this.preference.labelMap.registerDescription;
        this.buttonLabel = this.preference.labelMap.registerButtonCheckEmail;
    }
  }

  registerClient(){
      if(this.loginFailed){
          this.registerModalObj.email = this.newClient.emailAddress;
          this.registerModalObj.openLogin = true;
          this.registerModalObj.descriptionToPassToLogin = this.preference.labelMap.registerLoginErrorMsg;
          this.dialogRef.close(this.registerModalObj);
      } else if(this.registrationForm.invalid || !this.clientInfoService.isClientInfoFormValid(this.registrationForm, this.newClient.fields)){
          this.requiredFieldsEmpty = true;
          this.requiredFieldsEmptyMsg = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.requiredFieldsEmptyMsg, this.tagsService.preferenceTagsArray);
          for(let prop in this.registrationForm.controls){
              this.registrationForm.controls[prop].markAsTouched();
          }
      } else if(this.registrationForm.valid && this.registeringClient === false) {
          this.registeringClient = true;
          this.timezonesFailed = false;
          this.registrationFailed = false;
          this.loginFailed = false;
          const timezones = this.timezoneService.timezones;
          let clientsTimeZone = sessionStorage.getItem('timezone');
          for(let i = 0, x = timezones.length; i < x; i++){
              if(clientsTimeZone === timezones[i].timeZoneCode){
                  this.newClient.timeZoneCode = timezones[i];
              }
          }
          for(let i = 0, x = this.newClient.fields.length; i < x; i++){
              if(this.newClient.fields[i].code==='password'){
                  this.newClient.password = this.newClient.fields[i].value;
                  break;
              }
          }
          // LHB 10/10/2021 TT-8196
          for (let i = 0, x = this.newClient.fields.length; i < x; i++)
              if (this.newClient.fields[i].value === null) {
                  let registrationFormFieldValue = null;
                  try {
                      registrationFormFieldValue = this.registrationForm.controls[this.newClient.fields[i].schedulerPreferenceFieldDefnId].value;
                  } catch (e) {
                      console.log(e);
                  }
                  if (registrationFormFieldValue !== null)
                      this.newClient.fields[i].value = registrationFormFieldValue;
              }
          let connectClientId: number = null;
          if(this.connectClient && this.clientAccountService.loggedInClient !== undefined){
              connectClientId = this.clientAccountService.loggedInClient.clientAccountId;
              let dateTime = new Date().getTime();
              this.newClient.password = 'Pw!' + dateTime.toString();
          }
          this.businessService.createUpdateClient(this.newClient, connectClientId)
              .subscribe(
                  (updatedClient) => {
                      this.registrationFailed = false;
                      let clientLoginObject: ClientAccount = {'emailAddress': this.newClient.emailAddress, 'password': this.newClient.password};
                      let clientLabel = this.configListService.getTitleCaseLabel('clientLabel');
                      let registrationLabel = "New " + clientLabel + " registration: " + updatedClient.fullName + ", " + updatedClient.emailAddress + " at " + new Date();
                      this.googleAnalyticsService.eventEmitter(registrationLabel, "engagement", "sign_up", registrationLabel, 1);
                      if(this.connectClient){
                          let needToAddConnectedClientToDropdown = this.clientAccountService.addClientToClientList(updatedClient);
                          if(needToAddConnectedClientToDropdown)
                              this.clientAccountService.addConnectClientToClientList(this.preference);
                          this.registerModalObj.isAuthenticated = true;
                          this.registerModalObj.isConnectClient = true;
                          this.registerModalObj.newClient = updatedClient;
                          this.dialogRef.close(this.registerModalObj);
                      } else {
                          this.authService.clientLogin(clientLoginObject)
                              .subscribe(
                                  (loggedInClient: ClientAccount) => {
                                      this.registeringClient = false;
                                      this.loginFailed = false;
                                      this.authService.authenticatedSuccessfully(loggedInClient, true);
                                      this.registerModalObj.isAuthenticated = true
                                      this.dialogRef.close(this.registerModalObj);
                                  }, error => {
                                      this.registeringClient = false;
                                      this.loginFailed = true;
                                  });
                      }
                  }, error => {
                      this.error = error;
                      this.registeringClient = false;
                      this.registrationFailed = true;
                  });

      }
  }
  emailAddress: string;
  createNewClient(form: NgForm, path: string){
      let emailAddress: string = null;
      if(form !== undefined && form.value !== undefined && form.value !== null && // TT-7075 fix
          form.value.email === undefined && this.emailAddress !== undefined){ // TT-7053 fix
          emailAddress = this.emailAddress;
      } else if(form !== undefined) {
          emailAddress = form.value.email;
      }
      this.newClient = this.clientAccountService.createNewClient(emailAddress);
      let fields = _.cloneDeep(this.preference.schedulerPreferenceFieldDefnListOriginal);
      for (let i = 0, x = fields.length; i < x; i++) {
          if (fields[i].mode === 'CLIENT') {
              if (fields[i].code === 'emailAddress' && emailAddress !== null) {
                   fields[i].value = emailAddress;
                   // [TT-8922] LHB 04/22/2022
                   fields[i].visible = true;
                   fields[i].visibleOnScheduler = true;
              }
              this.newClient.fields.push((fields[i]))
          }
      }
      if(path === "CHECK_EMAIL"){
          let pwdField = this.clientInfoService.createPasswordField(this.preference);
          this.newClient.fields.unshift(pwdField);
      }

      this.newClient.fields = this.clientInfoService.initialConfigurationOfSchedulerFields(this.newClient.fields);
      this.registrationForm = this.schedulerService.getPanelForm(SchedulerPanelValues.clientInfo, this.newClient.fields);
      if(path === this.loginRegisterObj.connectClientCallFrom){
          this.description = this.preference.labelMap.registerDescriptionConnectClient;
          this.buttonLabel = this.preference.labelMap.registerButtonConnectClient;
      } else {
          this.description = this.preference.labelMap.registerDescriptionClientNotFound;
          if (this.description.indexOf('%CLIENT_EMAILADDRESS%') !== -1) {
              this.description = this.description.replace(new RegExp('%CLIENT_EMAILADDRESS%', 'g'), form.value.email);
          }
          this.buttonLabel = this.preference.labelMap.registerButtonRegister;
      }
      this.clientNeedsToRegister = true;
  }

  checkClientEmail(form: NgForm) {
      if(form.valid) {
          this.checkingEmail = true;
          this.emailAddress = form.value.email;
          this.authService.checkClientExistsForRegistration(this.preference.businessId, this.emailAddress)
              .subscribe(
                  (clientExists: boolean) => {
                      this.clientExists = clientExists
                      this.checkingEmailFailed = false;
                      this.checkingEmail = false;
                      if (this.clientExists) {
                          if (this.resetPwd) {
                              this.registerModalObj.descriptionToPassToLogin = this.preference.labelMap.resetPwdEmailSent;
                              if (this.registerModalObj.descriptionToPassToLogin.indexOf('%CLIENT_EMAILADDRESS%') !== -1) {
                                  this.registerModalObj.descriptionToPassToLogin = this.registerModalObj.descriptionToPassToLogin.replace(new RegExp('%CLIENT_EMAILADDRESS%', 'g'), form.value.email);
                              }
                          } else {
                              this.registerModalObj.descriptionToPassToLogin = this.preference.labelMap.registerDescriptionClientFound
                          }
                          this.registerModalObj.email = form.value.email;
                          this.registerModalObj.openLogin = true;
                          this.dialogRef.close(this.registerModalObj);
                      } else if(this.preference.loginReq === 2){
                          this.description = this.preference.labelMap.registerDescriptionClientNotFoundPreRegisteredOnly;
                          this.checkingEmailFailed = false;
                          this.checkingEmail = false;
                          this.clientNotFoundNoNewRegistrations = true;
                          if (this.description.indexOf('%CLIENT_EMAILADDRESS%') !== -1) {
                              this.description = this.description.replace(new RegExp('%CLIENT_EMAILADDRESS%', 'g'), form.value.email);
                          }
                          this.clientNeedsToRegister = false;
                      } else {
                          this.createNewClient(form, "CHECK_EMAIL");
                      }

                  },
                  error => {
                      this.checkingEmail = false;
                      this.checkingEmailFailed = true;
                  });
      }
  }
}
