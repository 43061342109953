import {Component, Inject, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../auth.service';
import {MAT_DIALOG_DATA} from '@angular/material';
import {TagsService} from '../../tags.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {preference} from '../../common-classes/app-objects.model';
import {PasswordResetSuccess, PasswordResetValid} from '../../../models/PasswordResetValid.model';

export class ResetPasswordModalData {
  passwordRequestId: string;
  skipPasswordRequestCheck: boolean = false;
  success: boolean;
  openLogin: boolean;
  email: string;
  errorMsg: string;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../../form-field-styles.component.css']
})
export class ResetPasswordComponent implements OnInit {
  loading: boolean = true;
  title: string;
  description: string;
  resetPwdErrorMsg: string;
  resetPwdError: boolean;
  passwordResetRequestActive: boolean;
  password: string;
  confirmPassword: string;
  resettingPasswords: boolean;
  resendingRequest: boolean;
  resendRequestBtnLabel: string;
  resendPasswordRequestSuccessMsg: string;
  resendPasswordRequestSuccess: boolean;
  buttonLabel: string;
  preference: preference;
  success: boolean = false;
  openLogin: boolean = false;
  email: string;
  passwordInstructions: string = 'Passwords must be at least 8 characters with a number, special character, upper case, and lower case letter';



  constructor(private authService: AuthService, @Inject(MAT_DIALOG_DATA) private passedData: ResetPasswordModalData, private tagsService: TagsService,
              public dialogRef: MatDialogRef<ResetPasswordComponent>, private schedulerPreferenceService: SchedulerPreferenceService) {
    this.preference = schedulerPreferenceService.schedulerPreference;
    dialogRef.backdropClick().subscribe(() => {
      this.dismissModal();
    })
  }

  dismissModal() {
    const closeModalData: ResetPasswordModalData = {passwordRequestId: this.passedData.passwordRequestId, skipPasswordRequestCheck: false, success: this.success, openLogin: this.openLogin, email: this.email, errorMsg: ''};
    this.dialogRef.close(closeModalData);
  }

  handleSuccess() {
    setTimeout(() => {
      this.dismissModal()
    }, 3000)
  }

  checkPasswords(form: NgForm) {
    if (form.valid) {
      this.password = form.value.password;
      this.confirmPassword = form.value.confirmPassword;
      if (this.password !== this.confirmPassword) {
        this.resetPwdError = true;
        this.resetPwdErrorMsg = this.preference.labelMap.resetPwdErrorPasswordsMismatched;
      } else {
        this.resettingPasswords = true;
        this.resetPwdError = false;
        if (this.passedData.skipPasswordRequestCheck) {
          this.authService.forceResetPassword(this.passedData.email, this.password).subscribe((passwordUpdated: PasswordResetSuccess) => {
            this.success = passwordUpdated.success;
            this.email = passwordUpdated.email;
            this.openLogin = true;
            this.dismissModal();
          }, error => {
            this.resettingPasswords = false;
            this.resetPwdError = true;
            this.resetPwdErrorMsg = error;
          })
        } else {
          this.authService.resetPassword(this.passedData.passwordRequestId, this.password).subscribe((passwordUpdated: PasswordResetSuccess) => {
            this.success = passwordUpdated.success;
            this.email = passwordUpdated.email;
            this.openLogin = true;
            this.dismissModal();
          }, error => {
            this.resettingPasswords = false;
            this.resetPwdError = true;
            this.resetPwdErrorMsg = error;
          })
        }
      }


    }
  }

  setLabelProps() {
    this.title = this.preference.labelMap.resetPwdTitle;
    this.description = this.preference.labelMap.resetPwdDescriptionEnteringNewPasswords;
    if (this.passedData.errorMsg) {
      this.resetPwdError = true;
      this.resetPwdErrorMsg = this.passedData.errorMsg;
    }
    this.buttonLabel = this.preference.labelMap.resetPwdButtonLabel;
    this.resendRequestBtnLabel = this.preference.labelMap.resetPwdResendRequestButtonLabel;
  }

  resendPasswordResetRequest() {
    this.resendingRequest = true;
    this.resendPasswordRequestSuccess = false;
    this.authService.resendPasswordReset(this.passedData.passwordRequestId).subscribe((passwordResetValid: PasswordResetValid) => {
      this.resendingRequest = false;
      this.resendPasswordRequestSuccess = true;
      this.resetPwdError = false;
      this.success = true;
      this.resendPasswordRequestSuccessMsg = this.preference.labelMap.resetPwdEmailSent;
      if (this.resendPasswordRequestSuccessMsg.indexOf('%CLIENT_EMAILADDRESS%') !== -1)
        if (passwordResetValid.email)
          this.resendPasswordRequestSuccessMsg = this.resendPasswordRequestSuccessMsg.replace(new RegExp('%CLIENT_EMAILADDRESS%', 'g'), passwordResetValid.email);
        else
          this.resendPasswordRequestSuccessMsg = this.resendPasswordRequestSuccessMsg.replace(new RegExp('%CLIENT_EMAILADDRESS%', 'g'), 'your email');
      this.handleSuccess();
    })
  }

  checkRequestValid() {
    //wasn't a password reset request, so we can skip this validation
    if (this.passedData.skipPasswordRequestCheck) {
      this.loading = false;
      this.passwordResetRequestActive = true;
    } else {
      this.loading = true;
      this.authService.checkPasswordResetValid(this.passedData.passwordRequestId).subscribe((passwordResetValid: boolean) => {
        this.passwordResetRequestActive = passwordResetValid;
        if (!passwordResetValid) {
          this.resetPwdError = true;
          this.resetPwdErrorMsg = this.preference.labelMap.resetPwdRequestExpired;
        }
        this.loading = false;
      })
    }
  }

  ngOnInit() {
    this.checkRequestValid();
    this.setLabelProps();
  }

}
