import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {Client, ClientAccount, miniWebsite, preference, schedulerLink} from "../../common-classes/app-objects.model";
import {TagsService} from "../../tags.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BusinessService} from "../../business.service";
import {ClientAccountService} from "../client-account.service";
import {ResponsiveService} from "../../responsive.service";
import {ClientInfoService} from "../../scheduler/client-info/client-info.service";
import {ClientFormListObj} from "../../disclaimer-form/disclaimer-form.model";
import {TitleCasePipe} from "@angular/common";
import {SchedulerPreferenceService} from '../../scheduler-preference.service';

@Component({
  selector: 'app-client-account-home',
  templateUrl: './client-account-home.component.html'
})
export class ClientAccountHomeComponent implements OnInit, AfterViewChecked {
    dataLoaded = false;
    errorLoading = false;
    preference: preference;
    clientAccount: ClientAccount;
    selectedClient: Client;
    loggedInWelcomeTitle: string;
    loggedInWelcomeText: string;
    formsToFillOutText: string;
    unsubmittedFormsList: ClientFormListObj[] = [];
    appointmentIntentsAllowed: boolean;
    constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private clientInfoService: ClientInfoService,
                private tagsService: TagsService, private router: Router, private route: ActivatedRoute, private responsiveService: ResponsiveService,
                private schedulerPreferenceService: SchedulerPreferenceService) {
        this.preference = schedulerPreferenceService.schedulerPreference;
    }

    viewAppts(){
        this.router.navigate(['my-account/appts']);
    }

    viewInvoices(){
        this.router.navigate(['my-account/invoices']);
    }

    viewProfile(){
        this.router.navigate(['my-account/profile']);
    }

    viewIntents(){
      this.router.navigate(['my-account/intents']);
    }

    bookNewAppt(){
        this.router.navigate(['scheduler'], {relativeTo: this.route.root, state: {onProfileHome: true}});
    }

    goToForm(clientFormListObj: ClientFormListObj){
        this.router.navigate(['forms/' + clientFormListObj.disclaimerFormUuid], {relativeTo: this.route.root, state: {onProfileHome: true}});
    }

    selectClient(changeEvent: any){
        let client = changeEvent.client;
        let event = changeEvent.event;
        if(client.clientId !== this.selectedClient.clientId && event.isUserInput){
            this.selectedClient = client;
            this.clientAccountService.selectedClient = this.selectedClient;
            this.configureFieldsForDisplay();
        }
    }

    configureFieldsForDisplay(){
        if(this.selectedClient === undefined && this.clientAccountService.loggedInClient !== undefined){
            this.selectedClient = this.clientAccountService.loggedInClient.clientList[0];
        }
        this.loggedInWelcomeTitle = this.tagsService.assignClientValuesToTags(this.preference.labelMap.loggedInWelcomeTitle, this.selectedClient.fields, this.selectedClient);
        this.loggedInWelcomeText = this.tagsService.assignClientValuesToTags(this.preference.labelMap.loggedInWelcomeText, this.selectedClient.fields, this.selectedClient);
        if(this.selectedClient.formList !== undefined && this.selectedClient.formList !== null && this.selectedClient.formList.length > 0){
            for(let i = 0, x = this.selectedClient.formList.length; i < x; i++){
                if(this.selectedClient.formList[i].status !== 'SUBMITTED' && this.selectedClient.formList[i].status !== 'WAIVED')
                    this.unsubmittedFormsList.push(this.selectedClient.formList[i]);
            }
            if(this.unsubmittedFormsList !== undefined && this.unsubmittedFormsList !== null && this.unsubmittedFormsList.length > 0) {
                this.formsToFillOutText = this.tagsService.assignClientValuesToTags(this.preference.labelMap.clientProfileHomeFormsToFillOutText, this.selectedClient.fields, this.selectedClient);
            }
        }
    }

    ngAfterViewChecked(): void {
        this.responsiveService.setHeightForEmbedAccount();
    }

    ngOnInit() {
          if(this.businessService.business){
              if(this.businessService.business.status !== 'ACTIVE'){
                  this.dataLoaded = false;
                  this.errorLoading = true;
                  // this.miniWebsite = this.businessService.createErrorMiniWebsite('inactive');
              } else {
                  this.clientAccount = this.clientAccountService.loggedInClient;
                    this.appointmentIntentsAllowed = this.businessService.appointmentIntentAllowed;
                  if(this.clientAccountService.selectedClient !== undefined && this.clientAccountService.selectedClient !== null){
                      for(let i = 0, x = this.clientAccount.clientList.length; i < x; i++){
                          if(this.clientAccount.clientList[i].clientId === this.clientAccountService.selectedClient.clientId){
                              this.selectedClient = this.clientAccount.clientList[i];
                          }
                      }
                      this.configureFieldsForDisplay();
                  } else {
                      this.selectedClient = this.clientAccountService.selectClientBasedOnLoggedInEmail();
                      this.clientAccountService.setSelectedClient(this.selectedClient, this.preference.schedulerPreferenceFieldDefnList);
                      this.configureFieldsForDisplay();
                  }
                  this.dataLoaded = true;
                  this.errorLoading = false;
              }

          } else {
              this.dataLoaded = false;
              this.errorLoading = true;
          }
    }

}
