export class EmailSettingConfig {
    displayName: string;
    type: string
    selected: boolean;
    id: number;
    emailTemplateOrCategory: 'emailTemplate' | 'emailCategory';
    changingSubscribeStatus: boolean = false;
    errorChangingSubscribeStatus: boolean = false;
    subscribeStatusChanged: boolean = false;

}
