import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BookingSelectionObjectTypes, BookingSelectionOption} from '../../../models/BookingSelectionOption.model';
import {Subscription} from 'rxjs/index';
import {JoinWaitListService} from '../../waitlist/join-wait-list/join-wait-list.service';
import {MainObjectTypes} from '../../../models/MainObjectTypes.model';
import {WaitList} from '../../../models/WaitList.model';
import {Appointment} from '../../../models/Appointment.model';
import {Group, GroupTypes} from '../../../models/Group.model';
import {Location} from '../../../models/Location.model';
import {Staff} from '../../../models/Staff.model';
import {CouponAPIReturnData, CSFilterParameterBean} from '../../common-classes/app-objects.model';
import {Reason} from '../../../models/Reason.model';
import {BusinessService} from '../../business.service';
import {BookingComponentsService} from '../booking-components.service';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {ReasonsService} from '../../reasons.service';
import {ConfigListService} from '../../config-list.service';

@Component({
  selector: 'app-booking-object-selector',
  template: `
    <mat-form-field appearance="standard" *ngIf="bookingSelection.options">
      <mat-label>{{bookingSelection.label |  titlecase}}</mat-label>
      <mat-select panelClass="schedulerFieldDrpdwn"
                  [required]="false"
                  [(value)]="bookingSelection.selectedOption"
                  placeholder="{{bookingSelection.label |  titlecase}}"
                  (selectionChange)="setObjectOnBookingObject()">
        <mat-option *ngFor="let dropdownValue of bookingSelection.options" [value]="dropdownValue">
          {{dropdownValue.displayName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: ['mat-form-field  {width: 100%;} .col-form-label {z-index: 0; width: 100%}']
})
export class BookingObjectSelectorComponent implements OnInit, OnDestroy {
  @Input() bookingSelection: BookingSelectionOption;
  @Input() bookingSelectionFlow: BookingSelectionOption[];
  @Input() objectType: MainObjectTypes;
  @Output() changeSelection = new EventEmitter<WaitList | Appointment>();
  @Input() CSFilterObj: CSFilterParameterBean;
  bookingObjectChanged: Subscription;
  bookingObject: WaitList | Appointment;
  parentObjectChanged: boolean = false;
  constructor(private joinWaitListService: JoinWaitListService, private businessService: BusinessService, private bookingComponentsService: BookingComponentsService,
              private schedulerPreferenceService: SchedulerPreferenceService, private reasonsService: ReasonsService, private configListService: ConfigListService) { }

  setObjectOnBookingObject() {
    switch (this.bookingSelection.objectType) {
      case BookingSelectionObjectTypes.REASON:
        this.bookingObject.reason = this.bookingSelection.selectedOption as Reason;
        break;
      case BookingSelectionObjectTypes.LOCATION:
        this.bookingObject.location = this.bookingSelection.selectedOption as Location;
        break;
      case BookingSelectionObjectTypes.STAFF:
        this.bookingObject.staff = this.bookingSelection.selectedOption as Staff;
        break;
      case BookingSelectionObjectTypes.GROUP:
        switch (this.bookingSelection.groupType) {
          case GroupTypes.LOCATION_GROUP:
            this.bookingObject.locationSuperGroup = this.bookingSelection.selectedOption as Group;
            break;
          case GroupTypes.LOCATION:
            this.bookingObject.locationGroup = this.bookingSelection.selectedOption as Group;
            break;
          case GroupTypes.SERVICE_GROUP:
            this.bookingObject.reasonSuperGroup = this.bookingSelection.selectedOption as Group;
            break;
          case GroupTypes.SERVICE:
            this.bookingObject.reasonGroup = this.bookingSelection.selectedOption as Group;
            break;
        }
        break;
    }
    this.changeSelection.emit(this.bookingObject);
  }

  getBookingObjects() {
    this.CSFilterObj = this.joinWaitListService.setCSFilterObject(this.bookingSelectionFlow, this.CSFilterObj);
    switch (this.bookingSelection.objectType) {
      case BookingSelectionObjectTypes.LOCATION:
        this.businessService.getLocations(this.CSFilterObj).subscribe((locations: Location[]) => {
          this.bookingSelection.options = locations;
          this.bookingSelection.selectedOption = this.bookingComponentsService.matchLocations(this.bookingObject.location, this.bookingSelection.options, false);
          this.setObjectOnBookingObject();
        });
        break;
      case BookingSelectionObjectTypes.STAFF:
        this.businessService.getStaff(this.CSFilterObj).subscribe((staff: Staff[]) => {
          if(this.schedulerPreferenceService.schedulerPreference.staffNoPreference === 0 && staff.length > 1){
            const noPreferenceStaff = new Staff(null, this.schedulerPreferenceService.schedulerPreference.labelMap.labelNoPreference);
            noPreferenceStaff.imageUrl = null;
            noPreferenceStaff.staffProfile = null;

            staff.unshift(noPreferenceStaff);
          }
          this.bookingSelection.options = staff;
          this.bookingSelection.selectedOption = this.bookingComponentsService.matchStaff(this.bookingObject.staff, this.bookingSelection.options, false);
          this.setObjectOnBookingObject();
        });
        break;
      case BookingSelectionObjectTypes.REASON:
        this.reasonsService.getReasons(this.CSFilterObj, true).subscribe((reasonReturnData: CouponAPIReturnData) => {
          if (reasonReturnData.reasonAPIBeanList && reasonReturnData.reasonAPIBeanList.length > 0)
            this.bookingSelection.options = reasonReturnData.reasonAPIBeanList;
          else {
            const noReasonForBooking: Reason = new Reason();
            noReasonForBooking.createNoWaitListAbleReason(this.configListService.configList);
            this.bookingSelection.options = [noReasonForBooking];
          }
          this.bookingSelection.selectedOption = this.bookingComponentsService.matchReason(this.bookingObject.reason, this.bookingSelection.options, false);
          this.setObjectOnBookingObject();
        });
        break;
      case BookingSelectionObjectTypes.GROUP:
        this.businessService.getGroups(this.bookingSelection.groupType, this.CSFilterObj).subscribe((groups: Group[]) =>{
          this.bookingSelection.options = groups;
          switch (this.bookingSelection.groupType) {
            case GroupTypes.LOCATION_GROUP:
              this.bookingSelection.selectedOption = this.bookingComponentsService.matchGroup(this.bookingObject.locationSuperGroup, this.bookingSelection.options, false);
              break;
            case GroupTypes.LOCATION:
              this.bookingSelection.selectedOption = this.bookingComponentsService.matchGroup(this.bookingObject.locationGroup, this.bookingSelection.options, false);
              break;
            case GroupTypes.SERVICE_GROUP:
              this.bookingSelection.selectedOption = this.bookingComponentsService.matchGroup(this.bookingObject.reasonSuperGroup, this.bookingSelection.options, false);
              break;
            case GroupTypes.SERVICE:
              this.bookingSelection.selectedOption = this.bookingComponentsService.matchGroup(this.bookingObject.reasonGroup, this.bookingSelection.options, false);
              break;
          }
          this.setObjectOnBookingObject();
        });
        break;
    }
  }

  ngOnInit() {
    if (this.objectType === MainObjectTypes.WAIT_LIST) {
      this.bookingObject = this.joinWaitListService.getCurrentEvent();
      this.bookingObjectChanged = this.joinWaitListService.currentEventChanged.subscribe((currentEventData: { newEventData: WaitList, oldEventData: WaitList }) => {
        this.bookingObject = currentEventData.newEventData;
        if (currentEventData.oldEventData) {
          for (let i = 0, x = this.bookingSelectionFlow.length; i < x; i++) {
            if (this.bookingSelectionFlow[i].objectType === BookingSelectionObjectTypes.GROUP &&
                this.bookingSelection.objectType === BookingSelectionObjectTypes.GROUP &&
                this.bookingSelectionFlow[i].groupType === this.bookingSelection.groupType)
              break;
            if (this.bookingSelectionFlow[i].objectType === this.bookingSelection.objectType)
              break;
            else {
              switch (this.bookingSelectionFlow[i].objectType) {
                case BookingSelectionObjectTypes.STAFF:
                  if (currentEventData.newEventData.staff && currentEventData.oldEventData.staff && currentEventData.newEventData.staff.professionalId !== currentEventData.oldEventData.staff.professionalId)
                    this.parentObjectChanged = true;
                    break;
                case BookingSelectionObjectTypes.LOCATION:
                  if (currentEventData.newEventData.location && currentEventData.oldEventData.location && currentEventData.newEventData.location.locationId !== currentEventData.oldEventData.location.locationId)
                    this.parentObjectChanged = true;
                  break;
                case BookingSelectionObjectTypes.REASON:
                  if (currentEventData.newEventData.reason && currentEventData.oldEventData.reason && currentEventData.newEventData.reason.reasonId !== currentEventData.oldEventData.reason.reasonId)
                    this.parentObjectChanged = true;
                  break;
                case BookingSelectionObjectTypes.GROUP:
                  switch (this.bookingSelectionFlow[i].groupType) {
                    case GroupTypes.SERVICE:
                      if (currentEventData.newEventData.reasonGroup && currentEventData.oldEventData.reasonGroup && currentEventData.newEventData.reasonGroup.groupId !== currentEventData.oldEventData.reasonGroup.groupId)
                        this.parentObjectChanged = true;
                      break;
                    case GroupTypes.LOCATION:
                      if (currentEventData.newEventData.locationGroup && currentEventData.oldEventData.locationGroup && currentEventData.newEventData.locationGroup.groupId !== currentEventData.oldEventData.locationGroup.groupId)
                        this.parentObjectChanged = true;
                      break;
                    case GroupTypes.SERVICE_GROUP:
                      if (currentEventData.newEventData.locationGroup && currentEventData.oldEventData.locationGroup && currentEventData.newEventData.locationGroup.groupId !== currentEventData.oldEventData.locationGroup.groupId)
                        this.parentObjectChanged = true;
                      break;
                    case GroupTypes.LOCATION_GROUP:
                      if (currentEventData.newEventData.locationSuperGroup && currentEventData.oldEventData.locationSuperGroup && currentEventData.newEventData.locationSuperGroup.groupId !== currentEventData.oldEventData.locationSuperGroup.groupId)
                        this.parentObjectChanged = true;
                      break;
                  }
                  break;
              }
            }
          }
          if (this.parentObjectChanged) {
            this.getBookingObjects();
            this.parentObjectChanged = false;
          }
        }
      });

    }
  }

  ngOnDestroy() {
    if (this.bookingObjectChanged)
      this.bookingObjectChanged.unsubscribe();
  }

}
