//checks if user is authenticated or not and if not redirects them to the login page
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate{
    constructor(private authService: AuthService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        this.authService.setRefreshUrl(state);
        if(this.authService.isAuth()) {
            return true;
        } else {
            this.router.navigate(['/scheduler']);
        }
    }
}