import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Cart, CartItem} from "./cart.model";
import {StoreService} from "../store.service";
import {
  Coupon,
  currency,
  Invoice,
  InvoiceItem,
  loginRegisterPopUpObject, miniWebsite,
  preference
} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {MatSelectChange} from "@angular/material/select";
import {ResponsiveService} from "../../responsive.service";
import {PackageSold} from "../packages/packages.model";
import {AuthService} from "../../auth/auth.service";
import {Subject, Subscription} from "rxjs";
import {ClientAccountService} from "../../client-account/client-account.service";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {MiniWebsiteDisplayConfig} from "../../common-classes/default-styles.model";
import {StyleSheetService} from "../../styleSheetService";
import {SchedulerPreferenceService} from '../../scheduler-preference.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, OnDestroy {
  displayConfig: MiniWebsiteDisplayConfig;
  isAuthenticated: boolean;
  authenticationSubscription: Subscription;
  loginClosedSubscription: Subscription;
  registerClosedSubscription: Subscription;
  cart: Cart;
  preference: preference;
  currency: currency;
  continueToCheckoutAfterLogin: boolean = false;
  quantities: number[] = [1,2,3,4,5,6,7,8,9,10];
  calculatingCartTotal: boolean = false;
  calculatingCartTotalWithCoupon: boolean = false;
  showDiscountInput: boolean = false;
  couponCode: string;
  showCouponError: boolean = false;
  loadCart: boolean = false;

  constructor(public storeService: StoreService, private businessService: BusinessService, private responsiveService: ResponsiveService, private styleSheetService: StyleSheetService,
              private authService: AuthService, private renderer: Renderer2, private clientAccountService: ClientAccountService,
              private router: Router, private route: ActivatedRoute, private schedulerPreferenceService: SchedulerPreferenceService) { }

  changeCartItemQuantity(item: CartItem, event: MatSelectChange){

    item = item.calculateTotalPrice();
    this.storeService.setCartData(this.cart);
    this.calculateCartTotal();
  }

  applyCouponToLineItems(couponCode: string){
    if(couponCode !== undefined && couponCode !== null){
      couponCode = couponCode.trim();
    }
    if(couponCode === undefined || couponCode === null || couponCode === ''){
      this.storeService.couponStatus.next('ERROR');
      this.storeService.couponStatusDetail.next('NO_CODE_ENTERED');
      this.storeService.applyingCoupon.next(false);
    } else {
      this.storeService.applyingCoupon.next(true);
      this.couponCode = couponCode;
      this.calculateCartTotal(this.couponCode);
    }
  }

  removeFromCart(item: CartItem){
    // console.log("remove from cart")
    // console.log(item);
    for(let i = 0; i < this.cart.items.length; i++){
      if(this.cart.items[i].id === item.id){
        this.cart.items.splice(i,1);
      }
    }
    this.storeService.setCartData(this.cart);
    this.calculateCartTotal();
  }

  showApplyDiscount(){
    this.showDiscountInput = !this.showDiscountInput;
  }

  turnCartItemsIntoInvoiceItems(packageSoldItems: PackageSold[]){
    let invoiceItems: InvoiceItem[] = [];
    for(let i = 0, x = packageSoldItems.length; i < x; i++){
      let clientId = null;
      if(packageSoldItems[i].client !== null)
        clientId = packageSoldItems[i].client.clientId
      let invoiceItem: InvoiceItem = new InvoiceItem(packageSoldItems[i].businessId, clientId);
      invoiceItem.packageSoldId = packageSoldItems[i].packageSoldId;
      invoiceItem.rate = packageSoldItems[i].price;
      invoiceItem.quantity = 1;
      invoiceItem.taxable = packageSoldItems[i].taxable;
      invoiceItems.push(invoiceItem);
    }
    return invoiceItems;
  }

  createInvoice(packageSoldItems: PackageSold[]){
    let invoiceItems: InvoiceItem[] = this.turnCartItemsIntoInvoiceItems(packageSoldItems);
    if(invoiceItems.length > 0){
      let invoice: Invoice = new Invoice(this.preference.businessId, this.clientAccountService.setSelectedClientOnComponent(this.preference));
      invoice.invoiceItem = invoiceItems;
      this.storeService.createInvoice(invoice, this.couponCode).subscribe((invoice: Invoice) => {
        this.cart.items = [];
        this.storeService.setCartData(this.cart);
        const navigationExtras: NavigationExtras = {
          relativeTo: this.route.root,
          queryParams: {
            paying: false
          },
          state: {
            invoice: invoice,
            paymentRequired: true,
            fromCart: true
          },
          queryParamsHandling: 'merge',
        };
        this.router.navigate(['invoices/' + invoice.invoiceHash], navigationExtras)
      })
    }
  }

  createPackageSoldObjs(packageSoldItems: PackageSold[], createInvoice: boolean){
    this.storeService.createPackageSoldObjs(packageSoldItems).subscribe((packageSoldObjs: PackageSold[]) => {
      for(let i = 0, x = packageSoldObjs.length; i < x; i++)
        packageSoldObjs[i].taxable = packageSoldObjs[i].pkg.taxable;
      if(createInvoice)
        this.createInvoice(packageSoldObjs)
    })
  }

  getPackageItems(): PackageSold[]{
    let packageItems: CartItem[] = [];
    for(let i = 0, x = this.cart.items.length; i < x; i++){
      if(this.cart.items[i].type === 'PACKAGE')
        packageItems.push(this.cart.items[i]);
    }
    let packageSoldItems: PackageSold[] = [];
    if(packageItems.length > 0){
      for(let i = 0, x = packageItems.length; i < x; i++){
        let packageSold: PackageSold = new PackageSold();
        packageSold.packageId = packageItems[i].id;
        packageSold.businessId = packageItems[i].businessId;
        packageSold.client = this.clientAccountService.setSelectedClientOnComponent(this.preference);
        packageSold.usedQuantity = 0;
        packageSold.price = packageItems[i].price;
        packageSold.taxable = packageItems[i].taxable;
        for(let j = 0, x = packageItems[i].quantityInCart; j < x; j++)
          packageSoldItems.push(packageSold);
      }

    }
    return packageSoldItems;
  }

  continueToCheckout(){
    if(!this.isAuthenticated) {
      let loginTriggeredData: loginRegisterPopUpObject = new loginRegisterPopUpObject();
      loginTriggeredData.loadMyAccount = false;
      loginTriggeredData.description = this.preference.labelMap.cartTriggerLoginMsg;
      loginTriggeredData.calledFrom = 'CART';
      loginTriggeredData.callSubscriptionNext = true;
      this.continueToCheckoutAfterLogin = true;
      this.authService.loginCalled.next(loginTriggeredData);
    } else {
      let packageSoldItems: PackageSold[] = this.getPackageItems();
      this.createPackageSoldObjs(packageSoldItems, true);
    }
  }

  updateCouponSubjects(couponCode: string, error: boolean, errorType?: string){
    if(couponCode !== undefined && couponCode !== null){
      if(error){
        this.storeService.applyingCoupon.next(false);
        this.storeService.couponStatus.next("ERROR");
        if(errorType === undefined || errorType === null){
          this.storeService.couponStatusDetail.next("HTTP")
          this.showCouponError = false;
        } else {
          this.storeService.couponStatusDetail.next(errorType);
          this.showCouponError = true;
        }
      } else {
        this.storeService.applyingCoupon.next(false);
        this.showCouponError = false;
      }
    }
  }

  calculateCartTotal(couponCode?: string){
    let packageSoldItems: PackageSold[] = this.getPackageItems();
    let invoiceItems: InvoiceItem[] = this.turnCartItemsIntoInvoiceItems(packageSoldItems);
    if(invoiceItems.length > 0) {
      if(couponCode !== undefined)
        this.calculatingCartTotalWithCoupon = true;
      else
        this.calculatingCartTotal = true;
      let invoice: Invoice = new Invoice(this.preference.businessId, this.clientAccountService.setSelectedClientOnComponent(this.preference));
      invoice.invoiceItem = invoiceItems;
      this.storeService.getCartTotal(invoice, couponCode).subscribe((invoice: Invoice) => {
        this.calculatingCartTotal = false;
        this.calculatingCartTotalWithCoupon = false;
        this.cart.subTotalAmount = invoice.subtotalAmount;
        this.cart.taxAmount = invoice.taxAmount;
        this.cart.transactionFeeAmount = invoice.transactionFeeAmount;
        if(invoice.discountAmount === null)
          invoice.discountAmount = 0;
        let couponError: boolean = false;
        let couponErrorCode: string = null;
        if(invoice.invoiceItem !== null && invoice.invoiceItem.length !== 0){
          for(let i = 0, x = invoice.invoiceItem.length; i < x; i++){
            if(invoice.invoiceItem[i].couponError !== null){
              couponError = true;
              couponErrorCode = invoice.invoiceItem[i].couponError;
            }
          }
        }
        this.cart.discountAmount = invoice.discountAmount;
        this.cart.invoiceTotalAmount = invoice.invoiceTotalAmount;
        this.updateCouponSubjects(couponCode, couponError, couponErrorCode);
      }, error => {
        this.calculatingCartTotal = false;
        this.calculatingCartTotalWithCoupon = false;
        this.updateCouponSubjects(couponCode, true);
      })
    } else {
      this.cart.subTotalAmount = 0;
      this.cart.taxAmount = 0;
      this.cart.discountAmount = 0;
      this.cart.invoiceTotalAmount = 0;
    }
  }



  ngOnInit() {
    this.displayConfig = this.styleSheetService.displayConfig;
    if(this.displayConfig.store.enable){
      this.loadCart = true;
      this.cart = this.storeService.cart;
      this.currency = this.businessService.currency;
      // console.log("this.cart ")
      // console.log(this.cart);
      this.preference = this.schedulerPreferenceService.schedulerPreference;
      // if(this.responsiveService.onMobileViewPort){
      //   this.wrapItemsInRow();
      // }
      this.calculateCartTotal();
      this.isAuthenticated = this.authService.isAuth();
      this.authenticationSubscription = this.authService.authChange.subscribe(isAuth => {
        this.isAuthenticated = this.authService.isAuth();
      })
      this.loginClosedSubscription = this.authService.loginClosed.subscribe((loginTriggeredData: loginRegisterPopUpObject) => {
        if(this.continueToCheckoutAfterLogin)
          this.continueToCheckout();
      });
      this.registerClosedSubscription = this.authService.registerClosed.subscribe((loginTriggeredData: loginRegisterPopUpObject) => {
        if(this.continueToCheckoutAfterLogin)
          this.continueToCheckout();
      })
    } else {
      this.router.navigate(['scheduler'], {relativeTo: this.route.root});
    }
  }

  ngOnDestroy(): void {
    if(this.authenticationSubscription !== undefined)
      this.authenticationSubscription.unsubscribe();
    if(this.loginClosedSubscription !== undefined)
      this.loginClosedSubscription.unsubscribe();
    if(this.registerClosedSubscription !== undefined)
      this.registerClosedSubscription.unsubscribe();
  }

}
