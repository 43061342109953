import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ValueOptionModel} from '../../../models/ValueOption.model';
import {FormFunctionsService} from '../../form-functions.service';

@Component({
  selector: 'app-multi-checkbox-form-field',
  template: `
    <div class="checkbox-list" *ngIf="loaded">
      <label class="checkbox checkbox-outline checkbox-outline-2x" [ngClass]="{'checkbox-disabled': formControl.disabled}" *ngFor="let valueOption of dropdownValues">
        <input type="checkbox" [checked]="valueOption.selected" [disabled]="formControl.disabled"
               [ngClass]="{'is-invalid': formControl.touched && formControl.errors}" [value]="valueOption.value"
               (change)="$event.preventDefault(); selectChange($event, valueOption)">
        <span></span> {{valueOption.label}}
      </label>
    </div>
  `,
  styles: ['.checkbox-list {display: flex; flex-direction: column;}']
})
export class MultiCheckboxFormFieldComponent implements OnInit {
  @Input() passedControl: FormControl;
  @Output() onValueChanges = new EventEmitter<{formControl: FormControl, event: Event}>();
  @Input() dropdownValues: ValueOptionModel[];
  formControl = new FormControl('');
  required: boolean = false;
  loaded: boolean = false;
  constructor(private cdRef: ChangeDetectorRef, private formFunctionsService: FormFunctionsService) { }

  selectChange(event: Event, valueOption: ValueOptionModel) {
    if (this.formControl.enabled) {
      valueOption.selected = !valueOption.selected;
      const currentValue = this.formControl.value;
      const checkboxSelectedValues = this.formFunctionsService.setMultiCheckboxValue(currentValue, String(valueOption.value), event);
      this.formControl.setValue(checkboxSelectedValues)
      const eventToEmit = {formControl: this.formControl, event};
      this.onValueChanges.emit(eventToEmit);
    }
  }

  renderField() {
    this.formControl = this.formFunctionsService.renderField(this.formControl, this.passedControl)
    this.required = this.formFunctionsService.checkRequired(this.formControl);
    this.loaded = true;
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('passedControl') && !changes.passedControl.firstChange)
      this.renderField();
  }

  ngOnInit(): void {
    this.renderField();
  }

}
