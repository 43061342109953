import {Injectable, Injector} from '@angular/core';
import {ValueOptionModel} from '../../../models/ValueOption.model';
import {WaitList, WaitListDate, WaitListDateTime} from '../../../models/WaitList.model';
import {TimeService} from '../../scheduler/time/time.service';
import * as _ from 'lodash';
import {MainObjectTypes} from '../../../models/MainObjectTypes.model';
import {AvailabilityService} from '../../booking-components/availability.service';
import {Subject} from 'rxjs/index';
import {SingleTimeSlot} from '../../scheduler/time/time-objects.model';
import {SelectedDateTimeObj} from '../../../models/SelectedDateTimeObj.model';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {BookingSelectionObjectTypes, BookingSelectionOption} from '../../../models/BookingSelectionOption.model';
import {GroupTypes} from '../../../models/Group.model';
import {CSFilterParameterBean} from '../../common-classes/app-objects.model';

@Injectable({
  providedIn: 'root'
})
export class JoinWaitListService {
  currentEvent: WaitList;
  timeService: TimeService;
  availabilityService: AvailabilityService;
  currentEventChanged = new Subject<{newEventData: WaitList, oldEventData: WaitList}>();
  schedulerPreferenceService: SchedulerPreferenceService;
  joinWaitListHeaderClicked = new Subject();
  constructor(private injector: Injector) {
    this.availabilityService = injector.get(AvailabilityService);
    this.timeService = injector.get(TimeService);
    this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
  }

  setCurrentEvent(waitList: WaitList, onRemoveTime?: boolean) {
    const oldEventData = _.cloneDeep(this.currentEvent);
    this.currentEvent = waitList;
    // this.timeService.getTimezoneForEvent(this.currentEvent as WaitList);
    const newEventData = _.cloneDeep(this.currentEvent);
    this.availabilityService.setCurrentEvent(this.currentEvent as WaitList, MainObjectTypes.WAIT_LIST, onRemoveTime);
    this.currentEventChanged.next({newEventData, oldEventData});
  }

  getCurrentEvent() {
    return _.cloneDeep(this.currentEvent);
  }

  clearCurrentEvent() {
    this.currentEvent = null;
  }

  setWaitListRegistrationDateTimeOption(selectedValue: string, waitListRegistrationDateTimeOptions: ValueOptionModel[]): ValueOptionModel {
    for (let i = 0, x = waitListRegistrationDateTimeOptions.length; i < x; i++)
      waitListRegistrationDateTimeOptions[i].selected = false;
    for (let i = 0, x = waitListRegistrationDateTimeOptions.length; i < x; i++)
      if (waitListRegistrationDateTimeOptions[i].value === selectedValue) {
        waitListRegistrationDateTimeOptions[i].selected = true;
        return waitListRegistrationDateTimeOptions[i];
      }
  }

  removeTimeSlot(waitList: WaitList, waitListDate: WaitListDate, waitListDateTime: WaitListDateTime, timeSlot: SingleTimeSlot) {
    for (let i = 0, x = waitList.waitListDateList.length; i < x; i++)
      if (!waitListDate.recurringScheduleId && waitList.waitListDateList[i].date === waitListDate.date) {
        for (let j = 0; j < waitList.waitListDateList[i].waitListDateTimeList.length; j++)
          if (waitList.waitListDateList[i].waitListDateTimeList[j].startTime === waitListDateTime.startTime)
            waitList.waitListDateList[i].waitListDateTimeList.splice(j, 1);
      } else if (waitListDate.recurringScheduleId && waitListDate.recurringScheduleId === waitList.waitListDateList[i].recurringScheduleId) {
        waitList.waitListDateList.splice(i, 1);
      }
    if (waitList.selectedTimes)
      for (let i = 0, x = waitList.selectedTimes.length; i < x; i++)
        if (waitList.selectedTimes[i].timeSlotId === timeSlot.timeSlotId)
          waitList.selectedTimes.splice(i, 1);
  }

  storeTimeSlotOnWaitList(waitList: WaitList, timeSlot: SingleTimeSlot): boolean {
    let added = false;
    const selectedDateTimeObj: SelectedDateTimeObj = new SelectedDateTimeObj().createFromSingleTimeSlot(timeSlot, waitList.reason, waitList.staff, waitList.location, this.schedulerPreferenceService.schedulerPreference.labelMap.labelNoPreference);
    if (!waitList.selectedTimes)
      waitList.selectedTimes = [];
    const selectedTimeIdList: string[] = [];
    for (let i = 0, x = waitList.selectedTimes.length; i < x; i++) {
      selectedTimeIdList.push(waitList.selectedTimes[i].timeSlotId);
    }
    if (selectedTimeIdList.indexOf(selectedDateTimeObj.timeSlotId) === -1) {
      waitList.selectedTimes.push(selectedDateTimeObj);
    }
    const year = Number(this.timeService.getPipeYearFromDate(timeSlot.staffStartDateTimeUTC));
    const month = Number(this.timeService.getPipeMonthFromDate(timeSlot.staffStartDateTimeUTC)) - 1;
    const day = Number(this.timeService.getPipeDayFromDate(timeSlot.staffStartDateTimeUTC));
    const objectDate = new Date(Date.UTC(year, month, day));
    const dateTime = objectDate.getTime();
    const waitListDate: WaitListDate = new WaitListDate(dateTime);
    waitListDate.recurringScheduleId = timeSlot.recurringScheduleId;
    waitListDate.courseSet = timeSlot.courseSet;
    const waitListDateTime: WaitListDateTime = new WaitListDateTime(timeSlot.startTime, timeSlot.endTime);
    waitListDateTime.timeString = timeSlot.timeString;
    waitListDateTime.classScheduleId = timeSlot.classScheduleId;
    waitListDateTime.clientStartTimeForDisplay = selectedDateTimeObj.cartDisplayStartDateTime;
    waitListDateTime.clientEndTimeForDisplay = selectedDateTimeObj.cartDisplayEndDateTime;
    waitListDateTime.startDateTimeUTC = selectedDateTimeObj.startDateTimeUTC;
    waitListDateTime.endDateTimeUTC = selectedDateTimeObj.endDateTimeUTC;
    waitListDate.waitListDateTimeList = [waitListDateTime];
    if (waitListDate.recurringScheduleId) {
      waitListDate.date = null;
      waitListDate.preferredDate = null;
      waitListDate.waitListDateTimeList = [];
    }
    if (timeSlot.selected) {
      const datesInWaitListCart = []; // GET AN ARRAY OF ALL THE DATES IN THE WAITLIST CART TO COMPARE NEW SELECTION TO

      // console.log(timeSlot);
      // console.log(selectedDateTimeObj);
      // console.log(waitListDateTime);
      if (waitList.waitListDateList.length > 0) {
        for (let i = 0, x = waitList.waitListDateList.length; i < x; i++)
          datesInWaitListCart.push(waitList.waitListDateList[i].date);
        if (datesInWaitListCart.indexOf(waitListDate.date) !== -1) { // DATE IS IN THE WAITLIST CART ALREADY AND JUST NEED TO ADD TIME TO IT
          for (let i = 0, x = waitList.waitListDateList.length; i < x; i++)
            if (waitList.waitListDateList[i].date === waitListDate.date)
              if (waitList.waitListDateList[i].waitListDateTimeList.length === 0) {
                waitList.waitListDateList[i].waitListDateTimeList = [waitListDateTime];
                added = true;
              } else { // NEXT NEED TO MAKE SURE THAT THE TIME IS NOT CURRENTLY PART OF THE DATE (fixes TT-2351)
                const timesOnDate = [];
                for (let j = 0, y = waitList.waitListDateList[i].waitListDateTimeList.length; j < y; j++)
                  timesOnDate.push(waitList.waitListDateList[i].waitListDateTimeList[j].timeString);
                if (timesOnDate.indexOf(waitListDateTime.timeString) === -1) { // TIME IS NOT IN THE DATE LIST YET AND NEEDS TO BE PUSHED
                  waitList.waitListDateList[i].waitListDateTimeList = [waitListDateTime];
                  added = true;
                }
              }
        } else { // DATE IS NOT IN THE CART
          waitList.waitListDateList.push(waitListDate);
          added = true;
        }
      } else { // WAITLIST CART IS EMPTY AND THIS IS THE FIRST ENTRY
        if (!waitList.waitListDateList)
          waitList.waitListDateList = [];
        waitList.waitListDateList.push(waitListDate);
        added = true;
      }
    } else
      this.removeTimeSlot(waitList, waitListDate, waitListDateTime, timeSlot);
    return added;
  }

  setCSFilterObject(bookingSelectionFlow: BookingSelectionOption[], csFilterObj: CSFilterParameterBean): CSFilterParameterBean {
    const filterObj = _.cloneDeep(csFilterObj);
    bookingSelectionFlow.forEach((bs, index) => {
      switch (bs.objectType) {
        case BookingSelectionObjectTypes.LOCATION:
          // @ts-ignore
          filterObj.locationIdList = [bs.selectedOption.locationId];
          break;
        case BookingSelectionObjectTypes.STAFF:
          // @ts-ignore
          filterObj.staffIdList = [bs.selectedOption.professionalId];
          break;
        case BookingSelectionObjectTypes.REASON:
          // @ts-ignore
          filterObj.reasonIdList = [bs.selectedOption.reasonId];
          break;
        case BookingSelectionObjectTypes.GROUP:
          switch (bs.groupType) {
            case GroupTypes.LOCATION_GROUP:
              // @ts-ignore
              filterObj.locationSuperGroupIdList = [bs.selectedOption.groupId];
              break;
            case GroupTypes.LOCATION:
              // @ts-ignore
              filterObj.locationGroupIdList = [bs.selectedOption.groupId];
              break;
            case GroupTypes.SERVICE_GROUP:
              // @ts-ignore
              filterObj.reasonSuperGroupIdList = [bs.selectedOption.groupId];
              break;
            case GroupTypes.SERVICE:
              // @ts-ignore
              filterObj.reasonGroupIdList = [bs.selectedOption.groupId];
              break;
          }
          break;
      }
    })
    return filterObj;
  }
}
