import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiUrl: string;
  constructor() {
    try {
      this.apiUrl = sessionStorage.getItem('csAPIBase') + 'cs/'
    } catch (e) {

    }
  }

  handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  handleErrorOpenModal(error: any, routeToGoTo?: string): Promise<any> {
    console.log(error);
    // this.dataLoadErrorService.openModal(error.error.message, routeToGoTo);
    return Promise.reject(error);
  }
}
