import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {TagsService} from "../../tags.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {currency, Invoice, preference} from "../../common-classes/app-objects.model";

@Component({
  selector: 'app-select-pay-amount',
  templateUrl: './select-pay-amount.component.html',
  styleUrls: ['./select-pay-amount.component.css']
})
export class SelectPayAmountComponent implements OnInit {
  invoice: Invoice;
  preference: preference;
  currency: currency;
  selectPayAmountDesc: string;
  selectPayAmountDueBtn: string;
  selectPayAmountBalanceBtn: string;
  constructor(@Inject(MAT_DIALOG_DATA) private passedData: {invoice: Invoice, preference: preference, currency: currency},
    private router: Router, public dialogRef: MatDialogRef<SelectPayAmountComponent>, private tagsService: TagsService) { }

  ngOnInit() {
    if(this.passedData !== undefined){
      this.invoice = this.passedData.invoice;
      this.preference = this.passedData.preference;
      this.currency = this.passedData.currency;
      this.selectPayAmountDesc = this.tagsService.assignObjectToTags(this.invoice, this.preference.labelMap.selectPayAmountDesc, this.tagsService.invoiceFieldTags, this.currency)
      this.selectPayAmountDueBtn = this.tagsService.assignObjectToTags(this.invoice, this.preference.labelMap.selectPayAmountDueBtn, this.tagsService.invoiceFieldTags, this.currency)
      this.selectPayAmountBalanceBtn = this.tagsService.assignObjectToTags(this.invoice, this.preference.labelMap.selectPayAmountBalanceBtn, this.tagsService.invoiceFieldTags, this.currency)
    }
  }

  selectAmount(amountType: string){
    this.dialogRef.close(amountType);
  }

}
