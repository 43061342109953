import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Reason} from '../../../../models/Reason.model';
import {MainObjectTypes} from '../../../../models/MainObjectTypes.model';
import {IDProps} from '../../../../models/IDProps.model';
import {SelectSchedulingObject} from '../scheduler-list-items/scheduler-list-items.component';
import {ResponsiveService} from '../../../responsive.service';

@Component({
  selector: 'scheduler-banner-list-items',
  templateUrl: './scheduler-banner-list-items.component.html',
  styleUrls: ['./scheduler-banner-list-items.component.css']
})
export class SchedulerBannerListItemsComponent implements OnInit {
  @Input() listItems: Reason[];
  @Input() litItemType: MainObjectTypes.REASON = MainObjectTypes.REASON;
  idProp: string;
  titleProp: string;
  descriptionProp: string;
  imageProp: string;
  @Output() onSelectItem = new EventEmitter<SelectSchedulingObject>();
  constructor(public responsiveService: ResponsiveService) { }

  selectObject(changeEvent: any, moveForward: boolean, itemSelected?: Reason){
    let selectItem: SelectSchedulingObject = {changeEvent: changeEvent, moveForward: moveForward, itemSelected: itemSelected};
    this.onSelectItem.emit(selectItem);
  }

  ngOnInit() {
    if (this.litItemType === MainObjectTypes.REASON) {
      this.idProp = IDProps.REASON_ID
      this.titleProp = 'reasonDesc';
      this.descriptionProp = 'reasonDetail';
      this.imageProp = 'imageUrl';
    }
  }

}
