import { Component, OnInit } from '@angular/core';
import {currency, preference} from "../common-classes/app-objects.model";
import {StyleSheetService} from "../styleSheetService";
import {BusinessService} from "../business.service";
import {StoreService} from "./store.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MiniWebsiteDisplayConfig} from "../common-classes/default-styles.model";

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {
  currency: currency;
  styleMap: any;
  displayConfig: MiniWebsiteDisplayConfig;
  loadStore: boolean=false;

  constructor(private storeService: StoreService,
              private styleSheetService: StyleSheetService,
              private businessService: BusinessService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.styleMap = this.styleSheetService.mwStyleMap;
    this.displayConfig = this.styleSheetService.displayConfig;
    this.currency = this.businessService.currency;
    if(this.displayConfig.store){
      this.loadStore = true;
    } else {
      this.router.navigate(['scheduler'], {relativeTo: this.route.root})
    }
  }

}
