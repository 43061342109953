import {Injectable, Injector} from '@angular/core';
import {Subject} from 'rxjs/index';
import {TimeService} from '../scheduler/time/time.service';
import {
    CourseSet,
    CSFilterParameterBean, MonthDataObj
} from '../common-classes/app-objects.model';
import {WaitList} from '../../models/WaitList.model';
import {MainObjectTypes} from '../../models/MainObjectTypes.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
    MonthAvailability,
    RepeatingAppointmentAvailability, SingleDateAvailability,
    SingleTimeSlot,
    TimeLock, TimeSlotUnit
} from '../scheduler/time/time-objects.model';
import {map} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {ApiService} from '../api.service';
import {AvailabilityFilterData} from '../../models/AvailabilityFilterData.model';
import {Appointment} from '../../models/Appointment.model';
import * as _ from 'lodash';
import {MilitaryTimeConversion} from '../../pipes/militaryTimeConversion.pipe';
import {BusinessService} from '../business.service';
import {Reason, ReasonTypes} from '../../models/Reason.model';
import {SchedulerPreferenceService} from '../scheduler-preference.service';
import {FormatFunctionsService} from '../format-functions.service';
import {ClassSession} from '../../models/ClassSession.model';
import {DateAvailability} from '../../models/DateAvailability.model';
import {Location} from '../../models/Location.model';
import {Staff} from '../../models/Staff.model';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {
  currentEventDateChanged = new Subject();
  timeService: TimeService;
  currentEvent: Appointment | WaitList;
  currentEventType: MainObjectTypes.APPT | MainObjectTypes.WAIT_LIST;
  apiService: ApiService;
  businessService: BusinessService;
    availabilityFilterData: AvailabilityFilterData;
    currentEventChanged  = new Subject<{newEventData: Appointment | WaitList, oldEventData: Appointment | WaitList}>();
    timeRemoved = new Subject();
    schedulerPreferenceService: SchedulerPreferenceService;
    formatFnsService: FormatFunctionsService;
    maxBookingDate: Date = new Date();
    minBookingDate: Date = new Date();
    waitListMode: boolean = false;
  constructor(private injector: Injector, private http: HttpClient, private militaryTimeConversion: MilitaryTimeConversion) {
    this.apiService = injector.get(ApiService);
    this.timeService = injector.get(TimeService);
    this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
    this.formatFnsService = injector.get(FormatFunctionsService);
    this.businessService = injector.get(BusinessService);
  }

  setMinMaxBookingDates(reason: Reason) {
      let hrsInMsForMinBookingDate = reason.apptHrMin * this.timeService.oneHrMs;
      let minBookingDateMs = new Date().getTime() + hrsInMsForMinBookingDate;
      let localMinBookingDate = new Date(minBookingDateMs);
      let UTCDate = Date.UTC(localMinBookingDate.getUTCFullYear(), localMinBookingDate.getUTCMonth(), localMinBookingDate.getUTCDate(), localMinBookingDate.getUTCHours(), localMinBookingDate.getUTCMinutes());
      this.minBookingDate = new Date(UTCDate);
      let daysInMsForMaxBookingDate = (reason.apptDayMax + 1) * this.timeService.oneDayMs;
      let maxBookingDateMs = new Date().getTime() + daysInMsForMaxBookingDate;
      this.maxBookingDate = new Date(maxBookingDateMs);
  }

    setCurrentEvent(event: Appointment | WaitList, eventType: MainObjectTypes.APPT | MainObjectTypes.WAIT_LIST, onRemoveTime: boolean) {
        const oldEventData = _.cloneDeep(this.currentEvent);
        this.currentEvent = event;
        const newEventData = _.cloneDeep(event);
        this.currentEventType = eventType;
        this.currentEventChanged.next({newEventData, oldEventData});
        if (onRemoveTime)
            this.timeRemoved.next();
    }

    clearCurrentEvent() {
        this.currentEvent = null;
        this.availabilityFilterData = null;
    }

    getAvailableMonthDates(year: string | number, month: string | number) {
        const availabilityFilterData: AvailabilityFilterData = this.getAvailabilityFilterDataForAppt();
        this.availabilityFilterData = availabilityFilterData;
        return this.http.post<MonthAvailability>(this.apiService.apiUrl + 'availability/' + year + '/' + month, availabilityFilterData,
            {observe: 'body', responseType: 'json'})
            .pipe(
                map((responseData: MonthAvailability) => {
                    return responseData;
                }),
                catchError((err, caught) => this.apiService.handleError(err))
            );
    }


    getMonthDatesServices(CSFilterObj: CSFilterParameterBean, year: string | number, month: string | number, findBestMonth: boolean, duration: number){
        if(CSFilterObj.staffIdList !== null && CSFilterObj.staffIdList.length === 1 && CSFilterObj.staffIdList[0] === null){
          CSFilterObj.staffIdList = null;
        }
        let paramsToPass = new HttpParams();
        paramsToPass = paramsToPass.append('findBestMonth', JSON.stringify(findBestMonth));
        paramsToPass = paramsToPass.append('duration', JSON.stringify(duration));
        paramsToPass = paramsToPass.append('waitListMode', JSON.stringify(this.waitListMode));
        return this.http
            .post<MonthAvailability>(this.apiService.apiUrl + 'availability/month/' + year + '/' + month, CSFilterObj, {observe: "body", responseType: 'json', params: paramsToPass})
            .pipe(
                map((responseData: MonthAvailability) => {
                  return responseData;
                }),
                catchError(this.apiService.handleError)
            )
      }

  getFullDateFromTime(time: number, year: number, month: number, day: number){
      let hourMinuteObj = this.timeService.getTimeString(time);
      return new Date(year, month - 1, day, hourMinuteObj.hourNum, hourMinuteObj.minuteNum);
  }

  configureDateAvailability(dateAvailability: DateAvailability, csFilterObj: CSFilterParameterBean | AvailabilityFilterData, selectedDate: SingleDateAvailability): DateAvailability {
      let propForStartTimeFormatting = 'startTime';
      let propForEndTimeFormatting = 'endTime';
      let propForStartDateFormatting = 'staffStartDate';
      let propForEndDateFormatting = 'staffEndDate';
      let timezoneToUse = this.businessService.businessTimezone; // LHB 09/17/2020 TT-7003 instead of using those props, switched to using timezone and getting the startTimeDateForTimeslotDisplay with the UTC time on the timeslot object
      if(this.schedulerPreferenceService.schedulerPreference.allowTZChange === 1){
          propForStartTimeFormatting = 'clientStartTime';
          propForEndTimeFormatting = 'clientEndTime';
          propForStartDateFormatting = 'clientStartDate';
          propForEndDateFormatting = 'clientEndDate';
          timezoneToUse = csFilterObj.clientTimeZone; // LHB 09/17/2020 TT-7003 instead of using those props, switched to using timezone and getting the startTimeDateForTimeslotDisplay with the UTC time on the timeslot object
      }
      if (dateAvailability.timeSlots && dateAvailability.timeSlots.length !== 0) {
          //First loop through and make sure that time slots are available based on apptHrMin and apptHrMax
          let validTimeSlots: SingleTimeSlot[] = [];
          for (let i = 0, x = dateAvailability.timeSlots.length; i < x; i++) {
              let dateToCompare = this.getFullDateFromTime(dateAvailability.timeSlots[i].clientStartTime, selectedDate.year, selectedDate.month, selectedDate.day);
              if (dateToCompare <= this.maxBookingDate)
                  validTimeSlots.push(dateAvailability.timeSlots[i]);
              dateAvailability.timeSlots[i].waitListMode = this.waitListMode;
          }
          validTimeSlots.sort((a,b) => (a.staffStartDateTimeUTC > b.staffStartDateTimeUTC) ? 1: -1);
          dateAvailability.timeSlots = validTimeSlots;
      }
      if (dateAvailability.timeSlots !== null && dateAvailability.timeSlots.length !== 0) {
          for (let i = 0, x = dateAvailability.timeSlots.length; i < x; i++) {
              // this.dateAvailability.timeSlots[i].startTimeDateForTimeslotDisplay = this.tagsService.getTimeWithMoment(this.dateAvailability.timeSlots[i][propForStartTimeFormatting], this.dateAvailability.timeSlots[i][propForStartDateFormatting], this.preference.allowTZChange, undefined, this.dateAvailability.timeSlots[i]);
              // this.dateAvailability.timeSlots[i].endTimeDateForTimeslotDisplay = this.tagsService.getTimeWithMoment(this.dateAvailability.timeSlots[i][propForEndTimeFormatting], this.dateAvailability.timeSlots[i][propForEndDateFormatting], this.preference.allowTZChange, undefined, this.dateAvailability.timeSlots[i]);
              // LHB 09/17/2020 TT-7003 instead of using methods above props, switched to using timezone and getting the startTimeDateForTimeslotDisplay with the UTC time on the timeslot object
              dateAvailability.timeSlots[i].startTimeDateForTimeslotDisplay = this.timeService.getTimeFromUTCTimeInClientTimezone(dateAvailability.timeSlots[i].staffStartDateTimeUTC, timezoneToUse);
              dateAvailability.timeSlots[i].endTimeDateForTimeslotDisplay = this.timeService.getTimeFromUTCTimeInClientTimezone(dateAvailability.timeSlots[i].staffEndDateTimeUTC, timezoneToUse);
              //Starttime date in business's timezone
              dateAvailability.timeSlots[i].startTimeDate = this.timeService.getTimeWithMoment(dateAvailability.timeSlots[i].startTime, dateAvailability.timeSlots[i].staffStartDate, 0, csFilterObj.businessTimeZone);
              dateAvailability.timeSlots[i].endTimeDate = this.timeService.getTimeWithMoment(dateAvailability.timeSlots[i].endTime, dateAvailability.timeSlots[i].staffEndDate, 0, csFilterObj.businessTimeZone);
              dateAvailability.timeSlots[i].timeSlotId = selectedDate.year + '-' + selectedDate.month + '-' + selectedDate.day + ':' + dateAvailability.timeSlots[i].staffStartTime + '-' + dateAvailability.timeSlots[i].staffEndTime;
              dateAvailability.timeSlots[i].clientTimeSlotId = selectedDate.year + '-' + selectedDate.month + '-' + selectedDate.day + ':' + dateAvailability.timeSlots[i].clientStartTime + '-' + dateAvailability.timeSlots[i].clientEndTime;
              dateAvailability.timeSlots[i].htmlId = dateAvailability.timeSlots[i].timeSlotId + dateAvailability.timeSlots[i].clientTimeSlotId;
              dateAvailability.timeSlots[i].classScheduleId = null;
          }
          for (let i = 0, x = dateAvailability.timeSlots.length; i < x; i++) { // LHB 09/17/2020 TT-7003 check to remove times that are the same for the client but the staff is different because staff is in different timezones
              if (!dateAvailability.timeSlots[i].duplicate) {
                  let timeSlotId = dateAvailability.timeSlots[i].timeSlotId;
                  let clientTimeSlotId = dateAvailability.timeSlots[i].clientTimeSlotId;
                  for (let j = 0, y = dateAvailability.timeSlots.length; j < y; j++) {
                      if (dateAvailability.timeSlots[j].clientTimeSlotId === clientTimeSlotId &&
                          dateAvailability.timeSlots[j].timeSlotId !== timeSlotId) {
                          dateAvailability.timeSlots[j].duplicate = true;
                      }
                  }
              }
          }
      }
      return dateAvailability;
  }

  getDateAvailabilityServices(CSFilterObj: CSFilterParameterBean, selectedDate: SingleDateAvailability, duration: number){
    if(CSFilterObj.staffIdList !== null && CSFilterObj.staffIdList.length === 1 && CSFilterObj.staffIdList[0] === null){
      CSFilterObj.staffIdList = null;
    }
    let paramsToPass = new HttpParams();
    paramsToPass = paramsToPass.append('duration', JSON.stringify(duration));
    paramsToPass = paramsToPass.append('waitListMode', JSON.stringify(this.waitListMode));
    return this.http
        .post<DateAvailability>(this.apiService.apiUrl + 'availability/day/' + selectedDate.year + '/' + selectedDate.month + '/' + selectedDate.day, CSFilterObj, {observe: "body", responseType: 'json', params: paramsToPass})
        .pipe(
            map((responseData: DateAvailability) => {
              return this.configureDateAvailability(responseData, CSFilterObj, selectedDate);
            }),
            catchError(this.apiService.handleError)
        )
  }

    convertTimeSlots(timeSlots: SingleTimeSlot[]): SingleTimeSlot[] {
        let timezoneToUse = this.timeService.getTimeZoneLabel(this.currentEvent);
        if (this.schedulerPreferenceService.schedulerPreference.allowTZChange && this.timeService.clientTimezone)
            timezoneToUse = this.timeService.clientTimezone;
        for (let i = 0, x = timeSlots.length; i < x; i++) {
            timeSlots[i].selected = false;
            if (!timeSlots[i].courseSet)
                timeSlots[i].timeString = this.timeService.configureTimeDisplay(timezoneToUse, timeSlots[i], 'staffStartDateTimeUTC', 'staffEndDateTimeUTC', this.schedulerPreferenceService.schedulerPreference, this.currentEvent.reason, this.currentEvent.startDate);
            if (this.schedulerPreferenceService.schedulerPreference.booleanMap && this.schedulerPreferenceService.schedulerPreference.booleanMap.timePnlShowTimezones)
                timeSlots[i].timeString += ' (' + this.formatFnsService.removeUnderscoresInLabels(timezoneToUse) + ')';
            if (this.currentEvent.reason.reasonType === ReasonTypes.CLASS)
                timeSlots[i].timeString += ' (' + timeSlots[i].currentCapacity + '/' + timeSlots[i].maxCapacity + ' seats filled)';
        }
        return timeSlots;
    }

    convertCourseSetsIntoTimeSlots(courseSets: CourseSet[]): SingleTimeSlot[] {
        let timeSlots: SingleTimeSlot[] = [];
        for (let i = 0, x = courseSets.length; i < x; i++) {
            const timeSlot: SingleTimeSlot = new SingleTimeSlot();
            timeSlot.convertClassSessionToTimeSlot(courseSets[i].classSessions[0], this.militaryTimeConversion, this.schedulerPreferenceService.schedulerPreference.timeFormat24Hrs, this.waitListMode, this.timeService)
            timeSlot.convertCourseSetToTimeSlot(courseSets[i], this.timeService, this.waitListMode);
            timeSlots.push(timeSlot);
        }
        timeSlots = this.convertTimeSlots(timeSlots);
        return timeSlots;
    }

  getTimeSlots() {
      const month = this.timeService.getPipeMonthFromDate(this.currentEvent.startDate);
      const year = this.timeService.getPipeYearFromDate(this.currentEvent.startDate);
      const day = this.timeService.getPipeDayFromDate(this.currentEvent.startDate);
      const availabilityFilterData = this.getAvailabilityFilterDataForAppt();
      return this.http.post<DateAvailability>(this.apiService.apiUrl + 'availability/' + year + '/' + month + '/' + day, availabilityFilterData,
          {observe: 'body', responseType: 'json'})
          .pipe(
              map((responseData: DateAvailability) => {
                  const selectedDate: SingleDateAvailability = new SingleDateAvailability(Number(day), Number(month), Number(year));
                  responseData = this.configureDateAvailability(responseData, availabilityFilterData, selectedDate)
                  responseData.timeSlots = this.convertTimeSlots(responseData.timeSlots);
                  return responseData;
              }),
              catchError((err, caught) => this.apiService.handleError(err))
          );
  }

  getRecurringAvailabilityServices(CSFilterObj: CSFilterParameterBean, numberOfAppointments: number, startDate?: string, daysOfWeek?: number[]){
    let daysOfWeekString = '';
    if(daysOfWeek !== undefined && daysOfWeek !== null && daysOfWeek.length !== 0){
      for(let i = 0, x = daysOfWeek.length; i < x; i++){
        if(i === 0)
          daysOfWeekString += daysOfWeek[i];
        else
          daysOfWeekString = daysOfWeekString + "," + daysOfWeek[i]
      }
    } else {
      daysOfWeekString = null;
    }
    let paramsToPass = new HttpParams();
    paramsToPass = paramsToPass.append('sessions', JSON.stringify(numberOfAppointments));
    paramsToPass = paramsToPass.append('startDate', startDate);
    paramsToPass = paramsToPass.append('daysOfWeek', daysOfWeekString);
    return this.http
        .post<RepeatingAppointmentAvailability[]>(this.apiService.apiUrl + 'availability/recurring', CSFilterObj, {observe: "body", responseType: 'json', params: paramsToPass})
        .pipe(
            map((responseData: RepeatingAppointmentAvailability[]) => {
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  getMonthDatesClasses(CSFilterObj: CSFilterParameterBean, year: number, month: number, findBestMonth: boolean){
    let params = new HttpParams();
    params = params.append('findBestMonth', JSON.stringify(findBestMonth));
    params = params.append('waitListMode', JSON.stringify(this.waitListMode));
    return this.http
        .post<MonthAvailability>(this.apiService.apiUrl + 'availability/class/month/' + year + '/' + month, CSFilterObj, {observe: "body", responseType: 'json', params})
        .pipe(
            map((responseData: MonthAvailability) => {
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  getDateAvailabilityClasses(CSFilterObj: CSFilterParameterBean, year: string | number, month: string | number, day: string | number){
    let params = new HttpParams();
    params = params.append('waitListMode', JSON.stringify(this.waitListMode));
    return this.http
        .post<ClassSession[]>(this.apiService.apiUrl + 'availability/class/day/' + year + '/' + month + '/' + day, CSFilterObj, {observe: "body", responseType: 'json', params})
        .pipe(
            map((responseData: ClassSession[]) => {
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  getAvailabilityClassesNoCalendar(CSFilterObj: CSFilterParameterBean){
    return this.http
        .post<DateAvailability>(this.apiService.apiUrl + 'availability/class/noCalendar', CSFilterObj, {observe: "body", responseType: 'json'})
        .pipe(
            map((responseData: DateAvailability) => {
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  getCourseSets(CSFilterObj: CSFilterParameterBean){
    let paramsToPass = new HttpParams();
    paramsToPass = paramsToPass.append('waitListMode', JSON.stringify(this.waitListMode));
    return this.http
        .post<CourseSet[]>(this.apiService.apiUrl + 'availability/courses', CSFilterObj, {observe: "body", responseType: 'json', params: paramsToPass})
        .pipe(
            map((responseData: CourseSet[]) => {
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  addTimeLock(timeLock: TimeLock){
    return this.http
        .post<boolean>(this.apiService.apiUrl + 'addTimeLock', timeLock, {observe: "body", responseType: 'json'})
        .pipe(
            map((responseData: boolean) => {
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  removeTimeLock(timeLock: TimeLock){
    return this.http
        .post<boolean>(this.apiService.apiUrl + 'removeTimeLock', timeLock, {observe: "body", responseType: 'json'})
        .pipe(
            map((responseData: boolean) => {
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

    getApptDuration(event: Appointment | WaitList, eventType: MainObjectTypes.APPT | MainObjectTypes.WAIT_LIST | MainObjectTypes.REPEATING_APPOINTMENT): number {
        let duration: number;
        let checkUTCTimeProps = false;
        let startDateTimeUTC = 0;
        let endDateTimeUTC = 0;
        if (eventType === MainObjectTypes.APPT) {
            event = event as Appointment;
            if (event.startDateTimeUTC && event.endDateTimeUTC &&
                typeof event.startDateTimeUTC === 'number' && typeof event.endDateTimeUTC === 'number') {
                checkUTCTimeProps = true;
                startDateTimeUTC = event.startDateTimeUTC;
                endDateTimeUTC = event.endDateTimeUTC;
            }
        }
        if (event.duration && event.duration.duration)
            duration = event.duration.duration;
        else if (checkUTCTimeProps)
            duration = ((endDateTimeUTC - startDateTimeUTC) / 1000) / 60;
        else if (event.reason)
            duration = event.reason.visitMinutes;
        return duration;
    }

    getAvailabilityFilterDataForAppt(): AvailabilityFilterData {
        const availabilityFilterData: AvailabilityFilterData = new AvailabilityFilterData();
        const eventForParams = _.cloneDeep(this.currentEvent);
        availabilityFilterData.duration = this.getApptDuration(this.currentEvent, this.currentEventType);
        availabilityFilterData.reasonIdList = [eventForParams.reason.reasonId];
        if (this.currentEvent instanceof WaitList)
            availabilityFilterData.waitListMode = true;
        else
            availabilityFilterData.waitListMode = false;
        if (eventForParams.client && eventForParams.client.clientId)
            availabilityFilterData.clientId = eventForParams.client.clientId;
        else
            availabilityFilterData.showClientTimezone = false;
        if (eventForParams.staff && eventForParams.staff.professionalId)
            availabilityFilterData.staffIdList = [eventForParams.staff.professionalId];
        if (eventForParams.location && eventForParams.location.locationId)
            availabilityFilterData.locationIdList = [eventForParams.location.locationId];
        else if (eventForParams.locationGroup && eventForParams.locationGroup.groupId)
            availabilityFilterData.locationGroupIdList = [eventForParams.locationGroup.groupId];
        if (eventForParams.address)
            availabilityFilterData.address = eventForParams.address;
        if (this.schedulerPreferenceService.schedulerPreference.allowTZChange === 1) {
            availabilityFilterData.showClientTimezone = true;
            availabilityFilterData.clientTimeZone = this.timeService.clientTimezone;
        }
        return availabilityFilterData;
    }

    getEventYear(): string {
        if (this.currentEvent.startDateTimeUTC)
            return this.timeService.getPipeYearFromDate(this.currentEvent.startDateTimeUTC);
        else if (this.currentEvent.startDate)
            return this.timeService.getPipeYearFromDate(this.currentEvent.startDate);
        else
            return this.timeService.getPipeYearFromDate(new Date());
    }

    getEventMonth(): string {
        if (this.currentEvent.startDateTimeUTC)
            return this.timeService.getPipeMonthFromDate(this.currentEvent.startDateTimeUTC);
        else if (this.currentEvent.startDate)
            return this.timeService.getPipeMonthFromDate(this.currentEvent.startDate);
        else
            return this.timeService.getPipeMonthFromDate(new Date());
    }

    getEventDay(): string {
        if (this.currentEvent.startDateTimeUTC)
            return this.timeService.getPipeDayFromDate(this.currentEvent.startDateTimeUTC);
        else if (this.currentEvent.startDate)
            return this.timeService.getPipeDayFromDate(this.currentEvent.startDate);
        else
            return this.timeService.getPipeDayFromDate(new Date());
    }

    createTimeSlotsFromWaitlist(): SingleTimeSlot[] {
        const timeSlots: SingleTimeSlot[] = [];

        if (this.currentEvent instanceof WaitList && this.currentEvent.waitListDateList && this.currentEvent.waitListDateList.length > 0) {
            for (let i = 0, x = this.currentEvent.waitListDateList.length; i < x; i++)
                for (let j = 0, y = this.currentEvent.waitListDateList[i].waitListDateTimeList.length; j < y; j++) {
                    const timeSlot = new SingleTimeSlot();
                    timeSlot.createTimeSlotFromWaitListDateTime(this.currentEvent.waitListDateList[i].waitListDateTimeList[j], this.currentEvent.waitListDateList[i], this.currentEvent, this.militaryTimeConversion, this.schedulerPreferenceService.schedulerPreference.timeFormat24Hrs, this.timeService);
                    timeSlots.push(timeSlot);
                }
        }
        return timeSlots;
    }

    createFirstAvailableTimeSlot(reason: Reason, staff: Staff, location: Location): SingleTimeSlot {
        let locationId = null;
        let staffId = null;
        let reasonId = reason.reasonId;
        if(location)
            locationId = location.locationId;
        if(staff)
            staffId = staff.professionalId;

        let waitListTimeSlotUnit: TimeSlotUnit = new TimeSlotUnit().setProps(locationId, staffId, reasonId, null);

        let stringDate = this.timeService.getStringDateFromTime(new Date());
        let firstAvailTime: SingleTimeSlot = new SingleTimeSlot().loadSingleTimeSlot(false, true, 'waitListFirstAvail', [waitListTimeSlotUnit], true, true, stringDate)
        firstAvailTime.clientTimeSlotId = 'waitListFirstAvail';
        firstAvailTime.htmlId = 'waitListFirstAvail';
        return firstAvailTime;
    }

    checkIfNextMonthAllowed(activeYear: number, activeMonth: number, currentMonthData: MonthDataObj, maxBookingDate?: Date, minBookingDate?: Date): boolean{
        if (!maxBookingDate)
            maxBookingDate = this.maxBookingDate;
        if (!minBookingDate)
            minBookingDate = this.minBookingDate;
        let allowGetNextMonth = false
        let nextYear = activeYear;
        let nextMonth = activeMonth + 1;
        if(currentMonthData.monthNumberIndex === 11){
            nextYear = nextYear + 1;
            nextMonth = 1;
        }
        let nextMonthYearMs = new Date(nextYear, nextMonth - 1, 1).getTime();
        if(nextMonthYearMs <= maxBookingDate.getTime() && nextMonthYearMs >= minBookingDate.getTime())
            allowGetNextMonth = true;
        return allowGetNextMonth;
    }

    checkIfPrevMonthAllowed(activeYear: number, activeMonth: number, currentMonthData: MonthDataObj, maxBookingDate?: Date, minBookingDate?: Date): boolean{
        if (!maxBookingDate)
            maxBookingDate = this.maxBookingDate;
        if (!minBookingDate)
            minBookingDate = this.minBookingDate;
        let allowGetPrevMonth = false
        let prevYear = activeYear;
        let prevMonth = activeMonth - 1;
        if(currentMonthData.monthNumberIndex === 0){
            prevYear = prevYear - 1;
            prevMonth = 12;
        }

        let prevMonthYearMs = new Date(prevYear, prevMonth,0).getTime();//when set date to 0 will give you last day of previous month
        if(prevMonthYearMs <= maxBookingDate.getTime() && prevMonthYearMs >= minBookingDate.getTime())
            allowGetPrevMonth = true;
        return allowGetPrevMonth;
    }
}
