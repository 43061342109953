import {ConfigListMap, preference, schedulerLink} from "./app-objects.model";
import {TitleCasePipe} from "@angular/common";
import {OnInit} from "@angular/core";


export class LabelMap implements OnInit {
    errorLoadingLabel: string = "Error loading data. Please refresh the page and try again.";
    additionalGuestText: string = "Additional Guests";
    allDayLabel: string = 'All Day';
    // Below need to be translated still
    addToCalendarBtn: string = "Add to Calendar";
    addToCalendarGoogleLabel: string = "Google";
    addToCalendarAppleLabel: string = "Apple iCal";
    addToCalendarOutlookLabel: string = "Outlook Live";
    addToCalendarYahooLabel: string = "Yahoo";
    addToCalendarICSLabel: string = ".ics File";
    addToCalendarEventTitle: string = "[Appointment] %REASON% with %STAFF_FULLNAME%";
    addToCalendarEventLocation: string = "%LOCATION_NAME% %APPT_ADDRESS%";
    addToCalendarEventDescription: string = "Manage your appointment at %MANAGE_APPT_URL%";
    addToCartBtn: string = "Add to Cart";
    addToCartBtnSuccess: string = "Added to Cart!";
    //Above need to be translated still
    appointmentDateTimeList: string = "<b>%APPT_DATE% at %APPT_CLIENT_START_TIME_SHORT%:</b> %STAFF_FULLNAME% at %LOCATION_NAME% for %REASON%";
    appointmentDetailText: string = "<dl class=\"row\"><dt class=\"col-3\"><strong>Id:</strong></dt>\n" +
        "<dd class=\"col-9\">%APPT_ID%</dd>\n" +
        "<dt class=\"col-3\"><strong>Date and Time:</strong></dt>\n" +
        "<dd class=\"col-9\">%APPT_CLIENT_START_DATE_TIME_FULL% %TIMEZONE_SHORT%</dd>\n" +
        "<dt class=\"col-3\"><strong>" + this.titleCasePipe.transform(this.configListLabels.reasonLabel) +":</strong></dt>\n" +
        "<dd class=\"col-9\">%REASON%</dd>\n" +
        "<dt class=\"col-3\"><strong>" + this.titleCasePipe.transform(this.configListLabels.locationLabel) +":</strong></dt>\n" +
        "<dd class=\"col-9\">%LOCATION_NAME%</dd>\n" +
        "<dt class=\"col-3\"><strong>" + this.titleCasePipe.transform(this.configListLabels.staffLabel) +":</strong></dt>\n" +
        "<dd class=\"col-9\">%STAFF_FULLNAME%</dd>\n" +
        "</dl>";
    apptDetailTitle: string = "Appointment Detail";
    apptDetailPendingApptInfoMsg: string = "Your appointment is currently pending confirmation. You will be notified once a " + this.configListLabels.staffLabel + " has approved your appointment request.";
    apptDetailRescheduleConfirmMsg: string = "Success! Your appointment has been rescheduled."
    modalCloseBtnLabel: string = "Close";
    apptCancelHourPlural: string = this.cancelHrs==1 ? 'hour' : 'hours';
    // Below need to be translated still
    apptCancelNotInOpenStatusMsg: string = "You cannot cancel appointments that are in %APPT_STATUS% status.";
    //Above need to be translated still
    apptCancelPastTimeframeMsg: string = "You are past the allowed timeframe to cancel this appointment. Cancellations are allowed up to %ALLOWED_CANCEL_HOURS% " + this.apptCancelHourPlural + " before the appointment start time.";
    apptDoesNotBelongToAccountErrorMsg: string = "There was an error loading the details for this appointment. This appointment does not belong to this account.";
    apptDoesNotBelongToAccountErrorTitle: string = "Error Loading Appointment Details";
    apptMaxRescheduleCountErrorMsg: string = "You have reached the max number of allowed reschedulings for this appointment.";
    apptListErrorLoadingMsg: string = "Error loading appointment lists. Please try again later.";
    apptMaxRescheduleCountErrorTitle: string = "Rescheduling Not Allowed";
    apptRescheduleHourPlural: string = this.rescheduleHrs==1 ? 'hour' : 'hours';
    // Below need to be translated still
    apptRescheduleNotAllowedByBusiness: string = "Rescheduling is not allowed by this business";
    apptRescheduleNotInOpenStatus: string = "This appointment is currently in %APPT_STATUS% status. You can only reschedule appointments that are in Open status.";
    //Above need to be translated still
    apptReschedulePastTimeframeMsg: string = "You are past the allowed timeframe to reschedule this appointment. Rescheduling is allowed up to %ALLOWED_RESCHEDULE_HOURS% " + this.apptRescheduleHourPlural + " before the appointment start time.";
    apptRescheduleNotAllowedTitle: string = "Reschedule Appointment";
    appointmentIntentPnlErrorLoading = this.errorLoadingLabel;
    appointmentIntentPnlNoObjectsMsg: string = "There are no "+this.titleCasePipe.transform(this.configListLabels.appointmentIntentLabelPlural)+" available for booking at this time";
    appointmentIntentText: string = '<p>Select an ' + this.titleCasePipe.transform(this.configListLabels.appointmentIntentLabel) + '</p>';
    appointmentIntentTitle: string = this.titleCasePipe.transform(this.configListLabels.appointmentIntentLabel);
    // appointmentFieldList: string = null;//NOT SURE IF USED
    authorizeCCBtnLabel: string = "Authorize Card";
    bookNewApptLabel: string = "Book a New Appointment";
    bookIntentLabel: string = "Book Intent";
    bookIntentBtn: string = "<i class=\"fas fa-calendar-check\"></i>";
    businessInfoText: string = '<p>Thanks for visiting our web scheduler to make an appointment with us! Click through the screens on the right to reserve a time to meet with <strong>%BUSINESS_NAME%</strong>. We look forward to servicing you soon!</p>\n' +
        '<h4>Additional Info:</h4>\n' +
        '<p>Managing your own appointments and need some help? Read the how-to guides on:</p>\n' +
        '<ul>   <li>Booking your <a target=\'_blank\' href="https://timetap.atlassian.net/wiki/display/TCB/Booking+your+first+appointment">first appointment</a></li>\n' +
        '   <li><a target=\'_blank\' href="https://timetap.atlassian.net/wiki/display/TCB/Registering+for+our+Scheduler">Registering</a> on our scheduler</li>\n' +
        '   <li><a target=\'_blank\' href="https://timetap.atlassian.net/wiki/display/TCB/Cancelling+an+Appointment">Cancelling</a> or <a href="https://timetap.atlassian.net/wiki/display/TCB/Rescheduling+an+Appointment">Rescheduling</a> an appointment</li>\n' +
        '</ul><p></p>';
    buttonSpacerString: string = "&nbsp;";
    // Below need to be translated still
    calendarId: string = "Appointment ID";
    //Above need to be translated still
    cancelApptErrorMsg: string = "There was an error cancelling your appointment. Please refresh the page and try again.";
    cancelApptNoReasonEnteredErrorMsg: string = "No reason entered. You must enter a reason to cancel your appointment.";
    cancelBtnLabel: string = "Cancel";
    cancelAppointmentModalTitle: string = "Cancel Appointment";
    cancelAppointmentModalDescription: string = "Please enter a reason below for why you want to cancel this appointment:";
    cancelModalReasonTitle: string = "Cancellation Reason:";
    // Below need to be translated still
    cancelNotAllowedClientOnHold: string = "Your " + this.configListLabels.clientLabel + " account status is currently on hold and does not allow you to cancel your own appointments. To cancel appointments, please contact the business.";
    //Above need to be translated still
    cancelledApptBtnLabel: string = "Cancelled";
    cancelledApptTitle: string = "Cancelled Appointments";
    // Below need to be translated still
    cancelRepeatingAppointmentModalTitle: string = "Cancel Repeating Appointment";
    cancelRepeatingAppointmentModalDescription: string = "By cancelling this repeating appointment, you will be cancelling any remaining open appointments and prevent this repeating appointment series from adding any more appointments in the future. Please enter a reason below for why you want to cancel:";
    cancelRepeatingAppointmentErrorMsg: string = "There was an error cancelling your repeating appointment. Please refresh the page and try again.";
    cancelRepeatingAppointmentNoReasonEnteredErrorMsg: string = "No reason entered. You must enter a reason to cancel your repeating appointment.";
    cancelWaitlistRegistrationModalTitle: string = "Cancel Wait List Registration";
    cancelWaitlistRegistrationModalDescription: string = "By cancelling this wait list registration you are removing yourself from being notified when timeslots open up that meet this wait list criteria. Please enter a reason below for why you want to cancel:";
    cancelWaitlistRegistrationErrorMsg: string = "There was an error cancelling your wait list registration. Please refresh the page and try again.";
    cancelWaitlistRegistrationNoReasonEnteredErrorMsg: string = "No reason entered. You must enter a reason to cancel your wait list registration";
    cartApplyDiscountLabel: string = "Apply Discount";
    cartCheckoutBtn: string = "Continue To Checkout";
    cartDiscountsLabel: string = "Discounts:";
    cartEmptyMsg: string = "You have no items in your cart.";
    cartEmptyShopLinkLabel: string = "Shop Our Store";
    cartCouponCodeReasonSpecific: string = "The coupon you entered is not valid for the items in your cart.";
    cartQuantityLabel: string = "Quantity";
    cartRemoveLabel: string = "Remove";
    cartSubtotalLabel: string = "Subtotal:";
    cartSummaryTitle: string = "Order Summary";
    cartTaxLabel: string = "Tax:"
    cartTotalLabel: string = "Total:"
    cartTriggerLoginMsg: string = "In order to complete the checkout process, please login to your account below."
    //Above need to be translated still
    clientBlacklistError: string = "The email address you have entered has been put on our list of blocked " + this.configListLabels.clientLabelPlural + ". At this time, you cannot create an appointment with our office. Thank you";
    clientCancelTimeframeError: string = "You are outside of the allowed timeframe to cancel this appointment";//NOT SURE IF USED
    clientConfirmedApptMsg: string = "Thanks! You are confirmed for your appointment!";
    clientProfileTitle: string = '%CLIENT_FULLNAME% Profile';
    // Below need to be translated still
    clientApptCannotBeConfirmedMsg: string = "Sorry, appointments that are in %APPT_STATUS% status cannot be confirmed.";
    clientOnHoldError: string = "Your " + this.titleCasePipe.transform(this.configListLabels.clientLabel) + " account is currently on hold. Please contact the business directly to book an appointment.";
    clientProfileAddNewClientLabel: string = "Add Connected " + this.titleCasePipe.transform(this.configListLabels.clientLabel);
    clientProfileNoFormsMsg: string = 'There are no forms assigned to you that you need to fill out.';
    clientProfileViewFormsBtn: string = '<i class=\"fas fa-search\"></i>';
    clientProfileHomeFormsToFillOutText: string = "<i class=\"fas fa-exclamation-triangle\"></i> You have forms that need to get filled out! Please click through the form links below:";
    //Above need to be translated still
    clientDetailsCancelEditBtnLabel: string = 'Cancel Edits';
    clientDetailsEditBtnLabel: string = 'Edit';
    clientDetailsSaveEditBtnLabel: string = 'Save Changes';
    // Below need to be translated still
    clientInfoFieldsInvalid: string = "We could not save your appointment because the following %INVALID_FIELD_COUNT% fields have errors:";
    clientInfoFieldsInvalidTitle: string = "Error saving appointment";
    clientPackageNoApptsMsg: string = "No appointments have been redeemed with this package."
    clientPaymentsAddCardTitle: string = "Add New Card";
    clientPaymentsAddCardBtn: string = "Add Card";
    clientPaymentsAddingCardBtn: string = "Adding Card...";
    clientPaymentsAddCardToConfirmAppointmentDescription: string = "In order to complete your booking, you must first add a card on file. Please fill out your card details below to confirm your booking.";
    clientPaymentsDeleteBtn: string = "<i class='fas fa-trash'></i>";
    clientPaymentsDeleteDescription: string = "Please confirm that you want to remove your %CREDIT_CARD_BRAND% card ending in %CREDIT_CARD_LAST4% from your list of payments.";
    clientPaymentsDeleteErrorMsg: string = "There was an error deleting your card. Please try again.";
    clientPaymentsNoCardsOnFile: string = "There are no stored cards on file.";
    clientStartTimeDate: string = 'Date & Time';
    //Above need to be translated still
    completedApptBtnLabel: string = "Completed";
    completedApptTitle: string = "Completed Appointments";
    // Below need to be translated still
    confirmEmailMismatchError: string = "Email addresses do not match!"
    cookiesBannerText: string = "For continuous improvement of user experience this website uses  cookies for collecting your IP address and individual statistics.";
    //Above need to be translated still
    courseSessionsLabel: string = "Sessions";
    defaultSMSRemindValue: string = "false";
    // Below need to be translated still
    disclaimerFormConfirmationMessage: string = "<p style='text-align: center;'><strong>You have some forms that need to be filled out:</strong></p><div style='text-align: center'>%FORM_LINK_LIST%</div>";
    disclaimerFormErrorLoading: string = "We could not find a form for you to fill out at this link. Please check your link and try again."
    disclaimerFormErrorSubmitting: string = "We could not submit your form at this time. Please try again."
    disclaimerFormLink: string = "%FORM_NAME%";
    disclaimerFormSubmitBtn: string = "Submit";
    disclaimerSuccessMsg: string = "<strong>Thanks for submitting the %FORM_NAME%!</strong> We've successfully received your form.";
    disclaimerFormFieldsInvalid: string = "We could not save your form because the following %INVALID_FIELD_COUNT% fields have errors:";
    disclaimerFormFieldsInvalidTitle: string = "Error saving form";
    emailSettingsNoCategoriesMsg: string = 'There are no email templates enabled for you to change your subscription for.';
    endDateNullLabel: string = 'No date set';
    errorReschedulingAppointment: string = "There was an error rescheduling your appointment. Please refresh your page and try again.";
    //Above need to be translated still
    errorSavingAllAppointmentsDblBooked: string = "<div style='text-align:center;'>Unfortunately, all the appointment dates and times you selected have recently been reserved by someone else. Please click back to choose a different dates and times.</div>";
    errorSavingAppointment: string = "<div style='text-align:center;'>There was an error saving your appointment. Please refresh your page and try again.</div>";
    errorSavingAppointmentAddCard: string = "<div style='text-align:center;'>In order to complete your booking, you must have a card on file. Please click save and add a card when prompted to complete your booking.</div>"
    errorSavingAppointmentDblBooked: string = "<div style='text-align:center;'>Unfortunately, the appointment date and time you've selected has recently been reserved by someone else. Please click back to choose a different date and time.</div>";
    errorSavingAppointmentClassMaxCapacity: string = "<div style='text-align:center;'>Unfortunately, the date and time you've selected has reached its max capacity. Please click back to choose a different date and time.</div>";
    errorSavingAppointmentTimeOut: string = "<div style='text-align:center;'>We are still processing your appointment request. You should receive an email notification in the next 10-15 minutes confirming your request. If you do not, please try booking again. </div>";
    errorSavingClassApptAlreadyEnrolled: string = "You are already enrolled in this " + this.configListLabels.classLabel + " session. You cannot enroll in a " + this.configListLabels.classLabel + " session more than once.";
    fileUploadBtnChooseFile: string = "Choose File";
    fileUploadBtnUploadingFile: string = "Uploading File";
    fileUploadBtnUploadedFile: string = "File Uploaded!";
    fileUploadErrorMsg: string = "Error uploading file. Please try again.";
    filterButton: string = "Search";
    filterLabel: string = "Filter by zip code";
    genericErrorMsg: string = "Error loading data.";
    headerNavLogin: string = 'Login';
    headerNavMyAccount: string = 'My Account';
    headerNavRegister: string = 'Register';
    headerNavScheduler: string = 'Book Appointment';
    headerNavStore: string = 'Packages';
    headerNavWaitList: string = 'Join Wait List';
    headerText: string = '%BUSINESS_NAME%';
    hourLabel: string = 'Hour';
    hoursLabel: string = 'Hours';
    infoPnlBookingSummary: string = "Booking %REASON% on %APPT_DATE_LIST%";
    infoBookingSummaryWaitList: string = "Joining wait list for %REASON% on %WAITLIST_DATE_LIST%";
    infoPnlNameLabel: string = "Booking For:";
    infoPnlSendTextCBLabel: string = 'Send Text Message Reminder:';
    infoPnlSendTextCBLabelWaitList: string = 'Send Text Message Notification:';
    infoPnlSendTextHrsCBLabel: string = "Choose when you'd like to receive the text";
    infoPnlSendTextD1Hr: string = '1 Hr in advance';
    infoPnlSendTextD2Hr: string = '2 Hrs in advance';
    infoPnlSendTextD4Hr: string = '4 Hrs in advance';
    infoPnlSendTextD12Hr: string = '12 Hrs in advance';
    infoPnlSendTextD24Hr: string = '24 Hrs in advance';
    infoTitle: string = "Your Information";
    invitationCampaignErrorNoInvitationTitle: string = "Need Invitation";
    invitationCampaignErrorNoInvitationMsg: string = "This scheduler requires an invitation to book. Please request an invitation from the business.";
    invitationCampaignErrorInvitationCompletedTitle: string = "Invitation Already Completed";
    invitationCampaignErrorInvitationCompletedMsg: string = "You have already booked through this invitation. Please request another invitation from the business to book again.";
    invitationCampaignErrorInvitationExpiredTitle: string = "Invitation Expired";
    expiredInvitationText: string = "Your invitation to book with this business has expired.";
    invoiceConnectingWithPaymentPortal: string = "Connecting to Payment Portal...";
    invoiceCreditCardChargeLabel: string = "Pay %INVOICE_PAYMENT_AMOUNT%";
    invoiceCreditCardChargeFailedLabel: string = "We were unable to charge your card at this time. Please try again. If you continue to experience issues, please contact us.";
    invoiceCreditCardChargingLabel: string = "Submitting payment...";
    invoiceCreditCardAuthorizeLabel: string = "Authorize for %INVOICE_PAYMENT_AMOUNT%";
    invoiceCreditCardAuthorizeFailedLabel: string = "We were unable to authorize your card at this time. Please try again. If you continue to experience issues, please contact us.";
    invoiceCreditCardAuthorizingLabel: string = "Authorizing card...";
    invoiceCreditCardSavePayment: string = "Save card for future payments";
    invoiceDateIssuedLabel: string = "Date Issued:";
    invoiceDetailTitle: string = "Invoice Detail";
    invoiceIdLabel: string = "Invoice Id:";
    invoiceItemsSelectPackage: string = "Redeem with Package";
    invoiceItemsTableHeader: string = "Invoice Items:";
    invoiceDownloadBtnLabel: string = "Download";
    invoiceListErrorLoadingMsg: string = "Error loading invoice lists. Please try again later.";
    invoicePayBtnLabel: string = "Pay Now";
    invoiceQuoteDetailTitle: string = "Quote Detail";
    invoiceSubTotalLabel: string = "Sub Total";
    invoiceTransactionFeeLabel: string = "Transaction Fee";
    invoiceTaxLabel: string = "Tax";
    invoiceEnterPaymentTitle: string = "Enter Card Information";
    invoiceTotalLabel: string = "Invoice Total";
    invoicePaidToDateLabel: string = "Paid To Date:";
    invoiceBalanceLabel: string = "Balance:";
    invoiceDepositAmountLabel: string = "Amount Due:";
    invoiceStatusLabel: string = "Status:";
    invoicesOpenListTitle: string = "Open Invoices";
    invoicesOpenBtnLabel: string = "Open";
    invoicesNoOpenMsg: string = "No open invoices found";
    invoicesClosedListTitle: string = "Closed Invoices";
    invoicesClosedBtnLabel: string = "Closed";
    invoicesNoClosedMsg: string = "No closed invoices found";
    invoicesVoidedListTitle: string = "Voided Invoices";
    invoicesVoidedBtnLabel: string = "Voided";
    invoicesNoVoidedMsg: string = "No voided invoices found";
    invoicesViewDetailBtnLabel: string = "<i class=\"fas fa-search\"></i>";
    // Below need to be translated still
    joinWaitListDescription: string = "Select what you'd like to join the wait list for below and then enter the dates and times you'd like to sign up for."
    joinWaitListBtnSpecificTimes: string = "Join Wait List";
    joinWaitListBtnFirstAvailable: string = "Join Wait List for First Available";
    joinWaitListNoReasonMsg: string = 'There are no wait listable reasons configured for scheduling on this booking site.';
    //Above need to be translated still
    labelBack: string = "Back";
    labelBookAnother: string = "Book Another Appointment";
    labelNext: string = "Next";
    labelNoPreference: string = "* No Preference *";
    labelSave: string = "Save";
    // Below need to be translated still
    loadingMsgGeneric: string = "Loading...";
    loadingMsgAppts: string = "Loading Appointments...";
    //Above need to be translated still
    location: string = this.titleCasePipe.transform(this.configListLabels.locationLabel);
    "location.locationName": string = this.titleCasePipe.transform(this.configListLabels.locationLabel);//Used for mapping column header on appt list
    locationGroupTitle: string = this.titleCasePipe.transform(this.configListLabels.locationGroupLabel);
    locationGroupPnlErrorLoading: string = this.errorLoadingLabel;
    locationGroupPnlNoObjectsMsg: string = "There are no " + this.configListLabels.locationGroupLabelPlural + " available for booking at this time";
    locationSuperGroupTitle: string = this.titleCasePipe.transform(this.configListLabels.locationSuperGroupLabel);
    locationSuperGroupPnlErrorLoading: string = this.errorLoadingLabel;
    locationSuperGroupPnlNoObjectsMsg: string = "There are no "  + this.configListLabels.locationSuperGroupLabelPlural + "available for booking at this time";
    locationPnlErrorLoading: string = this.errorLoadingLabel;
    locationPnlNoLocationsFilterSearchMsg: string = "No " + this.configListLabels.locationLabelPlural + " found with search '%FILTER_SEARCH%'";
    locationPnlMapInfoWindowContent: string = "<strong>%LOCATION_NAME%</strong><br/> %LOCATION_ADDRESS%";
    locationPnlVariableAddressInitialDescription: string = "Type in the address for your appointment below. Once you have finished, please hit the validate button to complete lookup.";
    locationPnlVariableAddressInputLabel: string = "Address";
    locationPnlVariableAddressMatchingAddressesTitle: string = "Matching Addresses:";
    locationPnlVariableAddressMatchingAddressesDescription: string = "Below is a list of addresses that match the address you typed in. Please click the confirm button next to the address that best represents yours:";
    locationPnlVariableAddressTitle: string = "Enter Address";
    locationPnlVariableAddressValidateBtn: string = "Validate Address";
    locationPnlVariableAddressConfirmBtn: string = "Confirm Address";
    locationTitle: string = this.titleCasePipe.transform(this.configListLabels.locationLabel);
    locationNoAvailError: string = "There are no " + this.configListLabels.locationLabelPlural + " available for booking at this time";
    loginErrorMsg: string = "There was an error logging you in. Please check your email address and password and try again.";
    loginTitle: string = "Login";
    loginForgotPwd: string = "Forgot Password?";
    loginBtnLabel: string = "Login";
    loginBtnLabelLoggingIn: string = "Logging in...";
    loggedInWelcomeText: string = "<div style='text-align: center; font-size: large;'><p>Now that you've logged into our scheduler, you can:</p><p style='margin-bottom: 5px;'>&bull; Schedule a new appointment</p><p>&bull; View and manage past and future appointments</p><p>Select a button below to get started:</p></div>";
    loggedInWelcomeTitle: string = 'Welcome, %CLIENT_FIRSTNAME%!';
    loginModalEmailLabel: string = 'Your email';
    loginModalPasswordLabel: string = 'Your password';
    logoutLabel: string = "Logout";
    maxApptsReachedModalErrorMsg: string = "You are only allowed to have %MAX_ACTIVE_APPTS% open appointments at a time and you have reached that limit. You must first cancel an existing appointment before being allowed to book any new appointments.";
    maxApptsReachedModalErrorTitle: string = "Max Appointments Reached";
    maxApptsReachedBookingErrorMsg: string = "You've selected to book for %CLIENT_FULLNAME% but they have already reached their max number of bookings allowed. " + this.maxApptsReachedModalErrorMsg;
    maxApptsReachedBookingMultipleErrorMsg: string = "This scheduler only allows for %MAX_ACTIVE_APPTS% upcoming appointments to be scheduled at any given time. You've selected to book %TIME_CART_COUNT% appointments for %CLIENT_FULLNAME%. %CLIENT_FULLNAME% already has %CLIENT_ACTIVE_APPT_COUNT% active appointments and booking %TIME_CART_COUNT% would put them over the maximum allowed of %MAX_ACTIVE_APPTS%. Please go back to the time page and remove %APPT_COUNT_TO_REMOVE% appointments from your time cart.";
    metaDescription: string = 'Schedule an appointment online, book online';
    minuteLabel: string = 'Minute';
    minutesLabel: string = 'Minutes';
    // Below need to be translated still
    monthLabel: string = "Month";
    monthsLabel: string = "Months";
    //Above need to be translated still
    myAccountMyProfileBtnLabel: string = "My Profile";
    noAppointmentText: string = "All appointment times are currently reserved. Please check back again later.";
    noAppointmentIntentError: string = this.appointmentIntentPnlNoObjectsMsg;
    alreadyBookedIntentError: string =  "Provided " + this.titleCasePipe.transform(this.configListLabels.appointmentIntentLabel)+" has already been booked or has expired.";
    noStaffError: string = "There is currently no availability for you to make an appointment through this scheduler. Please check back again later.";
    // Below need to be translated still
    noApptsMsg: string = "No appointments found";
    //Above need to be translated still
    noCancelledApptsMsg: string = "No cancelled appointments found";
    noCompletedApptsMsg: string = "No completed appointments found";
    noNoShowApptsMsg: string = "No no show appointments found";
    noOpenApptsMsg: string = "No open appointments found";
    noshowApptBtnLabel: string = "No Show";
    noshowApptTitle: string = "No Show Appointments";
    openApptTitle: string = "Open Appointments";
    pageTitle = '%BUSINESS_NAME%';
    passwordFieldLabel: string = "Set Your Password";
    payNowButtonLabel: string = 'Pay Now';
    policyText: string = '<h4>Policies &amp; Procedures</h4><p>Cancellations are allowed up to 24 hours before the appointment start time.</p>';
    promptPayWithPackageTitle: string = "Would You Like to Use Your Package?";
    promptPayWithPackageDesc: string = "Items on your invoice can be paid for with one of your packages. If you'd like to use your package against one of your invoice line items, please click the pay with package button below. Otherwise, please click the button to pay by card.";
    promptPayWithPackageBtn: string = "Pay With Package";
    promptPayWithPackageCreditCardBtn: string = "Pay With Card";
    // Below need to be translated still
    packagesAppointmentsLabel: string = 'Appointments';
    packagesErrorLoading: string = 'There was an error loading packages. Please refresh your page and try again. If you continue to experience issues, please contact support.'
    packagesExpiresAfterLabel: string = 'Expires After:';
    packagesExpiresAfterDaysLabel: string = 'Days';
    packagesIncludedReasonsTitle: string = 'Included ' + this.titleCasePipe.transform(this.configListLabels.reasonLabelPlural) + ':';
    packagesLoadingMsg: string = "Loading packages..."
    packagesNoExpirationLabel: string = '<em>No expiration date</em>';
    //Above need to be translated still
    poweredByTimeTap: string = "Powered by <a href='https://www.timetap.com' target='_blank'>TimeTap.com";
    //Below need to be translated still
    printBtnLabel: string = "Print";
    //Above need to be translated still
    quotaFullMsg: string = "The selected reason for your appointment has reached its maximum quota for the booking timeframe";
    reason: string = this.titleCasePipe.transform(this.configListLabels.reasonLabel);
    "reason.reasonDesc": string = this.titleCasePipe.transform(this.configListLabels.reasonLabel);//Used for mapping column header on appt list
    reasonPnlNoReasonsMsg: string = "No " + this.configListLabels.serviceLabelPlural + " or " + this.configListLabels.classLabelPlural + " are available for booking at this time.";
    repeatingApptDateList: string = "<b>%APPT_DATE_TIME%:</b> %STAFF_FULLNAME% at %LOCATION_NAME%";
    addOnReasonListItemLabelSimple: string = '%REASON%';
    addOnReasonListItemLabelPrice: string = '%REASON% - %REASON_PRICE%';
    addOnReasonListItemLabelDesc: string = '%REASON% - %REASON_DESCRIPTION%';
    addOnReasonListItemLabelInstructions: string = '%REASON% - %REASON_INSTRUCTIONS%';
    addOnReasonListItemLabelInstructions2: string = '%REASON% - %REASON_INSTRUCTIONS2%';
    addOnReasonListItemLabelPriceDesc: string = '%REASON% - %REASON_PRICE% - %REASON_DESCRIPTION%';
    addOnReasonListItemLabelPriceInstructions: string = '%REASON% - %REASON_PRICE% - %REASON_INSTRUCTIONS%';
    addOnReasonListItemLabelPriceInstructions2: string = '%REASON% - %REASON_PRICE% - %REASON_INSTRUCTIONS2%';
    // Below need to be translated still
    repeatingApptFilterModalApplyFiltersBtn: string = "Apply Filters";
    repeatingApptFilterModalStartDateLabel: string = "Starting as soon as:";
    repeatingApptFilterModalWeekdaysLabel: string = "Best Days of the Week:"
    repeatingApptFilterModalWeekdaysNeedToSelect: string = "Please select the best days of the week for you."
    repeatingApptFilterModalDescription: string = "Please select what days and times would work best for your %REASON_REPEATING_OPTION_SESSION_COUNT% sessions of %REASON% and we will show the best matching available times and dates:";
    repeatingApptFilterModalTitle: string = "Repeating Appointment Filters";
    registerAnotherWaitList: string = "Register For Another Wait List"
    //Above need to be translated still
    registerDescription: string = "First, please enter your email and we'll check if you've made appointments here in the past. If so we'll send you a password reset email ";
    registerDescriptionClientFound: string = "Looks like you've been here before! We've sent you an email to set a password for the scheduler. Once you've done that, come back here to login.";
    registerTitle: string = "Register";
    registerButtonCheckEmail: string = "Check Email";
    registerDescriptionClientNotFound: string = "We couldn't find your email address %CLIENT_EMAILADDRESS% in our records. Please fill out the fields below to register for our scheduler:";
    registerDescriptionClientNotFoundPreRegisteredOnly: string = "We couldn't find your email address %CLIENT_EMAILADDRESS% in our records. Please contact the business to get registered for the scheduler.";
    // Below need to be translated still
    registerConnectClientTitle: string = "Add a Connected " + this.titleCasePipe.transform(this.configListLabels.clientLabel);
    registerDescriptionConnectClient: string = "Please enter the details of the " + this.configListLabels.clientLabel + " you would like to connect to your account:"
    registerButtonConnectClient: string = "Add & Connect " + this.titleCasePipe.transform(this.configListLabels.clientLabel);
    //Above need to be translated still
    registerErrorMsg: string = "There was an error submitting your registration. Please try again.";
    registerForgotPwdEnterEmailPlaceholder: string = 'Your email';
    registerButtonRegister: string = "Register";
    registerLoginErrorMsg: string = "We've successfully registered you for the scheduler, but there was an error logging you in. Please refresh the page and click the login button to try again.";
    //Below need to be translated still
    repeatingApptBtnLabel: string = "Repeating";
    repeatingApptDetailTitle: string = "Repeating Appointment Detail";
    repeatingApptTitle: string = "Repeating Appointments";
    noRepeatingApptsMsg: string = "No repeating appointments found";
    repeatingApptDetailEndDateLabel: string = "End Date:";
    repeatingApptDetailIdLabel: string = "Id:";
    repeatingApptDetailDaysRepeatsLabel: string = "Repeats every %REPEAT_FREQUENCY% %REPEAT_PERIOD% on:";
    repeatingApptDetailStartDateLabel: string = "Start Date:";
    //Above need to be translated still
    requiredFieldsEmptyMsg: string = "Please fill all the required fields. Scroll down if necessary.";
    // Below need to be translated still
    rescheduleNotAllowedClientOnHold: string = "Your " + this.configListLabels.clientLabel + " account status is currently on hold and does not allow you to reschedule your own appointments. To reschedule appointments, please contact the business.";
    //Above need to be translated still
    rescheduleBtnLabel: string = "Reschedule";
    resetPwdEmailSent: string = "We've sent an email with a link to reset your password to %CLIENT_EMAILADDRESS%. Please check your email, reset your password, and then return here to login.";
    resetPwdTitle: string = "Reset Password";
    resetPwdButtonLabel: string = "Reset Password";
    resetPwdDescription: string = "Please enter your email address below and click the " + this.resetPwdButtonLabel + " button below.";
    resetPwdDescriptionEnteringNewPasswords: string = "Please enter the new password you'd like to use to login to the scheduler below.";
    resetPwdRequestExpired: string = "This password reset request is no longer active. To generate another link to reset your password, click the button below.";
    resetPwdErrorPasswordsMismatched: string = "Passwords do not match. Please check your passwords.";
    resetPwdEnterPasswordPlaceholder: string = 'Enter new password';
    resetPwdEnterPasswordErrorMsg: string = 'Must enter new password';
    resetPwdConfirmPasswordPlaceholder: string = 'Confirm new password';
    resetPwdConfirmPasswordErrorMsg: string = 'Please check passwords match';
    resetPwdRequestError: string = "There was an error resetting your password. Please try again.";
    resetPwdResendRequestButtonLabel: string = "Resend Reset Password Request";
    resetPwdSuccessMsg: string = "Your password has been reset! You can now login with your email address and your new password."
    selectAddOnsTitle: string = "Add Ons For Your Appointment";
    selectAddOnsDescription: string = "Below is a list of add ons that you can choose to include with your appointment for %REASON%. Please select any you'd like to add:";
    selectAddOnsAddAddOnsBtn: string = "Add Selected Add Ons";
    selectAddOnsDeclineAddOnsBtn: string = "Do Not Add Any Add Ons";
    // Below need to be translated still
    selectPackageTitle: string = "Select Package";
    selectPackageDescription: string = "Please select which of your packages you would like to use to redeem this appointment with:";
    selectPackageDropdownDescription: string = "%PACKAGE_NAME% (%PACKAGE_USED_QUANTITY% Used, %PACKAGE_REMAINING_QUANTITY% Remaining)";
    selectPackageBtn: string = "Select Package";
    selectPackageConfirmTitle: string = "Confirm Selected Package";
    selectPackageConfirmDescription: string = "You have selected to apply the following package to the line item %INVOICE_ITEM_DESCRIPTION%: <br/><br/><strong>"+ this.selectPackageDropdownDescription + "</strong><br/><br/> Once you apply the package, it cannot be removed unless you contact the business. Please confirm this is the right package you wish to apply.";
    selectPackageConfirmBtn: string = "Confirm";
    selectPackageSelectDiffPackageBtn: string = "Select Different Package";
    selectPayAmountTitle: string = "Select Payment Amount";
    selectPayAmountDesc: string = "The required amount due is %INVOICE_PAYMENT_AMOUNT%, but you can choose to pay the full amount of the invoice by selecting that option below:"
    selectPayAmountDueBtn: string = "Pay amount due:<br/>%INVOICE_PAYMENT_AMOUNT%"
    selectPayAmountBalanceBtn: string = "Pay remaining balance:<br/>%INVOICE_BALANCE_AMOUNT%"
    // Above need to be translated still
    serviceGroupTitle: string = this.titleCasePipe.transform(this.configListLabels.reasonGroupLabel);
    serviceGroupPnlErrorLoading: string = this.errorLoadingLabel;
    serviceGroupPnlNoObjectsMsg: string = "There are no " + this.configListLabels.reasonGroupLabel + " available for booking at this time";
    serviceSuperGroupTitle: string = "Reason Super Group";
    serviceSuperGroupPnlErrorLoading: string = this.errorLoadingLabel;
    serviceSuperGroupPnlNoObjectsMsg: string = "There are no reason super groups available for booking at this time";
    serviceTitle: string = this.titleCasePipe.transform(this.configListLabels.serviceLabel);
    servicePnlCouponButton: string = "Apply";
    servicePnlCouponErrorNoCode: string = "No coupon code entered. Please enter a code and retry.";
    servicePnlCouponErrorNoCouponFound: string = "No coupon found for coupon code: '%COUPON_CODE%'. Please check the spelling and retry.";
    servicePnlCouponErrorExpired: string = "Coupon code '%COUPON_CODE%' expired on %COUPON_ENDDATE%.";
    servicePnlCouponErrorMaxUseReached: string = "Coupon code '%COUPON_CODE%' has already reached its max use count of %COUPON_MAX_USE_COUNT%.";
    servicePnlCouponErrorNoReasonsDiscounted: string = "Coupon code '%COUPON_CODE%' does not apply to any of the appointment reasons shown.";
    servicePnlCouponSuccess: string = "Success! The coupon code '%COUPON_CODE%' has been applied to %COUPON_APPLIED_REASON_COUNT% reasons below.";
    servicePnlCouponLabel: string = "Enter Coupon Code";
    servicePnlErrorLoading: string = this.errorLoadingLabel;
    servicePnlNoObjectsMsg: string = "There are no " + this.configListLabels.serviceLabelPlural + " or " + this.configListLabels.classLabelPlural + " available for booking at this time";
    staff: string = this.titleCasePipe.transform(this.configListLabels.staffLabel);
    "staff.fullName": string = this.titleCasePipe.transform(this.configListLabels.staffLabel);//Used for mapping column header on appt list
    staffGroupTitle: string = this.titleCasePipe.transform(this.configListLabels.staffLabel) + " Group";
    staffTitle: string = this.titleCasePipe.transform(this.configListLabels.staffLabel);
    staffPnlErrorLoading: string = this.errorLoadingLabel;
    staffPnlNoObjectsMsg: string = "There are no staff available for booking at this time";
    staffPnlNoStaffFilterSearchMsg: string = "No staff found with search '%FILTER_SEARCH%'";
    // Below need to be translated still
    statusLabel: string = "Status:";
    staffStartTimeDate: string = this.titleCasePipe.transform(this.configListLabels.staffLabel) + ' Start Date & Time';
    staffEndTimeDate: string = this.titleCasePipe.transform(this.configListLabels.staffLabel) + ' End Date & Time';
    // Above need to be translated still
    switchToRegisterLink: string = "Register Now";
    switchToRegisterText: string = "Need an account?";
    thanksCourses: string = "<p style='text-align: center;'><strong>Your registration for %REASON% is successful</strong><br/> <br />" + this.titleCasePipe.transform(this.configListLabels.staffLabel) + ": %STAFF_FULLNAME%<br />Set: %SET_NAME%<br /> <br />" + this.titleCasePipe.transform(this.configListLabels.locationLabel) + ": %LOCATION_NAME%<br />%LOCATION_ADDRESS%</p>";
    thanksInfo: string = "<p style='text-align: center;'><strong>Your appointment has been scheduled!</strong><br> <br>" + this.titleCasePipe.transform(this.configListLabels.staffLabel) + ": %STAFF_FULLNAME%<br>Time: %APPT_DATE_TIME%<br>" + this.titleCasePipe.transform(this.configListLabels.serviceLabel) + ": %REASON%<br> <BR>" + this.titleCasePipe.transform(this.configListLabels.locationLabel) + ":  %LOCATION_NAME%<br>%LOCATION_ADDRESS%<br></p>";
    thanksInfoRepeating: string = "<p style='text-align: center;'><strong>Your appointments for %REASON% have been scheduled!</strong></p><div style='text-align: center'>%REPEATING_APPT_DATE_LIST%</div>";
    thanksInfoRepeatingApptsNotSaved: string = "<p style='text-align: center'><strong>Unfortunately, these times were booked by someone else and were not reserved:</strong></p><br><div style='text-align: center'>%REPEATING_APPT_DATE_LIST%</div>";
    thanksPnlErrorLoading: string = this.errorLoadingLabel;
    thanksInfoConfirm: string = "Your appointment is awaiting " + this.configListLabels.staffLabel + " confirmation. You will receive an email shortly to let you know whether the " + this.configListLabels.staffLabel + " person has confirmed or declined your appointment request.";
    thanksInfoConfirmRepeating: string = "Your appointments are awaiting " + this.configListLabels.staffLabel + " confirmation. You will receive an email shortly to let you know whether the " + this.configListLabels.staffLabel + " person has confirmed or declined your appointment requests.";
    thanksConfirmPnlErrorLoading: string = this.errorLoadingLabel;
    thanksInfoPayment: string = "<p style='text-align: center;'><strong>Your appointment is scheduled. Go ahead and submit payment now or wait until your appointment time.</strong><br /> <br />If you would like to go ahead and submit payment, please click the button below:<br />%PAYMENT_BUTTON%</p>";
    thanksInfoPaymentRepeating: string = "<p style='text-align: center;'><strong>Your appointments are scheduled. Go ahead and submit payment now or wait until your first appointment.</strong><br /> <br />If you would like to go ahead and submit payment, please click the button below:<br />%PAYMENT_BUTTON%</p>";
    thanksInfoPaymentAuthOnly: string = "<p style='text-align: center;'><strong>Please enter your credit card details using the button below to confirm your appointment.</strong><br /> <br />By clicking on the payment button below you will be prompted to enter your credit card information but no money will be drafted from your card at this time. This is just required in order to hold your appointment.<br />%PAYMENT_BUTTON%</p>";
    thanksInfoPaymentAuthOnlyRepeating: string = "<p style='text-align: center;'><strong>Please enter your credit card details using the button below to confirm your appointments.</strong><br /> <br />By clicking on the payment button below you will be prompted to enter your credit card information but no money will be drafted from your card at this time. This is just required in order to hold your appointments.<br />%PAYMENT_BUTTON%</p>";
    thanksInfoPaymentRequired: string = "<p style='text-align: center;'><strong>Your appointment is not yet scheduled.</strong><br /> <br />Please click on the button below to make payment. Failure to do that will result in cancellation of your appointment.<br />%PAYMENT_BUTTON%</p>";
    thanksInfoPaymentRequiredRepeating: string = "<p style='text-align: center;'><strong>Your appointments are not yet scheduled.</strong><br /> <br />Please click on the button below to make payment for your appointments. Failure to do that will result in cancellations of your appointments.<br />%PAYMENT_BUTTON%</p>";
    thanksInfoPaymentStaffConfirmationRequired: string = "<p style='text-align: center;'>Your appointment is pending approval. Go ahead and submit payment now or wait until your appointment time.<br> <br> If you would like to go ahead and submit payment, please click the button below:<br />%PAYMENT_BUTTON%</p>";
    thanksInfoPaymentStaffConfirmationRequiredRepeating: string = "<p style='text-align: center;'>Your appointments are pending approval. Go ahead and submit payment now or wait until your appointment times.<br> <br> If you would like to go ahead and submit payment, please click the button below:<br />%PAYMENT_BUTTON%</p>";
    thanksInfoPaymentRequiredStaffConfirmationRequired: string = "<p style='text-align: center;'>Please click on the button below to make payment. Failure to do that will result in cancellation of your appointment request.<br />%PAYMENT_BUTTON%</p>";
    thanksInfoPaymentRequiredStaffConfirmationRequiredRepeating: string = "<p style='text-align: center;'>Please click on the button below to make payment. Failure to do that will result in cancellation of your appointment requests.<br />%PAYMENT_BUTTON%</p>";
    thanksPaymentTitle: string = "Pay Now Or Later";
    thanksPaymentTitleAuthOnly: string = "Authorize Your Card";
    thanksPaymentTitleRequired: string = "Pay Now";
    thanksInfoPaymentStaffConfirmationRequiredTitle: string = "Your appointment request is pending approval";
    thanksInfoPaymentRequiredStaffConfirmationRequiredTitle: string = "Pay Now";
    thanksTitle: string = "Thank You!";
    thanksWaitListTitle: string = "You are on the Wait List";
    timePnlBookingSummary: string = "Booking %REASON% with %STAFF_FULLNAME% at %LOCATION_NAME%";
    timePnlCalendarLabel: string = "Select Date:";
    timePnlCartBookingTimesListTitle: string = "Selected Dates & Times for Booking:";
    timePnlCartCloseBtnLabel: string = "Close";
    timePnlCartDescription: string = "Below are all the times you've selected. If you no longer want to book one of the selected times, click the button to remove it.";
    timePnlCartNoTimesSelected: string = "There are currently no times selected. Close this window and select a few dates and times from the calendar to book.";
    timePnlCartRemoveBtnLabel: string = "Remove";
    timePnlCartTitle: string = "Selected Times";
    timePnlCartWaitlistTimesListTitle: string = "Selected Dates & Times for Waitlist:";
    timePnlChangeTimezoneLabel: string = "Change Timezone";
    timePnlErrorLoading: string = this.errorLoadingLabel;
    timePnlLoadingTimesLabel: string = "Loading Times...";
    timePnlNoCourseSetsAvailable: string = "There is no open availability for %REASON%.";
    timePnlNoTimesAvailOnSelectDay: string = "There are no times available on the selected date. Please choose a different date.";
    timePnlNoTimesAvailOnSelectMonth: string = "There are no times available in the current month. Please choose a different month.";
    timePnlRescheduleBookingSummary: string = "Rescheduling %REASON% with %STAFF_FULLNAME% at %LOCATION_NAME%";
    timePnlSeatsLabel: string = "Seats Remaining";
    timePnlSeatsFullJoinWaitlist: string = "0 seats remaining. Join wait list.";
    timePnlSelectCourseSetLabel: string = "Select the Course Set you'd like to book:";
    // Below need to be translated still
    timePnlSelectRepeatingApptSetLabel: string = "Select the best days and times for your appointments:";
    timePnlRepeatingApptNoTimesAvail: string = "There are no repeating appointment options that match your filter criteria. Please select new filters and try again..";
    timePnlChangeFiltersBtn: string = "Change Filters";
    timePnlShowSetCalendar: string = "Show Calendar";
    timePnlHideSetCalendar: string = "Hide Calendar";
    //Above need to be translated still
    timePnlSelectTimeLabel: string = "Select Time On:";
    timePnlSelectTimeNoClassCalendarLabel: string = "Select Session Date & Time:";
    timePnlSelectedTimesBtnLabel: string = "%SELECTED_TIMES_COUNT% Times Selected";
    timePnlTimezoneLabel: string = "Your Timezone";
    timePnlTimezoneLabelForBusiness: string = "Timezone:";
    timePnlWaitlistTitle: string = "Join the Waitlist";
    timePnlWaitlistText: string = this.waitListFirstAvailable ? "<div style='text-align: center;'>If you only want to be notified if specific date(s) and time(s) become available, then select those form the calendar below. Otherwise, you can click the button to be notified of the first available opening:</div>" : "<div style='text-align: center;'>Select the date(s) and time(s) you would like to be on the wait list for and we will send you a notification if those times become available.</div>";
    timeTitle: string = "Time";
    upcomingApptBtnLabel: string = "Upcoming";
    viewApptDetailBtnLabel: string = "<i class='fas fa-search'></i>";
    viewApptLabel: string = "View Appointments";
    viewApptTitle: string = "Appointment History";
    viewApptIntentLabel: string = "View " + this.titleCasePipe.transform(this.configListLabels.appointmentIntentLabelPlural);
    viewApptIntentTitle: string = this.titleCasePipe.transform(this.configListLabels.appointmentIntentLabel);
    viewDetailsBtnLabel: string = 'View Details';
    viewingAccountForLabel: string = "Viewing Account For:";
    viewingApptsForLabel: string = "Viewing Appointments For:";
    viewingInvoicesForLabel: string = "Viewing Invoices For:";
    viewingProfileForLabel: string = "Viewing Profile For:";
    viewInvoiceLabel: string = "View Invoices";
    waitListAcceptScreenErrorTitle: string = "Error Finding Appointment";
    waitListAcceptScreenErrorText: string = "We could not find the requested wait list appointment. Please be sure you have the proper link and try again. If you continue to experience issues, please contact TimeTap Support.";
    waitListAcceptScreenHeader: string = "Claim Appointment";
    waitListAcceptScreenText: string = "If you would like to claim the appointment with %STAFF_FULLNAME% at %LOCATION_NAME% for %REASON% on %APPT_DATE_TIME%, please click the claim button.";
    waitListAcceptCourseRegistrationScreenText: string = "<p style='text-align: center;'><strong>If you would like to register for %REASON% with %STAFF_FULLNAME% at %LOCATION_NAME% for the dates listed below, please click the claim button.</strong></p><div style='text-align: center'>%REPEATING_APPT_DATE_LIST%</div>";
    waitlistAlreadyClaimed: string = "Appointment Already Claimed";
    waitlistAlreadyClaimedText: string = "<p style='text-align: center;'>We're sorry, but this appointment has already been claimed. We will let you know if another time becomes available.</p>";
    waitlistClaimApptBtn: string = "Claim Appointment";
    waitlistClaimListNoClaims: string = "There are no times being held for you on the wait list currently. Please check again later."
    waitlistClaimListErrorLoadingMsg: string = "Error loading wait list hold times. Please try again later.";
    waitlistClaimedApptCancelledTitle: string = "Appointment Cancelled";
    waitlistClaimedApptCancelledText: string = "This appointment with %STAFF_FULLNAME% at %LOCATION_NAME% for %REASON% on %APPT_DATE_TIME% has been cancelled.";
    // Below need to be translated still
    waitlistClaimedApptCancelledTextNoApptData: string = "This appointment has been cancelled and can no longer be claimed.";
    //Above need to be translated still
    waitListDateListClass: string = "<b>%APPT_DATE% at %APPT_CLIENT_START_TIME_SHORT%:</b> %STAFF_FULLNAME% at %LOCATION_NAME% for %REASON%";
    waitListDateListFirstAvail: string = "<b>First Available Date and Time:</b> %STAFF_FULLNAME% at %LOCATION_NAME% for %REASON%";
    waitListDateListService: string = "<b>%APPT_DATE% at %APPT_CLIENT_START_TIME_SHORT% - %APPT_CLIENT_END_TIME_SHORT%:</b> %STAFF_FULLNAME% at %LOCATION_NAME% for %REASON%";
    waitListDateListAllDay: string = "<b>%APPT_DATE% for All Day:</b> %STAFF_FULLNAME% at %LOCATION_NAME% for %REASON%";
    waitListFirstAvailableInTimeCart: string = "First available date & time";
    waitListFirstAvailableText: string = "Click here to get notified on first available opening";
    // Below need to be translated still
    waitListOutsideBookingWindowTitle: string = "Outside Booking Window";
    waitListOutsideBookingWindowMsg: string = "<p style='text-align: center;'>We're sorry, but you are outside the allowed booking window for this appointment to claim it.</p>";
    waitlistPastStartTimeTitle: string = "Appointment Time Has Passed";
    waitlistPastStartTimeText: string = "<p style='text-align: center;'>We're sorry, but this appointment time has already passed and is no longer available to claim.</p>";
    waitlistRegistrationBtnLabel: string = "Wait List";
    waitlistRegistrationTitle: string = "Wait List Registrations";
    noWaitlistRegistrationsMsg: string = "No wait list registrations found";
    waitlistRegistrationDetailDateLabel: string = "Date & Time:";
    waitlistRegistrationIdLabel: string = "Registration ID:";
    waitlistRegistrationDetailTitle: string = "Wait List Registration Detail";
    waitlistRegistrationDetailAvailableTimes: string = "Currently available appointment times for you to claim from this wait list registration:";
    waitlistRegistrationDetailCancelBtnLabel: string = "Cancel Registration";
    //Above need to be translated still
    waitlistRemoveError: string = "There was an error removing you from the wait list. Please try again. If you continue to experience issues, please contact support.";
    waitlistRemoveLoadError: string = "There was an error loading your wait list registration. Please check your link and try again.  If you continue to experience issues, please contact support."
    waitlistRemoveSuccessTitle: string = "Successfully Removed From Wait List";
    waitlistRemoveSuccessText: string = "<p style='text-align: center;'><strong>You have been successfully removed from the wait list for the following registration(s): </strong></p><div style='text-align: center'>%WAITLIST_DATE_LIST%</div><p style='text-align: center;'></p>";
    waitlistRemoveTitle: string = "Remove From Wait List";
    waitlistRemoveText: string = "<p style='text-align: center;'><strong>Please click the Remove button below if you no longer wish to receive wait list notifications for the following registration(s): </strong></p><div style='text-align: center'>%WAITLIST_DATE_LIST%</div><p style='text-align: center;'></p>";
    waitlistRemoveBtn: string = "Remove";
    waitListSuccessText: string = "<p style='text-align: center;'><strong>You have been added to the waiting list for the following dates and times:</strong></p><div style='text-align: center'>%WAITLIST_DATE_LIST%</div><p style='text-align: center;'>Should an appointment become available you will be contacted by email or phone.</p>";
    waitListText: string = "Click here to join the Wait List for another date/time.";
    waitListTextExitWaitListMode: string = "Pick from available dates and times";
    // Below need to be translated still
    weekLabel: string = "Week";
    weeksLabel: string = "Months";
    //Above need to be translated still
    welcomeText: string = '<p><span style="font-size: x-large;">Please click through the next few screens to select an appropriate time slot for your appointment.</span></p>\n<p><span style="font-size: x-large;"><br />Once your appointment is set, you will receive an email confirmation. You will also get a reminder email 24 hours before your appointment.<br />Thank You!<br /></span></p>';
    welcomeTitle: string = "Welcome";
    welcomePnlErrorLoading: string = "Scheduler failed to load. Please refresh your page and try again.";
    cancelReasonList: string = null;
    filterHint: string = null;
    infoText: string = null;
    locationGroupText: string = null;
    locationSuperGroupText: string = null;
    locationText: string = null;
    serviceGroupText: string = null;
    servicePnlCouponHint: string = null;
    serviceSuperGroupText: string = null;
    serviceText: string = null;
    smsHint: string = null;
    smsHintWaitList: string = null;
    staffGroupText: string = null;
    staffText: string = null;
    timeText: string = null;
    calHeaderSun: string = null;
    calHeaderMon: string = null;
    calHeaderTue: string = null;
    calHeaderWed: string = null;
    calHeaderThu: string = null;
    calHeaderFri: string = null;
    calHeaderSat: string = null;


    // Appt intent labels???
    "appointmentIntent.appointmentIntentId": string = "Intent ID";
    "appointmentIntent.externalReferenceNumber": string = "Reference Number";
    "appointmentIntent.description": string = "Description";
    "appointmentIntent.expirationDate": string = "Expiration Date";
    "appointmentIntent.startDate": string = "Start Date";
    "appointmentIntent.endDate": string = "End Date";

    constructor(private configListLabels: ConfigListMap,
                private titleCasePipe: TitleCasePipe,
                private cancelHrs: number,
                private rescheduleHrs: number,
                private waitListFirstAvailable: boolean) {}

    ngOnInit(): void {
        if(this.rescheduleHrs === 0){
            this.apptReschedulePastTimeframeMsg = "You are past the allowed timeframe to reschedule this appointment. Rescheduling is allowed up to the start time of the appointment."
        }
        if(this.cancelHrs === 0){
            this.apptCancelPastTimeframeMsg = "You are past the allowed timeframe to cancel this appointment. Cancellations are allowed up to the start time of the appointment."
        }
        //@ts-ignore

    }

}


