export class ParameterPassedField {
    tagName: string;
    value: any;
    constructor(tagName: string, value: any) {
        this.tagName = tagName;
        this.value = value;
        try {
            this.value = decodeURIComponent(value);
        } catch (e) {
            // could not decode
        }
    }
}
