import {CourseSet, MonthDataObj, RecurringClassSchedule} from "../../common-classes/app-objects.model";
import {single} from "rxjs/operators";
import {TimeService} from "./time.service";
import {TagsService} from "../../tags.service";
import * as momentTimezone from "moment-timezone/moment-timezone";
import {WaitList, WaitListDate, WaitListDateTime} from '../../../models/WaitList.model';
import {MilitaryTimeConversion} from '../../../pipes/militaryTimeConversion.pipe';
import {Reason} from '../../../models/Reason.model';
import {ClassSession} from '../../../models/ClassSession.model';
import {tz} from 'moment-timezone';

export class TimeZoneCode {
    timeZoneCode: string;
    timeZoneId?: number;
    timeZoneDisplayName?: string;
    constructor(timeZoneCode: string, timeZoneId?: number, timeZoneDisplayName?: string){
        this.timeZoneCode = timeZoneCode;
        this.timeZoneId = timeZoneId;
        if (timeZoneDisplayName === undefined)
            timeZoneDisplayName = timeZoneCode;
        this.timeZoneDisplayName = timeZoneDisplayName;
    }
}
export class TimeLock {
    auditReferralId: string;
    classScheduleId: number;
    staffStartDate: string | Date;
    staffEndDate: string | Date;
    staffStartTime: number;
    staffEndTime: number;
    units: TimeSlotUnit[];
    constructor() {
    }
}
export class TimeSlotUnit {
    singleTimeSlot: SingleTimeSlot;
    locationId: number;
    professionalId: number;
    reasonId: number;
    resourceId: number;
    tandemStaffIdList: number[];
    constructor(){}
    setProps(locationId: number,
             professionalId: number,
             reasonId: number,
             resourceId: number) {
        this.locationId = locationId;
        this.professionalId = professionalId;
        this.reasonId = reasonId;
        this.resourceId = resourceId;
        return this;
    }
}
export class SingleTimeSlot{
    //below properties added after went through and did V8 of time rendering on backend - 2/27-2/28/2020
    appointmentIntentIdList: number[];
    available: boolean;
    classScheduleId: number;
    classSession: ClassSession;
    clientEndDate: string | Date;
    clientEndTime: number;
    clientStartDate: string | Date;
    clientStartTime: number;
    clientTimeSlotId: string;
    courseSet: RecurringClassSchedule;
    currentAvailable: number;
    currentCapacity: number;
    date: string | Date; // for class session copying
    deleted: boolean;
    duplicate: boolean; // LHB 09/17/2020 TT-7003
    endDate: string;
    endTime: number;
    endTimeDate: Date;
    endTimeDateForTimeslotDisplay: Date;
    htmlId: string;
    isWaitListFirstAvail: boolean;
    locationId: number;
    maxCapacity: number;
    professionalId: number;
    recurringScheduleId: number;
    roomNumber: string;
    selected: boolean;
    showInTimeList: boolean;
    slotCount: number;
    slotMax: number;
    staffEndDate: string | Date;
    staffEndDateTimeUTC: number | Date;
    staffEndTime: number;
    staffStartDate: string | Date;
    staffStartDateTimeUTC: number | Date;
    staffStartTime: number;
    startTime: number;
    startTimeDate: Date;
    startTimeDateForTimeslotDisplay: Date;
    status: string;
    timeSlotId: string;
    timeString: string;
    units: TimeSlotUnit[];
    waitListMode: boolean;
    constructor(){}
    loadSingleTimeSlot(showInTimeList: boolean, selected: boolean, timeSlotId: string, units: TimeSlotUnit[], isWaitListFirstAvail: boolean,
                       waitListMode: boolean, stringStartAndEndDate: string, startTime?: number, endTime?: number) {
        this.available = true;
        this.clientEndTime = endTime || 2400;
        this.clientStartTime = startTime || 0;
        this.deleted = false;
        this.endTime = endTime || 2400;
        this.staffEndTime = endTime || 2400;
        this.staffStartTime = startTime || 0;
        this.startTime = startTime || 0;
        this.startTimeDate = new Date();
        this.endTimeDate = new Date();
        this.startTimeDateForTimeslotDisplay = new Date();
        this.endTimeDateForTimeslotDisplay = new Date();
        this.staffStartDateTimeUTC = new Date();
        this.staffEndDateTimeUTC = new Date();
        this.showInTimeList = showInTimeList;
        this.selected = selected;
        this.timeSlotId = timeSlotId;
        this.clientTimeSlotId = timeSlotId;
        this.htmlId = timeSlotId;
        this.units = units;
        this.isWaitListFirstAvail = isWaitListFirstAvail;
        this.waitListMode = waitListMode;
        this.staffStartDate = stringStartAndEndDate;
        this.staffEndDate = stringStartAndEndDate;
        this.clientStartDate = stringStartAndEndDate;
        this.clientEndDate = stringStartAndEndDate;
        return this;
    }
    createTimeSlotFromWaitListDateTime(waitListDateTime: WaitListDateTime, waitListDate: WaitListDate, waitList: WaitList, militaryTimeConversion: MilitaryTimeConversion, show24HrFormat: boolean, timeService: TimeService) {
        this.endTime = waitListDateTime.endTime;
        this.startTime = Number(waitListDateTime.startTime);
        this.clientStartTime = Number(waitListDateTime.startTime);
        const waitListDateString = timeService.getStringDateFromTime(waitListDate.date);
        this.staffStartDate = waitListDateString;
        this.staffEndDate = waitListDateString;
        this.staffStartDateTimeUTC = timeService.convertStringDateToObject(waitListDateString, Number(waitListDateTime.startTime));
        this.staffEndDateTimeUTC = timeService.convertStringDateToObject(waitListDateString, waitListDateTime.endTime);
        const timeSlotUnit: TimeSlotUnit = new TimeSlotUnit();
        if (waitList.staff)
            timeSlotUnit.professionalId = waitList.staff.professionalId;
        if (waitList.location)
            timeSlotUnit.locationId = waitList.location.locationId;
        timeSlotUnit.reasonId = waitList.reason.reasonId;
        this.timeString = militaryTimeConversion.transform(this.startTime, show24HrFormat) + ' - ' + militaryTimeConversion.transform(this.endTime, show24HrFormat) + ' (' + waitList.waitListTZ + ')';
        this.waitListMode = true;

    }
    convertClassSessionToTimeSlot(classSession: ClassSession, militaryTimeConversion: MilitaryTimeConversion, show24HrFormat: boolean, waitListMode: boolean, timeService: TimeService) {
        this.classScheduleId = classSession.classScheduleId;
        this.roomNumber = classSession.roomNumber;
        this.clientEndDate = classSession.date;
        this.clientEndTime = classSession.endTime;
        this.clientStartDate = classSession.date;
        this.clientStartTime = classSession.startTime;
        this.currentAvailable = classSession.currentAvailable;
        this.currentCapacity = classSession.currentCapacity;
        this.date = classSession.date;
        this.endTime = classSession.endTime;
        this.maxCapacity = classSession.maxCapacity;
        this.staffEndDate = classSession.date;
        this.staffEndTime = classSession.endTime;
        this.staffEndDateTimeUTC = classSession.endDateTimeUTC;
        this.staffStartDate = classSession.date;
        this.staffStartTime = classSession.startTime;
        this.staffStartDateTimeUTC = classSession.startDateTimeUTC;
        this.startTime = classSession.startTime;
        this.status = classSession.status;
        const timeSlotUnit: TimeSlotUnit = new TimeSlotUnit();
        if (classSession.staff)
            timeSlotUnit.professionalId = classSession.staff.professionalId;
        if (classSession.location)
            timeSlotUnit.locationId = classSession.location.locationId;
        if (classSession.reason)
            timeSlotUnit.reasonId = classSession.reason.reasonId;
        this.units = [timeSlotUnit];
        this.classSession = classSession;
        this.startTimeDate = classSession.startDateTimeUTC;
        this.endTimeDate = classSession.endDateTimeUTC;
        this.startTimeDateForTimeslotDisplay = timeService.getTimeFromUTCTimeInClientTimezone(this.staffStartDateTimeUTC, timeService.clientTimezone);
        this.endTimeDateForTimeslotDisplay = timeService.getTimeFromUTCTimeInClientTimezone(this.staffEndDateTimeUTC, timeService.clientTimezone);
        this.timeSlotId = String(classSession.classScheduleId);
        this.clientTimeSlotId = String(classSession.classScheduleId);
        this.htmlId = String(classSession.classScheduleId);
        this.waitListMode = waitListMode;
        this.timeString = militaryTimeConversion.transform(this.startTime, show24HrFormat) + ' - ' + militaryTimeConversion.transform(this.endTime, show24HrFormat) + ' (' + this.currentCapacity + '/' + this.maxCapacity + ' seats filled)';
    }
    createCustomTimeSlotFromWaitList(waitList: WaitList, militaryTimeConversion: MilitaryTimeConversion, show24HrFormat: boolean, timeService: TimeService) {
        let waitListDateString = waitList.startDate;
        if (waitListDateString instanceof Date)
            waitListDateString = timeService.getStringDateFromTime(waitList.startDate);
        this.staffStartDate = waitListDateString;
        this.staffEndDate = waitListDateString;
        this.staffStartDateTimeUTC = timeService.dateWithTimeZone(timeService.clientTimezone, waitListDateString, this.startTime);
        this.staffEndDateTimeUTC = timeService.dateWithTimeZone(timeService.clientTimezone, waitListDateString, this.endTime);
        const timeSlotUnit: TimeSlotUnit = new TimeSlotUnit();
        if (waitList.staff)
            timeSlotUnit.professionalId = waitList.staff.professionalId;
        if (waitList.location)
            timeSlotUnit.locationId = waitList.location.locationId;
        timeSlotUnit.reasonId = waitList.reason.reasonId;
        this.startTimeDateForTimeslotDisplay = timeService.getTimeFromUTCTimeInClientTimezone(this.staffStartDateTimeUTC, timeService.clientTimezone);
        this.endTimeDateForTimeslotDisplay = timeService.getTimeFromUTCTimeInClientTimezone(this.staffEndDateTimeUTC, timeService.clientTimezone);
        this.classScheduleId = null;
        this.endTimeDate = new Date(this.staffEndDateTimeUTC);
        this.clientEndTime = this.endTime;
        this.startTimeDate = new Date(this.staffStartDateTimeUTC);
        this.clientStartTime = this.startTime;
        this.currentCapacity = null;
        this.staffEndTime = this.endTime;
        this.clientTimeSlotId = waitListDateString;
        this.htmlId = this.clientTimeSlotId;
        this.maxCapacity = null;
        this.staffStartTime = this.startTime;
        this.timeSlotId = this.clientTimeSlotId;
        this.waitListMode = true;
        this.timeString = militaryTimeConversion.transform(this.startTime, show24HrFormat) + ' - ' + militaryTimeConversion.transform(this.endTime, show24HrFormat) + ' (' + waitList.waitListTZ + ')';
    }

    convertCourseSetToTimeSlot(courseSetObj: CourseSet, timeService: TimeService, waitListMode: boolean) {
        const courseSet: RecurringClassSchedule = courseSetObj.recurringClassSchedule;
        this.recurringScheduleId = courseSet.recurringScheduleId;
        this.roomNumber = null;
        this.clientEndDate = courseSet.endDate;
        this.clientStartDate = courseSet.startDate;
        this.currentCapacity = courseSet.currentCapacity;
        this.date = courseSet.startDate;
        this.endTime = courseSet.endTime;
        this.maxCapacity = courseSet.maxCapacity;
        this.staffEndDate = courseSet.endDate;
        this.staffStartDate = courseSet.startDate;
        this.status = courseSet.status;
        const timeSlotUnit: TimeSlotUnit = new TimeSlotUnit();
        timeSlotUnit.professionalId = courseSet.staff.professionalId;
        timeSlotUnit.locationId = courseSet.location.locationId;
        timeSlotUnit.reasonId = courseSet.reason.reasonId;
        if (courseSet.resource)
            timeSlotUnit.resourceId = courseSet.resource.resourceId;
        this.units = [timeSlotUnit];
        this.courseSet = courseSet;
        this.timeSlotId = String(courseSet.recurringScheduleId);
        this.waitListMode = waitListMode;
        let startDateDate = timeService.convertStringDateToObject(this.staffStartDate, 0);
        let endDateDate = timeService.convertStringDateToObject(this.staffEndDate, 0);
        this.timeString = courseSet.courseSetName + ', ' +  timeService.getShortDateFromDate(startDateDate) + ' - ' + timeService.getShortDateFromDate(endDateDate) + ' (' + this.currentCapacity + '/' + this.maxCapacity + ' seats filled)';
    }
}


export class RepeatingAppointmentDateList {
    staffStartDate: string;
    staffEndDate: string;
    clientStartDate: string;
    clientEndDate: string;
    staffStartDateTimeUTC: Date;
    staffEndDateTimeUTC: Date;
}

export class RepeatingAppointmentTimeSlots {
    startTime: number;
    endTime: number;
    clientStartTime: number;
    clientEndTime: number;
    calDateList: RepeatingAppointmentDateList[];
}
export class RepeatingAppointmentAvailability {
    staffId: number;
    locationId: number;
    calSlotList: RepeatingAppointmentTimeSlots[];
    constructor(staffId: number, locationId: number, calSlotList: RepeatingAppointmentTimeSlots[]) {
        this.staffId = staffId;
        this.locationId = locationId;
        this.calSlotList = calSlotList;
    }

    createRepeatingApptSet(timeService: TimeService, tagsService: TagsService,
                           allowTZChange: number, businessTimeZone: string, timeZoneForMoment: string,
                           showOnlyStartTime: boolean, timeFormat24Hrs: boolean){
        timeZoneForMoment = timeService.checkToAddMomentTimezone(allowTZChange, timeZoneForMoment);
        let propForStartTimeFormatting = 'startTime';
        let propForEndTimeFormatting = 'endTime';
        let propForStartDateFormatting = 'staffStartDate';
        let propForEndDateFormatting = 'staffEndDate';
        if(allowTZChange === 1){
            propForStartTimeFormatting = 'clientStartTime';
            propForEndTimeFormatting = 'clientEndTime';
            propForStartDateFormatting = 'clientStartDate';
            propForEndDateFormatting = 'clientEndDate';
        }
        let repeatingApptSets: RepeatingAppointmentSet[] = [];
        for(let j = 0, y = this.calSlotList.length; j < y; j++){
            let repeatingAppointmentSet: RepeatingAppointmentSet = new RepeatingAppointmentSet();
            repeatingAppointmentSet.staffId = this.staffId;
            repeatingAppointmentSet.locationId = this.locationId;
            repeatingAppointmentSet.startDate = this.calSlotList[j].calDateList[0].staffStartDateTimeUTC;
            repeatingAppointmentSet.endDate = this.calSlotList[j].calDateList[0].staffEndDateTimeUTC;
            repeatingAppointmentSet.startTimeDateForTimeslotDisplay = null;
            repeatingAppointmentSet.endTimeDateForTimeslotDisplay = null;
            repeatingAppointmentSet.dateTimeList = [];
            let days: string[] = [];
            let startTimes: number[] = [];
            for(let k = 0, z = this.calSlotList[j].calDateList.length; k < z; k++){
                let repeatingAppointmentDateSet: RepeatingAppointmentDateSet = new RepeatingAppointmentDateSet();
                if(this.calSlotList[j].calDateList[k].staffStartDateTimeUTC < repeatingAppointmentSet.startDate)
                    repeatingAppointmentSet.startDate = this.calSlotList[j].calDateList[k].staffStartDateTimeUTC;
                if(this.calSlotList[j].calDateList[k].staffEndDateTimeUTC > repeatingAppointmentSet.endDate)
                    repeatingAppointmentSet.endDate = this.calSlotList[j].calDateList[k].staffEndDateTimeUTC;

                repeatingAppointmentDateSet.clientStartTime = this.calSlotList[j].clientStartTime;
                repeatingAppointmentDateSet.clientEndTime = this.calSlotList[j].clientEndTime;
                repeatingAppointmentDateSet.startTime = this.calSlotList[j].startTime;
                repeatingAppointmentDateSet.endTime = this.calSlotList[j].endTime;
                repeatingAppointmentDateSet.clientStartDate = this.calSlotList[j].calDateList[k].clientStartDate;
                repeatingAppointmentDateSet.clientEndDate = this.calSlotList[j].calDateList[k].clientEndDate;
                repeatingAppointmentDateSet.staffStartDate = this.calSlotList[j].calDateList[k].staffStartDate;
                repeatingAppointmentDateSet.staffEndDate = this.calSlotList[j].calDateList[k].staffEndDate;
                repeatingAppointmentDateSet.staffStartDateTimeUTC = this.calSlotList[j].calDateList[k].staffStartDateTimeUTC;
                repeatingAppointmentDateSet.staffEndDateTimeUTC = this.calSlotList[j].calDateList[k].staffEndDateTimeUTC;
                repeatingAppointmentDateSet.startTimeDateForTimeslotDisplay = timeService.getTimeWithMoment(repeatingAppointmentDateSet[propForStartTimeFormatting], repeatingAppointmentDateSet[propForStartDateFormatting], allowTZChange, undefined);
                repeatingAppointmentDateSet.endTimeDateForTimeslotDisplay = timeService.getTimeWithMoment(repeatingAppointmentDateSet[propForEndTimeFormatting], repeatingAppointmentDateSet[propForEndDateFormatting], allowTZChange, undefined);
                if(repeatingAppointmentSet.startTimeDateForTimeslotDisplay === null || repeatingAppointmentDateSet.startTimeDateForTimeslotDisplay < repeatingAppointmentSet.startTimeDateForTimeslotDisplay)
                    repeatingAppointmentSet.startTimeDateForTimeslotDisplay = repeatingAppointmentDateSet.startTimeDateForTimeslotDisplay;
                if(repeatingAppointmentSet.endTimeDateForTimeslotDisplay === null || repeatingAppointmentDateSet.endTimeDateForTimeslotDisplay < repeatingAppointmentSet.endTimeDateForTimeslotDisplay)
                    repeatingAppointmentSet.endTimeDateForTimeslotDisplay = repeatingAppointmentDateSet.endTimeDateForTimeslotDisplay;
                repeatingAppointmentDateSet.startTimeDate = timeService.getTimeWithMoment(repeatingAppointmentDateSet.startTime, repeatingAppointmentDateSet.staffStartDate, 0, businessTimeZone);
                repeatingAppointmentDateSet.endTimeDate = timeService.getTimeWithMoment(repeatingAppointmentDateSet.endTime, repeatingAppointmentDateSet.staffEndDate, 0, businessTimeZone);
                let day = timeService.getPipeDayOfWeekFromDate(repeatingAppointmentDateSet.startTimeDateForTimeslotDisplay);
                if(days.indexOf(day)===-1)
                    days.push(day);
                if(startTimes.indexOf(repeatingAppointmentDateSet.clientStartTime) === -1)
                    startTimes.push(repeatingAppointmentDateSet.clientStartTime)
                repeatingAppointmentSet.dateTimeList.push(repeatingAppointmentDateSet);
            }
            let displayLabel = '';
            let daysString = '';
            if(days.length === 1){
                displayLabel = days[0];
                daysString = days[0];
            } else {
                for(let k = 0, z = days.length; k < z; k++){
                    if(k === 0){
                        displayLabel = days[k];
                    } else if(k < z - 1){ // is not the last item in the list
                        displayLabel = displayLabel + "," + days[k];
                    } else if(k === z - 1){ // is the last item in the list
                        displayLabel = displayLabel + " & " + days[k];
                    }
                    daysString = daysString + days[k];
                }
            }
            let startTimesString = ''
            for(let k = 0, z = startTimes.length; k < z; k++){
                startTimesString = startTimesString + startTimes[k];
            }
            let startFormat = 'HH:mm';
            let endFormat = 'HH:mm';
            if(!timeFormat24Hrs){
                startFormat = 'LT';
                endFormat = 'LT';
            }
            let startTimeDisplay = momentTimezone(repeatingAppointmentSet.startTimeDateForTimeslotDisplay).tz(timeZoneForMoment).format(startFormat);
            let endTimeDisplay = momentTimezone(repeatingAppointmentSet.endTimeDateForTimeslotDisplay).tz(timeZoneForMoment).format(endFormat);
            repeatingAppointmentSet.displayLabel = displayLabel + ": " + timeService.getShortDateFromDate(repeatingAppointmentSet.startDate) + " - " +  timeService.getShortDateFromDate(repeatingAppointmentSet.endDate) + " at " + startTimeDisplay;
            if(!showOnlyStartTime)
                repeatingAppointmentSet.displayLabel = repeatingAppointmentSet.displayLabel + " - " + endTimeDisplay;
            repeatingAppointmentSet.setId = daysString + "_" + startTimesString + "_" + timeService.getStringDateFromTime(repeatingAppointmentSet.startDate) + "_" + timeService.getStringDateFromTime(repeatingAppointmentSet.endDate)
            repeatingApptSets.push(repeatingAppointmentSet);
        }
        return repeatingApptSets;
    }
}

export class WeekDayData {
    dayOfWeekName: string;
    dayOfWeekInt: number;
    selected: boolean;
}

export class RepeatingAppointmentDateSet {
    startTime: number;
    endTime: number;
    clientStartTime: number;
    clientEndTime: number;
    staffStartDate: string;
    staffEndDate: string;
    clientStartDate: string;
    clientEndDate: string;
    staffStartDateTimeUTC: Date;
    staffEndDateTimeUTC: Date;
    startTimeDate: Date;
    endTimeDate: Date;
    startTimeDateForTimeslotDisplay: Date;
    endTimeDateForTimeslotDisplay: Date;

    convertRepeatingDateSetToSingleTimeSlot(repeatingAppointmentSet: RepeatingAppointmentSet, reason: Reason): SingleTimeSlot{
        let unit: TimeSlotUnit = new TimeSlotUnit().setProps(repeatingAppointmentSet.locationId, repeatingAppointmentSet.staffId, reason.reasonId, null);
        let singleTimeSlot: SingleTimeSlot = new SingleTimeSlot().loadSingleTimeSlot(true, true, repeatingAppointmentSet.setId, [unit], false, false, this.staffStartDate, this.startTime, this.endTime);
        singleTimeSlot.startTimeDate = this.startTimeDate;
        singleTimeSlot.endTimeDate = this.endTimeDate
        singleTimeSlot.startTimeDateForTimeslotDisplay = this.startTimeDateForTimeslotDisplay;
        singleTimeSlot.endTimeDateForTimeslotDisplay = this.endTimeDateForTimeslotDisplay;
        singleTimeSlot.staffStartDateTimeUTC = this.staffStartDateTimeUTC;
        singleTimeSlot.staffEndDateTimeUTC = this.staffEndDateTimeUTC;
        singleTimeSlot.clientStartDate = this.clientStartDate;
        singleTimeSlot.clientEndDate = this.clientEndDate;
        singleTimeSlot.endDate = this.staffEndDate;
        singleTimeSlot.locationId = repeatingAppointmentSet.locationId;
        singleTimeSlot.professionalId = repeatingAppointmentSet.staffId;
        return singleTimeSlot;
    }
}

export class RepeatingAppointmentSet {
    staffId: number;
    locationId: number;
    startDate: Date;
    endDate: Date;
    startTimeDateForTimeslotDisplay: Date;
    endTimeDateForTimeslotDisplay: Date;
    displayLabel: string;
    setId: string;
    dateTimeList: RepeatingAppointmentDateSet[];
    monthAvailability: MonthAvailability[];
    selectedMonthAvailability: MonthAvailability;
    minBookingDate: Date;
    maxBookingDate: Date;
}

export class SingleDateAvailability {
    date: Date;
    day: number;
    dayOfWeek: number;
    month: number;
    openSlotCount: number;
    year: number;
    constructor(day: number, month: number, year: number, date?: Date) {
        this.day = day;
        this.year = year;
        this.month = month;

        if(date)
            this.date = date;
        else
            this.date = new Date(year, month, day);
    }
}

export class MonthAvailability {
    businessId: number;
    currentMonth: number;
    endDate: SingleDateAvailability;
    month: number;
    openDays: SingleDateAvailability[];
    startDate: SingleDateAvailability;
    year: number;
    constructor(){}
}

export class MonthCalendarChange{
    monthDataObj: MonthDataObj;
    path: string;
}

export class SelectDayChange {
    singleDateAvailability: SingleDateAvailability;
    path: string;
}

