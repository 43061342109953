import {Injectable, Injector} from '@angular/core';
import {TimeZoneCode} from '../../time/time-objects.model';
import {map} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {ApiService} from '../../../api.service';
import {FormatFunctionsService} from '../../../format-functions.service';
import {TitleCasePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {CSFilterParameterBean} from '../../../common-classes/app-objects.model';
import {SchedulerPreferenceService} from '../../../scheduler-preference.service';
import {TimeService} from '../../time/time.service';
import {TagsService} from '../../../tags.service';
import {Subject} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  apiService: ApiService;
  formatFnsService: FormatFunctionsService;
  schedulerPreferenceService: SchedulerPreferenceService;
  timeService: TimeService;
  tagsService: TagsService;
  timezones: TimeZoneCode[];
  timezonesLoaded = new Subject();
  constructor(private titleCasePipe: TitleCasePipe, private injector: Injector, private http: HttpClient) {
    this.apiService = injector.get(ApiService);
    this.formatFnsService = injector.get(FormatFunctionsService);
    this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
    this.timeService = injector.get(TimeService);
    this.tagsService = injector.get(TagsService);
  }

  getTimezones(){
    return this.http
        .get<TimeZoneCode[]>(this.apiService.apiUrl + 'timezones', {observe: "body", responseType: 'json'})
        .pipe(
            map((responseData: TimeZoneCode[]) => {
              for(let i = 0, x = responseData.length; i < x; i++) {
                responseData[i].timeZoneDisplayName = responseData[i].timeZoneCode;
                if (responseData[i].timeZoneDisplayName.indexOf('_') !== -1)
                    responseData[i].timeZoneDisplayName = responseData[i].timeZoneDisplayName.replace(new RegExp('_', 'g'), ' ');
              }
              this.timezones = responseData;
              this.timezonesLoaded.next();
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  getTimezoneForDisplay(CSFilterObj: CSFilterParameterBean){//Implemented for TT-6286
    return this.http.post(this.apiService.apiUrl + 'timezoneForDisplay', CSFilterObj, {responseType: 'text'})
        .pipe(
            map(res => {
              return res;
            }),
            catchError(this.apiService.handleError)
        )
  }

    matchClientTZ(businessTimezone: string, csFilterParameterBean: CSFilterParameterBean, recallAfterMatchingMoment?: boolean): CSFilterParameterBean{
        let timezoneFound = false;
        if(this.schedulerPreferenceService.schedulerPreference.allowTZChange === 1 && !recallAfterMatchingMoment){
            csFilterParameterBean.clientTimeZone = sessionStorage.getItem("browserTimezone");
            this.timeService.setClientTimezone(csFilterParameterBean.clientTimeZone); // LHB 10/16/2020 TT-7087
        }
        for(let i = 0, x = this.timezones.length; i < x; i++){
            if(this.timezones[i].timeZoneCode === csFilterParameterBean.clientTimeZone){
                csFilterParameterBean.clientTimeZoneId = this.timezones[i].timeZoneId;
                if(this.schedulerPreferenceService.schedulerPreference.allowTZChange === 1) { //Check for TT-6286
                    timezoneFound = true;
                    // this.timeZoneNameForDisplay = this.timezones[i].timeZoneDisplayName;
                }
            }
            if(this.timezones[i].timeZoneCode === businessTimezone){

                csFilterParameterBean.businessTimeZoneId = this.timezones[i].timeZoneId;
                csFilterParameterBean.businessTimeZone = this.timezones[i].timeZoneCode;
                this.timeService.setBusinessTimezone(csFilterParameterBean.businessTimeZone);
                if(this.schedulerPreferenceService.schedulerPreference.allowTZChange !== 1){
                    timezoneFound = true;
                    // this.timeZoneNameForDisplay = this.timezones[i].timeZoneDisplayName;
                    csFilterParameterBean.clientTimeZone = csFilterParameterBean.businessTimeZone;
                    this.timeService.setClientTimezone(csFilterParameterBean.clientTimeZone); // LHB 10/16/2020 TT-7087
                }
            }

        }
        if(!timezoneFound){
            let timezoneFromMomentConversion = this.tagsService.convertSessionTZFromMoment(csFilterParameterBean.clientTimeZone);
            if(timezoneFromMomentConversion !== csFilterParameterBean.clientTimeZone){
                csFilterParameterBean.clientTimeZone = timezoneFromMomentConversion;
                this.timeService.setClientTimezone(csFilterParameterBean.clientTimeZone); // LHB 10/16/2020 TT-7087
                sessionStorage.setItem("timezone", timezoneFromMomentConversion);
            }
        }
        return csFilterParameterBean;
    }
}
