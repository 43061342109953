import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  MonthDataObj,
  MonthDay
} from "../../../common-classes/app-objects.model";
import {TimeService} from "../time.service";
import {MonthCalendarChange, SelectDayChange, MonthAvailability, SingleDateAvailability} from "../time-objects.model";
import {AvailabilityService} from '../../../booking-components/availability.service';
import * as moment from "moment";
import {preference} from "../../../common-classes/app-objects.model";


@Component({
  selector: 'time-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, OnChanges {
  @Output() onChangeMonths = new EventEmitter<MonthCalendarChange>();
  @Output() onSetDay = new EventEmitter<SelectDayChange>();
  @Input() monthAvailabilityLoaded: boolean;
  @Input() errorLoading: boolean;
  @Input() monthAvailability: MonthAvailability;
  @Input() selectedDate: SingleDateAvailability;
  @Input() maxBookingDate: Date = new Date();
  @Input() minBookingDate: Date = new Date();
  @Input() disableDateClicking: boolean;
  @Input() preference: preference;

  currentDate: Date = new Date();
  activeYear: number = this.currentDate.getFullYear();
  activeMonth: number = this.currentDate.getMonth() + 1;
  activeDay: number = this.currentDate.getDate();
  monthDays: MonthDay[] = [];
  monthsData: MonthDataObj[] = this.timeService.getMonthsData(this.activeYear);
  weekdays: any[] = this.timeService.getWeekdayData();

  currentMonthData: MonthDataObj = this.monthsData[this.activeMonth - 1];

  allowGetNextMonth: boolean = false;
  allowGetPrevMonth: boolean = false;

  constructor(private timeService: TimeService, private availabilityService: AvailabilityService) { }

  setDay(day: MonthDay){
    if(!this.disableDateClicking && this.monthAvailability) {
      for (let i = 0, x = this.monthAvailability.openDays.length; i < x; i++) {
        if (this.monthAvailability.openDays[i].day === day.dayNumInMonth &&
            (this.selectedDate.day !== this.monthAvailability.openDays[i].day ||
                this.selectedDate.month !== this.monthAvailability.openDays[i].month ||
                this.selectedDate.year !== this.monthAvailability.openDays[i].year)) {
          this.onSetDay.emit({singleDateAvailability: this.monthAvailability.openDays[i], path: 'selectedDay'});
        }
      }
    }
  }

  nextMonth(currentMonthData: MonthDataObj, path: string){
    let nextMonthData = this.timeService.moveToNextMonth(currentMonthData, this.activeYear, this.monthsData, this.activeMonth);
    this.activeYear = nextMonthData.activeYear;
    this.activeMonth = nextMonthData.activeMonth;
    this.monthsData = nextMonthData.monthsData;
    if(this.timeService.getAllowGetMonth(this.activeYear, this.activeMonth, this.maxBookingDate, this.minBookingDate)) {
      this.currentMonthData = this.monthsData[this.activeMonth - 1];
      let dataToPass = {monthDataObj: this.currentMonthData, path: path};
      this.onChangeMonths.emit(dataToPass);
    }
  }

  previousMonth(currentMonthData: MonthDataObj){
    if(currentMonthData.monthNumberIndex === 0){
      this.activeYear = this.activeYear - 1;
      this.monthsData =  this.timeService.getMonthsData(this.activeYear);
      this.activeMonth = 12;
    } else {
      this.activeMonth = this.activeMonth - 1;
    }
    this.currentMonthData = this.monthsData[this.activeMonth - 1];
    // this.getMonthDates('previousMonth');
    let dataToPass = {monthDataObj: this.currentMonthData, path: 'previousMonth'};
    this.onChangeMonths.emit(dataToPass);
  }

  checkIfNextAndPrevMonthsAllowed(){
    this.allowGetNextMonth = this.availabilityService.checkIfNextMonthAllowed(this.activeYear, this.activeMonth, this.currentMonthData, this.maxBookingDate, this.minBookingDate);
    this.allowGetPrevMonth = this.availabilityService.checkIfPrevMonthAllowed(this.activeYear, this.activeMonth, this.currentMonthData, this.maxBookingDate, this.minBookingDate);
    return this.allowGetNextMonth;
  }

  setSelectedMonthOnCalendar(){
    // console.log("START")
    // //@ts-ignore
    // console.log("currentMonthData " + JSON.stringify(this.currentMonthData, true, 2));
    // //@ts-ignore
    // console.log("this.monthAvailability " + JSON.stringify(this.monthAvailability, true, 2));
    // //@ts-ignore
    // console.log("this.activeMonth " + JSON.stringify(this.activeMonth, true, 2));
    if (this.monthAvailability.month !== this.activeMonth || this.monthAvailability.month !== this.currentMonthData.monthNumberFull) {
      this.activeMonth = this.monthAvailability.month;
      if(this.activeYear !== this.monthAvailability.year){
        this.activeYear = this.monthAvailability.year;
        this.monthsData = this.timeService.getMonthsData(this.activeYear);
      }
      // //@ts-ignore
      // console.log("this.activeMonth 2 " + JSON.stringify(this.activeMonth, true, 2));
      // //@ts-ignore
      // console.log("this.monthsData[this.activeMonth - 1] " + JSON.stringify(this.monthsData[this.activeMonth - 1], true, 2));
      this.currentMonthData = this.monthsData[this.activeMonth - 1];
    }
    // //@ts-ignore
    // console.log("currentMonthData 2 " + JSON.stringify(this.currentMonthData, true, 2));
    // console.log("END")
    this.monthDays = this.timeService.getDaysOfMonth(this.activeYear, this.activeMonth, this.monthAvailability);
    // //@ts-ignore
    // console.log("monthDays " + JSON.stringify(this.monthDays, true, 2));
    this.checkIfNextAndPrevMonthsAllowed();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    if(changes.hasOwnProperty('monthAvailability') && changes.monthAvailability.currentValue !== undefined && changes.monthAvailability.currentValue !== null){
      this.setSelectedMonthOnCalendar();
    } else {
      //if there's no availability for the next month, we still have to update the calendar object
      try {
        this.monthDays = this.timeService.getDaysOfMonth(this.activeYear, this.activeMonth, this.monthAvailability);
        this.checkIfNextAndPrevMonthsAllowed();
      } catch(e) {
        console.error(e);
      }
    }
    if(changes.hasOwnProperty('selectedDate') && changes.selectedDate.currentValue !== undefined && changes.selectedDate.currentValue !== null){
      this.activeDay = this.selectedDate.day;
    }
  }

  ngOnInit() {
    // no load functions
    this.setCalendarHeaders();
  }

  setCalendarHeaders() : void {
    let firstDayOfWeek = moment().localeData().firstDayOfWeek();
    let sunIndex = (0 - firstDayOfWeek + 7) % 7;
    let monIndex = (1 - firstDayOfWeek + 7) % 7;
    let tueIndex = (2 - firstDayOfWeek + 7) % 7;
    let wedIndex = (3 - firstDayOfWeek + 7) % 7;
    let thuIndex = (4 - firstDayOfWeek + 7) % 7;
    let friIndex = (5 - firstDayOfWeek + 7) % 7;
    let satIndex = (6 - firstDayOfWeek + 7) % 7;

    if (this.preference.labelMap.calHeaderSun){
      this.weekdays[sunIndex] = this.preference.labelMap.calHeaderSun;
    }
    if (this.preference.labelMap.calHeaderMon) {
      this.weekdays[monIndex] = this.preference.labelMap.calHeaderMon;
    }
    if (this.preference.labelMap.calHeaderTue){
      this.weekdays[tueIndex] = this.preference.labelMap.calHeaderTue;
    }
    if (this.preference.labelMap.calHeaderWed) {
      this.weekdays[wedIndex] = this.preference.labelMap.calHeaderWed;
    }
    if (this.preference.labelMap.calHeaderThu){
      this.weekdays[thuIndex] = this.preference.labelMap.calHeaderThu;
    }
    if (this.preference.labelMap.calHeaderFri) {
      this.weekdays[friIndex] = this.preference.labelMap.calHeaderFri;
    }
    if (this.preference.labelMap.calHeaderSat){
      this.weekdays[satIndex] = this.preference.labelMap.calHeaderSat;
    }

  }

}
