import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MainObjectTypes} from '../../../models/MainObjectTypes.model';
import {SingleTimeSlot} from '../../scheduler/time/time-objects.model';
import {Subscription} from 'rxjs/index';
import {FormControl} from '@angular/forms';
import {ValueOptionModel} from '../../../models/ValueOption.model';
import {AvailabilityService} from '../availability.service';
import {FormatFunctionsService} from '../../format-functions.service';
import {FormFunctionsService} from '../../form-functions.service';
import {ReasonTypes} from '../../../models/Reason.model';
import {CourseSet, CSFilterParameterBean} from '../../common-classes/app-objects.model';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {TimeService} from '../../scheduler/time/time.service';
import {ClassSession} from '../../../models/ClassSession.model';
import {DateAvailability} from '../../../models/DateAvailability.model';
import {ClassSessionService} from '../../class-session.service';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {MilitaryTimeConversion} from '../../../pipes/militaryTimeConversion.pipe';
import {Appointment} from '../../../models/Appointment.model';
import {WaitList} from '../../../models/WaitList.model';

@Component({
  selector: 'app-timeslot-multi-select',
  template: `
      <app-multi-checkbox-form-field *ngIf="!loading && timeSlots.length > 0" [passedControl]="timeSelectControl"
                                    (onValueChanges)="setFormControlValue($event)"
                                    [dropdownValues]="timeSlotDropdownValues"></app-multi-checkbox-form-field>
      <app-field-label *ngIf="!loading && timeSlots.length === 0" [required]="false" class="col-12" labelAlign="left"
                      label="No times on selected date"
                      [italics]="true"></app-field-label>
  `,
  styles: []
})
export class TimeslotMultiSelectComponent implements OnInit, OnDestroy {
  @Input() objectType: MainObjectTypes.APPT | MainObjectTypes.WAIT_LIST;
  @Input() editing: boolean = false;
  @Output() storeTimeSlots = new EventEmitter<SingleTimeSlot[]>();
  loading: boolean = true;
  timeSlots: SingleTimeSlot[] = [];
  selectedTimeSlots: SingleTimeSlot[];
  newDateSelected: Subscription;
  currentEventChanged: Subscription;
  timeSelectControl: FormControl = new FormControl();
  timeSlotDropdownValues: ValueOptionModel[] = [];
  timeRemovedSubscription: Subscription;
  constructor(private availabilityService: AvailabilityService, private formatFnsService: FormatFunctionsService, private formFunctionsService: FormFunctionsService,
              private schedulerService: SchedulerService, private timeService: TimeService, private classSessionService: ClassSessionService,
              private schedulerPreferenceService: SchedulerPreferenceService, private militaryTimeConversion: MilitaryTimeConversion) { }

  convertTimeSlotsIntoDropdownValues() {
    this.timeSlotDropdownValues = [];
    for (let i = 0, x = this.timeSlots.length; i < x; i++) {
      let valueOptionValue = this.timeSlots[i].staffStartDateTimeUTC;
      if (this.timeSlots[i].recurringScheduleId)
        valueOptionValue = this.timeSlots[i].recurringScheduleId;
      const valueOptionModel: ValueOptionModel = new ValueOptionModel(this.timeSlots[i].timeString, valueOptionValue, this.timeSlots[i].selected);
      this.timeSlotDropdownValues.push(valueOptionModel);
    }
  }

  setFormControlValue(changeEvent: {formControl: FormControl, event: KeyboardEvent | Event}) {
    const selectedTimeStrings = this.formFunctionsService.getCheckedValues(changeEvent.formControl.value);
    const selectedTimeNumbers = this.formatFnsService.turnStringArrayIntoNumberArray(selectedTimeStrings);
    for (let i = 0, x = this.timeSlots.length; i < x; i++)
      this.timeSlots[i].selected = false;
    for (let i = 0, x = this.timeSlots.length; i < x; i++)
      if ((!this.timeSlots[i].recurringScheduleId && selectedTimeNumbers.indexOf(this.timeSlots[i].staffStartDateTimeUTC as number) !== -1) ||
          (this.timeSlots[i].recurringScheduleId && selectedTimeNumbers.indexOf(this.timeSlots[i].recurringScheduleId) !== -1))
        this.timeSlots[i].selected = true;
    this.storeTimeSlots.emit(this.timeSlots);
  }

  getSelectedTimeSlots() {
    this.selectedTimeSlots = this.availabilityService.createTimeSlotsFromWaitlist();
    if (this.selectedTimeSlots.length === 0)
      for (let i = 0, x = this.timeSlots.length; i < x; i++)
        this.timeSlots[i].selected = false;
    let formControlValue = null;
    if (this.timeSlots.length > 0 && this.selectedTimeSlots.length > 0) {
      for (let i = 0, x = this.timeSlots.length; i < x; i++)
        this.timeSlots[i].selected = false;
      for (let i = 0, x = this.timeSlots.length; i < x; i++)
        for (let j = 0, y = this.selectedTimeSlots.length; j < y; j++) {
          if (this.timeSlots[i].staffStartDateTimeUTC === this.selectedTimeSlots[j].staffStartDateTimeUTC ||
              (this.timeSlots[i].staffStartDate === this.selectedTimeSlots[j].staffStartDate && this.timeSlots[i].staffEndDate === this.selectedTimeSlots[j].staffEndDate &&
                  this.timeSlots[i].startTime === this.selectedTimeSlots[j].startTime && this.timeSlots[i].endTime === this.selectedTimeSlots[j].endTime) ||
              (this.timeSlots[i].recurringScheduleId && this.selectedTimeSlots[j].recurringScheduleId && this.timeSlots[i].recurringScheduleId === this.selectedTimeSlots[j].recurringScheduleId)) {
            this.timeSlots[i].selected = true;
            let appendValue = this.timeSlots[i].staffStartDateTimeUTC;
            if (this.timeSlots[i].recurringScheduleId)
              appendValue = this.timeSlots[i].recurringScheduleId;
            if (!formControlValue)
              formControlValue = String(appendValue);
            else
              formControlValue = formControlValue + ',' + String(appendValue);
          }
        }
    }
    if (formControlValue)
      this.timeSelectControl.patchValue(formControlValue);
    this.convertTimeSlotsIntoDropdownValues();
  }

  getTimeSlots() {
    this.timeSlots = [];
    this.loading = true;
    if (this.availabilityService.currentEvent.reason)
      switch (this.availabilityService.currentEvent.reason.reasonType) {
        case ReasonTypes.SERVICE:
          this.availabilityService.getTimeSlots().subscribe((calTimeSlot: DateAvailability) => {
            this.timeSlots = calTimeSlot.timeSlots;
            this.getSelectedTimeSlots();
            this.loading = false;
          });
          break;
        case ReasonTypes.CLASS:
          const csFilterObj: CSFilterParameterBean = this.schedulerService.newCSFilterObj();
          if (this.availabilityService.currentEvent){
            if (this.availabilityService.currentEvent.location)
              csFilterObj.locationIdList = [this.availabilityService.currentEvent.location.locationId];
            if (this.availabilityService.currentEvent.staff)
              csFilterObj.staffIdList = [this.availabilityService.currentEvent.staff.professionalId];
            csFilterObj.reasonIdList = [this.availabilityService.currentEvent.reason.reasonId];
          }
          const month = this.timeService.getPipeMonthFromDate(this.availabilityService.currentEvent.startDate);
          const year = this.timeService.getPipeYearFromDate(this.availabilityService.currentEvent.startDate);
          const day = this.timeService.getPipeDayFromDate(this.availabilityService.currentEvent.startDate);
          let waitListMode = false;
          if (this.objectType === MainObjectTypes.WAIT_LIST)
            waitListMode = true;
          this.availabilityService.getDateAvailabilityClasses(csFilterObj, year, month, day).subscribe((classSessions: ClassSession[]) => {
            let timeSlots: SingleTimeSlot[] = [];
            for (let i = 0, x = classSessions.length; i < x; i++) {
              classSessions[i] = this.classSessionService.configureListElement(classSessions[i]);
              const timeSlot: SingleTimeSlot = new SingleTimeSlot();
              timeSlot.convertClassSessionToTimeSlot(classSessions[i], this.militaryTimeConversion, this.schedulerPreferenceService.schedulerPreference.timeFormat24Hrs, this.availabilityService.waitListMode, this.timeService);
              timeSlots.push(timeSlot);
            }
            timeSlots = this.availabilityService.convertTimeSlots(timeSlots);
            this.timeSlots = timeSlots;
            this.getSelectedTimeSlots();
            this.loading = false;
          });
          break;
        case ReasonTypes.COURSE:
          const csFilterObjBean: CSFilterParameterBean = this.schedulerService.newCSFilterObj();
          this.availabilityService.getCourseSets(csFilterObjBean).subscribe((courseSets: CourseSet[]) => {
            this.timeSlots = this.availabilityService.convertCourseSetsIntoTimeSlots(courseSets);
            this.getSelectedTimeSlots();
            this.loading = false;
          });
          break;
      }
  }

  checkForTimeSlotsCall() {
    if (this.editing)
      this.getTimeSlots();
  }

  ngOnInit(): void {
    this.newDateSelected = this.availabilityService.currentEventDateChanged.subscribe(() => {
      this.checkForTimeSlotsCall();
    });
    this.currentEventChanged = this.availabilityService.currentEventChanged.subscribe((eventData: {newEventData: Appointment | WaitList, oldEventData: Appointment | WaitList}) => {
      const newAppt = eventData.newEventData;
      const oldAppt = eventData.oldEventData;
      const locationChanged = oldAppt.location && newAppt.location && oldAppt.location.locationId !== newAppt.location.locationId;
      const staffChanged = oldAppt.staff && newAppt.staff && oldAppt.staff.professionalId !== newAppt.staff.professionalId;
      const reasonChanged = oldAppt.reason && newAppt.reason && oldAppt.reason.reasonId !== newAppt.reason.reasonId;
      if (locationChanged || staffChanged || reasonChanged)
        this.checkForTimeSlotsCall();
    });
    this.timeRemovedSubscription = this.availabilityService.timeRemoved.subscribe(() => {
      this.getSelectedTimeSlots();
    });
    if (this.availabilityService.currentEvent)
      this.checkForTimeSlotsCall();
  }

  changeTimeZoneForTimeSlots() {
    this.timeSlots = this.availabilityService.convertTimeSlots(this.timeSlots);
    this.getSelectedTimeSlots();
  }

  ngOnDestroy(): void {
    this.newDateSelected.unsubscribe();
    this.currentEventChanged.unsubscribe();
    this.timeRemovedSubscription.unsubscribe();
  }

}
