import {Injectable, Injector} from '@angular/core';
import {miniWebsite, schedulerLink} from './common-classes/app-objects.model';
import {LabelMapService} from './common-classes/label-map.service';
import {SchedulerPreferenceService} from './scheduler-preference.service';
import {MiniWebsiteService} from './mini-website.service';
import {TagsService} from './tags.service';
import {Business} from '../models/Business.model';
import {ResponsiveService} from './responsive.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulerLinkService {
  schedulerLink: schedulerLink;
  labelMapService: LabelMapService;
  schedulerPreferenceService: SchedulerPreferenceService;
  miniWebsiteService: MiniWebsiteService;
  tagsService: TagsService;
  responsiveService: ResponsiveService;
  constructor(private injector: Injector) {
    this.labelMapService = injector.get(LabelMapService);
    this.schedulerPreferenceService = injector.get(SchedulerPreferenceService)
    this.miniWebsiteService = injector.get(MiniWebsiteService);
    this.tagsService = injector.get(TagsService);
    this.responsiveService = injector.get(ResponsiveService);
  }

  setSchedulerLink(schedulerLink: schedulerLink, business: Business, miniWebsite: miniWebsite) {
    this.schedulerLink = schedulerLink;
    this.schedulerLink.labelMap = this.labelMapService.reconcileSchedulerLinkPropertiesToLabelMap(this.schedulerLink, this.schedulerLink.labelMap, this.schedulerPreferenceService.schedulerPreference.labelMap);
    //On mobile when the default scheduler loads and the text reads "Click through the screens on the right" want to make sure that that instead reads "screens above" since it shows beneath scheduler
    if (this.responsiveService.onMobileViewPort && this.schedulerLink.labelMap && this.schedulerLink.labelMap.businessInfoText && this.schedulerLink.labelMap.businessInfoText.toLowerCase().indexOf('screens on the right') !== -1)
      this.schedulerLink.labelMap.businessInfoText = this.schedulerLink.labelMap.businessInfoText.replace(new RegExp('screens on the right', 'gi'), 'screens above');
    if (this.schedulerLink.labelMap && this.schedulerPreferenceService.schedulerPreference) // LHB 1/22/2021 TT-7301
      for (let key in this.schedulerLink.labelMap) {
        for (let i = 0, x = this.tagsService.businessTagsArray.length; i < x; i++) {
          if (this.schedulerLink.labelMap[key] && typeof this.schedulerLink.labelMap[key] === 'string' && this.schedulerLink.labelMap[key].indexOf(this.tagsService.businessTagsArray[i]))
            this.schedulerLink.labelMap[key] = this.tagsService.assignObjectToTags(business, this.schedulerLink.labelMap[key], this.tagsService.businessTagsArray);
        }
        if (this.schedulerPreferenceService.schedulerPreference.hasOwnProperty(key))
          this.schedulerPreferenceService.schedulerPreference[key] = this.schedulerLink.labelMap[key];
      }
    if (this.schedulerLink.labelMap && this.miniWebsiteService.miniWebsite) // LHB 12/29/2020 TT-5960
      for (let key in this.schedulerLink.labelMap)
        if (miniWebsite[key] !== undefined)
          miniWebsite[key] = this.schedulerLink.labelMap[key];
    //LOOK AT REMAINING PROPERTIES ON SCHEDULERLINK THAT ARE NOT IN THE LABELMAP AND WRITE THOSE TO CORRESPONDING PREFERENCE PROPERTIES SO THAT SETUP ON SCHEDULER LINK TAKES PRECEDENCE OVER DEFAULT PREFERENCE
    //DO THE SAME THING FOR MINIWEBSITE DESIGN WHERE THE LOGOURL PROPERTY LIVES
    for (let key in this.schedulerLink) {
      if (this.schedulerPreferenceService.schedulerPreference.hasOwnProperty(key) && this.schedulerLink[key] !== null) {
        if (key === 'booleanMap' || key === 'numberMap') {
          for (const prop in this.schedulerLink[key]) {
            this.schedulerPreferenceService.schedulerPreference[key][prop] = this.schedulerLink[key][prop];
            if (this.schedulerPreferenceService.schedulerPreference.hasOwnProperty(prop) && this.schedulerLink[key][prop] !== null)
              this.schedulerPreferenceService.schedulerPreference[prop] = this.schedulerLink[key][prop];
          }
        } else
          this.schedulerPreferenceService.schedulerPreference[key] = this.schedulerLink[key];
      } else if (!this.schedulerPreferenceService.schedulerPreference.hasOwnProperty(key) && this.schedulerLink[key] !== null) {//there is a property on schedulerLink that is not on preference and should just be added to preference (like skipWelcome)
        this.schedulerPreferenceService.schedulerPreference[key] = this.schedulerLink[key];
      }
      if (miniWebsite !== null && miniWebsite.hasOwnProperty(key) && this.schedulerLink[key] !== null) {
        if (key === 'landingPageStylesJSON')
          miniWebsite.schedulerLinkLandingPageStylesJson = this.schedulerLink[key]
        else
          miniWebsite[key] = this.schedulerLink[key];
      }

    }

    // LHB 10/16/2020 TT-7082 loop through scheduler link's boolean map and override props on miniwebsite (later
    if (this.schedulerLink.booleanMap !== null)
      for (let prop in this.schedulerLink.booleanMap) {
        if (this.miniWebsiteService.miniWebsite && this.miniWebsiteService.miniWebsite.hasOwnProperty(prop))
          this.miniWebsiteService.miniWebsite[prop] = this.schedulerLink.booleanMap[prop];
        if (this.schedulerPreferenceService.schedulerPreference && this.schedulerPreferenceService.schedulerPreference.hasOwnProperty(prop))
          this.schedulerPreferenceService.schedulerPreference[prop] = this.schedulerLink.booleanMap[prop];
        if (this.schedulerPreferenceService.schedulerPreference && this.schedulerPreferenceService.schedulerPreference.booleanMap && this.schedulerPreferenceService.schedulerPreference.booleanMap.hasOwnProperty(prop))
          this.schedulerPreferenceService.schedulerPreference.booleanMap[prop] = this.schedulerLink.booleanMap[prop];
      }
    if (this.schedulerLink.numberMap !== null)
      for (let prop in this.schedulerLink.numberMap)
        if (this.schedulerPreferenceService.schedulerPreference && this.schedulerPreferenceService.schedulerPreference.hasOwnProperty(prop))
          this.schedulerPreferenceService.schedulerPreference[prop] = this.schedulerLink.numberMap[prop];
  }
}
