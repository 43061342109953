import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {loginRegisterPopUpObject, miniWebsite, preference} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {AuthService} from "../../auth/auth.service";
import {Subscription} from "rxjs";
import {ResponsiveService} from "../../responsive.service";
import {MiniWebsiteDisplayConfig} from "../../common-classes/default-styles.model";
import {StoreService} from "../../store/store.service";
import {ActivatedRoute, Router} from '@angular/router';
import {JoinWaitListService} from '../../waitlist/join-wait-list/join-wait-list.service';

@Component({
  selector: 'header-sidebar',
  templateUrl: './header-sidebar.component.html'
})
export class HeaderSidebarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() preference: preference;
  @Input() miniWebsite: miniWebsite;
  authSubscription: Subscription;
  displayConfig: MiniWebsiteDisplayConfig;
  showLoginBtn: boolean;
  showRegisterBtn: boolean;
  showLoginRegisterBtns: boolean;
  isAuthenticated: boolean;
  cartSubscription: Subscription;
  cartQuantity: number = 0;

  constructor(private businessService: BusinessService, private authService: AuthService, private responsiveService: ResponsiveService, private cdRef: ChangeDetectorRef,
              private storeService: StoreService, private router: Router, private route: ActivatedRoute, private joinWaitListService: JoinWaitListService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.preference !== undefined && changes.preference.currentValue !== undefined && changes.preference.previousValue === undefined){
      let showBtns = this.responsiveService.showLoginRegisterBtnLogic(this.preference);
      this.showLoginBtn = showBtns.showLoginBtn;
      this.showRegisterBtn = showBtns.showRegisterBtn;
      if(this.showLoginBtn || this.showRegisterBtn){
        this.showLoginRegisterBtns = true;
        this.isAuthenticated = this.authService.isAuth();
      }
    }
    if(changes.miniWebsite !== undefined && changes.miniWebsite.currentValue !== undefined && changes.miniWebsite.previousValue === undefined){
      this.displayConfig = this.miniWebsite.displayConfig;
    }
  }

  goToLink(link: string){
    if (link === 'waitList') {
      if (this.router.url.indexOf('/waitlist/join') !== -1)
        this.joinWaitListService.joinWaitListHeaderClicked.next();
      else
        this.router.navigate(['/waitlist/join'], {relativeTo: this.route.root});
    }
  }

  ngOnInit() {

    this.authSubscription = this.authService.authChange.subscribe(authStatus => {
      this.isAuthenticated = authStatus;
    });
    this.cartSubscription = this.storeService.cartChange.subscribe((cartChange: any) => {
      this.cartQuantity = this.storeService.updateCart();
      this.cdRef.detectChanges();
    });
  }

  login(){
    let loginTriggeredData: loginRegisterPopUpObject = new loginRegisterPopUpObject();
    loginTriggeredData.loadMyAccount = true;
    this.authService.loginCalled.next(loginTriggeredData);
  }
  register(resetPwd: boolean){
    this.authService.registerCalled.next(resetPwd);
  }
  logout(){
    this.authService.logoutCalled.next();
  }
  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    this.cartSubscription.unsubscribe();
  }
}
