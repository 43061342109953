export class TableColumnDataModel {
    name: string;
    title: string;
    filter: string;
    filterFormats: TableColumnDataFilterFormat[];
    tag: string;
    nestedProp: string;
    selected: boolean;
    sticky: boolean;
    noDateString: string;
    constructor(name: string, title: string, filter?: string, filterFormats?: TableColumnDataFilterFormat[], selected?: boolean, nestedProp?: string, sticky?: boolean, tag?: string, noDateString?: string) {
        this.name = name;
        this.title = title;
        this.filter = filter;
        this.filterFormats = filterFormats;
        this.selected = selected;
        if(selected === undefined)
            this.selected = true;
        this.nestedProp = nestedProp;
        this.sticky = sticky;
        this.tag = tag;
        this.noDateString = noDateString;
        if(filter !== undefined && filterFormats === undefined){
            switch (filter) {
                case 'date':
                    let filterFormat = new TableColumnDataFilterFormat('short');
                    this.filterFormats = [filterFormat];
                    break;
            }
        }

    }
}

export class TableColumnDataFilterFormat {
    format: string;
    concatenator: boolean = false;
    useMoment: boolean = false;
    useUTC: boolean;
    constructor(format: string, concatenator?: boolean, useMoment?: boolean, useUTC?: boolean) {
        this.format = format;
        if(concatenator !== undefined)
            this.concatenator = concatenator;
        if(useMoment !== undefined)
            this.useMoment = useMoment;
        if (useUTC !== undefined)
            this.useUTC = useUTC;
    }
}
