import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BusinessService} from "../../business.service";
import {Router} from "@angular/router";
import {TagsService} from "../../tags.service";
import {Client, Invoice, preference} from "../../common-classes/app-objects.model";
import {ClientAccountService} from "../../client-account/client-account.service";
import {CreditCard} from "../../client-account/view-invoices/payment-portals/payments.model";
import {StoredCardInfo} from "../../client-account/client-profile/client-profile-container/client-profile-container.component";

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './manage-credit-card.component.html',
  styles: []
})
export class ManageCreditCardComponent implements OnInit {
  preference: preference;
  client: Client;
  cardAdded: boolean;
  card: CreditCard;
  path: string;
  title: string;
  deleteConfirmationDescription: string;
  addCardDescription: string = "";
  deletingCard: boolean = false;
  errorDeletingCard: boolean = false;
  errorMessage: string = '';
  constructor(@Inject(MAT_DIALOG_DATA) private passedData: {client: Client, preference: preference, path: string, card: CreditCard}, private clientAccountService: ClientAccountService,
              private router: Router, public dialogRef: MatDialogRef<ManageCreditCardComponent>, private tagsService: TagsService) {
    dialogRef.backdropClick().subscribe(() =>{
      this.dialogRef.close();
    })
  }

  ngOnInit() {
    this.preference = this.passedData.preference;
    this.client = this.passedData.client;
    this.path = this.passedData.path;
    if(this.path === 'addCardConfirmBooking'){
      this.path = 'addCard';
      this.addCardDescription = this.preference.labelMap.clientPaymentsAddCardToConfirmAppointmentDescription;
    }
    if(this.path === 'deleteCard'){
      this.card = this.passedData.card;
      this.deleteConfirmationDescription = this.tagsService.assignObjectToTags(this.card, this.preference.labelMap.clientPaymentsDeleteDescription, this.tagsService.creditCardFieldTags);
    }
  }

  hidePaymentForm(invoice: Invoice) {
    this.cardAdded = true;
  }

  confirmDelete(){
    let cardId: string;
    let cardType: string;
    if(this.card.stripeInfo){
      cardId = this.card.stripeInfo.id;
      cardType = "STRIPE";
    }
    if(this.card.squareInfo !== undefined){
      cardId = this.card.squareInfo.id;
      cardType = "SQUARE";
    }
    this.deletingCard = true;
    this.errorDeletingCard = false;
    this.clientAccountService.deleteCard(this.client.clientId, cardId, cardType).subscribe((storedCardInfo: StoredCardInfo) => {
      this.deletingCard = false;
      this.client = this.clientAccountService.storeCardInfoOnClient(this.client, storedCardInfo);
      this.dialogRef.close(storedCardInfo);
    }, error => {
      this.deletingCard = false;
      this.errorDeletingCard = true;
      this.errorMessage = this.preference.labelMap.clientPaymentsDeleteErrorMsg;
    })
  }

}
