import {Component, Inject, OnInit} from '@angular/core';
import {preference} from '../../common-classes/app-objects.model';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {BusinessService} from '../../business.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.css']
})
export class CookieBannerComponent implements OnInit {
  // LHB 11/21/2021 TT-7854 RE: https://medium.com/swlh/angular-include-google-tag-manager-with-analytics-bd24fb74b4fc
  // did not implement yet but leaving in anticipating we will need to implement soon
  showBanner: boolean;
  preference: preference;
  cookieName: string = 'timetapCookieConsent';
  constructor(private schedulerPreferenceService: SchedulerPreferenceService, private businessService: BusinessService, @Inject('document') private document,
              @Inject('window') private window) {
    this.preference = schedulerPreferenceService.schedulerPreference;
  }

  agreeToShare() {
    this.setCookie(this.cookieName, 'allow', 365);
    this.window.location.reload();
  }

  private getCookie(cname: string) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  private setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    this.document.cookie = cname + '=' + cvalue + ';' + expires;
  }

  ngOnInit() {
    let googleTagManagerCode = sessionStorage.getItem("googleTagManagerCode");
    if (googleTagManagerCode && googleTagManagerCode !== '') {
      this.cookieName = 'timetapCookieConsentBusinessId' + this.businessService.business.businessId;
      const consent = this.getCookie(this.cookieName);
      if (consent === 'allow') {
        this.showBanner = false;
      } else {
        this.showBanner = true;
      }
    }

  }

}
