import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TimeSlotDisplayComponent} from './time-slot-display.component';
import {MaterialModule} from '../../../material.module';
import {UIElementsModule} from '../../../ui-elements/ui-elements.module';



@NgModule({
  declarations: [TimeSlotDisplayComponent],
  imports: [
    CommonModule,
    MaterialModule,
    UIElementsModule
  ],
  exports: [TimeSlotDisplayComponent]
})
export class TimeSlotDisplayModule { }
