import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-view-appointments',
  templateUrl: './view-appointments.component.html'
})
export class ViewAppointmentsComponent implements OnInit {
    constructor(){}
    ngOnInit(){

    }


}
