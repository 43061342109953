import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StyleSheetService} from "../styleSheetService";
import * as $ from 'jquery';

@Directive({
  selector: '[backgroundStyling]'
})
export class BackgroundStylingDirective implements OnInit, OnChanges{
  @Input() containerId;
  @Input() containerClass;
  /**
   * This is for if they haven't set one specifically on the stylesheet and a change isn't causing it to revert (like if they don't have selected day on calendar defined and they
   * select a new day then the newly selected day is picking up the default dark teal color)
   * Also being used to set values to transparent when need to (like on view appointments & invoice list tables)
   */
  @Input() defaultColor;
  /**
   * This is for the mat-sidenav which I can't place the directive directly on. Instead putting it on the header-sidebar and setting the parent element on this property and then
   * building a catch for that here
   */
  @Input() parentElement: string;
  backgroundColor: string;
  selector: string;
  constructor(private stylesheetService: StyleSheetService, private elementRef: ElementRef) { }

  matchAndApplyStyles() {
    let styleMap = this.stylesheetService.mwStyleMap;
    let tagName = this.elementRef.nativeElement.tagName.toLowerCase();
    let propToMatch = null;
    if(this.containerClass !== undefined){
      propToMatch = this.containerClass;
    }
    if(this.containerId !== undefined){
      propToMatch = this.containerId;
    }
    let matchFound = false;
    if(propToMatch !== null){
      for(let prop in styleMap){
        if(prop === propToMatch && styleMap[prop].backgroundColor !== undefined){
          matchFound = true;
          this.backgroundColor = styleMap[prop].backgroundColor;
        }
      }
    }
    if(!matchFound){
      if(this.defaultColor === undefined){
        for(let prop in styleMap){
          if(prop === tagName && styleMap[prop].backgroundColor !== undefined){
            matchFound = true;
            this.backgroundColor = styleMap[prop].backgroundColor;
          }
        }
      } else {
        matchFound = true;
        this.backgroundColor = this.defaultColor;
      }
    }

    if(matchFound){
      if(this.parentElement !== undefined){//need to target parent element container passed in to set the background color on
        $(this.parentElement).css({backgroundColor: this.backgroundColor});
      } else {
        this.elementRef.nativeElement.style.backgroundColor = this.backgroundColor;
        if(this.backgroundColor === 'transparent'){
          this.elementRef.nativeElement.style.boxShadow = 'none';
        }
      }

    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.containerId !== undefined && changes.containerId !== null && changes.containerId.previousValue !== undefined && changes.containerId.previousValue !== null){
      if(changes.containerId.previousValue !== changes.containerId.currentValue){
        this.matchAndApplyStyles();
      }
    }
    if(changes.containerClass !== undefined && changes.containerClass !== null && changes.containerClass.previousValue !== undefined && changes.containerClass.previousValue !== null){
      if(changes.containerClass.previousValue !== changes.containerClass.currentValue){
        this.matchAndApplyStyles();
      }
    }
  }

  ngOnInit(): void {
    this.matchAndApplyStyles();
  }

}
