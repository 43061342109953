import {NgModule} from "@angular/core";
import {SafeHtml} from "./safehtml.pipe";
import {LocaleCurrency} from "./localecurrency.pipe";
import {MilitaryTimeConversion} from './militaryTimeConversion.pipe';

@NgModule({
    declarations: [SafeHtml, LocaleCurrency, MilitaryTimeConversion],
    providers: [LocaleCurrency, MilitaryTimeConversion],
    exports: [SafeHtml, LocaleCurrency, MilitaryTimeConversion]
})
export class PipesModule{}
