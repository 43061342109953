import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {WaitlistComponent} from './waitlist.component';
import {ClientWaitlistTimesComponent} from './client-waitlist-times/client-waitlist-times.component';
import {ClaimWaitlistComponent} from './confirm-waitlist/claim-waitlist.component';
import {RemoveWaitlistComponent} from './remove-waitlist/remove-waitlist.component';
import {JoinWaitListComponent} from './join-wait-list/join-wait-list.component';

const routes: Routes = [
  { path: 'waitlist', component: WaitlistComponent,
    children: [
      { path: "", component: WaitlistComponent },
      { path: "claimTimes/:clientHash", component: ClientWaitlistTimesComponent },
      { path: "claimTimes/:clientHash/:waitlistInviteId", component: ClaimWaitlistComponent },
      { path: "claim/:waitlistInviteId", component: ClaimWaitlistComponent },
      { path: "remove/:waitlistHash", component: RemoveWaitlistComponent },
      { path: "join", component: JoinWaitListComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WaitListRoutingModule{}
