import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TimeService} from '../../scheduler/time/time.service';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css', '../../input-group.component.css']
})
export class TimePickerComponent implements OnInit, OnChanges {
  @Input() time: number;
  @Input() editing: boolean;
  @Input() ariaLabel: string = 'Time';
  @Output() timeChanged = new EventEmitter<number>()
  meridian = true;
  hstep = 1;
  mstep = 5;
  displayTime: string;
  timepickerTime: Date;
  showTimepickerDropdown: boolean;
  configuringTimePicker: boolean;
  constructor(private timeService: TimeService) { }

  showTimepicker() {
    this.showTimepickerDropdown = true;
  }

  hideTimepicker() {
    setTimeout(() => {
      this.showTimepickerDropdown = false;
    }, 250);
  }

  setTime() {
    const hours = this.timeService.getMilitaryHoursWithDatePipe(this.timepickerTime);
    let minutes = this.timeService.getMinutesWithDatePipe(this.timepickerTime);
    if (Number(minutes) % 5 !== 0)
      minutes = String(Math.round(Number(minutes) / this.mstep) * this.mstep);
    const time = hours + minutes;
    if (this.configuringTimePicker) // this is to keep the change form emitting when it was passed from parent component and not from user interaction of the time picker
      this.configuringTimePicker = false;
    else
      this.timeChanged.emit(Number(time));
  }

  configureTimePicker() {
    this.configuringTimePicker = true;
    const timeObject = this.timeService.getHoursAndMinutesFromTime(this.time);
    const currentDate = new Date();
    const year = Number(this.timeService.getPipeYearFromDate(currentDate));
    const month = Number(this.timeService.getPipeMonthFromDate(currentDate)) - 1;
    const day = Number(this.timeService.getPipeDayFromDate(currentDate));
    this.timepickerTime = new Date(year, month, day, timeObject.hours, timeObject.mins);
    this.displayTime = this.timeService.getShortTimeWithDatePipe(this.timepickerTime);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('time') && changes.time.currentValue !== changes.time.previousValue)
      this.configureTimePicker();
  }

  ngOnInit(): void {
    this.configureTimePicker();
  }

}
