import {Component, Input, OnInit} from '@angular/core';
import {Client, preference} from '../common-classes/app-objects.model';
import {EmailSettingsService} from './email-settings.service';
import {EmailCategory} from '../../models/EmailCategory.model';
import {TableColumnDataModel} from '../common-classes/TableColumnData.model';
import {MatTableDataSource} from '@angular/material/table';
import {DataTablesService} from '../data-tables.service';
import {MatSort} from '@angular/material/sort';
import {BusinessService} from '../business.service';
import {ActivatedRoute} from '@angular/router';
import {EmailTemplate} from '../../models/EmailTemplate.model';
import {EmailSettingConfig} from '../../models/EmailSettingsList.model';
import {WaitlistClaim} from '../../models/WaitList.model';
import {SchedulerPreferenceService} from '../scheduler-preference.service';

@Component({
  selector: 'email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['../data-tables.component.css']
})
export class EmailSettingsComponent implements OnInit {
  @Input() selectedClient: Client;
  preference: preference;
  emailCategories: EmailCategory[];
  emailTemplates: EmailTemplate[];
  displayedColumns: string[] = this.dataTablesService.getDefaultEmailSettingsColumns();
  columnDefs: TableColumnDataModel[] = this.dataTablesService.getDefaultEmailSettingsColumnDefs();
  emailCategoriesList = new MatTableDataSource<EmailSettingConfig>();
  private sort: MatSort;
  changingSubscribeStatus: boolean = false;
  subscribeStatusChanged: boolean = false;
  errorChangingSubscribeStatus: boolean = false;
  clientHash: string;
  constructor(private emailSettingsService: EmailSettingsService, private dataTablesService: DataTablesService, private businessService: BusinessService,
              private route: ActivatedRoute, private schedulerPreferenceService: SchedulerPreferenceService) {
    this.preference = schedulerPreferenceService.schedulerPreference;
  }

  establishTable(){
    const emailSettings: EmailSettingConfig[] = [];
    for (let i = 0, x = this.emailCategories.length; i < x; i++) {
      const emailSetting: EmailSettingConfig = new EmailSettingConfig();
      emailSetting.displayName = this.emailCategories[i].emailCategoryDisplayName;
      emailSetting.type = this.emailCategories[i].type;
      emailSetting.selected = this.emailCategories[i].selected;
      emailSetting.id = this.emailCategories[i].emailCatagoryId;
      emailSetting.emailTemplateOrCategory = 'emailCategory';
      emailSettings.push(emailSetting);
    }
    for (let i = 0, x = this.emailTemplates.length; i < x; i++) {
      const emailSetting: EmailSettingConfig = new EmailSettingConfig();
      emailSetting.displayName = this.emailTemplates[i].externalName || this.emailTemplates[i].templateName;
      emailSetting.type = this.emailTemplates[i].emailCatagory.type;
      emailSetting.selected = this.emailTemplates[i].selected;
      emailSetting.id = this.emailTemplates[i].emailTemplateId;
      emailSetting.emailTemplateOrCategory = 'emailTemplate';
      emailSettings.push(emailSetting);
    }

    this.emailCategoriesList.data = emailSettings;
    this.emailCategoriesList.sortingDataAccessor = (obj, property) => this.dataTablesService.getProperty(obj, property);
    this.emailCategoriesList.sort = this.sort;
  }

  updateSelected() {
    if (!this.selectedClient.excludeEmailCategoryIdList)
      this.selectedClient.excludeEmailCategoryIdList = [];
    for (let i = 0, x = this.emailCategories.length; i < x; i++)
      if (this.selectedClient.excludeEmailCategoryIdList.indexOf(this.emailCategories[i].emailCatagoryId) !== -1)
        this.emailCategories[i].selected = false;
      else
        this.emailCategories[i].selected = true;
    if (!this.selectedClient.excludeEmailTemplateIdList)
      this.selectedClient.excludeEmailTemplateIdList = [];
    for (let i = 0, x = this.emailTemplates.length; i < x; i++)
      if (this.selectedClient.excludeEmailTemplateIdList.indexOf(this.emailTemplates[i].emailTemplateId) !== -1)
        this.emailTemplates[i].selected = false;
      else
        this.emailTemplates[i].selected = true;
    this.establishTable();
  }

  changeSubscribeStatus(emailSettingConfig: EmailSettingConfig) {
    emailSettingConfig.changingSubscribeStatus = true;
    emailSettingConfig.subscribeStatusChanged = false;
    emailSettingConfig.errorChangingSubscribeStatus = false;
    if (emailSettingConfig.emailTemplateOrCategory === 'emailCategory')
      for (let i = 0, x = this.emailCategories.length; i < x; i++)
        if (this.emailCategories[i].emailCatagoryId === emailSettingConfig.id)
          this.emailCategories[i].selected = !this.emailCategories[i].selected;
    if (emailSettingConfig.emailTemplateOrCategory === 'emailTemplate')
      for (let i = 0, x = this.emailTemplates.length; i < x; i++)
        if (this.emailTemplates[i].emailTemplateId === emailSettingConfig.id)
          this.emailTemplates[i].selected = !this.emailTemplates[i].selected;


    const excludedEmailCategoryIdList = [];
    const subscribedEmailCategoryIdList = [];
    for (let i = 0, x = this.emailCategories.length; i < x; i++) {
      if (!this.emailCategories[i].selected)
        excludedEmailCategoryIdList.push(this.emailCategories[i].emailCatagoryId)
      else
        subscribedEmailCategoryIdList.push(this.emailCategories[i].emailCatagoryId)
    }
    const excludeEmailTemplateIdList = [];
    const subscribedEmailTemplateIdList = [];
    for (let i = 0, x = this.emailTemplates.length; i < x; i++) {
      if (!this.emailTemplates[i].selected)
        excludeEmailTemplateIdList.push(this.emailTemplates[i].emailTemplateId)
      else
        subscribedEmailTemplateIdList.push(this.emailTemplates[i].emailTemplateId)
    }
    this.emailSettingsService.updateClientEmails(this.selectedClient.clientId, excludedEmailCategoryIdList, subscribedEmailCategoryIdList, excludeEmailTemplateIdList, subscribedEmailTemplateIdList)
        .subscribe(
            (updatedClient) => {
              this.selectedClient.excludeEmailCategoryIdList = updatedClient.excludeEmailCategoryIdList;
              this.selectedClient.excludeEmailTemplateIdList = updatedClient.excludeEmailTemplateIdList;
              this.updateSelected();
              emailSettingConfig.changingSubscribeStatus = false;
              emailSettingConfig.subscribeStatusChanged = true;
              setTimeout(() => {
                emailSettingConfig.subscribeStatusChanged = false;
              }, 2000)
              emailSettingConfig.errorChangingSubscribeStatus = false;
            }, error => {
              //FAILED TO SAVE CLIENT
              emailSettingConfig.changingSubscribeStatus = false;
              emailSettingConfig.errorChangingSubscribeStatus = true;
              setTimeout(() => {
                emailSettingConfig.errorChangingSubscribeStatus = false;
              }, 2000)
              emailSettingConfig.subscribeStatusChanged = false;
            }
        )
  }

  getClientForEmail() {
    this.route.params.subscribe(params => {
      this.clientHash = params["clientHash"];
    })
    this.emailSettingsService.getClientForEmailSettings(this.clientHash).subscribe((client: Client) => {
      this.selectedClient = client;
      this.updateSelected();
    })
  }

  ngOnInit() {
    this.emailCategories = this.emailSettingsService.emailCategories;
    this.emailTemplates = this.emailSettingsService.emailTemplates;
    if (this.selectedClient)
      this.updateSelected();
    else
      this.getClientForEmail();
  }

}
