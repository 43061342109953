import {Component, Input, OnInit} from '@angular/core';
import {
  Client,
  ClientAccount,
  miniWebsite,
  preference,
  schedulerLink
} from "../../../common-classes/app-objects.model";
import {TableColumnDataModel} from "../../../common-classes/TableColumnData.model";
import {DataTablesService} from "../../../data-tables.service";
import {MatTableDataSource} from "@angular/material/table";
import {PackageSold} from "../../../store/packages/packages.model";
import {ClientAccountService} from "../../client-account.service";
import {MatSort} from "@angular/material/sort";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ResponsiveService} from '../../../responsive.service';
import {Appointment} from '../../../../models/Appointment.model';

@Component({
  selector: 'client-packages',
  templateUrl: './client-packages.component.html',
  styleUrls: ['./client-packages.component.css', '../client-profile.component.css', '../../../data-tables.component.css'],
  // animations: [
  //   trigger('detailExpand', [
  //     state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
  //     state('expanded', style({height: '*', display: 'inline'})),
  //     transition('expanded <=> collapsed', animate('125ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  //   ]),
  // ],
})
export class ClientPackagesComponent implements OnInit {
  @Input() preference: preference;
  @Input() miniWebsite: miniWebsite;
  @Input() clientAccount: ClientAccount;
  @Input() selectedClient: Client;
  displayedColumns: string[] = this.dataTablesService.getDefaultPackageSoldColumns();
  columnDefs: TableColumnDataModel[] = this.dataTablesService.getDefaultPackageSoldColumnDefs();
  packageSoldList = new MatTableDataSource<PackageSold>();
  private sort: MatSort;
  expandedPackage: PackageSold;
  constructor(private dataTablesService: DataTablesService, private clientAccountService: ClientAccountService, public responsiveService: ResponsiveService) { }

  expandRow(element: PackageSold){
    element.expanded = !element.expanded;
    if(element.expanded) {
      this.expandedPackage = element;
      for(let i = 0, x = this.packageSoldList.data.length; i < x; i++){
        if(this.packageSoldList.data[i].packageSoldId !== element.packageSoldId)
          this.packageSoldList.data[i].expanded = false;
      }
      this.expandedPackage.loadingAppts = true;
      this.clientAccountService.getAppointmentsByPackageSold(this.expandedPackage.packageSoldId).subscribe((appts: Appointment[]) => {
        if(appts !== null)
          this.expandedPackage.appts = appts;
        this.expandedPackage.apptsLoaded = true;
        this.expandedPackage.loadingAppts = false;
        this.expandedPackage.errorLoading = false;
      }, error => {
        this.expandedPackage.apptsLoaded = false;
        this.expandedPackage.loadingAppts = false;
        this.expandedPackage.errorLoading = true;
      })
    } else {
      this.expandedPackage = undefined;
      for(let i = 0, x = this.packageSoldList.data.length; i < x; i++){
        this.packageSoldList.data[i].expanded = false;
      }
    }
  }

  establishTable(){
    this.packageSoldList.data = this.selectedClient.packageSoldList;
    this.packageSoldList.sortingDataAccessor = (obj, property) => this.dataTablesService.getProperty(obj, property);
    this.packageSoldList.sort = this.sort;
  }

  getPackagesSoldForClient(){
    if(this.selectedClient.packageSoldList === undefined){
      this.clientAccountService.getPackagesSoldByClientId(this.selectedClient.clientId).subscribe((packageSoldList: PackageSold[]) => {
        this.selectedClient.packageSoldList = packageSoldList;
        this.establishTable();
      })
    } else {
      this.establishTable();
    }
  }

  ngOnInit() {
    this.getPackagesSoldForClient();
  }

}
