import {TimeZoneCode} from '../app/scheduler/time/time-objects.model';
import {ScreeningQuestion} from './ScreeningQuestion.model';
export enum GroupTypes {
    LOCATION_GROUP = 'LOCATION_GROUP',
    LOCATION = 'LOCATION',
    SERVICE_GROUP = 'SERVICE_GROUP',
    SERVICE = 'SERVICE'
}
export class Group {
    timeZoneCode: TimeZoneCode;
    displayName: string;

    constructor(
        public groupId: number,
        public groupName: string,
        public groupDescription: string,
        public logoUrl: string,
        public selected?: boolean,
        public screeningQuestion?: ScreeningQuestion,
        public emailInstructions?: string
    ) {
    }
}
