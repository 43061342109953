import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-account',
  templateUrl: './client-account.component.html'
})
export class ClientAccountComponent implements OnInit {

    constructor() { }



    ngOnInit() {

    }

}
