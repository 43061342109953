import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterModule} from '../common-components/footer/footer.module';
import {MaterialModule} from '../../material.module';
import {TitleBarModule} from '../common-components/title-bar/title-bar.module';
import {PipesModule} from '../../../pipes/pipes.module';
import {ClientInfoComponent} from './client-info.component';
import {SchedulerPrefFieldFormModule} from '../../scheduler-pref-field-form/scheduler-pref-field-form.module';



@NgModule({
  declarations: [ClientInfoComponent],
  imports: [
    CommonModule,
    FooterModule,
    MaterialModule,
    TitleBarModule,
    PipesModule,
      SchedulerPrefFieldFormModule
  ],
  exports: [ClientInfoComponent]
})
export class ClientInfoModule { }
