import {AfterViewChecked, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Client, preference, RecurringAppointment} from "../../../../common-classes/app-objects.model";
import {TableColumnDataModel} from "../../../../common-classes/TableColumnData.model";
import {TagsService} from "../../../../tags.service";
import {BusinessService} from "../../../../business.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TimeService} from "../../../../scheduler/time/time.service";
import {DataTablesService} from "../../../../data-tables.service";
import {ResponsiveService} from "../../../../responsive.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {WeekDayData} from "../../../../scheduler/time/time-objects.model";
import {Appointment} from '../../../../../models/Appointment.model';

@Component({
  selector: 'repeating-appointments-table',
  templateUrl: '../appointments-table/appointments-table.component.html'
})
export class RepeatingAppointmentsTableComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input() repeatingApptData: RecurringAppointment[];
    @Input() selectedClient: Client;
    @Input() preference: preference;
    @Input() loaded: boolean;
    @Input() errorLoading: boolean;
    @Input() loading = false;
    @Input() noApptsFoundProp: string;
    idProp = 'recurringAppointmentId';
    preferenceDefinedColumns: string[];
    displayedColumns = this.dataTablesService.getDefaultRepeatingApptDisplayedColumns();
    columnDefs: TableColumnDataModel[] = this.dataTablesService.getDefaultRepeatingApptColumnDefs();
    tableData = new MatTableDataSource<RecurringAppointment>();

    private sort: MatSort;
    @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
        this.sort = ms;
        this.tableData.sort = this.sort;
    }
    constructor(private dataTablesService: DataTablesService, private responsiveService: ResponsiveService, private router: Router, private route: ActivatedRoute,
              private timeService: TimeService, private businessService: BusinessService, private tagsService: TagsService) { }

    viewDetails(repeatingAppt: RecurringAppointment){
        delete repeatingAppt.clientStartTimeDate;
        delete repeatingAppt.clientEndTimeDate;
        delete repeatingAppt.staffStartTimeDate;
        delete repeatingAppt.staffEndTimeDate;
        this.router.navigate(['/my-account/repeating/' + repeatingAppt.recurringAppointmentId], {relativeTo: this.route.root, state: {repeatingAppt: repeatingAppt}});
    }
    viewDetailsNonAction(recurringAppt: any){
        if(this.responsiveService.onMobileViewPort){
            this.viewDetails(recurringAppt);
        }
    }

    organizeApptData(){
        if(this.repeatingApptData === null){
            this.repeatingApptData = [];
        }
        for(let i = 0, x = this.repeatingApptData.length; i < x; i++){
            let timezone = this.timeService.getApptTimezone(this.repeatingApptData[i].location, this.repeatingApptData[i].locationGroup, this.repeatingApptData[i].staff);
            this.repeatingApptData[i].staffStartTimeDate = this.timeService.getTimeWithMomentForClientConvertedTimezone(this.repeatingApptData[i].startTime, this.repeatingApptData[i].startDate, timezone);
            this.repeatingApptData[i].staffEndTimeDate = this.timeService.getTimeWithMomentForClientConvertedTimezone(this.repeatingApptData[i].endTime, this.repeatingApptData[i].endDate, timezone);
            this.repeatingApptData[i].clientStartTimeDate = this.timeService.getTimeWithMomentForClientConvertedTimezone(this.repeatingApptData[i].clientStartTime, this.repeatingApptData[i].startDate, timezone);
            this.repeatingApptData[i].clientEndTimeDate = this.timeService.getTimeWithMomentForClientConvertedTimezone(this.repeatingApptData[i].clientEndTime, this.repeatingApptData[i].endDate, timezone);
            this.repeatingApptData[i] = this.dataTablesService.addCustomFieldsToListDataObjects(this.repeatingApptData[i], this.selectedClient);
        }
        this.tableData.data = this.repeatingApptData;
        this.tableData.sortingDataAccessor = (obj, property) => this.dataTablesService.getProperty(obj, property);
        this.tableData.sort = this.sort;
        this.loaded = true;
        this.loading = false;
        this.errorLoading = false;
    }

    getDisplayedColumns(){
        let columnsForTable: {columns: TableColumnDataModel[], appointmentFieldList: string[]};
        columnsForTable = this.tagsService.getApptListColumnValues(this.preferenceDefinedColumns, this.preference.schedulerPreferenceFieldDefnList,
            this.preference.labelMap, this.responsiveService.onMobileViewPort, 'repeatingApptTags');
        this.displayedColumns = columnsForTable.appointmentFieldList;
        this.columnDefs = this.dataTablesService.setupColumnDefs(this.columnDefs, columnsForTable.columns);
        this.organizeApptData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(changes);
        if(changes.repeatingApptData !== undefined && changes.repeatingApptData.currentValue !== undefined){
            if(this.preference.repeatingApptFieldList === undefined || this.preference.repeatingApptFieldList === null)
                this.preferenceDefinedColumns = this.dataTablesService.getDefaultRepeatingApptDisplayedColumns();
            else
                this.preferenceDefinedColumns = this.preference.repeatingApptFieldList;
            this.getDisplayedColumns();
        }
    }
    ngAfterViewChecked(){
        this.responsiveService.addHorizontalScrollForTableOnMobile();
    }
    ngOnInit() {
        for(let i = 0, x = this.columnDefs.length; i < x; i++){
            switch(this.columnDefs[i].name){
                case this.idProp:
                    this.columnDefs[i].title = 'ID';
                    break;
                case 'clientStartTimeDate':
                    this.columnDefs[i].filterFormats[0].format = 'EEE, MMM d, yy';
                    break;
            }

        }
    }

}
