import {Component, OnInit} from '@angular/core';
import {
  Client,
  preference
} from "../../common-classes/app-objects.model";
import {WaitlistService} from "../waitlist.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BusinessService} from "../../business.service";
import {ClientAccountService} from "../../client-account/client-account.service";
import {WaitlistClaim} from '../../../models/WaitList.model';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';

@Component({
  selector: 'client-waitlist-times',
  templateUrl: './client-waitlist-times.component.html',
  styles: []
})
export class ClientWaitlistTimesComponent implements OnInit {
  clientHash: string;
  loading: boolean = true;
  errorLoading: boolean = false;
  loaded: boolean = false;
  showWaitlistSuccess: boolean = false;
  preference: preference = this.schedulerPreferenceService.schedulerPreference;
  selectedClient: Client;
  waitlistClaims: WaitlistClaim[];
  constructor(private waitlistService: WaitlistService, private route: ActivatedRoute, private router: Router,
              private schedulerPreferenceService: SchedulerPreferenceService,
              private businessService: BusinessService, private clientAccountService: ClientAccountService) { }

  getWaitlistHoldsByClient(){
    this.route.params.subscribe(params => {
      this.clientHash = params["clientHash"];
    })
    this.waitlistService.getWaitlistHoldsByClient(this.clientHash)
        .subscribe((waitlistClaims: WaitlistClaim[]) => {
          this.waitlistClaims = waitlistClaims;
          this.loading = false;
          this.loaded = true;
          this.errorLoading = false;
        }, error => {
          this.loading = false;
          this.loaded = false;
          this.errorLoading = true;
        })
  }

  ngOnInit() {
    this.selectedClient = this.clientAccountService.setSelectedClientOnComponent(this.preference);
    this.getWaitlistHoldsByClient();
  }

}
