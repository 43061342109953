import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    CourseSet,
    CSFilterParameterBean,
    MonthDataObj,
    movePanels,
    preference,
} from "../../common-classes/app-objects.model";
import {
    MonthAvailability,
    MonthCalendarChange,
    RepeatingAppointmentAvailability,
    RepeatingAppointmentSet,
    SelectDayChange,
    SingleDateAvailability,
    SingleTimeSlot,
    TimeLock,
    TimeZoneCode,
    WeekDayData
} from "./time-objects.model";
import {BusinessService} from "../../business.service";
import {FormControl, FormGroup} from "@angular/forms";
import {TimeService} from "./time.service";
import {TagsService} from "../../tags.service";
import {MatDialog} from "@angular/material";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {TimeCartComponent} from "./time-cart/time-cart.component";
import {ResponsiveService} from "../../responsive.service";
import {RepeatingApptFilterComponent} from "../../modals/repeating-appt-filter/repeating-appt-filter.component";
import {AvailabilityService} from '../../booking-components/availability.service';
import {Appointment} from '../../../models/Appointment.model';
import {Reason} from '../../../models/Reason.model';
import {ClassSession} from '../../../models/ClassSession.model';
import {Location} from '../../../models/Location.model';
import {DateAvailability} from '../../../models/DateAvailability.model';
import {Staff} from '../../../models/Staff.model';
import {schedulerPanel} from '../../../models/SchedulerPanel.model';
import {SchedulerService} from '../scheduler.service';
import {SelectedDateTimeObj} from '../../../models/SelectedDateTimeObj.model';
import {TimezoneService} from '../common-components/timezone/timezone.service';
import {DatePipe, FormatWidth, getLocaleDateFormat} from '@angular/common';
import * as momentTimezone from "moment-timezone/moment-timezone";


@Component({
  selector: 'time-panel',
  templateUrl: './time.component.html',
    styleUrls: ['./time.component.css']
})
export class TimeComponent implements OnInit, AfterViewChecked {
    @Output() onMovePanels = new EventEmitter<movePanels>();
    @Input() preference: preference;
    @Input() panel: schedulerPanel;
    @Input() CSFilterObj: CSFilterParameterBean;
    @Input() directionComingFrom: string;
    @Input() inputValues: any[];
    timeForm: FormGroup;
    showBackButton: boolean = true;
    currentDate: Date = new Date();
    monthAvailability: MonthAvailability;
    dateAvailability: DateAvailability;
    classSessions: ClassSession[];
    courseSets: CourseSet[];
    currentMonthData: MonthDataObj;
    selectedDate: SingleDateAvailability;
    selectedTime: SingleTimeSlot;
    selectedSet: CourseSet;
    selectedTimesCount: number;
    selectedSession: ClassSession;
    availabilityLoaded: boolean = false;
    dayAvailabilityLoaded: boolean = false;
    dayHasAvailability: boolean = false;
    monthAvailabilityLoaded: boolean = false;
    monthHasAvailability: boolean = false;
    courseHasAvailability: boolean = false;
    noAppointmentsAvailable: boolean = false;
    waitListNotifyFirstAvail: boolean = false;
    showClassCalendar: boolean = true;
    reasonDuration: number = 0;
    appt: Appointment;
    selectedLocation: Location;
    selectedStaff: Staff;
    selectedReason: Reason;
    errorLoading: boolean = false;
    timezones: TimeZoneCode[] = [];
    timeZoneNameForDisplay: string = null;
    timeManuallySelected: boolean = false;
    timeTitle: string;
    timePnlHeaderText: string;
    timePnlBookingSummary: string;
    timePnlSelectedTimesBtnLabel: string;
    timePnlNoCourseSetsAvailable: string;
    timeFormControlNameBase: string = 'selectedDateTime';
    waitListText: string = null;
    repeatingApptSelectedStartDate: Date = this.timeService.repeatingApptSelectedStartDate;
    repeatingApptSelectedDays: WeekDayData[] = this.timeService.repeatingApptSelectedDays;
    repeatingApptSets: RepeatingAppointmentSet[] = [];
    showRepeatingServiceBooking: boolean = false;
    selectedRepeatingApptSet: RepeatingAppointmentSet;
    repeatingAppointmentFilterSummary: string;
    showMobileSetCalendar: boolean = false;
    constructor(private businessService: BusinessService, private timeService: TimeService, private tagsService: TagsService, private schedulerService: SchedulerService,
                private dialog: MatDialog, private cdRef: ChangeDetectorRef, private responsiveService: ResponsiveService, public availabilityService: AvailabilityService,
                private timezoneService: TimezoneService, private datePipe: DatePipe) { }

    ngAfterViewChecked(): void {
        if(this.selectedReason.reasonType === 'COURSE' && this.courseSets !== undefined) {
            for (let i = 0, x = this.courseSets.length; i < x; i++) {
                this.responsiveService.checkTimeSlotWidthForOverflow(this.courseSets[i].recurringClassSchedule.recurringScheduleId, 'COURSE')
            }
        } else if(this.showRepeatingServiceBooking && this.repeatingApptSets !== undefined){
            for(let i = 0, x = this.repeatingApptSets.length; i < x; i++){
                this.responsiveService.checkTimeSlotWidthForOverflow(this.repeatingApptSets[i].setId, 'SERVICE_REPEATING')
            }
        }
    }

    setWaitListFirstAvailable(){
        this.waitListNotifyFirstAvail = !this.waitListNotifyFirstAvail;
        let firstAvailTime = this.availabilityService.createFirstAvailableTimeSlot(this.selectedReason, this.selectedStaff, this.selectedLocation);

        let event: MatCheckboxChange = new MatCheckboxChange();
        if(this.waitListNotifyFirstAvail){
            event.checked = true;
            this.setDayTime(firstAvailTime, 'onSelection', event);
        } else {
            event.checked = false;
            firstAvailTime.selected = false;
            this.setDayTime(firstAvailTime, 'onSelection', event);
        }
    }

    showSelectedTimes(){
        let timeList: SelectedDateTimeObj[] = [];
        for(let prop in this.timeForm.value){
            timeList.push(this.timeForm.value[prop]);
        }

        const dialogRef = this.dialog.open(TimeCartComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(), data: {timeList: timeList, preference: this.preference, reason: this.selectedReason, selectedTimesCount: this.selectedTimesCount, timezone: this.CSFilterObj.clientTimeZone}})
        dialogRef.afterClosed().subscribe((timeSlotRemovalList: SelectedDateTimeObj[]) => {
            if(timeSlotRemovalList !== undefined && timeSlotRemovalList !== null && timeSlotRemovalList.length !== 0) {
                for(let i = 0, x = timeSlotRemovalList.length; i < x; i++){
                    let timeLockToRemove = this.getTimeLockFromSelectedDateObj(timeSlotRemovalList[i]);
                    this.removeSelectedTimesFromTimeForm(timeSlotRemovalList[i], true, null, timeLockToRemove);
                    if(timeSlotRemovalList[i].isWaitListFirstAvail){
                        this.waitListNotifyFirstAvail = false;
                    }
                }
                if(this.selectedReason.reasonType === 'CLASS'){
                    this.setSelectedTimes(this.classSessions);
                }
                if(this.selectedReason.reasonType==='SERVICE'){
                    this.setSelectedTimes(this.dateAvailability.timeSlots);
                }
            }
        });
    }

    addRemoveTimeLocks(timeLockToAdd: TimeLock, timeLockToRemove?: TimeLock){
        if(timeLockToAdd !== undefined && timeLockToAdd !== null && timeLockToAdd.staffStartDate !== null){
            this.availabilityService.addTimeLock(timeLockToAdd).subscribe((added: boolean) => {
                // time lock added
                let timeLockAdded = added;
            });
        }
        if(timeLockToRemove !== undefined && timeLockToRemove !== null && timeLockToRemove.staffStartDate !== null){
            this.availabilityService.removeTimeLock(timeLockToRemove).subscribe((removed: boolean) => {
                // time lock removed
                let timeLockRemoved = removed;
            });
        }
    }

    setBtnLabel(timeLockToAdd: TimeLock, timeLockToRemove?: TimeLock){
        this.selectedTimesCount = 0;
        for(let prop in this.timeForm.value){
            if(this.timeForm.value[prop].clientStartTime !== undefined && this.timeForm.value[prop].clientStartTime !== null){
                this.selectedTimesCount++;
            }
        }
        this.timePnlSelectedTimesBtnLabel = this.preference.labelMap.timePnlSelectedTimesBtnLabel;
        this.timePnlSelectedTimesBtnLabel  = this.timePnlSelectedTimesBtnLabel.replace(new RegExp('%SELECTED_TIMES_COUNT%', 'g'), String(this.selectedTimesCount));
        this.addRemoveTimeLocks(timeLockToAdd, timeLockToRemove);
    }

    setSelectedTimes(times: any){
        for(let i = 0, x = times.length; i < x; i++){
            times[i].selected = false;
            for(let prop in this.timeForm.controls){
                // @ts-ignore
                if(this.selectedReason.reasonType === 'CLASS' && this.timeForm.controls[prop].controls['classScheduleId'].value === times[i].classScheduleId &&
                    this.timeForm.controls[prop].value['waitListMode'] === times[i].waitListMode){
                    times[i].selected = true;
                    break;
                }
                if(this.selectedReason.reasonType === 'SERVICE' && this.timeForm.controls[prop].value['timeSlotId'] === times[i].timeSlotId &&
                    this.timeForm.controls[prop].value['clientTimeSlotId'] === times[i].clientTimeSlotId &&
                    this.timeForm.controls[prop].value['htmlId'] === times[i].htmlId &&
                    this.timeForm.controls[prop].value['waitListMode'] === times[i].waitListMode){
                    times[i].selected = true;
                    break;
                }
            }
        }
    }

    removeSelectedTimesFromTimeForm(selectedTime: SelectedDateTimeObj, updateBtnLabel: boolean, timeLockToAdd: TimeLock, timeLockToRemove?: TimeLock){
        this.timeService.removeSelectedTimesFromTimeForm(this.timeForm, selectedTime, this.selectedReason.reasonType);
        // for(let prop in this.timeForm.controls) {
        //     // @ts-ignore
        //     if(this.selectedReason.reasonType === ReasonTypes.CLASS && this.timeForm.controls[prop].controls['classScheduleId'].value === selectedTime.classScheduleId){
        //         this.timeForm.removeControl(prop);
        //         break;
        //     }
        //
        //     // @ts-ignore
        //     if(this.selectedReason.reasonType === ReasonTypes.SERVICE && this.timeForm.controls[prop].controls['timeSlotId'].value === selectedTime.timeSlotId && this.timeForm.controls[prop].controls['clientTimeSlotId'].value === selectedTime.clientTimeSlotId && this.timeForm.controls[prop].controls['htmlId'].value === selectedTime.htmlId){
        //         this.timeForm.removeControl(prop);
        //         break;
        //     }
        //
        //     // @ts-ignore
        //     if(this.selectedReason.reasonType === ReasonTypes.COURSE && this.timeForm.controls[prop].controls['recurringScheduleId'].value === selectedTime.recurringScheduleId){
        //         this.timeForm.removeControl(prop);
        //         break;
        //     }
        //
        // }
        // let count = 0;
        // for(let prop in this.timeForm.controls){
        //     let formGroup = this.timeForm.controls[prop];
        //     this.timeForm.removeControl(prop);
        //     if(count == 0){
        //         this.timeForm.addControl('selectedDateTime', formGroup);
        //     } else {
        //         let formControlName = 'selectedDateTime' + count;
        //         this.timeForm.addControl(formControlName, formGroup);
        //
        //     }
        //     count++;
        // }
        //if select, deselect, then reselect one time slot when calendar loads (TT-6015) then the cart will not update when it gets reselected because the timeform's initial selectedDateTime is removed
        //thus, check to see if timeform value is empty and if so add the default selectedDateTime to it
        if (this.timeForm.controls['selectedDateTime'] === undefined){
            this.timeForm.addControl('selectedDateTime', this.schedulerService.getTimeSlotFormGroup())
        }
        if(updateBtnLabel){
            this.setBtnLabel(timeLockToAdd, timeLockToRemove)
        }
    }

    checkAddRemoveMultipleTimes(event: MatCheckboxChange, selectedDateTimeObj: SelectedDateTimeObj, timeLockToAdd: TimeLock, timeLockToRemove?: TimeLock){
        // @ts-ignore
        if(event.checked) {
            // @ts-ignore
            if (this.timeForm.controls['selectedDateTime'].controls.clientStartTime.value === null) {
                this.timeForm.controls['selectedDateTime'].setValue(selectedDateTimeObj);
            } else {
                let count = 0;
                for (let prop in this.timeForm.controls) {
                    count++;
                }
                let formControlName = 'selectedDateTime' + count;
                this.timeForm.addControl(formControlName, this.schedulerService.getTimeSlotFormGroup());
                this.timeForm.controls[formControlName].setValue(selectedDateTimeObj);
                //if the selectedDateTimeObj getting added is waitlist first available,
                // then need to look through list of times that have already been selected and remove any other waitlist values
                if(selectedDateTimeObj.isWaitListFirstAvail) {
                    let timeArray: SelectedDateTimeObj[] = [];
                    for(let prop in this.timeForm.controls){
                        timeArray.push(this.timeForm.controls[prop].value);
                    }
                    timeArray.forEach((control) => {
                        // @ts-ignore
                        // @ts-ignore
                        if(control['waitListMode'] &&
                            // @ts-ignore
                            !control['isWaitListFirstAvail']){
                            for(let prop in this.timeForm.value){
                                if(this.timeForm.value[prop] !== undefined && control['timeSlotId']===this.timeForm.value[prop].timeSlotId &&
                                    control['clientTimeSlotId']===this.timeForm.value[prop].clientTimeSlotId &&
                                    control['htmlId']===this.timeForm.value[prop].htmlId){
                                    this.removeSelectedTimesFromTimeForm(this.timeForm.value[prop], false, timeLockToAdd, timeLockToRemove);
                                }
                            }
                            //update times on UI so checkboxes are not checked
                            if(this.dateAvailability && this.dateAvailability.timeSlots && this.dateAvailability.timeSlots.length > 0){
                                for(let i = 0, x = this.dateAvailability.timeSlots.length; i < x; i++){
                                    if(this.dateAvailability.timeSlots[i].timeSlotId === control['timeSlotId'] &&
                                        this.dateAvailability.timeSlots[i].clientTimeSlotId === control['clientTimeSlotId'] &&
                                        this.dateAvailability.timeSlots[i].htmlId === control['htmlId']){
                                        this.dateAvailability.timeSlots[i].selected = false;
                                    }
                                }
                            } else if (this.classSessions && this.classSessions.length > 0) {
                                for(let i = 0, x = this.classSessions.length; i < x; i++)
                                    if(this.classSessions[i].classScheduleId === control['classScheduleId'])
                                        this.classSessions[i].selected = false;
                            } else if (this.courseSets && this.courseSets.length > 0) {
                                this.selectedSet = null;
                                // for(let i = 0, x = this.courseSets.length; i < x; i++)
                                //     if(this.courseSets[i].recurringClassSchedule.recurringScheduleId === control['recurringScheduleId'])
                                //         this.selectedSet = null;
                            }
                        }
                    });
                    this.setBtnLabel(timeLockToAdd, timeLockToRemove);
                }

            }
            this.setBtnLabel(timeLockToAdd, timeLockToRemove)

        } else {
            this.removeSelectedTimesFromTimeForm(selectedDateTimeObj, true, timeLockToAdd, timeLockToRemove);
        }
    }

    getTimeLockFromSelectedDateObj(selectedDateTimeObj: SelectedDateTimeObj): TimeLock{
        let timeLockToReturn: TimeLock = new TimeLock();
        timeLockToReturn.auditReferralId = sessionStorage.getItem('auditReferralId');
        timeLockToReturn.staffStartDate = selectedDateTimeObj.clientStartDate;
        timeLockToReturn.staffEndDate = selectedDateTimeObj.clientEndDate;
        timeLockToReturn.staffStartTime = selectedDateTimeObj.startTime;
        timeLockToReturn.staffEndTime = selectedDateTimeObj.endTime;
        if(selectedDateTimeObj.classScheduleId !== undefined && selectedDateTimeObj.classScheduleId !== null)
            timeLockToReturn.classScheduleId = selectedDateTimeObj.classScheduleId;
        if(selectedDateTimeObj.calTimeSlot != null)
            timeLockToReturn.units = selectedDateTimeObj.calTimeSlot.units;
        return timeLockToReturn
    }

    setDayTime(time: SingleTimeSlot, path: string, event?: MatCheckboxChange){
        if(path === 'onSelection')
            this.timeManuallySelected = true;
        let selectedDateTimeObj: SelectedDateTimeObj = new SelectedDateTimeObj().createFromSingleTimeSlot(time, this.selectedReason, this.selectedStaff, this.selectedLocation, this.preference.labelMap.labelNoPreference);
        let timeLockToAdd: TimeLock = this.getTimeLockFromSelectedDateObj(selectedDateTimeObj)
        if(this.directionComingFrom !== 'reschedule' && (this.selectedReason.bookMultipleAppts || this.showRepeatingServiceBooking || (this.selectedReason.allowWaitList && this.availabilityService.waitListMode))) {
            this.checkAddRemoveMultipleTimes(event, selectedDateTimeObj, timeLockToAdd);
        } else {
            //If the client is currently booking a service that is waitlistable and they select waitlist times before selecting any booking dates and times, then we need to move the waitlist times to the selectedDateTime2+ controls and store the selectedTime in the selectedDateTime control
            if(this.timeForm.controls['selectedDateTime'].value.waitListMode && path === 'onSelection'){
                let timeFormControlsHolder = new FormGroup({});
                for(let prop in this.timeForm.controls){
                    let controlToAdd = new FormControl(this.timeForm.controls[prop].value,this.timeForm.controls[prop].validator, this.timeForm.controls[prop].asyncValidator);
                    timeFormControlsHolder.addControl(prop, controlToAdd);
                }
                for(let prop in timeFormControlsHolder.controls){
                    let propNumString = prop.substring(this.timeFormControlNameBase.length);
                    let propNum = 0;
                    if(propNumString === ''){
                        propNum = 1;
                    } else {
                        propNum = Number(propNumString) + 1;
                    }
                    let timeFormPropName = this.timeFormControlNameBase + propNum;
                    if(this.timeForm.controls[timeFormPropName] === undefined){
                        this.timeForm.addControl(timeFormPropName, timeFormControlsHolder.controls[prop]);
                    } else {
                        this.timeForm.controls[timeFormPropName].setValue(timeFormControlsHolder.controls[prop].value);
                    }

                }
                if(path === 'onSelection'){
                    this.selectedTime = time;
                    let timeLockToRemove: TimeLock = this.getTimeLockFromSelectedDateObj(this.timeForm.controls['selectedDateTime'].value);
                    this.timeForm.controls['selectedDateTime'].setValue(selectedDateTimeObj);
                    this.setBtnLabel(timeLockToAdd, timeLockToRemove)
                }
            } else if((!this.selectedReason.allowWaitList || this.directionComingFrom === 'reschedule') || path === 'onSelection' || path === 'onGetDateAvailabilitySelectedTime') {
                this.selectedTime = time;
                let timeLockToRemove: TimeLock = this.getTimeLockFromSelectedDateObj(this.timeForm.controls['selectedDateTime'].value);
                this.timeForm.controls['selectedDateTime'].setValue(selectedDateTimeObj);
                if(path === 'onSelection' || path === 'onGetDateAvailabilitySelectedTime')
                    this.setBtnLabel(timeLockToAdd, timeLockToRemove);
                else
                    this.addRemoveTimeLocks(timeLockToAdd, timeLockToRemove);
            } else if(this.selectedReason.allowWaitList && path !== 'onSelection'){
                this.selectedTime = time;
                let timeLockToRemove: TimeLock = this.getTimeLockFromSelectedDateObj(this.timeForm.controls['selectedDateTime'].value);
                this.timeForm.controls['selectedDateTime'].setValue(selectedDateTimeObj);
                this.setBtnLabel(timeLockToAdd, timeLockToRemove);
            }


        }
    }

    setClassSession(session: ClassSession, path: string, event?: MatCheckboxChange){
        if(path === 'onSelection')
            this.timeManuallySelected = true;
        let startDate = this.timeService.getStringDateFromTime(new Date(session.startDateTime));
        let endDate = this.timeService.getStringDateFromTime(new Date(session.endDateTime));
        let sessionWaitlistMode = false;
        if(session.currentCapacity >= session.maxCapacity)
            sessionWaitlistMode = true;
        let selectedDateTimeObj: SelectedDateTimeObj =  new SelectedDateTimeObj();
        selectedDateTimeObj.createFromClassSession(session, startDate, endDate, this.selectedReason, this.selectedStaff, this.selectedLocation, sessionWaitlistMode);
        let timeLockToAdd = this.getTimeLockFromSelectedDateObj(selectedDateTimeObj);

        if(this.selectedReason.bookMultipleAppts && this.selectedReason.reasonType !== 'COURSE' && this.directionComingFrom !== 'reschedule') {
            this.checkAddRemoveMultipleTimes(event, selectedDateTimeObj, timeLockToAdd);
        } else {
            this.selectedSession = session;
            let timeLockToRemove: TimeLock = this.getTimeLockFromSelectedDateObj(this.timeForm.controls['selectedDateTime'].value);
            this.timeForm.controls['selectedDateTime'].setValue(selectedDateTimeObj);
            this.setBtnLabel(timeLockToAdd, timeLockToRemove);
        }
    }

    setSelectedSet(courseSet:CourseSet,path: string){
        const timeZoneForMoment = this.timeService.checkToAddMomentTimezone(-1, (this.CSFilterObj.clientTimeZone?this.CSFilterObj.clientTimeZone:this.timeService.businessTimezone));
        this.selectedSet = courseSet;
        this.selectedSet.monthAvailability = [];
        let months: number[] = [];
        for(let i = 0, x = this.selectedSet.classSessions.length; i < x; i++){
            let startDate = momentTimezone.utc(this.selectedSet.classSessions[i].startDateTimeUTC).tz(timeZoneForMoment).format('M/DD/YY');

            months = this.checkForMonthInAvailabilityArray(months, startDate, this.selectedSet.monthAvailability);
        }
        this.selectedSet = this.setMinMaxDatesOnSelectedSet(this.selectedSet);

        this.setClassSession(courseSet.classSessions[0], path);
    }

    addRepeatingApptSetDataToMonthAvailability(monthAvailability: MonthAvailability, startTimeDate: Date){
        let timezone = this.timeService.getApptTimezone(this.appt.location, this.appt.locationGroup, this.appt.staff);
        let singleDateAvailbility: SingleDateAvailability = new SingleDateAvailability(Number(this.timeService.getPipeDayFromDate(startTimeDate, timezone)), Number(this.timeService.getPipeMonthFromDate(startTimeDate, timezone)), Number(this.timeService.getPipeYearFromDate(startTimeDate, timezone)));
        monthAvailability.openDays.push(singleDateAvailbility);
    }

    findMatchingMonth(monthNum: number, startTimeDate: Date, monthAvailability: MonthAvailability[]){
        for(let j = 0, y = monthAvailability.length; j < y; j++){
            if(monthAvailability[j].month === monthNum){
                this.addRepeatingApptSetDataToMonthAvailability(monthAvailability[j], startTimeDate);
            }
        }
    }

    checkForMonthInAvailabilityArray(months: number[], startTimeDate: Date, monthAvailabilityList: MonthAvailability[]){
        let monthNum = Number(this.timeService.getPipeMonthFromDate(startTimeDate));
        if(months.indexOf(monthNum) === -1) {
            months.push(monthNum);
            let monthAvailability: MonthAvailability = new MonthAvailability();
            monthAvailability.month = Number(this.timeService.getPipeMonthFromDate(startTimeDate))
            monthAvailability.year = Number(this.timeService.getPipeYearFromDate(startTimeDate));
            monthAvailability.openDays = [];
            monthAvailabilityList.push(monthAvailability);
            this.findMatchingMonth(monthNum, startTimeDate, monthAvailabilityList);
        } else {
            this.findMatchingMonth(monthNum, startTimeDate, monthAvailabilityList);
        }
        return months;
    }

    setSelectedMonthOnSet(monthAvailabilityList: MonthAvailability[], monthNumberFull: number, monthYear: number){
        for(let i = 0, x = monthAvailabilityList.length; i < x; i++){
            if(monthAvailabilityList[i].month === monthNumberFull && monthAvailabilityList[i].year === monthYear) {
                this.selectedDate = monthAvailabilityList[i].openDays[0];
                return monthAvailabilityList[i];
            }
        }

    }

    moveMonthsSelectedRepeatingSet(monthCalendarChange: MonthCalendarChange){ // called when month is toggled from calendar component when view times for one time appointment
        event.stopPropagation()
        if(this.selectedReason.reasonType === 'COURSE'){
            this.selectedSet.selectedMonthAvailability = this.setSelectedMonthOnSet(this.selectedSet.monthAvailability, monthCalendarChange.monthDataObj.monthNumberFull, monthCalendarChange.monthDataObj.currentYear)
        } else {
            this.selectedRepeatingApptSet.selectedMonthAvailability = this.setSelectedMonthOnSet(this.selectedRepeatingApptSet.monthAvailability, monthCalendarChange.monthDataObj.monthNumberFull, monthCalendarChange.monthDataObj.currentYear)
        }
    }

    setMinMaxDatesOnSelectedSet(selectedSet: any){ // could be selectedRepeatingApptSet or selectedSet (for courses)
        selectedSet.selectedMonthAvailability = selectedSet.monthAvailability[0];
        selectedSet.minBookingDate = selectedSet.monthAvailability[0].openDays[0].date;
        let lastMonthAvailabilityIndex = selectedSet.monthAvailability.length - 1;
        let lastOpenDaysIndex = selectedSet.monthAvailability[lastMonthAvailabilityIndex].openDays.length - 1;
        selectedSet.maxBookingDate = selectedSet.monthAvailability[lastMonthAvailabilityIndex].openDays[lastOpenDaysIndex].date;
        this.selectedDate = selectedSet.selectedMonthAvailability.openDays[0];
        return selectedSet;
    }

    setSelectedRepeatingSet(repeatingAppointmentSet: RepeatingAppointmentSet, path: string){
        this.clearTimeForm();
        this.selectedRepeatingApptSet = repeatingAppointmentSet;
        this.selectedRepeatingApptSet.monthAvailability = [];
        let months: number[] = [];
        for(let i = 0, x = this.selectedRepeatingApptSet.dateTimeList.length; i < x; i++){
            months = this.checkForMonthInAvailabilityArray(months, this.selectedRepeatingApptSet.dateTimeList[i].startTimeDateForTimeslotDisplay, this.selectedRepeatingApptSet.monthAvailability);
        }
        this.selectedRepeatingApptSet = this.setMinMaxDatesOnSelectedSet(this.selectedRepeatingApptSet);
        let event: MatCheckboxChange = new MatCheckboxChange();
        event.checked = true;
        for(let i = 0, x = this.selectedRepeatingApptSet.dateTimeList.length; i < x; i++){
            let singleTimeSlot: SingleTimeSlot = this.selectedRepeatingApptSet.dateTimeList[i].convertRepeatingDateSetToSingleTimeSlot(repeatingAppointmentSet, this.selectedReason);
            this.setDayTime(singleTimeSlot, path, event);
        }
    }



    organizeClassSessions(classSessions: ClassSession[]){
        this.classSessions = classSessions;
        for(let i = 0, x = this.classSessions.length; i < x; i++){
            if (this.classSessions[i].currentCapacity === this.classSessions[i].maxCapacity)
                classSessions[i].waitListMode = true;
            else
                classSessions[i].waitListMode = false;
            classSessions[i].stringDate = this.timeService.getStringDateFromTime(classSessions[i].date);
        }
        if(this.classSessions !== null && this.classSessions.length !== 0) {
            if(this.selectedReason.bookMultipleAppts) {
                this.setSelectedTimes(this.classSessions);
            } else if (this.preference.booleanMap.timePnlAutoSelectTime) {
                this.setClassSession(this.classSessions[0], 'onGetDateAvailability');
            }
            this.availabilityLoaded = true;
            this.dayAvailabilityLoaded = true;
            this.dayHasAvailability = true;
        } else {
            this.availabilityLoaded = true;
            this.dayAvailabilityLoaded = true;
            this.dayHasAvailability = false;
        }
    }

    getDateAvailability(selectedDate: SingleDateAvailability, path: string){
        this.dayAvailabilityLoaded = false;
        this.selectedDate = selectedDate;
        if(this.selectedReason.reasonType === 'SERVICE') {
            this.availabilityService.getDateAvailabilityServices(this.CSFilterObj, selectedDate, this.reasonDuration)
                .subscribe(
                    (dateAvailability: DateAvailability) => {
                        this.dateAvailability = dateAvailability;
                        //For TT-6286, when they are allowing timezone to change then ned to reference the clientStartTime
                        //otherwise, just need to reference startTime
                        if (this.dateAvailability.timeSlots !== null && this.dateAvailability.timeSlots.length !== 0) {
                            if(this.directionComingFrom !== 'reschedule' && (this.selectedReason.bookMultipleAppts || (this.selectedReason.allowWaitList && this.availabilityService.waitListMode))) {
                                this.setSelectedTimes(this.dateAvailability.timeSlots);
                            } else {
                                let timeFound = false;
                                if(this.selectedTime !== undefined && this.selectedTime !== null && (path === 'waitListToggle' || path === 'onloadSelectedTime')){
                                    for(let i = 0, x = this.dateAvailability.timeSlots.length; i < x; i++){
                                        if(this.dateAvailability.timeSlots[i].timeSlotId === this.selectedTime.timeSlotId &&
                                            this.dateAvailability.timeSlots[i].clientTimeSlotId === this.selectedTime.clientTimeSlotId &&
                                            this.dateAvailability.timeSlots[i].htmlId === this.selectedTime.htmlId){
                                            this.setDayTime(this.dateAvailability.timeSlots[i], 'onGetDateAvailabilitySelectedTime');
                                            timeFound = true;
                                            break;
                                        }
                                    }
                                }
                                if(!timeFound && this.preference.booleanMap.timePnlAutoSelectTime){
                                    this.setDayTime(this.dateAvailability.timeSlots[0], 'onGetDateAvailabilityDefaultTime');
                                }
                            }
                            this.availabilityLoaded = true;
                            this.dayAvailabilityLoaded = true;
                            this.dayHasAvailability = true;

                        } else {
                            this.availabilityLoaded = true;
                            this.dayAvailabilityLoaded = true;
                            this.dayHasAvailability = false;
                        }
                    }, error => {
                        this.availabilityLoaded = true;
                        this.dayAvailabilityLoaded = false;
                        this.errorLoading = true;
                    });
        } else if(this.selectedReason.reasonType === 'CLASS'){
            this.availabilityService.getDateAvailabilityClasses(this.CSFilterObj, selectedDate.year, selectedDate.month, selectedDate.day)
                .subscribe(
                    (classSessions: ClassSession[]) => {
                        this.organizeClassSessions(classSessions);
                    }, error => {
                        this.availabilityLoaded = false;
                        this.dayAvailabilityLoaded = false;
                        this.errorLoading = true;
                    });
        }
    }

    onSetDay(selectDayChange: SelectDayChange){
        this.getDateAvailability(selectDayChange.singleDateAvailability, selectDayChange.path);
    }

    organizeMonthDates(monthAvailability: MonthAvailability, path: string){
        // this.monthDays = this.timeService.getDaysOfMonth(this.activeYear, this.activeMonth, monthAvailability); // moved to calendar component
        this.monthAvailabilityLoaded = true;
        if(monthAvailability && monthAvailability.openDays && monthAvailability.openDays.length > 0) {
            if (this.currentMonthData.monthNumberFull !== monthAvailability.month) { // LHB 10/24/2021 TT-8225
                const monthsData: MonthDataObj[] = this.timeService.getMonthsData(monthAvailability.year);
                for (let i = 0, x = monthsData.length; i < x; i++)
                    if (monthsData[i].monthNumberFull === monthAvailability.month) {
                        this.currentMonthData = monthsData[i];
                        break;
                    }
            }
            this.monthHasAvailability = true;
            let dateFound = false;
            if(this.selectedDate !== undefined && path === 'timezoneChange'){
                for(let i = 0, x = monthAvailability.openDays.length; i < x; i++){
                    if(monthAvailability.openDays[i].date === this.selectedDate.date){
                        this.getDateAvailability(monthAvailability.openDays[i], path)
                        dateFound = true;
                    }
                }
            }

            if(this.selectedTime !== undefined && this.selectedTime !== null && (path === 'waitListToggle' || path === 'onloadSelectedTime')){
                let selectedDay: Number = null;
                if(this.selectedTime.startTimeDate !== undefined && this.selectedTime.startTimeDate !== null){
                    selectedDay = Number(this.timeService.getPipeDayFromDate(this.selectedTime.startTimeDate));
                }
                // @ts-ignore
                if(this.selectedTime.clientStartTimeDate !== undefined) {
                    // @ts-ignore
                    selectedDay = Number(this.timeService.getPipeDayFromDate(this.selectedTime.clientStartTimeDate));
                }

                for(let i = 0, x = this.monthAvailability.openDays.length; i < x; i++){
                    if(monthAvailability.openDays[i].day === selectedDay){
                        this.getDateAvailability(monthAvailability.openDays[i], path)
                        dateFound = true;
                    }
                }
            }
            if(!dateFound){
                this.getDateAvailability(monthAvailability.openDays[0], path);
            }
        } else {//No open days in selected month
            this.monthHasAvailability = false;
            this.dayAvailabilityLoaded = true;
            this.availabilityLoaded = true;
            if(this.selectedReason.reasonType === 'SERVICE'){
                if(this.dateAvailability === undefined){
                    this.dateAvailability = new DateAvailability(false, this.timeService.getStringDateFromTime(new Date()), []);
                    if(path === 'onload' && !this.selectedReason.allowWaitList){
                        this.noAppointmentsAvailable = true;
                        this.cdRef.detectChanges();
                        this.movePanels('back');
                    }
                } else {
                    this.dateAvailability.timeSlots = [];
                }
            }
            if(this.selectedReason.reasonType === 'CLASS'){
                this.classSessions = [];
            }

        }
    }

    timeZoneChanged(timezoneChangeEvent: {path: string, timezoneCode: string, timezoneId: number}){
        this.CSFilterObj.clientTimeZone = timezoneChangeEvent.timezoneCode;
        this.CSFilterObj.clientTimeZoneId = timezoneChangeEvent.timezoneId;
        this.getMonthDates(timezoneChangeEvent.path, this.currentMonthData.currentYear, this.currentMonthData.monthNumberFull);
    }

    getMonthDates(path: string, activeYear: number, activeMonth: number){
        let findBestMonth = false;
        if(path === 'onload' || path === 'waitListToggle'){
            findBestMonth = true;
        }
        if((path === 'waitListToggle' || path === 'onloadSelectedTime' || path === 'timezoneChange')  && this.selectedTime !== undefined && this.selectedTime !== null){
            if(this.selectedTime.startTimeDate !== undefined && this.selectedTime.startTimeDate !== null){
                activeYear = Number(this.timeService.getPipeYearFromDate(this.selectedTime.startTimeDate));
                activeMonth = Number(this.timeService.getPipeMonthFromDate(this.selectedTime.startTimeDate));
            }
            // @ts-ignore
            if(this.selectedTime.clientStartTimeDate !== undefined) {
                // @ts-ignore
                activeYear = Number(this.timeService.getPipeYearFromDate(this.selectedTime.clientStartTimeDate));
                // @ts-ignore
                activeMonth = Number(this.timeService.getPipeMonthFromDate(this.selectedTime.clientStartTimeDate));
            }
        }
        if(this.timeService.getAllowGetMonth(activeYear, activeMonth, this.availabilityService.maxBookingDate, this.availabilityService.minBookingDate)){
            this.monthAvailabilityLoaded = false;
            this.dayAvailabilityLoaded = false;
            if(this.selectedReason.reasonType === 'SERVICE') {
                this.availabilityService.getMonthDatesServices(this.CSFilterObj, activeYear, activeMonth, findBestMonth, this.reasonDuration)
                    .subscribe(
                        (monthAvailability: MonthAvailability) => {
                            this.monthAvailability = monthAvailability;
                            this.organizeMonthDates(this.monthAvailability, path);
                        }, error => {
                            this.availabilityLoaded = false;
                            this.dayAvailabilityLoaded = false;
                            this.monthAvailabilityLoaded = false;
                            this.errorLoading = true;
                        });
            } else if(this.selectedReason.reasonType === 'CLASS'){
                if(this.preference.classCalendar){
                    this.availabilityService.getMonthDatesClasses(this.CSFilterObj, activeYear, activeMonth, findBestMonth)
                        .subscribe(
                            (monthAvailability) => {
                                this.monthAvailability = monthAvailability;
                                this.organizeMonthDates(this.monthAvailability, path);
                            }, error => {
                                this.availabilityLoaded = false;
                                this.dayAvailabilityLoaded = false;
                                this.monthAvailabilityLoaded = false;
                                this.errorLoading = true;
                            });
                } else {
                    this.availabilityService.getAvailabilityClassesNoCalendar(this.CSFilterObj)
                        .subscribe(
                            (classSessions: ClassSession[]) => {
                                this.monthAvailabilityLoaded = true;
                                if(classSessions.length > 0){
                                    this.monthHasAvailability = true;
                                }
                                this.organizeClassSessions(classSessions);
                            }, error => {
                                this.availabilityLoaded = false;
                                this.dayAvailabilityLoaded = false;
                                this.monthAvailabilityLoaded = false;
                                this.errorLoading = true;
                            });
                }

            } else if(this.selectedReason.reasonType === 'COURSE'){
                this.courseSets = [];
                const timeZoneForMoment = this.timeService.checkToAddMomentTimezone(-1, (this.CSFilterObj.clientTimeZone?this.CSFilterObj.clientTimeZone:this.timeService.businessTimezone));

                this.availabilityService.getCourseSets(this.CSFilterObj)
                    .subscribe(
                        (courseSets: CourseSet[]) => {
                            if(courseSets.length > 0){
                                for(let i = 0, x = courseSets.length; i < x; i++){
                                    for(let j = 0, y = courseSets[i].classSessions.length; j < y; j++){
                                        if(courseSets[i].recurringClassSchedule.startDateTimeUTC === undefined ||courseSets[i].recurringClassSchedule.startDateTimeUTC === null ||
                                            courseSets[i].recurringClassSchedule.startDateTimeUTC > courseSets[i].classSessions[j].startDateTimeUTC){
                                            courseSets[i].recurringClassSchedule.startDateTimeUTC = courseSets[i].classSessions[j].startDateTimeUTC;
                                        }
                                        if(courseSets[i].recurringClassSchedule.endDateTimeUTC === undefined || courseSets[i].recurringClassSchedule.endDateTimeUTC === null ||
                                            courseSets[i].recurringClassSchedule.endDateTimeUTC < courseSets[i].classSessions[j].endDateTimeUTC){
                                            courseSets[i].recurringClassSchedule.endDateTimeUTC = courseSets[i].classSessions[j].endDateTimeUTC;
                                        }
                                        // LHB 02/04/2022 TT-8458 -- swapped out computation of string date to use datePipe transform method
                                        // courseSets[i].classSessions[j].stringDate = this.timeService.getStringDateFromTime(courseSets[i].classSessions[j].date);
                                        courseSets[i].classSessions[j].startTimeZonedDisplay = momentTimezone.utc(courseSets[i].classSessions[j].startDateTimeUTC).tz(timeZoneForMoment).format(getLocaleDateFormat(this.timeService.locale, FormatWidth.Short).toUpperCase());
                                        courseSets[i].classSessions[j].stringDate = momentTimezone.utc(courseSets[i].classSessions[j].startDateTimeUTC).tz(timeZoneForMoment).format('YYYY-MM-DD');
                                    }
                                    courseSets[i].recurringClassSchedule.startDateZonedDisplay = momentTimezone.utc(courseSets[i].recurringClassSchedule.startDateTimeUTC).tz(timeZoneForMoment).format(getLocaleDateFormat(this.timeService.locale, FormatWidth.Short).toUpperCase());
                                    courseSets[i].recurringClassSchedule.endDateZonedDisplay = momentTimezone.utc(courseSets[i].recurringClassSchedule.endDateTimeUTC).tz(timeZoneForMoment).format(getLocaleDateFormat(this.timeService.locale, FormatWidth.Short).toUpperCase());

                                }
                                this.courseSets = courseSets;
                                this.courseHasAvailability = true;
                                this.noAppointmentsAvailable = false;
                                this.setSelectedSet(this.courseSets[0], 'onload');
                            }  else {
                                this.courseSets = [];
                                this.courseHasAvailability = false;
                                this.timePnlNoCourseSetsAvailable = this.tagsService.convertApptPropertiesToTags('timePnlNoCourseSetsAvailable', this.preference, this.appt);
                                if (!this.selectedReason.allowWaitList) {
                                    this.noAppointmentsAvailable = true;
                                }
                            }
                            this.availabilityLoaded = true;
                            this.dayAvailabilityLoaded = true;
                            this.monthAvailabilityLoaded = true;

                        }, error => {
                            this.availabilityLoaded = false;
                            this.dayAvailabilityLoaded = false;
                            this.monthAvailabilityLoaded = false;
                            this.errorLoading = true;
                        });
            }
        } else if(path === 'onload') {
            let nextMonthData = this.timeService.moveToNextMonth(this.currentMonthData, activeYear, undefined, activeMonth);
            this.getMonthDates(path, nextMonthData.activeYear, nextMonthData.activeMonth);
        }
    }

    onGetMonthlyAvailability(monthCalendarChange: MonthCalendarChange){ // called when month is toggled from calendar component when view times for one time appointment
        this.currentMonthData = monthCalendarChange.monthDataObj;
        this.getMonthDates(monthCalendarChange.path, monthCalendarChange.monthDataObj.currentYear, monthCalendarChange.monthDataObj.monthNumberFull);
    }

    toggleWaitListMode(){
        this.availabilityService.waitListMode = !this.availabilityService.waitListMode;
        if(!this.selectedReason.bookMultipleAppts && !this.timeManuallySelected){
            this.clearTimeForm();
        }
        this.selectedSet = null;
        this.setBtnLabel(null);
        if(this.availabilityService.waitListMode){
            this.panel.panelTitleProperty = 'timePnlWaitlistTitle';
            this.waitListText = this.tagsService.convertApptPropertiesToTags('waitListTextExitWaitListMode', this.preference, this.appt);
            this.timePnlHeaderText = this.tagsService.convertApptPropertiesToTags('timePnlWaitlistText', this.preference, this.appt);
        } else {
            this.panel.panelTitleProperty = 'timeTitle';
            this.waitListText = this.tagsService.convertApptPropertiesToTags('waitListText', this.preference, this.appt);
            this.timePnlHeaderText = this.tagsService.convertApptPropertiesToTags('timeText', this.preference, this.appt);
        }
        if(this.preference.waitListFirstAvailable && this.selectedReason.reasonType === 'SERVICE'){
            for(let prop in this.timeForm.controls){
                if(this.timeForm.controls[prop].value['timeSlotId'] === 'waitListFirstAvail'){
                    this.waitListNotifyFirstAvail = true;
                }
            }
        }
        this.loadMonthDatesForGetMonthAvailCall('waitListToggle');
    }

    getRepeatingApptTimes(selectedDays: WeekDayData[], selectedStartDate: Date, timezoneChangeEvent?: {path: string, timezoneCode: string, timezoneId: number}){
        if (timezoneChangeEvent) {
            this.CSFilterObj.clientTimeZone = timezoneChangeEvent.timezoneCode;
            this.CSFilterObj.clientTimeZoneId = timezoneChangeEvent.timezoneId;
        }
        this.monthAvailabilityLoaded = false;
        this.dayAvailabilityLoaded = false;
        let selectedWeekdayNums = [];
        if(selectedDays !== undefined && selectedDays !== null){
            for(let i = 0, x = selectedDays.length; i < x; i++){
                if(selectedDays[i].selected)
                    selectedWeekdayNums.push(selectedDays[i].dayOfWeekInt);
            }
        }

        let selectedStartDateString = this.timeService.getStringDateFromTime(selectedStartDate);
        this.availabilityService.getRecurringAvailabilityServices(this.CSFilterObj, this.selectedReason.selectedRepeatingApptOption.numberOfAppointments, selectedStartDateString, selectedWeekdayNums)
            .subscribe((repeatingApptAvail: RepeatingAppointmentAvailability[]) => {
                this.repeatingApptSets = [];
                if(repeatingApptAvail !== null) {
                    for (let i = 0, x = repeatingApptAvail.length; i < x; i++) {
                        let repeatingApptAvailability: RepeatingAppointmentAvailability = new RepeatingAppointmentAvailability(repeatingApptAvail[i].staffId, repeatingApptAvail[i].locationId, repeatingApptAvail[i].calSlotList)
                        let repeatingApptSetsForStaff: RepeatingAppointmentSet[] = repeatingApptAvailability.createRepeatingApptSet(this.timeService, this.tagsService, this.preference.allowTZChange, this.CSFilterObj.businessTimeZone, this.CSFilterObj.clientTimeZone, this.preference.showOnlyStartTime, this.preference.timeFormat24Hrs);
                        for (let j = 0, y = repeatingApptSetsForStaff.length; j < y; j++)
                            this.repeatingApptSets.push(repeatingApptSetsForStaff[j]);
                    }
                    this.setSelectedRepeatingSet(this.repeatingApptSets[0], 'onload');
                    this.availabilityLoaded = true;
                    this.monthAvailabilityLoaded = true;
                    this.dayAvailabilityLoaded = true;
                    this.dayHasAvailability = true;
                } else {
                    this.availabilityLoaded = true;
                    this.monthAvailabilityLoaded = true;
                    this.dayAvailabilityLoaded = true;
                    this.dayHasAvailability = false;
                }


            })
    }
    handleRepeatingApptDialogClose(){
        this.repeatingApptSelectedDays = this.timeService.repeatingApptSelectedDays;
        this.repeatingApptSelectedStartDate = this.timeService.repeatingApptSelectedStartDate;
        let filterLabel = "Showing options on ";
        let daysSelectedLabel = '';
        if(this.repeatingApptSelectedDays.length === 1){
            daysSelectedLabel = this.repeatingApptSelectedDays[0].dayOfWeekName;
        } else {
            for(let k = 0, z = this.repeatingApptSelectedDays.length; k < z; k++){
                if(k === 0){
                    daysSelectedLabel = this.repeatingApptSelectedDays[k].dayOfWeekName;
                } else if(k < z - 1){ // is not the last item in the list
                    daysSelectedLabel = daysSelectedLabel + "," + this.repeatingApptSelectedDays[k].dayOfWeekName;
                } else if(k === z - 1){ // is the last item in the list
                    daysSelectedLabel = daysSelectedLabel + " & " + this.repeatingApptSelectedDays[k].dayOfWeekName;
                }
            }
        }
        this.repeatingAppointmentFilterSummary = filterLabel + daysSelectedLabel + " starting after " + this.timeService.getShortDateFromDate(this.repeatingApptSelectedStartDate)
        this.getRepeatingApptTimes(this.repeatingApptSelectedDays, this.repeatingApptSelectedStartDate);
    }
    showRepeatingApptFiltersModal(path: string){
        if(path === 'onload' && this.timeService.repeatingApptSelectedDays.length > 0){ // have already been here and selected options; just show those
            this.handleRepeatingApptDialogClose();
        } else {
            const dialogRef = this.dialog.open(RepeatingApptFilterComponent,
                {maxWidth: this.responsiveService.getMaxWidthForModals(),
                    data: {reason: this.selectedReason, preference: this.preference, startDate: this.repeatingApptSelectedStartDate, selectedDays: this.repeatingApptSelectedDays, minDate: this.availabilityService.minBookingDate, maxDate: this.availabilityService.maxBookingDate}
                })
            dialogRef.afterClosed().subscribe((repeatingApptForm: FormGroup) => {
                if(repeatingApptForm !== undefined){
                    this.timeService.repeatingApptSelectedDays = repeatingApptForm.controls['daysOfTheWeek'].value;
                    this.timeService.repeatingApptSelectedStartDate = repeatingApptForm.controls['startDate'].value;
                }
                this.handleRepeatingApptDialogClose();
            });
        }
    }

    loadMonthDatesForGetMonthAvailCall(path: string){
        if(this.currentMonthData === undefined || this.currentMonthData === null){// likely is getting called from onload so should set up initial current month data
            let monthsData = this.timeService.getMonthsData(new Date().getFullYear());
            this.currentMonthData = monthsData[new Date().getMonth()];
        }
        this.getMonthDates(path, this.currentMonthData.currentYear, this.currentMonthData.monthNumberFull)
    }

    handleTZMatch(){
        if(this.selectedReason.selectedRepeatingApptOption !== undefined && this.selectedReason.selectedRepeatingApptOption !== null && this.selectedReason.selectedRepeatingApptOption.numberOfAppointments > 1){
            this.showRepeatingServiceBooking = true;
            this.showRepeatingApptFiltersModal('onload');
        } else if(this.selectedTime !== undefined){
            //means they have toggled back into the Time screen and they've already selected a time so should load that date and have that time selected
            this.timeManuallySelected = true;
            this.loadMonthDatesForGetMonthAvailCall('onloadSelectedTime');
        } else {
            this.loadMonthDatesForGetMonthAvailCall('onload');
        }
    }

    matchClientTZ(businessTimezone: string, recallAfterMatchingMoment?: boolean){
        this.CSFilterObj = this.timezoneService.matchClientTZ(businessTimezone, this.CSFilterObj, recallAfterMatchingMoment);
        this.handleTZMatch();
    }

    getTimezoneForDisplay(){
        this.timezoneService.getTimezoneForDisplay(this.CSFilterObj)
            .subscribe((timezone: string) => {
                this.appt.apptTZ = timezone;
                this.matchClientTZ(timezone);
            });
    }

    clearTimeForm(){

        this.panel.formGroup = new FormGroup({
            'selectedDateTime': this.schedulerService.getTimeSlotFormGroup()});

        this.timeForm = this.panel.formGroup;
    }

    ngOnInit(){

        this.responsiveService.setHeightForEmbedScheduler();
        this.appt = new Appointment().createNew();
        this.availabilityService.waitListMode = false;
        for(let i = 0, x = this.inputValues.length; i < x; i++){
            switch(this.inputValues[i].panel){
                case 'locationGroups':
                    this.appt.locationGroup = this.inputValues[i].values[0].value;
                    break;
                case 'locations':
                    this.appt.location = this.inputValues[i].values[0].value;
                    for(let j = 0, y = this.inputValues[i].values.length; j < y; j++){
                        this.selectedLocation = this.inputValues[i].values[j].value;
                        if(this.selectedLocation.variableAddress !== undefined){
                            this.CSFilterObj.address = this.selectedLocation.variableAddress;
                        } else {
                            this.CSFilterObj.address = this.selectedLocation.description;
                        }
                    }
                    break;
                case 'staff':
                    this.appt.staff= this.inputValues[i].values[0].value;
                    for(let j = 0, y = this.inputValues[i].values.length; j < y; j++){
                        this.selectedStaff = this.inputValues[i].values[j].value;
                    }
                    break;
                case 'reasonGroups':
                    this.appt.reasonGroup = this.inputValues[i].values[0].value;
                    break;
                case 'reasons':
                    this.appt.reason = this.inputValues[i].values[0].value;
                    for(let j = 0, y = this.inputValues[i].values.length; j < y; j++){
                        this.selectedReason = this.inputValues[i].values[j].value;
                        if (!isNaN(this.inputValues[i].values[j].value.clientDuration)) {
                            this.reasonDuration = this.reasonDuration + this.inputValues[i].values[j].value.clientDuration;
                        } else {
                            this.reasonDuration = this.reasonDuration + this.inputValues[i].values[j].value.visitMinutes;
                        }
                        if (this.selectedReason.reasonType === 'SERVICE') {
                            if (this.inputValues[i].values[j].value.selectedAddOns && this.inputValues[i].values[j].value.selectedAddOns.length > 0) {
                                for (let k = 0, z = this.inputValues[i].values[j].value.selectedAddOns.length; k < z; k++)
                                    if (!isNaN(this.inputValues[i].values[j].value.selectedAddOns[k].visitMinutes) && this.inputValues[i].values[j].value.selectedAddOns[k].visitMinutes > 0) {
                                            this.reasonDuration = this.reasonDuration + this.inputValues[i].values[j].value.selectedAddOns[k].visitMinutes;
                                    }
                            }
                        }
                    }
                    if(this.selectedReason.selectedDuration !== undefined)
                        this.appt.reasonDesc = this.selectedReason.selectedDuration.durationName;
                    break;
                case 'time':
                    if(!this.selectedReason.bookMultipleAppts && !this.inputValues[i].values[0].value.waitListMode){
                        this.selectedTime = this.inputValues[i].values[0].value;
                    } else if(this.selectedReason.bookMultipleAppts || this.inputValues[i].values[0].value.waitListMode){
                        //toggle back into screen after already having selected multiple appointments
                        //need to add flag check here so that in togglewaitlist mode it doesn't clear form
                        //example: go through scheduler, pick 2 waitlist times and no booking times; go to client info, click back; toggle waitlist mode
                        //without this flag it would clear the selected times from cart
                        this.timeManuallySelected = true;
                    }

                    break;
            }
        }
        if(this.selectedStaff === undefined){
            this.selectedStaff = new Staff(null, null);
            this.selectedStaff.selected = true;
        }
        if(this.selectedLocation === undefined){
            this.selectedLocation = new Location();
            this.selectedLocation.locationId = null;
            this.selectedLocation.locationName = null;
            this.selectedLocation.allowTZChange = this.preference.allowTZChange;
        }
        if(this.selectedReason.bookMultipleAppts && this.selectedReason.requireStaffConfirmation){//TT-6054
            this.selectedReason.bookMultipleAppts = false;
        }
        // @ts-ignore
        if(this.panel.formGroup.controls[this.timeFormControlNameBase].controls['reasonId'].value === this.selectedReason.reasonId &&
            //@ts-ignore
            this.panel.formGroup.controls[this.timeFormControlNameBase].controls['professionalId'].value === this.selectedStaff.professionalId &&
            //@ts-ignore
            this.panel.formGroup.controls[this.timeFormControlNameBase].controls['locationId'].value === this.selectedLocation.locationId){
            this.timeForm = this.panel.formGroup
        } else {
            this.clearTimeForm();
        }
        if(this.selectedReason.allowWaitList)
            this.waitListText = this.tagsService.convertApptPropertiesToTags('waitListText', this.preference, this.appt);

        this.availabilityService.setMinMaxBookingDates(this.selectedReason);
        this.timeService.repeatingApptSelectedStartDate = this.availabilityService.minBookingDate;
        this.repeatingApptSelectedStartDate = this.timeService.repeatingApptSelectedStartDate;
        if(this.directionComingFrom === 'reschedule'){
            this.timePnlBookingSummary = this.tagsService.convertApptPropertiesToTags('timePnlRescheduleBookingSummary', this.preference, this.appt);
        } else {
            this.timePnlBookingSummary = this.tagsService.convertApptPropertiesToTags('timePnlBookingSummary', this.preference, this.appt);
        }
        this.panel.panelTitleProperty = 'timeTitle';
        this.timePnlHeaderText = this.tagsService.convertApptPropertiesToTags('timeText', this.preference, this.appt);
        if(this.selectedReason.reasonType !== 'CLASS' || (this.selectedReason.reasonType === 'CLASS' && this.preference.classCalendar)){
            this.showClassCalendar = true;
        } else {
            this.showClassCalendar = false;
        }

        this.getTimezoneForDisplay();
        if(this.selectedReason.bookMultipleAppts){
            this.setBtnLabel(null);
        }

    }

    movePanels(direction: string){
        // console.log(this.panel);
        if(direction === 'back' && this.showRepeatingServiceBooking)
            this.clearTimeForm();
        this.onMovePanels.emit({direction: direction, panel: this.panel, noAppointmentAvail: this.noAppointmentsAvailable})
    }

}
