import {AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  Client,
  currency,
  Invoice,
  preference
} from "../../../../common-classes/app-objects.model";
import {BusinessService} from "../../../../business.service";
import {ClientAccountService} from "../../../client-account.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TagsService} from "../../../../tags.service";
import {ResponsiveService} from "../../../../responsive.service";
import {Subscription} from "rxjs";
declare var Square: any;


@Component({
  selector: 'square-payment-form',
  templateUrl: './square.component.html',
  styleUrls: ['./square.component.css']
})
export class SquareComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Output() onGoBack = new EventEmitter<Invoice>();
  @Input() preference: preference;
  @Input() invoice: Invoice;
  @Input() client: Client;
  @Input() path: string;
  @Input() currency: currency;
  @Input() paymentFormHidden: boolean;
  squareJsProd: string = 'https://web.squarecdn.com/v1/square.js';
  squareJsTest: string = 'https://sandbox.web.squarecdn.com/v1/square.js';
  applicationIdProd: string = 'sq0idp-BUSIYGB-opVjWqDKVijxhA';
  applicationIdTest: string = 'sandbox-sq0idb-W6SeSVjyx2pPBMf0HH-fLA';
  applicationId: string = this.applicationIdProd;
  paymentFormLoaded: boolean = false;
  saveClientPayment: boolean = false;
  paramsSubscription: Subscription;
  @Input() submitBtnLabel: string;
  @Input() submittingBtnLabel: string;
  @Input() submitFailBtnLabel: string;
  loadExtJS: Promise<any>;
  constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private tagsService: TagsService,
              private route: ActivatedRoute, private router: Router, private responsiveService: ResponsiveService) {

  }

  setSaveClientPayment(){
    if(this.saveClientPayment)
      (<HTMLInputElement>document.getElementById('saveClientPayment')).value = "true"; //casting so .value will work
    else
      (<HTMLInputElement>document.getElementById('saveClientPayment')).value = "false"; //casting so .value will work
  }

  createSquarePaymentForm(counter: number){

    if(typeof Square !== "undefined" && counter < 200) {
      (async () => {
        let doVerify = true;
        let payments;
        if(this.invoice){
          payments = Square.payments(
              this.applicationId, this.invoice.squareLocationId
              );
        }else{
          if(this.businessService.defaultSquareLocationId){
            payments = Square.payments(
                this.applicationId, this.businessService.defaultSquareLocationId
            );
          }else{
            doVerify = false;
            payments = Square.payments(
                this.applicationId
            );
          }

        }

        const cardOptions = {
          style: {
            input: {
              backgroundColor: 'white'
            },
          }
        };
        let card;
        try {
            const card = await payments.card(cardOptions);
            await card.attach('#card').catch(e => {console.log('Error caught in card.attach ' + e)});


          const payButton = document.getElementById('sq-creditcard');
          this.paymentFormLoaded = true;
          payButton.addEventListener('click', async () => {
            let newUrl = window.location.href.substring(0, window.location.href.lastIndexOf('?'));
            (<HTMLInputElement>document.getElementById('sq-creditcard')).innerHTML = '<i class="fa fa-spinner fa-spin fa-fw"></i> ' + this.submittingBtnLabel;
            (<HTMLInputElement>document.getElementById('sq-creditcard')).disabled = true;
            let result = await card.tokenize().catch(e => {console.log('tokenize call ' + e)});
            let paymentsErrorBox =  document.getElementById("paymentsErrorBox");
            if (result.status === 'OK') {
              paymentsErrorBox.hidden = true;
              paymentsErrorBox.innerHTML = '';
              let nonce = result.token;
              (<HTMLInputElement>document.getElementById('paymentsErrorBox')).hidden = true;
              (<HTMLInputElement>document.getElementById('card-nonce')).value = nonce; //casting so .value will work
              let url: string;
              let path = sessionStorage.getItem("paymentFormPath");
              let debug = true;
              let currencyCode = 'USD';
              if(this.businessService && this.businessService.currency && this.businessService.currency.currencyCode)
                currencyCode = this.businessService.currency.currencyCode;
              let paymentAmount;
              let verifyBuyerDetails;
              let verifyBuyerStore;
              let clientId = (<HTMLInputElement>document.getElementById('clientId')).value;

              if (path === 'invoice') {
                window.location.href = newUrl + '?paying=true';
                let invoiceHash = (<HTMLInputElement>document.getElementById('invoiceHash')).value;
                let balanceAmount = (<HTMLInputElement>document.getElementById('balanceAmount')).value;
                paymentAmount = (<HTMLInputElement>document.getElementById('paymentAmount')).value;
                let saveClientPayment = (<HTMLInputElement>document.getElementById('saveClientPayment')).value;
                if (paymentAmount === undefined || paymentAmount === null) {
                  paymentAmount = balanceAmount;
                }
                if(saveClientPayment === 'true'){
                  verifyBuyerStore = { billingContact: {}, currencyCode: currencyCode, intent: 'STORE'};
                  let verificationResultsStore =
                      await payments.verifyBuyer(
                          result.token,
                          verifyBuyerStore
                      );

                  await this.saveCardToClient(debug, clientId, nonce, verificationResultsStore.token, invoiceHash, paymentAmount);
                  saveClientPayment = 'false';
                  //generate new nonce
                  result = await card.tokenize().catch(e => {console.log('tokenize call ' + e)});
                }

                if (result.status === 'OK') {
                  nonce = result.token;
                }
                verifyBuyerDetails = {amount: paymentAmount, billingContact: {}, currencyCode: currencyCode, intent: 'CHARGE'};

                if (debug)
                  url = sessionStorage.getItem('csAPIBase') + 'cs/payInvoice/' + invoiceHash + '?square-card-nonce=' + nonce + '&amt=' + paymentAmount + '&saveClientPayment=' + saveClientPayment;
                else
                  url = '../../../' + sessionStorage.getItem('csAPIBase') + 'cs/payInvoice/' + invoiceHash + '?square-card-nonce=' + nonce + '&amt=' + paymentAmount + '&saveClientPayment=' + saveClientPayment;



              } else if (path === 'addCard') {
                if (debug)
                  url = sessionStorage.getItem('csAPIBase') + 'cs/client/' + clientId + '/paymentInfo?square-card-nonce=' + nonce;
                else
                  url = '../../../' + sessionStorage.getItem('csAPIBase') + 'cs/client/' + clientId + '/paymentInfo?square-card-nonce=' + nonce;
                verifyBuyerDetails = { billingContact: {}, intent: 'STORE'};

              }

              if(doVerify) {
                const verificationResults = await payments.verifyBuyer(
                    nonce,
                    verifyBuyerDetails
                );
                if (verificationResults && verificationResults.token) {
                  url += '&verificationToken=' + verificationResults.token
                }
              }

                fetch(url , {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                  }
                })
                    .catch(err => {
                      alert('Network error: ' + err);
                    })
                    .then((response: Response) => {
                      if (!response.ok) {
                        return response.text().then(errorInfo => Promise.reject(errorInfo));
                      }
                      return response.text();
                    })
                    .then(data => {
                      if (path === 'invoice') {
                        let updatedInvoice = JSON.parse(data);
                        if (updatedInvoice.paymentConfirmUrl !== null && updatedInvoice.paymentConfirmUrl.trim() !== "" && updatedInvoice.subStatus !== 'Payment Declined')
                          window.location.href = updatedInvoice.paymentConfirmUrl;
                        else {
                          sessionStorage.setItem('updatedInvoice', data);
                          window.location.href = newUrl + '?paying=false';
                        }
                      } else if (path === 'addCard') {
                        sessionStorage.setItem('updatedCardsList', data);
                        window.location.href = window.location.href + '?cardAdded=true';
                      }
                    })
                    .catch(err => {
                      if (path === 'invoice') {
                        window.location.href = newUrl + '?paying=error';
                      }else{
                         paymentsErrorBox.hidden = false;
                        paymentsErrorBox.innerHTML ='<i class="fas fa-exclamation-triangle"></i> <strong>Error: Unable to add card to customer.</strong> ';

                        (<HTMLInputElement>document.getElementById('sq-creditcard')).innerHTML = 'Re-submit';
                        (<HTMLInputElement>document.getElementById('sq-creditcard')).disabled = false;
                      }
                      //paymentsErrorBox
                    });

            } else {
              // Log errors from nonce generation to the browser developer console.
              paymentsErrorBox.hidden = false;
              paymentsErrorBox.innerHTML ='<i class="fas fa-exclamation-triangle"></i> <strong>Error:</strong> ';
              result.errors.forEach(function(error) {
                paymentsErrorBox.innerHTML = paymentsErrorBox.innerHTML + error.message + '<br/>';
              });
              (<HTMLInputElement>document.getElementById('sq-creditcard')).innerHTML = 'Re-submit';
              (<HTMLInputElement>document.getElementById('sq-creditcard')).disabled = false;
            }
          })
        } catch (e) {
          console.error('In this error: ', e);
        }
      })().catch(e => {console.log('Thrown in main async ' + e)})
      this.addNonceFormEventListener(0);
    } else {
      counter++;
      try{
        setTimeout(() => this.createSquarePaymentForm(counter), 1000);
      }catch (e){
        console.log('error in catch for create');
      }
    }
  }

  addSqJSFile(){
    let node = undefined;
    let squareJSFileAdded = false;
    let scripts = document.getElementsByTagName("script");
    let squareScriptToUse = this.squareJsProd;
    if(this.businessService.businessConfigTest){
      squareScriptToUse = this.squareJsTest;
      this.applicationId = this.applicationIdTest;
    }
    for(let i = 0, x = scripts.length; i < x; i++){
      if(scripts[i].getAttribute('src')!== null && scripts[i].getAttribute('src')===squareScriptToUse){
        squareJSFileAdded = true;
      }
    }
    if(!squareJSFileAdded){
      node = document.createElement('script');
      node.src = squareScriptToUse;

      node.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(node);

    }
    return node;
  }

  ngAfterViewChecked(){
    this.responsiveService.setHeightForEmbedAccount();
  }

  ngOnInit() {
    sessionStorage.setItem("paymentFormPath", this.path);
    this.preference.continueShoppingUrl

    if(this.path === 'addCard'){
      this.submitBtnLabel = this.preference.labelMap.clientPaymentsAddCardBtn;
      this.submittingBtnLabel = this.preference.labelMap.clientPaymentsAddingCardBtn;
    }

    this.paramsSubscription = this.route.queryParams
        .subscribe(params => {
          let paying = params.paying;
          if(paying === "false" && !this.paymentFormHidden){
            let updatedInvoice = sessionStorage.getItem('updatedInvoice');
            sessionStorage.removeItem('updatedInvoice');
            this.goBack(JSON.parse(updatedInvoice));
          } else if(paying === 'error'){
            (<HTMLInputElement>document.getElementById('sq-creditcard')).innerHTML = this.submitBtnLabel;
            (<HTMLInputElement>document.getElementById('sq-creditcard')).disabled = false;
            (<HTMLInputElement>document.getElementById('paymentsErrorBox')).innerHTML = this.submitFailBtnLabel;
            (<HTMLInputElement>document.getElementById('paymentsErrorBox')).hidden = false;
          }
        });
    if(this.businessService.businessConfigTest){
      this.applicationId = this.applicationIdTest;
    }
    //this.loadExtJS.then((flag) => {this.createSquarePaymentForm(0)});
    this.createSquarePaymentForm(0);
  }

  goBack(invoice){
    this.onGoBack.emit(invoice);
  }

  // onSubmit(e){
  //   e.preventDefault();
  // }

  addNonceFormEventListener(counter: number) {
    let path = sessionStorage.getItem("paymentFormPath");
    if(path === 'invoice') {
      (<HTMLInputElement>document.getElementById('invoiceHash')).value = this.invoice.invoiceHash; //casting so .value will work
      (<HTMLInputElement>document.getElementById('balanceAmount')).value = String(this.invoice.balanceAmount); //casting so .value will work
      (<HTMLInputElement>document.getElementById('paymentAmount')).value = String(this.invoice.paymentAmount); //casting so .value will work
      (<HTMLInputElement>document.getElementById('saveClientPayment')).value = "false"; //casting so .value will work
      // nonceForm.addEventListener('submit', this.onSubmit);
    } else if(path === 'addCard')
      (<HTMLInputElement>document.getElementById('clientId')).value = String(this.client.clientId); //casting so .value will work
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  async saveCardToClient(debug, clientId, nonce, verificationToken, invoiceHash, paymentAmount){
    let newUrl = window.location.href.substring(0, window.location.href.indexOf('?'));
    let url = '';
    if (debug)
      url = sessionStorage.getItem('csAPIBase') + 'cs/payInvoice/' + invoiceHash + '?square-card-nonce=' + nonce + '&amt=' + paymentAmount + '&saveClientPayment=true';
    else
      url = '../../../' + sessionStorage.getItem('csAPIBase') + 'cs/payInvoice/' + invoiceHash + '?square-card-nonce=' + nonce + '&amt=' + paymentAmount + '&saveClientPayment=true';

    fetch(url + '&verificationToken=' + verificationToken, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      }
    })
        .catch(err => {
          alert('Network error: ' + err);
        })
        .then((response: Response) => {
          if (!response.ok) {
            return response.text().then(errorInfo => Promise.reject(errorInfo));
          }
          return response.text();
        })
        .then(data => {
          return 'Success';
        })
        .catch(err => {
            window.location.href = newUrl + '?paying=error';

          //paymentsErrorBox
        });

  }

  async initializeCard(payments) {
    const card = await payments.card();
    await card.attach('#card');
    return card;
  }

}

export class SquareCard{
  card_brand: string;
  cardholder_name: string;
  exp_month: number;
  exp_year: number;
  id: string;
  last_4: string;
}
export class SquareCustomer {
  cards: SquareCard[];
  company_name: string;
  email_address: string;
  family_name: string;
  given_name: string;
  id: string;
  idempotency_key: string;
  phone_number: string;
}
