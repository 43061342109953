import {NgModule} from "@angular/core";
import {PackagesComponent} from "./packages.component";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "../../material.module";
import {UIElementsModule} from "../../ui-elements/ui-elements.module";

@NgModule({
    declarations: [PackagesComponent],
    imports: [CommonModule, MaterialModule, UIElementsModule],
    exports: [PackagesComponent]
})
export class PackagesModule {}