import {AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Coupon, CSFilterParameterBean, preference} from "../../../common-classes/app-objects.model";
import {Subject} from "rxjs";
import {TagsService} from "../../../tags.service";
import {ResponsiveService} from "../../../responsive.service";
import {ReasonsService} from '../../../reasons.service';
import {Subscription} from 'rxjs/index';
import {StoreService} from '../../../store/store.service';

@Component({
  selector: 'coupon-search',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Output() onSearchCoupons = new EventEmitter<string>();
  @Input() preference: preference;
  @Input() CSFilterObj: CSFilterParameterBean;
  @Input() filterMode: number;
  @Input() couponSubscription: Subscription;
  @Input() applyingCouponSubscription: Subscription;
  @Input() couponStatusSubscription: Subscription;
  @Input() couponStatusDetailSubscription: Subscription;
  @Input() outerBoxIdString: string;
  @Input() cartCoupon: boolean;
  @Input() subjectServiceProvider: ReasonsService | StoreService;
  coupon: Coupon;
  applyingCoupon: boolean = false;
  couponStatus: string;
  couponStatusDetail: string;
  couponCode: string;
  constructor(public tagsService: TagsService, private responsiveService: ResponsiveService) { }

  searchCoupons(couponCode: string){
    this.applyingCoupon = true;
    this.onSearchCoupons.emit(couponCode);
  }
  checkEnter(event: KeyboardEvent, couponCode: string){
    if(event.key === 'Enter'){
      this.searchCoupons(couponCode);
    }
  }

  ngAfterViewChecked(): void {
    this.responsiveService.configureHorizontalSearch('couponSearchField', 'couponSearchBtn', this.outerBoxIdString)
  }

  ngOnInit() {
      this.applyingCouponSubscription = this.subjectServiceProvider.applyingCoupon.subscribe((event) => {
        this.applyingCoupon = event;
      })

      this.couponSubscription = this.subjectServiceProvider.coupon.subscribe((event) => {
        this.coupon = event;
      })


      this.couponStatusSubscription = this.subjectServiceProvider.couponStatus.subscribe((event) => {
        this.couponStatus = event;
      })


      this.couponStatusDetailSubscription = this.subjectServiceProvider.couponStatusDetail.subscribe((event) => {
        switch (event) {
          case 'NO_CODE_ENTERED':
            this.couponStatusDetail = this.tagsService.assignObjectToTags(this.coupon, this.preference.labelMap.servicePnlCouponErrorNoCode, this.tagsService.couponFieldTags);
            break;
          case 'NO_COUPON_FOUND':
          case 'INVALID_COUPONCODE':
            this.coupon = new Coupon(true, this.preference.businessId, this.couponCode, null, null, null, null, null)
            this.couponStatusDetail = this.tagsService.assignObjectToTags(this.coupon, this.preference.labelMap.servicePnlCouponErrorNoCouponFound, this.tagsService.couponFieldTags);
            break;
          case 'EXPIRED':
            this.couponStatusDetail = this.tagsService.assignObjectToTags(this.coupon, this.preference.labelMap.servicePnlCouponErrorExpired, this.tagsService.couponFieldTags);
            break;
          case 'MAX_USE_REACHED':
            this.couponStatusDetail = this.tagsService.assignObjectToTags(this.coupon, this.preference.labelMap.servicePnlCouponErrorMaxUseReached, this.tagsService.couponFieldTags);
            break;
          case 'NO_REASONS_DISCOUNTED':
            this.couponStatusDetail = this.tagsService.assignObjectToTags(this.coupon, this.preference.labelMap.servicePnlCouponErrorNoReasonsDiscounted, this.tagsService.couponFieldTags);
            break;
          case 'INVALID_REASONID':
            this.couponStatusDetail = this.tagsService.assignObjectToTags(this.coupon, this.preference.labelMap.cartCouponCodeReasonSpecific, this.tagsService.couponFieldTags);
            break;
          default:
            let indexForCount = event.indexOf(':');
            let appliedReasonCount = event.substring(indexForCount + 1);
            this.coupon.appliedReasonCount = Number(appliedReasonCount);
            this.couponStatusDetail = this.tagsService.assignObjectToTags(this.coupon, this.preference.labelMap.servicePnlCouponSuccess, this.tagsService.couponFieldTags);
        }
      })

  }

  ngOnDestroy() {
    if (this.couponSubscription)
      this.couponSubscription.unsubscribe();
    if (this.applyingCouponSubscription)
      this.applyingCouponSubscription.unsubscribe();
    if (this.couponStatusSubscription)
      this.couponStatusSubscription.unsubscribe();
    if (this.couponStatusDetailSubscription)
      this.couponStatusDetailSubscription.unsubscribe();
  }




}
