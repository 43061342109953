import {ConfigListMap, preference} from "../common-classes/app-objects.model";
import {TitleCasePipe} from "@angular/common";
import {OnInit} from "@angular/core";

export class DutchLabelMap implements OnInit{
    hourLabel: string = 'Uur';
    hoursLabel: string = 'Uren';
    minuteLabel: string = 'Minuut';
    minutesLabel: string = 'Minuten';

    constructor(private configListLabels: ConfigListMap,
                private titleCasePipe: TitleCasePipe,
                private cancelHrs: number,
                private rescheduleHrs: number,
                private waitListFirstAvailable: boolean) {}

    ngOnInit(): void {
        // if(this.rescheduleHrs === 0){
        //     this.apptReschedulePastTimeframeMsg = "Ha pasado el plazo permitido para reprogramar esta cita. La reprogramación está permitida hasta la hora de inicio de la cita.";
        // }
        // if(this.cancelHrs === 0){
        //     this.apptCancelPastTimeframeMsg = "El plazo permitido para cancelar esta cita ha expirado. Se permiten cancelaciones hasta la hora de inicio de la cita.";
        // }

    }

}
