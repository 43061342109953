import {AfterViewChecked, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Invoice, InvoiceItem, preference} from "../../common-classes/app-objects.model";
import {Router} from "@angular/router";
import {TagsService} from "../../tags.service";
import {PackageSold} from "../../store/packages/packages.model";
import {BusinessService} from "../../business.service";
import {ResponsiveService} from "../../responsive.service";

export class SelectPackageModalData {
  invoice: Invoice;
  preference: preference;
  invoiceItem: InvoiceItem;
  packagesSold: PackageSold[];
}

@Component({
  selector: 'app-select-package',
  templateUrl: './select-package.component.html',
  styleUrls: ['./select-package.component.css']
})
export class SelectPackageComponent implements OnInit, AfterViewChecked {
  invoice: Invoice;
  preference: preference;
  invoiceItem: InvoiceItem;
  packagesSold: PackageSold[] = [];
  title: string;
  description: string;
  packageSelected: boolean = false;
  selectedPackage: PackageSold;
  updatingInvoice: boolean = false;
  errorUpdating: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private passedData: SelectPackageModalData, private businessService: BusinessService, private responsiveService: ResponsiveService,
              private router: Router, public dialogRef: MatDialogRef<SelectPackageComponent>, private tagsService: TagsService) {
    dialogRef.backdropClick().subscribe(() =>{
      let dataToPass = {invoice: this.invoice, packageSold: this.selectedPackage}
      this.dialogRef.close(dataToPass);
    })
  }

  confirmPackage(){
    this.updatingInvoice = true;
    this.errorUpdating = false;
    this.businessService.updateInvoiceLineItemWithPackage(this.invoiceItem, this.selectedPackage).subscribe((responseData: {invoice: Invoice, packageSold: PackageSold}) => {
      this.updatingInvoice = false;
      this.invoice = responseData.invoice;
      this.selectedPackage = responseData.packageSold;
      this.dialogRef.close(responseData);
    }, error => {
      this.updatingInvoice = false;
      this.errorUpdating = true;
    })
  }

  selectPackage(){
    if (this.selectedPackage) {
      this.packageSelected = true;
      this.title = this.preference.labelMap.selectPackageConfirmTitle;
      this.description = this.tagsService.assignObjectToTags(this.invoiceItem, this.preference.labelMap.selectPackageConfirmDescription, this.tagsService.invoiceItemFieldTags);
      this.description = this.tagsService.assignObjectToTags(this.selectedPackage.pkg, this.description, this.tagsService.packageFieldTags);
      this.description = this.tagsService.assignObjectToTags(this.selectedPackage, this.description, this.tagsService.packageSoldFieldTags);
    }
  }

  organizePackagesSold(packagesSold: PackageSold[]){
    for(let i = 0, x = packagesSold.length; i < x; i++){
      if(packagesSold[i].pkg.serviceIds.indexOf(this.invoiceItem.reasonId) !== -1){
        if(packagesSold[i].remainingQuantity === undefined || packagesSold[i].remainingQuantity === null)
          packagesSold[i].remainingQuantity = packagesSold[i].maxQuantity - packagesSold[i].usedQuantity;
        packagesSold[i].dropdownDescription = this.tagsService.assignObjectToTags(packagesSold[i].pkg, this.preference.labelMap.selectPackageDropdownDescription, this.tagsService.packageFieldTags);
        packagesSold[i].dropdownDescription = this.tagsService.assignObjectToTags(packagesSold[i], packagesSold[i].dropdownDescription, this.tagsService.packageSoldFieldTags);
        if(packagesSold[i].client !== undefined && packagesSold[i].client !== null && packagesSold[i].client.clientId !== this.invoiceItem.clientId)
          packagesSold[i].dropdownDescription = packagesSold[i].client.fullName + "'s " + packagesSold[i].dropdownDescription;
        this.packagesSold.push(packagesSold[i]);
      }

    }
  }

  ngAfterViewChecked(): void {
    this.responsiveService.checkMatOptionWidth(".selectPackageDropdown","#selectPackagesModal");
  }

  ngOnInit() {
    this.invoice = this.passedData.invoice;
    this.preference = this.passedData.preference;
    this.invoiceItem = this.passedData.invoiceItem;
    this.organizePackagesSold(this.passedData.packagesSold);
    this.title = this.preference.labelMap.selectPackageTitle;
    this.description = this.tagsService.assignObjectToTags(this.invoice, this.preference.labelMap.selectPackageDescription, this.tagsService.invoiceFieldTags)

  }

}
