import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {BusinessService} from "../../../business.service";
import {Client, ClientAccount, miniWebsite, preference, schedulerLink} from "../../../common-classes/app-objects.model";
import {ClientAccountService} from "../../client-account.service";
import {PackageSold} from "../../../store/packages/packages.model";
import {ActivatedRoute, Router} from "@angular/router";
import {TagsService} from "../../../tags.service";
import {SquareCustomer} from "../../view-invoices/payment-portals/square/square.component";
import {ResponsiveService} from "../../../responsive.service";
import {EmailSettingsService} from '../../../email-settings/email-settings.service';
import {SchedulerPreferenceService} from '../../../scheduler-preference.service';
import {StripeCardResponse} from '../../../../models/Stripe/Stripe.model';
export class viewObject {
  label: string;
  value: string;
}
export class StoredCardInfo {
  stripe: StripeCardResponse;
  square: SquareCustomer;
  defaultGateway: string;
  stripeSetup: boolean;
  squareSetup: boolean;
  lumaPaySetup: boolean;
}

@Component({
  selector: 'app-client-profile-container',
  templateUrl: './client-profile-container.component.html',
  styleUrls: ['../client-profile.component.css']
})
export class ClientProfileContainerComponent implements OnInit, AfterViewChecked {
  preference: preference;
  clientAccount: ClientAccount;
  selectedClient: Client;
  availableViews: viewObject[] = [{label: "Details", value: "details"}];
  packageView: viewObject = {label: "Packages", value: "packages"};
  creditCardView: viewObject = {label: "Payment Info", value: "paymentInfo"};
  disclaimerFormsView: viewObject = {label: "Forms", value: "disclaimerForms"};
  emailSettingsView: viewObject = {label: "Email & SMS Settings", value: "emailSettings"};
  selectedView: viewObject;
  clientProfileTitle: string;

  constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private tagsService: TagsService,
              private schedulerPreferenceService: SchedulerPreferenceService,
              private router: Router, private route: ActivatedRoute, private responsiveService: ResponsiveService, private emailSettingsService: EmailSettingsService) {
    this.preference = schedulerPreferenceService.schedulerPreference;
  }

  goBack(){
    this.router.navigate(['../..'], {relativeTo: this.route});
  }

  selectClient(changeEvent: any){
    let client = changeEvent.client;
    let event = changeEvent.event;
    if(client.clientId !== this.selectedClient.clientId && event.isUserInput){
      this.selectedClient = client;
      this.clientAccountService.selectedClient = this.selectedClient;
      this.clientProfileTitle = this.tagsService.assignClientValuesToTags(this.preference.labelMap.clientProfileTitle, this.selectedClient.fields, this.selectedClient);
    }
  }

  determineViews(){
    this.clientProfileTitle = this.tagsService.assignClientValuesToTags(this.preference.labelMap.clientProfileTitle, this.selectedClient.fields, this.selectedClient);
    if ((this.emailSettingsService.emailCategories && this.emailSettingsService.emailCategories.length > 0) || (this.emailSettingsService.emailTemplates && this.emailSettingsService.emailTemplates.length > 0))
      this.availableViews.push(this.emailSettingsView);
    if(this.selectedClient.formList !== undefined && this.selectedClient.formList !== null && this.selectedClient.formList.length > 0)
      this.availableViews.push(this.disclaimerFormsView);
    if(this.selectedClient.packageSoldList !== undefined && this.selectedClient.packageSoldList.length > 0){
      this.availableViews.push(this.packageView);
    }
    if(this.selectedClient.showPaymentInfo)
      this.availableViews.push(this.creditCardView);
    if(history.state !== undefined && history.state !== null){
      if(history.state.showPackages !== undefined && history.state.showPackages !== null && history.state.showPackages === true){
        let foundPackageView = false;
        for(let i = 0, x = this.availableViews.length; i < x; i++){
          if(this.availableViews[i].value === 'packages'){
            foundPackageView = true;
            this.selectedView = this.availableViews[i];
          }
        }
        if(!foundPackageView)
          this.selectedView = this.availableViews[0];
      } else if(history.state.showForms !== undefined && history.state.showForms !== null && history.state.showForms === true){
        let foundFormsView = false;
        for(let i = 0, x = this.availableViews.length; i < x; i++){
          if(this.availableViews[i].value === 'disclaimerForms'){
            foundFormsView = true;
            this.selectedView = this.availableViews[i];
          }
        }
        if(!foundFormsView)
          this.selectedView = this.availableViews[0];
      } else
        this.selectedView = this.availableViews[0];
    } else
      this.selectedView = this.availableViews[0];
  }

  checkPaymentInfo(){
    if(this.selectedClient.showPaymentInfo !== undefined){
      this.determineViews();
    } else {
      this.clientAccountService.getStoredPaymentInfoByClientId(this.selectedClient.clientId).subscribe((storedCardInfo: StoredCardInfo) => {
        this.selectedClient = this.clientAccountService.storeCardInfoOnClient(this.selectedClient, storedCardInfo);
        this.determineViews();
      }, error => {
        this.determineViews();
      })
    }
  }

  checkPackages(){
    if(this.selectedClient.packageSoldList !== undefined){
      this.checkPaymentInfo();
    } else {
      this.clientAccountService.getPackagesSoldByClientId(this.selectedClient.clientId).subscribe((packageSoldList: PackageSold[]) => {
        this.selectedClient.packageSoldList = packageSoldList;
        this.checkPaymentInfo();
      }, error => {
        this.checkPaymentInfo();
      })
    }
  }

  ngAfterViewChecked(){
    // this.responsiveService.setHeightForEmbedAccount();
  }

  ngOnInit() {
      this.clientAccount = this.clientAccountService.loggedInClient;
      if(this.clientAccountService.selectedClient !== undefined && this.clientAccountService.selectedClient !== null){
        for(let i = 0, x = this.clientAccount.clientList.length; i < x; i++){
          if(this.clientAccount.clientList[i].clientId === this.clientAccountService.selectedClient.clientId){
            this.selectedClient = this.clientAccount.clientList[i];
          }
        }
      } else {
        this.selectedClient = this.clientAccountService.selectClientBasedOnLoggedInEmail();
        this.clientAccountService.setSelectedClient(this.selectedClient, this.preference.schedulerPreferenceFieldDefnList);
      }
      if(this.selectedClient === undefined && this.clientAccountService.loggedInClient !== undefined){
        this.selectedClient = this.clientAccountService.loggedInClient.clientList[0];
      }
      this.checkPackages();
      // this.determineViews();
  }

}
