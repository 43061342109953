import {Package} from "../packages/packages.model";

export class CartItem {
    id: number = null;
    businessId: number = null;
    type: string = null;
    name: string = null;
    price: number = null;
    quantityOfAppts: number = null;
    expireInDays: number = null;
    taxable: boolean = null;
    imageURL: string = null;
    totalPrice: number = 0;
    quantityInCart: number = null;

    public static readonly TYPE_PKG="PACKAGE"
    constructor() {}

    createCartItem(item: CartItem){
        for(let prop in item){
            if(this.hasOwnProperty(prop)){
                this[prop] = item[prop];
            }
        }
        return this;
    }


    createPackageItem(pkg: Package){
        this.id = pkg.packageId;
        this.type = CartItem.TYPE_PKG;
        this.name = pkg.packageName;
        this.quantityOfAppts = pkg.quantity;
        for(let prop in pkg){
            if(this.hasOwnProperty(prop)){
                this[prop] = pkg[prop];
            }
        }
        return this;
    }

    calculateTotalPrice(){
        this.totalPrice = this.price * this.quantityInCart;
        // localStorage.setItem('cart', JSON.stringify(this));
        return this;
    }
}
export class Cart {
    items: CartItem[] = [];
    subTotalAmount: number = 0;
    coupon: string;
    taxAmount: number = 0;
    taxRate: number = 0;
    invoiceTotalAmount: number = 0;
    discountAmount: number = 0;
    transactionFeeAmount: number = 0;
    constructor() {
    }

    // calculatePrice(){
    //     this.subTotal = 0;
    //     for(let i = 0, x = this.items.length; i < x; i++){
    //         this.subTotal = this.subTotal + this.items[i].totalPrice;
    //     }
    //     this.tax = this.subTotal * (this.taxRate / 100);
    //     this.total = this.subTotal + this.tax;
    //     return this;
    // }
}
