import {SingleTimeSlot} from '../app/scheduler/time/time-objects.model';
import {ClassSession} from './ClassSession.model';
import {Reason} from './Reason.model';
import {Staff} from './Staff.model';
import {Location} from './Location.model';

export class SelectedDateTimeObj {
    startTime: number;
    clientStartTime: number;
    clientStartTimeForDisplay: number;
    clientStartTimeDate: Date;
    endTime: number;
    clientEndTime: number;
    clientEndTimeForDisplay: number;
    clientEndTimeDate: Date;
    clientStartDate: string | Date;
    clientEndDate: string | Date;
    classScheduleId: number;
    recurringScheduleId: number;
    timeSlotId: string;
    clientTimeSlotId: string;
    htmlId: string;
    hideGuestOption: boolean;
    cartDisplayStartDateTime: Date;
    cartDisplayEndDateTime: Date;
    maxCapacity: number;
    currentCapacity: number;
    reasonId: number;
    professionalId: number;
    locationId: number;
    calTimeSlot: SingleTimeSlot;
    waitListMode: boolean;
    isWaitListFirstAvail: boolean;
    startDateTimeUTC: number | Date;
    endDateTimeUTC: number | Date;

    constructor() {
    }

    createFromClassSession(session: ClassSession, startDate: string, endDate: string, selectedReason: Reason, selectedStaff: Staff, selectedLocation: Location, sessionWaitlistMode: boolean) {
        this.startTime = session.startTime;
        this.clientStartTime = session.startTime;
        this.clientStartTimeForDisplay = session.startTime;
        this.clientStartTimeDate = session.startDateTime || session.startDateTimeUTC;
        this.clientEndTime = session.endTime;
        this.clientEndTimeForDisplay = session.endTime;
        this.endTime = session.endTime;
        this.clientEndTimeDate = session.endDateTime || session.endDateTimeUTC;
        this.clientStartDate = startDate;
        this.clientEndDate = endDate;
        this.classScheduleId = session.classScheduleId;
        this.timeSlotId = String(session.classScheduleId);
        this.clientTimeSlotId = String(session.classScheduleId);
        this.htmlId = String(session.classScheduleId);
        this.recurringScheduleId = session.recurringScheduleId;
        this.hideGuestOption = session.hideGuestOption;
        this.cartDisplayStartDateTime = session.startDateTimeUTC;
        this.cartDisplayEndDateTime = session.endDateTimeUTC;
        this.maxCapacity = session.maxCapacity;
        this.currentCapacity = session.currentCapacity;
        this.reasonId = selectedReason.reasonId;
        this.professionalId = selectedStaff.professionalId || session.professionalId;
        this.locationId = selectedLocation.locationId || session.locationId;
        this.waitListMode = sessionWaitlistMode;
        this.isWaitListFirstAvail = false;
        this.startDateTimeUTC = session.startDateTimeUTC;
        this.endDateTimeUTC = session.endDateTimeUTC;
        this.calTimeSlot = null;
    }

    createFromSingleTimeSlot(time: SingleTimeSlot, reason: Reason, staff: Staff, location: Location, labelNoPreference: string) {

        this.calTimeSlot = time;
        this.cartDisplayEndDateTime = time.endTimeDateForTimeslotDisplay;
        this.cartDisplayStartDateTime = time.startTimeDateForTimeslotDisplay;
        this.classScheduleId = time.classScheduleId;
        this.clientEndDate = time.staffEndDate;
        this.clientEndTime = time.endTime;
        this.clientEndTimeDate = time.endTimeDate;
        this.clientEndTimeForDisplay = time.clientEndTime;
        this.clientStartDate = time.staffStartDate;
        this.clientStartTime = time.startTime;
        this.clientStartTimeDate = time.startTimeDate;
        this.clientStartTimeForDisplay = time.clientStartTime;
        this.clientTimeSlotId = time.clientTimeSlotId;
        this.currentCapacity = null;
        this.endDateTimeUTC = time.staffEndDateTimeUTC;
        this.endTime = time.staffEndTime;
        this.hideGuestOption = false;
        this.htmlId = time.htmlId;
        this.isWaitListFirstAvail = time.isWaitListFirstAvail || false;
        this.locationId = null;
        if (location)
            this.locationId = location.locationId || null;
        if(time.locationId && location && !location.locationId)
            this.locationId = time.locationId;
        this.maxCapacity = null;
        this.professionalId = null;
        if (staff)
            this.professionalId = staff.professionalId;
        if(time.professionalId && staff && !staff.professionalId && staff.fullName !== labelNoPreference)
            this.professionalId = time.professionalId;
        this.reasonId = reason.reasonId;
        this.recurringScheduleId = null;
        this.startDateTimeUTC = time.staffStartDateTimeUTC;
        this.startTime = time.staffStartTime;
        this.timeSlotId = time.timeSlotId;
        this.waitListMode = time.waitListMode;
        this.recurringScheduleId = time.recurringScheduleId;
        for (const prop in this)
            if (this[prop] === undefined)
                this[prop] = null;
        return this;
    }
}
