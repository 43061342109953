import {Injectable, Injector} from '@angular/core';
import {ExistingSessionObject, preference} from './common-classes/app-objects.model';
import {ConfigListService} from './config-list.service';
import {LabelMapService} from './common-classes/label-map.service';
import {ClientInfoService} from './scheduler/client-info/client-info.service';
import {ParameterPassedField} from '../models/ParameterPassedField.model';
import {Params} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {CustomField} from '../models/CustomField.model';
import {Business} from '../models/Business.model';
import {TagsService} from './tags.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulerPreferenceService {
  schedulerPreference: preference;
  configListService: ConfigListService;
  labelMapService: LabelMapService;
  clientInfoService: ClientInfoService;
  parameterPassedFields: ParameterPassedField[];
  constructor(private injector: Injector) {
    this.configListService = injector.get(ConfigListService);
    this.labelMapService = injector.get(LabelMapService);
    this.clientInfoService = injector.get(ClientInfoService);
  }

  setSchedulerPreference(preference: preference) {
    this.schedulerPreference = preference;
    this.schedulerPreference.labelMap = this.labelMapService.reconcileLabels(this.schedulerPreference, this.schedulerPreference.labelMap, this.configListService.configList);
    this.schedulerPreference.schedulerPreferenceFieldDefnList = this.clientInfoService.initialConfigurationOfSchedulerFields(this.schedulerPreference.schedulerPreferenceFieldDefnList, this.schedulerPreference);
    for (let i = 0, x = this.schedulerPreference.schedulerPreferenceFieldDefnList.length; i < x; i++) {
      if (this.schedulerPreference.schedulerPreferenceFieldDefnList[i].code === 'emailAddress' &&
          this.schedulerPreference.schedulerPreferenceFieldDefnList[i].validationRegex !== null && this.schedulerPreference.schedulerPreferenceFieldDefnList[i].validationRegex !== '' &&
          this.clientInfoService.isValidRegex(this.schedulerPreference.schedulerPreferenceFieldDefnList[i].validationRegex)) {
        this.schedulerPreference.emailValidationRegex = this.schedulerPreference.schedulerPreferenceFieldDefnList[i].validationRegex
      } else
        this.schedulerPreference.emailValidationRegex = this.clientInfoService.standardEmailValidationRegex;

    }
  }

  clearSchedulerPreference() {
    this.schedulerPreference = null;
  }

  setParametersFromExistingSessionObject(existingSessionObject: ExistingSessionObject) {
    if (existingSessionObject.customFieldMap) {
      let fields: ParameterPassedField[] = [];
      for (let prop in existingSessionObject.customFieldMap) {
        let field: ParameterPassedField = new ParameterPassedField(prop, existingSessionObject.customFieldMap[prop]);
        fields.push(field);
      }
      if (!this.parameterPassedFields || this.parameterPassedFields.length === 0)
        this.parameterPassedFields = fields;
      else {
        for (let i = 0, x = fields.length; i < x; i++){
          let fieldInParameterList = false;
          for (let j = 0, y = this.parameterPassedFields.length; j < y; j++) {
            if (fields[i].tagName === this.parameterPassedFields[j].tagName)
              fieldInParameterList = true;
          }
          if (!fieldInParameterList)
            this.parameterPassedFields.push(fields[i]);
        }
      }
    }
  }

  setParameterPassedFields(params: Params) {
    let fields: ParameterPassedField[] = [];
    for (let prop in params) {
      if (prop.length > 2) {
        const first3Characters = prop.substring(0,3);
        if (first3Characters === 'CF_') {
          const fieldTag = prop.substring(3);
          let field: ParameterPassedField = new ParameterPassedField(fieldTag, params[prop]);
          fields.push(field);
        }
      }
    }
    this.parameterPassedFields = fields;
  }

  setValuesBasedOnParametersPassed (preference: preference, formGroup: FormGroup) {
    if (this.parameterPassedFields && this.parameterPassedFields.length > 0)
      for(let i = 0, x = preference.schedulerPreferenceFieldDefnList.length; i < x; i++)
        for (let j = 0, y = this.parameterPassedFields.length; j < y; j++)
          if (preference.schedulerPreferenceFieldDefnList[i].tagName === this.parameterPassedFields[j].tagName) {
            preference.schedulerPreferenceFieldDefnList[i].value = this.parameterPassedFields[j].value;
            for (let prop in formGroup.controls)
              if (preference.schedulerPreferenceFieldDefnList[i].schedulerPreferenceFieldDefnId === Number(prop) && formGroup.controls[prop] !== undefined)
                formGroup.controls[prop].setValue(this.parameterPassedFields[j].value);
          }
  }

  checkIfFieldInParametersPassed(field: CustomField): boolean {
    let fieldInParametersPassed = false;
    if (this.parameterPassedFields && this.parameterPassedFields.length > 0) {
      for (let i = 0, x = this.parameterPassedFields.length; i < x; i++)
        if (this.parameterPassedFields[i].tagName === field.tagName) {
          fieldInParametersPassed = true;
          break;
        }
    }
    return fieldInParametersPassed;
  }
}
