import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Client, ClientAccount, preference} from "../../../common-classes/app-objects.model";
import {BusinessService} from "../../../business.service";
import {ClientAccountService} from "../../client-account.service";
import {ClientInfoService} from "../../../scheduler/client-info/client-info.service";
import {FormGroup} from "@angular/forms";
import * as _ from "lodash";
import {ResponsiveService} from "../../../responsive.service";
import {SchedulerPanelValues} from '../../../../models/SchedulerPanel.model';
import {SchedulerService} from '../../../scheduler/scheduler.service';

@Component({
  selector: 'client-profile-details',
  templateUrl: './client-profile-details.component.html'
})
export class ClientProfileDetailsComponent implements OnInit, AfterViewChecked, OnChanges {
    @Input() preference: preference;
    @Input() clientAccount: ClientAccount;
    @Input() selectedClient: Client;
    uneditedClient: Client;
    clientInfoForm: FormGroup;
    editing: boolean = false;
    savingClient: boolean = false;
    disableSave: boolean = false;
    errorSavingClient: boolean = false;

    constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private clientInfoService: ClientInfoService,
                private cdRef: ChangeDetectorRef, private responsiveService: ResponsiveService, private schedulerService: SchedulerService) { }


    uploadingFile(event){
        this.disableSave = event;
        this.cdRef.detectChanges();
    }

    enableOrDisableEditing(){
        for(let prop in this.clientInfoForm.controls){
            if(this.editing) {
                for(let i = 0, x = this.selectedClient.fields.length; i < x; i++){
                    if(this.selectedClient.fields[i].schedulerPreferenceFieldDefnId === Number(prop) && this.selectedClient.fields[i].code !== 'emailAddress' && this.selectedClient.fields[i].allowClientEdit){
                        this.clientInfoForm.controls[prop].enable();
                    }
                }
            } else {
                this.clientInfoForm.controls[prop].disable();
            }
        }
    }

    editDetails() {
        this.editing = true;
        this.uneditedClient = _.cloneDeep(this.selectedClient);
        this.enableOrDisableEditing();
    }
    cancelEdits(){
        this.editing = false;
        this.configureFieldsForDisplay(this.uneditedClient);
    }

    configureFieldsForDisplay(uneditedClient?: Client){
        if(uneditedClient !== undefined){
            this.selectedClient = uneditedClient;
        }
        this.selectedClient.fields= this.clientInfoService.initialConfigurationOfSchedulerFields(this.selectedClient.fields);
        this.clientInfoForm = this.schedulerService.getPanelForm(SchedulerPanelValues.clientInfo, this.selectedClient.fields, -1); // TT-6661 06/22/2020 -- setting -1 as reasonId, should make it so that if field are specific to service/classses and are mode CLIENT then should show if they have a value
        this.editing = false;
        this.enableOrDisableEditing();
    }

    saveChanges(){
        if(this.savingClient === false){//to keep client from clicking multiple times on save button
            this.savingClient = true;
            for(let i = 0, x = this.selectedClient.fields.length; i < x; i++) {
                for (let prop in this.clientInfoForm.value) {
                    if(this.selectedClient.fields[i].schedulerPreferenceFieldDefnId === Number(prop)){
                        this.selectedClient.fields[i].value = this.clientInfoForm.value[prop];
                    }
                }
            }
            this.businessService.createUpdateClient(this.selectedClient)
                .subscribe(
                    (updatedClient) => {
                        this.selectedClient = updatedClient;
                        this.clientAccountService.updateClientInClientList(this.selectedClient);
                        this.configureFieldsForDisplay();
                        this.savingClient = false;
                        this.errorSavingClient = false;
                    }, error => {
                        //FAILED TO SAVE CLIENT
                        this.savingClient = false;
                        this.errorSavingClient = true;
                    }
                )
        }

    }

    ngAfterViewChecked(){
        this.responsiveService.setHeightForEmbedAccount();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.selectedClient !== undefined && changes.selectedClient.previousValue !== undefined){
            this.configureFieldsForDisplay();
        }
    }

    ngOnInit() {
        this.configureFieldsForDisplay();
    }

}
