import {Injectable, Injector} from '@angular/core';
import {ExistingSessionObject} from './common-classes/app-objects.model';
import {map} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {TimezoneService} from './scheduler/common-components/timezone/timezone.service';
import {TimeService} from './scheduler/time/time.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  sessionObject: ExistingSessionObject;
  apiService: ApiService;
  timezoneService: TimezoneService;
  constructor(private injector: Injector, private http: HttpClient) {
    this.apiService = injector.get(ApiService);
    this.timezoneService = injector.get(TimezoneService);
  }

  checkValidSession(sessionToken: string) {
    return this.http.get<ExistingSessionObject>(this.apiService.apiUrl + "refreshSession?sessionToken=" + sessionToken, {observe: "body", responseType: 'json'})
        .pipe(
            map((responseData: ExistingSessionObject) => {
              this.sessionObject = responseData;
              if (responseData.parmAuditReferralId)
                sessionStorage.setItem('auditReferralId', responseData.parmAuditReferralId);
              this.timezoneService.getTimezones().subscribe(() => {});
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }
}
