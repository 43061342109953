import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    CSFilterParameterBean, movePanels, preference
} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {ScreeningQuestionComponent} from "../screening-question/screening-question.component";
import {MatDialog} from "@angular/material";
import {ResponsiveService} from "../../responsive.service";
import {Group, GroupTypes} from '../../../models/Group.model';
import {schedulerPanel, SchedulerPanelValues} from '../../../models/SchedulerPanel.model';
import {SchedulerService} from '../scheduler.service';

@Component({
  selector: 'groups-panel',
  templateUrl: './groups.component.html'
})
export class GroupsComponent implements OnInit, OnChanges {
    @Output() onMovePanels = new EventEmitter<movePanels>();
    @Input() preference: preference;
    @Input() panel: schedulerPanel;
    @Input() CSFilterObj: CSFilterParameterBean;
    @Input() directionComingFrom: string;
    groups: Group[] = [];
    showBackButton: boolean = true;
    groupsLoaded: boolean = false;
    groupType: string;
    selectedGroup: Group;
    errorLoading: boolean = false;

    constructor(private businessService: BusinessService, private dialog: MatDialog, private responsiveService: ResponsiveService, private schedulerService: SchedulerService) { }

    loadGroups(){
        this.groupsLoaded = false;
        this.groups = [];
        this.responsiveService.setHeightForEmbedScheduler();

        switch(this.panel.panel){
            case SchedulerPanelValues.locationSuperGroups:
                this.groupType = GroupTypes.LOCATION_GROUP;
                break;
            case SchedulerPanelValues.locationGroups:
                this.groupType = GroupTypes.LOCATION;
                break;
            case SchedulerPanelValues.reasonSuperGroups:
                this.groupType = GroupTypes.SERVICE_GROUP;
                break;
            case SchedulerPanelValues.reasonGroups:
                this.groupType = GroupTypes.SERVICE;
                break;
        }
        this.businessService.getGroups(this.groupType, this.CSFilterObj)
            .subscribe((groups: Group[]) => {
                this.groups = groups;
                if(this.groups.length === 1 && this.directionComingFrom === 'next'){
                    let moveForward = false;
                    if(this.preference.autoAdvanceWhenSingle){
                        moveForward = true;
                    }
                    this.selectObject(this.groups[0], this.preference.autoAdvanceWhenSingle, undefined);
                }
                if(this.panel.formGroup.value.group !== null){
                    let objFound = false;
                    for(let i = 0, x = this.groups.length; i < x; i++){
                        if(this.groups[i].groupId === this.panel.formGroup.value.group.groupId){
                            this.groups[i].selected = true;
                            objFound = true;
                        }
                    }
                    if(!objFound){
                        this.panel.formGroup = this.schedulerService.getPanelForm(this.panel.panel, this.preference.schedulerPreferenceFieldDefnList);
                    }
                }
                this.groupsLoaded = true;
            }, (error)=> {
                this.groupsLoaded = false;
                this.errorLoading = true;
            })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.panel !== undefined && changes.panel.previousValue !== undefined){//TT-6158 -- when going from reason super groups to reason groups, need to register the change and reload groups
            this.loadGroups();
        }
    }

    ngOnInit() {
        this.loadGroups();
    }
    movePanels(direction: string){
        if(this.selectedGroup !== undefined && this.selectedGroup.screeningQuestion !== undefined && this.selectedGroup.screeningQuestion !== null && direction === 'next'){
            const dialogRef = this.dialog.open(ScreeningQuestionComponent,
                {maxWidth: this.responsiveService.getMaxWidthForModals(),
                        data: {screeningQuestion: this.selectedGroup.screeningQuestion},
                        id: 'GroupScreeningQuestionDialog',
                        panelClass: 'screeningQuestionDialog'});
            dialogRef.afterClosed().subscribe(result => {
                if(result === 'pass'){
                    this.onMovePanels.emit({direction: direction, panel: this.panel})
                }
            });
        } else {
            this.onMovePanels.emit({direction: direction, panel: this.panel})
        }
    }

    selectObject(group: Group, moveForward: boolean, event: MouseEvent){
        if(!this.businessService.checkInnerHTMLForHrefClick(event)) {
            //remove selected from all other groups; this is an issue when you initially select a groups that has a screening question; you dismiss that and select another group
            //that requires a screening question and it looks like you've selected two groups
            for (let i = 0, x = this.groups.length; i < x; i++) {
                this.groups[i].selected = false;
            }
            group.selected = true;
            this.selectedGroup = group;
            this.panel.formGroup.patchValue({group: this.selectedGroup});
            switch (this.panel.panel) {
                case 'locationSuperGroups':
                    if (this.CSFilterObj.locationSuperGroupIdList === null && moveForward) {
                        this.movePanels('next');
                    }
                    break;
                case 'locationGroups':
                    if (this.CSFilterObj.locationGroupIdList === null && moveForward) {
                        this.movePanels('next');
                    }
                    break;
                case 'reasonSuperGroups':
                    if (this.CSFilterObj.reasonSuperGroupIdList === null && moveForward) {
                        this.movePanels('next');
                    }
                    break;
                case 'reasonGroups':
                    if (this.CSFilterObj.reasonGroupIdList === null && moveForward) {
                        this.movePanels('next');
                    }
                    break;
            }
        }


    }

}
