import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {WaitList, WaitListDate, WaitListDateTime} from '../../../models/WaitList.model';
import {MatTableDataSource} from '@angular/material';
import {TableColumnDataModel} from '../../common-classes/TableColumnData.model';
import {DataTablesService} from '../../data-tables.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WaitlistService} from '../waitlist.service';
import {JoinWaitListService} from '../join-wait-list/join-wait-list.service';
import {Subscription} from 'rxjs/index';
import {ReasonTypes} from '../../../models/Reason.model';

@Component({
  selector: 'tt-wait-list-registration-detail-table',
  templateUrl: './wait-list-registration-detail-table.component.html',
  styleUrls: ['./wait-list-registration-detail-table.component.css']
})
export class WaitListRegistrationDetailTableComponent implements OnInit, OnDestroy {
  @Input() waitListId: number;
  @Input() waitList: WaitList;
  @Input() onAddNew: boolean = false;
  @Output() onRemoveTime = new EventEmitter<WaitListDate>();
  loading: boolean;
  errorLoading: boolean;
  displayedColumns: string[] = this.waitListsService.getDisplayedColumns();
  columnDefs: TableColumnDataModel[] = this.waitListsService.getColumnDefs();
  elements: WaitListDate[];
  elementList = new MatTableDataSource<WaitListDate>();
  waitListChanged: Subscription;
  constructor(public dataTablesService: DataTablesService,
              private router: Router, private route: ActivatedRoute, private waitListsService: WaitlistService, private joinWaitListService: JoinWaitListService) { }

  checkIsCourseRegistration(): boolean {
    let isCourse = false;
    if (this.waitList && this.waitList.reason && this.waitList.reason.reasonType === ReasonTypes.COURSE)
      isCourse = true;
    return isCourse;
  }

  establishTable(waitListDateList: WaitListDate[]) {
    const isCourse = this.checkIsCourseRegistration();
    this.displayedColumns = this.waitListsService.getDisplayedColumns(isCourse);
    this.elements = waitListDateList;
    this.loading = false;
    this.elementList.data = this.elements;
  }

  removeTime(wld: WaitListDate) {
    if (this.onAddNew) {
      let waitListDateTime: WaitListDateTime;
      let waitListDate: WaitListDate;
      for (let i = 0, x = this.waitList.waitListDateList.length; i < x; i++) {
        if (wld.date === this.waitList.waitListDateList[i].date) {
          waitListDate = this.waitList.waitListDateList[i];
          for (let j = 0, y = this.waitList.waitListDateList[i].waitListDateTimeList.length; j < y; j++)
            if (wld.waitListDateTime.startTime === this.waitList.waitListDateList[i].waitListDateTimeList[j].startTime &&
                wld.waitListDateTime.endTime === this.waitList.waitListDateList[i].waitListDateTimeList[j].endTime)
              waitListDateTime = this.waitList.waitListDateList[i].waitListDateTimeList[j];
        }
      }
      if (waitListDateTime && waitListDate) {
        this.waitListsService.removeTimeSlot(this.waitList, waitListDate, waitListDateTime);
      }
      if(waitListDate && !waitListDateTime) {
        this.waitListsService.removeTimeSlotDate(this.waitList, waitListDate);
      }
      this.joinWaitListService.setCurrentEvent(this.waitList, true);
    }
  }


  ngOnInit(): void {
    this.establishTable(this.waitListsService.createWaitListDateListArrayForTable(this.waitList));
    this.waitListChanged = this.joinWaitListService.currentEventChanged.subscribe((currentEventData: { newEventData: WaitList, oldEventData: WaitList }) => {
      this.waitList = currentEventData.newEventData;
      this.establishTable(this.waitListsService.createWaitListDateListArrayForTable(this.waitList));
    });
  }

  ngOnDestroy() {
    if (this.waitListChanged)
      this.waitListChanged.unsubscribe();
  }

}
