import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './reset-password.component';
import {MaterialModule} from '../../material.module';
import {FormsModule} from '@angular/forms';
import {UIElementsModule} from '../../ui-elements/ui-elements.module';



@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
      UIElementsModule
  ],
  entryComponents: [ResetPasswordComponent],
  exports: [ResetPasswordComponent]
})
export class ResetPasswordModule { }
