import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CSFilterParameterBean, preference} from "../../../common-classes/app-objects.model";
import {TimeZoneCode} from "../../time/time-objects.model";
import {MatOptionSelectionChange} from "@angular/material/core";
import {TimeService} from '../../time/time.service';
import * as _ from 'lodash';
import {TimezoneService} from './timezone.service';
import {SchedulerPreferenceService} from '../../../scheduler-preference.service';

@Component({
  selector: 'timezone-selector',
  templateUrl: './timezone.component.html'
})
export class TimezoneComponent implements OnInit {
  @Input() clientTimeZone: string;
  @Input() showBreak: boolean = true;
  @Output() getMonthDates = new EventEmitter<{path: string, timezoneCode: string, timezoneId: number}>();
  changingTimezones: boolean = false;
  loadingTimezones: boolean = false;
  originalTimezone: string;
  timezones: TimeZoneCode[];
  timeZoneNameForDisplay: string;
  preference: preference;
  loading: boolean = true;

  constructor(private timeService: TimeService, private timezoneService: TimezoneService, private schedulerPreferenceService: SchedulerPreferenceService) {
    this.preference = schedulerPreferenceService.schedulerPreference;
  }

  setTimeZoneDisplayName() {
    this.loading = true;
    if (this.timezoneService.timezones) {
      this.timezones = this.timezoneService.timezones;
      for (let i = 0, x = this.timezones.length; i < x; i++)
        if (this.timezones[i].timeZoneCode === this.clientTimeZone)
          this.timeZoneNameForDisplay = this.timezones[i].timeZoneDisplayName;
      this.loading = false;
    } else {
      const timezonesLoadedSubscription = this.timezoneService.timezonesLoaded.subscribe(() => {
        this.setTimeZoneDisplayName();
        timezonesLoadedSubscription.unsubscribe();
      })
    }
  }

  ngOnInit() {
    this.setTimeZoneDisplayName();
  }
  setOriginalTimezone(){
    this.originalTimezone = _.cloneDeep(this.clientTimeZone);
  }
  toggleChangeTimezone(){
    this.changingTimezones = !this.changingTimezones;
    if(this.changingTimezones){
      this.setOriginalTimezone();
    }
  }
  setTimezone(event: MatOptionSelectionChange, timezone: TimeZoneCode){
    if(event.isUserInput && timezone.timeZoneCode !== this.originalTimezone){
      this.clientTimeZone = timezone.timeZoneCode;
      this.timeService.setClientTimezone(timezone.timeZoneCode);
      this.setOriginalTimezone();
      this.timeZoneNameForDisplay = timezone.timeZoneDisplayName;
      sessionStorage.setItem("timezone", timezone.timeZoneCode);
      //Below was commented out but readding to fix TT-6273
      this.getMonthDates.emit({path: 'timezoneChange', timezoneCode: timezone.timeZoneCode, timezoneId: timezone.timeZoneId})
    }
  }

}
