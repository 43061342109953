import {
  AfterViewChecked,
  Component,
  ElementRef, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {DisclaimerForm, DisclaimerFormData, DisclaimerFormField, FieldValueObject} from "../disclaimer-form.model";
import {DisclaimerFormService} from "../disclaimer-form.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {StyleSheetService} from "../../styleSheetService";
import {DomSanitizer} from "@angular/platform-browser";
import {Client, preference} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormFunctionsService} from "../../form-functions.service";
import * as SignaturePad from 'signature_pad/dist/signature_pad';
import {TagsService} from "../../tags.service";
import {TimeService} from "../../scheduler/time/time.service";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatRadioChange} from "@angular/material/radio";
import {AuthService} from "../../auth/auth.service";
import {ClientAccountService} from "../../client-account/client-account.service";
import {ErrorModalComponent} from "../../modals/error-modal/error-modal.component";
import {ResponsiveService} from "../../responsive.service";
import {MatDialog} from "@angular/material/dialog";
import * as FullStory from '@fullstory/browser';
import {UserVars} from '@fullstory/browser';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {Subject} from 'rxjs/index';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-disclaimer-form-detail',
  templateUrl: './disclaimer-form-detail.component.html',
  styleUrls: ['./disclaimer-form-detail.component.css','../../form-field-styles.component.css']
})
export class DisclaimerFormDetailComponent implements OnInit, AfterViewChecked, OnDestroy {

  sandbox: boolean = false;
  @ViewChild('.signature', {static: true}) el:ElementRef;
  disclaimerFormGroup: FormGroup = new FormGroup({});
  disclaimerFormUUID: string;
  disclaimerFormData: DisclaimerFormData;
  disclaimerFormDataLoading: boolean = false;
  disclaimerFormDataLoaded: boolean = false;
  errorLoadingDisclaimerFormData: boolean = false;
  submittingDisclaimerForm: boolean = false;
  errorSubmittingDisclaimerForm: boolean = false;
  preference: preference;
  showBackButton: boolean = false;
  disclaimerFormTitle: string;
  requiredEmptyCounter: number = 0;
  formSuccessMessage: string;
  signaturePads: {signaturePad: SignaturePad, fieldId: string, required: boolean}[] = [];
  selectedClient: Client;
  toggleOnlyDisplayOnPrint: boolean = false; // LHB 1/7/2021 TT-7225
  fieldSaveSubject = new Subject<any>();
  fieldsToSave: any = {};
  constructor(private disclaimerFormService: DisclaimerFormService, private route: ActivatedRoute, private router: Router, private styleSheetService: StyleSheetService,
              private tagsService: TagsService, private authService: AuthService, private clientAccountService: ClientAccountService, private responsiveService: ResponsiveService,
              private sanitizer: DomSanitizer, private businessService: BusinessService, private formFunctionsService: FormFunctionsService, private timeService: TimeService,  private dialog: MatDialog,
              private schedulerPreferenceService: SchedulerPreferenceService) {
    this.preference = schedulerPreferenceService.schedulerPreference;
    this.sandbox =  businessService.isSandboxAccount;
  }

  saveFieldTrigger(field: DisclaimerFormField) {
    let value = this.getFieldValue(field);
    if (!value)
      value = ' ';
    this.fieldsToSave[field.id] = value;
    this.fieldSaveSubject.next(this.fieldsToSave);
  }

  saveField(fieldsToSave: any) {
    this.disclaimerFormService.saveFields(this.disclaimerFormData, fieldsToSave).subscribe(() => {},
        (error: HttpErrorResponse) => {
            try {
              console.log(error.error);
              console.log(error.message);
            } catch (e) {
              console.log(error)
            }

        });
  }

  // saveField(field: DisclaimerFormField) { // 5/8/2021 TT-7762
    // let value = this.getFieldValue(field);
    // if (!value)
    //   value = ' ';
    // this.disclaimerFormService.saveField(this.disclaimerFormData, field.id, value).subscribe(() => {},
    //     (error: HttpErrorResponse) => {
    //         try {
    //           console.log(error.error);
    //           console.log(error.message);
    //         } catch (e) {
    //           console.log(error)
    //         }
    //
    //     });

  // }

  getFieldValue(field: DisclaimerFormField) {
    let fieldId = field.htmlId;
    let value = this.disclaimerFormGroup.controls[fieldId].value;
    if(value !== null && value !== "" && field.dataType === 'DATE')
      value = this.timeService.getStringDateFromTime(value);
    return value;
  }

  resizeSignatureCanvas(canvas: HTMLCanvasElement, signaturePad: SignaturePad){
    let ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    signaturePad.clear();
  }
  storeSignature(fieldId: string){
    for(let i = 0, x = this.signaturePads.length; i < x; i++) {
      if(this.signaturePads[i].fieldId === fieldId) {
        let dataUrl: string = null;
        if (!this.signaturePads[i].signaturePad.isEmpty()) {// if it was a required signature, check that something has been filled in there and if not, fill in null to respective field value
          dataUrl = this.signaturePads[i].signaturePad.toDataURL("image/svg+xml");
        }
        this.disclaimerFormGroup.controls[fieldId].setValue(dataUrl);
        for (let i = 0, x = this.disclaimerFormData.formFields.length; i < x; i++)
          if (this.disclaimerFormData.formFields[i].htmlId === fieldId)
            this.saveFieldTrigger(this.disclaimerFormData.formFields[i]);
      }
    }
  }

  dataURItoBlob(dataURI, type) {
    // convert base64 to raw binary data held in a string
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    let bb = new Blob([ab], { type: type });
    return bb;
  }

  instantiateSignature(): void{
    setTimeout(() => {
      if (this.disclaimerFormData.formFields)
        for(let i = 0, x = this.disclaimerFormData.formFields.length; i < x; i++){
          if(this.disclaimerFormData.formFields[i].dataType === 'TXT' && this.disclaimerFormData.formFields[i].inputType === 'signature'){
            let fieldId = this.disclaimerFormData.formFields[i].htmlId;
            let canvas = <HTMLCanvasElement>document.getElementById(fieldId);
            if (canvas) {
              let signaturePad = new SignaturePad(canvas, {penColor: 'rgb(0,0,0)', onEnd: () => {this.storeSignature(fieldId)}});
              this.resizeSignatureCanvas(canvas, signaturePad);
              if(this.disclaimerFormData.formFields[i].value !== null){
                signaturePad.fromDataURL(this.disclaimerFormData.formFields[i].value);


                if(this.disclaimerFormData.status === 'SUBMITTED')
                  signaturePad.off();
              }
              this.signaturePads.push({signaturePad: signaturePad, fieldId: fieldId, required: this.disclaimerFormData.formFields[i].required});
            }


          }
        }
    },200);
  }

  goBack(){
    if(this.authService.isAuth() && history.state !== undefined && history.state !== null) {
      if(history.state.onMyProfile !== undefined && history.state.onMyProfile !== null && history.state.onMyProfile === true)
          this.router.navigate(['/my-account/profile'], {relativeTo: this.route.root, state: {showForms: true}})
      else if(history.state.onProfileHome !== undefined && history.state.onProfileHome !== null && history.state.onProfileHome === true)
        this.router.navigate(['/my-account'], {relativeTo: this.route.root})
      else
        this.router.navigate(['scheduler'], {relativeTo: this.route.root});
    } else {
      this.router.navigate(['scheduler'], {relativeTo: this.route.root});
    }
  }

  showRequiredFieldEmptyMessage() {
    let errorMessage: string = this.tagsService.getInvalidFormFieldErrorMsg(this.disclaimerFormData.formFields,
        'htmlId',
        this.disclaimerFormGroup,
        this.preference,
        'disclaimerFormFieldsInvalid');
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      maxWidth: this.responsiveService.getMaxWidthForModals(),
      data: {preference: this.preference, errorType: 'disclaimerFormFieldsInvalid', errorMessage: errorMessage}
    })
  }

  submitDisclaimerForm(){
    this.requiredEmptyCounter = 0;
    let allFormFieldsAreDescriptions = true; // LHB 6/1/2021 TT-7866
    const descriptionFieldTypes = ['description', 'variableDescription']
    for(let i = 0, x = this.disclaimerFormData.formFields.length; i < x; i++){
      let fieldId = this.disclaimerFormData.formFields[i].htmlId;
      if (descriptionFieldTypes.indexOf(this.disclaimerFormData.formFields[i].fieldType) === -1)
        allFormFieldsAreDescriptions = false;
      this.disclaimerFormData.formFields[i].value = this.getFieldValue(this.disclaimerFormData.formFields[i]);
      console.log('this.disclaimerFormData.formFields['+i+']',this.disclaimerFormData.formFields[i]);
      console.log('this.disclaimerFormData.formFields['+i+'].value', this.disclaimerFormData.formFields[i].value)
      if(this.disclaimerFormData.formFields[i].required && (this.disclaimerFormData.formFields[i].value === null || this.disclaimerFormData.formFields[i].value === "")){
        this.disclaimerFormGroup.controls[fieldId].setErrors({incorrect: true});
        this.disclaimerFormGroup.controls[fieldId].markAsTouched();
        this.disclaimerFormData.formFields[i].error = true;
        this.requiredEmptyCounter++;
      } else {
        this.disclaimerFormData.formFields[i].error = false;
      }
    }
    if(this.requiredEmptyCounter === 0){
      this.submittingDisclaimerForm = true;
      this.errorSubmittingDisclaimerForm = false;
      this.disclaimerFormService.saveFormData(this.disclaimerFormData, 'SUBMITTED', allFormFieldsAreDescriptions).subscribe((disclaimerFormData: DisclaimerFormData) => {
        this.submittingDisclaimerForm = false;
        this.disclaimerFormData = disclaimerFormData;
        if(this.selectedClient !== undefined && this.selectedClient !== null && this.selectedClient.formList !== undefined && this.selectedClient.formList !== null && this.selectedClient.formList.length > 0){
          for(let i = 0, x = this.selectedClient.formList.length; i < x; i++){
            if(this.selectedClient.formList[i].disclaimerFormUuid === this.disclaimerFormData.disclaimerFormUuid){
              this.selectedClient.formList[i].status = this.disclaimerFormData.status;
            }
          }
        }
        window.scrollTo(0,0);
        this.organizeFormFields();
      }, (error: HttpErrorResponse) => {
        console.log(error);
        let requiredFieldMissingError = 'REQUIRED_FIELD_MISSING:';
        if (error.error && error.error.message && error.error.message.indexOf(requiredFieldMissingError) !== -1) {
          let errorMessage = error.error.message;
          let errorMessageWithoutPrimary = errorMessage.slice(requiredFieldMissingError.length);
          let indexOfSecondSemiColon = errorMessageWithoutPrimary.indexOf(':');
          let fieldId = errorMessageWithoutPrimary.slice(0, indexOfSecondSemiColon);
          for(let i = 0, x = this.disclaimerFormData.formFields.length; i < x; i++){
            if (this.disclaimerFormData.formFields[i].id === fieldId) {
              let htmlId = this.disclaimerFormData.formFields[i].htmlId;
              this.disclaimerFormGroup.controls[htmlId].setErrors({incorrect: true});
              this.disclaimerFormGroup.controls[htmlId].markAsTouched();
              this.disclaimerFormData.formFields[i].error = true;
            }
          }
          this.showRequiredFieldEmptyMessage();
          this.submittingDisclaimerForm = false;
          this.errorSubmittingDisclaimerForm = true;
        } else {
          window.scrollTo(0, 0);
          this.submittingDisclaimerForm = false;
          this.errorSubmittingDisclaimerForm = true;
        }
      })
    } else {
        this.showRequiredFieldEmptyMessage();

    }
  }

  setMultiCheckboxValue(field: DisclaimerFormField, dropdownValue: FieldValueObject, event: MatCheckboxChange){
    field.value = this.formFunctionsService.setMultiCheckboxValue(field.value, dropdownValue.label, event);
    this.disclaimerFormGroup.controls[field.htmlId].setValue(field.value);
    this.saveFieldTrigger(field);
  }

  setRadioValue(field: DisclaimerFormField, dropdownValue: FieldValueObject, event: MatRadioChange){
    field.value = dropdownValue.label;
    this.disclaimerFormGroup.controls[field.htmlId].setValue(field.value);
    this.saveFieldTrigger(field);
  }

  renderSubmittedSignature(){
    if(this.disclaimerFormData.status === 'SUBMITTED' && !this.disclaimerFormData.disclaimerForm.hideSubmittedForms && this.disclaimerFormData.formFields){
      for(let i = 0, y: number = this.disclaimerFormData.formFields.length; i < y; i++) {
        if (this.disclaimerFormData.formFields[i].dataType === 'TXT' && this.disclaimerFormData.formFields[i].inputType === 'signature' && !this.disclaimerFormData.formFields[i].sigImgAdded) {
          let sigImgValue = this.disclaimerFormData.formFields[i].value;
          if(sigImgValue !== null && sigImgValue.indexOf('http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd') !== -1){ // old signature not new signature pad
            let newStringValue = '';
            for(let j = 0, z = sigImgValue.length; j < z; j++){
              if(sigImgValue === '"'){
                newStringValue = newStringValue + "'";
              } else {
                newStringValue = newStringValue + sigImgValue[j];
              }

            }
            sigImgValue = newStringValue.replace("data:image/svg+xml,<?xml version='1.0' encoding='UTF-8' standalone='no'?><!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'>", '');
            sigImgValue = newStringValue.replace(/\\/g, '');
            sigImgValue = this.sanitizer.bypassSecurityTrustHtml(sigImgValue);
            sigImgValue.changingThisBreaksApplicationSecurity = sigImgValue.changingThisBreaksApplicationSecurity.replace("data:image/svg+xml,", "")
            this.disclaimerFormData.formFields[i].sigImgAdded = true;
            this.disclaimerFormData.formFields[i].sigImgOld = true;
            this.disclaimerFormData.formFields[i].value = sigImgValue;
          } else {
            let imgId = this.disclaimerFormData.formFields[i].htmlId + "img";
            let img = new Image();
            img.src = sigImgValue;
            let imageField = document.getElementById(imgId);
            imageField.appendChild(img);
            this.disclaimerFormData.formFields[i].sigImgAdded = true;
            this.disclaimerFormData.formFields[i].sigImgOld = false;
          }
        }
      }
    }
  }
  ngAfterViewChecked(): void {
    if(this.disclaimerFormData !== undefined)
      this.renderSubmittedSignature();
  }

  organizeFormFields(){
    if (this.disclaimerFormData.formFields) {
      for (let i = 0, x = this.disclaimerFormData.formFields.length; i < x; i++) {
        this.disclaimerFormData.formFields[i].htmlId = 'field' + i;
        if (this.disclaimerFormData.formFields[i].dataType === 'DATE' && this.disclaimerFormData.formFields[i].value !== null && this.disclaimerFormData.formFields[i].value !== "" &&
            typeof this.disclaimerFormData.formFields[i].dataType === 'string') {
          this.disclaimerFormData.formFields[i].value = this.timeService.convertStringDateToObject(this.disclaimerFormData.formFields[i].value, 0);
        }
        if (this.disclaimerFormData.formFields[i].fieldValues !== null && (this.disclaimerFormData.formFields[i].dataType === 'CHECKBOXES' || this.disclaimerFormData.formFields[i].dataType === 'RADIO')) {
          this.disclaimerFormData.formFields[i].fieldValueObjs = [];
          let selectedValuesArray = this.formFunctionsService.getCheckedValues(this.disclaimerFormData.formFields[i].value);
          for (let j = 0, y = this.disclaimerFormData.formFields[i].fieldValues.length; j < y; j++) {
            let fieldValueObj: FieldValueObject = new FieldValueObject();
            fieldValueObj.label = this.disclaimerFormData.formFields[i].fieldValues[j];
            fieldValueObj.label = fieldValueObj.label.trim(); // LHB 07/20/2020 TT-6850
            if (selectedValuesArray.indexOf(fieldValueObj.label) !== -1) {
              fieldValueObj.selected = true;
            } else
              fieldValueObj.selected = false;

            this.disclaimerFormData.formFields[i].fieldValueObjs.push(fieldValueObj);
          }

        }
      }
      let controlsObject = {};
      for (let i = 0, x = this.disclaimerFormData.formFields.length; i < x; i++) {
        let validatorsArray = [];
        if (this.disclaimerFormData.formFields[i].required) {
          validatorsArray.push(Validators.required);
        }
        controlsObject[this.disclaimerFormData.formFields[i].htmlId] = new FormControl({
          value: this.disclaimerFormData.formFields[i].value,
          disabled: this.disclaimerFormData.status === 'SUBMITTED'
        }, validatorsArray);
      }
      this.disclaimerFormGroup = new FormGroup(controlsObject);
    }
    this.disclaimerFormTitle = this.disclaimerFormData.disclaimerForm.formName;
    this.styleSheetService.addStylesheetToHead(this.disclaimerFormData.disclaimerForm.stylesheet);

    this.disclaimerFormDataLoading = false;
    setTimeout(() => {  // LHB 1/7/2021 TT-7225
      this.toggleOnlyDisplayOnPrint = true;
    }, 100)
    this.instantiateSignature();
  }

  determineSuccessMsg(){
    if(this.disclaimerFormData.disclaimerForm.thankYouMsg === null || this.disclaimerFormData.disclaimerForm.thankYouMsg === ""){
      this.formSuccessMessage = this.tagsService.assignObjectToTags(this.disclaimerFormData.disclaimerForm, this.preference.labelMap.disclaimerSuccessMsg, this.tagsService.disclaimerFormTags);
    } else {
      this.formSuccessMessage = this.disclaimerFormData.disclaimerForm.thankYouMsg;
    }
  }

  getDisclaimerFormData(){
    if(this.disclaimerFormUUID !== undefined && this.disclaimerFormUUID !== null && this.disclaimerFormUUID !== '') {
      this.disclaimerFormDataLoading = true;
      this.disclaimerFormService.getDisclaimerFormData(this.disclaimerFormUUID).subscribe((disclaimerFormData: DisclaimerFormData) => {
        this.disclaimerFormData = disclaimerFormData;
        this.disclaimerFormDataLoaded = true;
        this.errorLoadingDisclaimerFormData = false;
        this.determineSuccessMsg();
        this.organizeFormFields();
        this.enableFullStory();
      }, (error: HttpErrorResponse) => {
        this.disclaimerFormDataLoaded = false;
        this.errorLoadingDisclaimerFormData = true;
        this.disclaimerFormDataLoading = false;
      })
    } else {

    }

  }

  enableFullStory() {
    let shouldEnableFullStory = false;
    try {
      shouldEnableFullStory = JSON.parse(sessionStorage.getItem('enableFullStory'));
    } catch (e) {

    }
    if (shouldEnableFullStory) {
      if (!this.authService.fullStoryEnabled) {
        FullStory.init({orgId: '11WBN3'});
        this.authService.fullStoryEnabled = true;
      } else
        FullStory.restart();
      const uid = String(this.disclaimerFormData.client.clientId);
      let disclaimerFormName = '';
      if (this.disclaimerFormData.disclaimerForm && this.disclaimerFormData.disclaimerForm.formName)
        disclaimerFormName = this.disclaimerFormData.disclaimerForm.formName;
      const userDetails: UserVars = {
        displayName: this.disclaimerFormData.client.fullName,
        email: this.disclaimerFormData.client.emailAddress,
        formUUID: this.disclaimerFormData.disclaimerFormUuid,
        businessId: this.disclaimerFormData.businessId,
        formName: disclaimerFormName
      };
      FullStory.identify(uid, userDetails);
    }
  }

  ngOnInit() {
    let disclaimerFormIdString = this.route.params.subscribe(params => {
      this.disclaimerFormUUID = params['disclaimerFormUUID'];
      this.getDisclaimerFormData();
    });
    this.fieldSaveSubject.pipe(debounceTime(500)).subscribe((fieldsToSave: any) => this.saveField(fieldsToSave));
    if(this.authService.isAuth())
      this.selectedClient = this.clientAccountService.setSelectedClientOnComponent(this.preference);
    if(this.authService.isAuth() && history.state !== undefined && history.state !== null &&
        (history.state.onMyProfile !== undefined && history.state.onMyProfile !== null && history.state.onMyProfile === true) ||
        (history.state.onProfileHome !== undefined && history.state.onProfileHome !== null && history.state.onProfileHome === true))
      this.showBackButton = true;

  }

  ngOnDestroy(): void {
    let shouldEnableFullStory = sessionStorage.getItem('enableFullStory');
    try {
      if (shouldEnableFullStory !== null && shouldEnableFullStory !== '') {
        shouldEnableFullStory = JSON.parse(shouldEnableFullStory);
        if (shouldEnableFullStory)
          FullStory.shutdown();
      }
    } catch (e) {
      console.log(e);
    }

  }



}
