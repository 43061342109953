import {Component, OnInit} from '@angular/core';
import {BusinessService} from "../../business.service";
import {WaitlistService} from "../waitlist.service";
import {Client, ClientAccount, MultipleAppts, preference} from "../../common-classes/app-objects.model";
import {ClientAccountService} from "../../client-account/client-account.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TagsService} from "../../tags.service";
import {AuthService} from "../../auth/auth.service";
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorModalComponent} from '../../modals/error-modal/error-modal.component';
import {ResponsiveService} from '../../responsive.service';
import {MatDialog} from '@angular/material/dialog';
import {WaitlistClaim} from '../../../models/WaitList.model';
import {Appointment} from '../../../models/Appointment.model';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {schedulerPanel, SchedulerPanelValues} from '../../../models/SchedulerPanel.model';
import {SchedulerService} from '../../scheduler/scheduler.service';

@Component({
  selector: 'app-claim-waitlist',
  templateUrl: './claim-waitlist.component.html'
})
export class ClaimWaitlistComponent implements OnInit {
  preference: preference;
  clientAccount: ClientAccount = this.clientAccountService.loggedInClient;
  selectedClient: Client;
  clientHash: string;
  calendarId: string;
  waitlistInviteId: string;
  waitlistClaim: WaitlistClaim;
  appt: Appointment;
  multipleAppts: MultipleAppts;
  waitlistClaimLoaded: boolean = false;
  waitlistClaimLoading: boolean = true;
  showBackButton: boolean = false;
  waitlistClaimTitle: string;
  waitlistClaimText: string;
  showWaitlistSuccess: boolean = false;
  finalConfirmationPanel: schedulerPanel;
  claimingAppt: boolean = false;
  constructor(private waitlistService: WaitlistService,
              private businessService: BusinessService,
              private schedulerService: SchedulerService,
              private clientAccountService: ClientAccountService,
              private router: Router,
              private route: ActivatedRoute,
              private tagsService: TagsService,
              private authService: AuthService, private dialog: MatDialog, private responsiveService: ResponsiveService,
              private schedulerPreferenceService: SchedulerPreferenceService) {
      this.preference = schedulerPreferenceService.schedulerPreference;
  }

  goToScheduler(){
      this.router.navigate(['scheduler']);
  }

  showWaitlistClaimCancelled(){
      this.waitlistClaimTitle = this.preference.labelMap.waitlistClaimedApptCancelledTitle;
      if(this.appt !== undefined)
        this.waitlistClaimText =  this.tagsService.convertApptPropertiesToTags('waitlistClaimedApptCancelledText', this.preference, this.appt);
      else
        this.waitlistClaimText = this.preference.labelMap.waitlistClaimedApptCancelledTextNoApptData;
      this.waitlistClaimLoading = false;
      this.waitlistClaimLoaded = true;
  }

  showWaitlistClaimExceedsMaxActiveAppts(){ // LHB 4/20/2021 TT-7689
      const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(), data: {preference: this.preference, errorType: 'maxApptsReached'}})
      this.waitlistClaimLoading = false;
      this.waitlistClaimLoaded = true;
  }

  showWaitlistAlreadyClaimed(){
      this.waitlistClaimTitle = this.preference.labelMap.waitlistAlreadyClaimed;
      this.waitlistClaimText =  this.tagsService.convertWaitListPropertiesToTags('waitlistAlreadyClaimedText', this.preference, null, this.waitlistClaim.waitlist);
      this.waitlistClaimLoading = false;
      this.waitlistClaimLoaded = true;
  }

  showWaitlistClaimSuccess(){
      this.finalConfirmationPanel = new schedulerPanel(SchedulerPanelValues.finalConfirmation, null, false, 10, true, 'thanksTitle', 'thanksInfo','thanksPnlErrorLoading', false)
      this.finalConfirmationPanel.formGroup = this.schedulerService.getPanelForm(this.finalConfirmationPanel.panel, this.preference.schedulerPreferenceFieldDefnList)
      this.showWaitlistSuccess = true;
  }

  showWaitlistClaimSuccessCourseSet(){
      this.finalConfirmationPanel = new schedulerPanel(SchedulerPanelValues.finalConfirmation, null, false, 10, true, 'thanksTitle', 'thanksInfoRepeating','thanksPnlErrorLoading', false)
      this.finalConfirmationPanel.formGroup = this.schedulerService.getPanelForm(this.finalConfirmationPanel.panel, this.preference.schedulerPreferenceFieldDefnList)
      this.showWaitlistSuccess = true;
  }

  getAppt(){
      this.businessService.getApptDetail(this.waitlistClaim.appointment.hashId)
          .subscribe((appt: Appointment) => {
              this.appt = appt;
              if(this.appt.status !== 'CANCELLED'){
                  this.showWaitlistClaimSuccess();
              } else {
                  this.showWaitlistClaimCancelled();
              }

          })
  }

  getCourseSetRegistration() {
      this.businessService.getRepeatingApptDetail(String(this.waitlistClaim.courseSetRegistration.hashId))
          .subscribe((multipleAppts: MultipleAppts) => {
              this.multipleAppts = multipleAppts;
              if (this.multipleAppts.recurringAppointment.status.toUpperCase() !== 'CANCELLED')
                  this.showWaitlistClaimSuccessCourseSet();
              else
                  this.showWaitlistClaimCancelled();
          }, (error: HttpErrorResponse) => {

          })
  }

  claimAppt(){
    this.claimingAppt = true;
    this.waitlistService.claimAppt(this.waitlistInviteId)
        .subscribe((response: {waitlistClaim: WaitlistClaim, appointment: Appointment, courseSetRegistration: MultipleAppts}) => {
          this.waitlistClaim = response.waitlistClaim;
          if(this.waitlistClaim.status === 'SUCCESS'){
              if (response.appointment) {
                  this.appt = response.appointment;
                  this.showWaitlistClaimSuccess();
              } else if (response.courseSetRegistration) {
                  this.multipleAppts = response.courseSetRegistration;
                  this.showWaitlistClaimSuccessCourseSet();
              }
          } else if (this.waitlistClaim.status === 'EXCEEDS_MAX_ACTIVE_APPTS') { // LHB 4/20/2021 TT-7689
              this.showWaitlistClaimExceedsMaxActiveAppts();
          } else {
              this.showWaitlistAlreadyClaimed();
          }
          this.waitlistClaimLoading = false;
          this.waitlistClaimLoaded = true;
          this.claimingAppt = false;
        }, (error: Error) => {
            // console.log(error);
        })
  }

  goBack(){
      if(this.authService.isAuth()) {
          let waitlistId = sessionStorage.getItem("waitlistRegistrationDetailId");
          let waitListDateTimeId = sessionStorage.getItem("waitListDateTimeId");
          if(history.state !== undefined && history.state !== null &&
              history.state.onWaitListRegistrationDetailPage !== undefined &&
              history.state.onWaitListRegistrationDetailPage !== null &&
              history.state.onWaitListRegistrationDetailPage === true &&
              waitlistId !== null &&
              waitListDateTimeId !== null){
              this.router.navigate(['my-account/waitlistRegistration/' + waitlistId, {waitListDateTimeId: waitListDateTimeId}], { relativeTo: this.route.root });
          } else
              this.router.navigate(['../'], { relativeTo: this.route });
      } else {
          this.router.navigate(['../'], { relativeTo: this.route });
      }

  }

  getWaitlistDetail(){
    this.route.params.subscribe(params => {
      this.waitlistInviteId = params["waitlistInviteId"];
      this.clientHash = params["clientHash"];
      if(this.clientHash !== undefined && this.clientHash !== null && this.clientHash !== ""){
          this.showBackButton = true;
      }
    });
    this.waitlistService.getWaitlistClaim(this.waitlistInviteId)
        .subscribe((waitlistClaim: WaitlistClaim) => {
          this.waitlistClaim = waitlistClaim;
          if (this.waitlistClaim.appointment && this.waitlistClaim.waitlist)
            this.waitlistClaim.appointment.client = this.waitlistClaim.waitlist.client;
          if(this.waitlistClaim.status === 'UNCLAIMED'){
            this.waitlistClaimTitle = this.preference.labelMap.waitListAcceptScreenHeader;
            if (this.waitlistClaim.appointment)
                this.waitlistClaimText =  this.tagsService.convertApptPropertiesToTags('waitListAcceptScreenText', this.preference, <Appointment>this.waitlistClaim.appointment);
            else if (this.waitlistClaim.courseSetRegistration)
                this.waitlistClaimText = this.tagsService.convertRepeatingApptPropertiesToTags('waitListAcceptCourseRegistrationScreenText', this.preference, this.waitlistClaim.courseSetRegistration, this.waitlistClaim.courseSetRegistrationAppointments)
            this.waitlistClaimLoading = false;
            this.waitlistClaimLoaded = true;
          } else if(this.waitlistClaim.status === 'PAST_START'){
              this.waitlistClaimTitle = this.preference.labelMap.waitlistPastStartTimeTitle;
              this.waitlistClaimText =  this.tagsService.convertWaitListPropertiesToTags('waitlistPastStartTimeText', this.preference, null, this.waitlistClaim.waitlist);
              this.waitlistClaimLoading = false;
              this.waitlistClaimLoaded = true;
          }else if(this.waitlistClaim.status === 'OUTSIDE_BOOKING_WINDOW'){
              this.waitlistClaimTitle = this.preference.labelMap.waitListOutsideBookingWindowTitle;
              this.waitlistClaimText =  this.tagsService.convertWaitListPropertiesToTags('waitListOutsideBookingWindowMsg', this.preference, null, this.waitlistClaim.waitlist);
              this.waitlistClaimLoading = false;
              this.waitlistClaimLoaded = true;
          } else if(this.waitlistClaim.status === 'CANCELLED'){
              this.showWaitlistClaimCancelled();
          } else if(this.waitlistClaim.status === 'CLAIMED'){
              if(this.waitlistClaim.waitlistId === this.waitlistClaim.waitlist.waitListId){
                  if (this.waitlistClaim.appointment)
                    this.getAppt()
                  else if (this.waitlistClaim.courseSetRegistration)
                    this.getCourseSetRegistration();
              } else {
                  this.showWaitlistAlreadyClaimed();
              }
          }

        }, (error: HttpErrorResponse) => {
            this.waitlistClaimTitle = this.preference.labelMap.waitListAcceptScreenErrorTitle;
            this.waitlistClaimText =  this.preference.labelMap.waitListAcceptScreenErrorText;
            this.waitlistClaimLoading = false;
            this.waitlistClaimLoaded = true;
        })
  }

  ngOnInit() {
    this.selectedClient = this.clientAccountService.setSelectedClientOnComponent(this.preference);
    this.getWaitlistDetail();
  }

}
