import {Component, Inject, OnInit} from '@angular/core';
import {preference} from "../../../common-classes/app-objects.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Reason} from '../../../../models/Reason.model';
import {SchedulerService} from '../../scheduler.service';
import {SelectedDateTimeObj} from '../../../../models/SelectedDateTimeObj.model';

@Component({
  selector: 'time-cart',
  templateUrl: './time-cart.component.html'
})
export class TimeCartComponent implements OnInit {
  timeList: SelectedDateTimeObj[];
  bookingTimeList: SelectedDateTimeObj[];
  waitlistTimeList: SelectedDateTimeObj[];
  timeListToRemove: SelectedDateTimeObj[] = [];
  preference: preference;
  reason: Reason;
  selectedTimesCount: number;
  timezone: string;
  locale = sessionStorage.getItem('locale')

  constructor(private schedulerService: SchedulerService, @Inject(MAT_DIALOG_DATA) private passedData: any, public dialogRef: MatDialogRef<TimeCartComponent>) {
    dialogRef.backdropClick().subscribe(() =>{
      this.dialogRef.close(this.timeListToRemove);
    })
  }

  organizeTimeListSets(){
    this.timeList = this.schedulerService.sortTimeList(this.timeList);
    this.bookingTimeList = [];
    this.waitlistTimeList = [];
    for(let i = 0, x = this.timeList.length; i < x; i++){
      if(this.timeList[i].waitListMode){
        this.waitlistTimeList.push(this.timeList[i]);
      } else {
        this.bookingTimeList.push(this.timeList[i]);
      }
    }

  }

  ngOnInit() {
    this.timeList = this.passedData.timeList;
    this.organizeTimeListSets();
    this.preference = this.passedData.preference;
    this.reason = this.passedData.reason;
    this.selectedTimesCount = this.passedData.selectedTimesCount;
    this.timezone = this.passedData.timezone;
  }

  removeTime(time: SelectedDateTimeObj){
    for(let i = 0, x = this.timeList.length; i < x; i++){
      if((time.timeSlotId !== null && this.timeList[i].timeSlotId === time.timeSlotId) ||
          (time.classScheduleId !== null && this.timeList[i].classScheduleId === time.classScheduleId)){
        this.timeListToRemove.push(this.timeList[i]);
        this.timeList.splice(i, 1);
        this.selectedTimesCount = this.timeList.length;
        this.organizeTimeListSets();
        break;
      }
    }
  }

  closeTimePanelCart(){
    this.dialogRef.close(this.timeListToRemove);
  }
}
