import {Client, RecurringAppointment, RecurringClassSchedule} from '../app/common-classes/app-objects.model';
import {ValueOptionModel} from './ValueOption.model';
import {Group} from './Group.model';
import {Appointment} from './Appointment.model';
import {Reason, ReasonDuration} from './Reason.model';
import {CustomField} from './CustomField.model';
import {Location} from './Location.model';
import {Staff} from './Staff.model';
import {SelectedDateTimeObj} from './SelectedDateTimeObj.model';
import {SingleTimeSlot} from '../app/scheduler/time/time-objects.model';

export enum WaitListRegistrationDateOptionValues {
    FIRST_AVAILABLE = 'FIRST_AVAILABLE',
    DATE_TIME = 'DATE_TIME'
}
export enum WaitListRegistrationTimeOptionValues {
    BOOKED_TIMES = 'BOOKED_TIMES',
    CUSTOM_TIME = 'CUSTOM_TIME',
    ALL_DAY = 'ALL_DAY'
}

export class WaitList {
    waitListId: number;
    businessId: number;
    status: string;
    client: Client;
    duration: ReasonDuration;
    staff: Staff;
    location: Location;
    locationGroup: Group;
    locationSuperGroup: Group;
    reason: Reason;
    reasonGroup: Group;
    reasonSuperGroup: Group;
    classScheduleId: number;
    fields: CustomField[];
    preferredDate: number | Date;
    preferredTimeOfDay: string;
    waitListDateList: WaitListDate[];
    waitListMode?: boolean;
    hashId?: string;
    cancelReason: string;
    preferredDateStart: number | Date;
    preferredDateEnd: number | Date;
    selectedDateTimeFromList: WaitListDateTime;
    startDate: string | Date;
    startDateTimeUTC: number | Date;
    selectedTimes: SelectedDateTimeObj[];
    waitListTZ: string;
    public address?: string;
    constructor() {
    }

    copyPrimaryFieldsFromExistingWaitList(waitList: WaitList) {
        this.waitListId = waitList.waitListId;
        this.businessId = waitList.businessId;
        this.status = waitList.status;
        this.client = waitList.client;
        this.staff = waitList.staff;
        this.location = waitList.location;
        this.reason = waitList.reason;
        this.classScheduleId = waitList.classScheduleId;
        this.fields = waitList.fields;
    }
}

export class WaitListDate {
    active: boolean;
    courseSet: RecurringClassSchedule;
    createdDate: number | Date;
    createdUser: string;
    date: string | number | Date;
    modifiedDate: number | Date;
    modifiedUser: string;
    preferredDate: string | number | Date;
    recurringScheduleId: number;
    waitListDateId: number;
    waitListDateTime: WaitListDateTime; // For display on waitlist registration detail table so don't have to iterate through timelist
    waitListDateTimeList: WaitListDateTime[];
    waitListId: number;
    constructor(date: string | number | Date, waitListDateId?: number, waitListId?: number) {
        this.active = true;
        this.date = date;
        this.preferredDate = date;
        this.waitListDateId = waitListDateId;
        this.waitListId = waitListId;
        this.waitListDateTimeList = [];
    }
    copyPropsFromAPI(waitListDate: WaitListDate) {
        if (waitListDate.courseSet) {
            const repeatingClassSchedule: RecurringClassSchedule = new RecurringClassSchedule();
            repeatingClassSchedule.copyPropsFromAPI(waitListDate.courseSet);
            waitListDate.courseSet = repeatingClassSchedule;
        }
        for (const prop in waitListDate)
            this[prop] = waitListDate[prop];
    }
}

export class WaitListDateTime {
    active: boolean;
    classScheduleId: number;
    clientStartTimeForDisplay: number | Date;
    clientEndTimeForDisplay: number | Date;
    displayTime: string;
    endDateTimeUTC: number | Date;
    endTime: number;
    seq: number;
    startDateTimeUTC: number | Date;
    startTime: number | string;
    timeString: string;
    waitListDateId: number;
    waitListDateTimeId: number;
    constructor(startTime: number | string, endTime: number, waitListDateTimeId?: number, classScheduleId?: number, clientStartTimeForDisplay?: number | Date, clientEndTimeForDisplay?: number | Date) {
        this.startTime = startTime;
        this.endTime = endTime;
        this.waitListDateTimeId = waitListDateTimeId;
        this.classScheduleId = classScheduleId;
        this.clientStartTimeForDisplay = clientStartTimeForDisplay
        this.clientEndTimeForDisplay = clientEndTimeForDisplay;
    }
}

export class WaitlistClaim {
    public waitlist: WaitList;
    public status: string;
    public waitlistId: number;
    public waitListInviteUUId: string;
    public appointment: Appointment;
    public courseSetRegistration: RecurringAppointment;
    public courseSetRegistrationAppointments: Appointment[];
    constructor() {
    }
}

export class WaitlistRegistrationDetail {
    waitlist: WaitList;
    waitlistClaimList: WaitlistClaim[]
}
