import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ConfirmationComponent} from './confirmation.component';
import {FooterModule} from '../common-components/footer/footer.module';
import {MaterialModule} from '../../material.module';
import {TitleBarModule} from '../common-components/title-bar/title-bar.module';
import {PipesModule} from '../../../pipes/pipes.module';



@NgModule({
  declarations: [ConfirmationComponent],
  imports: [
    CommonModule,
      FooterModule,
      MaterialModule,
      TitleBarModule,
    PipesModule
  ],
  exports: [ConfirmationComponent]
})
export class ConfirmationModule { }
