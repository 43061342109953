import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {BusinessService} from "../../../business.service";
import {
    Client, ClientAccount, currency, Invoice, InvoiceItem, PaymentMap, preference
} from "../../../common-classes/app-objects.model";
import {ClientAccountService} from "../../client-account.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MatSort, MatTableDataSource} from "@angular/material";
import {AuthService} from "../../../auth/auth.service";
import {TagsService} from "../../../tags.service";
import * as FileSaver from "file-saver";
import {ResponsiveService} from "../../../responsive.service";
import {MatDialog} from "@angular/material/dialog";
import {SelectPayAmountComponent} from "../../../modals/select-pay-amount/select-pay-amount.component";
import {PackageSold} from "../../../store/packages/packages.model";
import {SelectPackageComponent, SelectPackageModalData} from "../../../modals/select-package/select-package.component";
import {StoredCardInfo} from "../../client-profile/client-profile-container/client-profile-container.component";
import {CreditCard} from "../payment-portals/payments.model";
import {LocaleCurrency} from "../../../../pipes/localecurrency.pipe";
import {Appointment} from '../../../../models/Appointment.model';
import {SchedulerPreferenceService} from '../../../scheduler-preference.service';
import {PromptPackagePayService} from './prompt-package-pay/prompt-package-pay.service';
import {TimeService} from "../../../scheduler/time/time.service";
declare var Square: any;
@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.css']
})
export class InvoiceDetailComponent implements OnInit, AfterViewChecked {
    preference: preference;
    clientAccount: ClientAccount;
    selectedClient: Client;
    currency: currency;
    invoiceHash: string;
    invoice: Invoice;
    invoiceStatusClass: string;
    invoiceStatusChipBaseStyle: string;
    appt: Appointment;
    apptHash: string;
    paymentMap: PaymentMap;
    connectingWithPaymentPortal: boolean = false;
    errorConnectingWithPaymentPortal: boolean = false;
    errorConnectingWithPaymentPortalMsg: string = 'Error! Cannot Connect to Payment Portal';
    invoiceLoaded: boolean = false;
    invoiceLoading: boolean = true;
    enteringPayment: boolean = false;
    paying: boolean = false;
    showPackagePurchaseOption: boolean = false;
    paymentRequired: boolean;
    authorizeRequired: boolean;
    routerNavigationData: any;
    apptConfirmationTextWhenInvoicePaid: string = null;
    detailTitle: string = null;
    payBtnLabel: string = null;
    packagesSold: PackageSold[];
    displayedColumns = ['description', 'rate', 'quantity', 'netAmount'];
    creditCards: CreditCard[] = [];
    columnDefs = [
        {name: 'description', title: 'Description', filter: null},
        {name: 'rate', title: 'Rate', filter: 'currency'},
        {name: 'quantity', title: 'Quantity', filter: null},
        {name: 'netAmount', title: 'Total', filter: 'currency'},
    ]
    invoiceItems = new MatTableDataSource<InvoiceItem>();
    enterNewCard: boolean = false;
    selectedCard: CreditCard;
    submitBtnLabel: string = '';
    submittingBtnLabel: string = '';
    submitFailBtnLabel: string = '';
    chargingCard: boolean = false;
    params: Params;
    paymentAmountPassedViaParam: boolean = false;
    paymentFormHidden: boolean = true;
    private sort: MatSort;


    //Square Tokens
    applicationIdProd: string = 'sq0idp-BUSIYGB-opVjWqDKVijxhA';
    applicationIdTest: string = 'sandbox-sq0idb-W6SeSVjyx2pPBMf0HH-fLA';
    applicationId: string = this.applicationIdProd;
    verificationToken: string = '';


    tipAmountSaved = 0;
    selectedTipRateSaved: string = '0';
    otherTipAmountSaved: string;

    @ViewChild(MatSort, {static: false}) set matSort(ms: MatSort){
        this.sort = ms;
        this.setDataSourceAttributes();
    }
    setDataSourceAttributes() {
        this.invoiceItems.sort = this.sort;
    }
    constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private authService: AuthService, private tagsService: TagsService,
                private route: ActivatedRoute, private router: Router, private localeCurrency: LocaleCurrency, private dialog: MatDialog,
                private promptPackagePayService: PromptPackagePayService,
                private cdRef: ChangeDetectorRef, private responsiveService: ResponsiveService, private schedulerPreferenceService: SchedulerPreferenceService, private timeService: TimeService) {
        this.preference = schedulerPreferenceService.schedulerPreference;
    }

    expandEnterNewCard(){
        if(this.creditCards.length > 0){
            this.enterNewCard = !this.enterNewCard;
        }
    }

    chargeCardOnFile(){
        let cardId: string;
        let cardType: string;
        let verificationToken: string;
        if(this.selectedCard === undefined){
            //have not selected a radio dial button so just return;
            return;
        }

        if(this.selectedCard.stripeInfo !== undefined){
            cardId = this.selectedCard.stripeInfo.id;
            cardType = "STRIPE";
            this.chargingCard = true;

            if(this.selectedTipRateSaved !== 'Custom' && this.selectedTipRateSaved !== '0'){
                var r : number =  +this.selectedTipRateSaved;
                this.tipAmountSaved = ((r /100) * this.invoice.paymentAmount);
            }else if( this.selectedTipRateSaved === 'Custom'){
                // set tipAmount from other textfield
                //  var tempTip = this.otherTipAmount.match('/[+-]?\d+(\.\d+)?/g').join('');
                console.log('Custom ' + this.otherTipAmountSaved);
                this.tipAmountSaved = +this.otherTipAmountSaved;
            }

            console.log("current tip selected " + this.tipAmountSaved);

            let paymentAmount: number = this.invoice.paymentAmount;
            let tempTipRate: string = this.selectedTipRateSaved;
            if(this.tipAmountSaved > 0){
                paymentAmount += this.tipAmountSaved;
                if(this.selectedTipRateSaved !== 'Custom' && this.selectedTipRateSaved !== 'No Tip')
                    tempTipRate = this.selectedTipRateSaved + '%';
            }

            this.businessService.chargeCardOnFile(this.invoice, cardId, cardType, paymentAmount, verificationToken, tempTipRate, this.tipAmountSaved).subscribe((invoice: Invoice) => {
                this.chargingCard = false;
                this.hidePaymentForm(invoice);
            }, error => {
                this.chargingCard = false;
            })
        }
        if(this.selectedCard.squareInfo !== undefined){
            console.log('Square card');
            cardId = this.selectedCard.squareInfo.id;
            cardType = "SQUARE";
            this.getVerificationTokenFromSquare(this.invoice.paymentAmount.toString(), 'USD', cardId, cardType);
            console.log('waited???');
        }

    }

    getVerificationTokenFromSquare(paymentAmount, currencyCode, nonce, cardType ){
        console.log('Getting verification token ' + nonce);
        if(typeof Square !== "undefined") {
            (async () => {
                let payments;
                console.log('setting up payments ' + this.applicationId + ' : ' + this.invoice.squareLocationId);
                if (this.invoice) {
                    payments = Square.payments(
                        this.applicationId, this.invoice.squareLocationId
                    );
                } else {
                    payments = Square.payments(
                        this.applicationId
                    );
                }
                // verify card and return token
            console.log('amt: ' + paymentAmount + ' , currencyCode: ' + currencyCode);
                let verifyBuyerDetails = {amount: paymentAmount, billingContact: {}, currencyCode: currencyCode, intent: 'CHARGE'};

                console.log('Details ' + JSON.stringify(verifyBuyerDetails));
                const verificationResults =
                    await payments.verifyBuyer(
                        nonce,
                        verifyBuyerDetails
                    );
                this.verificationToken = verificationResults.token;
                console.log('Got a verification token ' + this.verificationToken);
            this.chargingCard = true;
            this.businessService.chargeCardOnFile(this.invoice, nonce, cardType, this.invoice.paymentAmount, this.verificationToken, null, null).subscribe((invoice: Invoice) => {
                this.chargingCard = false;
                this.hidePaymentForm(invoice);
            }, error => {
                this.chargingCard = false;
            })
        })().catch(e => {console.log('Thrown in main async ' + e)})
        }
    }

    getButtonLabelsForPaymentForm(){
        let chargeLabel = 'invoiceCreditCardChargeLabel';
        let chargeFailedLabel = 'invoiceCreditCardChargeFailedLabel';
        let chargingLabel = 'invoiceCreditCardChargingLabel';
        if (this.invoice.preAuthPayment && !this.invoice.hasBeenPreAuthed) {
            chargeLabel = 'invoiceCreditCardAuthorizeLabel';
            chargeFailedLabel = 'invoiceCreditCardAuthorizeFailedLabel';
            chargingLabel = 'invoiceCreditCardAuthorizingLabel';
        }
        this.submitBtnLabel = this.tagsService.assignObjectToTags(this.invoice, this.preference.labelMap[chargeLabel], this.tagsService.invoiceFieldTags, this.currency);
        this.submitFailBtnLabel = this.tagsService.assignObjectToTags(this.invoice, this.preference.labelMap[chargeFailedLabel], this.tagsService.invoiceFieldTags, this.currency);
        this.submittingBtnLabel = this.tagsService.assignObjectToTags(this.invoice, this.preference.labelMap[chargingLabel], this.tagsService.invoiceFieldTags, this.currency);
    }

    setErrorConnectingWithPaymentPortal() {
        this.connectingWithPaymentPortal = false;
        this.errorConnectingWithPaymentPortal = true;
        if (this.paymentMap.gateway === 'SQUARE' && !this.paymentMap.map.squareLocationId)
            this.errorConnectingWithPaymentPortalMsg = 'Error! No Default Location assigned in Square Payments Setup';
        else
            this.errorConnectingWithPaymentPortalMsg = 'Error! Cannot Connect to Payment Portal';
        this.paying = false;
        this.invoiceLoaded = true;
        this.invoiceLoading = false;
        setTimeout(() => {
            this.errorConnectingWithPaymentPortal = false;
        }, 5000);
    }

    getPaymentMap(paymentAmount?: number){
        if(this.invoice.status === 'OPEN') {
            this.connectingWithPaymentPortal = true;
            this.errorConnectingWithPaymentPortal = false;
            this.businessService.getInvoicePaymentMap(this.invoice.invoiceId, paymentAmount)
                .subscribe((response: { paymentMap: PaymentMap, storedCardInfo: StoredCardInfo }) => {
                    this.paymentMap = response.paymentMap;
                    if (!this.paymentMap)
                        this.setErrorConnectingWithPaymentPortal();
                    else if (this.paymentMap.gateway === 'SQUARE' && !this.paymentMap.map.squareLocationId) {
                        this.setErrorConnectingWithPaymentPortal();
                    } else {
                        console.log('Gateway' + this.paymentMap.gateway);
                        this.invoice.client = this.clientAccountService.storeCardInfoOnClient(this.invoice.client, response.storedCardInfo);
                        if (this.authService.isAuth() || (this.params && this.params.invoiceHash )) {
                            this.creditCards = this.clientAccountService.createCreditCardsArray(this.invoice.client);
                        } else {
                            this.creditCards = [];
                        }
                        if (this.creditCards.length === 0) {
                            this.enterNewCard = true;
                        } else {
                            this.enterNewCard = false;
                        }
                        this.getButtonLabelsForPaymentForm();
                        if (this.paymentMap.gateway === 'SQUARE' || this.paymentMap.gateway === 'STRIPE' || this.paymentMap.gateway === 'LUMAPAY') {
                            if (this.paymentMap.gateway === 'SQUARE')
                                this.invoice.squareLocationId = this.paymentMap.map.squareLocationId;
                            this.paymentFormHidden = false;
                            this.router.navigate([], {
                                relativeTo: this.route,
                                queryParams: {
                                    paying: true
                                },
                                queryParamsHandling: 'merge',
                                // preserve the existing query params in the route
                            });
                            this.enteringPayment = true;
                            this.connectingWithPaymentPortal = false;
                        } else {
                            let ua = navigator.userAgent.toLowerCase();
                            if (ua.indexOf('safari') != -1) {
                                if (ua.indexOf('chrome') > -1) {
                                    this.businessService.createFormPost(this.paymentMap);
                                } else {
                                    this.businessService.createSafariFormPost(this.paymentMap);
                                }
                            } else {
                                this.businessService.createFormPost(this.paymentMap);
                            }
                            this.connectingWithPaymentPortal = false;
                        }
                        this.invoiceLoaded = true;
                        this.invoiceLoading = false;
                    }

                }, error => {
                    this.setErrorConnectingWithPaymentPortal();
                })
        }
    }

    openChoosePayAmount() {
        // TT-6332 - open modal to ask what they want to pay
        const dialogRef = this.dialog.open(SelectPayAmountComponent,
            {
                maxWidth: this.responsiveService.getMaxWidthForModals(),
                data: {invoice: this.invoice, preference: this.preference, currency: this.currency}
            })
        dialogRef.afterClosed().subscribe((amountType: string) => {
            if(amountType === 'balanceAmount') {
                this.invoice.paymentAmount = this.invoice.balanceAmount;
                this.getPaymentMap(this.invoice.balanceAmount);
            } else if(amountType === 'paymentAmount')
                this.getPaymentMap();
        })
    }

    payInvoice(){
        const invoiceItems = this.invoiceItems.data;
        let showPackageOptionCounter = 0;
        for (let i = 0, x = invoiceItems.length; i < x; i++)
            if (invoiceItems[i].showPackagePurchaseOption)
                showPackageOptionCounter += 1;
        let choosePayAmount = this.invoice.status === 'OPEN' && this.invoice.paymentAmount < this.invoice.balanceAmount && !this.paymentAmountPassedViaParam && this.invoice.allowChoosePayAmount;
        if (this.invoice.status === 'OPEN' && showPackageOptionCounter > 0) {
            this.promptPackagePayService.openModal(showPackageOptionCounter, this.invoice);
            const promptPackagePayModalClosedSubscription = this.promptPackagePayService.modalClosed.subscribe((payWithPackage: string) => {
                if (payWithPackage === 'package') {
                    for (let i = 0, x = invoiceItems.length; i < x; i++)
                        if (invoiceItems[i].showPackagePurchaseOption) {
                            this.togglePackageSelectorPopup(invoiceItems[i]);
                            break;
                        }
                } else if (choosePayAmount)
                    this.openChoosePayAmount();
                else if (payWithPackage === 'card')
                    this.getPaymentMap(this.invoice.paymentAmount);
                promptPackagePayModalClosedSubscription.unsubscribe();
            })
        } else if(choosePayAmount)
            this.openChoosePayAmount();
        else
            this.getPaymentMap(this.invoice.paymentAmount);

    }

    renderInvoiceAfterPayment(invoice: Invoice){
        this.paying = false;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                paying: false
            },
            queryParamsHandling: 'merge',
            // preserve the existing query params in the route
        })
        for(let prop in invoice){
            this.invoice[prop] = invoice[prop];
        }
        if(this.apptHash !== undefined && this.apptHash !== null){
            if((this.paymentRequired && (this.invoice.status === 'CLOSED' || this.invoice.subStatus === 'Partial Payment')) ||
                (this.authorizeRequired && this.invoice.subStatus === 'Authorized') ||
                (!this.paymentRequired && !this.authorizeRequired)) {
                this.businessService.getApptDetail(this.apptHash).subscribe((appt: Appointment) => {
                    this.appt = appt;
                    if(this.appt.status === 'OPEN'){
                        this.apptConfirmationTextWhenInvoicePaid = this.tagsService.convertApptPropertiesToTags('thanksInfo', this.preference, this.appt);
                    } else if(this.appt.status === 'PENDING_CONFIRMATION'){
                        this.apptConfirmationTextWhenInvoicePaid = this.tagsService.convertApptPropertiesToTags('thanksInfoConfirm', this.preference, this.appt);
                    }
                })
            }
        }
        if(this.invoice.invoiceItem && this.selectedClient) {
            for (let i = 0, x = this.invoice.invoiceItem.length; i < x; i++) {
                if(this.invoice.invoiceItem[i].packageSoldId !== null){
                    this.selectedClient.packageSoldList = undefined;
                }
            }
        }
        this.invoiceLoaded = false;
        this.invoiceLoading = true;
        this.configureInvoiceDetail();
        this.cdRef.detectChanges();
        this.enteringPayment = false;
    }

    hidePaymentForm(invoice: Invoice){
        this.paymentFormHidden = true;
        if(!invoice){
            this.getInvoiceDetail('loadAfterPayment');
        } else {
            this.renderInvoiceAfterPayment(invoice);
        }

    }
    printInvoice(){
        this.businessService.printInvoice(this.invoice.invoiceHash)
            .subscribe(invoice => {
                FileSaver.saveAs(invoice, this.invoice.invoiceHash);
            })
    }
    goBack(){
        if(history.state !== undefined && history.state !== null && history.state.fromCart !== undefined && history.state.fromCart !== null && history.state.fromCart === true){
            this.router.navigate(['/cart'], {relativeTo: this.route.root})
        } else if(this.authService.isAuth()) {
            this.router.navigate(['my-account/invoices'], {relativeTo: this.route.root})
            // this.router.navigate(['../../invoices'], {relativeTo: this.route});
        } else {
            this.router.navigate(['../../scheduler'], {relativeTo: this.route});
        }
    }
    getProperty = (obj, path) => (
        path.split('.').reduce((o, p) => o && o[p], obj)
    );

    getCurrencyDisplay(moneyValue: number){
        return this.localeCurrency.transform(moneyValue,this.currency);
    }

    getFilterDisplay = function(element, col){
        let returnStringForDisplay = '';
        switch(col.filter){
            case 'currency':
                returnStringForDisplay = this.getCurrencyDisplay(element[col.name]);
                break;
        }
        return returnStringForDisplay;

    };

    togglePackageSelectorPopup(invoiceItem: InvoiceItem){
        let dataToPass: SelectPackageModalData = {invoice: this.invoice, preference: this.preference, invoiceItem: invoiceItem, packagesSold: this.packagesSold}
        const dialogRef = this.dialog.open(SelectPackageComponent,
            {
                maxWidth: this.responsiveService.getMaxWidthForModals(),
                minWidth: this.responsiveService.getMaxWidthForModals(),
                data: dataToPass,
                id: "selectPackagesModal"
            })
        dialogRef.afterClosed().subscribe((updatedData: {invoice: Invoice, packageSold: PackageSold}) => {
            if(updatedData){
                if(updatedData.packageSold) {
                    for(let i = 0, x = this.packagesSold.length; i < x; i++){
                        if(this.packagesSold[i].packageSoldId === updatedData.packageSold.packageSoldId)
                            this.packagesSold[i] = updatedData.packageSold;
                    }
                }
                if(updatedData.invoice !== undefined && updatedData.invoice !== null) {
                    this.invoice = updatedData.invoice;
                    this.organizePackagesSold();
                }
            }
        })
    }

    configureInvoiceDetail(){
        this.invoiceItems.data = this.invoice.invoiceItem;
        this.invoiceItems.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
        this.invoiceItems.sort = this.sort;
        this.detailTitle = this.preference.labelMap.invoiceDetailTitle;
        if(this.params["paymentAmount"] !== undefined && this.params["paymentAmount"] !== null){
            let paymentAmtParam = Number(this.params["paymentAmount"]);
            if(!isNaN(paymentAmtParam)){
                this.invoice.paymentAmount = paymentAmtParam;
                this.paymentAmountPassedViaParam = true;
            }
        }
        switch (this.invoice.status) {
            case 'OPEN':
                this.invoiceStatusClass = 'invoiceStatusOpenChip';
                this.invoiceStatusChipBaseStyle = 'primary';
                break;
            case 'CLOSED':
                this.invoiceStatusClass = 'invoiceStatusClosedChip';
                this.invoiceStatusChipBaseStyle = 'success';
                break;
            case 'VOID':
                this.invoiceStatusClass = 'invoiceStatusVoidChip';
                this.invoiceStatusChipBaseStyle = 'warning';
                break;
            default:
                this.invoiceStatusClass = 'invoiceStatusDefaultChip';
                this.invoiceStatusChipBaseStyle = 'default';
        }
        if(this.invoice.invoiceType === 'QUOTE'){
            this.detailTitle = this.preference.labelMap.invoiceQuoteDetailTitle;
        }
        if(this.invoice.preAuthPayment && !this.invoice.hasBeenPreAuthed){
            this.payBtnLabel = this.preference.labelMap.authorizeCCBtnLabel;
        } else {
            this.payBtnLabel = this.preference.labelMap.invoicePayBtnLabel;
        }
        if(this.paying && this.invoice.status === 'OPEN' && this.invoice.balanceAmount > 0){
            this.connectingWithPaymentPortal = true;
            this.payInvoice();
        } else {
            this.paying = false;
            this.invoiceLoaded = true;
            this.invoiceLoading = false;
        }
    }

    organizePackagesSold(){
        this.showPackagePurchaseOption = false;
        for(let i = 0, x = this.packagesSold.length; i < x; i++){
            for(let j = 0, y = this.invoice.invoiceItem.length; j < y; j++){
                if(this.invoice.invoiceItem[j].netAmount > 0 && this.invoice.invoiceItem[j].reasonId &&
                    this.packagesSold[i].status === 'OPEN' && this.packagesSold[i].pkg.serviceIds.indexOf(this.invoice.invoiceItem[j].reasonId) !== -1){
                    this.invoice.invoiceItem[j].showPackagePurchaseOption = true;
                    this.showPackagePurchaseOption = true;
                }
            }
        }
        if(this.showPackagePurchaseOption && this.displayedColumns.indexOf("showPackagePurchaseOption") === -1){
            this.displayedColumns.splice(3, 0, "showPackagePurchaseOption");
            this.columnDefs.splice(3, 0, {name: 'showPackagePurchaseOption', title: 'Package', filter: null})
        } else if(!this.showPackagePurchaseOption && this.displayedColumns.indexOf("showPackagePurchaseOption") !== -1){
            for(let i = 0, x = this.displayedColumns.length; i < x; i++){
                if(this.displayedColumns[i] === 'showPackagePurchaseOption'){
                    this.displayedColumns.splice(i, 1);
                    break;
                }
            }
            for(let i = 0, x = this.columnDefs.length; i < x; i++){
                if(this.columnDefs[i].name === 'showPackagePurchaseOption'){
                    this.columnDefs.splice(i, 1);
                    break;
                }
            }
        }
        if(this.showPackagePurchaseOption)
            this.paying = false;
        this.configureInvoiceDetail();
    }

    checkPackageForPaying(){
        if(this.invoice.status === 'OPEN' && this.invoice.client !== undefined && this.invoice.client !== null){
            if(this.invoice.client.clientAccountId !== undefined && this.invoice.client.clientAccountId !== null){
                this.clientAccountService.getPackageSoldByClientAccountId(this.invoice.client.clientAccountId, 'OPEN').subscribe((packagesSold: PackageSold[]) => {
                    this.packagesSold = packagesSold;
                    this.organizePackagesSold();
                }, error => {
                    this.configureInvoiceDetail();
                })
            } else {
                this.clientAccountService.getPackagesSoldByClientId(this.invoice.client.clientId, 'OPEN').subscribe((packagesSold: PackageSold[])=> {
                    this.packagesSold = packagesSold;
                    this.organizePackagesSold();
                }, error => {
                    this.configureInvoiceDetail();
                })
            }
        } else {
            this.configureInvoiceDetail();
        }
    }

    getInvoiceDetail(path: string){
        this.businessService.getInvoiceDetail(this.invoiceHash)
            .subscribe((invoice: Invoice) => {
                this.invoice = invoice;

                if(path === 'loadAfterPayment'){
                    this.renderInvoiceAfterPayment(this.invoice);
                } else {
                    this.checkPackageForPaying();
                }
            }, (error) => {
                this.goBack();
            })
    }

    loadInvoiceState(){
        let invoiceIdString = this.route.params.subscribe(params => {
            this.invoiceHash = params['invoiceHash'];
        });
        if(history.state !== undefined && history.state !== null){
            if(history.state.invoice !== undefined && history.state.invoice !== null){
                this.invoice = history.state.invoice;
            }
            if(history.state.apptHash !== undefined && history.state.apptHash !== null){
                this.apptHash = history.state.apptHash;
            }
            if(history.state.authorizeRequired !== undefined && history.state.authorizeRequired !== null){
                this.authorizeRequired = history.state.authorizeRequired;
            }
            if(history.state.paymentRequired !== undefined && history.state.paymentRequired !== null){
                this.paymentRequired = history.state.paymentRequired;
            }
        }
        if(this.invoice)
            this.checkPackageForPaying();
        else
            this.getInvoiceDetail('onload');
    }

    setTipsSave(event){
        let tempInvoice: Invoice = new Invoice(this.invoice.businessId, this.invoice.client);
        if(event.value !== 'Custom'){
            tempInvoice.paymentAmount = this.invoice.paymentAmount + ((event.value /100) * this.invoice.paymentAmount);
            this.updateLabelsSaved(tempInvoice);
        }else{
            this.otherTipAmountSaved = '0';
            tempInvoice.paymentAmount = this.invoice.paymentAmount;
            this.updateLabelsSaved(tempInvoice);
        }
    }

    setCustomTipSaved(event){
        let tempInvoice: Invoice = new Invoice(this.invoice.businessId, this.invoice.client);
        console.log(event);
        tempInvoice.paymentAmount = this.invoice.paymentAmount + (+event.value);
        this.updateLabelsSaved(tempInvoice);

    }

    updateLabelsSaved(tempInv){
        let chargeLabel = 'invoiceCreditCardChargeLabel';
        let chargingLabel = 'invoiceCreditCardChargingLabel';
        if (this.invoice.preAuthPayment && !this.invoice.hasBeenPreAuthed) {
            chargeLabel = 'invoiceCreditCardAuthorizeLabel';
            chargingLabel = 'invoiceCreditCardAuthorizingLabel';
        }
        this.submitBtnLabel = this.tagsService.assignObjectToTags(tempInv, this.preference.labelMap[chargeLabel], this.tagsService.invoiceFieldTags, this.currency);
        this.submittingBtnLabel = this.tagsService.assignObjectToTags(tempInv, this.preference.labelMap[chargingLabel], this.tagsService.invoiceFieldTags, this.currency);
    }

    ngAfterViewChecked(){
        this.responsiveService.setHeightForEmbedAccount();
    }

    ngOnInit() {
        this.selectedTipRateSaved= '0';
        this.route.queryParams.subscribe(params => {
            this.params = params;
            let paying = params["paying"];
            if(paying === 'false')
                this.paying = false;
            else if(paying === 'true')
                this.paying = true;

        })
        this.clientAccount = this.clientAccountService.loggedInClient;
        this.currency = this.businessService.currency;
        if(this.clientAccountService.selectedClient !== undefined && this.clientAccountService.selectedClient !== null){
            for(let i = 0, x = this.clientAccount.clientList.length; i < x; i++){
                if(this.clientAccount.clientList[i].clientId === this.clientAccountService.selectedClient.clientId){
                    this.selectedClient = this.clientAccount.clientList[i];
                }
            }
        } else if(this.clientAccount !== undefined && this.clientAccount !== null) {
            this.selectedClient = this.clientAccount.clientList[0];
            this.clientAccountService.setSelectedClient(this.selectedClient, this.preference.schedulerPreferenceFieldDefnList);
        }
        this.loadInvoiceState();

        //Square set up
        if(this.businessService.businessConfigTest){
            this.applicationId = this.applicationIdTest;
        }
    }

}
