import {Client} from "../common-classes/app-objects.model";
import {SafeUrl} from "@angular/platform-browser";
import {Appointment} from '../../models/Appointment.model';

export class ClientFormListObj {
    formName: string;
    disclaimerFormUuid: string;
    disclaimerFormDataUrl: string;
    status: string;
    required: boolean;
    expiresBy: Date;
    dateSent: Date;
    dateSubmitted: Date;
}

export class DisclaimerForm {
    active: boolean;
    alreadySubmittedMsg: string;
    businessId: number;
    createdDate: number;
    createdUser: string;
    disclaimerFormId: number;
    expiresInDays: number;
    formName: string;
    formJson?: any[];
    formFields: DisclaimerFormField[];
    formSetupJson: string;
    formType: string;
    hideSubmittedForms: boolean;
    modifiedDate: number;
    modifiedUser: string;
    required: boolean;
    status?: string;
    stylesheet: string;
    thankYouMsg: string;
    saved?: boolean;
    saving?: boolean;
    emptyRequiredFields?: boolean;
}
export class FieldValueObject {
    label: string;
    selected: boolean;
}
export class DisclaimerFormField {
    dataType: string;
    displayValue: string;
    fieldType: string;
    inputType: string
    required: boolean;
    sortOrder: number;
    value: any;
    htmlId: string;
    label: string;
    cssClasses: string;
    hint: string;
    fieldValues: string[];
    fieldValueObjs: FieldValueObject[];
    sigImgOld: boolean;
    sigImgAdded: boolean;
    error: boolean;
    id: string;

}
export class DisclaimerFormData {
    businessId: number;
    calendar: Appointment;
    client: Client;
    createdDate: number;
    createdUser: string;
    dataJson: string;
    dateSent: number;
    dateSubmitted: number;
    disclaimerFormId: number;
    disclaimerFormUuid: string;
    expiresBy: number;
    ipAddress: string;
    modifiedDate: number;
    modifiedUser: string;
    notes: string;
    status: string;
    waived: boolean;
    disclaimerForm: DisclaimerForm;
    formFields: DisclaimerFormField[]
    //below are extras from initial form app -- not sure if used or not
    formId?: number;
    formName?: string;
    formJson?: any[];
    formSetupJson?: string;
    stylesheet?: string;
    saved?: boolean;
    saving?: boolean;
    emptyRequiredFields?: boolean;
}
