import {AfterViewChecked, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Client, preference} from "../../../../common-classes/app-objects.model";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {TableColumnDataModel} from "../../../../common-classes/TableColumnData.model";
import {DataTablesService} from "../../../../data-tables.service";
import {ResponsiveService} from "../../../../responsive.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TimeService} from "../../../../scheduler/time/time.service";
import {BusinessService} from "../../../../business.service";
import {TagsService} from "../../../../tags.service";
import {TitleCasePipe} from "@angular/common";
import {Appointment} from '../../../../../models/Appointment.model';

@Component({
  selector: 'appointments-table',
  templateUrl: './appointments-table.component.html'
})
export class AppointmentsTableComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() apptData: Appointment[];
  @Input() selectedClient: Client;
  @Input() preference: preference;
  @Input() loaded: boolean;
  @Input() errorLoading: boolean;
  @Input() loading = false;
  @Input() noApptsFoundProp: string;
  @Input() columnToAdd: string;
  idProp = 'calendarId';
  preferenceDefinedColumns: string[];
  displayedColumns = this.dataTablesService.getDefaultApptDisplayedColumns();
  columnDefs: TableColumnDataModel[] = this.dataTablesService.getDefaultApptColumnDefs();
  tableData = new MatTableDataSource<Appointment>();
  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.tableData.sort = this.sort;
  }
  constructor(private dataTablesService: DataTablesService, private responsiveService: ResponsiveService, private router: Router, private route: ActivatedRoute,
              private timeService: TimeService, private businessService: BusinessService, private tagsService: TagsService, private titleCasePipe: TitleCasePipe) { }

  viewDetails(appt: Appointment){
    //After implementing TT-6552 where changed setting of clientStartTimeDate, clientEndTimeDate, staffStartTimeDate, staffEndTimeDate to use tagsService.getTimeWithMomentForClientConvertedTimezone
    // within the getApptsByStatus function, call to navigate to appt detail resulted in Error: Uncaught (in promise): DataCloneError: Failed to execute 'pushState' on 'History'
    // when trying to add appt to state property of navigate below. So to fix, am removing those properties before navigating
    delete appt.clientStartTimeDate;
    delete appt.clientEndTimeDate;
    delete appt.staffStartTimeDate;
    delete appt.staffEndTimeDate;
    let onClientProfile = false;
    let onRepeatingDetail = false;
    if(this.router.url === '/my-account/profile')
      onClientProfile = true;
    if(this.router.url.indexOf('/my-account/repeating') !== -1)
       onRepeatingDetail = true;
    this.router.navigate(['/my-account/appts/' + appt.hashId], {relativeTo: this.route.root, state: {appt: appt, onMyProfile: onClientProfile, onRepeatingDetail: onRepeatingDetail}});
  }
  viewDetailsNonAction(appt: any){
    if(this.responsiveService.onMobileViewPort){
      this.viewDetails(appt);
    }
  }

  organizeApptData(){
    if(this.apptData === null){
      this.apptData = [];
    }
    for(let i = 0, x = this.apptData.length; i < x; i++){
      this.apptData[i] = this.timeService.getApptDateTimeDisplayPropertiesWithTimezone(this.apptData[i], 0 ); // passing allowTZChange as 0 here because the clientUTC times should be defined on appt table
      this.apptData[i].status = this.titleCasePipe.transform(this.apptData[i].status);
      this.apptData[i] = this.dataTablesService.addCustomFieldsToListDataObjects(this.apptData[i], this.selectedClient);
    }
    this.tableData.data = this.apptData;
    this.tableData.sortingDataAccessor = (obj, property) => this.dataTablesService.getProperty(obj, property);
    this.tableData.sort = this.sort;
    this.loaded = true;
    this.loading = false;
    this.errorLoading = false;
  }

  getDisplayedColumns(){
    let columnsForTable = this.dataTablesService.getDisplayedColumns(this.preferenceDefinedColumns, this.columnDefs, this.preference, this.responsiveService.onMobileViewPort, this.columnToAdd);
    this.displayedColumns = columnsForTable.appointmentFieldList;
    this.columnDefs = columnsForTable.columns;
    this.organizeApptData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.apptData !== undefined && changes.apptData.currentValue !== undefined){
        if(!this.preference.appointmentFieldList)
            this.preferenceDefinedColumns = this.dataTablesService.getDefaultApptDisplayedColumns();
        else
            this.preferenceDefinedColumns = this.preference.appointmentFieldList;
        this.getDisplayedColumns();
    }

  }

  ngAfterViewChecked(){
    this.responsiveService.addHorizontalScrollForTableOnMobile();
  }

  ngAfterViewInit() {
    this.fixRowHeaders();
  }

  fixRowHeaders() {
    let rowHeaders: HTMLCollectionOf<HTMLElement>;
    rowHeaders = document.getElementsByClassName('rowHeader') as HTMLCollectionOf<HTMLElement>;
    Array.from(rowHeaders).forEach(header => {
      header.removeAttribute('role');
      header.setAttribute('role','rowheader');
    });
  }

  ngOnInit() {
    if(this.responsiveService.onMobileViewPort){
      for(let i = 0, x = this.columnDefs.length; i < x; i++){
        switch(this.columnDefs[i].name){
          case 'calendarId':
            this.columnDefs[i].title = 'ID';
            break;
          case 'clientStartTimeDate':
            this.columnDefs[i].filterFormats[0].format = 'EEE, MMM d, yy';
            break;
        }
      }
    }


  }

}
