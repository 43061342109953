import {ResponsiveService} from "../../../responsive.service";

export class SelectSchedulingObject {
  changeEvent: any;
  moveForward: boolean;
  itemSelected: any;
}
import {
  AfterViewChecked, AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import {currency, preference} from "../../../common-classes/app-objects.model";

@Component({
  selector: 'scheduler-list-items',
  templateUrl: './scheduler-list-items.component.html',
  styleUrls: ['./scheduler-list-items.component.css']
})
export class SchedulerListItemsComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @Input() listItems: any[];
  @Input() listItemType: string;
  @Input() idProp: string;
  @Input() titleProp: string;
  @Input() descriptionProp: string;
  @Input() imageProp: string;
  @Input() preference: preference;
  @Input() currency: currency;
  @Output() onSelectItem = new EventEmitter<SelectSchedulingObject>();

  constructor(private responsiveService: ResponsiveService, private cdRef: ChangeDetectorRef) { }

  setHeightOnItems(){
    if(this.responsiveService.onMobileViewPort) {
      for (let i = 0, x = this.listItems.length; i < x; i++) {
        this.responsiveService.adjustMatLineItemHeight(this.listItemType, this.listItems[i][this.idProp], this.listItems[i], this.preference, this.imageProp);
      }
      this.cdRef.detectChanges();
    }
  }

  ngAfterViewChecked(){
    this.setHeightOnItems();
  }

  ngAfterViewInit() {
    this.setHeightOnItems();
  }

  ngOnInit() {
    setTimeout(() => {
      this.setHeightOnItems();
    }, 200)
  }

  selectObject(changeEvent: any, moveForward: boolean, itemSelected?: any){
    let selectItem: SelectSchedulingObject = {changeEvent: changeEvent, moveForward: moveForward, itemSelected: itemSelected};
    this.onSelectItem.emit(selectItem);
  }

}
