import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from './footer.component';
import {UIElementsModule} from '../../../ui-elements/ui-elements.module';
import {MaterialModule} from '../../../material.module';



@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
      UIElementsModule,
      MaterialModule
  ],
  exports: [FooterComponent]
})
export class FooterModule { }
