import {
    BaseAlerts,
    BaseButtons, BaseChips,
    BaseFormElements,
    BaseLinks, BaseSpinner,
    CSSProperties, DefaultStyleMap,
    FormElements, MatListElements, MiniWebsiteDisplayConfig, NewToOldStylesMap, SpinnerElement
} from "./common-classes/default-styles.model";
import {miniWebsite, preference} from "./common-classes/app-objects.model";
import {ResponsiveService} from "./responsive.service";

export class StyleSheetService {
    baseButtons: BaseButtons = new BaseButtons();
    baseChips: BaseChips = new BaseChips();
    baseAlerts: BaseAlerts = new BaseAlerts();
    baseLinks: BaseLinks = new BaseLinks();
    baseSpinner: BaseSpinner = new BaseSpinner();
    baseFormElements: BaseFormElements = new BaseFormElements();
    displayConfig: MiniWebsiteDisplayConfig = new MiniWebsiteDisplayConfig();
    mwStyleMap: any = {};
    newStylePropsToMap = new NewToOldStylesMap().map;
    constructor(private responsiveService: ResponsiveService) {}

    addStylesheetToHead(stylesheet: string){
        if(stylesheet !== null) {
            let link = document.createElement( "link" );
            link.href = stylesheet;
            link.type = "text/css";
            link.rel = "stylesheet";
            link.media = "screen,print";
            document.getElementsByTagName( "head" )[0].appendChild( link );
        }
    }

    mapOldColorsToNewStyles(miniWebsiteStyles: any, oldStylesMap: any){
        for(let i = 0, x = this.newStylePropsToMap.length; i < x; i++){
            for(let bcProp in oldStylesMap){
                for(let j = 0, y = this.newStylePropsToMap[i].oldProps.length; j < y; j++){
                    if(bcProp === this.newStylePropsToMap[i].oldProps[j].oldPropName){
                        let newProp = this.newStylePropsToMap[i].newProp;
                        let propAlreadyOnMWS = false;
                        for(let mwProp in miniWebsiteStyles){
                            if(mwProp === newProp) {
                                propAlreadyOnMWS = true;
                                let cssPropsOnMW = new CSSProperties();

                                cssPropsOnMW.setStyles(miniWebsiteStyles[mwProp]);//since array has it as backgroundColor and styleMap has it as background-color

                                if (cssPropsOnMW[this.newStylePropsToMap[i].oldProps[j].newPropProperty] === undefined){
                                    let cssProps = new CSSProperties();
                                    cssProps[this.newStylePropsToMap[i].oldProps[j].newPropProperty]= oldStylesMap[bcProp];
                                    // let styleObject = cssProps.getStylesForStylesheet();
                                    // for(let cssprop in styleObject){
                                    //     miniWebsiteStyles[mwProp][cssprop] = oldStylesMap[bcProp];
                                    // }
                                    for(let cssProp in miniWebsiteStyles[mwProp]){
                                        cssProps[cssProp] = miniWebsiteStyles[mwProp][cssProp];
                                    }
                                    miniWebsiteStyles[mwProp] = cssProps;


                                }
                            }
                        }
                        if(!propAlreadyOnMWS){
                            // let cssProperties = new CSSProperties();
                            // cssProperties[this.newStylePropsToMap[i].oldProps[j].newPropProperty]= oldStylesMap[bcProp];
                            // miniWebsiteStyles[newProp] = cssProperties.getStylesForStylesheet();
                            miniWebsiteStyles[newProp] = new CSSProperties();
                            miniWebsiteStyles[newProp][this.newStylePropsToMap[i].oldProps[j].newPropProperty]= oldStylesMap[bcProp];
                        }

                    }
                }

            }
        }
        return miniWebsiteStyles;
    }


    mapColorsToElements(styleMap: any, baseElements: any){
        for(let el in baseElements){
            for(let styleProp in styleMap){
                if(styleProp === el){
                    baseElements[el].setStyles(styleMap[styleProp]);
                }
            }
        }
    }

    mapPropsToDisplayConfig(styleMap: any, displayConfig: MiniWebsiteDisplayConfig){
        //If they have store set to enabled then packages should inherit from store
        for(let prop in displayConfig){
            if(styleMap[prop] !== undefined && displayConfig[prop].children !== undefined && displayConfig[prop].children !== null && displayConfig[prop].children.length > 0){
                //let's say they have store set on stylemap but not packages which is a child of store
                for(let i = 0, x = displayConfig[prop].children.length; i < x; i++){
                    if(styleMap[displayConfig[prop].children[i]] === undefined){
                        styleMap[displayConfig[prop].children[i]] = styleMap[prop];
                    }
                }
            }
        }
        for(let prop in displayConfig){
            if(styleMap[prop] && styleMap[prop].enable !== undefined){
                displayConfig[prop].enable = styleMap[prop].enable;
            }
        }
        return displayConfig;
    }

    getStyleObject(styleMap: any, formElement: any, styleMapElement: any){
        if(formElement.backgroundSelector !== undefined){
            if(styleMap[formElement.backgroundSelector] === undefined)
                styleMap[formElement.backgroundSelector] = {};
            if(styleMapElement.backgroundColor !== undefined)
                styleMap[formElement.backgroundSelector].backgroundColor = styleMapElement.backgroundColor;
            else
                styleMap[formElement.backgroundSelector].backgroundColor = styleMapElement.color;
        }
        if(formElement.colorSelector !== undefined) {
            if(styleMap[formElement.colorSelector] === undefined)
                styleMap[formElement.colorSelector] = {};
            styleMap[formElement.colorSelector].color = styleMapElement.color;
        }
        if(formElement.textSelector) {
            if(!styleMap[formElement.textSelector])
                styleMap[formElement.textSelector] = {};
            styleMap[formElement.textSelector].fontSize = styleMapElement.fontSize;
        }
        if(formElement.borderSelector !== undefined) {
            if (styleMap[formElement.borderSelector] === undefined)
                styleMap[formElement.borderSelector] = {};
            styleMap[formElement.borderSelector].borderColor = styleMapElement.color;
        }
        if(formElement.dottedUnderlineSelector !== undefined) {
            if (styleMap[formElement.dottedUnderlineSelector] === undefined)
                styleMap[formElement.dottedUnderlineSelector] = {};
            let dottedUnderlineObj = 'linear-gradient(to right, ' + styleMapElement.color + ' 0, ' + styleMapElement.color + ' 33%, #0000 0)';
            styleMap[formElement.dottedUnderlineSelector].backgroundImage = dottedUnderlineObj;
        }
        if(formElement.strokeSelector !== undefined){
            if (styleMap[formElement.strokeSelector] === undefined)
                styleMap[formElement.strokeSelector] = {};
            styleMap[formElement.strokeSelector].stroke = styleMapElement.color;
        }
        return styleMap;
    }

    setDefaultElementsFromStyleMap(styleMap: any, defaultProps: any){

        for(let feProp in defaultProps){
            for(let mwProp in styleMap){
                if(defaultProps[feProp].savedSelectorValue === mwProp){
                    styleMap = this.getStyleObject(styleMap, defaultProps[feProp], styleMap[mwProp]);
                    delete styleMap[mwProp];
                }
            }
        }

        return styleMap;
    }

    mapStyleSheetDefaults(styleMap: any){
        if(!styleMap['.mat-radio-button-tt']){
            styleMap['.mat-radio-button-tt'] = this.baseFormElements.radioDial.getStylesForStylesheet();
            styleMap['.mat-radio-button-tt-print'] = this.baseFormElements.radioDial.getStylesForStylesheet();
        }
        if(!styleMap['.mat-checkbox-tt'])
            styleMap['.mat-checkbox-tt'] = this.baseFormElements.checkBox.getStylesForStylesheet();

        if (!styleMap['mat-form-field.mat-form-field'])
            styleMap['mat-form-field.mat-form-field'] = this.baseFormElements.formFieldGeneral.getStylesForStylesheet();

        if(!styleMap['.mat-form-field'])
            styleMap['.mat-form-field'] = this.baseFormElements.formFieldResting.getStylesForStylesheet();

        if(!styleMap['.mat-form-field.mat-focused'])
            styleMap['.mat-form-field.mat-focused'] = this.baseFormElements.formFieldFocused.getStylesForStylesheet();

        if(!styleMap['.mat-form-field-invalid'])
            styleMap['.mat-form-field-invalid'] = this.baseFormElements.formFieldInvalid.getStylesForStylesheet();

        if(!styleMap['.mat-form-field-print'])
            styleMap['.mat-form-field-print'] = this.baseFormElements.formFieldPrint.getStylesForStylesheet();

        if(!styleMap.body || (styleMap.body && !styleMap.body['loadingSpinner']))
            styleMap['loadingSpinner'] = this.baseSpinner.spinner.getStylesForStylesheet();
        else
            styleMap['loadingSpinner'] = {'color': styleMap.body['loadingSpinner']};
        return this.setDefaultElementsFromStyleMap(styleMap, new FormElements());
    }

    // TT-6476 -- for now since we haven't separated mat-cards into the header, content, and footer elements in the schedulerStyleSheet, take it
    // and apply the mat-card styling to the elements for borders; if come back later and add in the header/content/footer, may not need this
    mapMatCardSubStyles(styleMap: any){
        let propertiesGettingSet: string[] = ['borderBottomColor','borderBottomWidth'];
        let cardProps: {styleMapProp: string, selector: string}[] = [
            {styleMapProp: 'mat-card', selector: 'mat-toolbar.toolbarCardHeader, mat-card-header.toolbarCardHeader, .invoiceBalanceDivider, mat-card-content, mat-list-item'},
            {styleMapProp: 'schedulerBox', selector: '#schedulerBox mat-toolbar.toolbarCardHeader, #schedulerBox mat-card-header.toolbarCardHeader, #schedulerBox mat-card-content, #schedulerBox mat-list-item'},
            {styleMapProp: 'myAccountCard', selector: '#myAccountCard mat-toolbar.toolbarCardHeader, #myAccountCard mat-card-header.toolbarCardHeader, #myAccountCard mat-card-content, #myAccountCard mat-list-item'},
            {styleMapProp: 'businessInfo', selector: '#businessInfo mat-toolbar.toolbarCardHeader, #businessInfo mat-card-header.toolbarCardHeader, #businessInfo mat-list-item'},
            {styleMapProp: 'policy', selector: '#policy mat-toolbar.toolbarCardHeader, #policy mat-card-header.toolbarCardHeader, #policy mat-list-item'},
        ];
        let styleProps = new CSSProperties();
        styleProps.borderBottomColor = '#ebedf2';
        styleProps.borderBottomWidth = '1px';
        if(styleMap['mat-card'] == undefined){
            styleMap['mat-card'] = styleProps;
        }
        // @ts-ignore
        // console.log("styleMap 2 " + JSON.stringify(styleMap, true, 2));
        for(let i = 0, x = cardProps.length; i < x; i++){
            if(styleMap[cardProps[i].styleMapProp] !== undefined){
                let styleObjToSet = {};
                let propCounter = 0;
                for(let prop in styleMap[cardProps[i].styleMapProp]){
                    if(propertiesGettingSet.indexOf(prop) !== -1 && styleMap[cardProps[i].styleMapProp][prop] !== undefined){
                        styleObjToSet[prop] = styleMap[cardProps[i].styleMapProp][prop];
                        propCounter++;
                    }
                }
                if(propCounter > 0)
                    styleMap[cardProps[i].selector] = styleObjToSet;
            }
        }
        return styleMap;
    }

    configureLogoAlignment(miniWebsite: miniWebsite, preference: preference){
        let alignment = 'left';
        if(miniWebsite.styleSheetMap.businessLogo && miniWebsite.styleSheetMap.businessLogo.alignment){
            alignment = miniWebsite.styleSheetMap.businessLogo.alignment;
        } else if(miniWebsite.logoAlign && miniWebsite.logoAlign){
            alignment = miniWebsite.logoAlign;
        }
        //If logo is not displayed at all, then align left
        if(miniWebsite.styleSheetMap.businessLogo &&
            miniWebsite.styleSheetMap.businessLogo.display &&
            miniWebsite.styleSheetMap.businessLogo.display === 'none'){
            alignment = 'left';
            miniWebsite.styleSheetMap['#topNav.topNavRow'] = {'justifyContent': 'flex-end'}
        }
        //left is default so if it is set to left, don't need to do anything
        if(alignment === 'center'){
            let showLogin = true;
            if(preference !== undefined){
                let showBtns = this.responsiveService.showLoginRegisterBtnLogic(preference);
                showLogin = showBtns.showLoginBtn;
            }

            if(!this.responsiveService.onMobileViewPort || !showLogin){
                miniWebsite.styleSheetMap['login-register-btns'] = {float: 'right', position: 'relative', 'top': '-50px'}
                miniWebsite.styleSheetMap['#topNav.topNavRow'] = {'justifyContent': 'center'}
            } else {
                alignment = 'left';
            }
        }
        miniWebsite.logoAlign = alignment;
        return miniWebsite;
    }



    public createStyleSheet(miniWebsite: miniWebsite, preference: preference){
        if(miniWebsite.styleSheetMap === undefined){

            // LHB 04/04/2022 TT-8577 -- reconciling styles that can be set on custom booking site
            let defaultStyleSheetMap = null;
            let schedulerLinkStyleSheetMap = null;
            if(miniWebsite.landingPageStylesJSON !== undefined && miniWebsite.landingPageStylesJSON !== null)
                defaultStyleSheetMap = JSON.parse(miniWebsite.landingPageStylesJSON);
            if(miniWebsite.schedulerLinkLandingPageStylesJson !== undefined && miniWebsite.schedulerLinkLandingPageStylesJson !== null)
                schedulerLinkStyleSheetMap = JSON.parse(miniWebsite.schedulerLinkLandingPageStylesJson);
            if (defaultStyleSheetMap && schedulerLinkStyleSheetMap) {
                for (const prop in schedulerLinkStyleSheetMap) {
                    if (defaultStyleSheetMap[prop]) {
                        for (const cssStyle in schedulerLinkStyleSheetMap[prop]) {
                            if (defaultStyleSheetMap[prop][cssStyle]) {
                                defaultStyleSheetMap[prop][cssStyle] = schedulerLinkStyleSheetMap[prop][cssStyle];
                            }
                        }
                    } else
                        defaultStyleSheetMap[prop] = schedulerLinkStyleSheetMap[prop];

                }
                miniWebsite.styleSheetMap = defaultStyleSheetMap;
            } else if (schedulerLinkStyleSheetMap)
                miniWebsite.styleSheetMap = schedulerLinkStyleSheetMap;
            else  if (defaultStyleSheetMap)
                miniWebsite.styleSheetMap = defaultStyleSheetMap;
            else
                miniWebsite.styleSheetMap = {};

        }
        if(miniWebsite.logoUrl === undefined || miniWebsite.logoUrl === null){
            miniWebsite.logoUrl = 'https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/timetap-logo-black.png';
        }
        //For old data migration, copy what was set in the styles map previously to new format
        // let landingPageStylesMap = new BaseColors();
        if(miniWebsite.landingPageStylesMap === undefined || miniWebsite.landingPageStylesMap === null){
            miniWebsite.landingPageStylesMap = {};
        }
        //check and see if properties that are stored flat on object have been returned in map; if they haven't, write them to the map (likely update hasn't been called in backend)
        let flatPropertiesToWriteToMap = ['businessInfoColor','footerTextColor','footerLinkColor','headerColor','logoAlign','navigationBackgroundColor','websiteBackgroundColor','policyBackgroundColor'];
        for(let i = 0, x = flatPropertiesToWriteToMap.length; i < x; i++){
            if(!miniWebsite.landingPageStylesMap.hasOwnProperty(flatPropertiesToWriteToMap[i])){
                miniWebsite.landingPageStylesMap[flatPropertiesToWriteToMap[i]] = miniWebsite[flatPropertiesToWriteToMap[i]];
            }
        }
        // LHB 03/17/2022 TT-8597 -- with release of color scheme and styles in new back office, removing need to map legacy props to existing
        // miniWebsite.styleSheetMap = this.mapOldColorsToNewStyles(miniWebsite.styleSheetMap, miniWebsite.landingPageStylesMap);


        //Since the form override selectors are kind of complicated, don't want to store them as such on backoffice
        //instead, grab any that are in class FormElements here and replace the selector
        miniWebsite.styleSheetMap = this.mapStyleSheetDefaults(miniWebsite.styleSheetMap);
        //do the same thing with list elements
        miniWebsite.styleSheetMap = this.setDefaultElementsFromStyleMap(miniWebsite.styleSheetMap, new MatListElements());
        //same thing with spinner
        miniWebsite.styleSheetMap = this.setDefaultElementsFromStyleMap(miniWebsite.styleSheetMap, new SpinnerElement());

        // TT-6476 -- for now since we haven't separated mat-cards into the header, content, and footer elements in the schedulerStyleSheet, take it
        // and apply the mat-card styling to the elements for borders; if come back later and add in the header/content/footer, may not need this
        miniWebsite.styleSheetMap = this.mapMatCardSubStyles(miniWebsite.styleSheetMap);

        //Logo is difficult to define through just one property so depending on what they have selected we configure styles to match
        //doing this before loop below so style is applied to stylesheet
        miniWebsite = this.configureLogoAlignment(miniWebsite, preference)

        //If they set the default text color but not any of the other text colors, other properties are overriding; add check to add other text elements to list if needed
        if(miniWebsite.styleSheetMap.body !== undefined && miniWebsite.styleSheetMap.body.color !== undefined) {
            let textElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'label', 'div', 'span', 'p', 'i'];
            for (let i = 0, x = textElements.length; i < x; i++) {
                if(miniWebsite.styleSheetMap[textElements[i]] === undefined){//don't have an h1/div/label/etc. property on there at all
                    miniWebsite.styleSheetMap[textElements[i]] = {color: miniWebsite.styleSheetMap.body.color}
                } else if(miniWebsite.styleSheetMap[textElements[i]].color === undefined){
                    miniWebsite.styleSheetMap[textElements[i]].color = miniWebsite.styleSheetMap.body.color;
                }
            }
        }

        //Set property flags to hide or show
        //for testing, adding in packages to styleMap
        // miniWebsite.styleSheetMap.store = {enable: true};
        // miniWebsite.styleSheetMap.packages = {enable: true, columns: 3}
        if (preference && preference.booleanMap)
            miniWebsite.styleSheetMap.waitList = {enable: preference.booleanMap.showJoinWaitListTab}
        miniWebsite.displayConfig = this.mapPropsToDisplayConfig(miniWebsite.styleSheetMap, this.displayConfig);
        // console.log("this.displayConfig")
        // console.log(this.displayConfig);

        //Lastly, add properties to styleMap from DefaultStyleMap if they are not already set
        let defaultStyleMap: DefaultStyleMap = new DefaultStyleMap();
        for(let prop in defaultStyleMap){
            if(miniWebsite.styleSheetMap[prop] === undefined){
                miniWebsite.styleSheetMap[prop] = defaultStyleMap[prop];
            } else {
                for(let childProp in defaultStyleMap[prop]){
                    if(miniWebsite.styleSheetMap[prop][childProp] === undefined){
                        miniWebsite.styleSheetMap[prop][childProp] = defaultStyleMap[prop][childProp]
                    }
                }
            }
        }

        //@ts-ignore
        // console.log("miniWebsite.styleSheetMap " + JSON.stringify(miniWebsite.styleSheetMap, true, 2));
        var styleEl = document.createElement('style');
        var styleSheet;
        // Append style element to head
        document.head.appendChild(styleEl);
        // Grab style sheet
        styleSheet = styleEl.sheet;
        if (miniWebsite.styleSheetMap['body'] && miniWebsite.styleSheetMap['body'].popupBackground) {
            miniWebsite.styleSheetMap['mat-dialog-container'] = {'backgroundColor': miniWebsite.styleSheetMap['body'].popupBackground + " !important;"};
        }
        for(let prop in miniWebsite.styleSheetMap){
            let selector = prop;
            //since storing as backgroundColor, borderColor, etc, need to create properties object which will reformat as expected for CSS into background-color, border-color, etc.
            let cssProperties = new CSSProperties();
            cssProperties.setStyles(miniWebsite.styleSheetMap[prop]);
            let styleProperties = cssProperties.getStylesForStylesheet();
            let propStr = ''
            for(let styleProp in styleProperties){
                propStr += styleProp + ':' + styleProperties[styleProp] + ';\n';
            }
            styleSheet.insertRule(selector + '{' + propStr + '}', styleSheet.cssRules.length);
        }
        this.mapColorsToElements(miniWebsite.styleSheetMap, this.baseButtons);
        this.mapColorsToElements(miniWebsite.styleSheetMap, this.baseChips);
        this.mapColorsToElements(miniWebsite.styleSheetMap, this.baseAlerts);

        this.mwStyleMap = miniWebsite.styleSheetMap;
    }
    
}
