import {Directive, ElementRef, Input} from '@angular/core';
import {StyleSheetService} from "../styleSheetService";

@Directive({
  selector: '[appImageSizer]'
})
export class ImageSizerDirective {
  @Input() imageId;
  @Input() imageClass;
  @Input() defaultImageWidth: string;
  @Input() defaultImageHeight: string;
  imageWidth: string = '100%';
  imageMaxWidth: string = '100%';
  imageHeight: string = 'auto';
  displayImage: string = 'inherit';

  selector: string;
  constructor(private stylesheetService: StyleSheetService, private elementRef: ElementRef) { }


  ngOnInit(): void {
    let styleMap = this.stylesheetService.mwStyleMap;
    let propToMatch = null;
    if(this.imageClass !== undefined){
      propToMatch = this.imageClass;
    }
    if(this.imageId !== undefined){
      propToMatch = this.imageId;
    }
    let widthMatchFound = false;
    let maxWidthMatchFound = false;
    let heightMatchFound = false;
    let displayMatchFound = false;
    if(propToMatch !== null){
      for(let prop in styleMap){
        if(prop === propToMatch){
          if(styleMap[prop].width){
            widthMatchFound = true;
            this.imageWidth = styleMap[prop].width;
          }
          if(styleMap[prop].maxWidth){
            maxWidthMatchFound = true;
            this.imageMaxWidth = styleMap[prop].maxWidth;
          }
          if(styleMap[prop].height){
            heightMatchFound = true;
            this.imageHeight = styleMap[prop].height;
          }
          if(styleMap[prop].display){
            displayMatchFound = true;
            this.displayImage = styleMap[prop].display;
          }

        }
      }
    }

    if(widthMatchFound)
      this.elementRef.nativeElement.style.width = this.imageWidth;

    if(maxWidthMatchFound)
      this.elementRef.nativeElement.style.maxWidth = this.imageMaxWidth;

    if(heightMatchFound)
      this.elementRef.nativeElement.style.height = this.imageHeight;

    if(displayMatchFound)
      this.elementRef.nativeElement.style.display = this.displayImage;




  }

}
