import {Injectable, Injector} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Router, RouterStateSnapshot} from '@angular/router';
import {Subject} from "rxjs/index";
import {
    Client,
    ClientAccount,
    ExistingSessionObject,
    loginRegisterPopUpObject,
    movePanels, preference
} from "../common-classes/app-objects.model";
import {map} from "rxjs/operators";
import {catchError} from "rxjs/internal/operators";
import {ClientAccountService} from "../client-account/client-account.service";
import {SessionService} from '../session.service';
import {PasswordResetSuccess, PasswordResetValid} from '../../models/PasswordResetValid.model';


@Injectable()
export class AuthService {
    authChange = new Subject<boolean>();
    loginCalled = new Subject<loginRegisterPopUpObject>();
    registerCalled = new Subject<boolean>();
    addConnectedClientCalled = new Subject<loginRegisterPopUpObject>();
    logoutCalled = new Subject<boolean>();
    loginClosed = new Subject<loginRegisterPopUpObject>();
    registerClosed = new Subject<loginRegisterPopUpObject>();
    connectedClientClosed = new Subject<Client>();
    token: string;
    fullStoryEnabled: boolean = false;
    sessionService: SessionService;
    private isAuthenticated = false;
    private apiUrl: string;
    private loggedInApiUrl: string;
    private state: RouterStateSnapshot;
    constructor(private http: HttpClient, private router: Router, private clientAccountService: ClientAccountService, private injector: Injector){
        try {
            this.token = sessionStorage.getItem('token');
            this.apiUrl = sessionStorage.getItem('csAPIBase') + 'cs/';
            this.loggedInApiUrl = sessionStorage.getItem('csAPIBase') + 'csl/';
        } catch (e) {

        }
        this.sessionService = injector.get(SessionService);
    }

    private handleError(error: any): Promise<any>{
        return Promise.reject(error);
    }
    updateToken(token){
        sessionStorage.setItem('token', token);
        this.token = token;
    }

    clientLogin(clientLoginObject: ClientAccount){
        return this.http
            .post<ClientAccount>(this.apiUrl + 'login', clientLoginObject, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: ClientAccount) => {
                    return responseData;
                }),
                catchError((err, caught) => {
                    return this.handleError(err.error.message);
                })
            )
    }

    checkClientExistsForRegistration(businessId: number, clientEmail: string){
        let requestBody = {'emailAddress': clientEmail, 'redirectUrl': window.location.href};
        return this.http
            .post<boolean>(this.apiUrl + 'checkClientAccountExists/' + businessId, requestBody, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: boolean) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )

    }

    checkPasswordResetValid(requestId: string){
        return this.http
            .get<boolean>(this.apiUrl + 'checkPasswordResetValid/' + requestId, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: boolean) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )

    }

    resendPasswordReset(requestId: string){
        return this.http
            .post<PasswordResetValid>(this.apiUrl + 'resendPasswordReset/' + requestId, null, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: PasswordResetValid) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )

    }

    resetPassword(requestId: string, password: string) {
        return this.http
            .post<PasswordResetSuccess>(this.apiUrl + 'resetPassword/' + requestId, password, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: PasswordResetSuccess) => {
                    return responseData;
                }),
                catchError((err, caught) => {
                    return this.handleError(err.error.message);
                })
            )
    }

    forceResetPassword(email: string, password: string) {
        return this.http
            .post<PasswordResetSuccess>(this.apiUrl + 'forceResetPassword/' + password, email, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: PasswordResetSuccess) => {
                    return responseData;
                }),
                catchError((err, caught) => {
                    return this.handleError(err.error.message);
                })

            )
    }

    clientLogout(){

        return this.http
            // @ts-ignore
            .post<string>(this.loggedInApiUrl + 'logout?sessionToken=' + localStorage.getItem('existingSessionToken'), null, {responseType: 'text'})
            .pipe(
                map((responseData: string) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }


    isAuth() {
        return this.isAuthenticated;
    }
    setRefreshUrl(stateSnapshot: RouterStateSnapshot){
        this.state = stateSnapshot;
    }

    removeBackofficeSessionTokenFromURL() {
        if (window.location.href.indexOf('?backofficeSessionToken=') !== -1) {
            const sessionTokenIndex = window.location.href.indexOf('?backofficeSessionToken=');
            const baseUrl = window.location.href.substring(0, sessionTokenIndex);
            const sessionTokenParam = window.location.href.substring(sessionTokenIndex);
            const equalsIndex = sessionTokenParam.indexOf('=');
            const hashIndex = sessionTokenParam.indexOf('#');
            const backofficeSessionToken = sessionTokenParam.substring(equalsIndex + 1, hashIndex);
            // console.log('baseUrl' + baseUrl);
            // console.log('sessionTokenParam ' + sessionTokenParam);
            // console.log('equalsIndex ' + equalsIndex);
            // console.log('hashIndex ' + hashIndex);
            // console.log('backofficeSessionToken ' + backofficeSessionToken);
            sessionStorage.setItem('backofficeSessionToken', backofficeSessionToken);
            const endUrl = sessionTokenParam.substring(hashIndex);
            const newUrl = baseUrl + '/' + endUrl;
            window.history.pushState(null, 'TimeTap Backoffice', newUrl);
        }
    }

    authenticatedSuccessfully(loggedInClient: ClientAccount, loadMyAccount: boolean) {
        // localStorage.setItem('token', authReturnData.sessionToken);
        // this.checkinService.updateHeaders(authReturnData);
        this.clientAccountService.setLoggedInClient(loggedInClient);
        this.isAuthenticated = true;
        this.authChange.next(true);
        if(loadMyAccount){
            this.router.navigate(['/my-account']);
        } else if(this.state !== undefined && this.state.url !== undefined){
            if(this.state.url.indexOf('?')!==-1){//Url that refreshed from has query parameters. Need to get those and pass to router.navigate
                let queryParams = this.state.url.substring(this.state.url.indexOf('?') + 1);
                // let params = new URL(this.state.url).searchParams;
                let queryParamsObj = JSON.parse('{"' + queryParams.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
                this.state.url = this.state.url.substring(0,this.state.url.indexOf('?'));
                this.router.navigate([this.state.url], {queryParams: queryParamsObj});
            } else {
                this.router.navigate([this.state.url]);
            }
        }
    }

    logoutSuccessfully(){
        this.clientAccountService.setLoggedInClient(null);
        this.isAuthenticated = false;
        this.authChange.next(false);
    }
}
