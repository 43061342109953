import {Component, Inject, OnInit} from '@angular/core';
import {preference} from "../../common-classes/app-objects.model";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {TagsService} from "../../tags.service";
import {Router} from "@angular/router";
import {Appointment} from '../../../models/Appointment.model';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html'
})
export class ErrorModalComponent implements OnInit {
  preference: preference;
  appt: Appointment;
  errorTitle: string;
  showCloseBtn: boolean = false;
  errorMessage: string;
  errorType: string;
  errorData: any;
  btnLabelProp: string = 'modalCloseBtnLabel';
  constructor(@Inject(MAT_DIALOG_DATA) private passedData: any, private router: Router, public dialogRef: MatDialogRef<ErrorModalComponent>, private tagsService: TagsService) { }

  ngOnInit() {
    this.preference = this.passedData.preference;
    this.errorType = this.passedData.errorType;
    if(this.passedData.errorData !== undefined){
      this.errorData = this.passedData.errorData;
    }
    if(this.passedData.appt !== undefined){
      this.appt = this.passedData.appt;
    }

    switch (this.errorType) {
      case 'maxApptsReached':
        this.errorMessage = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.maxApptsReachedModalErrorMsg, this.tagsService.preferenceTagsArray);
        this.errorTitle = this.preference.labelMap.maxApptsReachedModalErrorTitle;
        this.btnLabelProp = 'viewApptLabel';
        break;
      case 'apptDoesNotBelongToAccount':
        this.errorMessage = this.preference.labelMap.apptDoesNotBelongToAccountErrorMsg;
        this.errorTitle = this.preference.labelMap.apptDoesNotBelongToAccountErrorTitle;
        this.showCloseBtn = true;
        break;
      case 'apptMaxRescheduleCountError':
        this.errorMessage = this.preference.labelMap.apptMaxRescheduleCountErrorMsg;
        this.errorTitle = this.preference.labelMap.apptMaxRescheduleCountErrorTitle;
        this.showCloseBtn = true;
        break;
      case 'apptReschedulePastTimeframe':
        this.errorMessage = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.apptReschedulePastTimeframeMsg, this.tagsService.preferenceTagsArray);
        this.errorTitle = this.preference.labelMap.apptRescheduleNotAllowedTitle;
        this.showCloseBtn = true;
        break;
      case 'apptRescheduleNotAllowedByBusiness':
        this.errorMessage = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.apptRescheduleNotAllowedByBusiness, this.tagsService.preferenceTagsArray);
        this.errorTitle = this.preference.labelMap.apptRescheduleNotAllowedTitle;
        this.showCloseBtn = true;
        break;
      case 'apptRescheduleNotInOpenStatus':
        this.errorMessage = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.apptRescheduleNotInOpenStatus, this.tagsService.preferenceTagsArray);
        this.errorMessage = this.tagsService.assignObjectToTags(this.appt, this.errorMessage, this.tagsService.apptFieldTags);
        this.errorTitle = this.preference.labelMap.apptRescheduleNotAllowedTitle;
        this.showCloseBtn = true;
        break;
      case 'apptCancelPastTimeframe':
        this.errorMessage = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.apptCancelPastTimeframeMsg, this.tagsService.preferenceTagsArray);
        this.errorTitle = this.preference.labelMap.cancelAppointmentModalTitle;
        this.showCloseBtn = true;
        break;
      case 'apptCancelNotInOpenStatus':
        this.errorMessage = this.tagsService.assignObjectToTags(this.appt, this.preference.labelMap.apptCancelNotInOpenStatusMsg, this.tagsService.apptFieldTags);
        this.errorTitle = this.preference.labelMap.cancelAppointmentModalTitle;
        this.showCloseBtn = true;
        break;
      case 'invitationCampaignErrors':
        if(this.errorData.noInvitation){
            this.errorMessage = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.invitationCampaignErrorNoInvitationMsg, this.tagsService.preferenceTagsArray);
            this.errorTitle = this.preference.labelMap.invitationCampaignErrorNoInvitationTitle;
        } else if(this.errorData.invitationCompleted){
            this.errorMessage = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.invitationCampaignErrorInvitationCompletedMsg, this.tagsService.preferenceTagsArray);
            this.errorTitle = this.preference.labelMap.invitationCampaignErrorInvitationCompletedTitle;
        } else if(this.errorData.invitationExpired){
            this.errorMessage = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.expiredInvitationText, this.tagsService.preferenceTagsArray);
            this.errorTitle = this.preference.labelMap.invitationCampaignErrorInvitationExpiredTitle;
        }
        this.showCloseBtn = true;
        break;
      case 'clientInfoFieldsInvalid':
          this.errorMessage = this.passedData.errorMessage;
          this.errorTitle = this.preference.labelMap.clientInfoFieldsInvalidTitle;
          this.showCloseBtn = true;
          break;
      case 'disclaimerFormFieldsInvalid':
          this.errorMessage = this.passedData.errorMessage;
          this.errorTitle = this.preference.labelMap.disclaimerFormFieldsInvalidTitle;
          this.showCloseBtn = true;
          break;
      case 'rescheduleNotAllowedClientOnHold':
          this.errorMessage = this.tagsService.assignObjectToTags(this.appt, this.preference.labelMap.rescheduleNotAllowedClientOnHold, this.tagsService.apptFieldTags);
          this.errorTitle = this.preference.labelMap.apptRescheduleNotAllowedTitle;
          this.showCloseBtn = true;
          break;
      case 'cancelNotAllowedClientOnHold':
          this.errorMessage = this.tagsService.assignObjectToTags(this.appt, this.preference.labelMap.cancelNotAllowedClientOnHold, this.tagsService.apptFieldTags);
          this.errorTitle = this.preference.labelMap.cancelAppointmentModalTitle;
          this.showCloseBtn = true;
          break;
      case 'cookiesNotAllowed':
        this.errorMessage = "It looks like you are browsing on a private browser or have disabled cookies on your browser. Please enable cookies and reload to view this scheduler."
        this.errorTitle = "Error";
        break;
      default:
        this.errorMessage = 'Error loading data!';
        this.errorTitle = 'Error';

    }
  }

  viewAppts(){
    this.dialogRef.close();
    this.router.navigate(['my-account/appts']);
  }

  handleClose(){
    switch(this.errorType){
      case 'maxApptsReached':
        this.viewAppts();
        break;
      default:
        this.dialogRef.close();
    }
  }


}
