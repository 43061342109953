import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Client, preference, RecurringAppointment} from "../../../../common-classes/app-objects.model";
import {TableColumnDataModel} from "../../../../common-classes/TableColumnData.model";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {DataTablesService} from "../../../../data-tables.service";
import {ResponsiveService} from "../../../../responsive.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TimeService} from "../../../../scheduler/time/time.service";
import {BusinessService} from "../../../../business.service";
import {TagsService} from "../../../../tags.service";
import {WaitList} from '../../../../../models/WaitList.model';

@Component({
  selector: 'waitlist-registrations-table',
  templateUrl: '../appointments-table/appointments-table.component.html'
})
export class WaitlistRegistrationsTableComponent implements OnInit {
  @Input() waitlistRegistrationData: WaitList[];
  @Input() selectedClient: Client;
  @Input() preference: preference;
  @Input() loaded: boolean;
  @Input() errorLoading: boolean;
  @Input() loading = false;
  @Input() noApptsFoundProp: string;
  idProp = 'waitListId';
  preferenceDefinedColumns: string[];
  displayedColumns = this.dataTablesService.getDefaultWaitlistRegistrationDisplayedColumns();
  columnDefs: TableColumnDataModel[] = this.dataTablesService.getDefaultWaitlistRegistrationColumnDefs();
  tableData = new MatTableDataSource<WaitList>();

  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.tableData.sort = this.sort;
  }
  constructor(private dataTablesService: DataTablesService, private responsiveService: ResponsiveService, private router: Router, private route: ActivatedRoute,
              private timeService: TimeService, private businessService: BusinessService, private tagsService: TagsService) { }

  viewDetails(waitList: WaitList){
    this.router.navigate(['/my-account/waitlistRegistration/' + waitList.waitListId, {waitListDateTimeId: waitList.selectedDateTimeFromList.waitListDateTimeId}], {relativeTo: this.route.root, state: {waitList: waitList}});
  }
  viewDetailsNonAction(waitList: any){
    if(this.responsiveService.onMobileViewPort){
      this.viewDetails(waitList);
    }
  }

  organizeApptData(){
    if(this.waitlistRegistrationData === null){
      this.waitlistRegistrationData = [];
    }
    for(let i = 0, x = this.waitlistRegistrationData.length; i < x; i++){
      let timezone = this.timeService.getApptTimezone(this.waitlistRegistrationData[i].location, null, this.waitlistRegistrationData[i].staff);
      // this.waitlistRegistrationData[i].clientStartTimeDate = this.tagsService.getTimeWithMomentForClientConvertedTimezone(this.waitlistRegistrationData[i].clientStartTime, this.waitlistRegistrationData[i].startDate, timezone);
      // this.waitlistRegistrationData[i].clientEndTimeDate = this.tagsService.getTimeWithMomentForClientConvertedTimezone(this.waitlistRegistrationData[i].clientEndTime, this.waitlistRegistrationData[i].endDate, timezone);
      this.waitlistRegistrationData[i] = this.dataTablesService.addCustomFieldsToListDataObjects(this.waitlistRegistrationData[i], this.selectedClient);
    }
    this.tableData.data = this.waitlistRegistrationData;
    this.tableData.sortingDataAccessor = (obj, property) => this.dataTablesService.getProperty(obj, property);
    this.tableData.sort = this.sort;
    this.loaded = true;
    this.loading = false;
    this.errorLoading = false;
  }

  getDisplayedColumns(){
    let columnsForTable: {columns: TableColumnDataModel[], appointmentFieldList: string[]};
    columnsForTable = this.tagsService.getApptListColumnValues(this.preferenceDefinedColumns, this.preference.schedulerPreferenceFieldDefnList,
        this.preference.labelMap, this.responsiveService.onMobileViewPort, 'waitlistRegistrationTags');
    this.displayedColumns = columnsForTable.appointmentFieldList;
    this.columnDefs = this.dataTablesService.setupColumnDefs(this.columnDefs, columnsForTable.columns);
    this.organizeApptData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    if(changes.waitlistRegistrationData !== undefined && changes.waitlistRegistrationData.currentValue !== undefined){
      if(this.preference.waitlistRegistrationsFieldList === undefined || this.preference.waitlistRegistrationsFieldList === null)
        this.preferenceDefinedColumns = this.dataTablesService.getDefaultWaitlistRegistrationDisplayedColumns();
      else
        this.preferenceDefinedColumns = this.preference.waitlistRegistrationsFieldList;
      this.getDisplayedColumns();
    }
  }

  ngOnInit() {
    for(let i = 0, x = this.columnDefs.length; i < x; i++){
      switch(this.columnDefs[i].name){
        case this.idProp:
          this.columnDefs[i].title = 'ID';
          break;
        case 'clientStartTimeDate':
          this.columnDefs[i].filterFormats[0].format = 'EEE, MMM d, yy';
          break;
      }

    }
  }

}
