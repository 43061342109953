import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {AgmMap, LatLngBounds} from '@agm/core';
import {Location} from '../../../../models/Location.model';
import {LocationMapService} from './location-map.service';
declare var google: any;

@Component({
  selector: 'app-location-map',
  template: `
    <agm-map #AgmMap id="map_canvas" [zoom]="zoom" [latitude]="centerLat" [longitude]="centerLng" (zoomChange)="zoomChanged($event)">
      <agm-marker *ngFor="let location of mapLocations" [latitude]="location.latitude" [longitude]="location.longitude">
        <agm-info-window>
          <div class="mapInfoWindowLocationDetails"  [innerHTML]="location.locationPnlMapInfoWindowContent | safeHtml"></div>
          <button (click)="selectObject.emit(location)" mat-stroked-button
                  buttonStyling baseStyle="basic" btnClass="locationPnlMapSelectBtn"
                  class="btn btn-block btn-sm locationPnlMapSelectBtn agm-info-window-select">
            Select
          </button>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  `,
  styles: []
})
export class LocationMapComponent implements OnInit, OnDestroy, AfterContentChecked, OnChanges {
  @Input() mapLocations: Location[];
  @ViewChild('AgmMap', {static: false}) agmMap: AgmMap;
  @Output() selectObject = new EventEmitter<Location>();
  centerLat: number = 0;
  centerLng: number = 0;
  zoom: number = this.locationMapService.zoom;
  googleMap: any;
  constructor(private locationMapService: LocationMapService) { }

  triggerRedraw() {
    this.agmMap.triggerResize();
  }

  zoomChanged(zoom: number) {
    this.locationMapService.zoom = zoom;
  }

  setMapZoom(map, count){
    if(this.mapLocations.length === 1 || count === 10){
      //zoom is already set, don't do anything
    } else if(this.mapLocations.length === 0 && count <= 10) {
      setTimeout(() => {
        count++;
        this.setMapZoom(map, count)
      },200);
    } else {
      const bounds: LatLngBounds = new google.maps.LatLngBounds();
      for (const location of this.mapLocations) {
        bounds.extend(new google.maps.LatLng(location.latitude, location.longitude));
      }
      map.fitBounds(bounds);
    }
  }

  setMapProps() {
    if (this.mapLocations) {
      this.centerLat = 0;
      this.centerLng = 0;
      for (let i = 0, x = this.mapLocations.length; i < x; i++) {
        if (this.mapLocations[i].latitude !== null && this.mapLocations[i].longitude !== null) {
          this.centerLat = this.centerLat + this.mapLocations[i].latitude;
          this.centerLng = this.centerLng + this.mapLocations[i].longitude;
        }
      }
      if (this.centerLat !== 0)
        this.centerLat = this.centerLat / this.mapLocations.length;
      if (this.centerLng !== 0)
        this.centerLng = this.centerLng / this.mapLocations.length;
      if (this.mapLocations.length === 1)
        this.zoom = 13;
      if (this.agmMap)
        this.setMapZoom(this.googleMap, 0);
    }
  }

  agmMapSubscriptionFn(counter: number){
    if(counter < 1000 && this.agmMap === undefined){
      setTimeout(() => {
        counter = counter + 1;
        this.agmMapSubscriptionFn(counter);
      });
    } else if(this.agmMap !== undefined) {
        const mapSubscription = this.agmMap.mapReady.subscribe(map => {
          this.googleMap = map;
          this.setMapZoom(map, 0);
          mapSubscription.unsubscribe();
        });
    }
  }

  ngAfterContentChecked() {
    this.agmMapSubscriptionFn(0);
  }

  ngOnChanges(changes:SimpleChanges) {
    if (changes.hasOwnProperty('mapLocations') && !changes.mapLocations.firstChange)
      this.setMapProps();
  }

  ngOnInit() {
    this.setMapProps();

  }

  ngOnDestroy() {
    if (this.agmMap)
     this.agmMap.ngOnDestroy();
  }

}
