import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { HttpClientModule} from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MaterialModule} from './material.module';
import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { WelcomeComponent } from './scheduler/welcome/welcome.component';
import { HeaderComponent } from './navigation/header/header.component';
import { LocationsComponent } from './scheduler/locations/locations.component';
import { StaffComponent } from './scheduler/staff/staff.component';
import { ReasonsComponent } from './scheduler/reasons/reasons.component';
import { TimeComponent } from './scheduler/time/time.component';
import { ScreeningQuestionComponent } from './scheduler/screening-question/screening-question.component';
import { GroupsComponent } from './scheduler/groups/groups.component';
import { ClientAccountComponent } from './client-account/client-account.component';
import { ViewAppointmentsComponent } from './client-account/view-appointments/view-appointments.component';
import { ClientAccountHomeComponent } from './client-account/client-account-home/client-account-home.component';
import { AppointmentDetailComponent } from './client-account/view-appointments/booking-object-details/appointment-detail/appointment-detail.component';
import { ViewInvoicesComponent } from './client-account/view-invoices/view-invoices.component';
import { ViewInvoicesListComponent } from './client-account/view-invoices/view-invoices-list/view-invoices-list.component';
import { InvoiceDetailComponent } from './client-account/view-invoices/invoice-detail/invoice-detail.component';
import { ClientProfileComponent } from './client-account/client-profile/client-profile.component';
import { ClientProfileDetailsComponent } from './client-account/client-profile/client-profile-details/client-profile-details.component';
import { ReasonChipListComponent } from './scheduler/reasons/reason-chip-list/reason-chip-list.component';
import { ZipCodeFilterComponent } from './scheduler/common-components/zip-code-filter/zip-code-filter.component';
import { SquareComponent } from './client-account/view-invoices/payment-portals/square/square.component';
import { StripeComponent } from './client-account/view-invoices/payment-portals/stripe/stripe.component';
import { LumapayComponent } from './client-account/view-invoices/payment-portals/lumapay/lumapay.component';
import { TimeCartComponent } from './scheduler/time/time-cart/time-cart.component';
import { VariableAddressComponent } from './scheduler/locations/variable-address/variable-address.component';
import { CancelAppointmentComponent } from './client-account/view-appointments/cancel-appointment/cancel-appointment.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { LoginRegisterBtnsComponent } from './navigation/login-register-btns/login-register-btns.component';
import { HeaderSidebarComponent } from './navigation/sidebar/header-sidebar.component';
import { SchedulerListItemsComponent } from './scheduler/common-components/scheduler-list-items/scheduler-list-items.component';
import { SelectPayAmountComponent } from './modals/select-pay-amount/select-pay-amount.component';
import { ClientPackagesComponent } from './client-account/client-profile/client-packages/client-packages.component';
import { ClientProfileContainerComponent } from './client-account/client-profile/client-profile-container/client-profile-container.component';
import { SelectPackageComponent } from './modals/select-package/select-package.component';
import { ClientPaymentInfoComponent } from './client-account/client-profile/client-payment-info/client-payment-info.component';
import { ManageCreditCardComponent } from './modals/manage-credit-card/manage-credit-card.component';
import { RepeatingApptFilterComponent } from './modals/repeating-appt-filter/repeating-appt-filter.component';
import { CalendarComponent } from './scheduler/time/calendar/calendar.component';
import { DisclaimerFormComponent } from './disclaimer-form/disclaimer-form.component';
import { DisclaimerFormDetailComponent } from './disclaimer-form/disclaimer-form-detail/disclaimer-form-detail.component';
import { ClientFormsComponent } from './client-account/client-profile/client-forms/client-forms.component';
import { AddToCalendarComponent } from './scheduler/common-components/add-to-calendar/add-to-calendar.component';
import {CartModule} from "./store/cart/cart.module";
import {CouponModule} from "./scheduler/common-components/coupon/coupon.module";
import {UIElementsModule} from "./ui-elements/ui-elements.module";
import {StoreModule} from "./store/store.module";
import {CoreModule} from "./core.module";
import {PipesModule} from "../pipes/pipes.module";
import {ClientAccountHeaderModule} from "./client-account/client-account-header/client-account-header.module";
import {ViewAppointmentsListModule} from "./client-account/view-appointments/view-appointments-list/view-appointments-list.module";
import { RepeatingAppointmentDetailComponent } from './client-account/view-appointments/booking-object-details/repeating-appointment-detail/repeating-appointment-detail.component';
import { WaitlistRegistrationDetailComponent } from './client-account/view-appointments/booking-object-details/waitlist-registration-detail/waitlist-registration-detail.component';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { EmailSettingsContainerComponent } from './email-settings/email-settings-container.component';
import {WaitListModule} from './waitlist/wait-list.module';
import {ConfirmationModule} from './scheduler/confirmation/confirmation.module';
import {FooterModule} from './scheduler/common-components/footer/footer.module';
import {TitleBarModule} from './scheduler/common-components/title-bar/title-bar.module';
import {SchedulerPrefFieldFormModule} from './scheduler-pref-field-form/scheduler-pref-field-form.module';
import {ClientInfoModule} from './scheduler/client-info/client-info.module';
import {TimezoneModule} from './scheduler/common-components/timezone/timezone.module';
import {TimeSlotDisplayModule} from './scheduler/time/time-slot-display/time-slot-display.module';
import {LocationMapModule} from './scheduler/locations/location-map/location-map.module';
import {SchedulerBannerListItemsModule} from './scheduler/common-components/scheduler-banner-list-items/scheduler-banner-list-items.module';
import {PromptPackagePayModule} from './client-account/view-invoices/invoice-detail/prompt-package-pay/prompt-package-pay.module';
import {ResetPasswordModule} from './auth/reset-password/reset-password.module';
import { SelectAddOnsComponent } from './modals/select-add-ons/select-add-ons.component';
import { CookieBannerComponent } from './auth/cookie-banner/cookie-banner.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { ViewAppointmentIntentsComponent } from './client-account/view-appointment-intents/view-appointment-intents.component';
import { ViewAppointmentIntentsListComponent } from './client-account/view-appointment-intents/view-appointment-intents-list/view-appointment-intents-list.component';
import { AppointmentIntentsTableComponent } from './client-account/view-appointment-intents/view-appointment-intents-list/appointment-intents-table/appointment-intents-table.component';
import { AppointmentIntentPanelComponent } from './scheduler/appointment-intent-panel/appointment-intent-panel.component';




@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    SchedulerComponent,
    WelcomeComponent,
    HeaderComponent,
    LocationsComponent,
    StaffComponent,
    ReasonsComponent,
    TimeComponent,
    ScreeningQuestionComponent,
    GroupsComponent,
    ClientAccountComponent,
    ViewAppointmentsComponent,
    ClientAccountHomeComponent,
    AppointmentDetailComponent,
    ViewInvoicesComponent,
    ViewInvoicesListComponent,
    InvoiceDetailComponent,
    ClientProfileComponent,
    ClientProfileDetailsComponent,
    ReasonChipListComponent,
    ZipCodeFilterComponent,
    SquareComponent,
    StripeComponent,
      LumapayComponent,
    TimeCartComponent,
    VariableAddressComponent,
    CancelAppointmentComponent,
    ErrorModalComponent,
    LoginRegisterBtnsComponent,
    HeaderSidebarComponent,
      SchedulerListItemsComponent,
      SelectPayAmountComponent,
      ClientPackagesComponent,
      ClientProfileContainerComponent,
      SelectPackageComponent,
      ClientPaymentInfoComponent,
      ManageCreditCardComponent,
      RepeatingApptFilterComponent,
      CalendarComponent,
      DisclaimerFormComponent,
      DisclaimerFormDetailComponent,
      ClientFormsComponent,
      AddToCalendarComponent,
      RepeatingAppointmentDetailComponent,
      WaitlistRegistrationDetailComponent,
    EmailSettingsComponent,
    EmailSettingsContainerComponent,
    SelectAddOnsComponent,
    CookieBannerComponent,
    ViewAppointmentIntentsComponent,
    ViewAppointmentIntentsListComponent,
    AppointmentIntentsTableComponent,
    AppointmentIntentPanelComponent,
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot(),
    ClientAccountHeaderModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXv-JTNzsrItjEf72xKaGXaknKhgI91zI'
    }),
    ViewAppointmentsListModule,
    CouponModule,
    CartModule,
    UIElementsModule,
    StoreModule,
    CoreModule,
    PipesModule,
    WaitListModule,
    ConfirmationModule,
    FooterModule,
    TitleBarModule,
    ClientInfoModule,
    SchedulerPrefFieldFormModule,
    TimezoneModule,
    TimeSlotDisplayModule,
    LocationMapModule,
    SchedulerBannerListItemsModule,
    PromptPackagePayModule,
    ResetPasswordModule,
    RecaptchaV3Module
  ],
  providers: [
    {
      provide: 'window',
      useValue: window,
    },
    {
      provide: 'document',
      useValue: document,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoginComponent, RegisterComponent, ScreeningQuestionComponent, TimeCartComponent, VariableAddressComponent,
      CancelAppointmentComponent, ErrorModalComponent, SelectPayAmountComponent, SelectPackageComponent, ManageCreditCardComponent, RepeatingApptFilterComponent, AddToCalendarComponent,
    SelectAddOnsComponent]
})
export class AppModule { }
