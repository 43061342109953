import {
  Directive,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';
import {StyleSheetService} from "../styleSheetService";

@Directive({
  selector: '[appLink]'
})
export class LinkDirective implements OnInit{
  @Input() linkId;
  @Input() linkClass;
  linkColor: string = '#1C827C';
  selector: string;
  constructor(private stylesheetService: StyleSheetService, private elementRef: ElementRef) { }


  ngOnInit(): void {
    let styleMap = this.stylesheetService.mwStyleMap;
    let propToMatch = null;
    if(this.linkClass !== undefined){
      propToMatch = this.linkClass;
    }
    if(this.linkId !== undefined){
      propToMatch = this.linkId;
    }
    let matchFound = false;
    if(propToMatch !== null){
      for(let prop in styleMap){
        if(prop === propToMatch && styleMap[prop].color !== undefined){
          matchFound = true;
          this.linkColor = styleMap[prop].color;
        }
      }
    }
    if(!matchFound){
      for(let prop in styleMap){
        if(prop === 'a' && styleMap[prop].color !== undefined){
          this.linkColor = styleMap[prop].color;
        }
      }
    }
    this.elementRef.nativeElement.style.color = this.linkColor;
    this.elementRef.nativeElement.style.textDecoration = 'none';
    this.elementRef.nativeElement.style.cursor = 'pointer';

  }
}
