import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {map} from "rxjs/operators";
import {catchError} from "rxjs/internal/operators";
import {DisclaimerFormData, DisclaimerFormField} from "./disclaimer-form.model";
import {delayedRetry} from '../operators/retry';
import * as _ from 'lodash';

@Injectable()
export class DisclaimerFormService {
    private apiUrl = sessionStorage.getItem('csAPIBase') + 'cs/';

    constructor(private http: HttpClient, private datePipe: DatePipe){}

    private handleError(error: any): Promise<any>{
        console.log(error);
        return Promise.reject(error);
    }

    getDisclaimerFormData(disclaimerFormUUID: string){
        let params = new HttpParams();
        const backofficeSessionToken = sessionStorage.getItem('backofficeSessionToken');
        params = params.append('backofficeSessionToken', backofficeSessionToken);
        return this.http
            .get<DisclaimerFormData>(this.apiUrl + 'disclaimerFormData/' + disclaimerFormUUID, {observe: "body", responseType: 'json', params})
            .pipe(
                map((responseData: DisclaimerFormData) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    saveFormData(disclaimerForm: DisclaimerFormData, newStatus: string, allFormFieldsAreDescriptions: boolean){
        let params = new HttpParams();
        params = params.append('newStatus', newStatus);
        params = params.append('backofficeSessionToken', sessionStorage.getItem('backofficeSessionToken'));
        const dfd: DisclaimerFormData = _.cloneDeep(disclaimerForm);
        if (!allFormFieldsAreDescriptions)
            dfd.formFields = null;
        if (dfd.dataJson && !allFormFieldsAreDescriptions)
            dfd.dataJson = null;
        if (dfd.disclaimerForm && dfd.disclaimerForm.formFields && !allFormFieldsAreDescriptions)
            dfd.disclaimerForm.formFields = null;
        return this.http
            .post<DisclaimerFormData>(this.apiUrl + 'disclaimerFormData', dfd, {observe: "body", responseType: 'json', params})
            .pipe(
                map((responseData: DisclaimerFormData) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    saveFields(disclaimerFormData: DisclaimerFormData, fieldsToSave: any) {
        return this.http
            .post<any>(this.apiUrl + 'disclaimerFormData/' + disclaimerFormData.disclaimerFormUuid + '/saveFieldValues', fieldsToSave, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: any) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    saveField(disclaimerFormData: DisclaimerFormData, fieldId: string, value: any) {
        return this.http
            .post<any>(this.apiUrl + 'disclaimerFormData/' + disclaimerFormData.disclaimerFormUuid + '/' + fieldId, value, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: any) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }


}
