import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    CSFilterParameterBean,
    movePanels,
    preference
} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {TagsService} from "../../tags.service";
import {MatDialog} from "@angular/material/dialog";
import {VariableAddressComponent} from "./variable-address/variable-address.component";
import {ResponsiveService} from "../../responsive.service";
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {Location} from '../../../models/Location.model';
import {schedulerPanel} from '../../../models/SchedulerPanel.model';
import {SchedulerService} from '../scheduler.service';
import {AgmMap} from '@agm/core';

@Component({
  selector: 'locations-panel',
  templateUrl: './locations.component.html'
})
export class LocationsComponent implements OnInit {
    @Output() onMovePanels = new EventEmitter<movePanels>();
    @Input() preference: preference;
    @Input() panel: schedulerPanel;
    @Input() CSFilterObj: CSFilterParameterBean;
    @Input() directionComingFrom: string;
    locations: Location[] = [];
    mapLocations: Location[];
    selectedLocation: Location;
    showBackButton: boolean = true;
    locationsLoaded: boolean = false;
    errorLoading: boolean = false;
    searchingLocations: boolean = false;
    filterValue: string;
    locationPnlNoLocationsFilterSearchMsg: string = '';
    totalLocationsLength: number;
    locationsHaveMapAddress: boolean = false;
    @ViewChild('locationMapComponent', {static: false}) locationMapComponent;

    constructor(private businessService: BusinessService, private tagsService: TagsService, private dialog: MatDialog, private responsiveService: ResponsiveService,
                private schedulerPreferenceService: SchedulerPreferenceService, private schedulerService: SchedulerService) { }


    renderMap(locations: Location[]){
        // this.preference.showLocationMap = false; LHB 6/1/2021 TT-7868 -- commented this out to fix issue; not sure why i was setting it to false in the first place
        this.locationsHaveMapAddress = false;
        const mapLocations = [];
        for(let i = 0, x = locations.length; i < x; i++){
            if(locations[i].latitude !== null && locations[i].longitude !== null){
                this.locationsHaveMapAddress = true;
                this.locations[i].locationPnlMapInfoWindowContent = this.tagsService.assignObjectToTags(locations[i], this.preference.labelMap.locationPnlMapInfoWindowContent, this.tagsService.locationFieldTags);
                mapLocations.push(locations[i]);
            }
        }
        this.mapLocations = mapLocations;
        this.locationsLoaded = true;
        if(this.mapLocations.length > 0)
            this.responsiveService.toggleLocationsMapView('show');
        if (this.locationMapComponent)
            this.locationMapComponent.triggerRedraw();
        this.searchingLocations = false;
    }

    getLocations(CSFilterObj: CSFilterParameterBean, filterSearch: boolean){
        this.searchingLocations = filterSearch;
        this.businessService.getLocations(CSFilterObj)
            .subscribe((locations: Location[]) => {
                this.locations = locations;
                if(this.locations.length === 1 && this.preference.autoAdvanceWhenSingle && this.directionComingFrom === 'next'){
                    this.locationsLoaded = true;
                    this.searchingLocations = false;
                    // this.preference.showLocationMap = false;
                    this.selectObject(this.locations[0], true);
                } else {
                    if(this.locations.length === 0 && CSFilterObj.filterLocation !== undefined && CSFilterObj.filterLocation !== null && CSFilterObj.filterLocation !== ''){
                        this.locationPnlNoLocationsFilterSearchMsg = this.preference.labelMap.locationPnlNoLocationsFilterSearchMsg.replace(new RegExp('%FILTER_SEARCH%', 'g'), CSFilterObj.filterLocation);
                    }
                    if(this.locations.length === 1){//TT-6233
                        this.selectObject(this.locations[0], false);
                    }
                    if(!this.searchingLocations){
                        this.totalLocationsLength = locations.length;
                    }
                    if(this.preference.showLocationMap){
                        this.renderMap(this.locations);
                    } else {
                        this.locationsLoaded = true;
                        this.searchingLocations = false;
                    }
                    if(this.panel.formGroup.value.location !== null){
                        let objFound = false;
                        for(let i = 0, x = this.locations.length; i < x; i++){
                            if(this.locations[i].locationId === this.panel.formGroup.value.location.locationId){
                                this.locations[i].selected = true;
                                objFound = true;
                            }
                        }
                        if(!objFound)
                            this.panel.formGroup = this.schedulerService.getPanelForm(this.panel.panel, this.preference.schedulerPreferenceFieldDefnList);
                    }
                }
            }, (error)=> {
                this.locationsLoaded = false;
                this.errorLoading = true;
                this.searchingLocations = false;
            })
    }

    ngOnInit() {
        this.responsiveService.setHeightForEmbedScheduler();

        this.getLocations(this.CSFilterObj, false);
    }


    movePanels(direction: string){
        if(direction === 'next' && this.selectedLocation !== undefined && this.selectedLocation.locationType !== undefined && this.selectedLocation.locationType !== null && this.selectedLocation.locationType === 'VARIABLE'){
            const dialogRef = this.dialog.open(VariableAddressComponent,
                {maxWidth: this.responsiveService.getMaxWidthForModals(), data: {selectedLocation: this.selectedLocation, preference: this.preference}, id: 'variableAddressModal'});
            dialogRef.afterClosed().subscribe(result => {
                if(result !== undefined && result.variableAddress !== undefined && result.variableAddress !== null && result.variableAddress !== ''){
                    this.onMovePanels.emit({direction: direction, panel: this.panel})
                }
            });
        } else {
            this.CSFilterObj.filterLocation = null;
            this.onMovePanels.emit({direction: direction, panel: this.panel})
        }
    }

    selectObject(location: Location, moveForward: boolean, event?: MouseEvent){
        if(!this.businessService.checkInnerHTMLForHrefClick(event)) {
            //remove selected from all other locations; this is an issue when you initially select a location that requires validate address; you dismiss that and select another location
            //and it looks like you've selected two locations
            for (let i = 0, x = this.locations.length; i < x; i++) {
                this.locations[i].selected = false;
            }
            this.selectedLocation = location;
            this.selectedLocation.selected = true;
            if (this.selectedLocation.allowTZChange !== null && this.selectedLocation.allowTZChange !== this.preference.allowTZChange) {
                this.schedulerPreferenceService.schedulerPreference.allowTZChange = this.selectedLocation.allowTZChange;
            } else if (this.selectedLocation.allowTZChange === null && this.preference.allowTZChange !== this.preference.allowTZChangeOriginal) {
                //Added this check in case they initially select a location that has a different allowTZChange status than the preference
                //and then toggle back and choose one that should have the default -- in same work effort as everything for TT-6286
                this.schedulerPreferenceService.schedulerPreference.allowTZChange = this.preference.allowTZChangeOriginal
            }
            this.panel.formGroup.patchValue({location: this.selectedLocation, preference: this.preference});
            if (moveForward) {
                this.movePanels('next');
            }
        }
    }


}
