import { Component, Input, OnInit, ViewChild, AfterViewChecked, OnChanges, SimpleChanges } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { AppointmentIntent } from '../../../../../models/AppointmentIntent.model';
import { Client, preference } from '../../../../common-classes/app-objects.model';
import { ResponsiveService } from '../../../../responsive.service';
import { TableColumnDataModel } from '../../../../common-classes/TableColumnData.model';
import {DataTablesService} from "../../../../data-tables.service";
import {TimeService} from "../../../../scheduler/time/time.service";
import { ApiService } from '../../../../api.service';
import {Router} from "@angular/router";

@Component({
  selector: 'appointment-intents-table',
  templateUrl: './appointment-intents-table.component.html'
})
export class AppointmentIntentsTableComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() intentData: AppointmentIntent[];
  @Input() selectedClient: Client;
  @Input() preference: preference;
  @Input() loaded: boolean;
  @Input() errorLoading: boolean;
  @Input() loading = false;
  @Input() noIntentsFoundProp: string;
  @Input() columnToAdd: string;
  idProp = 'appointmentIntentId';
  preferenceDefinedColumns: string[];
  displayedColumns = this.dataTablesService.getDefaultAppointmentIntentDisplayedColumns();
  columnDefs: TableColumnDataModel[] = this.dataTablesService.getDefaultAppointmentIntentColumnDefs();
  tableData = new MatTableDataSource<AppointmentIntent>();
  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.tableData.sort = this.sort;
  }

  constructor(
    private responsiveService: ResponsiveService,
    private dataTablesService: DataTablesService,
    private timeService: TimeService,
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit() {
  }
  ngAfterViewChecked(){
    this.responsiveService.addHorizontalScrollForTableOnMobile();
  }
  organizeApptData(){
    if(this.intentData === null){
      this.intentData = [];
    }
    for(let i = 0, x = this.intentData.length; i < x; i++){
      this.intentData[i] = this.timeService.getAppointmentIntentDateTimeDisplayPropertiesWithTimezone(this.intentData[i], this.selectedClient.timeZoneCode.timeZoneCode);
    }
    this.tableData.data = this.intentData;
    this.tableData.sortingDataAccessor = (obj, property) => this.dataTablesService.getProperty(obj, property);
    this.tableData.sort = this.sort;
    this.loaded = true;
    this.loading = false;
    this.errorLoading = false;
  }
  getDisplayedColumns(){
    let columnsForTable = this.dataTablesService.getDisplayedAppointmentIntentColumns(this.preferenceDefinedColumns, this.columnDefs, this.preference, this.responsiveService.onMobileViewPort, this.columnToAdd);
    this.displayedColumns = columnsForTable.appointmentFieldList;
    this.columnDefs = columnsForTable.columns;
    this.organizeApptData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.intentData !== undefined && changes.intentData.currentValue !== undefined){
        this.preferenceDefinedColumns = this.dataTablesService.getDefaultAppointmentIntentDisplayedColumns();
        this.getDisplayedColumns();
    }
  }
  bookIntent(intent: AppointmentIntent){
    this.router.navigate(['/scheduler'], {state: {apptintentid: intent.appointmentIntentId}})
  } 
}
