import {Injectable, Injector} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import { map } from "rxjs/operators";
import {catchError} from "rxjs/internal/operators";
import {
    Client, ClientAccount, MultipleAppts, preference,
    RecurringAppointment
} from "../common-classes/app-objects.model";
import * as _ from "lodash";
import {PackageSold} from "../store/packages/packages.model";
import {StoredCardInfo} from "./client-profile/client-profile-container/client-profile-container.component";
import {CreditCard} from "./view-invoices/payment-portals/payments.model";
import {ResponsiveService} from "../responsive.service";
import {WaitlistService} from "../waitlist/waitlist.service";
import {Subject} from 'rxjs';
import {WaitList} from '../../models/WaitList.model';
import {Appointment} from '../../models/Appointment.model';
import {CustomField} from '../../models/CustomField.model';
import {SchedulerPreferenceService} from '../scheduler-preference.service';
import {AppointmentIntent} from "../../models/AppointmentIntent.model";

@Injectable()

export class ClientAccountService {
    loggedInClient: ClientAccount;
    selectedClient: Client;
    onHoldStatus: string = 'On Hold';
    blockedStatus: string = 'Blocked';
    private apiUrl: string;
    private nonLoggedInApiUrl: string;
    responsiveService: ResponsiveService;
    waitListService: WaitlistService;
    movedFromDetailWhenBookingNotAllowed = new Subject();
    schedulerPreferenceService: SchedulerPreferenceService;
    constructor(private http: HttpClient, private injector: Injector){
        try {
            this.apiUrl = sessionStorage.getItem('csAPIBase') + 'csl/';
            this.nonLoggedInApiUrl = sessionStorage.getItem('csAPIBase') + 'cs/';
        } catch (e) {

        }
        this.responsiveService = injector.get(ResponsiveService);
        this.waitListService = injector.get(WaitlistService);
        this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
    }

    private handleError(error: any): Promise<any>{
        return Promise.reject(error);
    }

    setLoggedInClient(loggedInClient: ClientAccount){
        this.loggedInClient = loggedInClient;
        if(this.loggedInClient === null)
            this.selectedClient = null;
    }

    addConnectClientToClientList(preference: preference){
        let showLoginBtns = this.responsiveService.showLoginRegisterBtnLogic(preference);
        if(showLoginBtns.showRegisterBtn && preference.allowAddConnectedClient && this.loggedInClient !== undefined && this.loggedInClient !== null && this.loggedInClient.clientList !== null && this.loggedInClient.clientList.length > 1){
            let connectClient: Client = this.createNewClient();
            connectClient.fullName = preference.labelMap.clientProfileAddNewClientLabel;
            let connectClientAlreadyAdded = false;
            for(let i = 0, x = this.loggedInClient.clientList.length; i < x; i++){
                if(this.loggedInClient.clientList[i].fullName === connectClient.fullName){
                    connectClientAlreadyAdded = true;
                }
            }
            if(!connectClientAlreadyAdded)
                this.loggedInClient.clientList.push(connectClient);
        }
    }

    addClientToClientList(client: Client){
        if(this.loggedInClient !== undefined && this.loggedInClient !== null && this.loggedInClient.clientList !== null){
            let addConnectClientOptionToList = false;
            let indexToInsertAt = this.loggedInClient.clientList.length - 1;
            if(this.loggedInClient.clientList.length === 1){ // have added in the first connected client so we need to update list to include add and connect client at bottom
                addConnectClientOptionToList = true;
                indexToInsertAt = this.loggedInClient.clientList.length;
            }

            if(indexToInsertAt < 0)
                indexToInsertAt = 0;
            this.loggedInClient.clientList.splice(indexToInsertAt, 0, client);
            return addConnectClientOptionToList;
        } else
            return false;
    }

    createNewClient(emailAddress?: string){
        let newClient = new Client(null,
            null,
            null,
            [],
            sessionStorage.getItem('locale'),
            'Active',
            'WebClient',
            new Date().getTime(),
            null, false, null, emailAddress)
        return newClient;
    }

    setSelectedClient(selectedClient: Client, fields: CustomField[]){
        if(selectedClient && selectedClient.fields === null && selectedClient.fieldDataList !== undefined){
            selectedClient.fields = _.cloneDeep(fields);
            for(let i = 0, x = selectedClient.fields.length; i < x; i++){
                for(let j = 0, y = selectedClient.fieldDataList.length; j < y; j++){
                    if(selectedClient.fieldDataList[j].schedulerPreferenceFieldDefnId === selectedClient.fields[i].schedulerPreferenceFieldDefnId){
                        selectedClient.fields[i].value = selectedClient.fieldDataList[j].value;
                    }
                }
            }
        }
        this.selectedClient = selectedClient;
    }

    getSelectedClient(preference: preference):Client{
        if(this.selectedClient){
            for(let i = 0, x = this.loggedInClient.clientList.length; i < x; i++){
                if(this.loggedInClient.clientList[i].clientId === this.selectedClient.clientId){
                    return this.loggedInClient.clientList[i];
                }
            }
        } else if(this.loggedInClient) {
            this.setSelectedClient(this.loggedInClient.clientList[0], preference.schedulerPreferenceFieldDefnList);
        }
        return this.selectedClient;
    }

    setSelectedClientOnComponent(preference: preference){
        if(this.selectedClient !== undefined && this.selectedClient !== null){
            for(let i = 0, x = this.loggedInClient.clientList.length; i < x; i++){
                if(this.loggedInClient.clientList[i].clientId === this.selectedClient.clientId){
                    return this.loggedInClient.clientList[i];
                }
            }
        } else if(this.loggedInClient !== undefined && this.loggedInClient !== null) {
            this.setSelectedClient(this.loggedInClient.clientList[0], preference.schedulerPreferenceFieldDefnList);
            return this.loggedInClient.clientList[0];
        } else {
            return null;
        }
    }

    selectClientBasedOnLoggedInEmail(counter?: number){//TT-6009
        if(counter === undefined){
            counter = 0;
        } else {
            counter++;
        }
        if(this.loggedInClient !== undefined) {
            let matchedClient: number = -1;
            for (let i = 0, x = this.loggedInClient.clientList.length; i < x; i++) {
                if (this.loggedInClient.clientAccountId === this.loggedInClient.clientList[i].clientAccountId) {
                    matchedClient = i;
                    break;
                }
            }
            if (matchedClient !== null) {
                return this.loggedInClient.clientList[matchedClient];
            } else {
                return this.loggedInClient.clientList[0];
            }
        } else if(counter < 10) {
            setTimeout(() => {
                counter++;
                this.selectClientBasedOnLoggedInEmail(counter);
            }, 250)
        }
    }

    updateClientInClientList(updatedClient: Client){//TT-6011
        for(let i = 0, x = this.loggedInClient.clientList.length; i < x; i++){
            if(this.loggedInClient.clientList[i].clientId === updatedClient.clientId){
                this.loggedInClient.clientList[i] = updatedClient;
            }
        }
    }


    getApptsByStatus(clientId: number, status: string){
        return this.http
            .get<Appointment[]>(this.apiUrl + 'appointments/' + clientId + '/' + status, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: Appointment[]) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getRepeatingAppts(clientId){
        return this.http
            .get<RecurringAppointment[]>(this.apiUrl + 'recurringappointments/' + clientId, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: RecurringAppointment[]) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getWaitlistRegistrations(clientId){
        return this.http
            .get<WaitList[]>(this.apiUrl + 'waitlistRegistrations/' + clientId, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: WaitList[]) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    // @ts-ignore
                    if(responseData === null){
                        return [];
                    } else {
                        let waitListExpanded: WaitList[] = [];
                        for (let i = 0, x = responseData.length; i < x; i++) {
                            let waitlists: WaitList[] = this.waitListService.formatWaitlistForSingleTime(responseData[i])
                            for(let j = 0, y = waitlists.length; j < y; j++)
                                waitListExpanded.push(waitlists[j]);
                        }
                        waitListExpanded.sort((a,b) => (a.preferredDateStart < b.preferredDateStart) ? -1 : 1);
                        return waitListExpanded;
                    }
                }),
                catchError(this.handleError)
            )
    }

    getRepeatingApptDetail(repeatingApptId: string){
        return this.http
            .get<MultipleAppts>(this.apiUrl + 'recurringappointment/' + repeatingApptId, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: MultipleAppts) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getApptDetail(calendarId: string){
        return this.http
            .get<Appointment>(this.apiUrl + 'appointment/' + calendarId, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: Appointment) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }


    getInvoicesByStatus(clientId: number, status: string){
        return this.http
            .get<any[]>(this.apiUrl + 'invoices/' + clientId + '/' + status, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: any[]) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    formatQuantityUsedOnPackageSold(packageSold: PackageSold): PackageSold {
        const usedQuantityString = packageSold.usedQuantity + '/' + packageSold.maxQuantity;
        packageSold.quantityUsedDisplay = usedQuantityString;
        return packageSold;
    }

    getPackagesSoldByClientId(clientId: number, statusList?: string){
        let paramsToPass = new HttpParams();
        paramsToPass = paramsToPass.append('statusList', statusList);
        return this.http
            .get<PackageSold[]>(this.nonLoggedInApiUrl + 'packageSold/' + clientId, {
                observe: 'body',
                responseType: 'json',
                params: paramsToPass
            })
            .pipe(
                map((responseData: PackageSold[]) => {
                    for (let i = 0, x = responseData.length; i < x; i++) {
                        this.formatQuantityUsedOnPackageSold(responseData[i]);
                    }
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getPackageSoldByClientAccountId(clientAccountId: number, statusList?: string){
        let paramsToPass = new HttpParams();
        paramsToPass = paramsToPass.append('statusList', statusList);
        return this.http
            .get<PackageSold[]>(this.nonLoggedInApiUrl + 'packageSold/clientAccountId/' + clientAccountId, {
                observe: 'body',
                responseType: 'json',
                params: paramsToPass
            })
            .pipe(
                map((responseData: PackageSold[]) => {
                    for (let i = 0, x = responseData.length; i < x; i++) {
                        this.formatQuantityUsedOnPackageSold(responseData[i]);
                    }
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getAppointmentsByPackageSold(packageSoldId: number){
        return this.http
            .get<Appointment[]>(this.apiUrl + 'packageSold/' + packageSoldId + '/appointments', {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: Appointment[]) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getStoredPaymentInfoByClientId(clientId: number){
        return this.http
            .get<StoredCardInfo>(this.nonLoggedInApiUrl + 'client/' + clientId + '/paymentInfo', {
                observe: 'body',
                responseType: 'json'
            })
            .pipe(
                map((responseData: StoredCardInfo) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    storeCardInfoOnClient(selectedClient: Client, storedCardInfo: StoredCardInfo){
        selectedClient.storedCardInfo = storedCardInfo;
        if(storedCardInfo.stripeSetup || storedCardInfo.squareSetup || storedCardInfo.lumaPaySetup)
            selectedClient.showPaymentInfo = true;
        else
            selectedClient.showPaymentInfo = false;
        selectedClient.stripeCreditCards = [];
        selectedClient.squareCreditCards = [];
        if(storedCardInfo.stripe && storedCardInfo.stripe.data){
            for(let i = 0, x = storedCardInfo.stripe.data.length; i < x; i++){
                selectedClient.stripeCreditCards.push(storedCardInfo.stripe.data[i]);
            }

        }
        if(storedCardInfo.square && storedCardInfo.square.cards){
            for(let i = 0, x = storedCardInfo.square.cards.length; i < x; i++){
                selectedClient.squareCreditCards.push(storedCardInfo.square.cards[i]);
            }

        }
        return selectedClient;
    }

    deleteCard(clientId: number, cardId: string, cardType: string){
        let paramsToPass = new HttpParams();
        if(cardType === 'STRIPE')
            paramsToPass = paramsToPass.append('stripePaymentMethodId', cardId);
        if(cardType === 'SQUARE')
            paramsToPass = paramsToPass.append('squareCardId', cardId);
        return this.http
            .delete<StoredCardInfo>(this.apiUrl + 'client/' + clientId + '/paymentInfo', {
                observe: 'body',
                responseType: 'json',
                params: paramsToPass
            })
            .pipe(
                map((responseData: StoredCardInfo) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    createCreditCardsArray(client: Client){
        let creditCards: CreditCard[] = [];
        if(client.stripeCreditCards !== undefined && client.stripeCreditCards.length > 0){
            for(let i = 0, x = client.stripeCreditCards.length; i < x; i++){
                let cardToAdd = new CreditCard();
                cardToAdd.brand = client.stripeCreditCards[i].card.brand;
                cardToAdd.expiration = client.stripeCreditCards[i].card.expMonth + "/" + client.stripeCreditCards[i].card.expYear;
                cardToAdd.last4 = client.stripeCreditCards[i].card.last4;
                cardToAdd.zip = client.stripeCreditCards[i].billingDetails.address.postalCode;
                cardToAdd.stripeInfo = client.stripeCreditCards[i];
                creditCards.push(cardToAdd);
            }
        }
        if(client.squareCreditCards !== undefined && client.squareCreditCards.length > 0){
            for(let i = 0, x = client.squareCreditCards.length; i < x; i++) {
                let cardToAdd = new CreditCard();
                cardToAdd.brand = client.squareCreditCards[i].card_brand;
                cardToAdd.expiration = client.squareCreditCards[i].exp_month + "/" + client.squareCreditCards[i].exp_year;
                cardToAdd.last4 = client.squareCreditCards[i].last_4;
                cardToAdd.zip = null;
                cardToAdd.squareInfo = client.squareCreditCards[i];
                creditCards.push(cardToAdd);
            }
        }
        return creditCards;
    }

    /***
     * Get Appointment Intents for client
     */
    getAppointmentIntentsByClientId(clientId: number, active: boolean){
      return this.http
          .get<AppointmentIntent>(this.apiUrl + 'appointmentIntentList/'+clientId+'?active='+active, {
              observe: 'body',
              responseType: 'json'
          })
          .pipe(
              map((responseData: AppointmentIntent) => {
                  return responseData;
              }),
              catchError(this.handleError)
          )
    }
}
