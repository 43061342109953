import {AfterViewChecked, Component, OnInit, ViewChild} from '@angular/core';
import {
    Client, ClientAccount, currency, Invoice, miniWebsite, preference,
    schedulerLink
} from "../../../common-classes/app-objects.model";
import {MatSort, MatTableDataSource} from "@angular/material";
import {ClientAccountService} from "../../client-account.service";
import {TagsService} from "../../../tags.service";
import {CurrencyPipe, DatePipe, TitleCasePipe} from "@angular/common";
import {BusinessService} from "../../../business.service";
import {TimeService} from "../../../scheduler/time/time.service";
import {ActivatedRoute, Router} from "@angular/router";
import * as moment from "moment/moment";
import * as momentTimezone from "moment-timezone/moment-timezone";
import {ResponsiveService} from "../../../responsive.service";
import {AuthService} from "../../../auth/auth.service";
import {SchedulerPreferenceService} from '../../../scheduler-preference.service';

@Component({
  selector: 'app-view-invoices-list',
  templateUrl: './view-invoices-list.component.html'
})
export class ViewInvoicesListComponent implements OnInit, AfterViewChecked {
    pageLoaded: boolean = false;
    invoicesLoaded: boolean = false;
    errorLoading: boolean = false;
    loadingInvoices: boolean = false;
    preference: preference;
    currency: currency;
    clientAccount: ClientAccount;
    selectedClient: Client;
    titleBarProp: string;
    noInvoicesFoundProp: string;
    displayedColumns = ['invoiceNumber', 'invoiceType', 'invoiceDate', 'invoiceTotalAmount', 'balanceAmount'];
    columnDefs = [
        {name: 'invoiceNumber', title: 'Invoice ID', filter: null},
        {name: 'invoiceType', title: 'Type', filter: 'titleCase'},
        {name: 'invoiceDate', title: 'Date', filter: 'date',
            filterFormats: [{format: 'EEEE, MMM d, y', concatenator: false, useMoment: false}]},
        {name: 'invoiceTotalAmount', title: 'Total', filter: 'currency'},
        {name: 'balanceAmount', title: 'Remaining Balance', filter: 'currency'},
    ]
    invoices = new MatTableDataSource<Invoice>();
    private sort: MatSort;
    @ViewChild(MatSort, {static: false}) set matSort(ms: MatSort){
        this.sort = ms;
        this.setDataSourceAttributes();
    }
    setDataSourceAttributes() {
        this.invoices.sort = this.sort;
    }
    invoiceStatuses = [
        {'status': 'OPEN', 'selected': true, 'buttonLabel': 'invoicesOpenBtnLabel', 'titleBarProp': 'invoicesOpenListTitle', 'noInvoicesFoundProp': 'invoicesNoOpenMsg'},
        {'status': 'CLOSED', 'selected': false, 'buttonLabel': 'invoicesClosedBtnLabel', 'titleBarProp': 'invoicesClosedListTitle', 'noInvoicesFoundProp': 'invoicesNoClosedMsg'},
        {'status': 'VOID', 'selected': false, 'buttonLabel': 'invoicesVoidedBtnLabel', 'titleBarProp': 'invoicesVoidedListTitle', 'noInvoicesFoundProp': 'invoicesNoVoidedMsg'}
    ];
    constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private route: ActivatedRoute, private timeService: TimeService,
                private tagsService: TagsService, private router: Router, private datePipe: DatePipe, private responsiveService: ResponsiveService,
                private currencyPipe: CurrencyPipe, private titleCasePipe: TitleCasePipe, private authService: AuthService, private schedulerPreferenceService: SchedulerPreferenceService) {
        this.preference = schedulerPreferenceService.schedulerPreference;
        moment.locale(sessionStorage.getItem('locale'));
    }
    goBack(){
        if(this.authService.isAuth()){
            this.router.navigate(['my-account'], {relativeTo: this.route.root})
        } else {
            this.router.navigate([''], {relativeTo: this.route.root})
        }
    }

    viewInvoiceDetails(invoice: Invoice){
        this.router.navigate([invoice.invoiceHash], {relativeTo: this.route, state: {invoice: invoice}});
    }

    viewInvoiceDetailsNonActions(invoice){
        if(this.responsiveService.onMobileViewPort){
            this.viewInvoiceDetails(invoice);
        }
    }

    selectClient(changeEvent: any){
        let client = changeEvent.client;
        let event = changeEvent.event;
        if(client.clientId !== this.selectedClient.clientId && event.isUserInput){
            this.selectedClient = client;
            this.clientAccountService.selectedClient = this.selectedClient;
            this.getInvoicesByStatus();
        }
    }

    getProperty = (obj, path) => (
        path.split('.').reduce((o, p) => o && o[p], obj)
    );

    getFilterDisplay = function(element, col){
        let returnStringForDisplay = '';
        switch(col.filter){
            case 'date':
                for(let i = 0, x = col.filterFormats.length; i < x; i++){
                    if(!col.filterFormats[i].concatenator && col.filterFormats[i].useMoment){
                        if(momentTimezone.tz.zone(this.selectedClient.timeZoneCode.timeZoneCode) === null){
                            for(let i = 0, x = this.momentTimezoneService.allTimezones.zones.length; i < x; i++){
                                if(this.momentTimezoneService.allTimezones.zones[i].indexOf(this.selectedClient.timeZoneCode.timeZoneCode)!==-1){
                                    momentTimezone.tz.add(this.momentTimezoneService.allTimezones.zones[i]);
                                }
                            }
                        }
                        returnStringForDisplay = returnStringForDisplay +
                            momentTimezone(element[col.name]).tz(this.selectedClient.timeZoneCode.timeZoneCode).format(col.filterFormats[i].format);
                    } else if(!col.filterFormats[i].concatenator && !col.filterFormats[i].useMoment){
                        let timeZoneCode = sessionStorage.getItem("browserTimezone");
                        if(this.selectedClient.timeZoneCode !== null)
                            timeZoneCode = this.selectedClient.timeZoneCode.timeZoneCode;
                        returnStringForDisplay = returnStringForDisplay +
                            this.datePipe.transform(element[col.name], col.filterFormats[i].format, timeZoneCode, sessionStorage.getItem('locale'));
                    } else {
                        returnStringForDisplay = returnStringForDisplay + col.filterFormats[i].format;
                    }
                }
                break;
            case 'currency':
                returnStringForDisplay = this.currencyPipe.transform(element[col.name], this.currency.currencyCode, "symbol","1.2-2", sessionStorage.getItem('locale'));
                break;
            case 'titleCase':
                returnStringForDisplay = this.titleCasePipe.transform(element[col.name]);
                break;
        }
        return returnStringForDisplay;
    };

    getInvoicesByStatus(){
        this.loadingInvoices = true;
        let invoiceStatusString = '';
        for(let i = 0, x = this.invoiceStatuses.length; i < x; i ++){
            if(this.invoiceStatuses[i].selected){
                this.titleBarProp = this.invoiceStatuses[i].titleBarProp;
                this.noInvoicesFoundProp = this.invoiceStatuses[i].noInvoicesFoundProp;
                invoiceStatusString = invoiceStatusString + this.invoiceStatuses[i].status;
            }
        }
        this.pageLoaded = true;
        this.clientAccountService.getInvoicesByStatus(this.selectedClient.clientId, invoiceStatusString)
            .subscribe((invoices: any[]) => {
                if(invoices === null){
                    invoices = [];
                }
                for(let i = 0, x = invoices.length; i < x; i++){
                    if(!invoices[i].invoiceType)
                        invoices[i].invoiceType = 'INVOICE';
                    if (!invoices[i].invoiceNumber)
                        invoices[i].invoiceNumber = String(invoices[i].invoiceId);
                }
                this.invoices.data = invoices;
                this.invoices.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
                this.invoices.sort = this.sort;
                this.invoicesLoaded = true;
                this.loadingInvoices = false;
                this.errorLoading = false;
            }, error => {
                this.invoicesLoaded = false;
                this.loadingInvoices = false;
                this.errorLoading = true;
            })

    }
    selectStatus = function(status: any){
        for(let i = 0, x = this.invoiceStatuses.length; i < x; i++){
            if(this.invoiceStatuses[i].status === status.status){
                this.invoiceStatuses[i].selected = true;
            } else {
                this.invoiceStatuses[i].selected = false;
            }
        }
        this.getInvoicesByStatus();
    };

    ngAfterViewChecked(){
        this.responsiveService.setHeightForEmbedAccount();
    }

    ngOnInit() {
        if(!this.authService.isAuth()){
            this.goBack();
        } else {
            if(!this.responsiveService.onMobileViewPort)
                this.displayedColumns.push('actions');
            if(this.businessService.business){
                if(this.businessService.business.status !== 'ACTIVE'){
                    this.invoicesLoaded = false;
                    this.errorLoading = true;
                    // this.miniWebsite = this.businessService.createErrorMiniWebsite('inactive');
                } else {
                    this.currency = this.businessService.currency;
                    this.clientAccount = this.clientAccountService.loggedInClient;
                    if (this.clientAccountService.selectedClient !== undefined && this.clientAccountService.selectedClient !== null) {
                        for (let i = 0, x = this.clientAccount.clientList.length; i < x; i++) {
                            if (this.clientAccount.clientList[i].clientId === this.clientAccountService.selectedClient.clientId) {
                                this.selectedClient = this.clientAccount.clientList[i];
                            }
                        }
                    } else {
                        this.selectedClient = this.clientAccountService.selectClientBasedOnLoggedInEmail();
                        this.clientAccountService.setSelectedClient(this.selectedClient, this.preference.schedulerPreferenceFieldDefnList);
                    }
                    if(this.selectedClient === undefined && this.selectedClient !== null &&
                        this.clientAccountService.loggedInClient !== undefined && this.clientAccountService.loggedInClient !== null){
                        this.selectedClient = this.clientAccountService.loggedInClient.clientList[0];
                    }
                    this.getInvoicesByStatus();
                }

            } else {
                this.invoicesLoaded = false;
                this.errorLoading = true;
                // this.miniWebsite = this.businessService.createErrorMiniWebsite('errorLoading');
            }
        }

    }

}
