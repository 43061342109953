import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'tt-button-save',
  template: `
    <span *ngIf="!saving && !errorSaving && !saved"><i *ngIf="showSaveIcon" [className]="saveIcon"></i> {{saveText}}</span>
    <span *ngIf="saved"><i class="fas fa-check"></i> {{savedText}}</span>
    <span *ngIf="saving"><i *ngIf="showSpinner" class="fa fa-spinner fa-spin fa-fw"></i> {{savingText}}</span>
    <span *ngIf="errorSaving"><i class="far fa-times-circle"></i> {{errorSavingText}}</span>
  `,
  styles: []
})
export class ButtonSaveComponent implements OnInit, OnChanges {
  @Input() saving: boolean;
  @Input() saved: boolean;
  @Input() errorSaving: boolean;
  @Input() showSaveIcon: boolean = false;
  @Input() saveIcon: string = 'la la-save';
  @Input() saveText: string;
  @Input() savedText: string;
  @Input() savingText: string;
  @Input() errorSavingText: string;
  @Input() noTextOnBtn: boolean = false;
  @Input() showSpinner: boolean = true;
  @Output() onSavedDismissed = new EventEmitter();
  @Output() onErrorSavingDismissed = new EventEmitter();
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('saved') && changes.saved.currentValue)
      setTimeout(() => {
        this.saved = false;
        this.onSavedDismissed.emit();
      }, 3000);
    if (changes.hasOwnProperty('errorSaving') && changes.errorSaving.currentValue)
      setTimeout(() => {
        this.errorSaving = false;
        this.onErrorSavingDismissed.emit();
      }, 3000);
  }

  ngOnInit(): void {
    if (!this.saveText && !this.noTextOnBtn)
      this.saveText = 'Save';
    if (!this.savedText && !this.noTextOnBtn)
      this.savedText = 'Saved!';
    if (!this.savingText && !this.noTextOnBtn)
      this.savingText = 'Saving...';
    if (!this.errorSavingText && !this.noTextOnBtn)
      this.errorSavingText = 'Error Saving!';
  }

}
