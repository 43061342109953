import { Injectable } from '@angular/core';
import {ClassSession} from '../models/ClassSession.model';

@Injectable({
  providedIn: 'root'
})
export class ClassSessionService {

  constructor() { }

  configureListElement(cs: ClassSession) {
    const classSession: ClassSession = new ClassSession();
    classSession.copyPropsFromAPI(cs);
    classSession.currentAvailable = classSession.maxCapacity - classSession.currentCapacity;
    classSession.subStatus = classSession.status;
    return classSession;
  }
}
