import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatFunctionsService {

  constructor() { }

  formatArrayIntoCommaSeparatedString(arrayToFormat: string[] | number[]): string {
    let stringToReturn = '';
    if (arrayToFormat !== undefined && arrayToFormat !== null && arrayToFormat.length !== 0) {
      for (let i = 0, x = arrayToFormat.length; i < x; i++) {
        if (i === 0)
          stringToReturn += arrayToFormat[i];
        else
          stringToReturn = stringToReturn + ',' + arrayToFormat[i];
      }
    } else
      stringToReturn = undefined;
    return stringToReturn;
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i)
      if (a[i] !== b[i]) return false;
    return true;
  }

  turnStringArrayIntoNumberArray(arrayToParse: string[]): number[] {
    const arrayToReturn: number[] = [];
    for (let i = 0, x = arrayToParse.length; i < x; i++)
      arrayToReturn.push(Number(arrayToParse[i]));
    return arrayToReturn;
  }

  removeUnderscoresInLabels(stringWithUnderscore: string): string {
    return stringWithUnderscore.replace(/_/g, ' ');
  }
}
