import {Injectable, Injector} from '@angular/core';
import {ApiService} from '../../../../api.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Invoice} from '../../../../common-classes/app-objects.model';
import {map} from 'rxjs/operators';
import {PaymentIntentResponse} from '../../../../../models/Stripe/PaymentIntent.model';
declare var Stripe;

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  apiService: ApiService;
  stripeJs: string = 'https://js.stripe.com/v3/';

  constructor(private injector: Injector, private http: HttpClient) {
    this.apiService = injector.get(ApiService);
  }

  addStripeJSFile(){
    let stripeJSFileAdded = false;
    let scripts = document.getElementsByTagName("script");
    for(let i = 0, x = scripts.length; i < x; i++){
      if(scripts[i].getAttribute('src')!== null && scripts[i].getAttribute('src')===this.stripeJs){
        stripeJSFileAdded = true;
      }
    }
    if(!stripeJSFileAdded){
      let node = document.createElement('script');
      node.src = this.stripeJs;
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  createStripePaymentForm(counter: number, paymentIntent: PaymentIntentResponse) {
    if (typeof Stripe !== "undefined" && counter < 20) {
      let stripe = Stripe(paymentIntent.stripePublishableKey);
      stripe.confirmCardPayment(paymentIntent.secret, {}).then(function(result) {
        let newUrl = window.location.href.substring(0, window.location.href.indexOf('?'));
        window.location.href = newUrl + '?paying=false';
      })
    } else {
      counter++;
      setTimeout(() => this.createStripePaymentForm(counter, paymentIntent), 100)
    }
  }

  chargeStripeCardOnFile(invoice: Invoice, paymentMethodId: string, amt: number) {
    let paramsToPass = new HttpParams();
    paramsToPass = paramsToPass.append('amt', JSON.stringify(amt));
    paramsToPass = paramsToPass.append('stripePaymentMethodId', paymentMethodId);
    return this.http
        .post<PaymentIntentResponse>(this.apiService.apiUrl + 'stripe/paymentIntent/' + invoice.invoiceHash, {}, {observe: "body", responseType: "json", params: paramsToPass})
        .pipe(
            map((responseData: PaymentIntentResponse) => {
              this.addStripeJSFile();
              this.createStripePaymentForm(0, responseData);
              return responseData;
            })
        )
  }
}
