import {FormGroup} from '@angular/forms';

export enum SchedulerPanelValues {
    welcome = 'welcome',
    locationSuperGroups = 'locationSuperGroups',
    locationGroups = 'locationGroups',
    locations = 'locations',
    staff = 'staff',
    reasonSuperGroups = 'reasonSuperGroups',
    reasonGroups = 'reasonGroups',
    reasons = 'reasons',
    appointmentIntent = 'appointmentIntent',
    time = 'time',
    clientInfo = 'clientInfo',
    finalConfirmation = 'finalConfirmation'
}

export class schedulerPanel {
    constructor(
        public panel: SchedulerPanelValues,
        public panelOrder: number,
        public displayed: boolean,
        public index: number,
        public stepOptional: boolean,
        public panelTitleProperty: string,
        public panelTextProperty: string,
        public panelErrorLoadingProperty: string,
        public hasSelectedValues: boolean,
        public csFilterObjProps?: string[],
        public idPropName?: string,
        public panelNoObjectsProperty?: string,
        public formGroup?: FormGroup,
        public recordNavigatePanel?: string) {
    }
}
