import {Injectable, Injector} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {catchError} from "rxjs/internal/operators";
import {FormatFunctionsService} from '../format-functions.service';
import {
    WaitList,
    WaitlistClaim,
    WaitListDate,
    WaitListDateTime,
    WaitlistRegistrationDetail
} from '../../models/WaitList.model';
import {Appointment} from '../../models/Appointment.model';
import {MilitaryTimeConversion} from '../../pipes/militaryTimeConversion.pipe';
import {SchedulerPreferenceService} from '../scheduler-preference.service';
import * as _ from 'lodash';
import {TableColumnDataFilterFormat, TableColumnDataModel} from '../common-classes/TableColumnData.model';
import {DataTablesService} from '../data-tables.service';
import {MultipleAppts} from '../common-classes/app-objects.model';

@Injectable()

export class WaitlistService {
    private apiUrl: string;
    formatFnsService: FormatFunctionsService;
    schedulerPreferenceService: SchedulerPreferenceService;
    dataTablesService: DataTablesService;
    // private headers = new HttpHeaders( {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('token')});
    constructor(private http: HttpClient, private injector: Injector, private militaryTime: MilitaryTimeConversion){
        this.formatFnsService = injector.get(FormatFunctionsService);
        this.schedulerPreferenceService = injector.get(SchedulerPreferenceService)
        try {
            this.apiUrl = sessionStorage.getItem('csAPIBase') + 'cs/'
        } catch (e) {

        }
        this.dataTablesService = injector.get(DataTablesService);
    }

    private handleError(error: any): Promise<any>{
        return Promise.reject(error);
    }

    getWaitlist(waitlistHash: string){
        return this.http
            .get<WaitList>(this.apiUrl + 'waitlist/' + waitlistHash, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: WaitList) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getWaitlistClaim(waitlistInviteId: string){
        return this.http
            .get<WaitlistClaim>(this.apiUrl + 'waitlist/invite/' + waitlistInviteId, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: WaitlistClaim) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getWaitlistHoldsByClient(clientHash: string){
        return this.http
            .get<WaitlistClaim[]>(this.apiUrl + 'waitlistholds/client/' + clientHash, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: WaitlistClaim[]) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    claimAppt(waitlistInviteId: string){
        return this.http
            .post<{waitlistClaim: WaitlistClaim, appointment: Appointment}>(this.apiUrl + 'waitlist/claim/' + waitlistInviteId, null, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: {waitlistClaim: WaitlistClaim, appointment: Appointment, courseSetRegistration: MultipleAppts}) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    removeFromWaitlist(waitlistId: number){
        return this.http
            .put<WaitList>(this.apiUrl + 'waitlist/remove/' + waitlistId, null, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: WaitList) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    cancelWaitlistDateTime(waitlistId: number, waitlistDateId: number, waitListDateTimeIds: number[]){
        let paramsToPass = new HttpParams();
        // let waitListDateTimeIdString: string = "";
        // for(let i = 0, x = waitListDateTimeIds.length; i < x; i++){
        //     if(waitListDateTimeIdString === "")
        //         waitListDateTimeIdString = waitListDateTimeIds[i].toString();
        //     else
        //         waitListDateTimeIdString = waitListDateTimeIdString + "," + waitListDateTimeIds[i];
        // }
        let waitListDateTimeIdString: string = this.formatFnsService.formatArrayIntoCommaSeparatedString(waitListDateTimeIds);
        paramsToPass = paramsToPass.append('waitListDateTimeIdList', waitListDateTimeIdString);
        // paramsToPass = paramsToPass.append('waitListDateTimeIdList', JSON.stringify(waitListDateTimeIds));
        return this.http
            .put<WaitList>(this.apiUrl + 'waitlist/remove/' + waitlistId + '/waitlistDate/' + waitlistDateId, null,
                {observe: "body", responseType: 'json', params: paramsToPass})
            .pipe(
                map((responseData: WaitList) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    formatWaitlistForSingleTime(waitList:WaitList): WaitList[]{
        let waitLists: WaitList[] = [];
        if(waitList.waitListDateList !== null && waitList.waitListDateList.length > 0){
            for(let j = 0, y = waitList.waitListDateList.length; j < y; j++){
                for(let k = 0, z = waitList.waitListDateList[j].waitListDateTimeList.length; k < z; k++){
                    let waitListToAdd: WaitList = new WaitList();
                    waitListToAdd.copyPrimaryFieldsFromExistingWaitList(waitList);
                    waitListToAdd.selectedDateTimeFromList = waitList.waitListDateList[j].waitListDateTimeList[k];
                    if (waitList.waitListDateList[j].date) {
                        waitListToAdd.preferredDate = waitList.waitListDateList[j].waitListDateTimeList[k].startDateTimeUTC;
                        waitListToAdd.preferredDateStart = waitList.waitListDateList[j].waitListDateTimeList[k].startDateTimeUTC;
                        waitListToAdd.preferredDateEnd = waitList.waitListDateList[j].waitListDateTimeList[k].endDateTimeUTC;
                    }
                    waitLists.push(waitListToAdd);
                }
            }
        } else {
            waitLists.push(waitList);
        }
        return waitLists;
    }

    removeTimeSlot(waitList: WaitList, waitListDate: WaitListDate, waitListDateTime: WaitListDateTime) {
        for (let i = 0, x = waitList.waitListDateList.length; i < x; i++)
            if (waitList.waitListDateList[i].date === waitListDate.date)
                for (let j = 0; j < waitList.waitListDateList[i].waitListDateTimeList.length; j++)
                    if (waitList.waitListDateList[i].waitListDateTimeList[j].startTime === waitListDateTime.startTime) {
                        waitList.waitListDateList[i].waitListDateTimeList.splice(j, 1);
                    }


    }

    removeTimeSlotDate(waitList: WaitList, waitListDate: WaitListDate) {
        for (let i = 0, x = waitList.waitListDateList.length; i < x; i++)
            if (waitList.waitListDateList[i].date === waitListDate.date)
                waitList.waitListDateList.splice(i, 1);

    }

    getWaitlistRegistrationDetail(waitlistId: string, waitListDateTimeId: string){
        return this.http
            .get<WaitlistRegistrationDetail>(this.apiUrl + 'waitlistRegistrationDetail/' + waitlistId, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: WaitlistRegistrationDetail) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    let waitLists: WaitList[] = this.formatWaitlistForSingleTime(responseData.waitlist)
                    for(let i = 0, x = waitLists.length; i < x; i++){
                        if(waitLists[i].selectedDateTimeFromList.waitListDateTimeId.toString() === waitListDateTimeId) {
                            responseData.waitlist = waitLists[i];
                        }
                    }
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    sortWaitListRegistrationDates(waitListDateList: WaitListDate[]): WaitListDate[] {
        return waitListDateList.sort((a, b) => (a.date < b.date) ? -1 : 1);
    }

    createWaitListDateListArrayForTable(waitList: WaitList): WaitListDate[] {
        const waitListDateList: WaitListDate[] = [];
        if (waitList.waitListDateList.length !== 0)
            for (let j = 0, y = waitList.waitListDateList.length; j < y; j++) {
                if (waitList.waitListDateList[j].active) {
                    const waitListDate: WaitListDate = new WaitListDate(waitList.waitListDateList[j].date, waitList.waitListDateList[j].waitListDateId, waitList.waitListDateList[j].waitListId);
                    waitListDate.copyPropsFromAPI(waitList.waitListDateList[j]);
                    if (waitList.waitListDateList[j].waitListDateTimeList)
                        for (let k = 0, z = waitList.waitListDateList[j].waitListDateTimeList.length; k < z; k++) {
                            const waitListDateTime: WaitListDateTime = new WaitListDateTime(waitList.waitListDateList[j].waitListDateTimeList[k].startTime, waitList.waitListDateList[j].waitListDateTimeList[k].endTime, waitList.waitListDateList[j].waitListDateTimeList[k].waitListDateTimeId, waitList.waitListDateList[j].waitListDateTimeList[k].classScheduleId, waitList.waitListDateList[j].waitListDateTimeList[k].clientStartTimeForDisplay, waitList.waitListDateList[j].waitListDateTimeList[k].clientEndTimeForDisplay);
                            waitListDateTime.startDateTimeUTC = waitList.waitListDateList[j].waitListDateTimeList[k].startDateTimeUTC
                            waitListDateTime.endDateTimeUTC = waitList.waitListDateList[j].waitListDateTimeList[k].endDateTimeUTC
                            // waitListEntryTime.displayStartTime = $filter('militaryTime')(waitListEntryTime.startTime);
                            // waitListEntryTime.displayEndTime = $filter('militaryTime')(waitListEntryTime.endTime);
                            waitListDate.waitListDateTime = _.cloneDeep(waitListDateTime);
                        }
                    waitListDateList.push(_.cloneDeep(waitListDate));
                }
            }
        return this.sortWaitListRegistrationDates(waitListDateList);
    }

    getDisplayedColumns(isCourse?: boolean): string[] {
        if (isCourse)
            return ['courseSet.courseSetName', 'courseSet.startDate', 'courseSet.endDate', 'actions'];
        else
            return ['date', 'waitListDateTime.clientStartTimeForDisplay', 'waitListDateTime.clientEndTimeForDisplay', 'actions'];
    }

    getColumnDefs(): TableColumnDataModel[] {
        const columnDefs: TableColumnDataModel[] = [
            new TableColumnDataModel('date', 'Date', 'date', [this.dataTablesService.fullDateFilterUTC], null, null, null, null, 'Any Date'),
            new TableColumnDataModel('waitListDateTime.clientStartTimeForDisplay', 'Start Time', 'date', [this.dataTablesService.momentTimeFilter]),
            new TableColumnDataModel('waitListDateTime.clientEndTimeForDisplay', 'End Time', 'date', [this.dataTablesService.momentTimeFilter]),
            new TableColumnDataModel('courseSet.courseSetName', 'Set Name', null),
            new TableColumnDataModel('courseSet.startDate', 'Start Date', 'date', [this.dataTablesService.fullDateFilterUTC]),
            new TableColumnDataModel('courseSet.endDate', 'End Date', 'date', [this.dataTablesService.fullDateFilterUTC]),
        ];
        return columnDefs;
    }


}
