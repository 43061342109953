import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {
    Client, MultipleAppts, preference, RecurringAppointment,
} from "../../../../common-classes/app-objects.model";
import {BusinessService} from "../../../../business.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ResponsiveService} from "../../../../responsive.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {AuthService} from "../../../../auth/auth.service";
import {TagsService} from "../../../../tags.service";
import {ClientAccountService} from "../../../client-account.service";
import {ErrorModalComponent} from "../../../../modals/error-modal/error-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {TitleCasePipe} from "@angular/common";
import {TimeService} from "../../../../scheduler/time/time.service";
import {
    CancelAppointmentComponent,
    CancelBookingObjModalData
} from "../../cancel-appointment/cancel-appointment.component";
import {WeekDayData} from "../../../../scheduler/time/time-objects.model";
import {AddToCalendarComponent} from "../../../../scheduler/common-components/add-to-calendar/add-to-calendar.component";
import {Appointment} from '../../../../../models/Appointment.model';
import {SchedulerPreferenceService} from '../../../../scheduler-preference.service';

@Component({
  selector: 'repeating-appointment-detail',
  templateUrl: './repeating-appointment-detail.component.html',
  styleUrls: ['../booking-object-details.component.css']
})
export class RepeatingAppointmentDetailComponent implements OnInit, AfterViewChecked {
  preference: preference;
  selectedClient: Client;
  repeatingApptId: string;
  repeatingApptFullObj: MultipleAppts;
  repeatingAppt: RecurringAppointment;
  appts: Appointment[];
  calendarAddAppts: Appointment[] = [];
  loaded: boolean = false;
  loading: boolean = true;
  errorLoading: boolean = false;
  showAddToCalendarBtn: boolean = true;
  status: string;
  chipBaseStyle: string;
  chipClass: string;
  cancelNotAllowed: boolean = true;
  cancelNotAllowedReason: string;
  weekdays: WeekDayData[] = this.timeService.getWeekdayDataObjs();
  repeatingApptDetailDaysRepeatsLabel: string;

  constructor(private businessService: BusinessService, private responsiveService: ResponsiveService, private route: ActivatedRoute, private router: Router,
              private tagsService: TagsService, private authService: AuthService, private addToCalendarSheet: MatBottomSheet, private clientAccountService: ClientAccountService,
              private dialog: MatDialog, private titleCasePipe: TitleCasePipe, private timeService: TimeService, private schedulerPreferenceService: SchedulerPreferenceService) {
      this.preference = schedulerPreferenceService.schedulerPreference;
  }

  goBack() {
      if (this.authService.isAuth()) {
          this.router.navigate(['/my-account/appts'], {relativeTo: this.route.root, state: {listStatus: 'REPEATING'}})
      } else {
          this.router.navigate(['../../scheduler'], {relativeTo: this.route});
      }
  }

  cancelRepeatingAppt() {
      if (this.cancelNotAllowed) {
          const dialogRef = this.dialog.open(ErrorModalComponent, {
              maxWidth: this.responsiveService.getMaxWidthForModals(),
              data: {preference: this.preference, errorType: this.cancelNotAllowedReason, appt: this.repeatingAppt}
          })
      } else {
          let dataToPass: CancelBookingObjModalData = new CancelBookingObjModalData();
          dataToPass.repeatingAppt = this.repeatingAppt;
          dataToPass.cancelType = 'REPEATING';
          dataToPass.preference = this.preference;
          const dialogRef = this.dialog.open(CancelAppointmentComponent,
              {
                  maxWidth: this.responsiveService.getMaxWidthForModals(),
                  data: dataToPass
              });
          dialogRef.afterClosed().subscribe((cancelledRepeatingAppt: MultipleAppts) => {
              // console.log(cancelledRepeatingAppt);
              if (cancelledRepeatingAppt !== undefined) {
                  this.loaded = false;
                  this.loading = true;
                  this.configureDetail(cancelledRepeatingAppt);
              }
          })
      }
  }

  configureDetail(repeatingAppt: MultipleAppts){
      if(repeatingAppt === null) {
          const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(),
              data: {preference: this.preference, errorType: 'apptDoesNotBelongToAccount'}})
          dialogRef.afterClosed().subscribe( data => {
              this.goBack();
          })
      } else {
          this.repeatingApptFullObj = repeatingAppt;
          this.repeatingAppt = repeatingAppt.recurringAppointment;
          if(this.repeatingAppt.everyXMonths !== null && this.repeatingAppt.everyXMonths !== 0) {
              this.repeatingAppt.weeksOrMonths = this.repeatingAppt.everyXMonths === 1 ? this.preference.labelMap.monthLabel : this.preference.labelMap.monthsLabel;
              this.repeatingAppt.frequency = this.repeatingAppt.everyXMonths;
          } else {
              this.repeatingAppt.weeksOrMonths = this.repeatingAppt.everyXWeeks === 1 ? this.preference.labelMap.weekLabel : this.preference.labelMap.weeksLabel;
              this.repeatingAppt.frequency = this.repeatingAppt.everyXWeeks;
          }
          this.appts = repeatingAppt.appointments;
          this.calendarAddAppts = [];
          let statusesForAddToCalendar = ['OPEN'];
          for(let i = 0, x = this.appts.length; i < x; i++){
              if(statusesForAddToCalendar.indexOf(this.appts[i].status.toUpperCase()) !== -1){
                  this.calendarAddAppts.push(this.appts[i]);
              }
          }
          if(this.calendarAddAppts.length === 0)
              this.showAddToCalendarBtn = false;
          this.status = this.repeatingAppt.status.replace(new RegExp('_', 'g'), ' ');
          this.status = this.titleCasePipe.transform(this.status);
          this.weekdays = this.timeService.getSelectedWeekdays(this.repeatingAppt, this.weekdays);
          this.repeatingApptDetailDaysRepeatsLabel = this.tagsService.assignObjectToTags(this.repeatingAppt, this.preference.labelMap.repeatingApptDetailDaysRepeatsLabel, this.tagsService.repeatingApptTags);
          switch (this.repeatingAppt.status) {
              case 'Open':
                  this.chipBaseStyle ='primary';
                  this.chipClass ='apptStatusOpenChip';
                  break;
              case 'Closed':
                  this.chipBaseStyle ='success';
                  this.chipClass ='apptStatusCompletedChip';
                  break;
              case 'Cancelled':
                  this.chipBaseStyle ='error';
                  this.chipClass ='apptStatusCancelledChip';
                  break;
              case 'NO_SHOW':
                  this.chipBaseStyle ='warning';
                  this.chipClass ='apptStatusNoShowChip';
                  break;
          }

          if(this.status !== 'Open'){
              this.cancelNotAllowedReason = 'apptCancelNotInOpenStatus';
          } else if(this.repeatingAppt.client.status === this.clientAccountService.onHoldStatus){
              this.cancelNotAllowedReason = 'cancelNotAllowedClientOnHold';
          } else {
              this.cancelNotAllowed = false;

          }
          this.loaded = true;
          this.loading = false;
      }
  }

  getDetail() {
      let repeatingApptIdString = this.route.params.subscribe(params => {
          this.repeatingApptId = params['id'];
      });

      this.clientAccountService.getRepeatingApptDetail(this.repeatingApptId)
          .subscribe((multipleAppts: MultipleAppts) => {
              this.configureDetail(multipleAppts)
          }, (error: HttpErrorResponse) => {
              this.errorLoading = true;
              this.loading = false;
          })
  }

  addToCalendar(){
      if(this.calendarAddAppts.length > 0)
        this.addToCalendarSheet.open(AddToCalendarComponent, {data: {appointments: [this.calendarAddAppts]}});
  }

  ngAfterViewChecked(){
      this.responsiveService.setHeightForEmbedAccount();
  }

  ngOnInit() {
    this.showAddToCalendarBtn = this.preference.showAddToCalendar;
    this.selectedClient = this.clientAccountService.getSelectedClient(this.preference);
    this.getDetail();
  }

}
