import {AfterViewChecked, Directive, ElementRef, Input, OnInit} from '@angular/core';
import {StyleSheetService} from "../styleSheetService";
import * as $ from 'jquery';
import {ResponsiveService} from "../responsive.service";

@Directive({
  selector: '[ttChip]',
})
export class ChipDirective implements OnInit, AfterViewChecked {
  @Input() chipClass;
  @Input() baseStyle;
  backgroundColor: string;
  fontColor: string;
  baseSelector: string;
  fullSelectorIncludeSpan: string;
  constructor(private styleSheetService: StyleSheetService, private el: ElementRef, private responsiveService: ResponsiveService) {}

  setBaseStyleProp(){
    let baseStyleProp: string = 'basicChip';
    switch (this.baseStyle) {
      case 'primary':
        baseStyleProp = 'primaryChip';
        break;
      case 'success':
        baseStyleProp = 'successChip';
        break;
      case 'error':
        baseStyleProp = 'errorChip';
        break;
      case 'accent':
        baseStyleProp = 'accentChip';
        break;
      case 'warning':
        baseStyleProp = 'warningChip';
        break;
    }
    return baseStyleProp;
  }

  setStyleOnChip(){

    $(this.fullSelectorIncludeSpan).css({'background-color': this.backgroundColor, 'color': this.fontColor});
    if(this.responsiveService.onMobileViewPort){
      $(this.baseSelector).css({'margin': '0px', 'padding': '5px 10px', 'font-size': '12px'});
    }
  }

  setColors(){
    let baseStyleProp: string = this.setBaseStyleProp();

    let baseChipMap = this.styleSheetService.baseChips;

    let propToMatchOn = this.chipClass;
    this.baseSelector = '.' + this.chipClass + '.mat-chip.mat-standard-chip';

    this.fullSelectorIncludeSpan = this.baseSelector + ', ' + this.baseSelector + ' span';

    this.backgroundColor = baseChipMap[baseStyleProp].backgroundColor;
    this.fontColor = baseChipMap[baseStyleProp].color;
    let styleMap = this.styleSheetService.mwStyleMap;
    for(let prop in styleMap){
      if(prop === propToMatchOn){
        if(styleMap[prop].color !== undefined){
          this.fontColor = styleMap[prop].color
        }
        if(styleMap[prop].backgroundColor !== undefined){
          this.backgroundColor = styleMap[prop].backgroundColor
        }
      }
    }
    this.setStyleOnChip();
  }
  ngAfterViewChecked(): void {
    this.setColors();
  }
  ngOnInit() {
    this.setColors();
  }

}
