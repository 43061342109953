import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TimezoneComponent} from './timezone.component';
import {MaterialModule} from '../../../material.module';



@NgModule({
  declarations: [TimezoneComponent],
  imports: [
    CommonModule,
      MaterialModule
  ],
  exports: [TimezoneComponent]
})
export class TimezoneModule { }
