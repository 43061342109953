export class EmailCategory {
    allowChange: boolean;
    allowDuplicates: boolean;
    businessId: number;
    changingSubscribeStatus: boolean = false;
    code: string;
    countOfTemplates: number;
    emailCatagory: string;
    emailCategoryDisplayName: string;
    emailCatagoryId: number;
    emailTemplateId: number;
    errorChangingSubscribeStatus: boolean = false;
    objectType: string;
    selected: boolean;
    sendICS: number;
    sentTo: string;
    sentToClient: boolean;
    sms: boolean;
    sortOrder: number;
    subject: string;
    subscribeStatusChanged: boolean = false;
    type: string;
}

export class CategoryNamesByCode {
    ACCOUNT_APPROVE_STAFF: string = 'Pending ' + this.staffLabel + ' Requires Approval';
    ACCOUNT_NEW_STAFF: string = 'New User ' + this.staffLabel + ' Welcome Message';
    ACCOUNT_PENDING_STAFF_APPROVED: string = 'Pending ' + this.staffLabel + ' Approved';
    ACCOUNT_PENDING_STAFF_DECLINED: string = 'Pending ' + this.staffLabel + ' Declined';
    APPT_BULK_MSG: string = 'Invitation Template';
    APPT_CANCEL_CLIENT_WEB: string = 'Appointment Cancellation by ' + this.clientLabel;
    APPT_CANCEL_CLIENT: string = 'Appointment Cancellation by ' + this.staffLabel;
    APPT_CANCEL_NONPAY_CLIENT: string = 'Appointment Cancellation Due to Non-Payment';
    APPT_CHANGE_CLIENT: string = 'Appointment Changed or Rescheduled';
    APPT_CHANGE_STAFF: string = 'Appointment Changed or Rescheduled';
    APPT_CHANGE_STAFF_SMS: string = 'Appointment Changed Text Message';
    APPT_COMPLETED_CLIENT: string = 'Appointment Completed';
    APPT_END_STAFF: string = 'Appointment Triggers';
    APPT_END_CLIENT: string = 'Appointment Triggers';
    APPT_END_STAFF_SMS: string = 'Appointment Text Message Triggers';
    APPT_END_CLIENT_SMS: string = 'Appointment Text Message Triggers';
    APPT_NEW_CLIENT: string = 'New Appointment by ' + this.staffLabel;
    APPT_NEW_CLIENT_WEB: string = 'New Appointment by ' + this.clientLabel;
    APPT_NEW_STAFF_CONFIRM: string = 'Appointment Requested by ' + this.clientLabel;
    APPT_NEW_STAFF_CONFIRM_TEXT: string = 'Appointment Requested by ' + this.clientLabel + ' Text Message';
    APPT_NEW_STAFF_WEB: string = 'New Appointment';
    APPT_NEW_STAFF_WEB_SMS: string = 'New Appointment Text Message';
    APPT_NOSHOW_CLIENT: string = 'Appointment No Show';
    APPT_CANCEL_STAFF_WEB: string = 'Appointment Cancellation';
    APPT_CANCEL_NONPAY_STAFF: string = 'Appointment Cancellation Due to Non-Payment';
    APPT_CANCEL_STAFF_SMS: string = 'Appointment Cancellation Text Message';
    APPT_NEW_STAFF_CONFIRM_CLIENT: string = 'Appointment Requests Under Review';
    APPT_REMINDER_CLIENT: string = 'Appointment Reminder';
    APPT_REMINDER_CLIENT_SMS: string = 'Appointment Reminder Text Message';
    APPT_REMINDER_STAFF: string = 'Appointment Reminder';
    APPT_REMINDER_STAFF_SMS: string = 'Appointment Reminder Text Message';
    APPT_STAFF_DECLINE_CLIENT: string = 'Appointment Declined by ' + this.staffLabel;
    AVAIL_REQUEST_ASSIGN_NOTIF: string = 'Education Request Assignment to ' + this.staffLabel;
    AVAIL_REQUEST_ASSIGN_CONFIRM: string = 'Education Request Confirmation to Admin';
    AVAIL_REQUEST_RETURN_CONFIRM: string = 'Education Request Returned to ' + this.staffLabel;
    AVAIL_REQUEST_RETURN_NOTIF: string = 'Education Request Returned to Admin';
    CLASS_SCHED_CHANGE: string = this.classLabel + ' Session Changed';
    CLASS_SCHED_STAFF_NEW: string = 'New ' + this.classLabel + ' Session Scheduled';
    CLASS_SCHED_REMINDER_STAFF: string = this.classLabel + ' Session Reminder';
    CLIENT_PWD: string = this.clientLabel + ' Password Reset Request';
    CLIENT_WELCOME_BULK: string = 'Invitation Template';
    CLIENT_WELCOME_BULK_SMS: string = 'Invitation Text Message Template';
    COURSE_APPT_CLIENT_NEW: string = 'New ' + this.courseLabel + ' Registration';
    COURSE_APPT_CLIENT_CANCEL: string = this.courseLabel + ' Registration Cancellation';
    COURSE_APPT_CLIENT_CANCEL_NOPAY: string = this.courseLabel + ' Registration Cancellation due to non payment';
    COURSE_APPT_STAFF_NEW: string = 'New ' + this.courseLabel + ' Registration';
    COURSE_APPT_STAFF_CANCEL: string = this.courseLabel + ' Registration Cancellation';
    COURSE_APPT_STAFF_CANCEL_NOPAY: string = this.courseLabel + 'Registration Cancellation due to non payment';
    COURSE_APPT_STAFF_REQUEST: string = this.courseLabel + ' Registration Request';
    DISCLAIMER_FORM_CLIENT: string = 'Disclaimer Form Added';
    DISCLAIMER_FORM_CHANGE_CLIENT: string = 'Disclaimer Form Updated';
    DISCLAIMER_FORM_NEW_STAFF: string = 'Disclaimer Form Added';
    DISCLAIMER_FORM_CHANGE_STAFF: string = 'Disclaimer Form Updated';
    INV_BULK: string = 'Bulk Invoice Email';
    INVITE_COMPLETE_BULK: string = 'Automated Completion Templates for Invitations';
    INVITE_REMIND_BULK: string = this.clientLabel + ' Automated Reminder Templates for Invitations';
    INVITE_REMIND_BULK_STAFF: string = this.staffLabel + ' Automated Reminder Templates for Invitations';
    PACKAGE_USED_UP: string = 'Package almost used up';
    PAY_INVOICE: string = 'Invoice pay remaining balance';
    REC_APPT_CLIENT_CANCEL: string = 'Repeating Appointment Cancellation Sent to ' + this.clientLabel;
    REC_APPT_CLIENT_CHANGE: string = 'Repeating Appointment Change Sent to ' + this.clientLabel;
    REC_APPT_STAFF_CANCEL: string = 'Repeating Appointment Cancellation Sent to ' + this.staffLabel;
    REC_APPT_STAFF_CHANGE: string = 'Repeating Appointment Change Sent to ' + this.staffLabel;
    REC_CLASS_CLIENT: string = 'New Repeating Appointments';
    REC_CLASS_STAFF: string = 'New Repeating Appointments';
    RECALL_CLIENT: string = 'Appointment Recall';
    RECALL_CLIENT_SMS: string = 'Appointment Recall Text Message';
    RECALL_STAFF: string = 'Appointment Recall';
    RECALL_STAFF_SMS: string = 'Appointment Recall Text Message';
    REVIEW_QUOTE: string = 'Quote Email';
    STAFF_BULK_MSG: string = 'Invitation Template/Bulk Message to ' + this.staffLabel;
    WAIT_LIST_ADD_CLIENT: string = 'Added to Waitlist Confirmation';
    WAIT_LIST_ADD_CLIENT_SMS: string = 'Added to Waitlist SMS Confirmation';
    WAIT_LIST_ADD_STAFF: string = this.clientLabel + ' Added to your Waitlist';
    WAIT_LIST_CLIENT_TO_CLIENT: string = 'View All Available Wait List Times';
    WAIT_LIST_CLIENT_TO_CLIENT_SMS: string = 'View All Available Wait List Times Text Message';
    WAIT_LIST_NEW_SLOTS: string = 'Wait List New Appointment Time Available';
    WAIT_LIST_NEW_SLOTS_SMS: string = 'Wait List New Appointment Times Available Text Message';
    WAIT_LIST_CHANGED_TO_CLIENT: string = 'Wait List Registration Details Changed';
    WAIT_LIST_EXPIRED_TO_CLIENT: string = 'Wait List Registration Expired';
    WAIT_LIST_REMOVED_TO_CLIENT: string = 'Wait List Registration Removed';
    WAIT_LIST_CHANGED_TO_STAFF: string = 'Wait List Registration Details Changed';
    WAIT_LIST_EXPIRED_TO_STAFF: string = 'Wait List Registration Expired';
    WAIT_LIST_REMOVED_TO_STAFF: string = 'Wait List Registration Removed';
    constructor(private clientLabel: string, private staffLabel: string, private classLabel: string, private courseLabel: string) {

    }
}

export class ClientEmailCategoryCodeRef {
    cancelApptByStaff: string = 'APPT_CANCEL_CLIENT';
    cancelApptByClient: string = 'APPT_CANCEL_CLIENT_WEB';
    cancelApptNonPayment: string = 'APPT_CANCEL_NONPAY_CLIENT';
    cancelCourse: string = 'COURSE_APPT_CLIENT_CANCEL';
    cancelCourseNonPayment: string = 'COURSE_APPT_CLIENT_CANCEL_NOPAY';
    cancelRecurring: string = 'REC_APPT_CLIENT_CANCEL';
    changedAppt: string = 'APPT_CHANGE_CLIENT';
    changedDisclaimerForm: string = 'DISCLAIMER_FORM_CHANGE_CLIENT';
    changedRecurring: string = 'REC_APPT_CLIENT_CHANGE';
    changedWaitListRegistration: string = 'WAIT_LIST_CHANGED_TO_CLIENT';
    completedAppt: string = 'APPT_COMPLETED_CLIENT';
    completedPackage: string = 'PACKAGE_USED_UP';
    decline: string = 'APPT_STAFF_DECLINE_CLIENT';
    expiredWaitListRegistration: string = 'WAIT_LIST_EXPIRED_TO_CLIENT';
    invitation: string = 'CLIENT_WELCOME_BULK';
    invoice: string = 'INV_BULK';
    newApptByStaff: string = 'APPT_NEW_CLIENT';
    newApptByClient: string = 'APPT_NEW_CLIENT_WEB';
    newCourseRegistration: string = 'COURSE_APPT_CLIENT_NEW';
    newDisclaimerForm: string = 'DISCLAIMER_FORM_CLIENT';
    newPendingAppt: string = 'APPT_NEW_STAFF_CONFIRM_CLIENT';
    newRecurring: string = 'REC_CLASS_CLIENT';
    newWaitlistInvitationGeneric: string = 'WAIT_LIST_CLIENT_TO_CLIENT'; // used when recurring cancelled; default template includes link to landing page to see all appt times
    newWaitlistInvitationSpecific: string = 'WAIT_LIST_NEW_SLOTS'; // for when a specific time has become available
    newWaitlistRegistration: string = 'WAIT_LIST_ADD_CLIENT';
    noShowAppt: string = 'APPT_NOSHOW_CLIENT';
    payInvoice: string = 'PAY_INVOICE';
    pendingInvoice: string = 'REVIEW_QUOTE';
    password: string = 'CLIENT_PWD';
    recallAppt: string = 'RECALL_CLIENT';
    removedWaitListRegistration: string = 'WAIT_LIST_REMOVED_TO_CLIENT';
    reminderAppt: string = 'APPT_REMINDER_CLIENT';
    trigger: string = 'APPT_END_CLIENT';
    constructor() {
    }
}

export class StaffEmailCategoryCodeRef {
    approvedPendingStaff: string = 'ACCOUNT_PENDING_STAFF_APPROVED';
    assignAvailRequest: string = 'AVAIL_REQUEST_ASSIGN_NOTIF';
    cancelAppt: string = 'APPT_CANCEL_STAFF_WEB';
    cancelApptNonPayment: string = 'APPT_CANCEL_NONPAY_STAFF';
    cancelCourse: string = 'COURSE_APPT_STAFF_CANCEL';
    cancelCourseNonPayment: string = 'COURSE_APPT_STAFF_CANCEL_NOPAY';
    cancelRecurring: string = 'REC_APPT_STAFF_CANCEL';
    changedAppt: string = 'APPT_CHANGE_STAFF';
    changedClassSession: string = 'CLASS_SCHED_CHANGE';
    changedDisclaimerForm: string = 'DISCLAIMER_FORM_CHANGE_STAFF';
    changedRecurring: string = 'REC_APPT_STAFF_CHANGE';
    changedWaitListRegistration: string = 'WAIT_LIST_CHANGED_TO_STAFF';
    confirmAvailRequestAssignment: string = 'AVAIL_REQUEST_ASSIGN_CONFIRM';
    confirmAvailRequestReturn: string = 'AVAIL_REQUEST_RETURN_CONFIRM';
    dayFull: string = 'DAY_FULL_EMAIL';
    declinedPendingStaff: string = 'ACCOUNT_PENDING_STAFF_DECLINED';
    expiredWaitListRegistration: string = 'WAIT_LIST_EXPIRED_TO_STAFF';
    invitation: string = 'STAFF_BULK_MSG';
    invitationComplete: string = 'INVITE_COMPLETE_BULK';
    invitationRemind: string = 'INVITE_REMIND_BULK_STAFF';
    newAccountWelcome: string = 'ACCOUNT_NEW_OWNER';
    newAppt: string = 'APPT_NEW_STAFF_WEB';
    newApptCourse: string = 'COURSE_APPT_STAFF_NEW';
    newClassSession: string = 'CLASS_SCHED_STAFF_NEW';
    newDisclaimerForm: string = 'DISCLAIMER_FORM_NEW_STAFF';
    newPendingAppt: string = 'APPT_NEW_STAFF_CONFIRM';
    newPendingApptCourse: string = 'COURSE_APPT_STAFF_REQUEST';
    newPendingStaff: string = 'ACCOUNT_APPROVE_STAFF';
    newStaffWelcome: string = 'ACCOUNT_NEW_STAFF';
    newWaitlistRegistration: string = 'WAIT_LIST_ADD_STAFF';
    reminderAppt: string = 'APPT_REMINDER_STAFF';
    recallAppt: string = 'RECALL_STAFF';
    recurringClass: string = 'REC_CLASS_STAFF';
    reminderClassSession: string = 'CLASS_SCHED_REMINDER_STAFF';
    returnAvailRequest: string = 'AVAIL_REQUEST_RETURN_NOTIF';
    trigger: string = 'APPT_END_STAFF';
    removedWaitListRegistration: string = 'WAIT_LIST_REMOVED_TO_STAFF';
    constructor() {
    }
}

export class ClientTextCategoryCodeRef {
    invitation: string = 'CLIENT_WELCOME_BULK_SMS';
    newWaitlistInvitationGeneric: string = 'WAIT_LIST_CLIENT_TO_CLIENT_SMS'; // used when recurring cancelled; default template includes link to landing page to see all appt times
    newWaitlistInvitationSpecific: string = 'WAIT_LIST_NEW_SLOTS_SMS'; // for when a specific time has become available
    reminderAppt: string = 'APPT_REMINDER_CLIENT_SMS';
    trigger: string = 'APPT_END_CLIENT_SMS';
}

export class StaffTextCategoryCodeRef {
    cancelAppt: string = 'APPT_CANCEL_STAFF_SMS';
    changedAppt: string = 'APPT_CHANGE_STAFF_SMS';
    newAppt: string = 'APPT_NEW_STAFF_WEB_SMS';
    newApptConfirm: string = 'APPT_NEW_STAFF_CONFIRM_TEXT';
    recallAppt: string = 'RECALL_STAFF_SMS';
    reminderAppt: string = 'APPT_REMINDER_STAFF_SMS';
    trigger: string = 'APPT_END_STAFF_SMS';
}

export class EmailCategoryNameMapObj {
    codeName: string;
    displayName: string;
    constructor(codeName: string, categoryNamesByCode: CategoryNamesByCode) {
        this.codeName = codeName;
        this.displayName = categoryNamesByCode[codeName];
    }
}
export class EmailCategoryNameMap {
    clientEmailCategoryCodeRef = new ClientEmailCategoryCodeRef();
    clientTextCategoryCodeRef = new ClientTextCategoryCodeRef();
    staffEmailCategoryCodeRef = new StaffEmailCategoryCodeRef();
    client_cancelApptByStaff: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.cancelApptByStaff, this.categoryNamesByCode);
    client_cancelApptByClient: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.cancelApptByClient, this.categoryNamesByCode);
    client_cancelApptNonPayment: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.cancelApptNonPayment, this.categoryNamesByCode);
    client_cancelCourse: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.cancelCourse, this.categoryNamesByCode);
    client_cancelCourseNonPayment: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.cancelCourseNonPayment, this.categoryNamesByCode);
    client_cancelRecurring: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.cancelRecurring, this.categoryNamesByCode);
    client_changedAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.changedAppt, this.categoryNamesByCode);
    client_changedDisclaimerForm: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.changedDisclaimerForm, this.categoryNamesByCode);
    client_changedRecurring: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.changedRecurring, this.categoryNamesByCode);
    client_changedWaitListRegistration: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.changedWaitListRegistration, this.categoryNamesByCode);
    client_completedAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.completedAppt, this.categoryNamesByCode);
    client_completedPackage: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.completedPackage, this.categoryNamesByCode);
    client_decline: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.decline, this.categoryNamesByCode);
    client_expiredWaitListRegistration: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.expiredWaitListRegistration, this.categoryNamesByCode);
    client_invitation: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.invitation, this.categoryNamesByCode);
    client_invoice: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.invoice, this.categoryNamesByCode);
    client_newApptByStaff: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.newApptByStaff, this.categoryNamesByCode);
    client_newApptByClient: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.newApptByClient, this.categoryNamesByCode);
    client_newCourseRegistration: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.newCourseRegistration, this.categoryNamesByCode);
    client_newDisclaimerForm: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.newDisclaimerForm, this.categoryNamesByCode);
    client_newPendingAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.newPendingAppt, this.categoryNamesByCode);
    client_newRecurring: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.newRecurring, this.categoryNamesByCode);
    client_newWaitlistInvitationGeneric: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.newWaitlistInvitationGeneric, this.categoryNamesByCode);
    client_newWaitlistInvitationSpecific: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.newWaitlistInvitationSpecific, this.categoryNamesByCode);
    client_newWaitlistRegistration: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.newWaitlistRegistration, this.categoryNamesByCode);
    client_noShowAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.noShowAppt, this.categoryNamesByCode);
    client_payInvoice: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.payInvoice, this.categoryNamesByCode);
    client_pendingInvoice: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.pendingInvoice, this.categoryNamesByCode);
    client_password: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.password, this.categoryNamesByCode);
    client_recallAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.recallAppt, this.categoryNamesByCode);
    client_reminderAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.reminderAppt, this.categoryNamesByCode);
    client_removedWaitListRegistration: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.removedWaitListRegistration, this.categoryNamesByCode);
    client_trigger: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientEmailCategoryCodeRef.trigger, this.categoryNamesByCode);

    client_sms_invitation: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientTextCategoryCodeRef.invitation, this.categoryNamesByCode);
    client_sms_newWaitlistInvitationGeneric: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientTextCategoryCodeRef.newWaitlistInvitationGeneric, this.categoryNamesByCode);
    client_sms_newWaitlistInvitationSpecific: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientTextCategoryCodeRef.newWaitlistInvitationSpecific, this.categoryNamesByCode);
    client_sms_reminderAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientTextCategoryCodeRef.reminderAppt, this.categoryNamesByCode);
    client_sms_trigger: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.clientTextCategoryCodeRef.trigger, this.categoryNamesByCode);

    staff_approvedPendingStaff: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.approvedPendingStaff, this.categoryNamesByCode);
    staff_assignAvailRequest: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.assignAvailRequest, this.categoryNamesByCode);
    staff_cancelAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.cancelAppt, this.categoryNamesByCode);
    staff_cancelApptNonPayment: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.cancelApptNonPayment, this.categoryNamesByCode);
    staff_cancelCourse: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.cancelCourse, this.categoryNamesByCode);
    staff_cancelCourseNonPayment: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.cancelCourseNonPayment, this.categoryNamesByCode);
    staff_cancelRecurring: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.cancelRecurring, this.categoryNamesByCode);
    staff_changedAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.changedAppt, this.categoryNamesByCode);
    staff_changedClassSession: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.changedClassSession, this.categoryNamesByCode);
    staff_changedDisclaimerForm: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.changedDisclaimerForm, this.categoryNamesByCode);
    staff_changedRecurring: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.changedRecurring, this.categoryNamesByCode);
    staff_changedWaitListRegistration: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.changedWaitListRegistration, this.categoryNamesByCode);
    staff_confirmAvailRequestAssignment: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.confirmAvailRequestAssignment, this.categoryNamesByCode);
    staff_confirmAvailRequestReturn: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.confirmAvailRequestReturn, this.categoryNamesByCode);
    staff_dayFull: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.dayFull, this.categoryNamesByCode);
    staff_declinedPendingStaff: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.declinedPendingStaff, this.categoryNamesByCode);
    staff_expiredWaitListRegistration: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.expiredWaitListRegistration, this.categoryNamesByCode);
    staff_invitation: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.invitation, this.categoryNamesByCode);
    staff_invitationComplete: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.invitationComplete, this.categoryNamesByCode);
    staff_invitationRemind: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.invitationRemind, this.categoryNamesByCode);
    staff_newAccountWelcome: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newAccountWelcome, this.categoryNamesByCode);
    staff_newAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newAppt, this.categoryNamesByCode);
    staff_newApptCourse: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newApptCourse, this.categoryNamesByCode);
    staff_newClassSession: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newClassSession, this.categoryNamesByCode);
    staff_newDisclaimerForm: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newDisclaimerForm, this.categoryNamesByCode);
    staff_newPendingAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newPendingAppt, this.categoryNamesByCode);
    staff_newPendingApptCourse: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newPendingApptCourse, this.categoryNamesByCode);
    staff_newPendingStaff: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newPendingStaff, this.categoryNamesByCode);
    staff_newStaffWelcome: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newStaffWelcome, this.categoryNamesByCode);
    staff_newWaitlistRegistration: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.newWaitlistRegistration, this.categoryNamesByCode);
    staff_reminderAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.reminderAppt, this.categoryNamesByCode);
    staff_recallAppt: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.recallAppt, this.categoryNamesByCode);
    staff_recurringClass: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.recurringClass, this.categoryNamesByCode);
    staff_reminderClassSession: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.reminderClassSession, this.categoryNamesByCode);
    staff_removedWaitListRegistration: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.removedWaitListRegistration, this.categoryNamesByCode);
    staff_returnAvailRequest: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.returnAvailRequest, this.categoryNamesByCode);
    staff_trigger: EmailCategoryNameMapObj = new EmailCategoryNameMapObj(this.staffEmailCategoryCodeRef.trigger, this.categoryNamesByCode);
    constructor(private categoryNamesByCode: CategoryNamesByCode) {

    }
}
