import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
    preference
} from "../../common-classes/app-objects.model";
import {ActivatedRoute, Router} from "@angular/router";
import {BusinessService} from "../../business.service";
import { TableColumnDataModel} from "../../common-classes/TableColumnData.model";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {DataTablesService} from "../../data-tables.service";
import {ResponsiveService} from "../../responsive.service";
import {TimeService} from "../../scheduler/time/time.service";
import {WaitlistClaim} from '../../../models/WaitList.model';
import {AppointmentBase} from '../../../models/Appointment.model';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';

@Component({
    selector: 'client-waitlist-times-table',
    templateUrl: './client-waitlist-times-table.component.html',
    styles: []
})
export class ClientWaitlistTimesTableComponent implements OnInit, OnChanges {
    @Input() waitlistClaims: WaitlistClaim[];
    @Input() selectedClient;
    @Input() clientHash: string;
    loading: boolean = true;
    errorLoading: boolean = false;
    loaded: boolean = false;
    preference: preference = this.schedulerPreferenceService.schedulerPreference;
    preferenceDefinedColumns: string[];
    displayedColumns = this.dataTablesService.getDefaultApptDisplayedColumns();
    columnDefs: TableColumnDataModel[] = this.dataTablesService.getDefaultApptColumnDefs();
    appts = new MatTableDataSource<AppointmentBase>();
    private sort: MatSort;
    @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
        this.sort = ms;
        this.appts.sort = this.sort;
    }
    constructor(private route: ActivatedRoute, private dataTablesService: DataTablesService, private router: Router,
                private timeService: TimeService, private schedulerPreferenceService: SchedulerPreferenceService,
                private businessService: BusinessService, private responsiveService: ResponsiveService) { }

    organizeWaitlistHolds(){
        let appts: AppointmentBase[] = [];
        if(this.waitlistClaims !== undefined && this.waitlistClaims !== null && this.waitlistClaims.length > 0) {
            for (let i = 0, x = this.waitlistClaims.length; i < x; i++) {
                if (this.waitlistClaims[i].status !== 'PAST_START' && this.waitlistClaims[i].status !== 'OUTSIDE_BOOKING_WINDOW' && this.waitlistClaims[i].status !== 'CANCELLED') {
                    if (this.waitlistClaims[i].appointment) {
                        this.waitlistClaims[i].appointment.waitListInviteUUId = this.waitlistClaims[i].waitListInviteUUId;
                        this.waitlistClaims[i].appointment.clientStartTimeDate = this.timeService.convertStringDateToObject(this.waitlistClaims[i].appointment.clientStartDate, Number(this.waitlistClaims[i].appointment.clientStartTime));
                        appts.push(this.waitlistClaims[i].appointment)
                    } else if (this.waitlistClaims[i].courseSetRegistration){
                        const appointment = new AppointmentBase();
                        appointment.waitListInviteUUId = this.waitlistClaims[i].waitListInviteUUId;
                        appointment.calendarId = this.waitlistClaims[i].courseSetRegistration.recurringAppointmentId;
                        for (const prop in this.waitlistClaims[i].courseSetRegistration) {
                            appointment[prop] = this.waitlistClaims[i].courseSetRegistration[prop];
                        }
                        appointment.clientStartTimeDate = this.waitlistClaims[i].courseSetRegistration.recurringSchedule.courseSetName;
                        appts.push(appointment);

                    }
                }
            }
        }
        appts.sort((a,b) => (a.clientStartTimeDate < b.clientStartTimeDate) ? -1 : 1);
        this.appts.data = appts;
        this.loading = false;
        this.loaded = true;
        this.errorLoading = false;
    }

    getDisplayedColumns(){
        let columnsForTable = this.dataTablesService.getDisplayedColumns(this.preferenceDefinedColumns, this.columnDefs, this.preference, this.responsiveService.onMobileViewPort, null);
        console.log('columnsForTable', columnsForTable);
        this.displayedColumns = columnsForTable.appointmentFieldList;
        this.columnDefs = columnsForTable.columns;
        this.organizeWaitlistHolds();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(changes);
        if(changes.waitlistClaims !== undefined && changes.waitlistClaims.currentValue !== undefined){
            // if(this.preference.appointmentFieldList === undefined || this.preference.appointmentFieldList === null)
            //     this.preferenceDefinedColumns = this.dataTablesService.getDefaultApptDisplayedColumns();
            // else
            //     this.preferenceDefinedColumns = this.preference.appointmentFieldList;
            this.preferenceDefinedColumns = this.dataTablesService.getDefaultApptDisplayedColumns();
            this.getDisplayedColumns();
        }
    }

    ngOnInit() {

    }

    viewWaitlistClaimDetails(waitlistHold: AppointmentBase){
        let onClaimTimesPage = false;
        let onWaitListRegistrationDetailPage = false;
        if(this.router.url.indexOf("waitlist/claimTimes") !== -1){
            onClaimTimesPage = true;
        }
        if(this.router.url.indexOf("my-account/waitlistRegistration") !== -1){
            onWaitListRegistrationDetailPage = true;
        }

        this.router.navigate(['waitlist/claimTimes/' + this.clientHash + "/"  + waitlistHold.waitListInviteUUId],
            {relativeTo: this.route.root, state: {onClaimTimesPage: onClaimTimesPage, onWaitListRegistrationDetailPage: onWaitListRegistrationDetailPage}})
    }

}
