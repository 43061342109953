import {Injectable, Injector} from '@angular/core';
import {ConfigListMap, ExistingSessionObject, landingPageObjects} from './common-classes/app-objects.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {SchedulerBooleanMapModel} from '../models/SchedulerBooleanMap.model';
import {catchError} from 'rxjs/internal/operators';
import {ApiService} from './api.service';
import {AuthService} from './auth/auth.service';
import {ConfigListService} from './config-list.service';
import {BusinessService} from './business.service';
import {EmailSettingsService} from './email-settings/email-settings.service';
import {StyleSheetService} from './styleSheetService';
import {SchedulerPreferenceService} from './scheduler-preference.service';
import {SchedulerLinkService} from './scheduler-link.service';
import {MiniWebsiteService} from './mini-website.service';

@Injectable({
  providedIn: 'root'
})
export class OnloadService {
    apiService: ApiService;
    configListService: ConfigListService;
    businessService: BusinessService;
    emailSettingsService: EmailSettingsService;
    styleSheetService: StyleSheetService;
    schedulerPreferenceService: SchedulerPreferenceService;
    schedulerLinkService: SchedulerLinkService;
    miniWebsiteService: MiniWebsiteService;
    constructor(private injector: Injector, private http: HttpClient) {
        this.apiService = injector.get(ApiService);
        this.configListService = injector.get(ConfigListService);
        this.businessService = injector.get(BusinessService);
        this.emailSettingsService = injector.get(EmailSettingsService);
        this.styleSheetService = injector.get(StyleSheetService);
        this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
        this.schedulerLinkService = injector.get(SchedulerLinkService);
        this.miniWebsiteService = injector.get(MiniWebsiteService);
    }

  getLandingPageObjects(unpublished: boolean){
    // let getParams = new HttpParams();
    // getParams = getParams.append('key', 'value');
    // getParams = getParams.append('nextKey', 'nextValue');
    let paramsToPass = new HttpParams();
    paramsToPass = paramsToPass.append('unpublished', JSON.stringify(unpublished));
    return this.http
        .get<landingPageObjects>(this.apiService.apiUrl + 'scheduler/handle/' + sessionStorage.getItem('handle'), {
          observe: 'body',//FOR MORE INFO, VIDEO 268 OF FULL COURSE
          responseType: 'json',//FOR MORE INFO, VIDEO 269 OF FULL COURSE
          params: paramsToPass, //VIDEO 267 OF FULL COURSE
          // headers: this.headers//FOR MORE INFO, VIDEO 266 OF FULL COURSE
        })
        .pipe(
            map((responseData: landingPageObjects) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
              if(responseData.preference !== null){
                responseData.preference.allowTZChangeOriginal = _.cloneDeep(responseData.preference.allowTZChange);
                responseData.preference.schedulerPreferenceFieldDefnListOriginal = _.cloneDeep(responseData.preference.schedulerPreferenceFieldDefnList);
                if(responseData.preference.allowAddConnectedClient === null)
                  responseData.preference.allowAddConnectedClient = true;
                const booleanMap = new SchedulerBooleanMapModel();
                if (!responseData.preference.booleanMap)
                  responseData.preference.booleanMap = booleanMap;
                else {
                  for (let prop in booleanMap)
                    if (!responseData.preference.booleanMap.hasOwnProperty(prop))
                      responseData.preference.booleanMap[prop] = booleanMap[prop];
                }
              }
              this.businessService.setBusinessItems(responseData);

              if(responseData.configListLabels === null){
                  responseData.configListLabels = new ConfigListMap();
              }
              this.configListService.setConfigList(responseData.configListLabels);
              this.emailSettingsService.setEmailCategories(responseData.emailCategories, responseData.configListLabels);
              this.emailSettingsService.setEmailTemplates(responseData.emailTemplates);
              this.schedulerPreferenceService.setSchedulerPreference(responseData.preference);
              // if (this.schedulerPreferenceService.schedulerPreference.labelMap) // LHB 1/22/2021 TT-7301
              //     for (let key in this.schedulerPreference.labelMap) {
              //         for (let i = 0, x = this.tagsService.businessTagsArray.length; i < x; i++) {
              //             if (this.schedulerPreference.labelMap[key] && this.schedulerPreference.labelMap[key].indexOf(this.tagsService.businessTagsArray[i]))
              //                 this.schedulerPreference.labelMap[key] = this.tagsService.assignObjectToTags(business, this.schedulerPreference.labelMap[key], this.tagsService.businessTagsArray);
              //         }
              //     }

              this.schedulerLinkService.setSchedulerLink(responseData.schedulerLink, responseData.business, responseData.miniWebsite);
              this.miniWebsiteService.setMiniWebsite(responseData.miniWebsite, responseData.business);
              if (responseData.styleUrl) //LOAD OVERRIDING STYLESHEET LAST SO IT HAS HIGHEST CSS PRECEDENCE
                  this.styleSheetService.addStylesheetToHead(responseData.styleUrl);
              if (responseData.schedulerLink.styleUrl)
                  this.styleSheetService.addStylesheetToHead(responseData.schedulerLink.styleUrl);


              return responseData;
            }),
            //USE BELOW MAP IF OBSERVE IN OPTIONS IS SET TO VALUE OF TYPE 'response'
            // map(responseData => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
            //     this.landingPageObjects = responseData.body;
            //     return this.landingPageObjects;
            // }),
            // catchError(errorRes => {
            //     //do something with it; generic error handling task
            //     //errorRes.newProperty = 'this is a new property';
            //     this.handleError(errorRes);
            //     return throwError(errorRes);
            // })
            catchError(this.apiService.handleError)
        )
    //.subscribe((responseData: landingPageObjects) => {

    //})
  }
}
