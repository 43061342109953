export class ValueOptionModel {
    label: string;
    value: any;
    selected: boolean = false;
    secondaryValue: any;
    constructor(label: string, value: any, selected?: boolean) {
        this.label = label;
        this.value = value;
        if (selected !== undefined)
            this.selected = selected;
    }
}
