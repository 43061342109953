import {
    AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit,
    Output, SimpleChanges
} from '@angular/core';
import {preference} from "../../../common-classes/app-objects.model";
import {FormGroup} from "@angular/forms";
import {BusinessService} from "../../../business.service";
import {ResponsiveService} from "../../../responsive.service";
import {ClientInfoService} from '../../client-info/client-info.service';

@Component({
  selector: 'scheduler-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, AfterViewChecked, OnChanges {
  @Output() onMovePanels = new EventEmitter<string>();
  @Output() onAddToCalendar = new EventEmitter();
  @Input() preference: preference;
  @Input() showBackButton: boolean;
  @Input() showBookAnotherButton: boolean;
  @Input() formGroup: FormGroup;
  @Input() titleProperty: string;
  @Input() titleId: string;
  @Input() nextBtnId: string;
  @Input() nextBtnLabelProp: string;
  @Input() disableSave: boolean;
  finalConfirmationFooter: boolean = false;
  showLoginRegisterBtns: boolean = false;
  @Input() showPoweredByTimeTap: boolean = false;
  @Input() showAddToCalendarBtn: boolean = false;

  @Input() pageLoaded: boolean;
  showNextBtn = false;
  constructor(private cdRef: ChangeDetectorRef, private businessService: BusinessService, private responsiveService: ResponsiveService, private clientInfoService: ClientInfoService) { }

  ngOnChanges(changes: SimpleChanges){
      if(changes.pageLoaded !== undefined && changes.pageLoaded.currentValue){
          this.showNextBtn = true;
          this.cdRef.detectChanges();
      }
  }

  adjustTitleHeight(){
      // if(this.preference.schedButtonsTopNav && this.responsiveService.innerWidth <= this.responsiveService.xsScreenBreakPoint){//TT-6092
      //     this.responsiveService.adjustMatCardTitleBarHeight("schedulerHeaderFooter")
      // }
      //
      // this.cdRef.detectChanges();

  }

  ngAfterViewChecked(){
      if(this.showBookAnotherButton === undefined) {
          this.showBookAnotherButton = true;
      }
      this.responsiveService.adjustTitleMargin(this.titleId);
      this.cdRef.detectChanges();

      // this.adjustTitleHeight();
  }

  checkFormGroupValid(): boolean {
      if (this.nextBtnId === 'saveBtn') {
          return this.clientInfoService.isClientInfoFormValid(this.formGroup, this.preference.schedulerPreferenceFieldDefnList)
      } else
          return this.formGroup.valid;
  }

  ngOnInit() {
      if(this.nextBtnId === 'bookAnother'){
          this.finalConfirmationFooter = true;
      }
      if(this.responsiveService.isEmbedded){
          let showBtns = this.responsiveService.showLoginRegisterBtnLogic(this.preference);
          if(showBtns.showLoginBtn || showBtns.showRegisterBtn){
              this.showLoginRegisterBtns = true;
          }
      }
  }

  movePanels(direction: string){
      this.onMovePanels.emit(direction);
  }
  addToCalendar(){
      this.onAddToCalendar.emit();
  }

}
