import {Client} from "../../common-classes/app-objects.model";
import {Appointment} from '../../../models/Appointment.model';
import {Reason} from '../../../models/Reason.model';

export class Package {
    public packageId: number;
    public businessId: number;
    public packageName: string;
    public serviceIds: number[];
    public price: number;
    public quantity: number;
    public expireInDays: number;
    public taxable: boolean;
    public reasonList: Reason[];
    public sortOrder: number;
    public imageURL: string;
    public description: string;
    public showAddedToCartSuccess?: boolean;
}

export class PackageSold {
    packageSoldId: number;
    businessId: number;
    packageId: number;
    pkg: Package; //lower case package is a reserved word in java
    client: Client;
    usedQuantity: number;
    maxQuantity: number;
    quantityUsedDisplay: string;
    status: string;
    purchaseDate: Date;
    expireDate: Date;
    price: number;
    taxable?: boolean;
    expanded?: boolean;
    appts?: Appointment[];
    apptsLoaded?: boolean;
    errorLoading?: boolean;
    loadingAppts?: boolean;
    dropdownDescription?: string;
    remainingQuantity: number;
}
