import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import * as $ from 'jquery';
import {StyleSheetService} from "../styleSheetService";
import {ResponsiveService} from "../responsive.service";

@Directive({
  selector: '[buttonStyling]'
})
export class ButtonStylingDirective implements OnInit, AfterViewChecked {
  @Input() btnId: string;
  @Input() btnClass: string;
  @Input() btnStyleId: string;
  @Input() baseStyle: string;
  @Input() actAsSpacer: boolean = false;
  @Input() fullHeight: boolean = false;
  @Input() catchOverflow: boolean = false;
  @Input() lineHeight: string;
  @Input() removeBorderLeftRadius: boolean = false;
  @Input() removeBorderRightRadius: boolean = false;
  @Input() classesOnMobile: string[];
  @Input() float: string;
  color: string;
  borderColor: string;
  fontColor: string;
  selector: string;
  fontSelector: string;
  backgroundSelector: string;

  constructor(private styleSheetService: StyleSheetService, private responsiveService: ResponsiveService,
              private el: ElementRef, private renderer: Renderer2) { }

  setBaseStyleProp(){
    let baseStyleProp: string = 'basicBtn';
    switch (this.baseStyle) {
      case 'primary':
        baseStyleProp = 'primaryBtn';
        break;
      case 'success':
        baseStyleProp = 'successBtn';
        break;
      case 'error':
        baseStyleProp = 'errorBtn';
        break;
      case 'accent':
        baseStyleProp = 'accentBtn';
        break;
    }
    return baseStyleProp;
  }

  setStyleOnButton(){
    $(this.selector).css({'border-color': this.borderColor});
    $(this.fontSelector).css({'color': this.fontColor});
    $(this.backgroundSelector).css({'background-color': this.color});
    if(this.actAsSpacer){
      this.renderer.setStyle(this.el.nativeElement, 'visibility','hidden');
      // $(this.selector).css({'visibility': 'hidden'});
    }
    if(this.removeBorderLeftRadius) {
      this.renderer.setStyle(this.el.nativeElement, 'border-top-left-radius','0px');
      this.renderer.setStyle(this.el.nativeElement, 'border-bottom-left-radius','0px');
      // $(this.selector).css({'border-top-left-radius': '0px', 'border-bottom-left-radius': '0px'});
    }
    if(this.removeBorderRightRadius){
      this.renderer.setStyle(this.el.nativeElement, 'border-top-right-radius','0px');
      this.renderer.setStyle(this.el.nativeElement, 'border-top-right-radius','0px');
      // $(this.selector).css({'border-top-right-radius': '0px', 'border-bottom-right-radius': '0px'});
    }
    if(this.fullHeight){
      this.renderer.setStyle(this.el.nativeElement, 'flex','1 1 100%');
      this.renderer.setStyle(this.el.nativeElement, 'height','100%');
      this.renderer.setStyle(this.el.nativeElement, 'display','block');
      this.renderer.setStyle(this.el.nativeElement, 'width','100%');
      let fontSize = '1rem';
      if(this.responsiveService.onMobileViewPort)
        fontSize = '.84rem';
      this.renderer.setStyle(this.el.nativeElement, 'font-size',fontSize);
    }
    if(this.catchOverflow){
      this.renderer.setStyle(this.el.nativeElement, 'white-space','normal');
      this.renderer.setStyle(this.el.nativeElement, 'overflow-wrap','break-word');
    }
    if(this.lineHeight !== undefined){
      this.renderer.setStyle(this.el.nativeElement, 'line-height',this.lineHeight);
    }
    if(this.float !== undefined){
      this.renderer.setStyle(this.el.nativeElement, 'float',this.float);
    }
    if(this.responsiveService.onMobileViewPort){
      this.renderer.addClass(this.el.nativeElement, 'btn-sm');
      if(this.classesOnMobile !== undefined && this.classesOnMobile !== null && this.classesOnMobile.length > 0){
        for(let i = 0, x = this.classesOnMobile.length; i < x; i++){
          this.renderer.addClass(this.el.nativeElement, this.classesOnMobile[i]);
        }
      }
    }
  }

  ngAfterViewChecked(): void{
    this.setStyleOnButton();
  }

  defineStyles(){
    let baseStyleProp: string = this.setBaseStyleProp();
    let baseButtonMap = this.styleSheetService.baseButtons;
    //If I don't have to set the btn id on the template then I am not; only when it has to get interpretted on load (like for the nextBtn) am I setting it; otherwise, reading from nativeElement
    if(this.btnId === undefined && this.el.nativeElement.attributes.id !== undefined){
      this.btnId = this.el.nativeElement.attributes.id.value;
    }
    // console.log("This.btnid = " + this.btnId)

    let propToMatchOn = this.btnId;
    this.selector = "#" + this.btnId;
    if(this.btnStyleId !== undefined){
      propToMatchOn = this.btnStyleId;
    }

    if(propToMatchOn === undefined){
      propToMatchOn = this.btnClass;
      this.selector = "." + propToMatchOn;
    }

    this.selector = this.selector + '.mat-stroked-button';
    // console.log("propToMatchOn ")
    // console.log(propToMatchOn)
    this.color = baseButtonMap[baseStyleProp].color;
    // console.log('baseButtonMap', baseButtonMap);
    let mwStyleMap = this.styleSheetService.mwStyleMap;
    // console.log("mwStyleMap ")
    // console.log(mwStyleMap)
    for(let prop in mwStyleMap){
      if(prop === propToMatchOn){
        this.color = mwStyleMap[prop].color;
      }
    }
    // console.log("this.color " + this.color);
    this.borderColor = this.color + '80';
    this.fontColor = this.color;
    this.fontSelector = this.selector + ' span, ' + this.selector + ' span > span, '  + this.selector + ' i';
    this.backgroundSelector = this.selector +' > div.mat-button-ripple > div.mat-ripple-element, #' + this.btnId + '.mat-button-focus-overlay';
    // console.log(this.el.nativeElement.attributes);
  }

  ngOnInit(): void {
    this.defineStyles();

  }

}
