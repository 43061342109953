import {Injectable, Injector} from '@angular/core';
import {ResponsiveService} from '../../../../responsive.service';
import {Subject} from 'rxjs/index';
import {MatDialog} from '@angular/material';
import {PromptPackagePayComponent} from './prompt-package-pay.component';
import {Invoice} from '../../../../common-classes/app-objects.model';

@Injectable({
  providedIn: 'root'
})
export class PromptPackagePayService {
  responsiveService: ResponsiveService;
  modalClosed = new Subject<string>();
  dialogRef: any;
  constructor(private injector: Injector, private dialog: MatDialog) {
    this.responsiveService = injector.get(ResponsiveService);
  }

  openModal(showPackageOptionCounter: number, invoice: Invoice) {
    const dialogRef = this.dialog.open(PromptPackagePayComponent,
        {
          maxWidth: this.responsiveService.getMaxWidthForModals(),
          data: {invoice: invoice, showPackageOptionCounter: showPackageOptionCounter}
        })
    dialogRef.afterClosed().subscribe((payWithPackages: string) => {
      this.modalClosed.next(payWithPackages);
    })

  }
}
