import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SchedulerComponent} from './scheduler/scheduler.component';
import {ClientAccountComponent} from "./client-account/client-account.component";
import {AuthGuard} from "./auth/auth.guard";
import {ViewAppointmentsComponent} from "./client-account/view-appointments/view-appointments.component";
import {ClientAccountHomeComponent} from "./client-account/client-account-home/client-account-home.component";
import {AppointmentDetailComponent} from "./client-account/view-appointments/booking-object-details/appointment-detail/appointment-detail.component";
import {ViewAppointmentsListComponent} from "./client-account/view-appointments/view-appointments-list/view-appointments-list.component";
import {ViewInvoicesComponent} from "./client-account/view-invoices/view-invoices.component";
import {ViewInvoicesListComponent} from "./client-account/view-invoices/view-invoices-list/view-invoices-list.component";
import {InvoiceDetailComponent} from "./client-account/view-invoices/invoice-detail/invoice-detail.component";
import {ClientProfileComponent} from "./client-account/client-profile/client-profile.component";
import {ClientProfileContainerComponent} from "./client-account/client-profile/client-profile-container/client-profile-container.component";
import {DisclaimerFormComponent} from "./disclaimer-form/disclaimer-form.component";
import {DisclaimerFormDetailComponent} from "./disclaimer-form/disclaimer-form-detail/disclaimer-form-detail.component";
import {RepeatingAppointmentDetailComponent} from "./client-account/view-appointments/booking-object-details/repeating-appointment-detail/repeating-appointment-detail.component";
import {WaitlistRegistrationDetailComponent} from "./client-account/view-appointments/booking-object-details/waitlist-registration-detail/waitlist-registration-detail.component";
import {EmailSettingsContainerComponent} from './email-settings/email-settings-container.component';
import {EmailSettingsComponent} from './email-settings/email-settings.component';
import { ViewAppointmentIntentsComponent } from './client-account/view-appointment-intents/view-appointment-intents.component';
import { ViewAppointmentIntentsListComponent } from './client-account/view-appointment-intents/view-appointment-intents-list/view-appointment-intents-list.component';

const routes: Routes = [
  { path: '', component: SchedulerComponent },
  { path: 'scheduler', component: SchedulerComponent },
  { path: 'invoices', component: ViewInvoicesComponent,
      children: [
          {path: "", component: ViewInvoicesListComponent},
          {path: ":invoiceHash", component: InvoiceDetailComponent, pathMatch: 'full'}
      ]
  },
  { path: 'emailSettings', component: EmailSettingsContainerComponent,
      children: [
           {path: "", component: EmailSettingsContainerComponent},
           {path: ":clientHash", component: EmailSettingsComponent, pathMatch: 'full'}
      ]
  },
  { path: 'forms', component: DisclaimerFormComponent,
      children: [
          {path: "", component: DisclaimerFormComponent},
          {path: ":disclaimerFormUUID", component: DisclaimerFormDetailComponent, pathMatch: 'full'}
      ]
  },
  { path: 'appts', component: ViewAppointmentsComponent,
      children: [
          {path: "", component: ViewAppointmentsListComponent},
          {path: ":apptHash", component: AppointmentDetailComponent, pathMatch: 'full'}
      ]
  },
  { path: 'my-account', component: ClientAccountComponent, canActivate: [AuthGuard],
    children: [
        {path: "", component: ClientAccountHomeComponent},
        {path: "appts", component: ViewAppointmentsComponent,
          children: [
              {path: "", component: ViewAppointmentsListComponent},
              {path: ":apptHash", component: AppointmentDetailComponent, pathMatch: 'full'}
          ]
        },
        {path: "repeating", component: ViewAppointmentsComponent,
            children: [
                {path: "", component: ViewAppointmentsListComponent},
                {path: ":id", component: RepeatingAppointmentDetailComponent, pathMatch: 'full'}
            ]
        },
        {path: "waitlistRegistration", component: ViewAppointmentsComponent,
            children: [
                {path: "", component: ViewAppointmentsListComponent},
                {path: ":waitlistId", component: WaitlistRegistrationDetailComponent, pathMatch: 'full'}
            ]
        },
        {path: "invoices", component: ViewInvoicesComponent,
            children: [
                {path: "", component: ViewInvoicesListComponent},
                {path: ":invoiceHash", component: InvoiceDetailComponent, pathMatch: 'full'}
            ]
        },
        { path: 'forms', component: DisclaimerFormComponent,
            children: [
                {path: "", component: DisclaimerFormComponent},
                {path: ":disclaimerFormUUID", component: DisclaimerFormComponent, pathMatch: 'full'}
            ]
        },
        {path: "profile", component: ClientProfileComponent,
            children: [
                {path: "", component: ClientProfileContainerComponent}
            ]
        },
        {path: "intents", component: ViewAppointmentIntentsComponent,
            children: [
                {path: "", component: ViewAppointmentIntentsListComponent}
            ]
        }
    ]
  },
    // {
    //     path: 'cart',
    //     loadChildren: () => import('./store/cart/cart.module').then(m => m.CartModule)
    // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
