import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {
    CSFilterParameterBean, movePanels, preference, currency, CouponAPIReturnData, Coupon
} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {MatDialog} from "@angular/material";
import {ScreeningQuestionComponent} from "../screening-question/screening-question.component";
import {Subject} from "rxjs";
import {ResponsiveService} from "../../responsive.service";
import {SelectSchedulingObject} from "../common-components/scheduler-list-items/scheduler-list-items.component";
import {Reason, ReasonDuration, RepeatingApptOption} from '../../../models/Reason.model';
import {ReasonsService} from '../../reasons.service';
import {schedulerPanel} from '../../../models/SchedulerPanel.model';
import {SchedulerService} from '../scheduler.service';
import {MainObjectTypes} from '../../../models/MainObjectTypes.model';
import {SelectPayAmountComponent} from '../../modals/select-pay-amount/select-pay-amount.component';
import {SelectAddOnsComponent} from '../../modals/select-add-ons/select-add-ons.component';
import {SelectAddOnsModalData} from '../../../models/SelectAddOnsModalData.model';

@Component({
  selector: 'reasons-panel',
  templateUrl: './reasons.component.html',
    styleUrls: ['../scheduler.component.css']
})
export class ReasonsComponent implements OnInit, OnDestroy {
    @Output() onMovePanels = new EventEmitter<movePanels>();
    @Input() preference: preference;
    @Input() panel: schedulerPanel;
    @Input() CSFilterObj: CSFilterParameterBean;
    @Input() directionComingFrom: string;
    reasons: Reason[] = [];
    currency: currency;
    showBackButton: boolean = true;
    reasonsLoaded: boolean = false;
    errorLoading: boolean = false;
    reasonTextElementFloatHeight = 103;
    selectedReason: Reason;

    listItemType = MainObjectTypes.REASON;

    constructor(private businessService: BusinessService, private dialog: MatDialog, private cdRef: ChangeDetectorRef, private responsiveService: ResponsiveService,
                public reasonsService: ReasonsService, private schedulerService: SchedulerService) { }

    organizeReasonsForDisplay(reasons: Reason[]){
        this.reasons = this.reasonsService.formatReasonList(reasons);
        if(this.reasons.length === 1 && this.directionComingFrom === 'next'){
            if(this.CSFilterObj.reasonIdList === null) {
                if(this.preference.autoAdvanceWhenSingle && this.reasons[0].durationList === null){
                    this.selectObject(undefined, true, this.reasons[0]);
                } else {
                    this.selectObject(undefined, false, this.reasons[0]);
                }
            }
        } else if(this.reasons.length > 0 && this.panel.formGroup.value.reason !== null){
            let objFound = false;
            for(let i = 0, x = this.reasons.length; i < x; i++){
                if(this.reasons[i].reasonId === this.panel.formGroup.value.reason.reasonId){
                    this.reasons[i].selected = true;
                    this.selectedReason = this.reasons[i];
                    objFound = true;
                }
            }
            if(!objFound)
                this.panel.formGroup = this.schedulerService.getPanelForm(this.panel.panel, this.preference.schedulerPreferenceFieldDefnList);
        }

        this.reasonsService.applyingCoupon.next(false);
        this.reasonsLoaded = true;
        this.cdRef.detectChanges();
    }

    ngOnInit() {
        this.responsiveService.setHeightForEmbedScheduler();
        this.currency = this.businessService.currency;
        this.reasonsService.getReasons(this.CSFilterObj)
            .subscribe((couponAPIReturnData: CouponAPIReturnData) => {
                this.setReasonCouponData(couponAPIReturnData);
            }, (error)=> {
                this.reasonsLoaded = false;
                this.errorLoading = true;
            })
    }

    openSelectAddOns(direction: string) {
        const dataToPass: SelectAddOnsModalData = new SelectAddOnsModalData();
        dataToPass.selectedReason = this.selectedReason;
        dataToPass.availableAddOnsForSelectedReason = this.selectedReason.addOnReasonList;
        const dialogRef = this.dialog.open(SelectAddOnsComponent,
            {
                maxWidth: this.responsiveService.getMaxWidthForModals(),
                data: dataToPass
            })
        dialogRef.afterClosed().subscribe((responseData: SelectAddOnsModalData) => {
            if (responseData) {
                this.selectedReason.selectedAddOns = responseData.selectedAddOns;
                if (this.selectedReason.screeningQuestion)
                    this.openScreeningQuestion(direction);
                else
                    this.onMovePanels.emit({direction: direction, panel: this.panel});
            }
        })
    }

    openScreeningQuestion(direction: string) {
        const dialogRef = this.dialog.open(ScreeningQuestionComponent,
            {maxWidth: this.responsiveService.getMaxWidthForModals(), data: {screeningQuestion: this.selectedReason.screeningQuestion}, id: 'ReasonScreeningQuestionDialog', panelClass: 'screeningQuestionDialog'});
        dialogRef.afterClosed().subscribe(result => {
            if(result === 'pass'){
                this.onMovePanels.emit({direction: direction, panel: this.panel})
            }
        });
    }


    movePanels(direction: string){
        if(this.selectedReason) {
            this.selectedReason = this.isDurationSelected(this.selectedReason)
            this.selectedReason = this.isRepeatingOptionSelected(this.selectedReason)
        }
        if (direction === 'next' && this.selectedReason && !this.selectedReason.needToSelectDuration && !this.selectedReason.needToSelectRepeatingOption) {
            if (this.selectedReason.addOnReasonList && this.selectedReason.addOnReasonList.length > 0)
                this.openSelectAddOns(direction)
            else if(this.selectedReason.screeningQuestion)
                this.openScreeningQuestion(direction);
            else
                this.onMovePanels.emit({direction: direction, panel: this.panel})
        } else if (direction === 'back')
            this.onMovePanels.emit({direction: direction, panel: this.panel})
    }

    selectSchedulingObj(schedulingObject: SelectSchedulingObject){
        this.selectObject(schedulingObject.changeEvent, schedulingObject.moveForward, schedulingObject.itemSelected);
    }

    isDurationSelected(reason: Reason): Reason {
        if(reason.multiDuration){
            if(reason.selectedDuration.duration !== undefined && reason.selectedDuration.duration !== null){
                reason.visitMinutes = reason.selectedDuration.duration;
                reason.price = reason.selectedDuration.price;
                reason.originalPrice = reason.selectedDuration.originalPrice;
                reason.depositAmount = reason.selectedDuration.deposit;
                reason.needToSelectDuration = false;
            } else {
                reason.visitMinutes = null;
                reason.price = null;
                reason.originalPrice = null;
                reason.depositAmount = null;
                reason.needToSelectDuration = true;
            }
        } else {
            reason.needToSelectDuration = false;
        }
        return reason;
    }

    isRepeatingOptionSelected(reason: Reason): Reason {
        if(reason.allowRepeating !== null && reason.allowRepeating > -1){
            if(reason.selectedRepeatingApptOption.numberOfAppointments !== undefined && reason.selectedRepeatingApptOption.numberOfAppointments !== null){
                reason.price = reason.selectedRepeatingApptOption.price / reason.selectedRepeatingApptOption.numberOfAppointments;
                reason.depositAmount = reason.selectedRepeatingApptOption.deposit;
                reason.needToSelectRepeatingOption = false;
            } else {
                reason.price = null;
                reason.depositAmount = null;
                reason.needToSelectRepeatingOption = true;
            }
        } else {
            reason.needToSelectRepeatingOption = false;
        }
        return reason;
    }

    selectObject(changeEvent: any, moveForward: boolean, reasonSelected?: Reason){
        if(!this.businessService.checkInnerHTMLForHrefClick(changeEvent)) {
            //remove selected from all other reasons; this is an issue when you initially select a reason that is multiple duration and it doesn't auto advance you
            //if you then select another reason it looks like you've selected two reasons
            for (let i = 0, x = this.reasons.length; i < x; i++) {
                this.reasons[i].selected = false;
            }
            let reason = reasonSelected || changeEvent.reason;

            reason.selected = true;
            this.selectedReason = reason;
            reason = this.isDurationSelected(reason);
            reason = this.isRepeatingOptionSelected(reason);

            //
            this.CSFilterObj.reasonRequiresIntent = reason.requireApptIntent;
            this.panel.formGroup.controls['reason'].setValue(reason);
            if (event.defaultPrevented !== undefined) {//prevents function from getting called twice (once from child component and once from parent component)
                event.preventDefault();
            }
            if (!reason.needToSelectDuration && !reason.needToSelectRepeatingOption && moveForward) {
                this.movePanels('next');
            }
        }
    }

    setReasonCouponData(couponAPIReturnData: CouponAPIReturnData) {
        this.organizeReasonsForDisplay(couponAPIReturnData.reasonAPIBeanList);
        if (couponAPIReturnData.status) {
            this.reasonsService.coupon.next(couponAPIReturnData.coupon);
            this.reasonsService.couponStatus.next(couponAPIReturnData.status);
            this.reasonsService.couponStatusDetail.next(couponAPIReturnData.statusDetail);
            if (couponAPIReturnData.status === 'SUCCESS')
                this.CSFilterObj.coupon = couponAPIReturnData.coupon;
        }
    }

    applyCouponToReasons(couponCode: string){
        if(couponCode !== undefined && couponCode !== null){
            couponCode = couponCode.trim();
        }
        if(couponCode === undefined || couponCode === null || couponCode === ''){
            this.reasonsService.couponStatus.next('ERROR');
            this.reasonsService.couponStatusDetail.next('NO_CODE_ENTERED');
            this.reasonsService.applyingCoupon.next(false);
        } else {
            this.reasonsService.applyingCoupon.next(true);
            /*

             */
            this.reasonsService.applyCouponToReasons(couponCode, this.reasons).subscribe((responseData: CouponAPIReturnData) => {
                this.setReasonCouponData(responseData);
            }, error => {
                this.reasonsService.applyingCoupon.next(false);
                this.reasonsService.couponStatus.next("Error");
                this.reasonsService.couponStatusDetail.next("HTTP")
            });
        }

    }

    // Complete the subject when your component is destroyed to avoid memory leaks
    ngOnDestroy() {
        this.reasonsService.applyingCoupon.complete();
        this.reasonsService.couponStatus.complete();
        this.reasonsService.couponStatusDetail.complete();
        this.reasonsService.coupon.complete();
    }


}
