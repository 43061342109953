import {AfterViewChecked, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StyleSheetService} from "../styleSheetService";
import * as $ from 'jquery';

@Directive({
  selector: '[appAlert]'
})
export class AlertDirective implements OnInit, OnChanges {
  @Input() baseStyle: string;
  backgroundColor: string;
  fontColor: string;
  borderColor: string;

  constructor(private styleSheetService: StyleSheetService, private el: ElementRef) { }

  setBaseStyleProp(){
    let baseStyleProp: string = 'infoAlert';
    switch (this.baseStyle) {
      case 'success':
        baseStyleProp = 'successAlert';
        break;
      case 'error':
        baseStyleProp = 'errorAlert';
        break;
    }
    return baseStyleProp;
  }

  setStyles() {
    let baseStyleProp: string = this.setBaseStyleProp();
    let baseAlertMap = this.styleSheetService.baseAlerts;
    this.backgroundColor = baseAlertMap[baseStyleProp].backgroundColor;
    this.fontColor = baseAlertMap[baseStyleProp].color;
    this.borderColor = baseAlertMap[baseStyleProp].borderColor;
    this.el.nativeElement.style.backgroundColor = this.backgroundColor;
    this.el.nativeElement.style.color = this.fontColor;
    this.el.nativeElement.style.borderColor = this.borderColor;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.baseStyle)
      this.setStyles();
  }

  ngOnInit(): void {
    this.setStyles();
  }
}
