import { Injectable } from '@angular/core';
import {Location} from '../../models/Location.model';
import {Staff} from '../../models/Staff.model';
import {Reason} from '../../models/Reason.model';
import {Group} from '../../models/Group.model';

@Injectable({
  providedIn: 'root'
})
export class BookingComponentsService {

  constructor() { }

  matchLocations(object: Location, objects: Location[], addObject: boolean): Location {
    let objFound = false;
    if (object)
      for (let i = 0, x = objects.length; i < x; i++)
        if (objects[i].locationId === object.locationId) {
          objFound = true;
          return objects[i];
        }
    if (!objFound) {
      if (object && addObject)
        objects.unshift(object);
      return objects[0];
    }
  }

  matchStaff(object: Staff, objects: Staff[], addObject: boolean): Staff {
    let objFound = false;
    if (object)
      for (let i = 0, x = objects.length; i < x; i++)
        if (objects[i].professionalId === object.professionalId) {
          objFound = true;
          return objects[i];
        }
    if (!objFound) {
      if (object && addObject)
        objects.unshift(object);
      return objects[0];
    }
  }

  matchReason(object: Reason, objects: Reason[], addObject: boolean): Reason {
    let objFound = false;
    if (object)
      for (let i = 0, x = objects.length; i < x; i++)
        if (objects[i].reasonId === object.reasonId) {
          objFound = true;
          return objects[i];
        }
    if (!objFound) {
      if (object && addObject)
        objects.unshift(object);
      return objects[0];
    }
  }

  matchGroup(object: Group, objects: Group[], addObject: boolean): Group {
    let objFound = false;
    if (object)
      for (let i = 0, x = objects.length; i < x; i++)
        if (objects[i].groupId === object.groupId) {
          objFound = true;
          return objects[i];
        }
    if (!objFound) {
      if (object && addObject)
        objects.unshift(object);
      return objects[0];
    }
  }
}
