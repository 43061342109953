import {NgModule} from "@angular/core";
import {StoreComponent} from "./store.component";
import {CommonModule} from "@angular/common";
import {PackagesModule} from "./packages/packages.module";
import {StoreRoutingModule} from "./store-routing.module";

@NgModule({
    declarations: [StoreComponent],
    imports:[CommonModule, PackagesModule, StoreRoutingModule]
})
export class StoreModule{}