import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {ResponsiveService} from "../responsive.service";

@Directive({
  selector: '[appWrapperClasses]'
})
export class WrapperClassesDirective implements OnInit{
  @Input() wrapperElement: string;
  @Input() wrapperClass: string;
  @Input() onMobile: boolean;
  constructor(private elementRef: ElementRef, private responsiveService: ResponsiveService, private renderer: Renderer2) { }

  ngOnInit(): void {
    if(this.onMobile && this.responsiveService.onMobileViewPort){
      let wrapperEl = this.renderer.createElement(this.wrapperElement);
      this.renderer.addClass(wrapperEl, this.wrapperClass);
      // console.log(wrapperEl);
      let el = this.elementRef.nativeElement;
      // console.log(el);
      let parent = el.parentNode;
      // console.log(parent);
      this.renderer.insertBefore(parent,wrapperEl,el);
      this.renderer.appendChild(wrapperEl, el);
    }
  }

}
