import {NgModule} from "@angular/core";
import {CartComponent} from "./cart.component";
import {MaterialModule} from "../../material.module";
import {CommonModule} from "@angular/common";
import {CouponModule} from "../../scheduler/common-components/coupon/coupon.module";
import {UIElementsModule} from "../../ui-elements/ui-elements.module";
import {CartRoutingModule} from "./cart-routing.module";

@NgModule({
    declarations: [
        CartComponent,
    ],
    imports: [
        CartRoutingModule,
        CommonModule,
        MaterialModule,
        UIElementsModule,
        CouponModule
    ]
})
export class CartModule {

}
