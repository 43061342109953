import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
    CSFilterParameterBean, loginRegisterPopUpObject,
    movePanels,
    preference,
} from "../../common-classes/app-objects.model";
import { AppointmentIntent } from "../../../models/AppointmentIntent.model";
import { BusinessService } from "../../business.service";
import { ResponsiveService } from "../../responsive.service";
import { schedulerPanel } from "../../../models/SchedulerPanel.model";
import { SchedulerService } from "../scheduler.service";
import { SelectSchedulingObject } from "../common-components/scheduler-list-items/scheduler-list-items.component";
import {ClientAccountService} from "../../client-account/client-account.service";
import {AuthService} from "../../auth/auth.service";
import {Subscription} from "rxjs";

@Component({
  selector: "appointment-intent-panel",
  templateUrl: "./appointment-intent-panel.component.html",
})
export class AppointmentIntentPanelComponent implements OnInit {
  @Output() onMovePanels = new EventEmitter<movePanels>();
  @Input() preference: preference;
  @Input() panel: schedulerPanel;
  @Input() CSFilterObj: CSFilterParameterBean;
  @Input() inputValues: any;
  @Input() panels: schedulerPanel[];
  @Input() directionComingFrom: string;
  appointmentIntents: AppointmentIntent[] = [];
  totalAppointmentIntentLength: number;
  appointmentIntentLoaded: boolean = false;
  errorLoading: boolean = false;
  invalidIntent: boolean = false;
  showBackButton: boolean = true;
  loginClosedSubscription: Subscription;
  registerClosedSubscription: Subscription;

  constructor(
    private businessService: BusinessService,
    private cdRef: ChangeDetectorRef,
    private responsiveService: ResponsiveService,
    private schedulerService: SchedulerService,
    private clientAccountService: ClientAccountService,
    private authService: AuthService
  ) {}

  getAppointmentAttents() {

          if (this.clientAccountService.loggedInClient &&
              this.clientAccountService.loggedInClient.clientList.length > 0) {
                  let apptIntentId;
                  if (sessionStorage.getItem("appointmentIntentId")) {
                      apptIntentId = JSON.parse(sessionStorage.getItem("appointmentIntentId"));
                  }
                  if (apptIntentId) {
                      this.businessService.getAppointmentIntentById(apptIntentId).subscribe(
                          (appointmentIntent: AppointmentIntent) => {
                              if (appointmentIntent.active && !appointmentIntent.booked) {
                                  this.selectObject(
                                      appointmentIntent,
                                      true
                                  );
                              } else {
                                  this.invalidIntent = true;
                                  this.appointmentIntentLoaded = false;
                              }
                              this.appointmentIntentLoaded = true;
                              this.cdRef.detectChanges();
                          },
                          (error) => {
                              console.error("error:", error);
                              this.appointmentIntentLoaded = false;
                              this.errorLoading = true;
                          }
                      );
                  } else {
                   let clientId = this.clientAccountService.loggedInClient.clientList[0].clientId;
                      this.businessService.getAppointmentIntents(clientId).subscribe(
                          (appointmentIntents: AppointmentIntent[]) => {
                              this.appointmentIntents = appointmentIntents;
                              this.handleImages();
                              if (this.appointmentIntents.length === 0) {
                                  this.totalAppointmentIntentLength = 0;
                                  this.appointmentIntentLoaded = true;
                                  this.cdRef.detectChanges();
                              } else if (
                                  this.appointmentIntents.length === 1 &&
                                  this.directionComingFrom === "next"
                              ) {
                                  this.selectObject(
                                      this.appointmentIntents[0],
                                      this.preference.autoAdvanceWhenSingle
                                  );
                              } else {
                                  if (this.schedulerService.schedulerPreferenceService.schedulerPreference.booleanMap.selectAppointmentIntent) {
                                      this.totalAppointmentIntentLength = this.appointmentIntents.length;

                                      if (this.panel.formGroup.value.appointmentIntent !== null) {
                                          this.panel.formGroup = this.schedulerService.getPanelForm(
                                              this.panel.panel,
                                              this.preference.schedulerPreferenceFieldDefnList
                                          );
                                      }
                                      this.appointmentIntentLoaded = true;
                                      this.cdRef.detectChanges();
                                  } else {
                                      // skip panel
                                      this.panel.formGroup.setErrors(null);
                                      this.movePanels('skip');
                                  }
                              }
                          },
                          (error) => {
                              console.error("error:", error);
                              this.appointmentIntentLoaded = false;
                              this.errorLoading = true;
                          }
                      );

                  }
          } else {
              let loginTriggeredData: loginRegisterPopUpObject = new loginRegisterPopUpObject();
              loginTriggeredData.loadMyAccount = false;
              loginTriggeredData.calledFrom = 'SCHEDULER';
              loginTriggeredData.callSubscriptionNext = true;
              this.authService.loginCalled.next(loginTriggeredData);
          }
  }

  ngOnInit() {
    this.responsiveService.setHeightForEmbedScheduler();


      this.loginClosedSubscription = this.authService.loginClosed.subscribe((loginTriggeredData: loginRegisterPopUpObject) => {
          if(this.authService.isAuth())
              this.getAppointmentAttents();
      });
      this.registerClosedSubscription = this.authService.registerClosed.subscribe((loginTriggeredData: loginRegisterPopUpObject) => {
          if(this.authService.isAuth())
              this.getAppointmentAttents();
      });
      if (this.schedulerService.schedulerLinkService.schedulerLink.booleanMap.appointmentIntentRequired
          || this.CSFilterObj.reasonRequiresIntent) {

          this.getAppointmentAttents();
      }else {
          this.movePanels('skip');
      }
  }
  movePanels(direction: string) {
    this.onMovePanels.emit({ direction: direction, panel: this.panel });
  }

  selectSchedulingObject(schedulingObject: SelectSchedulingObject) {
    this.selectObject(
      schedulingObject.itemSelected,
      schedulingObject.moveForward,
      schedulingObject.changeEvent
    );
  }
  selectObject(
    appointmentIntent: AppointmentIntent,
    moveForward: boolean,
    event?: MouseEvent
  ) {
    if (!this.businessService.checkInnerHTMLForHrefClick(event)) {
      appointmentIntent.selected = true;
      this.CSFilterObj.appointmentIntentId = appointmentIntent.appointmentIntentId;
      this.panel.formGroup.patchValue({ appointmentIntent: appointmentIntent });
      if (moveForward) {
        this.movePanels("next");
      }
    }
  }
  /***
   * This is a hack so intents will have a null imageUrl property
   *  Necessary for the shared html component to render properly
   */
  handleImages(){
    for(let i=0;i<this.appointmentIntents.length;i++){
      this.appointmentIntents[i].imageUrl = null;
    }
  }
}
