import {Group} from './Group.model';
import {SingleTimeSlot} from '../app/scheduler/time/time-objects.model';
import {
    Client,
    Coupon
} from '../app/common-classes/app-objects.model';
import {Reason, ReasonDuration} from './Reason.model';
import {CustomField} from './CustomField.model';
import {Location} from './Location.model';
import {Staff} from './Staff.model';
import {AppointmentIntent} from "./AppointmentIntent.model";

export class AppointmentBase {
    public calendarId: number;
    public businessId: number;
    public status: string = 'OPEN';
    public subStatus: string = 'OPEN';
    public clientStartDate: string;
    public clientEndDate: string;
    public clientStartTime: number | string;
    public clientEndTime: number;
    public location: Location;
    public staff: Staff;
    public reason: Reason;
    public fields?: CustomField[] = [];
    public startDate?: string;
    public endDate?: string;
    public startTime?: number | string;
    public endTime?: number;
    public clientStartTimeForDisplay?: number;
    public clientEndTimeForDisplay?: number;
    public clientStartTimeDate?: number | Date;
    public clientEndTimeDate?: number | Date;
    public staffStartTimeDate?: Date;
    public staffEndTimeDate?: Date;
    public startDateTimeUTC?: number | Date;
    public endDateTimeUTC?: number | Date;
    public clientStartDateTimeUTC?: Date | number;
    public clientEndDateTimeUTC?: Date;
    public classScheduleId?: number;
    public locationSuperGroup?: Group;
    public locationGroup?: Group;
    public reasonSuperGroup?: Group;
    public reasonGroup?: Group;
    public seats?: number;
    public price?: number;
    public coupon?: Coupon;
    public address?: string;
    public hashId?: string
    public waitListInviteUUId?: string;
    public appointmentDateTimeClient?: string;
    apptTZ?: string;
    apptQRCode: string;
    apptCheckInQRCode: string;
    addOnReasonIdList?: number[];
    addOnReasonList?: Reason[];
    appointmentIntent? : AppointmentIntent;
    public apptDurationMinutesClient?: number;

    constructor() {
    }
    copyPropsFromAPI(appointment: Appointment) {
        for (const prop in appointment)
            this[prop] = appointment[prop];
    }
    createNew() {
        this.calendarId = null;
        this.businessId = null;
        this.status = null;
        this.subStatus = null;
        this.clientStartDate = null;
        this.clientEndDate = null;
        this.clientStartTime = null;
        this.clientEndTime = null;
        this.locationGroup = null;
        this.location = null;
        this.staff = null;
        this.reasonGroup = null;
        this.reason = null;

    }
}

export class Appointment extends AppointmentBase {
    public client: Client;
    public calTimeSlot: SingleTimeSlot;
    public remindClientSmsHrs?: number;
    public hideGuestOption?: boolean;
    public invoiceId?: number;
    public sendConfirmationToStaff?: boolean;
    public sendConfirmationToClient?: boolean;
    public thankYouString?: string;
    public waitListMode?: boolean;
    public cancelReason?: string;
    public clientRescheduleCount?: number;
    public reasonDesc: string;
    recurringAppointmentId?: number;
    duration: ReasonDuration;
    constructor() {
        super();
    }
    copyPropsFromAPI(appointment: Appointment) {
        super.copyPropsFromAPI(appointment);
        for (const prop in appointment)
            this[prop] = appointment[prop];
    }
    createNew() {
        super.createNew();
        this.client = null;
        this.calTimeSlot = null;
        this.reasonDesc = null;
        this.duration = null;
        return this;
    }


}
