import { Injectable } from '@angular/core';
import {ConfigListMap} from './common-classes/app-objects.model';
import {TitleCasePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConfigListService {
  configList: ConfigListMap;
  constructor(private titleCasePipe: TitleCasePipe) { }

  setConfigList(configList: ConfigListMap) {
    this.configList = configList;
  }

  getTitleCaseLabel(labelProp: string): string {
    if (this.configList)
      return this.titleCasePipe.transform(this.configList[labelProp]);
    else return labelProp;
  }

  clearConfigList() {
    this.configList = null;
  }
}
