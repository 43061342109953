import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-appointment-intents',
  templateUrl: './view-appointment-intents.component.html'
})
export class ViewAppointmentIntentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
