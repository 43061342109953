import {NgModule} from "@angular/core";
import {CouponComponent} from "./coupon.component";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "../../../material.module";
import {FormsModule} from "@angular/forms";
import {UIElementsModule} from "../../../ui-elements/ui-elements.module";

@NgModule({
    declarations: [CouponComponent],
    imports: [CommonModule, MaterialModule, FormsModule, UIElementsModule],
    exports: [CouponComponent]
})

export class CouponModule {

}
