import {Component, Inject, OnInit} from '@angular/core';
import {Reason} from '../../../models/Reason.model';
import {currency, preference} from '../../common-classes/app-objects.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {SelectAddOnsModalData} from '../../../models/SelectAddOnsModalData.model';
import {TagsService} from '../../tags.service';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {BusinessService} from '../../business.service';
import {ReasonsService} from '../../reasons.service';
import {ResponsiveService} from '../../responsive.service';

@Component({
  selector: 'app-select-add-ons',
  templateUrl: './select-add-ons.component.html',
  styleUrls: ['./select-add-ons.component.css']
})
export class SelectAddOnsComponent implements OnInit {
  selectedReason: Reason;
  availableAddOnReasons: Reason[];
  selectedAddOnReasons: Reason[];
  preference: preference;
  currency: currency;
  title: string;
  description: string;
  addAddOnsBtn: string;
  declineAddOnsBtn: string;
  idProp = 'reasonId';
  imageProp = 'imageUrl';
  titleProp = 'reasonDesc';
  descriptionProp = 'reasonDetail';
  constructor(@Inject(MAT_DIALOG_DATA) private passedData: SelectAddOnsModalData, public dialogRef: MatDialogRef<SelectAddOnsComponent>,
              private tagsService: TagsService, private schedulerPreferenceService: SchedulerPreferenceService, private businessService: BusinessService,
              private reasonsService: ReasonsService, public responsiveService: ResponsiveService) {
    this.preference = schedulerPreferenceService.schedulerPreference;
    this.currency = businessService.currency;
  }

  selectAddOns(event: any, addOn: Reason) {
    addOn.selected = !addOn.selected;
  }

  dismissModal(addAddOns: boolean) {
    const closeModalData: SelectAddOnsModalData = this.passedData;
    if (addAddOns) {
      this.selectedAddOnReasons = [];
      for (let i = 0, x = this.availableAddOnReasons.length; i < x; i++) {
        if (this.availableAddOnReasons[i].selected)
          this.selectedAddOnReasons.push(this.availableAddOnReasons[i]);
      }
      if (this.selectedAddOnReasons.length === 0)
        this.selectedAddOnReasons = null;
      closeModalData.selectedAddOns = this.selectedAddOnReasons;
    }

    this.dialogRef.close(closeModalData);
  }

  ngOnInit() {
    this.selectedReason = this.passedData.selectedReason;
    this.availableAddOnReasons = this.reasonsService.formatReasonList(this.passedData.availableAddOnsForSelectedReason);
    this.title = this.tagsService.assignObjectToTags(this.selectedReason, this.preference.labelMap.selectAddOnsTitle, this.tagsService.reasonFieldTags);
    this.description = this.tagsService.assignObjectToTags(this.selectedReason, this.preference.labelMap.selectAddOnsDescription, this.tagsService.reasonFieldTags);
    this.addAddOnsBtn = this.tagsService.assignObjectToTags(this.selectedReason, this.preference.labelMap.selectAddOnsAddAddOnsBtn, this.tagsService.reasonFieldTags);
    this.declineAddOnsBtn = this.tagsService.assignObjectToTags(this.selectedReason, this.preference.labelMap.selectAddOnsDeclineAddOnsBtn, this.tagsService.reasonFieldTags);

  }

}
