import {Injectable, Injector} from '@angular/core';
import { miniWebsite} from './common-classes/app-objects.model';
import {StyleSheetService} from './styleSheetService';
import {SchedulerPreferenceService} from './scheduler-preference.service';
import {ResponsiveService} from './responsive.service';
import {Meta, Title} from '@angular/platform-browser';
import {TagsService} from './tags.service';
import {Business} from '../models/Business.model';

@Injectable({
  providedIn: 'root'
})
export class MiniWebsiteService {
  miniWebsite: miniWebsite;
  styleSheetService: StyleSheetService;
  schedulerPreferenceService: SchedulerPreferenceService;

  tagsService: TagsService;
  constructor(private injector: Injector, private titleService: Title, private meta: Meta) {
    this.styleSheetService = injector.get(StyleSheetService);
    this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
    this.tagsService = injector.get(TagsService);
  }



  configureMiniWebsite(business: Business){
    this.styleSheetService.createStyleSheet(this.miniWebsite, this.schedulerPreferenceService.schedulerPreference);
    if (this.miniWebsite.styleSheetMap !== undefined && this.miniWebsite.styleSheetMap !== null &&
        this.miniWebsite.styleSheetMap.body !== undefined && this.miniWebsite.styleSheetMap.body !== null &&
        this.miniWebsite.styleSheetMap.body.favicon !== undefined && this.miniWebsite.styleSheetMap.body.favicon !== null) {
      let faviconLinks = document.getElementsByClassName("appFavicon");
      for (let i = 0, x = faviconLinks.length; i < x; i++)
        faviconLinks[i].setAttribute('href', this.miniWebsite.styleSheetMap.body.favicon)
    }
    let pageTitle = 'TimeTap Scheduling';
    if (this.schedulerPreferenceService.schedulerPreference && this.schedulerPreferenceService.schedulerPreference.labelMap && this.schedulerPreferenceService.schedulerPreference.labelMap.pageTitle && this.schedulerPreferenceService.schedulerPreference.labelMap.pageTitle !== '')
      pageTitle = this.schedulerPreferenceService.schedulerPreference.labelMap.pageTitle;
    else if (this.miniWebsite && this.miniWebsite.pageTitle && this.miniWebsite.pageTitle !== '')
      pageTitle = this.miniWebsite.pageTitle;
    pageTitle = this.tagsService.assignObjectToTags(business, pageTitle, this.tagsService.businessTagsArray);

    let metaDescription = 'Schedule an appointment online, book online';
    if (this.schedulerPreferenceService.schedulerPreference && this.schedulerPreferenceService.schedulerPreference.labelMap && this.schedulerPreferenceService.schedulerPreference.labelMap.metaDescription && this.schedulerPreferenceService.schedulerPreference.labelMap.metaDescription !== '')
      metaDescription = this.schedulerPreferenceService.schedulerPreference.labelMap.metaDescription;
    else if (this.miniWebsite && this.miniWebsite.metaDescription && this.miniWebsite.metaDescription !== '')
      metaDescription = this.miniWebsite.metaDescription;
    this.titleService.setTitle(pageTitle);
    metaDescription = this.tagsService.assignObjectToTags(business, metaDescription, this.tagsService.businessTagsArray);
    this.meta.addTag({name: 'description', content: metaDescription});
  }

  setMiniWebsite(miniWebsite: miniWebsite, business: Business) {
    this.miniWebsite = miniWebsite;
    if (this.miniWebsite)
     this.configureMiniWebsite(business);
  }

  createErrorMiniWebsite(errorType: string){
    let errorMiniWebsite = new miniWebsite();
    errorMiniWebsite.logoAlign = 'center';
    switch (errorType) {
      case 'UNPUBLISHED_BOOKING_SITE':
        errorMiniWebsite.pageTitle = 'Booking\'s Currently Disabled';
        break;
      case 'HANDLE_NOT_FOUND':
        errorMiniWebsite.pageTitle = 'Booking Page Not Found';
        break;
      default:
        errorMiniWebsite.pageTitle = 'Error Loading Scheduling Page';
    }

    this.styleSheetService.createStyleSheet(errorMiniWebsite, undefined);
    return errorMiniWebsite;
  }
}
