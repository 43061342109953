import {PipeTransform, Pipe} from '@angular/core';
import {DatePipe} from '@angular/common';
import * as _ from 'lodash';

@Pipe({ name: 'militaryTime'})

export class MilitaryTimeConversion implements PipeTransform {
    constructor(private datePipe: DatePipe) {}
    transform(time: number, showTimes24HourFormat: boolean) {
        if (time || time === 0) {
            let returnString = time.toString();
            let hour: number;
            let minutes: number;
            if (returnString.length === 3) {
                hour = Number(returnString.substring(0, 1));
                minutes = Number(returnString.substring(1, 3));
            } else if (returnString.length === 1) {
                hour = 0;
                minutes = Number('0' + returnString);
            } else if (returnString.length === 2) {
                // if it's 2, then it's going to be 12 am
                hour = 0;
                minutes = Number(returnString);
            } else {
                hour = Number(returnString.substring(0, 2));
                minutes = Number(returnString.substring(2, 4));
            }
            if (isNaN(hour) || isNaN(minutes))
                return returnString;

            const fullDate = new Date();
            const newDate = new Date(fullDate.getFullYear(), fullDate.getMonth(), fullDate.getDay(), hour, minutes);
            if (showTimes24HourFormat)
                returnString = this.datePipe.transform(newDate, 'HH:mm');
            else
                returnString = this.datePipe.transform(newDate, 'hh:mm a');

            return returnString;
        }
    }
}
