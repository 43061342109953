import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WaitlistComponent} from './waitlist.component';
import {RemoveWaitlistComponent} from './remove-waitlist/remove-waitlist.component';
import {ClaimWaitlistComponent} from './confirm-waitlist/claim-waitlist.component';
import {ClientWaitlistTimesComponent} from './client-waitlist-times/client-waitlist-times.component';
import {ClientWaitlistTimesTableComponent} from './client-waitlist-times/client-waitlist-times-table.component';
import {MaterialModule} from '../material.module';
import {ClientAccountHeaderModule} from '../client-account/client-account-header/client-account-header.module';
import {WaitListRoutingModule} from './wait-list-routing.module';
import {PipesModule} from '../../pipes/pipes.module';
import {ConfirmationModule} from '../scheduler/confirmation/confirmation.module';
import { JoinWaitListComponent } from './join-wait-list/join-wait-list.component';
import {TitleBarModule} from '../scheduler/common-components/title-bar/title-bar.module';
import {BookingComponentsModule} from '../booking-components/booking-components.module';
import {UIElementsModule} from '../ui-elements/ui-elements.module';
import { WaitListRegistrationDetailTableComponent } from './wait-list-registration-detail-table/wait-list-registration-detail-table.component';
import {ClientInfoModule} from '../scheduler/client-info/client-info.module';
import {TimezoneModule} from '../scheduler/common-components/timezone/timezone.module';



@NgModule({
  declarations: [WaitlistComponent, RemoveWaitlistComponent, ClaimWaitlistComponent, ClientWaitlistTimesComponent, ClientWaitlistTimesTableComponent, JoinWaitListComponent, WaitListRegistrationDetailTableComponent],
    imports: [
        CommonModule,
        MaterialModule,
        ClientAccountHeaderModule,
        WaitListRoutingModule,
        PipesModule,
        ConfirmationModule,
        TitleBarModule,
        BookingComponentsModule,
        UIElementsModule,
        ClientInfoModule,
        TimezoneModule
    ],
  exports: [WaitlistComponent, RemoveWaitlistComponent, ClaimWaitlistComponent, ClientWaitlistTimesComponent, ClientWaitlistTimesTableComponent]
})
export class WaitListModule { }
