import {Injectable, Injector} from '@angular/core';
import {BookingSelectionObjectTypes, BookingSelectionOption} from '../../models/BookingSelectionOption.model';
import {BusinessService} from '../business.service';
import {GroupTypes} from '../../models/Group.model';
import {CSFilterParameterBean} from '../common-classes/app-objects.model';
import {SchedulerPreferenceService} from '../scheduler-preference.service';
import {SchedulerLinkService} from '../scheduler-link.service';
import {ConfigListService} from '../config-list.service';
import * as _ from 'lodash';
import {schedulerPanel, SchedulerPanelValues} from '../../models/SchedulerPanel.model';
import {CustomField} from '../../models/CustomField.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientInfoService} from './client-info/client-info.service';
import {SelectedDateTimeObj} from '../../models/SelectedDateTimeObj.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  businessService: BusinessService;
  schedulerLinkService: SchedulerLinkService;
  configListService: ConfigListService;
  schedulerPreferenceService: SchedulerPreferenceService;
  clientInfoService: ClientInfoService;
  allPanels: any = {
    welcomePanel: new schedulerPanel(SchedulerPanelValues.welcome, null, true, 0, true, 'welcomeTitle', 'welcomeText', 'welcomePnlErrorLoading', false),
    locationSuperGroupPanel: new schedulerPanel(SchedulerPanelValues.locationSuperGroups, null, true, 1, false, 'locationSuperGroupTitle', 'locationSuperGroupText','locationSuperGroupPnlErrorLoading', true, ['groupIdList','locationSuperGroupIdList'], 'groupId', 'locationSuperGroupPnlNoObjectsMsg'),
    locationGroupPanel: new schedulerPanel(SchedulerPanelValues.locationGroups, null, true, 2, false, 'locationGroupTitle', 'locationGroupText','locationGroupPnlErrorLoading', true, ['groupIdList','locationGroupIdList'], 'groupId', 'locationGroupPnlNoObjectsMsg'),
    locationPanel: new schedulerPanel(SchedulerPanelValues.locations, null, true, 3, false, 'locationTitle', 'locationText','locationPnlErrorLoading', true, ['locationIdList'], 'locationId', 'locationPnlNoObjectsMsg'),
    staffPanel: new schedulerPanel(SchedulerPanelValues.staff, null, true, 4, false, 'staffTitle', 'staffText', 'staffPnlErrorLoading', true, ['staffIdList'], 'professionalId', 'staffPnlNoObjectsMsg'),
    reasonSuperGroupPanel: new schedulerPanel(SchedulerPanelValues.reasonSuperGroups, null, true, 5, false, 'serviceSuperGroupTitle', 'serviceSuperGroupText','serviceSuperGroupPnlErrorLoading', true, ['groupIdList','reasonSuperGroupIdList'], 'groupId', 'serviceSuperGroupPnlNoObjectsMsg'),
    reasonGroupPanel: new schedulerPanel(SchedulerPanelValues.reasonGroups, null, true, 6, false, 'serviceGroupTitle', 'serviceGroupText', 'serviceGroupPnlErrorLoading', true, ['groupIdList','reasonGroupIdList'], 'groupId', 'serviceGroupPnlNoObjectsMsg'),
    reasonPanel: new schedulerPanel(SchedulerPanelValues.reasons, null, true, 7, false, 'serviceTitle', 'serviceText','servicePnlErrorLoading', true, ['reasonIdList'], 'reasonId', 'servicePnlNoObjectsMsg'),
    appointmentIntentPanel: new schedulerPanel(SchedulerPanelValues.appointmentIntent, null, true, 8, true, 'appointmentIntentTitle', 'appointmentIntentText','appointmentIntentPnlErrorLoading', true, ['appointmentIntentIdList'], 'appointmentIntentId', 'appointmentIntentPnlNoObjectsMsg'),
    timePanel: new schedulerPanel(SchedulerPanelValues.time, null, true, 9, false, 'timeTitle', 'timeText', 'timePnlErrorLoading', true),
    clientInfoPanel: new schedulerPanel(SchedulerPanelValues.clientInfo, null, true, 10, false, 'infoTitle', 'infoText','infoPnlErrorLoading', true, undefined, undefined, undefined, undefined, 'your_info'),
    finalConfirmationPanel: new schedulerPanel(SchedulerPanelValues.finalConfirmation, null, false, 11, true, 'thanksTitle', 'thanksInfo','thanksPnlErrorLoading', false, undefined, undefined, undefined, undefined, 'thanks')
  };
  constructor(private injector: Injector) {
    this.businessService = injector.get(BusinessService);
    this.schedulerLinkService = injector.get(SchedulerLinkService);
    this.configListService = injector.get(ConfigListService);
    this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
    this.clientInfoService = injector.get(ClientInfoService);
  }

  getAllPanels() {
    return _.cloneDeep(this.allPanels);
  }

  newCSFilterObj(): CSFilterParameterBean{
    let csFilterObj = new CSFilterParameterBean().createInitial(sessionStorage.getItem('auditReferralId'),false, sessionStorage.getItem('locale'), this.businessService.business.businessId, this.schedulerLinkService.schedulerLink.schedulerLinkId, sessionStorage.getItem('timezone'))
    return csFilterObj;
  }

  getPanelOrder(appMode: number, includeReasonGroups: boolean, includeReasonSuperGroups: boolean): string[] {
    let panelsToShow: string[] = [];
    switch(appMode){
      case 1://location > staff > reason
        panelsToShow = ['locations', 'staff', 'reasons'];
        break;
      case 2:
        panelsToShow = ['locations', 'reasons', 'staff'];
        break;
      case 3:
        panelsToShow = ['staff', 'locations', 'reasons'];
        break;
      case 4:
        panelsToShow = ['staff', 'reasons', 'locations'];
        break;
      case 5:
        panelsToShow = ['reasons', 'staff', 'locations'];
        break;
      case 6:
        panelsToShow = ['reasons', 'locations', 'staff'];
        break;
      case 8:
        panelsToShow = ['locations', 'reasons'];
        break;
      case 10:
        panelsToShow = ['staff', 'reasons'];
        break;
      case 11:
        panelsToShow = ['reasons', 'staff'];
        break;
      case 12:
        panelsToShow = ['reasons', 'locations'];
        break;
      case 15:
        panelsToShow = ['reasons'];
        break;
      case 17:
        panelsToShow = ['locationGroups', 'locations', 'staff', 'reasons'];
        break;
      case 18:
        panelsToShow = ['locationGroups', 'staff', 'reasons'];
        break;
      case 19:
        panelsToShow = ['locationGroups', 'locations', 'reasons'];
        break;
      case 20:
        panelsToShow = ['locationGroups', 'reasons', 'staff'];
        break;
      case 21:
        panelsToShow = ['staff', 'locationGroups', 'locations', 'reasons'];
        break;
      case 22:
        panelsToShow = ['staff', 'locationGroups', 'reasons'];
        break;
      case 23:
        panelsToShow = ['staff', 'reasons', 'locationGroups', 'locations'];
        break;
      case 24:
        panelsToShow = ['staff', 'reasons', 'locationGroups'];
        break;
      case 25:
        panelsToShow = ['reasons', 'staff', 'locationGroups', 'locations'];
        break;
      case 26:
        panelsToShow = ['reasons', 'staff', 'locationGroups'];
        break;
      case 27:
        panelsToShow = ['reasons', 'locationGroups', 'locations', 'staff'];
        break;
      case 28:
        panelsToShow = ['reasons', 'locationGroups', 'staff'];
        break;
      case 29:
        panelsToShow = ['locationGroups', 'locations', 'reasons'];
        break;
      case 30:
        panelsToShow = ['locationGroups', 'reasons'];
        break;
      case 31:
        panelsToShow = ['reasons', 'locationGroups', 'locations'];
        break;
      case 32:
        panelsToShow = ['reasons', 'locationGroups'];
        break;
      case 33:
        panelsToShow = ['staff', 'reasons', 'locationSuperGroups', 'locationGroups', 'locations'];
        break;
      case 34:
        panelsToShow = ['staff', 'reasons', 'locationSuperGroups', 'locationGroups'];
        break;
      case 35:
        panelsToShow = ['reasons', 'staff', 'locationSuperGroups', 'locationGroups', 'locations'];
        break;
      case 36:
        panelsToShow = ['reasons', 'staff', 'locationSuperGroups', 'locationGroups'];
        break;
      case 37:
        panelsToShow = ['reasons', 'locationSuperGroups', 'locationGroups', 'locations', 'staff'];
        break;
      case 38:
        panelsToShow = ['reasons', 'locationSuperGroups', 'locationGroups', 'staff'];
        break;
      case 39:
        panelsToShow = ['locationSuperGroups', 'locationGroups', 'locations', 'reasons'];
        break;
      case 40:
        panelsToShow = ['locationSuperGroups', 'locationGroups', 'reasons'];
        break;
      case 41:
        panelsToShow = ['reasons', 'locationSuperGroups', 'locationGroups', 'locations'];
        break;
      case 42:
        panelsToShow = ['reasons', 'locationSuperGroups', 'locationGroups'];
        break;
    }
    if(includeReasonGroups){
      for(let i = 0; i < panelsToShow.length; i++){
        if(panelsToShow[i] === 'reasons'){
          panelsToShow.splice(i,0, 'reasonGroups');
          break;
        }
      }
    }
    if(includeReasonSuperGroups){
      for(let i = 0; i < panelsToShow.length; i++){
        if(panelsToShow[i] === 'reasonGroups'){
          panelsToShow.splice(i,0, 'reasonSuperGroups');
          break;
        }
      }
    }
    return panelsToShow;
  }

  configurePanels(skipWelcome: boolean, panelsToShow: string[], showAppointmentIntentPanel: boolean=false ) {
    const panels: schedulerPanel[] = [];

    if(!skipWelcome){
      panelsToShow.unshift('welcome')
    }



    if(showAppointmentIntentPanel && this.schedulerPreferenceService.schedulerPreference.booleanMap.appointmentIntentRequired)
      panelsToShow.unshift(SchedulerPanelValues.appointmentIntent);
    else if(showAppointmentIntentPanel && !this.schedulerPreferenceService.schedulerPreference.booleanMap.appointmentIntentRequired)
      panelsToShow.push(SchedulerPanelValues.appointmentIntent);
    // add appointment intent if appropriate
    panelsToShow.push('time');
    panelsToShow.push('clientInfo');
    panelsToShow.push('finalConfirmation');
    const allPanels = this.getAllPanels();
    for(let prop in allPanels){
      if(panelsToShow.indexOf(allPanels[prop].panel) !== -1) {
        allPanels[prop].panelOrder = panelsToShow.indexOf(allPanels[prop].panel);
        allPanels[prop].displayed = true;
      } else {
        allPanels[prop].displayed = false;
      }
    }
    for(let prop in allPanels){
      if(allPanels[prop].displayed){
        panels.push(allPanels[prop]);
      }
    }
    for(let i = 0, x = panels.length; i < x; i++)
      panels[i].formGroup = this.getPanelForm(panels[i].panel, this.schedulerPreferenceService.schedulerPreference.schedulerPreferenceFieldDefnList);
    panels.sort(function(a: any,b: any) {return (a.panelOrder > b.panelOrder) ? 1 : ((b.panelOrder > a.panelOrder) ? -1 : 0);} );
    return panels;
  }

  createLocationBookingOption(){
    const bookingSelectionOption: BookingSelectionOption = new BookingSelectionOption();
    bookingSelectionOption.label = this.configListService.configList.locationLabel;
    bookingSelectionOption.objectType = BookingSelectionObjectTypes.LOCATION;
    return bookingSelectionOption;
  }

  createStaffBookingOption(){
    const bookingSelectionOption: BookingSelectionOption = new BookingSelectionOption();
    bookingSelectionOption.label = this.configListService.configList.staffLabel;
    bookingSelectionOption.objectType = BookingSelectionObjectTypes.STAFF;
    return bookingSelectionOption;
  }

  createReasonBookingOption(){
    const bookingSelectionOption: BookingSelectionOption = new BookingSelectionOption();
    bookingSelectionOption.label = this.configListService.configList.reasonLabel;
    bookingSelectionOption.objectType = BookingSelectionObjectTypes.REASON;
    return bookingSelectionOption;
  }

  createGroupBookingOption(groupType: GroupTypes){
    const bookingSelectionOption: BookingSelectionOption = new BookingSelectionOption();
    switch (groupType) {
      case GroupTypes.LOCATION_GROUP:
        bookingSelectionOption.label = this.configListService.configList.locationSuperGroupLabel;
        break;
      case GroupTypes.LOCATION:
        bookingSelectionOption.label = this.configListService.configList.locationGroupLabel;
        break;
      case GroupTypes.SERVICE_GROUP:
        bookingSelectionOption.label = this.schedulerPreferenceService.schedulerPreference.labelMap.serviceSuperGroupTitle;
        break;
      case GroupTypes.SERVICE:
        bookingSelectionOption.label = this.configListService.configList.reasonGroupLabel;
        break;
    }
    bookingSelectionOption.objectType = BookingSelectionObjectTypes.GROUP;
    bookingSelectionOption.groupType = groupType;
    return bookingSelectionOption;
  }

  getValuesFromForm = function(formValueObject: any){
    let values = [];
    for(let prop in formValueObject){
      let valueObj = {'field': prop, 'value': formValueObject[prop]};
      values.push(valueObj);
    }
    return values;
  }

  sortTimeList(timeList: SelectedDateTimeObj[]){
    return timeList.sort((a,b) => (a.cartDisplayStartDateTime < b.cartDisplayStartDateTime) ? -1 : 1);
  }

  arrangeInputValues(inputValues: any, formValueObject: any, indexNum: number, panels: schedulerPanel[], CSFilterObj: CSFilterParameterBean){
    if(panels[indexNum].panel === SchedulerPanelValues.time){//	TT-6340 display times in ascending order
      let timeList = [];
      let timeProps = [];
      for(let prop in formValueObject){
        timeList.push(formValueObject[prop]);
        timeProps.push(prop);
      }
      timeList = this.sortTimeList(timeList);
      let tempFormValueValueObj = {};
      for(var i = 0, x = timeProps.length; i < x; i++){
        tempFormValueValueObj[timeProps[i]] = timeList[i];
      }
      formValueObject = tempFormValueValueObj;
    }
    let values = this.getValuesFromForm(formValueObject);
    let objectInputValue = {'panel': panels[indexNum].panel, 'values': values};


    let objectInInputValuesArray: boolean = false;
    for(let j = 0, y = inputValues.length; j < y; j++){
      if(inputValues[j].panel === panels[indexNum].panel){
        inputValues[j].values = objectInputValue.values;
        objectInInputValuesArray = true;
      }
    }
    if(!objectInInputValuesArray){
      inputValues.push(objectInputValue);
    }
    if(panels[indexNum].csFilterObjProps !== undefined){
      let CSFilterObjPropValues = [];
      for(let j = 0, y = objectInputValue.values.length; j < y; j++){
        if(objectInputValue.values[j].value[panels[indexNum].idPropName] !== null){
          CSFilterObjPropValues.push(objectInputValue.values[j].value[panels[indexNum].idPropName]);
        }

      }
      if(CSFilterObjPropValues.length > 0) {
        for (let j = 0, y = panels[indexNum].csFilterObjProps.length; j < y; j++) {
          const valuesCopy = [...CSFilterObjPropValues];
          if (CSFilterObj[panels[indexNum].csFilterObjProps[j]] === null) {
            CSFilterObj[panels[indexNum].csFilterObjProps[j]] = valuesCopy;
          } else {
            for (let k = 0, z = valuesCopy.length; k < z; k++) {
              CSFilterObj[panels[indexNum].csFilterObjProps[j]].push(valuesCopy[k]);
            }
          }

        }
      }
    }
    return inputValues;
  }

  getTimeSlotFormGroup(){
    let startTime = new FormControl(null, Validators.required);
    let clientStartTime = new FormControl(null, Validators.required);
    let clientStartTimeForDisplay = new FormControl(null, Validators.required);
    let startTimeDate = new FormControl(null, Validators.required);
    let endTime = new FormControl(null, Validators.required);
    let clientEndTime = new FormControl(null, Validators.required);
    let clientEndTimeForDisplay = new FormControl(null, Validators.required);
    let endTimeDate = new FormControl(null, Validators.required);
    let startDate = new FormControl(null, Validators.required);
    let endDate = new FormControl(null, Validators.required);
    let classScheduleId = new FormControl(null);
    let recurringScheduleId = new FormControl(null);
    let hideGuestOption = new FormControl(null);
    let cartDisplayStartDateTime = new FormControl(null);
    let cartDisplayEndDateTime = new FormControl(null);
    let maxCapacity = new FormControl(null);
    let currentCapacity = new FormControl(null);
    let reasonId = new FormControl(null);
    let professionalId = new FormControl(null);
    let locationId = new FormControl(null);
    let timeSlotId = new FormControl(null);
    let clientTimeSlotId = new FormControl(null);
    let htmlId = new FormControl(null);
    let calTimeSlot = new FormControl(null);
    let waitListMode = new FormControl(null);
    let isWaitListFirstAvail = new FormControl(null);
    let startDateTimeUTC = new FormControl(null);
    let endDateTimeUTC = new FormControl(null);
    return new FormGroup({
      'startTime': startTime,
      'clientStartTime': clientStartTime,
      'clientStartTimeForDisplay': clientStartTimeForDisplay,
      'clientStartTimeDate': startTimeDate,
      'endTime': endTime,
      'clientEndTime': clientEndTime,
      'clientEndTimeForDisplay': clientEndTimeForDisplay,
      'clientEndTimeDate': endTimeDate,
      'clientStartDate': startDate,
      'clientEndDate': endDate,
      'classScheduleId': classScheduleId,
      'recurringScheduleId': recurringScheduleId,
      'hideGuestOption': hideGuestOption,
      'cartDisplayStartDateTime': cartDisplayStartDateTime,
      'cartDisplayEndDateTime': cartDisplayEndDateTime,
      'maxCapacity': maxCapacity,
      'currentCapacity': currentCapacity,
      'reasonId': reasonId,
      'professionalId': professionalId,
      'locationId': locationId,
      'timeSlotId': timeSlotId,
      'clientTimeSlotId': clientTimeSlotId,
      'htmlId': htmlId,
      'calTimeSlot': calTimeSlot,
      'waitListMode': waitListMode,
      'isWaitListFirstAvail': isWaitListFirstAvail,
      'startDateTimeUTC': startDateTimeUTC,
      'endDateTimeUTC': endDateTimeUTC
    })

  }

  getPanelForm(panel: SchedulerPanelValues, fieldDefnList: CustomField[], reasonId?: number, reasonType?: string){
    let form: FormGroup;
    switch(panel){
      case SchedulerPanelValues.welcome:
        form = new FormGroup({
          'allowedToBook': new FormControl(null, Validators.requiredTrue)
        });
        break;
      case SchedulerPanelValues.locationSuperGroups:
        form =  new FormGroup({'group': new FormControl(null, Validators.required)});
        break;
      case SchedulerPanelValues.locationGroups:
        form =  new FormGroup({'group': new FormControl(null, Validators.required)});
        break;
      case SchedulerPanelValues.locations:
        form =  new FormGroup({'location': new FormControl(null, Validators.required)});
        break;
      case SchedulerPanelValues.staff:
        form =  new FormGroup({'staff': new FormControl(null, Validators.required)});
        break;
      case SchedulerPanelValues.reasonSuperGroups:
        form =  new FormGroup({'group': new FormControl(null, Validators.required)});
        break;
      case SchedulerPanelValues.reasonGroups:
        form =  new FormGroup({'group': new FormControl(null, Validators.required)});
        break;
      case SchedulerPanelValues.reasons:
        form =  new FormGroup({'reason': new FormControl(null, Validators.required)});
        break;
      case SchedulerPanelValues.appointmentIntent:
        form =  new FormGroup({'appointmentIntent': new FormControl(null, Validators.required)});
        break;
      case SchedulerPanelValues.time:
        form =  new FormGroup({
          'selectedDateTime': this.getTimeSlotFormGroup()});
        break;
      case SchedulerPanelValues.clientInfo:
        let controlsObject = {};

        let phoneInvalid = false;
        let textMessageReminder = null;
        for(let i = 0; i < fieldDefnList.length; i++){
          if (fieldDefnList[i].code == 'sendTextReminder') {
            textMessageReminder = fieldDefnList[i];
          }
          // LHB 07/13/2020 TT-6789
          if(fieldDefnList[i].visible && fieldDefnList[i].visibleOnScheduler && reasonType && reasonType === 'CLASS' && fieldDefnList[i].code === 'additionalGuests'){
            fieldDefnList[i].showOnScheduler = true;
            fieldDefnList[i].enable = true;
          }
          if(fieldDefnList[i].visible && fieldDefnList[i].visibleOnScheduler && reasonId &&
              fieldDefnList[i].serviceIds && fieldDefnList[i].serviceIds.length > 0){//Fix for TT-6153
            // For TT-6661 06/22/2020 added in check with passing reasonId=-1 to only show reason specific custom fields assigned to CLIENT if they have a value
            if((reasonId === -1 && fieldDefnList[i].value) || fieldDefnList[i].serviceIds.indexOf(reasonId) !== -1){
              fieldDefnList[i].showOnScheduler = true;
              fieldDefnList[i].enable = true;
            } else {
              fieldDefnList[i].showOnScheduler = false;
              fieldDefnList[i].enable = false;
              if (fieldDefnList[i].code == 'cellPhone') {
                phoneInvalid = true;
              }
            }
          } else if(!reasonId && fieldDefnList[i].visible && fieldDefnList[i].visibleOnScheduler && fieldDefnList[i].serviceIds && fieldDefnList[i].serviceIds.length > 0){
            fieldDefnList[i].showOnScheduler = false;
            fieldDefnList[i].enable = false;
          }
          if((fieldDefnList[i].showOnScheduler && !reasonId) ||
              (fieldDefnList[i].showOnScheduler && reasonId &&
                  (!fieldDefnList[i].serviceIds || fieldDefnList[i].serviceIds.length === 0) ||
                  (fieldDefnList[i].serviceIds && fieldDefnList[i].serviceIds.length > 0 && fieldDefnList[i].serviceIds.indexOf(reasonId) !== -1)) ||
              (reasonId === -1 && fieldDefnList[i].value && fieldDefnList[i].serviceIds && fieldDefnList[i].serviceIds.length > 0)) {
            let validatorsArray = this.clientInfoService.createValidatorsArray(fieldDefnList[i]);
            controlsObject[fieldDefnList[i].schedulerPreferenceFieldDefnId] = new FormControl({
              value: fieldDefnList[i].value,
              disabled: !fieldDefnList[i].enable
            }, validatorsArray);

          }
        }
        if (phoneInvalid) {
          if (textMessageReminder) {
            textMessageReminder.showOnScheduler = false;
          }
        }
        form =  new FormGroup(controlsObject);
        for(let i = 0, x = fieldDefnList.length; i < x; i++){ // 04/27/2020 Fix for TT-6525 when field is specific to service but condition has it hidden by default
          this.clientInfoService.checkDependencyFields(fieldDefnList[i], fieldDefnList, form, reasonId);
        }
        break;
      case SchedulerPanelValues.finalConfirmation:
        form = new FormGroup({'finalConfirmationMsg': new FormControl(null)})
        break;
    }
    return form;
  }
}
