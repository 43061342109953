import {Component, Input, OnInit} from '@angular/core';
import {Client, ClientAccount, miniWebsite, preference, schedulerLink} from "../../../common-classes/app-objects.model";
import {TableColumnDataModel} from "../../../common-classes/TableColumnData.model";
import {MatTableDataSource} from "@angular/material/table";
import {CreditCard} from "../../view-invoices/payment-portals/payments.model";
import {MatSort} from "@angular/material/sort";
import {DataTablesService} from "../../../data-tables.service";
import {ClientFormListObj} from "../../../disclaimer-form/disclaimer-form.model";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'client-forms',
  templateUrl: './client-forms.component.html',
  styleUrls: ['../../../data-tables.component.css']
})
export class ClientFormsComponent implements OnInit {
  @Input() preference: preference;
  @Input() selectedClient: Client;
  displayedColumns: string[] = this.dataTablesService.getDefaultDisclaimerFormColumns();
  columnDefs: TableColumnDataModel[] = this.dataTablesService.getDefaultDisclaimerFormColumnDefs();
  disclaimerFormList = new MatTableDataSource<ClientFormListObj>();
  disclaimerForms: ClientFormListObj[] = [];
  private sort: MatSort;

  constructor(private dataTablesService: DataTablesService, private router: Router, private route: ActivatedRoute) { }

  goToForm(clientFormListObj: ClientFormListObj){
    this.router.navigate(['forms/' + clientFormListObj.disclaimerFormUuid], {relativeTo: this.route.root, state: {onMyProfile: true}});
  }

  establishTable(){
    this.disclaimerFormList.data = this.disclaimerForms;
    this.disclaimerFormList.sortingDataAccessor = (obj, property) => this.dataTablesService.getProperty(obj, property);
    this.disclaimerFormList.sort = this.sort;
  }


  ngOnInit() {
    this.disclaimerForms = this.selectedClient.formList;
    this.establishTable();
  }

}
