import {LabelMap} from "./default-labels.model";
import {MiniWebsiteDisplayConfig} from "./default-styles.model";
import {PackageSold} from "../store/packages/packages.model";
import {StoredCardInfo} from "../client-account/client-profile/client-profile-container/client-profile-container.component";
import {SquareCard} from "../client-account/view-invoices/payment-portals/square/square.component";
import {MonthAvailability, TimeZoneCode} from "../scheduler/time/time-objects.model";
import {ClientFormListObj} from "../disclaimer-form/disclaimer-form.model";
import {SchedulerLinkBooleanMapModel} from '../../models/SchedulerLinkBooleanMap.model';
import {Business} from '../../models/Business.model';
import {SchedulerBooleanMapModel} from '../../models/SchedulerBooleanMap.model';
import {SchedulerLinkNumberMapModel} from '../../models/SchedulerLinkNumberMap.model';
import {EmailCategory} from '../../models/EmailCategory.model';
import {EmailTemplate} from '../../models/EmailTemplate.model';
import {Group} from '../../models/Group.model';
import {WaitList} from '../../models/WaitList.model';
import {Appointment} from '../../models/Appointment.model';
import {Reason} from '../../models/Reason.model';
import {ClassSession} from '../../models/ClassSession.model';
import {CustomField, FieldData} from '../../models/CustomField.model';
import {Location} from '../../models/Location.model';
import {Staff} from '../../models/Staff.model';
import {schedulerPanel} from '../../models/SchedulerPanel.model';
import {SelectedDateTimeObj} from '../../models/SelectedDateTimeObj.model';
import {StripeCard, StripeCardData} from '../../models/Stripe/Stripe.model';

export class ConfigListMap {
    constructor(
        public courseLabelPlural: string = "courses",
        public locationAddressLabel: string = "address",
        public classLabel: string = "class",
        public reasonGroupLabelPlural: string = "reason groups",
        public supplierLineLabelPlural: string = "supplier lines",
        public resourceLabel: string = "resource",
        public staffLabel: string = "staff",
        public serviceLabelPlural: string = "services",
        public staffLabelPlural: string = "staff",
        public supplierLabelPlural: string = "suppliers",
        public locationLabelPlural: string = "locations",
        public supplierLineLabel: string = "supplier line",
        public additionalStaffLabel: string = "additional staff",
        public clientLabelPlural: string = "clients",
        public reasonLabel: string = "service & class",
        public reasonLabelPlural: string = "services & classes",
        public serviceLabel: string = "service",
        public courseLabel: string = "course",
        public locationGroupLabelPlural: string = "location groups",
        public locationLabel: string = "location",
        public locationSuperGroupLabel: string = "location super group",
        public locationSuperGroupLabelPlural: string = "location super groups",
        public resourceLabelPlural: string = "resources",
        public coordinatorStaffLabel: string = "coordinator staff",
        public clientLabel: string = "client",
        public supplierLabel: string = "supplier",
        public staffProfileSkypeIdLabel: string = "Skype ID",
        public classLabelPlural: string = "classes",
        public reasonGroupLabel: string = "reason group",
        public locationGroupLabel: string = "location group",
        public appointmentIntentLabel: string = "appointment intent",
        public appointmentIntentLabelPlural: string = "appointment intents",

    ) {
    }
}
export class landingPageObjects {
    currency: currency;
    miniWebsite: miniWebsite;
    preference: preference;
    schedulerLink: schedulerLink;
    styleUrl: string;
    backOfficeURL: string;
    requireStaffConfirmation: boolean;
    requirePayment: boolean;
    requirePreAuthPayment: boolean;
    businessConfigTest: boolean;
    business: Business;
    configListLabels: ConfigListMap;
    defaultGateway: string;
    invitationUUId?: string;
    invitationExpired?: boolean;
    invitationCompleted?: boolean;
    emailCategories: EmailCategory[];
    emailTemplates: EmailTemplate[];
    defaultSquareLocationId;
    accepttips: boolean;
    tiprate1: number;
    tiprate2: number;
    tiprate3: number;
    appointmentIntentAllowed: boolean;

}
export interface currency {
    currencyCode: string;
    currencyId: number;
    currencyName: string;
    prefix: string;
}

export class miniWebsite {
    businessId: number;
    businesssInfoColor?: string;
    businessInfoText?: string;
    businessInfoVisible?: boolean;
    createdDate?: Date;
    createdUser?: string;
    customJs?: string;
    footerBackgroundColor?: string;
    footerLinkColor?: string;
    footerTextColor?: string;
    googleAnalyticsCode?: string;
    headerColor?: string;
    headerText?: string;
    iframeHeight?: number;
    invitationDeclineHeader?: string;
    styleSheetMap?: any;
    displayConfig?: MiniWebsiteDisplayConfig;
    schedulerLinkLandingPageStylesJson?: string;
    schedulerLinkLandingPageStylesMap?: any;
    landingPageStylesJSON?: string;
    landingPageStylesMap?: any;
    logoAlign: string;
    logoUrl?: string;
    metaDescription?: string;
    modifiedDate?: Date;
    modifiedUser?: string;
    navigationBackgroundColor?: string;
    pageTitle?: string;
    policyBackgroundColor?: string;
    policyText?: string;
    policyVisible?: boolean;
    websiteBackgroundColor?: string;
}

export class inactiveBusinessMiniWebsite {
    businessId = null;
    businessInfoVisible = false;
    pageTitle = 'TimeTap Booking: Business Inactive';
    policyVisible = false;
    logoAlign =  'center';
    logoUrl = 'https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/timetap-logo-black.png';
}

export class errorLoadingMiniWebsite {
    businessId = null;
    businessInfoVisible = false;
    pageTitle = 'TimeTap Booking: Loading Error';
    policyVisible = false;
    logoAlign =  'center';
    logoUrl = 'https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/timetap-logo-black.png';
}

export interface preference {
    acceptPaymentOnBooking: boolean;
    additionalGuestText: string;//MOVE TO LABELJSON
    addressReq: number;//NOT USED
    allowTZChange: number;
    allowTZChangeOriginal: number;//In case allowTZChange gets overwritten by location, need this to look back at
    allowAddConnectedClient: boolean;
    allowPrintConfirmation: boolean;
    animate: boolean;//NOT USED
    appMode: number;
    appointmentDetailText: string;//NOT SURE IF USE --- IF SO MOVE TO LABELJSON
    appointmentFieldList: string[];
    appointmentListStatuses: string[];
    apptDayMax: number;
    apptHrMin: number;
    askClientSMSRemind: boolean;
    autoAdvanceWhenSingle: boolean;
    autoTranslateDefaultLabels: boolean;
    bookMultipleAppts: boolean;
    booleanMap: SchedulerBooleanMapModel;
    businessId: number;
    calendarEngine: number;//DETERMINES TRAVEL TIME CALCULATION
    cancelHrs: number;
    cancelReasonOptional?: boolean;
    classCalendar: true;
    classDayMax: number;
    classHrMin: number;
    classMaxSeat: number;//NOT SURE IF USED
    classMinSeat: number;//NOT SURE IF USED
    clientBlacklistError: string;//MOVE TO LABELJSON
    clientBlacklistMode: string;
    clientCancelTimeframeError: string;//NOT SURE IF USED --- IF SO MOVE TO LABELJSON
    clientReminderSMSHoursList: number[];
    commentsReq: number;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    companyReq: number;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    completedApptBtnLabel: string;//MOVE TO LABELJSON
    completedApptTitle: string;//MOVE TO LABELJSON
    continueShoppingUrl?: string;
    countyReq: number;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    couponReq: number;
    createdDate: Date;
    createdUser: string;
    currency?: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN CURRENCY OBJECT
    debug: boolean;
    defaultScheduler: boolean;
    emailReq: number;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    expiredInvitationText?: string;
    filterButton: string;//MOVE TO LABELJSON
    filterHint?: string;//MOVE TO LABELJSON
    filterLabel: string;//MOVE TO LABELJSON
    filterMode: number;
    firstDayOfWeek: number;//NOT SURE IF USED OR IF WE ARE USING LOCALE
    fullNameShow: boolean;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    height: number;//NOT SURE IF USED
    hidePrice: boolean;
    hintMobile: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    hintPhone: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    infoBookingSummary?: string;//MOVE TO LABELJSON
    infoBookingSummaryWaitList?: string;//MOVE TO LABELJSON
    infoText?: string;//MOVE TO LABELJSON
    infoTitle: string;//MOVE TO LABELJSON
    labelBack: string;//MOVE TO LABELJSON
    labelBookAnother: string;//MOVE TO LABELJSON
    labelCompany: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    labelCreditCard: string;//NOT SURE IF USED
    labelEmail: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    labelFirstName: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    labelJson?: string;
    labelLastName: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    labelMap: LabelMap;
    labelName: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    labelNext: string;//MOVE TO LABELJSON
    labelNoPreference: string;//MOVE TO LABELJSON
    labelSave: string;//MOVE TO LABELJSON
    labelState?: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    labelZip?: string;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    locationGroupText?: string;//MOVE TO LABELJSON
    locationGroupTitle?: string;//MOVE TO LABELJSON
    locationSuperGroupText?: string;//MOVE TO LABELJSON
    locationSuperGroupTitle?: string;//MOVE TO LABELJSON
    locationText?: string;//MOVE TO LABELJSON
    locationTitle: string;//MOVE TO LABELJSON
    loggedInWelcomeText: string;//MOVE TO LABELJSON
    loggedinWelcomeScreen: boolean;//NOT SURE IF USED
    loginReq: number;
    logoutLabel: string;//MOVE TO LABELJSON
    lookBusy: boolean;
    maxActiveApptClient: number;
    maxAdditionalGuests: number;
    maxClasses: number;//NOT SURE IF USED
    maxClientRescheduleCount: number;
    mobileReq: number;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    modifedDate: Date;
    modifiedUser: string;
    name: string;//NOT SURE IF USED
    newCustomFields: boolean;//NOT SURE IF ANYONE IS STILL ON OLD CUSTOM FIELDS MODEL -- MAY NEED TO QUERY TO CHECK
    noAppointmentText: string;//MOVE TO LABELJSON
    noStaffError: string;//MOVE TO LABELJSON
    noshowApptBtnLabel: string;//MOVE TO LABELJSON
    noshowApptTitle: string;//MOVE TO LABELJSON
    onlyAllowBookingByInvitation: boolean;
    openApptTitle: string;//MOVE TO LABELJSON
    payByCreditCard: boolean;//NOT SURE IF WE USE THIS FLAG, THE acceptPaymentOnBooking, OR THE paymentReq FLAG ON THE BUSINESS KEYS?
    phoneReq: number;//MIGHT HAVE BEEN USED ON CHECKAPPTS ORIGINALLY BUT NOW DEFINED IN FIELDS
    poweredBy: string;//NOT SURE IF USED
    preventDupClassBooking?: boolean;
    quotaFullMsg: string;//MOVE TO LABELJSON
    recurringClass: boolean;
    repeatingApptFieldList: string[];
    rescheduleBtnLabel: string;//MOVE TO LABELJSON
    rescheduleHrs: number;
    restrictReschedToSameStaff: boolean;
    schedButtonsTopNav: boolean;
    schedulerLink: number;
    schedulerPreferenceFieldDefnList: CustomField[];
    schedulerPreferenceFieldDefnListOriginal: CustomField[]; // Added to help fix TT-6633
    schedulerPreferenceId: number;
    selectStaffSorted: boolean;//NOT SURE IF USED OR HOW USED
    serviceGroupText?: string;//MOVE TO LABELJSON
    serviceGroupTitle?: string;//MOVE TO LABELJSON
    serviceSuperGroupText?: string;//MOVE TO LABELJSON
    serviceSuperGroupTitle?: string;//MOVE TO LABELJSON
    serviceText?: string;//MOVE TO LABELJSON
    serviceTitle?: string;//MOVE TO LABELJSON
    shortDayList: any;//NOT SURE IF USED OR HOW
    shortMonthList: any;//NOT SURE IF USED OR HOW
    showAddToCalendar: boolean; // LHB 08/25/2020 TT-6930
    showAltTimeslot: number;
    showBookAnother: boolean;
    showClassOnLocationTab: boolean;
    showClassRoomOnTimePanel: boolean;
    showClientInvoices: boolean;
    showDuration: boolean;
    showLocationGroup: boolean;//NOT SURE IF USED OR IF WE ARE USING APPMODE EXCLUSIVELY
    showLocationMap: boolean;
    showOnlyStartTime: boolean;
    showOpenSlots: boolean;
    showPoweredBy: boolean;
    showServiceGroup: boolean;
    showServiceSuperGroup: boolean;
    showStaffGroup: boolean;
    staffNoPreference: number;
    timeFormat24Hrs: boolean;
    waitListFirstAvailable: boolean;
    waitlistRegistrationsFieldList: string[];
    skipWelcome?: boolean;
    emailValidationRegex: string;
    allowRecaptcha?: boolean;
}

export interface schedulerLink {
    active: boolean;
    appMode?: number;
    booleanMap: SchedulerLinkBooleanMapModel;
    businessId: number;
    classScheduleIdList?: number[];
    createdDate: Date;
    createdUser: string;
    domain: string;
    groupIdList?: number[];
    handle: string;
    internalName: string;
    labelJson?: string;
    labelMap: LabelMap;
    locationIdList?: number[];
    logoUrl?: string;
    modifiedDate?: Date;
    modifiedUser?: string;
    numberMap: SchedulerLinkNumberMapModel;
    privateUrl: string;
    publish: boolean;
    reasonIdList?: number[];
    schedulerLinkId: number;
    schedulerType: string;
    schedulerVersion: number
    showPrivateGroup: boolean;
    showPrivateLocation: boolean;
    showPrivateReason: boolean;
    skipWelcome: boolean;
    staffIdList?: number[];
    styleUrl: string;
    vanity: boolean;
    welcomeText?: string;//IN LABELMAP
    welcomeTitle?: string;//IN LABELMAP
    preference?: preference;
    allowRecaptcha?: boolean;

}

export interface movePanels {
    direction: string;
    panel?: schedulerPanel;
    appt?: Appointment;
    multipleAppts?: MultipleAppts,
    waitLists?: WaitList[],
    noAppointmentAvail?: boolean
}

export class loginRegisterPopUpObject {
    moveData: movePanels;
    index: number;
    loadMyAccount: boolean;
    description: string;
    calledFrom: string;
    callSubscriptionNext: boolean;
    email: string;
    readonly connectClientCallFrom: string = "CONNECT_CLIENT";
}

export class CSFilterParameterBean {
    auditReferralId: string;
    debug: boolean;
    locale: string;
    businessId: number;
    schedulerLinkId: number;
    staffIdList: number[] = null;
    reasonIdList: number[] = null;
    locationIdList: number[] = null;
    locationGroupIdList: number[] = null;
    reasonGroupIdList: number[] = null;
    locationSuperGroupIdList: number[] = null;
    reasonSuperGroupIdList: number[] = null;
    classScheduleIdList: number[] = null;
    groupIdList: number[] = null;
    appointmentIntentIdList: number[] = null;
    clientTimeZone: string;
    clientTimeZoneId?: number;
    filterStaff?: string;
    filterLocation?: string;
    filterMode?: number;//LOCATION MODE = 1; STAFF MODE = 2
    coupon?: Coupon;
    address?: string;
    businessTimeZone?: string;
    businessTimeZoneId?: number;
    rescheduling?: boolean;
    appointmentIntentId? : number;
    reasonRequiresIntent? : boolean;
    constructor(){}
    createInitial(auditReferralId: string, debug: boolean, locale: string, businessId: number, schedulerLinkId: number, clientTimeZone: string) {
        this.auditReferralId = auditReferralId;
        this.debug = false;
        this.locale = locale;
        this.businessId = businessId;
        this.schedulerLinkId = schedulerLinkId;
        this.clientTimeZone = clientTimeZone;
        return this;
    }
}

export class CouponAPIReturnData {
    constructor(
        public coupon: Coupon,
        public reasonAPIBeanList: Reason[],
        public status: string,
        public statusDetail: string
    ){}
}

export class Coupon {
    constructor(
        public active: boolean,
        public businessId: number,
        public couponCode: string,
        public couponId: number,
        public couponName: string,
        public couponType: string,
        public createdDate: Date,
        public createdUser: string,
        public amount?: number,
        public description?: string,
        public endDate?: Date,
        public maxUseCount?: number,
        public modifiedDate?: Date,
        public modifiedUser?: string,
        public percentage?: number,
        public reasonId?: number,
        public reasonIdList?: number[],
        public appliedReasonCount?: number
    ){}
}

export class Client {
    viewClientAccountIds: number[];
    storedCardInfo: StoredCardInfo;
    packageSoldList: PackageSold[];
    stripeCreditCards: StripeCardData[];
    squareCreditCards: SquareCard[];
    showPaymentInfo: boolean;
    businessId: number;
    hasCardOnFile: boolean;
    formList: ClientFormListObj[];
    clientHash: string;
    excludeEmailCategoryIdList: number[];
    excludeEmailTemplateIdList: number[];
    externalUserName: string;
    constructor(
        public clientId: number,
        public clientAccountId: number,
        public password: string,
        public fields: CustomField[],
        public locale: string,
        public status: string,
        public createdUser: string,
        public createdDate: number,
        public timeZoneCode: TimeZoneCode,
        public deleted: boolean,
        public fullName?: string,
        public emailAddress?: string,
        public activeApptCount?: number,
        public fieldDataList?: FieldData[],
        public allowWaitListText?: boolean
    ){}
}

export class ClientAccount {
    constructor(
        public emailAddress: string,
        public password: string,
        public active?: boolean,
        public businessId?: number,
        public clientAccountId?: number,
        public clientList?: Client[],
        public createdDate?: Date,
        public createdUser?: string,
        public linkedClientAccountId?: number,
        public modifiedDate?: Date,
        public modifiedUser?: string,
    ){}
}

export class ExistingSessionObject {
    constructor(
        public businessId: number,
        public clientAccount: ClientAccount,
        public handle: string,
        public locale: string,
        public privateUrlHash: string,
        public passwordLogin: boolean,
        public parmInvitationUUId: string,
        public invitationExpired: boolean,
        public invitationCompleted: boolean,
        public parmInvoiceHash: string,
        public parmCoupon: string,
        public parmDisclaimerFormUUID: string,
        public sessionToken: string,
        public parmAuditReferralId: string,
        public customFieldMap: {[key: string]: string}
    ){}
}

export class Resource {
    constructor(
        public resourceId: number,
        public resourceName: string
    ){}
}

export class RecurringAppointment {
    status: string;
    everyXWeeks: number;
    everyXMonths: number;
    sun: boolean;
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    startTime: number;
    endTime: number;
    weeksOrMonths: string;
    frequency: number;
    cancelReason: string;
    public clientStartTimeDate?: Date;
    public clientEndTimeDate?: Date;
    public staffStartTimeDate?: Date;
    public staffEndTimeDate?: Date;
    public hashId?: string
    constructor(
        public recurringAppointmentId: number,
        public businessId: number,
        public location: Location,
        public staff: Staff,
        public client: Client,
        public reason: Reason,
        public adhoc: boolean,
        public fields?: CustomField[],
        public startDate?: string,
        public endDate?: string,
        public resource?: Resource,
        public remindClientSmsHrs?: number,
        public seats?: number,
        public locationSuperGroup?: Group,
        public locationGroup?: Group,
        public reasonSuperGroup?: Group,
        public reasonGroup?: Group,
        public address?: string,
        public timeSlotList?: SelectedDateTimeObj[],
        public classScheduleList?: SelectedDateTimeObj[],
        public startTimeDate?: Date,
        public endTimeDate?: Date,
        public clientStartTime?: number,
        public clientEndTime?: number,
        public recurringSchedule?: any,
        public coupon?: Coupon
    ){}
}

export class MultipleAppts {
    constructor(
        public appointments: Appointment[],
        public recurringAppointment: RecurringAppointment
    ){}
}



export class CourseSet {
    monthAvailability: MonthAvailability[];
    selectedMonthAvailability: MonthAvailability;
    minBookingDate: Date;
    maxBookingDate: Date;
    recurringClassSchedule: RecurringClassSchedule;
    classSessions: ClassSession[];
    constructor() {}
}
export class RecurringClassSchedule {
    recurringScheduleId: number;
    businessId: number;
    location: Location;
    staff: Staff;
    reason: Reason;
    resource: Resource;
    startTime: number;
    endTime: number;
    startDate: string;
    endDate: string;
    active: boolean;
    status: string;
    everyXWeeks: number;
    everyXMonths: number;
    bookable: boolean;
    sun: boolean;
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    maxCapacity: number;
    roomNumber: string;
    courseSetName: string;
    locationGroup: Group;
    locationSuperGroup: Group;
    fields: CustomField[];
    coordinatorStaff: Staff;
    allowWalkins: boolean;
    currentCapacity: number;
    endDateTimeUTC?: Date;
    startDateTimeUTC?: Date

    //displayOnly
    startDateZonedDisplay: any;
    endDateZonedDisplay: any;
    constructor() {}
    copyPropsFromAPI(repeatingClassSchedule: RecurringClassSchedule) {
        for (const prop in repeatingClassSchedule)
            this[prop] = repeatingClassSchedule[prop];
    }
}

export class MonthDay {
    constructor(
        public dayNumInMonth: number,
        public available: boolean,
        public isDayInMonth: boolean
    ){}
}

export class MonthDataObj {
    constructor(
        public monthFullName: string,
        public monthAbbreviation: string,
        public daysInMonth: number,
        public monthNumberFull: number,
        public monthNumberIndex: number,
        public currentYear: number,
        public startDayOfWeek: string,
        public startDayOfWeekNum: number,
        public startDateOfMonth: Date
    ){}
}

export class InvoiceItem {
    active: boolean;
    businessId: number;
    calendarId: number;
    clientId: number;
    coupon: any;
    createdDate: Date;
    createdUser: string;
    depositAmount: number;
    description: string;
    discountAmount: number;
    discountPercentage: number;
    giftCardUUID: string;
    grossAmount: number;
    invoiceId: number;
    invoiceItemId: number;
    invoiceItemName: string;
    modifiedDate: Date;
    modifiedUser: string;
    netAmount: number;
    packageSoldId: number;
    productId: number;
    quantity: number;
    rate: number;
    recurringAppointmentId: number;
    taxable: boolean;
    reasonId: number;
    showPackagePurchaseOption: boolean;
    couponError: string;
    constructor(businessId: number, clientId: number){
        this.businessId = businessId;
        this.clientId = clientId;
        this.active = true;
    }
}

export class Invoice {
    allowChoosePayAmount: boolean;
    balanceAmount: number;
    billingLocationId: number;
    billingStaffId: number;
    businessId: number;
    chargeDate: number;
    client: Client;
    comments: string;
    createdDate: Date;
    depositAmount: number;
    discountAmount: number;
    invoiceDate: Date;
    invoiceDueDate: Date;
    invoiceHash: string;
    invoiceId: number;
    invoiceItem: InvoiceItem[];
    invoiceNumber: string;
    invoiceTotalAmount: number;
    invoiceType: string;
    open: boolean;
    paidAmount: number;
    paidOnDate: Date;
    payBtnClickTime: Date;
    paymentAmount: number;
    preAuthPayment: boolean;
    hasBeenPreAuthed: boolean;
    status: string;
    statusText: string;
    subStatus: string;
    subtotalAmount: number;
    tax2Amount: number;
    taxAmount: number;
    tipAmount: number;
    transactionFeeAmount: number;
    writeOffAmount: number;
    paymentConfirmUrl: string;
    squareLocationId: string;
    showPayNowButton: boolean;

    constructor(businessId: number, client: Client){
        this.businessId = businessId;
        this.client = client;
        this.status = "OPEN";
    }
}

export interface PaymentMap{
    buttonimageUrl: string,
    gateway: string,
    html: string,
    map: PaymentDataMap,
    postUrl: string,
    redirectUrl: string
}

export class PaymentDataMap{
    add: string;
    amount: string;
    bn: string;
    business: string;
    cancel_return: string;
    cmd: string;
    currency_code: string;
    custom: string;
    email: string;
    invoice: string;
    item_name: string;
    last_name: string;
    notify_url: string;
    //return: string;
    shipping: string;
    tax: string;
    tax_cart: string;
    squareLocationId: string;
    constructor(

    ){}
}
