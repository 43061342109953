import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Client, currency, Invoice, preference} from "../../../../common-classes/app-objects.model";
import {BusinessService} from "../../../../business.service";
import {ClientAccountService} from "../../../client-account.service";
import {TagsService} from "../../../../tags.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ResponsiveService} from "../../../../responsive.service";
import {PaymentIntentResponse} from '../../../../../models/Stripe/PaymentIntent.model';
import {StripeService} from './stripe.service';

declare var Stripe;



@Component({
  selector: 'stripe-payment-form',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css']
})
export class StripeComponent implements OnInit, AfterViewChecked {
  @Output() onGoBack = new EventEmitter<Invoice>();
  @Input() preference: preference;
  @Input() invoice: Invoice;
  @Input() client: Client;
  @Input() currency: currency;
  @Input() path: string;
  stripeJs: string = 'https://js.stripe.com/v3/';
  // stripeJs: string = 'https://checkout.stripe.com/checkout.js';
  publishableKey: string;
  accountId: string;
  paymentFormLoaded: boolean = false;
  paymentFormHasErrors: boolean = false;
  paying: boolean = false;
  @Input() submitBtnLabel: string;
  @Input() submittingBtnLabel: string;
  stripe; // : stripe.Stripe;
  card;
  cardErrors;
  form: any;

  loading = false;
  confirmation;
  saveClientPayment: boolean = false;
  constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private tagsService: TagsService,
              private route: ActivatedRoute, private router: Router, private responsiveService: ResponsiveService, private stripeService: StripeService) {}

  setSaveClientPayment(){
    if(this.saveClientPayment)
      (<HTMLInputElement>document.getElementById('saveClientPayment')).value = "true"; //casting so .value will work
    else
      (<HTMLInputElement>document.getElementById('saveClientPayment')).value = "false"; //casting so .value will work
  }


  async handleForm(e) {
    e.preventDefault();

    const { source, error } = await this.stripe.createSource(this.card);

    if (error) {
      // Inform the customer that there was an error.
      const cardErrors = error.message;
    } else {
      // Send the token to your server.
      this.loading = true;
      // const user = await this.auth.getUser();
      // const fun = this.functions.httpsCallable('stripeCreateCharge');
      // this.confirmation = await fun({ source: source.id, uid: user.uid, amount: this.amount }).toPromise();
      this.loading = false;

    }
  }

  submitStripeForm(){
    this.form.onsubmit(undefined);
  }

  getUrlWithoutParams(){
    let newUrl = window.location.href.substring(0, window.location.href.lastIndexOf('?'));

    return newUrl;
  }

  goBackToInvoice(data: any){
    sessionStorage.setItem('updatedInvoice', data);
    let newUrl = this.getUrlWithoutParams();
    window.location.href = newUrl + '?paying=false';
  }

  createStripePaymentForm(counter: number){
    if(typeof Stripe !== "undefined" && counter < 20){
      //LOAD STRIPE PAYMENT FORM
      let stripe = Stripe(this.publishableKey, {stripeAccount: this.accountId});

      const elements = stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      let style = {
        base: {
          // Add your base input styles here. For example:
          fontSize: '16px',
          color: "#32325d",
        }
      };

      // Create an instance of the card Element.
      let card = elements.create('card', {style: style});

      // Add an instance of the card Element into the `card-element` <div>.
      card.mount('#card-element');
      this.form = document.getElementById('stripeCreditCardForm');
      (<HTMLInputElement>document.getElementById('card-errors')).hidden = true;
      this.form.addEventListener('submit', function(event) {
        event.preventDefault();
        (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = true;
          let newUrl = window.location.href.substring(0, window.location.href.lastIndexOf('?'));
        stripe.createPaymentMethod({
             type: 'card',
             card: card
        }).then(function(result) {
          if (result.error) {
            // Inform the customer that there was an error.
            (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
            let errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
            window.location.href = newUrl + '?paying=error';
            (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = false;
          } else {
            let path = sessionStorage.getItem("paymentFormPath");
            // Send the token to your server
            let submittingBtnLabel = sessionStorage.getItem('submittingBtnLabel');
            sessionStorage.removeItem('submittingBtnLabel');
            (<HTMLInputElement>document.getElementById('stripe-creditcard')).innerHTML = '<i class="fa fa-spinner fa-spin fa-fw"></i> ' + submittingBtnLabel;
            (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = true;
            let url: string;
            let debug = true;
            const require3dSecure = result.paymentMethod && result.paymentMethod.card && result.paymentMethod.card.three_d_secure_usage && result.paymentMethod.card.three_d_secure_usage.supported;
            let invoiceHash = null;
            let authOnly = null;
            let balanceAmount = null;
            let paymentAmount = null;
            let saveClientPayment = null;
            if(path === 'invoice'){
              window.location.href = newUrl + '?paying=true';
              invoiceHash = (<HTMLInputElement>document.getElementById('invoiceHash')).value;
              authOnly = (<HTMLInputElement>document.getElementById('authOnly')).value;
              balanceAmount = (<HTMLInputElement>document.getElementById('balanceAmount')).value;
              paymentAmount = (<HTMLInputElement>document.getElementById('paymentAmount')).value;
              saveClientPayment = (<HTMLInputElement>document.getElementById('saveClientPayment')).value;
              if(paymentAmount === undefined || paymentAmount === null){
                paymentAmount = balanceAmount;
              }

              const queryString = window.location.href;
              const urlParams = new URLSearchParams(queryString);
              console.log(urlParams.get('username'));
              let username = urlParams.get('username');

              if (require3dSecure){
                if(debug)
                  url = sessionStorage.getItem('csAPIBase') + 'cs/stripe/paymentIntent/' + invoiceHash + '?amt=' + paymentAmount + '&saveClientPayment=' + saveClientPayment + '&stripePaymentMethodId=' + result.paymentMethod.id + '&require3dSecure=true' + '&authOnly=' + authOnly + '&username=' + username;
                else
                  url = '../../../' + sessionStorage.getItem('csAPIBase') + 'cs/stripe/paymentIntent/' + invoiceHash + '?amt=' + paymentAmount + '&saveClientPayment=' + saveClientPayment + '&stripePaymentMethodId=' + result.paymentMethod.id + '&require3dSecure=true' + '&authOnly=' + authOnly + '&username=' + username;
              } else {
                if (debug)
                  url = sessionStorage.getItem('csAPIBase') + 'cs/payInvoice/' + invoiceHash + '?stripePaymentMethodId=' + result.paymentMethod.id + '&amt=' + paymentAmount + '&saveClientPayment=' + saveClientPayment + '&authOnly=' + authOnly + '&username=' + username;
                else
                  url = '../../../' + sessionStorage.getItem('csAPIBase') + 'cs/payInvoice/' + invoiceHash + '?stripePaymentMethodId=' + result.paymentMethod.id + '&amt=' + paymentAmount + '&saveClientPayment=' + saveClientPayment + '&authOnly=' + authOnly + '&username=' + username;
              }
            } else if(path === 'addCard'){
              let clientId = (<HTMLInputElement>document.getElementById('clientId')).value;
              if(debug)
                url = sessionStorage.getItem('csAPIBase') + 'cs/client/' + clientId + '/paymentInfo?stripePaymentMethodId=' + result.paymentMethod.id;
              else
                url = '../../../' + sessionStorage.getItem('csAPIBase') + 'cs/client/' + clientId + '/paymentInfo?stripePaymentMethodId=' + result.paymentMethod.id;
            }

            fetch(url, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
              }
            })
                .catch(err => {
                    (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
                    let errorElement = document.getElementById('card-errors');
                  let jsonErr = JSON.parse(err);
                  if(jsonErr && jsonErr.message)
                    errorElement.textContent = jsonErr.message;
                  else
                    errorElement.textContent = err;
                    window.location.href = newUrl + '?paying=error';
                })
                .then((response: Response) => {
                  if (!response.ok) {
                    return response.text().then(errorInfo => Promise.reject(errorInfo));
                  }
                  return response.text();
                })
                .then(data => {
                  if(path === 'invoice') {
                    if (require3dSecure) {
                      let paymentIntentData: PaymentIntentResponse = JSON.parse(data);
                      stripe.confirmCardPayment(paymentIntentData.secret, {}).then(function(result) {
                        if (result.error) {
                          // Inform the customer that there was an error.
                          (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
                          let errorElement = document.getElementById('card-errors');
                          errorElement.textContent = result.error.message;
                          window.location.href = newUrl + '?paying=error';
                          (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = false;
                        } else if (result.paymentIntent) {
                          if(debug)
                            url = sessionStorage.getItem('csAPIBase') + 'cs/stripe/chargePaymentIntent/' + invoiceHash + '?paymentIntentId=' + result.paymentIntent.id + '&authOnly=' + authOnly;
                          else
                            url = '../../../' + sessionStorage.getItem('csAPIBase') + 'cs/stripe/chargePaymentIntent/' + invoiceHash + '?paymentIntentId=' + result.paymentIntent.id + '&authOnly=' + authOnly;
                          fetch(url, {
                            method: 'POST',
                            headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                            }
                          })
                              .catch(err => {
                                (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
                                let errorElement = document.getElementById('card-errors');
                                let jsonErr = JSON.parse(err);
                                if(jsonErr && jsonErr.message)
                                  errorElement.textContent = jsonErr.message;
                                else
                                  errorElement.textContent = err;

                                window.location.href = newUrl + '?paying=error';
                              })
                              .then((response: Response) => {
                                if (!response.ok) {
                                  return response.text().then(errorInfo => Promise.reject(errorInfo));
                                }
                                return response.text();
                              })
                              .then(data => {
                                let updatedInvoice = JSON.parse(data);
                                if(updatedInvoice.paymentConfirmUrl !== null && updatedInvoice.paymentConfirmUrl.trim() !== "" && updatedInvoice.subStatus !== 'Payment Declined')
                                  window.location.href = updatedInvoice.paymentConfirmUrl;
                                else {
                                  sessionStorage.setItem('updatedInvoice', data);
                                  window.location.href = newUrl + '?paying=false';
                                }
                              })
                        }

                      })
                    } else {
                      let updatedInvoice = JSON.parse(data);
                      if(updatedInvoice.paymentConfirmUrl !== null && updatedInvoice.paymentConfirmUrl.trim() !== "" && updatedInvoice.subStatus !== 'Payment Declined')
                        window.location.href = updatedInvoice.paymentConfirmUrl;
                      else {
                        sessionStorage.setItem('updatedInvoice', data);
                        window.location.href = newUrl + '?paying=false';
                      }
                    }

                  } else if(path === 'addCard'){
                    sessionStorage.setItem('updatedCardsList', data);
                    window.location.href = window.location.href + '?cardAdded=true';
                  }
                })
                .catch(err => {
                    (<HTMLInputElement>document.getElementById('card-errors')).hidden = false;
                    let errorElement = document.getElementById('card-errors');
                    let jsonErr = JSON.parse(err);
                    if(jsonErr && jsonErr.message)
                      errorElement.textContent = jsonErr.message;
                    else
                      errorElement.textContent = err;
                  window.location.href = newUrl + '?paying=error';
                })
          }
        });
      });
      this.paymentFormLoaded = true;
      let path = sessionStorage.getItem("paymentFormPath");
      if(path === 'invoice') {
        (<HTMLInputElement>document.getElementById('invoiceHash')).value = this.invoice.invoiceHash; //casting so .value will work
        (<HTMLInputElement>document.getElementById('balanceAmount')).value = String(this.invoice.balanceAmount); //casting so .value will work
        (<HTMLInputElement>document.getElementById('paymentAmount')).value = String(this.invoice.paymentAmount); //casting so .value will work
        (<HTMLInputElement>document.getElementById('saveClientPayment')).value = "false"; //casting so .value will work
        let authOnly = this.invoice.preAuthPayment && !this.invoice.hasBeenPreAuthed;
        (<HTMLInputElement>document.getElementById('authOnly')).value = String(authOnly); //casting so .value will work
      } else if(path === 'addCard'){
        (<HTMLInputElement>document.getElementById('clientId')).value = String(this.client.clientId); //casting so .value will work
      }
    } else {
      counter++;
      setTimeout(() => this.createStripePaymentForm(counter), 100)
    }
  }

  addStripeJSFile(){
    this.stripeService.addStripeJSFile();
    this.createStripePaymentForm(0)
  }

  ngAfterViewChecked(){
    this.responsiveService.setHeightForEmbedAccount();
  }

  ngOnInit() {
    sessionStorage.setItem("paymentFormPath", this.path);
    let businessId: number;
    if(this.path === 'invoice'){
      businessId = this.invoice.businessId;
    } else if(this.path === 'addCard'){
      this.submitBtnLabel = this.preference.labelMap.clientPaymentsAddCardBtn;
      this.submittingBtnLabel = this.preference.labelMap.clientPaymentsAddingCardBtn;
      businessId = this.client.businessId;
    }
    if (this.invoice && this.invoice.invoiceId) {
      this.businessService.getStripeConfigByInvoice(businessId, this.invoice.invoiceId).subscribe((stripeConfig: any) => {
        this.publishableKey = stripeConfig.stripePublishableKey;
        this.accountId = stripeConfig.stripeAccountId;
        this.addStripeJSFile();
      });
    } else {
      this.businessService.getStripeConfigDefault(businessId).subscribe((stripeConfig: any) => {
        this.publishableKey = stripeConfig.stripePublishableKey;
        this.accountId = stripeConfig.stripeAccountId;
        this.addStripeJSFile();
      });
    }


    sessionStorage.setItem('submittingBtnLabel', this.submittingBtnLabel);
    this.route.queryParams
        .subscribe(params => {
          let paying = params.paying;
          let cardAdded = params.cardAdded;
          if(paying === "false"){
            let updatedInvoice = sessionStorage.getItem('updatedInvoice');
            sessionStorage.removeItem('updatedInvoice');
            this.goBack(JSON.parse(updatedInvoice));
          } else if(paying === 'error'){
            (<HTMLInputElement>document.getElementById('stripe-creditcard')).innerHTML = this.submitBtnLabel;
            (<HTMLInputElement>document.getElementById('stripe-creditcard')).disabled = false;
            this.submittingBtnLabel = this.tagsService.assignObjectToTags(this.invoice, this.preference.labelMap.invoiceCreditCardChargingLabel, this.tagsService.invoiceFieldTags, this.currency);
            sessionStorage.setItem('invoiceCreditCardChargingLabel', this.submittingBtnLabel);
          }
        })
  }


  goBack(invoice){
    this.onGoBack.emit(invoice);
  }
}

