import {
  Component,
  OnInit,
  Input,
  Output,
  AfterViewChecked,
  AfterViewInit,
  AfterContentChecked,
  OnDestroy, ChangeDetectorRef
} from '@angular/core';
import {miniWebsite, preference, schedulerLink} from "../../common-classes/app-objects.model";
import {ResponsiveService} from "../../responsive.service";
import {StoreService} from "../../store/store.service";
import {Subscription} from "rxjs";
import {MiniWebsiteDisplayConfig} from "../../common-classes/default-styles.model";
import {BusinessService} from "../../business.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from '@angular/router';
import {JoinWaitListService} from '../../waitlist/join-wait-list/join-wait-list.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() miniWebsite: miniWebsite;
  @Input() schedulerLink: schedulerLink;
  @Input() preference: preference;
  displayConfig: MiniWebsiteDisplayConfig;
  showLoginBtn: boolean;
  showRegisterBtn: boolean;
  logoSizeDetected: boolean = false;

  @Input() isAuthenticated: boolean;
  @Input() isSandboxAccount: boolean;
  constructor(private responsiveService: ResponsiveService, private router: Router, private route: ActivatedRoute,
              private joinWaitListService: JoinWaitListService) { }

  checkIfLogoDefined(counter: number){
    let logoHeight = this.responsiveService.getLogoHeight();

    if(logoHeight === 0 && counter < 20){
      setTimeout(() => {
        counter++;
        this.checkIfLogoDefined(counter)
      },200);
    } else if(logoHeight !== 0) {
      this.logoSizeDetected = this.responsiveService.centerLoginBtns();
    }

  }

  goToLink(link: string){
    if (link === 'waitList') {
      if (this.router.url.indexOf('/waitlist/join') !== -1)
        this.joinWaitListService.joinWaitListHeaderClicked.next();
      else
        this.router.navigate(['/waitlist/join'], {relativeTo: this.route.root});
    }
  }

  ngOnInit() {
    this.displayConfig = this.miniWebsite.displayConfig;
    if(this.miniWebsite.logoAlign === 'center'){
      this.checkIfLogoDefined(0)
    } else {
      this.logoSizeDetected = true;
    }
    let showBtns = this.responsiveService.showLoginRegisterBtnLogic(this.preference);
    this.showLoginBtn = showBtns.showLoginBtn;

  }

  ngOnDestroy(): void {

  }

}
