import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {preference} from "../../../common-classes/app-objects.model";
import {BusinessService} from "../../../business.service";
import {MatDialog} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {AuthService} from "../../../auth/auth.service";
import {Router} from "@angular/router";
import {ResponsiveService} from "../../../responsive.service";
import {FormFunctionsService} from "../../../form-functions.service";

@Component({
  selector: 'scheduler-title-bar',
  templateUrl: './title-bar.component.html'
})
export class TitleBarComponent implements OnInit, AfterViewChecked {
  @Input() preference: preference;
  @Input() titleProperty: string;
  @Input() titleId: string;
  @Input() showPrintButton: boolean = false;
  @Input() printId: string;

  showLoginRegisterBtns: boolean = false;
  constructor(private businessService: BusinessService, private cdRef: ChangeDetectorRef, private responsiveService: ResponsiveService,
              private formFunctionsService: FormFunctionsService) { }

  ngAfterViewChecked(): void {
    // if(this.responsiveService.innerWidth <= this.responsiveService.xsScreenBreakPoint){
    //   this.responsiveService.adjustMatCardTitleBarHeight('schedulerHeader', this.titleId);
    // }
  }

  ngOnInit() {
    if(this.responsiveService.isEmbedded){
      let showBtns = this.responsiveService.showLoginRegisterBtnLogic(this.preference);
      if(showBtns.showLoginBtn || showBtns.showRegisterBtn){
        this.showLoginRegisterBtns = true;
      }
    }

  }

    printPage() {
        if(this.printId !== undefined){
            this.formFunctionsService.printElement(document.getElementById(this.printId))
            window.print();
        }
    }



}
