import {Injectable, Injector} from '@angular/core';
import {Coupon, CouponAPIReturnData, CSFilterParameterBean} from './common-classes/app-objects.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {ApiService} from './api.service';
import {Reason, ReasonDuration, ReasonTypes, RepeatingApptOption} from '../models/Reason.model';
import * as _ from 'lodash';
import {SessionService} from './session.service';
import {SchedulerPreferenceService} from './scheduler-preference.service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReasonsService {
  apiService: ApiService;
  sessionService: SessionService;
  schedulerPreferenceService: SchedulerPreferenceService;
  applyingCoupon = new Subject<boolean>();
  couponStatus = new Subject<string>();
  couponStatusDetail = new Subject<string>();
  coupon = new Subject<Coupon>();
  constructor(private injector: Injector, private http: HttpClient) {
    this.apiService = injector.get(ApiService);
    this.sessionService = injector.get(SessionService);
    this.schedulerPreferenceService = injector.get(SchedulerPreferenceService);
  }

  getReasons(CSFilterObj: CSFilterParameterBean, waitListOnly?: boolean){
    CSFilterObj.reasonIdList = null;
    let params = new HttpParams();
    if(this.sessionService.sessionObject && this.sessionService.sessionObject.parmCoupon) {
      // LHB 07/13/2020 TT-6781 added encodeURIComponent to make # symbol work on coupons
      params = params.append('couponCode', encodeURIComponent(this.sessionService.sessionObject.parmCoupon));
    }
    if (waitListOnly)
      params = params.append('waitListOnly', String(waitListOnly));
    return this.http
        .post<CouponAPIReturnData>(this.apiService.apiUrl + 'service', CSFilterObj, {observe: "body", responseType: 'json', params})
        .pipe(
            map((responseData: CouponAPIReturnData) => {
              if (responseData && responseData.reasonAPIBeanList)
                for (let i = 0, x = responseData.reasonAPIBeanList.length; i < x; i++)
                  responseData.reasonAPIBeanList[i].displayName = responseData.reasonAPIBeanList[i].reasonDesc;
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  applyCouponToReasons(couponCode: string, reasons: Reason[]){
    for(let i = 0, x = reasons.length; i < x; i++){
      if(reasons[i].originalPrice !== undefined && reasons[i].originalPrice !== null){
        reasons[i].price = reasons[i].originalPrice;
      }
    }
    let originalReasons = _.cloneDeep(reasons);
    couponCode = encodeURIComponent(couponCode); // LHB 07/13/2020 TT-6781 added encodeURIComponent to make # symbol work on coupons
    return this.http
        .post<CouponAPIReturnData>(this.apiService.apiUrl + 'service/applyCoupon/' + couponCode, reasons, {observe: "body", responseType: 'json'})
        .pipe(
            map((responseData: CouponAPIReturnData) => {
              for(let i = 0, x = originalReasons.length; i < x; i++){
                for(let j = 0, y = responseData.reasonAPIBeanList.length; j < y; j++){
                  if(originalReasons[i].reasonId === responseData.reasonAPIBeanList[j].reasonId){
                    if (originalReasons[i].price !== responseData.reasonAPIBeanList[j].price)
                      responseData.reasonAPIBeanList[j].originalPrice = originalReasons[i].price;
                    if (originalReasons[i].durationList !== null && originalReasons[i].durationList.length > 0) {
                      for (let k = 0, z = originalReasons[i].durationList.length; k < z; k++) {
                        for (let l = 0, a = responseData.reasonAPIBeanList[j].durationList.length; l < a; l++)
                          if (originalReasons[i].durationList[k].duration === responseData.reasonAPIBeanList[j].durationList[l].duration &&
                              originalReasons[i].durationList[k].durationName === responseData.reasonAPIBeanList[j].durationList[l].durationName &&
                              originalReasons[i].durationList[k].internalName === responseData.reasonAPIBeanList[j].durationList[l].internalName)
                            if (originalReasons[i].durationList[k].price !== responseData.reasonAPIBeanList[j].durationList[l].price)
                              responseData.reasonAPIBeanList[j].durationList[l].originalPrice = originalReasons[i].durationList[k].price;
                      }
                    }
                  }
                }
              }
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  createFriendlyDurationDisplay(visitMinutes: number){
    let returnString: string;
    if(visitMinutes!== null && visitMinutes !== undefined ){
      let durationHours: number;
      let durationHoursString: string;
      let durationMinutes: number;
      let durationMinutesString: string;
      let hoursLabel: string;
      let minutesLabel: string;
      if(visitMinutes%60 !==0){
        durationHours = visitMinutes - (visitMinutes%60);
        durationHours = durationHours/60;
        durationMinutes = visitMinutes%60;
      } else {
        durationHours = visitMinutes/60;
        durationMinutes = 0;
      }

      if(durationHours === 1){
        durationHoursString = durationHours.toString();
        hoursLabel = this.schedulerPreferenceService.schedulerPreference.labelMap.hourLabel;
      } else if(durationHours === 0){
        durationHoursString = "";
        hoursLabel = "";
      } else {
        durationHoursString = durationHours.toString();
        hoursLabel = this.schedulerPreferenceService.schedulerPreference.labelMap.hoursLabel;
      }

      if(durationMinutes === 0){
        durationMinutesString = "";
        minutesLabel = "";
      } else {
        durationMinutesString = durationMinutes.toString();
        minutesLabel = this.schedulerPreferenceService.schedulerPreference.labelMap.minutesLabel;
      }

      returnString = durationHoursString + " " + hoursLabel + " " + durationMinutesString + " " + minutesLabel;
    } else {
      returnString = "";
    }

    return returnString;
  }

  formatReason(reason: Reason): Reason {
    if(reason.durationList !== null && reason.durationList.length !== 0){
      reason.multiDuration = true;
      for(let j = 0, y = reason.durationList.length; j < y; j++){
        if(reason.durationList[j].duration === null || reason.durationList[j].duration === 0){
          reason.durationList[j].duration = 15;
        }
        reason.durationList[j].friendlyDuration = this.createFriendlyDurationDisplay(reason.durationList[j].duration);
      }
      if(reason.selectedDuration === undefined || reason.selectedDuration === null){
        reason.selectedDuration = new ReasonDuration();
        reason.selectedDuration.active = true;
      }
    } else {
      reason.multiDuration = false;
      if((reason.visitMinutes === null || reason.visitMinutes === 0) && reason.reasonType !== ReasonTypes.ADDON){
        reason.visitMinutes = 15;
      }
      reason.friendlyDuration = this.createFriendlyDurationDisplay(reason.visitMinutes);
    }
    if(reason.repeatingApptOptionList === null || reason.repeatingApptOptionList.length === 0){
      reason.allowRepeating = -1;
    }
    if(reason.allowRepeating !== null && reason.allowRepeating > -1){
      if(reason.allowRepeating === 0){
        let singleInstanceRepeat = new RepeatingApptOption();
        singleInstanceRepeat.numberOfAppointments = 1;
        singleInstanceRepeat.displayName = "1 Session";
        singleInstanceRepeat.price = reason.price;
        singleInstanceRepeat.deposit = reason.depositAmount;
        reason.repeatingApptOptionList.unshift(singleInstanceRepeat);
      }
      if (reason.repeatingApptOptionList.length === 1) {
        reason.selectedRepeatingApptOption = reason.repeatingApptOptionList[0];
      } else if(reason.selectedRepeatingApptOption === undefined || reason.selectedRepeatingApptOption === null){
        reason.selectedRepeatingApptOption = new RepeatingApptOption();
      }
    }
    return reason;
  }

  formatReasonList(reasons: Reason[]): Reason[] {
    for(let i = 0, x = reasons.length; i < x; i++)
      reasons[i] = this.formatReason(reasons[i])
    return reasons;
  }
}
