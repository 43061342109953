import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    CSFilterParameterBean,
    movePanels,
    preference,
    Client,
    RecurringAppointment,
    MultipleAppts,
    ClientAccount
} from "../../common-classes/app-objects.model";
import {TimeZoneCode} from "../time/time-objects.model";
import {FormGroup} from "@angular/forms";
import {BusinessService} from "../../business.service";
import {ClientInfoService} from "./client-info.service";
import {DatePipe} from "@angular/common";
import {ClientAccountService} from "../../client-account/client-account.service";
import {TagsService} from "../../tags.service";
import {TimeService} from "../time/time.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ResponsiveService} from "../../responsive.service";
import {ManageCreditCardComponent} from "../../modals/manage-credit-card/manage-credit-card.component";
import {StoredCardInfo} from "../../client-account/client-profile/client-profile-container/client-profile-container.component";
import {DataTablesService} from "../../data-tables.service";
import {MatDialog} from "@angular/material/dialog";
import {GoogleAnalyticsService} from "../../google-analytics.service";
import {ErrorModalComponent} from "../../modals/error-modal/error-modal.component";
import {WaitList, WaitListDate, WaitListDateTime} from '../../../models/WaitList.model';
import {Appointment} from '../../../models/Appointment.model';
import {Reason, ReasonTypes} from '../../../models/Reason.model';
import {SchedulerPreferenceService} from '../../scheduler-preference.service';
import {Location} from '../../../models/Location.model';
import {Staff} from '../../../models/Staff.model';
import {SchedulerService} from '../scheduler.service';
import {schedulerPanel, SchedulerPanelValues} from '../../../models/SchedulerPanel.model';
import {SelectedDateTimeObj} from '../../../models/SelectedDateTimeObj.model';
import {CustomField} from "../../../models/CustomField.model";
import {VariableAddressComponent} from "../locations/variable-address/variable-address.component";
import { ReCaptchaV3Service } from 'ng-recaptcha';


@Component({
  selector: 'client-info-panel',
  templateUrl: './client-info.component.html'
})
export class ClientInfoComponent implements OnInit {
    @Output() onMovePanels = new EventEmitter<movePanels>();
    @Input() preference: preference;
    @Input() panel: schedulerPanel;
    @Input() CSFilterObj: CSFilterParameterBean;
    @Input() inputValues: any;
    @Input() panels: schedulerPanel[];
    @Input() directionComingFrom: string;
    @Output() clearTimePanelForm = new EventEmitter<any>();
    clientInfoForm: FormGroup;
    appt: Appointment;
    recurringAppointment: RecurringAppointment;
    selectedReason: Reason;
    waitLists: WaitList[] = [];
    clientAccount: ClientAccount;
    showBackButton: boolean = true;
    savingAppointment: boolean = false;
    errorSavingAppointment: boolean = false;
    bookingMultipleAppts: boolean = false;
    bookingWaitListSingle: boolean = true;
    selectedClient: Client;
    formLoaded: boolean = false;
    infoPnlBookingSummary: string = null;
    errorSavingAppointmentMsg: string = '';
    requiredFieldsEmptyMsg: string = '';
    invalidAddressMsg: string = 'The address entered does not appear to be valid, please update the address and try again.';
    invalidAddressMsgExtra: string = '';
    clientNotAllowedToBook: boolean = false; // because of client status blacklisted or on hold
    disableSave: boolean = false;
    requiredFieldsEmpty: boolean = false;
    invalidAddress: boolean = false;
    additionalGuestsCapacity: number;
    earliestApptDate: Date; // LHB 08/08/2020 TT-4897 -- need to pass appt date to form to add conditions check
    constructor(private businessService: BusinessService, private clientInfoService: ClientInfoService, private tagsService: TagsService, private responsiveService: ResponsiveService,
                private datePipe: DatePipe, private clientAccountService: ClientAccountService, private cdRef: ChangeDetectorRef, private timeService: TimeService,
                private dialog: MatDialog, private googleAnalyticsService: GoogleAnalyticsService, private schedulerPreferenceService: SchedulerPreferenceService,
                private schedulerService: SchedulerService, private recaptchaV3Service: ReCaptchaV3Service) { }

    onFormLoaded(formLoaded){
        this.formLoaded = true;
        this.cdRef.detectChanges();
    }


    uploadingFile(event){
        this.disableSave = event;
        this.cdRef.detectChanges();
    }

    handleErrorSavingAppointment(error: HttpErrorResponse, bookingObject){
        if(error.error && error.error.message && error.error.message.indexOf('You are already in this Class Session') !== -1){
            this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('errorSavingClassApptAlreadyEnrolled', this.preference, bookingObject);
        } else if(error.error && error.error.message && error.error.message === 'errorClassMaxCapacity'){
            this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('errorSavingAppointmentClassMaxCapacity', this.preference, bookingObject);
        } else if(error.error && error.error.message && error.error.message === 'CARD_NOT_ADDED'){
            this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('errorSavingAppointmentAddCard', this.preference, bookingObject);
        } else if ((error && error.status === 504) || (error && error.error && error.error.status === 504)) {
            this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('errorSavingAppointmentTimeOut', this.preference, bookingObject);
        } else {
            this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('errorSavingAppointment', this.preference, bookingObject);
        }

        this.savingAppointment = false;
        this.errorSavingAppointment = true;
    }

    assignValuesToFields(form: FormGroup, bookingObject: any, client: Client){
        // In case saving fails on first time, reset fields objects to empty arrays to refill in when reattempting save
        bookingObject.fields = [];
        client.fields = [];
        let schedulerPrefIdsFilledIn = [];
        for(let prop in form.value){
            if(form.value[prop]!==null){
                schedulerPrefIdsFilledIn.push(Number(prop))
            }
        }
        //Fix for TT-6372 -- if client is logged in, copy the client id from selected client and then map all field values from selected client to preference fields
        if(this.clientAccountService.loggedInClient !== undefined && this.clientAccountService.loggedInClient !== null &&
            this.clientAccountService.selectedClient !== undefined && this.clientAccountService.selectedClient !== null){
            client.clientId = this.clientAccountService.selectedClient.clientId;
            client.createdDate = this.clientAccountService.selectedClient.createdDate;
            client.clientAccountId = this.clientAccountService.selectedClient.clientAccountId;//Setting to solve issue reported in comment for TT-6435
            client.viewClientAccountIds = this.clientAccountService.selectedClient.viewClientAccountIds;
            client.excludeEmailCategoryIdList = this.clientAccountService.selectedClient.excludeEmailCategoryIdList;
            client.excludeEmailTemplateIdList = this.clientAccountService.selectedClient.excludeEmailTemplateIdList;
            client.status = this.clientAccountService.selectedClient.status; // 04/28/2020 TT-6526 - fix for when client is logged in and trying to book
            client.externalUserName = this.clientAccountService.selectedClient.externalUserName;
            if(this.clientAccountService.selectedClient.fields !== undefined && this.clientAccountService.selectedClient.fields !== null) {
                for (let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++) {
                    for (let j = 0, y = this.clientAccountService.selectedClient.fields.length; j < y; j++) {
                        if(this.preference.schedulerPreferenceFieldDefnList[i].schedulerPreferenceFieldDefnId === this.clientAccountService.selectedClient.fields[j].schedulerPreferenceFieldDefnId){
                            this.preference.schedulerPreferenceFieldDefnList[i].value = this.clientAccountService.selectedClient.fields[j].value;
                        }
                    }
                }
            }
        }
        for(let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++){
            if(schedulerPrefIdsFilledIn.indexOf(this.preference.schedulerPreferenceFieldDefnList[i].schedulerPreferenceFieldDefnId)!==-1) {
                let idString = this.preference.schedulerPreferenceFieldDefnList[i].schedulerPreferenceFieldDefnId.toString();
                for(let j = 0, y = this.inputValues.length; j < y; j++){
                    if(this.inputValues[j].panel === 'clientInfo'){
                        for(let k = 0, z = this.inputValues[j].values.length; k < z; k++){
                            if(this.inputValues[j].values[k].field === idString) {
                                this.preference.schedulerPreferenceFieldDefnList[i].value = this.inputValues[j].values[k].value;
                            }
                        }
                    }
                }
            } else if (this.preference.schedulerPreferenceFieldDefnList[i].mode === 'APPT' && !this.schedulerPreferenceService.checkIfFieldInParametersPassed(this.preference.schedulerPreferenceFieldDefnList[i])) // LHB 09/18/2020 TT-7008; LHB 09/22/2020 TT-7027 added if check so doesn't null out client info fields for save
                this.preference.schedulerPreferenceFieldDefnList[i].value = null;
            else if (this.preference.schedulerPreferenceFieldDefnList[i].mode === 'CLIENT' && !this.clientAccountService.loggedInClient && !this.clientAccountService.selectedClient && !this.schedulerPreferenceService.checkIfFieldInParametersPassed(this.preference.schedulerPreferenceFieldDefnList[i])) // LHB 3/5/2021 TT-7508 -- clear out client info fields if they selected some other value
                this.preference.schedulerPreferenceFieldDefnList[i].value = null;

            switch(this.preference.schedulerPreferenceFieldDefnList[i].mode){
                case 'CLIENT':
                    client.fields.push(this.preference.schedulerPreferenceFieldDefnList[i]);
                    if(this.preference.schedulerPreferenceFieldDefnList[i].code === 'emailAddress' && this.preference.schedulerPreferenceFieldDefnList[i].value !== null)
                        client.emailAddress = this.preference.schedulerPreferenceFieldDefnList[i].value;
                    break;
                case 'APPT':
                    bookingObject.fields.push(this.preference.schedulerPreferenceFieldDefnList[i])
                    break;
                case 'APPT_TXT_REMINDER':
                    if(this.preference.schedulerPreferenceFieldDefnList[i].code === 'sendTextReminder'){
                        if(this.preference.schedulerPreferenceFieldDefnList[i].value !== null
                            && this.preference.schedulerPreferenceFieldDefnList[i].value.toString() === 'true') {
                            //Fix for TT-6377
                            if (this.waitLists !== undefined && this.waitLists !== null && this.waitLists.length > 0) {
                                client.allowWaitListText = true;
                            }
                            for (let j = 0, y = this.preference.schedulerPreferenceFieldDefnList.length; j < y; j++) {
                                if (this.preference.schedulerPreferenceFieldDefnList[j].code === 'sendTextReminderHrs') {
                                    switch (this.preference.schedulerPreferenceFieldDefnList[j].value) {
                                        case this.preference.labelMap.infoPnlSendTextD2Hr:
                                            bookingObject.remindClientSmsHrs = 2;
                                            break;
                                        case this.preference.labelMap.infoPnlSendTextD4Hr:
                                            bookingObject.remindClientSmsHrs = 4;
                                            break;
                                        case this.preference.labelMap.infoPnlSendTextD12Hr:
                                            bookingObject.remindClientSmsHrs = 12;
                                            break;
                                        case this.preference.labelMap.infoPnlSendTextD24Hr:
                                            bookingObject.remindClientSmsHrs = 24;
                                            break;
                                        default:
                                            if (this.preference.clientReminderSMSHoursList && this.preference.clientReminderSMSHoursList.length > 0) // TT-6496 2/5/2021
                                                bookingObject.remindClientSmsHrs = this.preference.clientReminderSMSHoursList[0];
                                            else
                                                bookingObject.remindClientSmsHrs = 1;
                                    }
                                }
                            }
                        } else if(this.preference.schedulerPreferenceFieldDefnList[i].value === null
                            || this.preference.schedulerPreferenceFieldDefnList[i].value.toString() === 'false'){
                            bookingObject.remindClientSmsHrs = 0;
                        }
                    }
                    break;
                case 'APPT_ADDITIONAL_GUESTS':
                    bookingObject.seats = 1 + Number(this.preference.schedulerPreferenceFieldDefnList[i].value);
                    break;
            }
        }
        return {bookingObject: bookingObject, client: client}
    }

    createClientObject(){
        //FIRST GET FIELD VALUES TO CREATE CLIENT
        let createdDateDate = new Date().getTime();
        let createdDateString = this.datePipe.transform(createdDateDate, 'yyyy-MM-dd', 'UTC');
        let timeZoneCode = new TimeZoneCode(this.CSFilterObj.clientTimeZone, this.CSFilterObj.clientTimeZoneId);
        if(this.preference.allowTZChange !== 1){
            timeZoneCode = new TimeZoneCode(this.CSFilterObj.businessTimeZone, this.CSFilterObj.businessTimeZoneId);
        }
        let client = new Client(null,
            null,
            null,
            [],
            sessionStorage.getItem('locale'),
            null,
            'WebClient',
            createdDateDate,
            timeZoneCode,
            false);
        return client;
    }

    addDisplayTimesToWaitLists(saveWaitListTime: WaitListDateTime[], passedWaitListTime: WaitListDateTime[]){
        for(let i = 0, x = saveWaitListTime.length; i < x; i++){
            for(let j = 0, y = passedWaitListTime.length; j < y; j++){
                if(saveWaitListTime[i].startTime === passedWaitListTime[j].startTime)
                    saveWaitListTime[i].clientStartTimeForDisplay = passedWaitListTime[j].clientStartTimeForDisplay
                if (saveWaitListTime[i].endTime === passedWaitListTime[j].endTime)
                    saveWaitListTime[i].clientEndTimeForDisplay = passedWaitListTime[j].clientEndTimeForDisplay
            }
        }
    }

    reserveWaitList(direction, waitLists: WaitList[], updatedClient: Client, appt?: Appointment, multipleAppts?: MultipleAppts){
        for(let i = 0, x = waitLists.length; i < x; i++){
            waitLists[i].client = updatedClient;
            if(waitLists[i].fields === undefined || waitLists[i].fields === null || waitLists[i].fields.length == 0){
                if(appt)
                    waitLists[i].fields = appt.fields;
                else if(multipleAppts)
                    waitLists[i].fields = multipleAppts.recurringAppointment.fields;
            }
            if(waitLists[i].staff && waitLists[i].staff.professionalId === null)
                waitLists[i].staff = null;
            waitLists[i].waitListTZ = this.CSFilterObj.clientTimeZone;
            if (waitLists[i].reason && waitLists[i].reason.selectedDuration) {
                // @ts-ignore
                waitLists[i].duration = waitLists[i].reason.selectedDuration.duration;
            } else {
                // @ts-ignore
                waitLists[i].duration = waitLists[i].reason.visitMinutes;
            }
        }

        this.businessService.createWaitList(waitLists)
            .subscribe((savedWaitlist: WaitList[]) => {
                this.waitLists = savedWaitlist;
                //For TT-6286 related work; when saving waitLists, need to reconcile the display tie with the passed time if the timezone is allowed to be set to the clients timezone
                for (let i = 0, x = this.waitLists.length; i < x; i++) {
                    for (let j = 0, y = waitLists.length; j < y; j++) {
                        for (let k = 0, z = this.waitLists[i].waitListDateList.length; k < z; k++) {
                            for (let l = 0, a = waitLists[j].waitListDateList.length; l < a; l++) {
                                if (this.waitLists[i].waitListDateList[k].date === waitLists[j].waitListDateList[l].date) {
                                    this.addDisplayTimesToWaitLists(this.waitLists[i].waitListDateList[k].waitListDateTimeList, waitLists[j].waitListDateList[l].waitListDateTimeList);
                                }
                            }
                        }
                    }
                }
                this.errorSavingAppointment = false;
                this.onMovePanels.emit({direction: direction, panel: this.panel, appt: appt, multipleAppts: multipleAppts, waitLists: this.waitLists})
            }, error => {
                //FAILED TO SAVE APPT
                this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('errorSavingAppointment', this.preference, this.appt);
                this.savingAppointment = false;
                this.errorSavingAppointment = true;
            })
    }

    formatWaitlistForReserve(form: FormGroup, direction, waitLists: WaitList[]){
        let client = this.createClientObject();
        //Since all the waitlists created will have same fields and client, can just send first waitlist object to get values and then copy that to all others
        let updatedObjectsFromFieldValues = this.assignValuesToFields(form, waitLists[0], client);
        client = updatedObjectsFromFieldValues.client;
        for(let i = 0, x = waitLists.length; i < x; i++){
            waitLists[i].fields = updatedObjectsFromFieldValues.bookingObject.fields;
        }

        //booking single reservation on waitlist
        this.businessService.createUpdateClient(client)
            .subscribe(
                (updatedClient) => {
                    this.reserveWaitList(direction, waitLists, updatedClient);
                }, error => {
                    //FAILED TO SAVE CLIENT
                    this.savingAppointment = false;
                    this.errorSavingAppointment = true;
                })
    }



    createRepeatingAppt(updatedClient: Client, direction: string, bookingObject: any, token: string){
        if (bookingObject.reason.reasonType === 'CLASS') {
            bookingObject.classScheduleList = bookingObject.timeSlotList;
            bookingObject.timeSlotList = null;
        }
        bookingObject.recaptchaToken = token;
        this.businessService.createRepeatingAppt(bookingObject)
            .subscribe((multipleAppts: MultipleAppts) => {
                //Check to make sure at least 1 of the appointments have saved
                let savedApptCount = 0;
                for (let i = 0, x = multipleAppts.appointments.length; i < x; i++) {
                    if (multipleAppts.appointments[i].thankYouString !== this.businessService.doubleBookedTimeErrorMsg) {
                        savedApptCount++;
                        break;
                    }
                }
                let appointmentLabel : string;
                if(multipleAppts.recurringAppointment !== null) {
                    appointmentLabel = multipleAppts.recurringAppointment.client.fullName + " booked " + multipleAppts.appointments.length + " appointments for " + multipleAppts.recurringAppointment.reason.reasonDesc + " with " + this.googleAnalyticsService.getStaffNameForGoogleAnalyticsPost(multipleAppts.recurringAppointment.staff, this.preference) + " at " + this.googleAnalyticsService.getLocationNameForGoogleAnalyticsPost(multipleAppts.recurringAppointment.location);
                }
                if (savedApptCount > 0 && appointmentLabel !== null) {
                    if (this.waitLists.length > 0) {
                        this.reserveWaitList(direction, this.waitLists, updatedClient, undefined, multipleAppts);
                        this.googleAnalyticsService.eventEmitter(appointmentLabel, "appointment", "booked_multiple_appointments", appointmentLabel, 1);
                    } else {
                        this.errorSavingAppointment = false;
                        this.onMovePanels.emit({
                            direction: direction,
                            panel: this.panel,
                            appt: null,
                            multipleAppts: multipleAppts
                        })
                        this.googleAnalyticsService.eventEmitter(appointmentLabel, "appointment", "booked_multiple_appointments", appointmentLabel, 1);
                    }

                } else {
                    this.errorSavingAppointment = true;
                    this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('errorSavingAllAppointmentsDblBooked', this.preference, bookingObject);
                    this.savingAppointment = false;
                }
            }, error => {
                //FAILED TO SAVE REPEATING APPT
                this.handleErrorSavingAppointment(error, bookingObject);
            })
    }

    removeApptTimeFromTimeInputValue(appt: Appointment) { // LHB 02/25/2022 TT-7085
        if (appt.thankYouString === this.businessService.doubleBookedTimeErrorMsg)
            for (let i = 0; i < this.inputValues.length; i++) {
                if (this.inputValues[i].panel === 'time') {
                    for (let j = 0, y = this.inputValues[i].values.length; j < y; j++) {
                        if (this.inputValues[i].values[j].value.startDateTimeUTC === appt.startDateTimeUTC) {
                            this.inputValues[i].values.splice(j, 1);
                            break;
                        }
                    }
                    if (this.inputValues[i].values.length === 0)
                        this.clearTimePanelForm.emit();
                }
            }
    }

    createOneTimeAppt(updatedClient: Client, direction: string, bookingObject: any, token: string){
        bookingObject.recaptchaToken = token;
        this.businessService.createAppt(bookingObject)
            .subscribe((updatedAppt) => {
                this.appt = updatedAppt;
                for(let prop in bookingObject){
                    if(!this.appt.hasOwnProperty(prop)){
                        this.appt[prop] = bookingObject[prop];
                    }
                }

                let appointmentLabel = this.appt.client.fullName + " booked " + this.appt.reason.reasonDesc + " with " + this.googleAnalyticsService.getStaffNameForGoogleAnalyticsPost(this.appt.staff, this.preference) + " at " + this.appt.location.locationName + " for " + this.appt.appointmentDateTimeClient
                if (this.appt.thankYouString === this.businessService.doubleBookedTimeErrorMsg) {
                    this.savingAppointment = false;
                    this.errorSavingAppointment = true;
                    this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('errorSavingAppointmentDblBooked', this.preference, this.appt);
                    this.removeApptTimeFromTimeInputValue(this.appt);
                } else if (this.waitLists.length > 0) {
                    this.reserveWaitList(direction, this.waitLists, updatedClient, this.appt);
                    this.googleAnalyticsService.eventEmitter(appointmentLabel, "appointment", "booked_appointment", appointmentLabel, 1);
                } else {
                    this.errorSavingAppointment = false;
                    this.onMovePanels.emit({direction: direction, panel: this.panel, appt: this.appt})
                    this.googleAnalyticsService.eventEmitter(appointmentLabel, "appointment", "booked_appointment", appointmentLabel, 1);
                }
            }, error => {
                //FAILED TO SAVE APPT
                this.handleErrorSavingAppointment(error, bookingObject);

            })
    }

    handleApptSave(updatedClient: Client, direction: string, bookingObject: any){
        if (bookingObject.location && bookingObject.location.wasUndefined) // LHB 1/7/2021 TT-7258
            bookingObject.location = null;
        if (bookingObject.endTime === 2400) {
            bookingObject.endTime = 0;
        }
        //TT-11084: Checks for a very unique condition in which there is a buffer at the end of
        //the appointment ending at midnight so clientEndDate erroneously is pushed back to the next day
        this.checkDateFields(bookingObject)

        if (this.preference.allowRecaptcha) {
            this.recaptchaV3Service.execute('importantAction')
                .subscribe((token: string) => {
                    if (this.recurringAppointment !== undefined && this.recurringAppointment !== null) {
                        this.createRepeatingAppt(updatedClient, direction, bookingObject, token);
                    } else {
                        this.createOneTimeAppt(updatedClient, direction, bookingObject, token);
                    }
                });
        } else {
            if (this.recurringAppointment !== undefined && this.recurringAppointment !== null) {
                this.createRepeatingAppt(updatedClient, direction, bookingObject, null);
            } else {
                this.createOneTimeAppt(updatedClient, direction, bookingObject, null);
            }
        }

    }

    showAddCardModal(updatedClient: Client, direction: string, bookingObject: any){
        let dataToPass = {client: updatedClient, preference: this.preference, path: 'addCardConfirmBooking'}
        const dialogRef = this.dialog.open(ManageCreditCardComponent,
            {
                maxWidth: this.responsiveService.getMaxWidthForModals('90vw'),
                minWidth: this.responsiveService.getMaxWidthForModals('90vw'),
                data: dataToPass
            })
        dialogRef.afterClosed().subscribe((updatedData: any) => {
            let updatedCardsList = sessionStorage.getItem('updatedCardsList');
            if(updatedCardsList !== undefined && updatedCardsList !== null){
                sessionStorage.removeItem('updatedCardsList');
                let updatedCards: StoredCardInfo = JSON.parse(updatedCardsList);
                // this.selectedClient = this.clientAccountService.storeCardInfoOnClient(this.selectedClient, updatedCards);
                this.handleApptSave(updatedClient, direction, bookingObject);
            } else {
                let init = {error: 'CARD_NOT_ADDED'}
                let error: HttpErrorResponse = new HttpErrorResponse(init);
                this.handleErrorSavingAppointment(error, bookingObject);
            }
        })
    }

    getActiveApptCountForClient(updatedClient: Client, direction: string, bookingObject: any) { // LHB 3/26/2021 TT-7562
        this.businessService.getActiveApptCount(updatedClient.businessId, [updatedClient])
            .subscribe(clientApptCountMap => {
                for(let j = 0, y = clientApptCountMap.length; j < y; j++){
                    for(let prop in clientApptCountMap[j]){
                        if(Number(prop) === updatedClient.clientId){
                            updatedClient.activeApptCount = clientApptCountMap[j][prop];
                        }
                    }
                }
                let allowedToBook = false;
                if(updatedClient.activeApptCount < this.preference.maxActiveApptClient){
                    allowedToBook = true;
                }
                if(allowedToBook){
                    this.handleApptSave(updatedClient, direction, bookingObject);
                } else {
                    this.clientNotAllowedToBook = true;
                    this.savingAppointment = false;
                    this.errorSavingAppointment = true;
                    const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(), data: {preference: this.preference, errorType: 'maxApptsReached'}})
                }
            }, error => {
                // console.log(error);
            })
    }

    createUpdateClient(form: FormGroup, direction, bookingObject: any){
        this.invalidAddress = false;
        this.invalidAddressMsgExtra = '';
        let client = this.createClientObject();
        let updatedObjectsFromFieldValues = this.assignValuesToFields(form, bookingObject, client);
        client = updatedObjectsFromFieldValues.client;
        bookingObject = updatedObjectsFromFieldValues.bookingObject;
        if(bookingObject.staff !== undefined && bookingObject.staff !== null && bookingObject.staff.professionalId === null){
            bookingObject.staff = null;
        }
        let checkCardOnFile: boolean = false;
        if((this.businessService.defaultGateway === 'SQUARE' || this.businessService.defaultGateway === 'STRIPE'  || this.businessService.defaultGateway === 'LUMAPAY') &&
            (bookingObject.reason.requirePreAuthPayment || (bookingObject.reason.requirePreAuthPayment === null && this.businessService.requirePreAuthPayment)) &&
            (bookingObject.price === null || bookingObject.price === 0)){
            checkCardOnFile = true;
        }
        if(this.schedulerService.schedulerLinkService.schedulerLink.booleanMap != null && this.schedulerService.schedulerLinkService.schedulerLink.booleanMap.allowClientAddressValidation){
           let fullAddress = this.getFullAddress(client);
           if(fullAddress !== 'incomplete') {
               if (fullAddress.replace(' ', '') !== '') {
                   // fullAddress is not empty, validate it
                   this.businessService.validateVariableLocationAddress(fullAddress)
                       .subscribe((addresses: string[]) => {
                           if (addresses) {
                               let found: boolean = false;
                               let similarAddresses = [];
                               for (let x = 0; x < addresses.length; x++) {
                                   similarAddresses.push(addresses[x]);
                                   if (addresses[x].toUpperCase().includes(fullAddress.toUpperCase())) {
                                       found = true;
                                       similarAddresses = [];
                                       this.callCreateUpdateClient(client, checkCardOnFile, direction, bookingObject);
                                       break;
                                   }
                               }
                               if (!found) {
                                   this.savingAppointment = false;
                                   this.invalidAddress = true;
                                   this.invalidAddressMsgExtra = this.invalidAddressMsg + '<br/>' + 'Below are some addresses that similar to the one entered.<br/><ul>';
                                   for (let x = 0; x < similarAddresses.length; x++) {
                                       this.invalidAddressMsgExtra += '<li>' + similarAddresses[x] + '</li>';
                                   }
                                   this.invalidAddressMsgExtra += '</ul>';
                               }
                           } else {
                               this.savingAppointment = false;
                               this.invalidAddress = true;
                           }
                       }, (error) => {
                       });
               } else {
                   this.callCreateUpdateClient(client, checkCardOnFile, direction, bookingObject);
               }
           }else{
               this.savingAppointment = false;
               this.invalidAddress = true;
               this.invalidAddressMsgExtra += this.invalidAddressMsg + '<br/>' + 'A complete address is required on this form.'
           }
        } else {
            this.callCreateUpdateClient(client, checkCardOnFile, direction, bookingObject);
        }
    }

    callCreateUpdateClient(client: Client, checkCardOnFile: boolean, direction, bookingObject: any){
        this.businessService.createUpdateClient(client, undefined, checkCardOnFile)
            .subscribe(
                (updatedClient: Client) => {
                    bookingObject.client = updatedClient;
                    if (this.bookingMultipleAppts) {
                        bookingObject.clientId = updatedClient.clientId;
                    }
                    if (updatedClient.status === this.clientAccountService.onHoldStatus) {
                        //Show on hold error message
                        this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('clientOnHoldError', this.preference, bookingObject);
                        this.clientNotAllowedToBook = true;
                        this.savingAppointment = false;
                        this.errorSavingAppointment = true;
                    } else if (updatedClient.status === this.clientAccountService.blockedStatus && this.preference.clientBlacklistMode !== 'GOBBLE') {
                        //Show black list error message
                        this.errorSavingAppointmentMsg = this.tagsService.convertApptPropertiesToTags('clientBlacklistError', this.preference, bookingObject);
                        this.clientNotAllowedToBook = true;
                        this.savingAppointment = false;
                        this.errorSavingAppointment = true;
                    } else {
                        if (checkCardOnFile && !updatedClient.hasCardOnFile) {
                            // show add card modal
                            updatedClient.storedCardInfo = new StoredCardInfo();
                            updatedClient.storedCardInfo.defaultGateway = this.businessService.defaultGateway;
                            this.showAddCardModal(updatedClient, direction, bookingObject)
                        } else if (this.preference.maxActiveApptClient !== -1) { // LHB 3/26/2021 TT-7562
                            this.getActiveApptCountForClient(updatedClient, direction, bookingObject);
                        } else
                            this.handleApptSave(updatedClient, direction, bookingObject);
                    }

                }, error => {
                    //FAILED TO SAVE CLIENT
                    this.savingAppointment = false;
                    this.errorSavingAppointment = true;
                }
            )
    }
    getFullAddress(client: Client){
        let fullAddress = '';
        let address1 = null;
        let address2 = '';
        let city = null;
        let state = null;
        let zip = null;
        for(let x =0; x < client.fields.length; x++){
            if(client.fields[x].code === 'address1' && client.fields[x].value)
                address1 = client.fields[x].value;
            if(client.fields[x].code === 'address2' && client.fields[x].value)
                address2 = client.fields[x].value;
            if(client.fields[x].code === 'city' && client.fields[x].value)
                city = client.fields[x].value;
            if(client.fields[x].code === 'state' && client.fields[x].value)
                state = client.fields[x].value;
            if(client.fields[x].code === 'zip' && client.fields[x].value)
                zip = client.fields[x].value;
        }
        if(address1 && city && state && zip)
            fullAddress = address1 + (address2?(' ' + address2 + ', ') : ', ') + city + ', ' + state + ' ' + zip;
        else
            fullAddress = 'incomplete';
        return fullAddress;
    }
    movePanels(direction: string){
        if(direction === 'back'){
            this.onMovePanels.emit({direction: direction, panel: this.panel})
        } else if(!this.clientNotAllowedToBook && !this.savingAppointment) {
            //Check to see if all required fields are filled out:
            if(!this.clientInfoService.isClientInfoFormValid(this.clientInfoForm, this.preference.schedulerPreferenceFieldDefnList)){
                this.requiredFieldsEmpty = true;
                this.requiredFieldsEmptyMsg = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.requiredFieldsEmptyMsg, this.tagsService.preferenceTagsArray);
                for(let prop in this.clientInfoForm.controls){
                    this.clientInfoForm.controls[prop].markAsTouched();
                }
                let errorMessage: string = this.tagsService.getInvalidFormFieldErrorMsg(this.preference.schedulerPreferenceFieldDefnList,
                    'schedulerPreferenceFieldDefnId',
                    this.clientInfoForm,
                    this.preference,
                    'clientInfoFieldsInvalid');
                const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(),
                    data: {preference: this.preference, errorType: 'clientInfoFieldsInvalid', errorMessage: errorMessage}})
            } else {
                this.requiredFieldsEmpty = false;
                this.savingAppointment = true;
                let clientInfoPanelNum = this.panel.panelOrder;
                this.inputValues = this.schedulerService.arrangeInputValues(this.inputValues, this.clientInfoForm.value, clientInfoPanelNum, this.panels, this.CSFilterObj);
                for(let h = 0, w = this.inputValues.length; h < w; h++) {
                    if(this.inputValues[h].panel === 'clientInfo') {
                        for (let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++) {
                            for(let j = 0, y = this.inputValues[h].values.length; j < y; j++) {
                                if(Number(this.inputValues[h].values[j].field) === this.preference.schedulerPreferenceFieldDefnList[i].schedulerPreferenceFieldDefnId) {
                                    switch (this.preference.schedulerPreferenceFieldDefnList[i].dataType) {
                                        case 'LIST_CHKBOX':
                                            if (this.preference.schedulerPreferenceFieldDefnList[i].value !== null) {
                                                this.inputValues[h].values[j].value= this.preference.schedulerPreferenceFieldDefnList[i].value;
                                            }
                                            break;
                                        case 'DATE':
                                            this.inputValues[h].values[j].value = this.datePipe.transform(this.preference.schedulerPreferenceFieldDefnList[i].value, 'yyyy-MM-dd', sessionStorage.getItem('browserTimezone'));
                                            break;
                                    }
                                }
                            }
                        }
                    }
                }
                if(this.recurringAppointment){
                    if((this.recurringAppointment.recurringSchedule  && this.recurringAppointment.recurringSchedule.recurringScheduleId) ||
                        (this.recurringAppointment.timeSlotList && this.recurringAppointment.timeSlotList.length !== 0) ||
                        (this.recurringAppointment.classScheduleList && this.recurringAppointment.classScheduleList.length !== 0)){
                        if(this.clientAccountService.loggedInClient !== undefined && this.preference.maxActiveApptClient !== -1){
                            let currentActiveApptCount = this.clientAccountService.selectedClient.activeApptCount;
                            let apptsBooking = 0;
                            if(this.recurringAppointment.timeSlotList !== undefined && this.recurringAppointment.timeSlotList !== null && this.recurringAppointment.timeSlotList.length !== 0){
                                apptsBooking = this.recurringAppointment.timeSlotList.length;
                            } else if(this.recurringAppointment.classScheduleList !== undefined && this.recurringAppointment.classScheduleList !== null && this.recurringAppointment.classScheduleList.length !== 0){
                                apptsBooking = this.recurringAppointment.classScheduleList.length;
                            }
                            if(currentActiveApptCount + apptsBooking > this.preference.maxActiveApptClient){
                                this.savingAppointment = false;
                                let errorMsgClientTagsTranslated = this.tagsService.assignObjectToTags(this.preference, this.preference.labelMap.maxApptsReachedBookingMultipleErrorMsg, this.tagsService.clientTagsArray);
                                errorMsgClientTagsTranslated = this.tagsService.assignClientValuesToTags(errorMsgClientTagsTranslated, this.clientAccountService.selectedClient.fields, this.clientAccountService.selectedClient);
                                if(errorMsgClientTagsTranslated.indexOf('%TIME_CART_COUNT%')!== -1){
                                    errorMsgClientTagsTranslated = errorMsgClientTagsTranslated.replace(new RegExp("%TIME_CART_COUNT%", 'g'), apptsBooking.toString());
                                }
                                if(errorMsgClientTagsTranslated.indexOf('%APPT_COUNT_TO_REMOVE%')!== -1){
                                    let apptCountToRemove = currentActiveApptCount + apptsBooking - this.preference.maxActiveApptClient;
                                    errorMsgClientTagsTranslated = errorMsgClientTagsTranslated.replace(new RegExp("%APPT_COUNT_TO_REMOVE%", 'g'), apptCountToRemove.toString());
                                }
                                this.errorSavingAppointmentMsg = this.tagsService.assignObjectToTags(this.preference, errorMsgClientTagsTranslated, this.tagsService.preferenceTagsArray);
                                this.errorSavingAppointment = true;
                            } else {
                                this.createUpdateClient(this.clientInfoForm, direction, this.recurringAppointment);
                            }
                        } else {
                            this.createUpdateClient(this.clientInfoForm, direction, this.recurringAppointment);
                        }
                    } else {
                        this.formatWaitlistForReserve(this.clientInfoForm, direction, this.waitLists);
                    }
                } else {
                    if(this.appt.waitListMode){
                        this.formatWaitlistForReserve(this.clientInfoForm, direction, this.waitLists);
                    } else {
                        if(this.clientAccountService.loggedInClient !== undefined && this.preference.maxActiveApptClient !== -1 && this.clientAccountService.selectedClient.activeApptCount >= this.preference.maxActiveApptClient){
                            this.savingAppointment = false;
                            let errorMsgClientTagsTranslated = this.tagsService.assignClientValuesToTags(this.preference.labelMap.maxApptsReachedBookingErrorMsg, this.clientAccountService.selectedClient.fields, this.clientAccountService.selectedClient)
                            this.errorSavingAppointmentMsg = this.tagsService.assignObjectToTags(this.preference, errorMsgClientTagsTranslated, this.tagsService.preferenceTagsArray);
                            this.errorSavingAppointment = true;
                        } else {
                            this.createUpdateClient(this.clientInfoForm, direction, this.appt);
                        }
                    }

                }
            }


        }
    }

    setAdditionalGuestsCapacity(selectedDateTimeObj: SelectedDateTimeObj){
        if(selectedDateTimeObj.maxCapacity !== undefined && selectedDateTimeObj.maxCapacity !== null &&
            selectedDateTimeObj.currentCapacity !== undefined && selectedDateTimeObj.currentCapacity !==null){
            let currentSessionAdditionalGuestCapacity = selectedDateTimeObj.maxCapacity - selectedDateTimeObj.currentCapacity - 1;//subtracting 1 for current seat that appt will take up
            if(this.additionalGuestsCapacity === undefined || this.additionalGuestsCapacity === null || currentSessionAdditionalGuestCapacity < this.additionalGuestsCapacity){
                this.additionalGuestsCapacity = currentSessionAdditionalGuestCapacity;
            }
        }
    }

    addFieldsToObjects(object: any, apptTimes: SelectedDateTimeObj[]){
        for(let i = 0, x = this.inputValues.length; i < x; i++) {
            switch (this.inputValues[i].panel) {
                case 'locationSuperGroups':
                    object.locationSuperGroup = this.inputValues[i].values[0].value;
                    break;
                case 'locationGroups':
                    object.locationGroup = this.inputValues[i].values[0].value;
                    break;
                case 'locations':
                    object.location = this.inputValues[i].values[0].value;
                    if(object.location.variableAddress)
                        object.address = object.location.variableAddress;
                    break;
                case 'staff':
                    object.staff = this.inputValues[i].values[0].value;
                    if(object.staff.professionalId === null)
                        object.noPrefSelected = true;
                    break;
                case 'reasonSuperGroups':
                    object.reasonSuperGroup = this.inputValues[i].values[0].value;
                    break;
                case 'reasonGroups':
                    object.reasonGroup = this.inputValues[i].values[0].value;
                    break;
                case 'reasons':
                    object.reason = this.inputValues[i].values[0].value;
                    object.price = object.reason.originalPrice || object.reason.price;
                    if (object.reason.selectedAddOns && object.reason.selectedAddOns.length > 0) {
                        object.addOnReasonIdList = [];
                        for (let k = 0, z = object.reason.selectedAddOns.length; k < z; k++)
                            if (!isNaN(object.reason.selectedAddOns[k].price) && object.reason.selectedAddOns[k].price > 0) {
                                object.price = object.price + object.reason.selectedAddOns[k].price;
                                object.addOnReasonIdList.push(object.reason.selectedAddOns[k].reasonId);
                            }
                    } else
                        object.addOnReasonIdList = null;
                    if(object.reason.selectedDuration !== undefined)
                        object.reasonDesc = object.reason.selectedDuration.durationName;
                    if(object.reason.selectedRepeatingApptOption !== undefined && object.reason.selectedRepeatingApptOption !== null && object.reason.selectedRepeatingApptOption.numberOfAppointments > 1){
                        object.adhoc = false;
                    }
                    break;
                case 'time':
                    if (apptTimes.length > 1) {
                        //create time slot array
                        object.timeSlotList = [];
                        let locationIds = [];
                        let staffIds = [];
                        for(let j = 0, y = apptTimes.length; j < y; j++){
                            this.setAdditionalGuestsCapacity(apptTimes[j]);
                            object.timeSlotList.push(apptTimes[j]);
                            if(this.earliestApptDate === undefined || apptTimes[j].clientStartTimeDate < this.earliestApptDate) // LHB 08/08/2020 TT-4897
                                this.earliestApptDate = apptTimes[j].clientStartTimeDate;
                            if((object.location === undefined || object.location === null) && apptTimes[j].locationId !== null && apptTimes[j].locationId !== undefined){
                                locationIds.push(apptTimes[j].locationId);
                            }
                            if((object.staff === undefined || object.staff === null) && apptTimes[j].professionalId !== null && apptTimes[j].professionalId !== undefined){
                                staffIds.push(apptTimes[j].professionalId)
                            }
                            if(locationIds.length === 1)
                                object.location = {'locationId': locationIds[0]}
                            if(staffIds.length === 1)
                                object.staff = {'professionalId': staffIds[0]}
                        }
                    } else if (apptTimes.length === 1) {
                        this.setAdditionalGuestsCapacity(apptTimes[0]);
                        if((object.location === undefined || object.location === null) && apptTimes[0].locationId !== null && apptTimes[0].locationId !== undefined)
                            object.location = {'locationId': apptTimes[0].locationId}
                        if(object.staff === undefined || object.staff === null && apptTimes[0].professionalId !== null && apptTimes[0].professionalId !== undefined){
                            object.staff = {'professionalId': apptTimes[0].professionalId}
                        }
                        this.earliestApptDate = apptTimes[0].clientStartTimeDate;
                        for (let prop in apptTimes[0]) {
                            switch (prop) {
                                case 'clientEndDate':
                                case 'clientStartDate':
                                    object[prop] = this.datePipe.transform(apptTimes[0][prop], 'yyyy-MM-dd', '');
                                    break;
                                case 'recurringScheduleId':
                                    object['recurringSchedule'] = {'recurringScheduleId': apptTimes[0][prop]};
                                default:
                                    object[prop] = apptTimes[0][prop];
                            }
                        }
                    }//otherwise they are just booking waitlist times
                    break;

            }
        }
        // LHB 10/13/2020 TT-7072
        if (!object.location) {
            object.location = new Location();
            object.location.locationName = null;
            object.location.wasUndefined = true; // LHB 10/21/2020 TT-7092
        }
        if (!object.location.timezone &&
            (!object.locationGroup || !object.locationGroup.timezone) &&
            (!object.staff || !object.staff.timezone))
            object.location.timezone = this.CSFilterObj.businessTimeZone; // LHB 10/12/2020 TT-7072
        else if (object.location.wasUndefined)
            object.location = undefined; // LHB 10/21/2020 TT-7092
        if(this.preference.maxAdditionalGuests > 0 && (object.reason.reasonType === 'SERVICE' || object.hideGuestOption || this.additionalGuestsCapacity === 0)){
            // GO THROUGH AND SET SHOWONSCHEDULER PROPERTY ADDITIONAL GUESTS ON FIELD DEFN LIST TO FALSE
            for(let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++){
                if(this.preference.schedulerPreferenceFieldDefnList[i].code === 'additionalGuests'){
                    this.preference.schedulerPreferenceFieldDefnList[i].showOnScheduler = false;
                }
            }
        } else {//Go through and update field list value for the field to only have as many options as there are seats left available in the class
            if(this.additionalGuestsCapacity > this.preference.maxAdditionalGuests){
                this.additionalGuestsCapacity = this.preference.maxAdditionalGuests;
            }
            for(let i = 0, x = this.preference.schedulerPreferenceFieldDefnList.length; i < x; i++){
                if(this.preference.schedulerPreferenceFieldDefnList[i].code === 'additionalGuests'){
                    this.preference.schedulerPreferenceFieldDefnList[i].fieldValues = this.clientInfoService.createAdditionalGuestsFieldValues(this.additionalGuestsCapacity);
                    this.preference.schedulerPreferenceFieldDefnList[i].dropdownValues = this.preference.schedulerPreferenceFieldDefnList[i].fieldValues.split(/\n/);
                    this.preference.schedulerPreferenceFieldDefnList[i].showOnScheduler = true;
                }
            }
        }
        return object;
    }

    setUpRepeatingAppt(apptTimes: SelectedDateTimeObj[], waitListTimes: SelectedDateTimeObj[]){
        this.recurringAppointment = new RecurringAppointment( null,
            this.preference.businessId,
            null,
            null,
            null,
            null,
            true,
            [],
            null,
            null);
        this.recurringAppointment = this.addFieldsToObjects(this.recurringAppointment, apptTimes);
        this.selectedReason = this.recurringAppointment.reason;
        let startDate: string | Date = null;
        let endDate: string | Date = null;

        if(this.CSFilterObj.coupon !== undefined && this.CSFilterObj.coupon !== null){
            this.recurringAppointment.coupon = this.CSFilterObj.coupon;
        }

        if(this.recurringAppointment.timeSlotList !== undefined) {// For course sets, recurringAppointments won't have a timeSlotList so need to prevent this loop
            for (let i = 0, x = this.recurringAppointment.timeSlotList.length; i < x; i++) {
                if (startDate === null) {
                    startDate = this.recurringAppointment.timeSlotList[i].clientStartDate;
                } else if (startDate > this.recurringAppointment.timeSlotList[i].clientStartDate) {
                    startDate = this.recurringAppointment.timeSlotList[i].clientStartDate;
                }
                if (endDate === null) {
                    endDate = this.recurringAppointment.timeSlotList[i].clientEndDate;
                } else if (endDate < this.recurringAppointment.timeSlotList[i].clientEndDate) {
                    endDate = this.recurringAppointment.timeSlotList[i].clientEndDate;
                }
            }
        }
        this.recurringAppointment.startDate = this.datePipe.transform(startDate, 'yyyy-MM-dd', 'UTC');
        this.recurringAppointment.endDate = this.datePipe.transform(endDate, 'yyyy-MM-dd', 'UTC');
        this.infoPnlBookingSummary = this.tagsService.convertRepeatingApptPropertiesToTags( 'infoPnlBookingSummary', this.preference, this.recurringAppointment, null);
        if(waitListTimes.length > 0){
            this.setUpWaitList(waitListTimes, this.recurringAppointment);
        }
        if (this.appt && this.appt.location && this.appt.location.wasUndefined) // LHB 10/21/2020 TT-7092
            this.appt.location = undefined;
    }

    setUpWaitList(waitListTimes: SelectedDateTimeObj[], bookingObject: any){
        this.selectedReason = bookingObject.reason;
        if(bookingObject !== undefined){
            let waitList = new WaitList();
            waitList.businessId = this.preference.businessId;
            waitList.status = 'OPEN';
            waitList.staff = bookingObject.staff;
            waitList.location = bookingObject.location;
            if(waitList.location && waitList.location.variableAddress) // LHB 04/04/2022 TT-8566
                waitList.address = waitList.location.variableAddress;
            waitList.reason = bookingObject.reason;
            waitList.fields = [];
            waitList.waitListDateList = [];
            waitList.waitListMode = true;
            let waitListDates = [];
            for(let i = 0, x = waitListTimes.length; i < x; i++){
                let year = Number(this.timeService.getPipeYearFromDate(waitListTimes[i].clientStartTimeDate));
                let month = Number(this.timeService.getPipeMonthFromDate(waitListTimes[i].clientStartTimeDate)) - 1;
                let day = Number(this.timeService.getPipeDayFromDate(waitListTimes[i].clientStartTimeDate));
                let waitListDateTime = new WaitListDateTime(waitListTimes[i].clientStartTime, waitListTimes[i].clientEndTime);
                if(bookingObject.reason.reasonType === 'CLASS' && !waitListTimes[i].isWaitListFirstAvail)
                    waitListDateTime.classScheduleId = waitListTimes[i].classScheduleId;
                if(!waitListTimes[i].isWaitListFirstAvail){
                    waitListDateTime.clientStartTimeForDisplay = waitListTimes[i].clientStartTimeForDisplay;
                    waitListDateTime.clientEndTimeForDisplay = waitListTimes[i].clientEndTimeForDisplay;
                    if (waitListTimes[i].startDateTimeUTC) {
                        waitListDateTime.startDateTimeUTC = waitListTimes[i].startDateTimeUTC;
                        if (waitListDateTime.startDateTimeUTC instanceof Date)
                            waitListDateTime.startDateTimeUTC = waitListDateTime.startDateTimeUTC.getTime();
                    }
                    if (waitListTimes[i].endDateTimeUTC) {
                        waitListDateTime.endDateTimeUTC = waitListTimes[i].endDateTimeUTC;
                        if (waitListDateTime.endDateTimeUTC instanceof Date)
                            waitListDateTime.endDateTimeUTC = waitListDateTime.endDateTimeUTC.getTime();
                    }
                    let dateToAdd = new Date(year, month, day).getTime();
                    let dateFound = false;
                    for(let j = 0, y = waitList.waitListDateList.length; j < y; j++){
                        if(waitList.waitListDateList[j].date === dateToAdd){
                            waitList.waitListDateList[j].waitListDateTimeList.push(waitListDateTime);
                            dateFound = true;
                            break;
                        }
                    }
                    if(!dateFound){
                        waitListDates.push(dateToAdd);
                        let waitListDate = new WaitListDate(dateToAdd)
                        if (bookingObject.reason && bookingObject.reason.reasonType === ReasonTypes.COURSE)
                            waitListDate.recurringScheduleId = waitListTimes[i].recurringScheduleId;
                        waitListDate.waitListDateTimeList.push(waitListDateTime);
                        waitList.waitListDateList.push(waitListDate);
                    }
                } else {//Add in waitlist first available slot
                    let waitListDate = new WaitListDate(null);
                    if (bookingObject.reason && bookingObject.reason.reasonType === ReasonTypes.COURSE)
                        waitListDate.recurringScheduleId = waitListTimes[i].recurringScheduleId;
                    waitListDate.waitListDateTimeList = [waitListDateTime];
                    waitList.waitListDateList.push(waitListDate);
                }
            }
            this.waitLists.push(waitList);
        }
        if (this.waitLists && this.waitLists.length > 0) {// LHB TT-3921 12/3/2021
            for (let i = 0, x = this.waitLists.length; i < x; i++)
                if (this.waitLists[i].reason && this.waitLists[i].reason.reasonType === ReasonTypes.COURSE && this.waitLists[i].waitListDateList && this.waitLists[i].waitListDateList.length > 0)
                    for (let j = 0, y = this.waitLists[i].waitListDateList.length; j < y; j++)
                        if (!this.waitLists[i].waitListDateList[j].waitListDateTimeList || (this.waitLists[i].waitListDateList[j].waitListDateTimeList && this.waitLists[i].waitListDateList[j].waitListDateTimeList.length > 0))
                            this.waitLists[i].waitListDateList[j].waitListDateTimeList = []
        }
        let waitlistBookingSummary = this.tagsService.convertWaitListPropertiesToTags('infoBookingSummaryWaitList', this.preference, this.waitLists, this.waitLists[0]);
        if(this.infoPnlBookingSummary === null){
            this.infoPnlBookingSummary = waitlistBookingSummary;
        } else {
            this.infoPnlBookingSummary = this.infoPnlBookingSummary + '<br><br>' + waitlistBookingSummary;
        }
        for (let i = 0, x = this.waitLists.length; i < x; i++)
            if (this.waitLists[i].location !== undefined && this.waitLists[i].location !== null && this.waitLists[i].location.wasUndefined) // LHB 10/21/2020 TT-7092
                this.waitLists[i].location = undefined;
    }

    setUpSingleAppt(apptTimes: SelectedDateTimeObj[], waitListTimes: SelectedDateTimeObj[]){
        this.appt = new Appointment();
        this.appt.businessId = this.preference.businessId
        this.appt = this.addFieldsToObjects(this.appt, apptTimes);
        this.selectedReason = this.appt.reason;
        //ULTIMATELY THESE VALUES WILL GET SET ON THE BACKEND;
        this.appt.startDate = this.appt.clientStartDate;
        this.appt.endDate = this.appt.clientEndDate;

        this.appt.apptDurationMinutesClient = this.getDuration(this.appt.clientStartTimeForDisplay, this.appt.clientEndTimeForDisplay);

        this.appt.sendConfirmationToStaff=true;
        this.appt.sendConfirmationToClient=true;
        if(this.CSFilterObj.coupon !== undefined && this.CSFilterObj.coupon !== null){
            this.appt.coupon = this.CSFilterObj.coupon;
        }


        if((this.businessService.requireStaffConfirmation && this.appt.reason.requireStaffConfirmation === null) || this.appt.reason.requireStaffConfirmation){
            this.appt.status='PENDING_CONFIRMATION';
            this.appt.subStatus='PENDING_CONFIRMATION';
        } else if(this.schedulerPreferenceService.schedulerPreference.acceptPaymentOnBooking && this.appt.price !== 0 && (this.appt.reason.requirePreAuthPayment || this.appt.reason.requirePayment)){
            this.appt.status='PENDING';
            this.appt.subStatus='PENDING';
        }
        if(waitListTimes.length > 0 && apptTimes.length === 0)
            this.appt.waitListMode = true;
        else {
            this.infoPnlBookingSummary = this.tagsService.convertApptPropertiesToTags('infoPnlBookingSummary', this.preference, this.appt);
            if (this.infoPnlBookingSummary && this.infoPnlBookingSummary.indexOf("%APPT_DATE_LIST%") !== -1) {
                let apptDateListTag = this.tagsService.convertApptPropertiesToTags('appointmentDateTimeList', this.preference, this.appt);
                this.infoPnlBookingSummary = this.infoPnlBookingSummary.replace("%APPT_DATE_LIST%", apptDateListTag);
            }
        }
        if(waitListTimes.length > 0)
            this.setUpWaitList(waitListTimes, this.appt)
        if (this.appt.location !== undefined && this.appt.location !== null && this.appt.location.wasUndefined) // LHB 10/21/2020 TT-7092
            this.appt.location = undefined;
    }

    //expects two times expressed in military time in a long i.e. 1430
    getDuration(before: number, after: number) {
        let beforeHours = Math.floor(before / 100);
        let afterHours = Math.floor(after / 100);
        let beforeMinutes = beforeHours * 60 + before % 100;
        let afterMinutes = afterHours * 60 + after % 100;
        let duration = afterMinutes - beforeMinutes;

        //in case the appointment spans across to the next day
        if (duration < 0) {
            duration += (24 * 60);
        }
        return duration;
    }

    checkDateFields(appt: any) {
        if (appt.clientStartTime < appt.clientEndTime &&
            appt.clientStartDate !== appt.clientEndDate &&
            this.addDaysToStringDate(appt.startDate, 1) === appt.endDate) {
            appt.clientEndDate = this.addDaysToStringDate(appt.endDate, -1);
        }
    }

    addDaysToStringDate(endDate: string, days: number): string {
        let newEndDate: Date = new Date(endDate)
        newEndDate.setDate(newEndDate.getDate() + days);
        let newEndDateString: string = newEndDate.toISOString().split('T')[0]
        return newEndDateString;
    }



    ngOnInit() {
        this.responsiveService.setHeightForEmbedScheduler();
        let timesCount = 0;
        let waitListTimes = [];
        let apptTimes = [];
        let reasonTypeIsCourse = false;
        let reasonId = null;
        let reasonType = null;
        for(let i = 0, x = this.inputValues.length; i < x; i++) {
            if(this.inputValues[i].panel === "reasons"){
                for(let j = 0, y = this.inputValues[i].values.length; j < y; j++){
                    reasonId = this.inputValues[i].values[j].value.reasonId;
                    reasonType = this.inputValues[i].values[j].value.reasonType;
                    if(this.inputValues[i].values[j].value.reasonType === 'COURSE'){
                        reasonTypeIsCourse = true;
                    }
                }
            }
            if(this.inputValues[i].panel === 'time') {
                for(let j = 0, y = this.inputValues[i].values.length; j < y; j++){
                    timesCount++;
                    if(this.inputValues[i].values[j].value.waitListMode){
                        waitListTimes.push(this.inputValues[i].values[j].value);
                    } else {
                        apptTimes.push(this.inputValues[i].values[j].value);
                    }
                }
            }
        }
        // console.log('this.inputValues', this.inputValues);
        // console.log('apptTimes', apptTimes);
        // console.log('waitListTimes', waitListTimes);
        let waitListOnly: boolean = false;
        if ((!apptTimes || apptTimes.length === 0) && waitListTimes && waitListTimes.length > 0) // [TT-5495] LHB 04/12/2022
            waitListOnly = true;
        // this.clientInfoService.printPreferenceId(this.preference.schedulerPreferenceFieldDefnList, 27523);
        this.preference.schedulerPreferenceFieldDefnList = this.clientInfoService.initialConfigurationOfSchedulerFields(this.preference.schedulerPreferenceFieldDefnListOriginal, this.preference, this.preference.schedulerPreferenceFieldDefnList, waitListOnly);
        this.panel.formGroup = this.schedulerService.getPanelForm(SchedulerPanelValues.clientInfo, this.preference.schedulerPreferenceFieldDefnList, reasonId, reasonType)
        this.clientInfoForm = this.panel.formGroup;
        this.schedulerPreferenceService.setValuesBasedOnParametersPassed(this.preference, this.clientInfoForm);
        // this.clientInfoService.printPreferenceId(this.preference.schedulerPreferenceFieldDefnList, 27523);
        if(reasonTypeIsCourse){
            this.setUpRepeatingAppt(apptTimes, waitListTimes);
        } else if(apptTimes.length > 1){
            this.setUpRepeatingAppt(apptTimes, waitListTimes);
        } else {
            this.setUpSingleAppt(apptTimes, waitListTimes);
        }
        setTimeout(() => {
            let badge = document.getElementsByClassName('grecaptcha-badge') as HTMLCollectionOf<HTMLElement>;
            if (badge && badge.length > 0 && this.preference.allowRecaptcha) {
                badge[0].style.visibility = 'visible';
            }
        }, 1000);

    }

}
