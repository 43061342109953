import { Injectable } from "@angular/core";
import * as $ from 'jquery';
import {AbstractControl, FormControl} from '@angular/forms';

@Injectable()
export class FormFunctionsService {

    printElement(elem: any, append?: boolean, delimiter?: any) {
        let domClone = elem.cloneNode(true);
        let printSection = $('<div id="printSection"></div>').appendTo(document.body);
        let printBody = $('<div></div>');
        printSection.append(printBody);
        printBody.css({
            minWidth: '800px', display: 'block', marginLeft: 'auto', marginRight: 'auto'});

        // $('login-register-btns').css({top: topPositionForLoginBtns});
        if (append !== true) {
            printBody.html("");
        } else if (append === true) {
            if (typeof(delimiter) === "string") {
                $(delimiter).appendTo(printBody);
            } else if (typeof(delimiter) === "object") {
                printBody.append(delimiter);
            }
        }
        printSection.append(domClone);
    };

    hidePrintElement(){
        $('#printSection').css({display: 'none'})
    }

    setMultiCheckboxValue(value: string, labelToAdd: string, event: any){
        //TT-6445 -- Using labelToAdd and replacing comma with %2C
        labelToAdd = labelToAdd.replace(/,/g, '%2C');
        const target = event.target as HTMLInputElement;

        if((event && event.checked) || (target && target.checked)) {
            if (value === null) {
                value = labelToAdd;
            } else {
                value = value + ',' + labelToAdd;
            }
        } else {
            value = value.replace(labelToAdd, '');
            if(value.indexOf(',,')!==-1){
                value = value.replace(',,', ',');
            }
            let numberOfCommas = value.replace(/[^,]/g, '').length;
            if(numberOfCommas === 1 && value.indexOf(',')===0){
                value =  value.replace(',', '');
            }
            if(value === ''){
                value = null;
            }
        }
        if(value !== null) {
            // remove comma that is at the end if there is one
            let indexOfLastCharacter = value.length - 1;
            if(value.substring(indexOfLastCharacter) === ","){
                value = value.substring(0, indexOfLastCharacter);
            }
        }
        return value;
    }

    getCheckedValues(storedFieldValue: string){
        let checkedValuesArray = [];
        if(storedFieldValue === null){
            //do nothing
        } else if(storedFieldValue.indexOf(',') === -1){//JUST 1 VALUE SO PUSH IT TO checkedValuesArray
            checkedValuesArray.push(storedFieldValue);
        } else {
            checkedValuesArray = storedFieldValue.split(',');
        }
        if(checkedValuesArray.length !== 0){//TT-4290
            for(let a = 0, x = checkedValuesArray.length; a < x; a++){
                if(checkedValuesArray[a].indexOf('%2C')!==-1){
                    checkedValuesArray[a] = checkedValuesArray[a].replace(/%2C/g, ',');
                }
                checkedValuesArray[a] = checkedValuesArray[a].replace(/&amp;/g, '&');
                checkedValuesArray[a] = checkedValuesArray[a].trim();
            }
        }
        return checkedValuesArray;
    }

    checkRequired(displayedFormControl: FormControl): boolean {
        if (displayedFormControl.validator === null)
            return false;
        else {
            const validator = displayedFormControl.validator({} as AbstractControl);
            return validator && validator.required;
        }
    }

    renderField(displayedFormControl: FormControl, passedControl: FormControl): FormControl {
        displayedFormControl.setValue(passedControl.value);
        if (passedControl.disabled)
            displayedFormControl.disable();
        else if (passedControl.enabled)
            displayedFormControl.enable();
        if (passedControl.validator)
            displayedFormControl.setValidators(passedControl.validator);
        displayedFormControl.setErrors(passedControl.errors);
        if (passedControl.touched)
            displayedFormControl.markAsTouched();
        return displayedFormControl;
    }
}
