import {Component, Inject, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ClientAccount, preference} from "../../common-classes/app-objects.model";
import {AuthService} from "../auth.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {BusinessService} from "../../business.service";
import {ResponsiveService} from "../../responsive.service";
import {GoogleAnalyticsService} from "../../google-analytics.service";
import {TitleCasePipe} from "@angular/common";
import {ConfigListService} from '../../config-list.service';
import {ResetPasswordComponent, ResetPasswordModalData} from "../reset-password/reset-password.component";
import {AppComponent} from "../../app.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
    styleUrls: ['../../form-field-styles.component.css']
})
export class LoginComponent implements OnInit {
  loggingIn: boolean = false;
  loginFailed: boolean = false;
  noClientRecordsFound: boolean = false;
  preference: preference;
  email: string;
  loadMyAccount: boolean;
  description: string = null;
  showRegisterBtn: boolean;
  loginModalObj = {isAuthenticated: false, email: '', resetPwd: false, needAccount: false, forcePasswordReset: false, accountLocked: false}
  constructor(public authService: AuthService, public businessService: BusinessService, private responsiveService: ResponsiveService,
              @Inject(MAT_DIALOG_DATA) private passedData: any, private googleAnalyticsService: GoogleAnalyticsService, private titleCasePipe: TitleCasePipe,
              public dialogRef: MatDialogRef<LoginComponent>, private configListService: ConfigListService) { }

  ngOnInit() {
      this.preference = this.passedData.preference;
      if(this.passedData.email){
        this.email = this.passedData.email;
      }
      if(this.passedData.description){
          this.description = this.passedData.description;
      }
      if(this.passedData.loadMyAccount !== undefined){
          this.loadMyAccount = this.passedData.loadMyAccount;
      } else {
          this.loadMyAccount = true;
      }
      let showBtns = this.responsiveService.showLoginRegisterBtnLogic(this.preference);
      this.showRegisterBtn = showBtns.showRegisterBtn;
  }
  onSubmit(form: NgForm){
      if(form.valid) {
          this.loggingIn = true;
          let clientLoginObject: ClientAccount = {'emailAddress': form.value.email, 'password': form.value.password};
          this.authService.clientLogin(clientLoginObject)
              .subscribe(
                  (loggedInClient: ClientAccount) => {
                      this.loggingIn = false;
                      if(loggedInClient.clientList.length === 0){
                          this.noClientRecordsFound = true;
                      } else {
                          this.loginFailed = false;
                          let clientLabel = this.configListService.getTitleCaseLabel('clientLabel');
                          let loginLabel = clientLabel + " login " + loggedInClient.emailAddress + " at " + new Date();
                          this.googleAnalyticsService.eventEmitter(loginLabel, "engagement", "login", loginLabel, 1);
                          this.authService.authenticatedSuccessfully(loggedInClient, this.loadMyAccount);
                          this.loginModalObj.isAuthenticated = true;
                          this.dialogRef.close(this.loginModalObj);
                      }
                  },
                  error => {
                      this.loggingIn = false;
                      this.loginFailed = true;
                      //email and username match, but account needs to reset password
                      if (error === 'RESET') {
                        this.forcePasswordReset(form);
                      }
                      if (error === 'LOCKED') {
                          this.loginModalObj.accountLocked = true;
                          this.forgotPassword(form);
                      }
                  }
              );
      }
  }

  forgotPassword(form: NgForm){
      this.loginModalObj.email = form.value.email;
      this.loginModalObj.resetPwd = true;
      this.loginModalObj.needAccount = false;
      this.dialogRef.close(this.loginModalObj);
  }

  openRegister(form: NgForm){
      this.loginModalObj.email = form.value.email;
      this.loginModalObj.resetPwd = false;
      this.loginModalObj.needAccount = true;
      this.dialogRef.close(this.loginModalObj);
  }

  forcePasswordReset(form: NgForm) {
      this.loginModalObj.email = form.value.email;
      this.loginModalObj.forcePasswordReset = true;
      this.dialogRef.close(this.loginModalObj);
  }
}
