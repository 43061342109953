import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerBannerListItemsComponent } from './scheduler-banner-list-items.component';
import {MaterialModule} from '../../../material.module';
import {PipesModule} from '../../../../pipes/pipes.module';



@NgModule({
  declarations: [SchedulerBannerListItemsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule
  ],
  exports: [SchedulerBannerListItemsComponent]
})
export class SchedulerBannerListItemsModule { }
