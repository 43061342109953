import { AfterViewChecked, Component, OnInit } from "@angular/core";
import { ResponsiveService } from "../../../responsive.service";
import {
  Client,
  ClientAccount,
  preference,
} from "../../../common-classes/app-objects.model";
import { SchedulerPreferenceService } from "../../../scheduler-preference.service";
import * as moment from "moment";
import { BusinessService } from "../../../business.service";
import { ClientAccountService } from "../../client-account.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentIntent } from "../../../../models/AppointmentIntent.model";

@Component({
  selector: "app-view-appointment-intents-list",
  templateUrl: "./view-appointment-intents-list.component.html",
})
export class ViewAppointmentIntentsListComponent
  implements OnInit, AfterViewChecked
{
  intents: AppointmentIntent[];
  pageLoaded = true;
  dataLoaded = false;
  errorLoading = false;
  loadingData = false;
  preference: preference;
  clientAccount: ClientAccount;
  selectedClient: Client;
  titleBarProp: string = 'viewApptIntentTitle';
  noIntentsFoundProp: string = 'noAppointmentIntentError';
  

  constructor(
    private businessService: BusinessService,
    private responsiveService: ResponsiveService,
    private schedulerPreferenceService: SchedulerPreferenceService,
    private clientAccountService: ClientAccountService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.preference = this.schedulerPreferenceService.schedulerPreference;
    moment.locale(sessionStorage.getItem("locale"));
  }

  goBack() {
    this.router.navigate(["/my-account"], { relativeTo: this.route.root });
  }

  selectClient(changeEvent: any) {
    let client = changeEvent.client;
    let event = changeEvent.event;
    if (client.clientId !== this.selectedClient.clientId && event.isUserInput) {
      this.selectedClient = client;
      this.clientAccountService.selectedClient = this.selectedClient;
      this.getIntents();
    }
  }

  /***
   * Get Appointment Intents
   */
  getIntents() {
    this.loadingData = true;
    
    this.pageLoaded = true;
    this.clientAccountService.getAppointmentIntentsByClientId(this.selectedClient.clientId, false)
        .subscribe((appointmentIntents: AppointmentIntent[]) => {
            this.intents = appointmentIntents;
            this.dataLoaded = true;
            this.loadingData = false;
            this.errorLoading = false;
        }, error => {
            this.dataLoaded = false;
            this.loadingData = false;
            this.errorLoading = true;
        });
  }

  ngOnInit() {
    if (this.businessService.business) {
      if (this.businessService.business.status !== "ACTIVE") {
        this.dataLoaded = false;
        this.errorLoading = true;
      } else {
        // get selected client
        this.clientAccount = this.clientAccountService.loggedInClient;
        if (
          this.clientAccountService.selectedClient !== undefined &&
          this.clientAccountService.selectedClient !== null
        ) {
          for (
            let i = 0, x = this.clientAccount.clientList.length;
            i < x;
            i++
          ) {
            if (
              this.clientAccount.clientList[i].clientId ===
              this.clientAccountService.selectedClient.clientId
            ) {
              this.selectedClient = this.clientAccount.clientList[i];
            }
          }
        } else {
          this.selectedClient =
            this.clientAccountService.selectClientBasedOnLoggedInEmail();
          this.clientAccountService.setSelectedClient(
            this.selectedClient,
            this.preference.schedulerPreferenceFieldDefnList
          );
        }
        if (
          this.selectedClient === undefined &&
          this.clientAccountService.loggedInClient !== undefined
        ) {
          this.selectedClient =
            this.clientAccountService.loggedInClient.clientList[0];
        }
        if (this.selectedClient.timeZoneCode === null) {
          this.selectedClient.timeZoneCode = {
            timeZoneCode: sessionStorage.getItem("timezone"),
          };
        }
        this.getIntents();
      }
    } else {
      this.dataLoaded = false;
      this.errorLoading = true;
    }
  }

  ngAfterViewChecked(): void {
    this.responsiveService.setHeightForEmbedAccount();
  }
}
