import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    movePanels, preference
} from "../../common-classes/app-objects.model";
import {BusinessService} from "../../business.service";
import {ResponsiveService} from "../../responsive.service";
import {MatDialog} from "@angular/material/dialog";
import {ErrorModalComponent} from "../../modals/error-modal/error-modal.component";
import {SessionService} from '../../session.service';
import {schedulerPanel} from '../../../models/SchedulerPanel.model';

@Component({
  selector: 'welcome-panel',
  templateUrl: './welcome.component.html'
})
export class WelcomeComponent implements OnInit {
  @Output() onMovePanels = new EventEmitter<movePanels>();
  @Input() preference: preference;
  @Input() panel: schedulerPanel;
  @Input() errorLoading: boolean;
  showBackButton: boolean = false;
  showPoweredByTimeTap: boolean = false;
  errorMessage: string;
  constructor(private businessService: BusinessService, private responsiveService: ResponsiveService, private dialog: MatDialog, private sessionService: SessionService) { }

  configureErrorsForInvitation(counter: number){
      if(counter < 10 && !this.sessionService.sessionObject.parmInvitationUUId){
          setTimeout(() => {
              counter++;
              this.configureErrorsForInvitation(counter);
          },200);
      } else {
          let errors = {'noInvitation': true, 'invitationExpired': true, 'invitationCompleted': true};
          if (this.sessionService.sessionObject.parmInvitationUUId &&
              this.sessionService.sessionObject.parmInvitationUUId !== "") {
              errors.noInvitation = false;
              if (!this.sessionService.sessionObject.invitationExpired)
                  errors.invitationExpired = false;
              if (!this.sessionService.sessionObject.invitationCompleted)
                  errors.invitationCompleted = false;
              // console.log("errors " + JSON.stringify(errors));
              if (errors.invitationExpired || errors.invitationCompleted) {
                  this.panel.formGroup.controls['allowedToBook'].setValue(false);
                  const dialogRef = this.dialog.open(ErrorModalComponent, {
                      maxWidth: this.responsiveService.getMaxWidthForModals(),
                      data: {preference: this.preference, errorType: 'invitationCampaignErrors', errorData: errors}
                  })
              } else {
                  this.panel.formGroup.controls['allowedToBook'].setValue(true);
              }
          } else {
              this.panel.formGroup.controls['allowedToBook'].setValue(false);
              this.panel.formGroup.controls['allowedToBook'].setErrors({'noInvitationId': true});
              const dialogRef = this.dialog.open(ErrorModalComponent, {
                  maxWidth: this.responsiveService.getMaxWidthForModals(),
                  data: {preference: this.preference, errorType: 'invitationCampaignErrors', errorData: errors}
              })
          }
      }
  }

  ngOnInit() {
    if(this.preference !== undefined && this.preference.onlyAllowBookingByInvitation){
        this.configureErrorsForInvitation(0);
    } else if(this.panel !== undefined) {
        this.panel.formGroup.controls['allowedToBook'].setValue(true);
    }
    if(this.responsiveService.isEmbedded){
      this.showPoweredByTimeTap = true;
    }
    this.responsiveService.setHeightForEmbedScheduler();
  }

  movePanels(direction: string){
      this.onMovePanels.emit({direction: direction, panel: this.panel})
  }

}
