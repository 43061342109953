import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit, Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {StyleSheetService} from "../styleSheetService";

@Directive({
  selector: '[textFormatting]'
})
export class TextFormattingDirective implements OnInit, OnChanges{
  @Input() textId;
  @Input() textClass;
  @Input() textType;//i.e. h1, h2, div, etc.
  @Input() inheritedColorOverride: string;
  @Input() displayInline: boolean;
  @Input() textAlign: string;
  color: string;
  selector: string;
  fontSize: string;
  constructor(private stylesheetService: StyleSheetService, private elementRef: ElementRef, private renderer: Renderer2) { }

  matchAndApplyStyles() {
    let styleMap = this.stylesheetService.mwStyleMap;
    let tagName = this.elementRef.nativeElement.tagName.toLowerCase();
    let propToMatch = null;
    if(this.textClass !== undefined){
      propToMatch = this.textClass;
    }
    if(this.textId !== undefined){
      propToMatch = this.textId;
    }

    let colorMatchFound = false;
    let fontSizeMatchFound = false;
    if(propToMatch !== null){
      for(let prop in styleMap){
        if(prop === propToMatch){
          if(styleMap[prop].color !== undefined){
            colorMatchFound = true;
            this.color = styleMap[prop].color;
          }
          if(styleMap[prop].fontSize !== undefined){
            fontSizeMatchFound = true;
            this.fontSize = styleMap[prop].fontSize;
          }
        }
      }
    }
    for(let prop in styleMap){
      if(prop === tagName){
        if(!colorMatchFound) {
          if (this.inheritedColorOverride === undefined && styleMap[prop].color !== undefined) {
            colorMatchFound = true;
            this.color = styleMap[prop].color;
          } else if (this.inheritedColorOverride !== undefined){
            colorMatchFound = true;
            this.color = this.inheritedColorOverride;
          }
        }
        if(!fontSizeMatchFound && styleMap[prop].fontSize !== undefined){
          fontSizeMatchFound = true;
          this.fontSize = styleMap[prop].fontSize;
        }
      }
    }
    if(!colorMatchFound && this.inheritedColorOverride !== undefined) {
      this.color = this.inheritedColorOverride;
      colorMatchFound = true;
    }

    if(this.displayInline)
      this.renderer.setStyle(this.elementRef.nativeElement,'display','inline');

    if(this.textAlign)
      this.renderer.setStyle(this.elementRef.nativeElement, 'text-align', this.textAlign);

    if(colorMatchFound) {
      this.elementRef.nativeElement.style.color = this.color;
    }
    if(fontSizeMatchFound) {
      this.elementRef.nativeElement.style.fontSize = this.fontSize;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.textId !== undefined && changes.textId !== null && changes.textId.previousValue !== undefined && changes.textId.previousValue !== null){
      if(changes.textId.previousValue !== changes.textId.currentValue){
        this.matchAndApplyStyles();
      }
    }
    if(changes.textClass !== undefined && changes.textClass !== null && changes.textClass.previousValue !== undefined && changes.textClass.previousValue !== null){
      if(changes.textClass.previousValue !== changes.textClass.currentValue){
        this.matchAndApplyStyles();
      }
    }
  }

  ngOnInit(): void {
    this.matchAndApplyStyles();
  }

}
