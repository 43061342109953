import {NgModule} from "@angular/core";
import {ClientAccountService} from "./client-account/client-account.service";
import {DataTablesService} from "./data-tables.service";
import {DisclaimerFormService} from "./disclaimer-form/disclaimer-form.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {LocaleService} from "./locale.service";
import {TagsService} from "./tags.service";
import {FormFunctionsService} from "./form-functions.service";
import {StoreService} from "./store/store.service";
import {CurrencyPipe, DatePipe, TitleCasePipe} from "@angular/common";
import {WaitlistService} from "./waitlist/waitlist.service";
import {TimeService} from "./scheduler/time/time.service";
import {ClientInfoService} from "./scheduler/client-info/client-info.service";
import {BusinessService} from "./business.service";
import {MomentTimezoneService} from "./scheduler/time/moment-timezones.service";
import {AuthInterceptorService} from "./auth/auth-interceptor.service";
import {GoogleAnalyticsService} from "./google-analytics.service";
import {AuthService} from "./auth/auth.service";
import {ResponsiveService} from "./responsive.service";
import {LabelMapService} from "./common-classes/label-map.service";
import {StyleSheetService} from "./styleSheetService";

@NgModule({
    providers: [
        LocaleService, BusinessService, StoreService, WaitlistService, LabelMapService, ResponsiveService, AuthService, TimeService,
        StyleSheetService, MomentTimezoneService, ClientAccountService,
        ClientInfoService, DatePipe, TitleCasePipe, CurrencyPipe, TagsService, DataTablesService, {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
        DisclaimerFormService, FormFunctionsService, GoogleAnalyticsService
    ]
})
export class CoreModule{}