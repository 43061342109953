import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {
  Client,
  MultipleAppts,
  preference
} from "../../../../common-classes/app-objects.model";
import {BusinessService} from "../../../../business.service";
import {ClientAccountService} from "../../../client-account.service";
import {ResponsiveService} from "../../../../responsive.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorModalComponent} from "../../../../modals/error-modal/error-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../../../auth/auth.service";
import {TitleCasePipe} from "@angular/common";
import {WaitlistService} from "../../../../waitlist/waitlist.service";
import {
  CancelAppointmentComponent,
  CancelBookingObjModalData
} from "../../cancel-appointment/cancel-appointment.component";
import {WaitList, WaitlistClaim, WaitlistRegistrationDetail} from '../../../../../models/WaitList.model';
import {SchedulerPreferenceService} from '../../../../scheduler-preference.service';
import {TimeService} from "../../../../scheduler/time/time.service";

@Component({
  selector: 'app-waitlist-registration-detail',
  templateUrl: './waitlist-registration-detail.component.html',
  styleUrls: ['../booking-object-details.component.css']
})
export class WaitlistRegistrationDetailComponent implements OnInit, AfterViewChecked {
  preference: preference;
  selectedClient: Client;
  waitlist: WaitList;
  waitlistClaims: WaitlistClaim[];
  waitlistId: string;
  clientHash: string;
  waitListDateTimeId: string;
  errorLoading: boolean;
  loading: boolean = true;
  loaded: boolean = false;
  status: string;
  chipBaseStyle: string;
  chipClass: string;
  constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService,
              private responsiveService: ResponsiveService, private waitlistService: WaitlistService,
              private schedulerPreferenceService: SchedulerPreferenceService, private timeService: TimeService,
              private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private authService: AuthService, private titleCasePipe: TitleCasePipe) {
    this.preference = schedulerPreferenceService.schedulerPreference;
  }

  goBack() {
    if (this.authService.isAuth()) {
      this.router.navigate(['/my-account/appts'], {relativeTo: this.route.root, state: {listStatus: 'WAITLIST'}})
    } else {
      this.router.navigate(['scheduler'], {relativeTo: this.route.root});
    }
  }

  cancelWaitlist(){
    let dataToPass: CancelBookingObjModalData = new CancelBookingObjModalData();
    dataToPass.waitlist = this.waitlist;
    dataToPass.cancelType = 'WAITLIST';
    dataToPass.preference = this.preference;
    const dialogRef = this.dialog.open(CancelAppointmentComponent,
        {
          maxWidth: this.responsiveService.getMaxWidthForModals(),
          data: dataToPass
        });
    dialogRef.afterClosed().subscribe((cancelledWaitlist: WaitList) => {
      if (cancelledWaitlist !== undefined) {
        this.goBack();
      }
    })
  }

  configureDetail(waitlistRegistrationDetail: WaitlistRegistrationDetail){
    let waitlist = waitlistRegistrationDetail.waitlist;
    if(waitlist === null) {
      const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(),
        data: {preference: this.preference, errorType: 'apptDoesNotBelongToAccount'}})
      dialogRef.afterClosed().subscribe( data => {
        this.goBack();
      })
    } else {
      this.waitlist = waitlist;
      this.waitlistClaims = waitlistRegistrationDetail.waitlistClaimList;
      this.clientHash = waitlist.client.clientHash;
      this.status = this.waitlist.status.replace(new RegExp('_', 'g'), ' ');
      this.status = this.titleCasePipe.transform(this.status);
      switch (this.waitlist.status) {
        case 'OPEN':
          this.chipBaseStyle ='primary';
          this.chipClass ='apptStatusOpenChip';
          break;
        case 'INVITED':
          this.chipBaseStyle ='success';
          this.chipClass ='apptStatusCompletedChip';
          break;
      }
      this.loaded = true;
      this.loading = false;
    }
  }

  getDetail(){
    let waitListIdString = this.route.params.subscribe(params => {
      this.waitlistId = params['waitlistId'];
      this.waitListDateTimeId = params['waitListDateTimeId']
      if(this.waitListDateTimeId === undefined){
        this.waitlistId = sessionStorage.getItem("waitlistRegistrationDetailId");
        this.waitListDateTimeId = sessionStorage.getItem("waitListDateTimeId");
      } else {
        sessionStorage.setItem("waitlistRegistrationDetailId", this.waitlistId);
        sessionStorage.setItem("waitListDateTimeId", this.waitListDateTimeId);
      }
    });

    this.waitlistService.getWaitlistRegistrationDetail(this.waitlistId, this.waitListDateTimeId)
        .subscribe((waitlistRegistrationDetail: WaitlistRegistrationDetail) => {
          this.configureDetail(waitlistRegistrationDetail)
        }, (error: HttpErrorResponse) => {
          this.errorLoading = true;
          this.loading = false;
        })
  }

  ngAfterViewChecked(){
    this.responsiveService.setHeightForEmbedAccount();
  }

  ngOnInit() {
      this.selectedClient = this.clientAccountService.getSelectedClient(this.preference);
      this.getDetail();
  }

}
