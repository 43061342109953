import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromptPackagePayComponent } from './prompt-package-pay.component';
import {MaterialModule} from '../../../../material.module';



@NgModule({
  declarations: [PromptPackagePayComponent],
  imports: [
    CommonModule,
      MaterialModule
  ],
  entryComponents: [PromptPackagePayComponent],
  exports: [PromptPackagePayComponent]
})
export class PromptPackagePayModule { }
