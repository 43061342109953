import {Component, Input, OnInit} from '@angular/core';
import {Client, preference} from "../../../common-classes/app-objects.model";
import {ClientAccountService} from "../../client-account.service";
import {TableColumnDataModel} from "../../../common-classes/TableColumnData.model";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {DataTablesService} from "../../../data-tables.service";
import {MatDialog} from "@angular/material/dialog";
import {ResponsiveService} from "../../../responsive.service";
import {ManageCreditCardComponent} from "../../../modals/manage-credit-card/manage-credit-card.component";
import {StoredCardInfo} from "../client-profile-container/client-profile-container.component";
import {CreditCard} from "../../view-invoices/payment-portals/payments.model";

@Component({
  selector: 'client-payment-info',
  templateUrl: './client-payment-info.component.html',
  styleUrls: ['../../../data-tables.component.css']
})
export class ClientPaymentInfoComponent implements OnInit {
  @Input() preference: preference;
  @Input() selectedClient: Client;
  displayedColumns: string[] = this.dataTablesService.getDefaultPaymentInfoColumns();
  columnDefs: TableColumnDataModel[] = this.dataTablesService.getDefaultPaymentInfoColumnDefs();
  creditCardList = new MatTableDataSource<CreditCard>();
  creditCards: CreditCard[] = [];
  private sort: MatSort;

  constructor(private clientAccountService: ClientAccountService, private dataTablesService: DataTablesService,
              private dialog: MatDialog, private responsiveService: ResponsiveService) { }

  deleteCard(creditCard: CreditCard){
    let dataToPass = {client: this.selectedClient, preference: this.preference, path: 'deleteCard', card: creditCard}
    const dialogRef = this.dialog.open(ManageCreditCardComponent,
        {
          maxWidth: this.responsiveService.getMaxWidthForModals(),
          minWidth: this.responsiveService.getMaxWidthForModals(),
          data: dataToPass
        })
    dialogRef.afterClosed().subscribe((updatedData: any) => {
      this.sortStripeSquareIntoCreditCardList();
    })
  }

  addCard(){
    let dataToPass = {client: this.selectedClient, preference: this.preference, path: 'addCard'}
    const dialogRef = this.dialog.open(ManageCreditCardComponent,
        {
          maxWidth: this.responsiveService.getMaxWidthForModals('90vw'),
          minWidth: this.responsiveService.getMaxWidthForModals('90vw'),
          data: dataToPass
        })
    dialogRef.afterClosed().subscribe((updatedData: any) => {
      this.clientAccountService.getStoredPaymentInfoByClientId(this.selectedClient.clientId).subscribe((storedCardInfo: StoredCardInfo) => {
        this.selectedClient = this.clientAccountService.storeCardInfoOnClient(this.selectedClient, storedCardInfo);
        this.sortStripeSquareIntoCreditCardList();
      });

    })
  }

  establishTable(){
    this.creditCardList.data = this.creditCards;
    this.creditCardList.sortingDataAccessor = (obj, property) => this.dataTablesService.getProperty(obj, property);
    this.creditCardList.sort = this.sort;
  }

  sortStripeSquareIntoCreditCardList(){
    this.creditCards = this.clientAccountService.createCreditCardsArray(this.selectedClient);
    this.establishTable();
  }

  ngOnInit() {
    this.sortStripeSquareIntoCreditCardList();
  }

}
