import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {TagsService} from "../../tags.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {preference} from "../../common-classes/app-objects.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TimeService} from "../../scheduler/time/time.service";
import {WeekDayData} from "../../scheduler/time/time-objects.model";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {Reason} from '../../../models/Reason.model';

@Component({
  selector: 'app-repeating-appt-filter',
  templateUrl: './repeating-appt-filter.component.html',
  styleUrls: ['./repeating-appt-filter.component.css']
})
export class RepeatingApptFilterComponent implements OnInit {
  preference: preference;
  reason: Reason;
  repeatingApptFilterModalDescription: string;
  repeatingApptForm = new FormGroup({
    startDate: new FormControl('', Validators.required),
    daysOfTheWeek: new FormControl('')
  })
  minDate: Date;
  maxDate: Date;
  needToSelectWeekdays: boolean = false;
  weekdays: WeekDayData[] = this.timeService.getWeekdayDataObjs();
  constructor(@Inject(MAT_DIALOG_DATA) private passedData: {reason: Reason, preference: preference, startDate: Date, selectedDays: WeekDayData[], minDate: Date, maxDate: Date}, private router: Router,
              public dialogRef: MatDialogRef<RepeatingApptFilterComponent>, private tagsService: TagsService, private timeService: TimeService) {
    dialogRef.backdropClick().subscribe(() =>{
      this.applyFilters('backdropClick');
    })
  }

  ngOnInit() {
    if(this.passedData.reason !== undefined && this.passedData.reason !== null){
      this.reason = this.passedData.reason;
    }
    if(this.passedData.preference !== undefined && this.passedData.preference !== null){
      this.preference = this.passedData.preference;
    }
    if(this.passedData.startDate !== undefined && this.passedData.startDate !== null){
      this.repeatingApptForm.controls['startDate'].setValue(this.passedData.startDate);
      this.minDate = this.passedData.startDate;
    }
    if(this.passedData.minDate !== undefined){
      this.minDate = this.passedData.minDate;
    }
    if(this.passedData.maxDate !== undefined){
      this.maxDate = this.passedData.maxDate;
    }
    if(this.passedData.selectedDays !== undefined && this.passedData.selectedDays !== null && this.passedData.selectedDays.length > 0){
      for(let i = 0, x = this.passedData.selectedDays.length; i < x; i++){
        for(let j = 0, y = this.weekdays.length; j < y; j++){
          if(this.weekdays[j].dayOfWeekInt === this.passedData.selectedDays[i].dayOfWeekInt)
            this.weekdays[j].selected = true;
        }
      }
      let checkEvent: MatCheckboxChange = new MatCheckboxChange();
      checkEvent.checked = true;
      for(let i = 0, x = this.weekdays.length; i < x; i++){
        if(this.weekdays[i].selected)
          this.setWeekdayValue(this.weekdays[i], checkEvent);
      }
    }
    this.repeatingApptFilterModalDescription = this.tagsService.assignObjectToTags(this.reason, this.preference.labelMap.repeatingApptFilterModalDescription, this.tagsService.reasonFieldTags);
    this.repeatingApptFilterModalDescription = this.tagsService.assignObjectToTags(this.reason.selectedRepeatingApptOption, this.repeatingApptFilterModalDescription, this.tagsService.repeatingApptOptionTags);
  }

  applyFilters(path: string){
    if(path === 'backdropClick' && (this.repeatingApptForm.controls['daysOfTheWeek'].value === undefined ||
        this.repeatingApptForm.controls['daysOfTheWeek'].value === null ||
        this.repeatingApptForm.controls['daysOfTheWeek'].value === '')){
      let selectedWeekdays: WeekDayData[] = [];
      for(let i = 0, x = this.weekdays.length; i < x; i++){// if they didn't select any days of the week, just go ahead and select Monday
        if(this.weekdays[i].dayOfWeekInt === 1){
          this.weekdays[i].selected = true;
          selectedWeekdays.push(this.weekdays[i])
          break;
        }
      }
      this.repeatingApptForm.controls['daysOfTheWeek'].setValue(selectedWeekdays);
    }
    let daysHaveBeenSelected = false;
    this.needToSelectWeekdays = false;
    for(let i = 0, x = this.weekdays.length; i < x; i++){
      if(this.weekdays[i].selected)
        daysHaveBeenSelected = true;
    }
    if(daysHaveBeenSelected){
      this.dialogRef.close(this.repeatingApptForm);
    } else {
      this.needToSelectWeekdays = true;
    }

  }

  setWeekdayValue(weekday: WeekDayData, event: MatCheckboxChange){
    if(event.checked)
      weekday.selected = true;
    else
      weekday.selected = false;
    let selectedWeekdays: WeekDayData[] = [];
    for(let i = 0, x = this.weekdays.length; i < x; i++){
      if(this.weekdays[i].selected)
        selectedWeekdays.push(this.weekdays[i]);
    }
    this.repeatingApptForm.controls['daysOfTheWeek'].setValue(selectedWeekdays);
  }

}
