import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {ClientInfoService} from "../scheduler/client-info/client-info.service";
import {StyleSheetService} from "../styleSheetService";
import {map} from "rxjs/operators";
import {catchError} from "rxjs/internal/operators";
import {Package, PackageSold} from "./packages/packages.model";
import {Subject} from "rxjs";
import {Cart, CartItem} from "./cart/cart.model";
import {Coupon, Invoice, InvoiceItem} from "../common-classes/app-objects.model";
import {invoiceItems} from "stripe";

@Injectable()
export class StoreService {
  cartChange = new Subject<boolean>();
  cart: Cart = new Cart();
    applyingCoupon = new Subject<boolean>();
    couponStatus = new Subject<string>();
    couponStatusDetail = new Subject<string>();
    coupon = new Subject<Coupon>();
  private apiUrl = sessionStorage.getItem('csAPIBase') + 'cs/';
  constructor(private http: HttpClient){}

  private handleError(error: any): Promise<any>{
    return Promise.reject(error);
  }

  setCartData(cart: Cart){
      this.cart = cart;
      localStorage.setItem('cart', JSON.stringify(this.cart));
      this.cartChange.next(true);
  }

  addPackageToCart(pkg: Package){
        // console.log(pkg);
        pkg.showAddedToCartSuccess = true;
        let pkgInCart = false;
        // if(this.cart.items !== undefined)
        for(let i = 0, x = this.cart.items.length; i < x; i++){
            if(this.cart.items[i].id === pkg.packageId){
                pkgInCart = true;
                this.cart.items[i].quantityInCart = this.cart.items[i].quantityInCart + 1;
                this.cart.items[i] = this.cart.items[i].calculateTotalPrice();
            }
        }
        if(!pkgInCart){
            let item: CartItem = new CartItem();
            item = item.createPackageItem(pkg);
            item.quantityInCart = 1;
            item = item.calculateTotalPrice();
            // item.id = pkg.packageId;
            // item.type = CartItem.TYPE_PKG;
            // item.name = pkg.packageName;
            // console.log(item);
            this.cart.items.push(item);
        }
        // console.log(this.cart.items);

        this.setCartData(this.cart);
        setTimeout(() => {
            pkg.showAddedToCartSuccess = false;
        },2000);
  }

  getPackages(){
    return this.http
        .get<Package[]>(this.apiUrl + 'packages', {observe: "body", responseType: 'json'})
        .pipe(
            map((responseData: Package[]) => {
              responseData = responseData.sort((a, b) => (a.sortOrder < b.sortOrder) ? -1 : 1);
              return responseData;
            }),
            catchError(this.handleError)
        )
  }

  loadCart(businessId: number){
      // localStorage.removeItem('cart');
      let cartFromMemory = localStorage.getItem('cart');
      // console.log("cartFromMemory ")
      // console.log(cartFromMemory);
      if(cartFromMemory !== undefined && cartFromMemory !== null && cartFromMemory !== '') {
          let cart = JSON.parse(cartFromMemory);
          for(let i = 0, x = cart.items.length; i < x; i++){
              if(cart.items[i].businessId === businessId) {
                  let item: CartItem = new CartItem();
                  item = item.createCartItem(cart.items[i]);
                  this.cart.items.push(item);
              }
          }
          this.setCartData(this.cart)
      }
  }

  getCartTotal(invoice: Invoice, couponCode: string){
      let paramsToPass = new HttpParams();
      paramsToPass = paramsToPass.append('couponCode', couponCode);
      return this.http
          .post<Invoice>(this.apiUrl + 'cartTotal', invoice, {observe: "body", responseType: 'json', params: paramsToPass})
          .pipe(
              map((responseData: Invoice) => {
                  return responseData;
              }),
              catchError(this.handleError)
          )
  }

  createInvoice(invoice: Invoice, couponCode: string){
      let paramsToPass = new HttpParams();
      paramsToPass = paramsToPass.append('couponCode', couponCode);
      return this.http
          .post<Invoice>(this.apiUrl + 'invoices', invoice, {observe: "body", responseType: 'json', params: paramsToPass})
          .pipe(
              map((responseData: Invoice) => {
                  return responseData;
              }),
              catchError(this.handleError)
          )
  }

  createPackageSoldObjs(packageItems: PackageSold[]){
      return this.http
          .post<PackageSold[]>(this.apiUrl + 'packageSold', packageItems, {observe: "body", responseType: 'json'})
          .pipe(
              map((responseData: PackageSold[]) => {
                  return responseData;
              }),
              catchError(this.handleError)
          )
  }

  updateCart(): number{
      let itemsInCart = 0;
      for(let i = 0, x = this.cart.items.length; i < x; i++){
          itemsInCart = itemsInCart + this.cart.items[i].quantityInCart;
      }
      return itemsInCart;
  }
}
