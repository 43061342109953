import {Injectable, Injector} from '@angular/core';
import {
  CategoryNamesByCode,
  EmailCategory,
  EmailCategoryNameMap,
  EmailCategoryNameMapObj
} from '../../models/EmailCategory.model';
import {Client, ConfigListMap} from '../common-classes/app-objects.model';
import {TitleCasePipe} from '@angular/common';
import {ApiService} from '../api.service';
import {map} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {FormatFunctionsService} from '../format-functions.service';
import {EmailTemplate} from '../../models/EmailTemplate.model';

@Injectable({
  providedIn: 'root'
})
export class EmailSettingsService {
  emailCategories: EmailCategory[];
  emailTemplates: EmailTemplate[];
  emailCategoryNameMap: EmailCategoryNameMap;
  configListMap: ConfigListMap;
  apiService: ApiService;
  formatFnsService: FormatFunctionsService;
  constructor(private titleCasePipe: TitleCasePipe, private injector: Injector, private http: HttpClient) {
    this.apiService = injector.get(ApiService);
    this.formatFnsService = injector.get(FormatFunctionsService);
  }

  getClientForEmailSettings(clientHash: string) {
    return this.http.get<Client>(this.apiService.apiUrl + 'clientEmails/' + clientHash, {observe: "body", responseType: 'json'})
        .pipe(
            map((responseData: Client) => {
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  updateClientEmails(clientId: number, excludedEmailCategoryIdList: number[], subscribedEmailCategoryIdList: number[], excludedEmailTemplateIdList: number[], subscribedEmailTemplateIdList: number[]) {
    let params = new HttpParams();
    const excludedCategoryIdString = this.formatFnsService.formatArrayIntoCommaSeparatedString(excludedEmailCategoryIdList);
    if (excludedCategoryIdString)
      params = params.append('excludedEmailCategoryIds', excludedCategoryIdString);
    const subscribedEmailCategoryIdString = this.formatFnsService.formatArrayIntoCommaSeparatedString(subscribedEmailCategoryIdList);
    if (subscribedEmailCategoryIdString)
      params = params.append('subscribedEmailCategoryIds', subscribedEmailCategoryIdString);
    const excludedTemplateIdString = this.formatFnsService.formatArrayIntoCommaSeparatedString(excludedEmailTemplateIdList);
    if (excludedTemplateIdString)
      params = params.append('excludedEmailTemplateIds', excludedTemplateIdString);
    const subscribedEmailTemplateIdString = this.formatFnsService.formatArrayIntoCommaSeparatedString(subscribedEmailTemplateIdList);
    if (subscribedEmailTemplateIdString)
      params = params.append('subscribedEmailTemplateIds', subscribedEmailTemplateIdString);

    return this.http.post<Client>(this.apiService.apiUrl + 'clientEmails/' + clientId, null, {observe: "body", responseType: 'json', params})
        .pipe(
            map((responseData: Client) => {
              return responseData;
            }),
            catchError(this.apiService.handleError)
        )
  }

  getEmailCategoryNameMap(onload?: boolean): EmailCategoryNameMap {
    if (onload || this.emailCategoryNameMap === undefined) {
      const clientLabel = this.titleCasePipe.transform(this.configListMap.clientLabel);
      const staffLabel = this.titleCasePipe.transform(this.configListMap.staffLabel);
      const classLabel = this.titleCasePipe.transform(this.configListMap.classLabel);
      const courseLabel = this.titleCasePipe.transform(this.configListMap.courseLabel);
      const categoryNamesByCode: CategoryNamesByCode = new CategoryNamesByCode(clientLabel, staffLabel, classLabel, courseLabel);
      this.emailCategoryNameMap = new EmailCategoryNameMap(categoryNamesByCode);
      return this.emailCategoryNameMap;
    } else
      return this.emailCategoryNameMap;
  }

  mapDisplayNameToEmailCategories(emailCategoryCode: string, emailCategoryNameMap: EmailCategoryNameMap): string {
    for (const prop in emailCategoryNameMap)
      if (emailCategoryNameMap[prop] instanceof EmailCategoryNameMapObj && emailCategoryNameMap[prop].codeName === emailCategoryCode)
        return emailCategoryNameMap[prop].displayName;
    return emailCategoryCode;
  }

  setEmailCategories(emailCategories: EmailCategory[], configListMap: ConfigListMap) {
    this.configListMap = configListMap;
    if (emailCategories) {
      for (let i = 0, x = emailCategories.length; i < x; i++) {
        const emailCategoryNameMap = this.getEmailCategoryNameMap();
        emailCategories[i].emailCategoryDisplayName = this.mapDisplayNameToEmailCategories(emailCategories[i].code, emailCategoryNameMap);
      }
      this.emailCategories = emailCategories;
    } else
      this.emailCategories = [];
  }

  setEmailTemplates(emailTemplates: EmailTemplate[]) {
    if (emailTemplates)
      this.emailTemplates = emailTemplates;
    else
      this.emailTemplates = [];
  }

}
