import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Event, NavigationExtras, ResolveEnd, Router, RouterStateSnapshot} from "@angular/router";

@Component({
  selector: 'app-waitlist',
  templateUrl: './waitlist.component.html'
})
export class WaitlistComponent implements OnInit {
  constructor( private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    //For now, if land on waitlist component without going to claim or remove, redirect to scheduler component
    // console.log("this.router.url " + this.router.url);
    // console.log("this.router ");
    // console.log(this.router);
    // console.log("this.route ");
    // console.log(this.route);
    if(this.router.url.indexOf("claim") === -1 && this.router.url.indexOf("join") === -1 && this.router.url.indexOf("remove") === -1  && this.router.url.indexOf("claimTimes") === -1){
      const navigationExtras: NavigationExtras = {
        relativeTo: this.route.root,
      };
      this.router.navigate(['scheduler'], navigationExtras)
    }

  }

}
