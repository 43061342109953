import { Injectable } from "@angular/core";
import {preference} from "./common-classes/app-objects.model";
import {Location} from '../models/Location.model';
import {Staff} from '../models/Staff.model';
declare let pushToParent:Function;
declare let gtag:Function;

@Injectable()
export class GoogleAnalyticsService {
    public eventEmitter(eventName: string, eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null ){
        let googleAnalyticsCode = sessionStorage.getItem("googleAnalyticsCode");
        if(googleAnalyticsCode !== null && googleAnalyticsCode !== "") {
            gtag('event', eventAction, {
                'event_category': eventCategory,
                'event_label': eventLabel,
                'event_name': eventName
            });
            // in the case that the scheduler is being loaded in an iframe, we need to
            // push the event to the parent window so that the wrapper can do the call to google.
            pushToParent(eventAction, eventCategory, eventLabel, eventName);
        }
    }

    getStaffNameForGoogleAnalyticsPost(staff: Staff, preference: preference){
        let staffName = ""; // LHB 08/12/2020 TT-6902
        if(staff === null)
            staffName = preference.labelMap.labelNoPreference;
        else
            staffName = staff.fullName;
        return staffName;
    }

    getLocationNameForGoogleAnalyticsPost(location: Location){
        let locationName = ""; // LHB 08/12/2020 TT-6902
        if(location === null)
            locationName = "various locations";
        else
            locationName = location.locationName;
        return locationName;
    }
}
