export class Staff {
    timezone: string;
    professionalId: number;
    fullName: string;
    displayName: string;
    staffProfile: string;
    imageUrl: string;
    selected?: boolean;
    showExtendedHeight?: boolean;
    textFloatHeight?: number;
    mobilePhone: string;
    zoomLink: string;

    constructor(professionalId: number, fullName: string) {
        this.professionalId = professionalId;
        this.fullName = fullName;
        this.displayName = fullName;
    }
}
