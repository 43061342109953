import {Group} from './Group.model';
import {CourseSession} from './CourseSession.model';
import {CustomField} from './CustomField.model';
import {DateFactory} from './DateFactory.model';
import {Location} from './Location.model';
import {Staff} from './Staff.model';
import {Reason} from './Reason.model';

export class ClassSession {
    active: boolean;
    additionalStaffIdList: number[];
    allowWalkins: boolean;
    blockStaffIdList: number[];
    blockedStaffList: Staff[];
    businessId: number;
    cancelDate: Date;
    cancelReason: string;
    changeReason: string;
    classScheduleId: number;
    classTZ: string;
    color: string;
    coordinatorStaffId: number;
    courseSession: CourseSession;
    createdDate: Date;
    createdUser: string;
    currentAvailable: number;
    currentCapacity: number;
    currentWaitListCount: number;
    date: Date;
    dateFactory: DateFactory;
    dateId: number;
    dateText: string;
    endDateTime: Date;
    endDateTimeUTC: Date;
    endTime: number;
    fieldDataMap: any;
    fieldDataMapJson: string;
    fields: CustomField[];
    hideGuestOption: boolean;
    location: Location;
    locationGroup: Group;
    locationId: number;
    locationName: string;
    locationSuperGroup: Group;
    maxCapacity: number;
    mini: any;
    modifiedDate: Date;
    modifiedUser: string;
    openSeats: number;
    parentBusinessId: number;
    professionalId: number;
    reason: Reason;
    reasonId: number;
    reasonName: string;
    recurringScheduleId: number;
    resourceId: number;
    roomNumber: string;
    selected?: boolean;
    showOpenSlots: boolean;
    staff: Staff;
    staffName: string;
    staffTimeZone: string;
    startDateTime: Date;
    startDateTimeUTC: Date;
    startTime: number;
    status: string;
    statusCount: number;
    subStatus: string;
    stringDate?: string;
    waitListMode: boolean; // LHB 10/30/2020 TT-7117


    // display only
    startTimeZonedDisplay: any;
    endTimeZonedDisplay: any;


    constructor() {
    }
    copyPropsFromAPI(classSession: ClassSession) {
        for (const prop in classSession)
            this[prop] = classSession[prop];
    }
}
