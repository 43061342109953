import {SquareCard} from "./square/square.component";
import {StripeCard, StripeCardData} from '../../../../models/Stripe/Stripe.model';

export class CreditCard {
    brand: string;
    last4: string;
    expiration: string;
    zip: string;
    stripeInfo: StripeCardData;
    squareInfo: SquareCard;
    constructor() {
    }
}
