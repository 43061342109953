import { Component, OnInit } from '@angular/core';
import {ResponsiveService} from '../responsive.service';

@Component({
  selector: 'app-email-settings-container',
  template: `
    <mat-card id="clientProfile" class="myAccountCard noBorderBottom" backgroundStyling containerClass="myAccountCard" [ngClass]="{'embeddedView': responsiveService.isEmbedded}">
        <router-outlet></router-outlet>
    </mat-card>
  `,
  styles: []
})
export class EmailSettingsContainerComponent implements OnInit {

  constructor(public responsiveService: ResponsiveService) { }

  ngOnInit() {
  }

}
