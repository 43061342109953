import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SchedulerPrefFieldFormComponent} from './scheduler-pref-field-form.component';
import {MaterialModule} from '../material.module';
import {PipesModule} from '../../pipes/pipes.module';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [SchedulerPrefFieldFormComponent],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule
  ],
  exports: [SchedulerPrefFieldFormComponent]
})
export class SchedulerPrefFieldFormModule { }
