import { Injectable} from "@angular/core";
import {Subject} from "rxjs";
import * as $ from 'jquery';
import {preference} from "./common-classes/app-objects.model";
import {ErrorModalComponent} from './modals/error-modal/error-modal.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable()

export class ResponsiveService {
    embeddedScheduler = new Subject<boolean>();
    isEmbedded: boolean = false;
    onMobileViewPort: boolean;
    onTabletViewPort: boolean;
    innerWidth: number;
    scrollHeight: number;
    schedulerPanelBodyHeight: number;
    xsScreenBreakPoint = 575.98;
    smScreenBreakPoint = 767.98;
    mdScreenBreakPoint = 992;
    constructor() {}

    setInnerWidth(){
        this.innerWidth = window.innerWidth;
        // this.innerWidthSubject.next(this.innerWidth);
        if(this.isEmbedded){
            this.setHeightForEmbedScheduler();
        }
        if(this.innerWidth <= this.xsScreenBreakPoint){//setting this to make references in template easier instead of always having to check if responsiveService.innerWidth <= responsiveService.xsScreenBreakPoint
            this.onMobileViewPort = true;
        } else {
            this.onMobileViewPort = false;
        }

        if(this.innerWidth > this.xsScreenBreakPoint && this.innerWidth <= this.smScreenBreakPoint){
            this.onTabletViewPort = true;
        } else {
            this.onTabletViewPort = false;
        }
    }

    init(){
        this.setInnerWidth();
    }

    getLogoHeight(){
        return Number(this.getNumberNoPixels($('#businessLogo').css('height')));
    }

    centerLoginBtns(){
        let logoHeight = this.getLogoHeight();
        let topPositionForLoginBtns = (logoHeight + 50 - 15) / 2 * -1;
        $('login-register-btns').css({top: topPositionForLoginBtns});
        return true;
    }

    adjustMatCardTitleBarHeight(idValue: string, preference: preference, showRegisterLoginButtons: any){
        let titleHeight = Number(this.getNumberNoPixels($('mat-card-title.mat-card-title').css('height')));
        let heightToSet = titleHeight - 10 + "px";
        $('#' + idValue).css({'min-height': heightToSet});

        if(this.isEmbedded && preference !== undefined){
            if($('#' + idValue + ' #loginBtn').offset() !== undefined && showRegisterLoginButtons.showLoginBtn) {
                let titleOffset = $('#' + idValue + ' mat-card-title.mat-card-title h2').offset();
                let titleWidth = $('#' + idValue + ' mat-card-title.mat-card-title h2').width();
                let titleRowOffset = $('#' + idValue + ' mat-card-title.mat-card-title').offset();

                let loginBtnOffset = $('#' + idValue + ' #loginBtn').offset();
                let loginBtnHeight = Number(this.getNumberNoPixels($('#' + idValue + ' #loginBtn').css('height')));
                let registerBtnWidth = Number(this.getNumberNoPixels($('#' + idValue + ' #registerBtn').css('width')));
                let registerBtnOffset = $('#' + idValue + ' #registerBtn').offset();
                let schedulerBoxPaddingLeft = Number(this.getNumberNoPixels($('#schedulerBox').css('padding-left')));
                let schedulerBoxPaddingTop = Number(this.getNumberNoPixels($('#schedulerBox').css('padding-top')));
                let headerWidth = $('#' + idValue).css('width');

                // if (loginBtnOffset.left < 0) {
                titleOffset.left = titleOffset.left - schedulerBoxPaddingLeft + loginBtnOffset.left;
                registerBtnOffset.left = registerBtnOffset.left + schedulerBoxPaddingLeft + (loginBtnOffset.left * -1);
                loginBtnOffset.left = schedulerBoxPaddingLeft;
                //}
                let registerBtnEnd = registerBtnOffset.left + registerBtnWidth;

                if (registerBtnEnd > titleOffset.left) {
                    let initialMinHeight = $('#' + idValue).css('min-height');
                    let leftMove = titleRowOffset.left * -1 + 16;
                    if(titleRowOffset.left > titleWidth) {
                        //     leftMove = (titleRowOffset.left - titleOffset.left) * -1;
                        leftMove = leftMove + 16;
                    }


                    if(registerBtnOffset.top + loginBtnHeight > titleOffset.top) {
                        $('mat-card-title.mat-card-title').css({
                            'position': 'relative',
                            'top': loginBtnHeight + schedulerBoxPaddingTop + 'px',
                            'left': leftMove + 'px',
                            'width': headerWidth,
                            'display': 'block',
                            'margin-top': '0px'
                        })

                        $('mat-card-title.mat-card-title h2').css({'margin-left': '0px', 'font-size': '18px'})
                    }
                    titleHeight = Number(this.getNumberNoPixels($('mat-card-title.mat-card-title').css('height')));
                    let btnRowHeight = Number(this.getNumberNoPixels($('.toolbarCardHeader > mat-toolbar-row.mat-toolbar-row').css('height')));
                    heightToSet = titleHeight + btnRowHeight + schedulerBoxPaddingTop + "px";
                    $('#' + idValue).css({'min-height': heightToSet});

                    if(initialMinHeight !== heightToSet){
                        this.setHeightForEmbedScheduler();
                    }
                }



            }

        }

    }

    addHorizontalScrollForTableOnMobile(){
        if(this.onMobileViewPort){
            $('mat-table.data-table').css({maxWidth: '100%', overflowX: 'scroll'})
            $('mat-header-cell').css({flex: '0 0 20%'})
            $('mat-cell').css({flex: '0 0 20%'})
            $('mat-header-cell.chipCell').css({flex: '0 0 30%'})
            $('mat-cell.chipCell').css({flex: '0 0 30%'})
        }
    }

    //Used when next and back buttons are in header of scheduler and title is really long and overlaps them
    //adjusts the margin
    adjustTitleMargin(titleIdValue: string){
        let h2Target = '#' + titleIdValue + ' h2';
        let titleBarRowTarget = '#' + titleIdValue;
        let h2TitlePosition = $(h2Target).offset();
        let h2TitleWidth = Number(this.getNumberNoPixels($(h2Target).css('width')));
        if(this.isEmbedded ) {
            let toggleBtnPosition = $('#toggleSideNavBtn').offset();
            let toggleBtnWidth = Number(this.getNumberNoPixels($('#toggleSideNavBtn').css('width')));

            let nextBackPosition = $('.next-back-wrapper').offset();
            let nextBackWidth = Number(this.getNumberNoPixels($('.next-back-wrapper').css('width')));
            let nextBackHeight = $('.next-back-wrapper').css('height');
            // console.log("toggleBtnPosition");
            // console.log(toggleBtnPosition);
            // console.log("toggleBtnPosition");
            // console.log(toggleBtnWidth);

            // console.log("nextBackPosition");
            // console.log(nextBackPosition);
            // console.log("nextBackWidth");
            // console.log(nextBackWidth);
            // console.log("nextBackHeight");
            // console.log(nextBackHeight);
            if(nextBackPosition !== undefined){
                let endPositionOfH2 = h2TitlePosition.left + h2TitleWidth;
                if (endPositionOfH2 > nextBackPosition.left) {
                    $(h2Target).css({'margin-top': nextBackHeight});

                } else {
                    $(h2Target).css({'margin-top': '0px'});
                }
            }

        } else if(h2TitlePosition !== undefined) {
            //below is used to center title when wraps to start new line
            //If top of title is greater than bottom of next btn, we know it wrapped to next line
            //row that the title is in should thus span 100% so text is centered
            let nextBtnPosition = $('#nextBtn').offset();
            let nextBtnHeight = Number(this.getNumberNoPixels($('#nextBtn').css('height')));
            if(nextBtnPosition !== undefined){
                let nextBtnBottom = nextBtnPosition.top + nextBtnHeight;
                let difference = Math.ceil(h2TitlePosition.top - nextBtnBottom);
                if(difference === 0){
                    $(titleBarRowTarget).css({'width': '100%'});
                    $(h2Target).css({'width': '100%'});
                }
            }
        }
    }

    toggleLocationsMapView (path: string){
        if(path==='hide'){
            $('agm-map').css({'visibility': 'hidden'});
            $('agm-map').addClass('hide-map');
        } else {
            $('agm-map').css({'visibility': 'visible'});
            $('agm-map').removeClass('hide-map');
        }

    }

    setEmbeddedValue(showHeaderParamValue){
        // console.log("showHEaderParamValue in setEmbedValue " + showHeaderParamValue + " typeof " + typeof showHeaderParamValue);
        if(showHeaderParamValue === '0'){
            this.isEmbedded = true;
            this.embeddedScheduler.next(true);
        } else {
            this.isEmbedded = false;
            this.embeddedScheduler.next(false);
        }

    }

    getMaxWidthForModals(mobileWidthException?: string){
        let maxWidth = '40vw';
        let onOlderBrowser = 'false';
        try {
            onOlderBrowser = sessionStorage.getItem('noPromisesBrowser')
        } catch (e) {

        }
        if(onOlderBrowser === 'true')
            maxWidth = '300px'
        else if(this.onMobileViewPort || this.innerWidth < 900)
            maxWidth = mobileWidthException || '80vw';
        return maxWidth;
    }

    getNumberNoPixels = (pixelHeight) => {
        if (pixelHeight !== undefined) {
            let indexOfPx = pixelHeight.indexOf('px');
            if (indexOfPx !== -1) {
                return pixelHeight.substring(0, indexOfPx);
            }
        } else {
            return '0';
        }
    }

    adjustMatLineItemHeight(itemType: string, idForRow: number, listItem: any, preference: preference, imageProp: string){
        let textForId = '#textFor'+ idForRow;
        let h3Id = '#h3For'+ idForRow;
        let pId = '#pFor'+ idForRow;
        let imageId = '#imageFor'+ idForRow;
        // let idsForConsole = [];
        // if(idsForConsole.indexOf(idForRow) !== -1) {
        //     console.log("/******START*****/")
        //     console.log("textForId" + textForId);
        //     console.log("h3Id" + h3Id);
        //     console.log("pId" + pId);
        //     console.log("imageId" + imageId);
        //     console.log("itemType" + itemType);
        //     console.log("listItem.showExtendedHeight START " + listItem.showExtendedHeight);
        //     console.log($(textForId).css('height'))
        //     console.log($(h3Id).css('height'))
        //     console.log($(pId).css('height'))
        //     console.log($(imageId).css('height'))
        // }
        let heightOfRowNoExtendedHeight = 200;
        if(itemType === 'staff')
            heightOfRowNoExtendedHeight = 103;
        if($(textForId).css('height') !== undefined){
            listItem.textFloatHeight = Number(this.getNumberNoPixels($(textForId).css('height')));
        } else if($(h3Id).css('height') !== undefined) {
            listItem.textFloatHeight = Number(this.getNumberNoPixels($(h3Id).css('height')));
            // this.reasons[i].textFloatHeight = $(h3Id).offsetHeight;
            if($(pId)!== undefined && $(pId)!== null) {
                listItem.textFloatHeight += Number(this.getNumberNoPixels($(pId).css('height')));
            } else {
                listItem.textFloatHeight += 10;
            }
        }
        let makeLayoutChanges = false;

        // if(idsForConsole.indexOf(idForRow) !== -1) {
        //     console.log("listItem.textFloatHeight " + listItem.textFloatHeight)
        // }
        switch (itemType) {
            case 'reason':
                if (listItem.textFloatHeight > heightOfRowNoExtendedHeight)
                    makeLayoutChanges = true;
                if (listItem.multiDuration)
                    makeLayoutChanges = true;
                if ((preference.showDuration || (!preference.hidePrice && listItem.price !== null))
                        && ((listItem[imageProp] !== null && listItem[imageProp] !== '') || (listItem['reasonDesc'] !== null && listItem['reasonDesc'] !== '')))
                    makeLayoutChanges = true;
                break;
            case 'staff':
                if (listItem.textFloatHeight > heightOfRowNoExtendedHeight && listItem[imageProp] !== null && listItem[imageProp] !== '')
                    makeLayoutChanges = true;
                break;
        }
        //SHOW MAT LIST ITEM WITH EXTENDED HEIGHT
        // console.log('makeLayoutChanges ' + makeLayoutChanges);
        if(makeLayoutChanges){
            listItem.showExtendedHeight = true;
            let rowId = '#rowFor' + idForRow;
            let itemRow = $(rowId);
            // console.log(itemRow);
            let itemRowWidth = itemRow.css('width');
            // console.log('itemRowWidth ' + itemRowWidth);
            $(rowId + ' div.extendedHeightRow').css({'width': itemRowWidth});
            let heightOfRow = 0;
            let heightOfChipList = 0;
            switch (itemType) {
                case 'reason':
                    heightOfRow = Number(this.getNumberNoPixels($(rowId + ' div.extendedHeightRow').css('height')));
                    heightOfChipList = Number(this.getNumberNoPixels($(rowId + ' .mat-chip-list-wrapper').css('height')));
                    heightOfRow = heightOfRow + heightOfChipList;
                    break;
                case 'staff':
                    heightOfRow = Number(this.getNumberNoPixels($(rowId + ' div.extendedHeightRow').css('height')));
                    let heightOfImage = Number(this.getNumberNoPixels($(imageId).css('height')));
                    if(heightOfImage === 0)
                        heightOfRow = heightOfRow + 100;
                    break;
            }
            // $(rowId).css({'height': heightOfRow + 'px'})
            // console.log('heightOfRow ' + heightOfRow);
            // console.log('heightOfChipList ' + heightOfChipList);
            $(rowId).css({'height': 'fit-content', 'padding-top': '15px'})
            if(heightOfChipList === 0){
                let imageHeight = Number(this.getNumberNoPixels($(imageId).css('height')));
                if(imageHeight === 0)
                    $(rowId + ' h3').css({'margin-top': '22%'})
                else {
                    let h3Height = Number(this.getNumberNoPixels($(rowId + ' h3.mat-line').css('height')))
                    // console.log("h3Height " + h3Height);
                    let marginTop = imageHeight / 2 - h3Height / 2;
                    $(rowId + ' h3').css({'margin-top': marginTop + 'px'})
                }
            } else {
                $(rowId + ' .mat-chip-list-wrapper').css({'margin-left': '0px'})
            }
            // $(rowId + ' h3').css({'font-size': '18px'})
        } else {
            listItem.showExtendedHeight = false
        }
        // if(idsForConsole.indexOf(idForRow) !== -1) {
        //     console.log("makeLayoutChanges " + makeLayoutChanges);
        //     console.log("listItem.showExtendedHeight END " + listItem.showExtendedHeight);
        //     console.log("/******END*****/")
        // }
    }


    setHeightsForEmbeded(parent: string, header: string, body: string, footer: string){
        if(this.isEmbedded){
            let innerHeight = window.innerHeight;
            let headerHeight = 0;
            let bodyMarginHeight = 8;
            let footerHeight = 0;
            if ($(parent) !== undefined && $(parent) !== null && $(parent).css('padding-bottom') !== undefined) {
                footerHeight = Number(this.getNumberNoPixels($(parent).css('padding-bottom')));
                if ($(header) !== undefined && $(header) !== null) {
                    headerHeight = Number(this.getNumberNoPixels($(header).css('height'))) + Number(this.getNumberNoPixels($(parent).css('padding-top'))) + 1;
                }
                if ($(footer).css('height') !== undefined) {
                    footerHeight = footerHeight + Number(this.getNumberNoPixels($(footer).css('height')));
                }
                let currentHeightHeaderFooter = headerHeight + footerHeight;
                let backgroundColor = $(parent).css('background-color');
                let bodyHeight = innerHeight - currentHeightHeaderFooter - (bodyMarginHeight * 2);

                $(body).height(bodyHeight);
                $(body).css({
                    'overflow-y': 'auto',
                    'overflow-x': 'hidden',
                    'margin-top': bodyMarginHeight + 'px',
                    'margin-bottom': bodyMarginHeight + 'px'
                });
                $(parent).css({'box-shadow': 'none'});
                $('#routerOutletContainer').css({'background-color':backgroundColor, 'padding-bottom':'0px'});
                $('body').css({'background-color':backgroundColor});
            }
        }
    }

    checkTimeSlotWidthForOverflow(idToPass, type: string) {//TT-6136
        let radioButtonId = '#' + idToPass;
        let radioGroupId = type === 'SERVICE_REPEATING' ? '#serviceRadioGroup' : type === 'CLASS' ? '#classRadioGroup' : '#courseRadioGroup';
        
        let radioButtonWidth = this.getNumberNoPixels($(radioButtonId).css('width'));
        let radioGroupWidth = this.getNumberNoPixels($(radioGroupId).css('width')) - this.getNumberNoPixels($(radioGroupId).css('padding-right')) - this.getNumberNoPixels($(radioGroupId).css('padding-left'));

        if(radioButtonWidth > radioGroupWidth){
            let classLabelDisplay = '.timeDisplayLabel';
            if(type === 'COURSE' || type === 'SERVICE_REPEATING'){
                classLabelDisplay = '.dateDisplayLabel';
            }
            //first make sure the mat chip label wraps properly
            let selectorWidth = 40;
            let timeDisplayLabelWidth = 0;
            let timeDisplayRoomNumberWidth = 0;
            // if($(radioGroupId + ' .mat-radio-container') !== undefined && $(radioGroupId + ' .mat-radio-container') !== null){
            //   selectorWidth = this.businessService.getNumberNoPixels($(radioGroupId + ' .mat-radio-container').css('width'));
            // }
            if($(radioGroupId + ' ' + classLabelDisplay).css('width') !== undefined && $(radioGroupId + ' ' + classLabelDisplay).css('width') !== null){
                timeDisplayLabelWidth = this.getNumberNoPixels($(radioGroupId + ' ' + classLabelDisplay).css('width'));
            }
            if($(radioGroupId + ' .timeDisplayRoomNumber').css('width') !== undefined && $(radioGroupId + ' .timeDisplayRoomNumber').css('width') !== null){
                timeDisplayRoomNumberWidth = this.getNumberNoPixels($(radioGroupId + ' .timeDisplayRoomNumber').css('width'));
            }

            let widthForChip = radioGroupWidth - selectorWidth - timeDisplayLabelWidth - timeDisplayRoomNumberWidth;

            let radioGroupLineHeight = this.getNumberNoPixels($(radioGroupId).css('line-height'));
            if(type === 'COURSE'){
                widthForChip = widthForChip - 10;
            }
            if(widthForChip < 100){
                $(radioGroupId + ' .mat-radio-button .seatsChipInfo').css({
                    'display': 'block',
                    'clear': 'both',
                    'width': 'fit-content',
                    'margin-left': '0px'
                });
                let fullLabelWidth = selectorWidth + timeDisplayLabelWidth + timeDisplayRoomNumberWidth;
                if(radioGroupWidth - fullLabelWidth < 0){
                    $(radioGroupId + ' span' + classLabelDisplay).css('white-space', 'normal');
                    $(radioGroupId + ' span.timeDisplayRoomNumber').css('white-space', 'normal');
                }
                let radioLabelHeight = this.getNumberNoPixels($(radioGroupId + ' .mat-radio-label').css('height'));

                let positionToAdjustSelectorTo = (radioLabelHeight - radioGroupLineHeight) / 2;
                $(radioGroupId + ' .mat-radio-container').css({
                    'position': 'relative',
                    'top': '-' + positionToAdjustSelectorTo + 'px'
                });

            } else {
                $(radioGroupId + ' .mat-radio-button .seatsChipInfo').css({
                    'white-space': 'normal',
                    'max-width': widthForChip + 'px',
                    'word-break': 'break-word',
                    'height': 'auto'
                });
                //next adjust the time and room label to be inline
                let radioGroupHeight = this.getNumberNoPixels($(radioGroupId).css('height'));
                if(type === 'COURSE'){
                    radioGroupHeight = this.getNumberNoPixels($(radioGroupId + ' .mat-radio-button').css('height'));
                }
                let topPositionToAdjustLabelsTo = radioGroupHeight / 2 - radioGroupLineHeight;

                $(radioGroupId + ' ' + classLabelDisplay).css({
                    'position': 'relative',
                    'top': topPositionToAdjustLabelsTo + 'px'
                });
                if(type === 'CLASS') {
                    $(radioGroupId + ' .timeDisplayRoomNumber').css({
                        'position': 'relative',
                        'top': topPositionToAdjustLabelsTo + 'px'
                    });
                }
            }

        }


    }

    setHeightForEmbedAccount(){
        this.setHeightsForEmbeded('.myAccountCard', '.toolbarCardHeader', '.myAccountCard > mat-card-content:not(#profileViewSelector)', '.myAccountFooter');

    }

    setHeightForEmbedScheduler(){//Was using this to fix issue with footer floating over content, but instead wrapped the scheduler and business info box in a row and then it worked to adjust body height on its own. leaving it here in case I need it in the future
        this.setHeightsForEmbeded('#schedulerBox', '.toolbarCardHeader', '.schedulerPanelBody', '#schedulerFooter');

    }

    checkMatOptionWidth(dropdownClass: string, containerId: string){
        let durationDropdownWidth = this.getNumberNoPixels($(dropdownClass).css('width'));
        let durationOptionsPaddingLeft = this.getNumberNoPixels($(dropdownClass +' > mat-option').css('padding-left'))
        let durationOptionsPaddingRight = this.getNumberNoPixels($(dropdownClass +' > mat-option').css('padding-right'))
        let schedulerContainerRowWidth = this.getNumberNoPixels($(containerId).css('width'));
        let schedulerContainerMinusDurationPadding = schedulerContainerRowWidth - durationOptionsPaddingRight - durationOptionsPaddingLeft;
        if(durationDropdownWidth > schedulerContainerMinusDurationPadding){
            const minWidthValue = schedulerContainerMinusDurationPadding- durationOptionsPaddingRight - durationOptionsPaddingLeft;
            $(dropdownClass).css({'width':minWidthValue + 'px',
                'min-width':  minWidthValue + 'px',
                'position': 'relative'});
        } else if (this.onMobileViewPort) {
            const minWidthValue = ((schedulerContainerMinusDurationPadding - durationDropdownWidth) / 2) + durationDropdownWidth;
            $(dropdownClass).css({
                'min-width': minWidthValue + 'px',
            });
        }

    }

    configureHorizontalSearch(inputIdString: string, btnIdString: string, outerBoxIdString?: string){//used by zip code filter and coupon to get button to align properly
        let inputId = "#" + inputIdString;
        let btnId = "#" + btnIdString;
        let outerBoxId = "#schedulerBox";
        if(outerBoxIdString !== undefined){
            outerBoxId = "#" + outerBoxIdString;
            if(outerBoxIdString === 'couponListItem'){
                outerBoxId = outerBoxId + ' div.mat-list-item-content';
            }
        }
        let inputWidth = $(inputId).css('width');
        let btnWidth = Number(this.getNumberNoPixels($(btnId).css('width')));
        let schedulerBoxWidth = Number(this.getNumberNoPixels($(outerBoxId).css('width'))) - Number(this.getNumberNoPixels($(outerBoxId).css('padding-left'))) - Number(this.getNumberNoPixels($(outerBoxId).css('padding-right')));
        let inputWidthToSet = schedulerBoxWidth - btnWidth - 5;
        $(inputId).css({'width': inputWidthToSet + 'px'});
    }

    configureValidateAddressBtnForMobile(){
        let inputIdString = 'locationPnlVariableAddressValidateField';
        let btnIdString = 'locationPnlVariableAddressValidateBtn';
        let inputId = "#" + inputIdString;
        let btnId = "#" + btnIdString;
        if(this.onMobileViewPort){
            $(inputId).css({'width': '100%'});
            $(btnId).css({'width': '100%', 'position': 'relative', 'top': '-15px'});
        } else {
            this.configureHorizontalSearch(inputIdString, btnIdString, 'variableAddressModal');
        }
    }

    setFooterToBottom(path){

        let windowHeight = $(window).height();
        let documentHeight = $(document).height();
        // let appContainer = $('#appContainer').height();
        let routerOutletContainer = Number(this.getNumberNoPixels($('#routerOutletContainer').css('height')));
        let routerOutletContainerMarginTop = Number(this.getNumberNoPixels($('#routerOutletContainer').css('margin-top')));
        let appHeader =  Number(this.getNumberNoPixels($('#pageHeader').css('height')));
        let appHeaderPaddingTop =  Number(this.getNumberNoPixels($('#pageHeader').css('padding-top')));
        let appHeaderPaddingBottom = Number(this.getNumberNoPixels($('#pageHeader').css('padding-bottom')));


        let contentBodyHeight = appHeader + appHeaderPaddingTop + appHeaderPaddingBottom + routerOutletContainer + routerOutletContainerMarginTop;
        let documentMinusContent = documentHeight - contentBodyHeight;
        // console.log("////START " + path)
        // console.log("windowHeight " + windowHeight);
        // console.log("documentHeight " + documentHeight);
        // // console.log("appContainer " + appContainer);
        // console.log("appHeader " + appHeader);
        // console.log("appHeaderPaddingTop " + appHeaderPaddingTop);
        // console.log("appHeaderPaddingBottom " + appHeaderPaddingBottom);
        // console.log("routerOutletContainer " + routerOutletContainer);
        // console.log("routerOutletContainerMarginTop " + routerOutletContainerMarginTop);
        // console.log("contentBodyHeight " + contentBodyHeight);
        // console.log("documentMinusContent " + documentMinusContent);


        // if(windowHeight === documentHeight && documentMinusContent > 0){
        //     $('#footer').css({'bottom': '0'})
        //     $('mat-sidenav-container').css({'height': windowHeight + 'px'});
        // } else {
        //     $('#footer').css({'bottom': 'inherit'})
        //     $('mat-sidenav-container').css({'height': 'inherit'});
        // }

    }

    showLoginRegisterBtnLogic(preference: preference){
        let showLoginBtn = false;
        let showRegisterBtn = false;
        if(preference === undefined || preference === null){
            showLoginBtn = false;
            showRegisterBtn = false;
        } else if(preference.loginReq === -1){//DON'T ALLOW CLIENTS TO LOGIN
            showLoginBtn = false;
            showRegisterBtn = false;
        } else if(preference.loginReq === 2){//ALLOW ONLY PRE-REGISTERED CLIENTS TO LOGIN
            showLoginBtn = true;
            showRegisterBtn = false;
        } else {
            //LOGINREQ = 0 -- ALLOW CLIENTS TO LOGIN BUT DON'T DORCE
            //LOGINREQ= 1 -- MUST LOGIN TO BOOK
            showLoginBtn = true;
            showRegisterBtn = true;
        }
        return {'showLoginBtn': showLoginBtn, 'showRegisterBtn': showRegisterBtn};
    }
}
